/**
 * Developer: Stepan Burguchev
 * Date: 6/6/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './multiValueButton.scss';

import { useDropdownAnchor } from '@approvalmax/ui/src/old/drop';
import { autoGrowInput } from '@approvalmax/ui/src/old/utils';
import last from 'lodash/last';
import React, { forwardRef, useImperativeHandle, useLayoutEffect, useRef } from 'react';
import bemFactory from 'react-bem-factory';

import { DropdownButtonElement, DropdownButtonProps, DropdownMode, DropdownMultipleValueType } from './types';

type Props = DropdownButtonProps<DropdownMultipleValueType>;

const MultiValueButton = forwardRef<DropdownButtonElement, Props>((props, ref) => {
    const {
        theme,
        value,
        onTextChange,
        boxItem: BoxItem,
        displayTextSelector,
        onRemove,
        invalid,
        warning,
        disabled,
        inputText,
        mode,
        filterPlaceholder,
    } = props;

    const bem = bemFactory.block('form-dropdown-editor-multi-value-button').themed(theme as any);
    const qa = bemFactory.qa('form-dropdown-editor-multi-value-button');

    const focused = mode !== DropdownMode.Unfocused;
    const placeholder = getPlaceholder();
    const loading = props.loading && mode !== DropdownMode.Unfocused;

    const buttonRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const updateInputWidthRef = useRef<(() => void) | null>(null);

    useDropdownAnchor(() => {
        // magic numbers below are used to adjust the measurements to styles
        const buttonEl = buttonRef.current!;
        const inputEl = inputRef.current!;
        const rootRect = buttonEl.getBoundingClientRect();
        const rootHeight = buttonEl.offsetHeight;
        const rootWidth = buttonEl.offsetWidth;
        const inputHeight = inputEl.offsetHeight + 8;

        return {
            top: rootRect.top + rootHeight - inputHeight,
            left: rootRect.left,
            width: rootWidth,
            height: inputHeight,
        };
    });

    useLayoutEffect(() => {
        updateInputWidthRef.current = autoGrowInput(inputRef.current!, { manualUpdate: true });
    }, []);

    useLayoutEffect(() => {
        if (updateInputWidthRef.current) {
            updateInputWidthRef.current();
        }
    }, [inputText, placeholder]);

    function getPlaceholder(): string | undefined {
        if (!value || value.length === 0) {
            // Default placeholder is always used when the editor is empty
            return props.placeholder;
        }

        if (mode !== DropdownMode.Unfocused) {
            // When there are values in the editor and it's focused, the filter placeholder is shown
            // (with fallback to regular placeholder)
            return filterPlaceholder !== undefined ? filterPlaceholder : props.placeholder;
        }

        return undefined;
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Backspace': {
                if (!inputRef.current!.selectionStart && !inputRef.current!.selectionEnd) {
                    const lastItem = last(value || []);

                    if (lastItem) {
                        onRemove(lastItem);
                    }
                }

                break;
            }

            default:
                break;
        }
    };

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const isUnfocused = mode === DropdownMode.Unfocused;
        const isRootTarget = e.target === buttonRef.current!;
        const isInputTarget = e.target === inputRef.current;

        if (!isUnfocused && !isInputTarget) {
            // prevent unfocus if the user clicks on the root div
            e.preventDefault();
        }

        if (isUnfocused && isRootTarget) {
            // always focus input on click, like if the button is a huge input
            e.preventDefault();
            inputRef.current!.focus();
        }
    };

    useImperativeHandle(ref, () => {
        return {
            focus() {
                inputRef.current!.focus();
            },
            blur() {
                inputRef.current!.blur();
            },
            select() {
                inputRef.current!.select();
            },
        };
    });

    return (
        <div
            className={bem(null, { disabled, loading, invalid, warning, focused })}
            data-qa={qa()}
            ref={buttonRef}
            onMouseDown={!disabled ? onMouseDown : undefined}
        >
            {value &&
                value.map((x) => (
                    <BoxItem
                        key={x.id}
                        className={bem('item')}
                        item={x}
                        disabled={disabled}
                        displayTextSelector={displayTextSelector}
                        onRemove={onRemove}
                    />
                ))}

            <input
                ref={inputRef}
                className={bem('input')}
                data-qa={qa('input')}
                placeholder={placeholder}
                type='search'
                autoComplete='new-password'
                disabled={disabled}
                value={inputText}
                onKeyDown={onKeyDown}
                onChange={(e) => onTextChange(e.target.value)}
            />
        </div>
    );
});

export default MultiValueButton;
