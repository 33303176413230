import { Action, ADD_STEP, FINALIZE_ADD_STEP } from '../../actions/index';

export default function addingNewStepReducer(state: boolean = false, action: Action): boolean {
    switch (action.type) {
        case ADD_STEP:
            return true;

        case FINALIZE_ADD_STEP:
            return false;

        default:
            return state;
    }
}
