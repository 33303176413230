import './stringCell.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const i18nPrefix = 'reports.cells.StringCell';

interface StringCellProps {
    value: string;
    className?: string;
}

const bem = bemFactory.block('rpt-string-cell');

const StringCell: FC<StringCellProps> = (props) => {
    const { value, className } = props;

    return <div className={bem.add(className)()}>{value}</div>;
};

export default memo(StringCell);
