import { Route } from 'modules/page';
import React from 'react';

const WorkflowsListPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/WorkflowsListPage')
);
const WorkflowPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './containers/WorkflowPage'));
const NewWorkflowPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './containers/NewWorkflowPage')
);

const routes: Route[] = [
    {
        path: '/:companyId/workflows',
        component: WorkflowsListPage,
    },
    {
        path: '/:companyId/workflows/new',
        component: NewWorkflowPage,
    },
    {
        path: '/:companyId/workflows/:workflowId',
        component: WorkflowPage,
    },
];

export default routes;
