import { toast } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';

import { StartOverPopupMode } from '../reducers/page/activeStartOverReducer';
import { getActiveStartOver } from '../selectors/pageSelectors';

const i18nPrefix = 'app.WorkflowId.actions.startOver';
const messages = defineMessages({
    msg_requests_start_over_success: {
        id: `${i18nPrefix}.msg_requests_start_over_success`,
        defaultMessage: 'Selected request(s) will start over soon. This may take a few moments.',
    },
});

export const OPEN_START_OVER_POPUP = 'WORKFLOW_TEMPLATES/OPEN_START_OVER_POPUP';
export const OPEN_START_OVER_POPUP_RESPONSE = 'WORKFLOW_TEMPLATES/OPEN_START_OVER_POPUP_RESPONSE';
export const OPEN_START_OVER_POPUP_FAILURE = 'WORKFLOW_TEMPLATES/OPEN_START_OVER_POPUP_FAILURE';
export const openStartOverPopup = (
    template: selectors.types.ExpandedTemplate,
    mode: StartOverPopupMode,
    isWorkflowManager: boolean
) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(OPEN_START_OVER_POPUP, {
                isNew: template.isNew,
                companyId: template.companyId,
                template,
            }),

        response: async (request) => {
            let response;
            let additional;

            if (isWorkflowManager) {
                const data = await api.requests.selectQuantityForTemplateReset({
                    companyId: template.companyId,
                    workflowIntegrationCode: template.integrationCode,
                });

                additional = {
                    isWorkflowManager: true,
                    quantityRequests: data.Quantity,
                };
            } else {
                response = await api.requests.selectForTemplateReset({
                    companyId: template.companyId,
                    templateIntegrationCode: template.integrationCode,
                });
            }

            return createAction(OPEN_START_OVER_POPUP_RESPONSE, {
                mode,
                request,
                raw: response,
                ...additional,
            });
        },

        failure: (error, request) => createErrorAction(OPEN_START_OVER_POPUP_FAILURE, error, {}),

        schema: {
            raw: { Requests: [schemas.requestSchema] },
        },
    });

export const START_OVER = 'WORKFLOW_TEMPLATES/START_OVER';
export const START_OVER_RESPONSE = 'WORKFLOW_TEMPLATES/START_OVER_RESPONSE';
export const START_OVER_FAILURE = 'WORKFLOW_TEMPLATES/START_OVER_FAILURE';
export const startOver = ({
    templateId,
    requestIds,
    companyId,
    commentText,
    isWorkflowManager,
    requestCount,
}: {
    templateId: string;
    companyId: string;
    commentText?: string;
    requestIds?: string[];
    isWorkflowManager?: boolean;
    requestCount: number;
}) =>
    createAsyncAction({
        request: (state: State) => {
            const activeStartOver = getActiveStartOver(state)!;
            const allRequestCount =
                'requestIds' in activeStartOver ? activeStartOver.requestIds.length : activeStartOver.quantityRequests;

            return createAction(START_OVER, {
                commentText,
                requestIds,
                templateId,
                allRequestCount,
                companyId,
            });
        },

        response: async (request) => {
            if (isWorkflowManager) {
                await api.requests.resetAllForTemplate({
                    workflowId: templateId,
                    commentText,
                    companyId,
                });
            } else if (requestIds) {
                await api.requests.resetTemplate({
                    requestIds,
                    templateId,
                    commentText,
                    companyId,
                });
            }

            return createAction(START_OVER_RESPONSE, {
                request,
                hasOutdatedRequests: requestCount < request.allRequestCount,
            });
        },

        failure: (error, request) => createErrorAction(START_OVER_FAILURE, error, {}),

        didDispatchResponse: () => {
            const msg = intl.formatMessage(messages.msg_requests_start_over_success, {
                count: requestCount,
            });

            toast.success(msg);
        },
    });

export const DISCARD_START_OVER_POPUP = 'WORKFLOW_TEMPLATES/DISCARD_START_OVER_POPUP';
export const discardStartOverPopup = () => createAction(DISCARD_START_OVER_POPUP, {});

export type Action = ExtractActions<typeof discardStartOverPopup | typeof openStartOverPopup | typeof startOver>;
