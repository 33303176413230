import { Route } from 'modules/page';
import React from 'react';

const Page = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './Page'));

const routes: Route[] = [
    {
        path: '/customerFeedback',
        component: Page,
    },
    {
        path: '/customerFeedback/:boardId',
        component: Page,
    },
];

export default routes;
