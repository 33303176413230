import { Module } from 'modules/page';
import { ThunkAction } from 'modules/react-redux';

import { openContactSalesPopup, openContactSupportPopup, showMyDelegatesPopup, showProfilePopup } from './actions';
import * as components from './components';
import ContactSupportPopup from './containers/ContactSupportPopup/ContactSupportPopup';
import EditableAvatar from './containers/EditableAvatar';
import { ModuleComponent } from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import moduleReducer from './reducers/moduleReducer';
import profileReducer from './reducers/profileReducer';

export { components, ContactSupportPopup, EditableAvatar };
export * as actions from './actions';

export function loadProfilePopup(): ThunkAction {
    return (dispatch, getState) => {
        dispatch(showProfilePopup());
    };
}

export function loadDelegatesPopup(): ThunkAction {
    return (dispatch, getState) => {
        dispatch(showMyDelegatesPopup());
    };
}

export function loadContactSalesPopup(): ThunkAction {
    return (dispatch, getState) => {
        dispatch(openContactSalesPopup());
    };
}

export function loadContactSupportPopup(): ThunkAction {
    return (dispatch, getState) => {
        dispatch(openContactSupportPopup());
    };
}

export const moduleDef: Module = {
    id: 'profile',
    moduleReducer: moduleReducer,
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
        profile: profileReducer,
    },
};
