import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AllFeatureTrialPaywallPopup.Content', {
    title: 'Streamline your financial controls with our top-tier features',
    chooseOrganisation: 'Choose Organisation',
    moreThan:
        'More than 6,000 ApprovalMax customers use Advanced and Premium plans to create robust financial controls.',
    upgrade: 'Upgrade your subscription',
    tryFree: 'Try all features for 14 days',
    cancelAnytime: 'No additional charges. Cancel anytime.',
});
