import { useMemo } from 'react';

import { useGetXeroCounterpartyPaymentDetails } from '../../data';
import { FormState, UseInitialFormValuesParams } from './EditPaymentDetailsPopupContent.types';

export const useInitialFormValues = ({
    paymentDetailsId,
    companyId,
    contact,
    preloadedListOfPaymentDetails,
}: UseInitialFormValuesParams): FormState => {
    const { data } = useGetXeroCounterpartyPaymentDetails(
        {
            companyId,
            counterpartyId: contact?.counterpartyId,
        },
        {
            enabled: !preloadedListOfPaymentDetails,
        }
    );

    const listOfPaymentDetailsForCounterParty = preloadedListOfPaymentDetails || data?.items;

    const selectedPaymentDetails = useMemo(
        () => listOfPaymentDetailsForCounterParty?.find((item) => item.id === paymentDetailsId),
        [listOfPaymentDetailsForCounterParty, paymentDetailsId]
    );

    const noAnyPaymentDetailsForCounterparty = listOfPaymentDetailsForCounterParty?.length === 0;

    const isGBPCurrency = contact?.financialDetails.currency === 'GBP' || !contact?.financialDetails.currency;

    if (!selectedPaymentDetails || !paymentDetailsId) {
        const defaultAccountName = contact?.financialDetails.bankAccountName || contact?.name || '';

        return {
            isDefault: true,
            country: 'UK',
            currency: isGBPCurrency ? 'GBP' : '',
            name: noAnyPaymentDetailsForCounterparty ? defaultAccountName : '',
            accountNumber: noAnyPaymentDetailsForCounterparty ? contact?.financialDetails.bankAccountNumber ?? '' : '',
            sortCode: noAnyPaymentDetailsForCounterparty ? contact?.financialDetails.sortCode ?? '' : '',
        };
    }

    return {
        isDefault: selectedPaymentDetails.isDefault,
        ...selectedPaymentDetails.bankAccount,
    };
};
