import { messages } from './WorkflowWizardPopup.messages';
import { WizardStep } from './WorkflowWizardPopup.types';

export const workflowWizardOpenTemplateIdKey = 'workflowWizardOpenTemplateId';

export const tabName: Record<WizardStep, string> = {
    [WizardStep.Initial]: '',
    [WizardStep.TypeOfDocs]: messages.wizardStepTypeOfDocs,
    [WizardStep.SourceOfDocs]: messages.wizardStepSourceOfDocs,
    [WizardStep.ApprovalSteps]: messages.wizardStepApprovalSteps,
    [WizardStep.Users]: messages.wizardStepUsers,
    [WizardStep.Requesters]: messages.wizardStepRequesters,
    [WizardStep.Conditions]: messages.wizardStepConditions,
    [WizardStep.Preview]: messages.wizardStepPreview,
};
