import { useCallback, useMemo } from 'react';

import { checkboxColumnId, retrieveRowId } from '../../Table.helpers';
import { checkboxSpacing } from '../../Table.styles';
import type { BaseItem, TableProps } from '../../Table.types';

export const useCheckedColumn = <Item extends BaseItem>(
    items: TableProps<Item>['data'],
    checkedItems: TableProps<Item>['checkedItems'],
    getRowId: TableProps<Item>['getRowId'],
    onCheckedItemsChange: TableProps<Item>['onCheckedItemsChange']
) => {
    const handleColumnCheck = useCallback(() => {
        if (!onCheckedItemsChange) {
            return;
        }

        if (!checkedItems || checkedItems.length < items.length) {
            onCheckedItemsChange(items.map((el) => retrieveRowId(el, getRowId)));
        } else {
            onCheckedItemsChange([]);
        }
    }, [items, checkedItems, onCheckedItemsChange, getRowId]);

    const checkboxColumnDefinition = useMemo(
        () => ({
            id: checkboxColumnId,
            spacing: checkboxSpacing,
            headerTextAlign: 'center' as const,
        }),
        []
    );

    return { checkboxColumnDefinition, handleColumnCheck };
};
