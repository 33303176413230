import { GlobalHotKeys, Popup } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import React from 'react';
import { connect } from 'react-redux';

import { closeActivePopup, showDevToolsPopup } from './actions/index';
import DevToolsPopup from './containers/DevToolsPopup';
import { ActivePopup } from './reducers/moduleReducer';
import { getActivePopup } from './selectors/moduleSelectors';

const i18nPrefix = 'request.Module';

interface OwnProps {}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

interface OwnState {}

function mapStateToProps(state: stateTree.State) {
    return {
        activePopup: getActivePopup(state),
        loaded: selectors.navigation.getLoaded(state),
    };
}

const mapDispatchToProps = {
    showDevToolsPopup,
    closeActivePopup,
};

class Module extends React.Component<Props, OwnState> {
    private _handlers = {
        'ctrl+alt+shift+a ctrl+alt+shift+m': this.props.showDevToolsPopup,
        'command+alt+shift+a command+alt+shift+m': this.props.showDevToolsPopup,
    };

    public render() {
        const { loaded, activePopup } = this.props;

        return (
            <GlobalHotKeys handlers={this._handlers}>
                <Popup isOpen={activePopup === ActivePopup.DevTools} onRequestClose={this.props.closeActivePopup}>
                    <DevToolsPopup />
                </Popup>
            </GlobalHotKeys>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Module);
