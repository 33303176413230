import { createAction, ExtractActions } from 'modules/react-redux';

import {} from '../reducers/moduleReducer';
import {} from '../selectors/moduleSelectors';

const i18nPrefix = 'request.actions';

export const SHOW_NEW_REQUEST_POPUP = 'REQUEST/SHOW_NEW_REQUEST_POPUP';
export const showNewRequestPopup = () => createAction(SHOW_NEW_REQUEST_POPUP, {});

export const SHOW_ACTIVE_COMPANY_NEW_REQUEST_POPUP = 'REQUEST/SHOW_ACTIVE_COMPANY_NEW_REQUEST_POPUP';
export const showActiveCompanyNewRequestPopup = (activeCompanyId: string) =>
    createAction(SHOW_ACTIVE_COMPANY_NEW_REQUEST_POPUP, {
        activeCompanyId,
    });

export const CLOSE_ACTIVE_POPUP = 'REQUEST/CLOSE_ACTIVE_POPUP';
export const closeActivePopup = () => createAction(CLOSE_ACTIVE_POPUP, {});

export type Action = ExtractActions<
    typeof closeActivePopup | typeof showActiveCompanyNewRequestPopup | typeof showNewRequestPopup
>;
