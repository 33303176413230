import { css } from 'styled-components';

const theme = {
    fontFamily: {
        primary:
            "font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', " +
            "'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
        mono: "font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;",
    },
    fontWeight: {
        standard: '400',
        semibold: '600',
        bold: '700',
    },
};

/**
 * All font-related properties in one helper.
 * @param size in pixels
 * @param color any color in CSS format
 * @param weight standard, semibold, bold
 */
export const font = (size: number, color?: string | null, weight?: keyof typeof theme.fontWeight) => css`
    font-size: ${size}px;
    ${color && `color: ${color};`}
    ${weight && `font-weight: ${theme.fontWeight[weight]};`}
`;

export const uLink = (color: string) => css`
    text-decoration: none;
    color: ${color};

    &:link,
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
        color: ${color};
    }
`;

export const margin = (top: number, right: number = top, bottom: number = top, left: number = right) => css`
    margin: ${top}px ${right}px ${bottom}px ${left}px;
`;

function toUnit(value: string | number | undefined) {
    if (typeof value === 'string') {
        return value;
    }

    return value + 'px';
}

export const uAbsolute = (props: {
    left?: number | string;
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    height?: number | string;
    width?: number | string;
}) => css`
    position: absolute;
    ${props.left && `left: ${toUnit(props.left)};`}
    ${props.top && `top: ${toUnit(props.top)};`}
    ${props.right && `right: ${toUnit(props.right)};`}
    ${props.bottom && `bottom: ${toUnit(props.bottom)};`}
    ${props.height && `height: ${toUnit(props.height)};`}
    ${props.width && `width: ${toUnit(props.width)};`}
`;

export const uAbsoluteFill = (
    props: {
        left?: number | string;
        top?: number | string;
        right?: number | string;
        bottom?: number | string;
    } = {}
) => css`
    position: absolute;
    left: ${toUnit(props.left || 0)};
    top: ${toUnit(props.top || 0)};
    right: ${toUnit(props.right || 0)};
    bottom: ${toUnit(props.bottom || 0)};
`;

export const uFlexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const uTextEllipsis = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const uTransparentInput = css`
    border: none;
    padding: 0;
    background-color: transparent;
`;

export const uTransparentLink = css`
    text-decoration: none;
    color: inherit;

    &:link,
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
        color: inherit;
    }
`;

export const oFormInput = css`
    border: solid 1px #cacccd;
    border-radius: 2px;
    padding: 3px;
    width: 100%;
    background-color: #fff;
    cursor: pointer;

    &:focus,
    &--focused,
    &--active {
        cursor: auto;
    }
    &:focus:not(&--invalid),
    &--focused:not(&--invalid),
    &--active:not(&--invalid) {
        border-color: #565656;
    }

    &--disabled {
        color: #a0a1a1;
        background-color: #f8f5f5;
        cursor: default;
    }

    &--invalid {
        background-color: #fff5f3;
        border-color: #f99178;
        color: #923924;

        &::placeholder {
            color: #923924;
        }
    }
`;

export function oDropdownPanel(offset = 2) {
    return css`
        margin: ${offset}px 0;
        background-color: #fff;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    `;
}

export function oPopupPaddingLeft() {
    return css`
        padding-left: 80px;
    `;
}

export function oPopupPaddingRight() {
    return css`
        padding-right: 60px;
    `;
}

export function oPopupBorderBottom() {
    return css`
        border-bottom: 1px solid #ebeaea;
    `;
}

export function oPopupBorderTop() {
    return css`
        border-top: 1px solid #ebeaea;
    `;
}

export function oPopupPaddingHorizontal() {
    return css`
        ${oPopupPaddingLeft()}
        ${oPopupPaddingRight()}
    `;
}

export function oPopupPaddingTop() {
    return css`
        padding-top: 10px;
    `;
}

export function oPopupPaddingBottom() {
    return css`
        padding-bottom: 40px;
    `;
}

export function oPopupPaddingVertical() {
    return css`
        ${oPopupPaddingTop()}
        ${oPopupPaddingBottom()}
    `;
}

export function oPopupPadding() {
    return css`
        ${oPopupPaddingVertical()}
        ${oPopupPaddingHorizontal()}
    `;
}

export const oDisabled = css`
    opacity: 0.7;
    cursor: default;
`;
