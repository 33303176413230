import { mixins, Mods, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ButtonProps } from './Button.types';

const rootColorMixin = (
    bg: RequiredMods['color'],
    text: RequiredMods['color'],
    hover: RequiredMods['color'],
    outline: RequiredMods['color']
) => css`
    ${mods('link', false)`
        background-color: ${theme.color[bg]};
        color: ${theme.color[text]};

        &:hover,:focus {
            background-color: ${theme.color[hover]};
            color: ${theme.color[text]};
            box-shadow: none;

            ${mods.color.transparent`
                color: ${theme.color[outline]};
            `}

            ${mods.color.white100`
                color: ${theme.color[outline]};
            `}
        }

        ${mods.outline.true`
            background-color: transparent;
            box-shadow: inset 0 0 0 1px ${theme.color[outline]};

            color: ${theme.color[outline]};

            &:focus,:hover {
                color: ${theme.color[text]};
            }

            ${mods.color.transparent`
                box-shadow: none;
            `};

            ${mods.color.white100`
                box-shadow: none;
            `};
        `};

        ${mods('icon', true)`
            ${mods.color.transparent`
                color: ${theme.color.midnight100};
            `};

            ${mods.color.red40`
                ${mods('noPadding', true)`
                    &,
                    &:focus {
                        color: ${theme.color.midnight100};
                        background-color: ${theme.color['transparent']};
                    }
                    &:hover {
                        background-color: ${theme.color['transparent']};
                        color: ${theme.color[text]};
                        box-shadow: none;
                    }
                `};
            `};
        `};
    `};
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    padding: RequiredMods['spacing'],
    gap: RequiredMods['spacing'],
    iconSize: RequiredMods['iconSize']
) => css`
    ${mods('icon', false)`
        padding: ${padding}px;
        column-gap: ${gap}px;

        ${mods.color.transparent`
            padding-left: 0;
            padding-right: 0;
        `};

        ${mixins.font('label', fontSize)};

        & > svg {
            ${mixins.iconSize(iconSize)};
        }
    `};

    ${mods('icon', true)`
        padding: ${padding / 4}px;
    `};
`;

const progressHeightMixin = (iconSize: RequiredMods['iconSize']) => css`
    height: ${iconSize}px;

    & > svg {
        ${mixins.iconSize(iconSize)};
    }
`;

export const DisabledOverlay = styled.div`
    ${mixins.position.absoluteFill};
`;

export const Inner = styled.div<StyledMods<Mods<'hide'>>>`
    display: flex;

    ${mods.hide.true`
        visibility: hidden;
    `}
`;

export const ProgressWrapper = styled.div<StyledMods<Pick<ButtonProps, 'size'>>>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;

    ${mods.size.xsmall(progressHeightMixin(12))};
    ${mods.size.small(progressHeightMixin(12))};
    ${mods.size.medium(progressHeightMixin(16))};
    ${mods.size.large(progressHeightMixin(20))};
`;

export const Root = styled.div<
    StyledMods<
        Pick<
            ButtonProps,
            | 'color'
            | 'disabled'
            | 'size'
            | 'outline'
            | 'block'
            | 'justifyContent'
            | 'fontWeight'
            | 'uppercase'
            | 'icon'
            | 'noPadding'
            | 'link'
            | 'wrap'
        >
    >
>`
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: ${({ $justifyContent }) => $justifyContent};
    transition: ${theme.duration.medium}ms;
    vertical-align: middle;
    text-decoration: none;
    position: relative;

    &:focus,
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }

    ${mods.color.transparent(rootColorMixin('transparent', 'blue100', 'transparent', 'blue80'))};
    ${mods.color.white100(rootColorMixin('white100', 'midnight100', 'white100', 'midnight80'))};
    ${mods.color.green80(rootColorMixin('green80', 'white100', 'green100', 'green100'))};
    ${mods.color.blue80(rootColorMixin('blue80', 'white100', 'blue100', 'blue100'))};
    ${mods.color.blue10(rootColorMixin('blue10', 'blue100', 'blue30', 'blue80'))};
    ${mods.color.cobalt80(rootColorMixin('cobalt80', 'white100', 'cobalt100', 'cobalt100'))};
    ${mods.color.mint80(rootColorMixin('mint80', 'midnight80', 'mint100', 'mint100'))};
    ${mods.color.red40(rootColorMixin('red40', 'red100', 'red60', 'red100'))};
    ${mods.color.midnight100(rootColorMixin('midnight100', 'white100', 'midnight100', 'midnight100'))};
    ${mods.color.midnight80(rootColorMixin('midnight80', 'white100', 'midnight100', 'midnight100'))};
    ${mods.color.midnight40(rootColorMixin('midnight40', 'midnight100', 'midnight60', 'midnight80'))};
    ${mods.color.brandDext90(rootColorMixin('brandDext90', 'white100', 'brandDext100', 'brandDext90'))};
    ${mods.color.brandQBO80(rootColorMixin('brandQBO80', 'white100', 'brandQBO100', 'brandQBO80'))};
    ${mods.color.brandXero80(rootColorMixin('brandXero80', 'white100', 'brandXero100', 'brandXero80'))};
    ${mods.color.brandCin780(rootColorMixin('brandCin780', 'white100', 'brandCin7100', 'brandCin780'))};
    ${mods.color.brandAWX80(rootColorMixin('brandAWX80', 'white100', 'brandAWX80', 'brandAWX80'))};
    ${mods.color.brandAWX100(rootColorMixin('brandAWX100', 'white100', 'brandAWX100', 'brandAWX100'))};

    ${mods.size.xsmall(rootSizeMixin('xsmall', 4, 4, 12))};
    ${mods.size.small(rootSizeMixin('small', 8, 4, 12))};
    ${mods.size.medium(rootSizeMixin('medium', 12, 8, 16))};
    ${mods.size.large(rootSizeMixin('large', 16, 8, 20))};

    ${mods.fontWeight(mixins.fontWeight)};

    ${mods.uppercase.true`
        text-transform: uppercase;
    `};

    ${mods.block.true`
        display: flex;
        width: 100%;
    `};

    ${mods.wrap.false`
        white-space: nowrap;
    `};

    ${mods.disabled.true`
        &,
        &:hover {
            background-color: ${theme.color.midnight40};
            color: ${theme.color.midnight60};
            box-shadow: none;
            cursor: default;

            ${mods.color.transparent`
                background-color: transparent;
            `};
        }
    `};

    ${mods('noPadding', true)`
        padding: 0;
    `};

    ${mods('link', true)`
        padding: 0;
        text-transform: initial;
        font-weight: ${theme.fontWeight.regular};
        ${mixins.link('blue100')};
    `};
`;
