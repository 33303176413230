import { dateTimeHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';
import { noValue } from 'shared/constants';

import {
    amountColumn,
    dateColumn,
    friendlyNameColumn,
    paymentDateColumn,
    requesterColumn,
    statusColumn,
    workflowStepColumn,
} from './RequestList.config';

export const renderDate = (request: selectors.types.ExpandedRequest) => {
    if ('date' in request.details) {
        const date = request.details.date;

        if (date) {
            const mDate = moment(date.substr(0, 10));

            if (mDate) {
                return dateTimeHelpers.formatDate(mDate);
            }
        }
    }

    return noValue;
};

export const renderPaymentDate = (request: selectors.types.ExpandedRequest) => {
    if ('paymentDate' in request.details) {
        const date = request.details.paymentDate;

        if (date) {
            const mDate = moment(date.substr(0, 10));

            if (mDate) {
                return dateTimeHelpers.formatDate(mDate);
            }
        }
    }

    return noValue;
};

export const getStartOverColumnDefinition = (integrationCode: domain.IntegrationCode | null) => {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroQuote:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.XeroCreditNotesPayable:
            return [friendlyNameColumn, statusColumn, dateColumn, workflowStepColumn, amountColumn];

        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            return [
                friendlyNameColumn,
                statusColumn,
                workflowStepColumn,
                paymentDateColumn,
                requesterColumn,
                amountColumn,
            ];

        case domain.IntegrationCode.XeroContact:
        case domain.IntegrationCode.QBooksVendor:
        case domain.IntegrationCode.QBooksJournalEntry:
        case domain.IntegrationCode.XeroManualJournal:
            return [friendlyNameColumn, statusColumn, requesterColumn, workflowStepColumn];

        default:
            return [friendlyNameColumn, statusColumn, dateColumn, requesterColumn, workflowStepColumn, amountColumn];
    }
};
