import { Button } from '@approvalmax/ui/src/components';
import { CloseIcon } from '@approvalmax/ui/src/icons';
import { forwardRef, memo } from 'react';

import { useClosable } from './Tag.hooks';
import { Label, Root } from './Tag.styles';
import { TagProps } from './Tag.types';

/**
 * The Tag component is used for items that need to be labeled, categorized, or organized using keywords that describe them.
 */
const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
    const {
        color = 'blue80',
        rounded,
        outline,
        size = 'medium',
        onClose,
        children,
        closable,
        backgroundColor,
        ...restProps
    } = props;

    const { isOpen, handleClose } = useClosable({ onClose });

    if (!isOpen) return null;

    return (
        <Root
            ref={ref}
            $color={color}
            $size={size}
            $outline={outline}
            $rounded={rounded}
            $backgroundColor={backgroundColor}
            {...restProps}
        >
            <Label>{children}</Label>

            {closable && (
                <Button onClick={handleClose} icon size={size}>
                    <CloseIcon />
                </Button>
            )}
        </Root>
    );
});

// The wrap around in the memo here is a fix for displaying the component in StoryBook
export default memo(Tag);
