import { Link } from '@approvalmax/ui/src/components';
import { memo, MouseEvent, useCallback } from 'react';

import { Root } from './UrlReferenceCell.styles';
import { UrlReferenceCellProps } from './UrlReferenceCell.types';

export const UrlReferenceCell = memo<UrlReferenceCellProps>((props) => {
    const { value, className, onClick } = props;

    const handleClick = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            onClick && onClick();

            e.preventDefault();
        },
        [onClick]
    );

    if (value.url) {
        return (
            <Root
                as={Link}
                className={className}
                ellipsis={1}
                href={value.url}
                font='body'
                target='_blank'
                rel='noopener noreferrer'
                onClick={handleClick}
            >
                {value.text}
            </Root>
        );
    }

    return (
        <Root ellipsis={1} className={className}>
            {value.text}
        </Root>
    );
});

UrlReferenceCell.displayName = 'UrlReferenceCell';
