export const authApiPaths = {
    extendSession: 'auth/extendSession',
    cannyIoToken: 'auth/getCannyIoToken',
    fido2CredentialOptions: 'auth/fido2/credentialOptions',
    fido2Credentials: 'auth/fido2/credentials',
    fido2CredentialsLabel: 'auth/fido2/credentials/label',
    fido2AssertionOptions: 'auth/fido2/assertionOptions',
    fido2Assertion: 'auth/fido2/assertion',
    xeroOAuthUrl: 'auth/getXeroOAuthUrl',
    qBooksOAuthUrl: 'auth/getQBooksOAuthUrl',
    googleOAuthUrl: 'auth/getGoogleOAuthUrl',
    microsoftOAuthUrl: 'auth/getMicrosoftOAuthUrl',
    resetPassword: 'auth/resetPwd',
    logout: 'auth/logout',
};
