import moment from 'moment';
import { FocusEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useKeyPressEvent, useLatest, useToggle } from 'react-use';

import type { HTMLTextFieldElement } from '../TextField/TextField.types';
import {
    formatCalendarValue,
    formatDatePickerValue,
    formatTextFieldValue,
    parseEnteredTextFieldValue,
} from './DatePicker.helpers';
import { DatePickerProps, MonthModeValue, YearModeValue } from './DatePicker.types';

export const useTextFieldValue = (
    props: Pick<ReturnType<typeof useDatePickerValue>, 'changeDatePickerValue' | 'datePickerValue'> &
        Pick<DatePickerProps, 'view'> &
        Partial<Pick<DatePickerProps, 'minDate' | 'maxDate' | 'noUTCTransform'>>
) => {
    const { datePickerValue, changeDatePickerValue, view, minDate, maxDate, noUTCTransform } = props;

    const [textFieldValue, setTextFieldValue] = useState<string>();

    useEffect(() => {
        setTextFieldValue(formatTextFieldValue(datePickerValue, view, noUTCTransform));
    }, [datePickerValue, noUTCTransform, view]);

    const handleTextFieldChange = useCallback((enteredValue: string) => {
        setTextFieldValue(enteredValue);
    }, []);

    const submitEntered = useCallback(() => {
        const parsedTextFieldValue = parseEnteredTextFieldValue(textFieldValue, noUTCTransform);

        const parsedDate = parsedTextFieldValue ? new Date(parsedTextFieldValue) : null;

        let resultDate = parsedDate;

        if (parsedDate && minDate && parsedDate < minDate) {
            resultDate = new Date(minDate);
        }

        if (parsedDate && maxDate && parsedDate > maxDate) {
            resultDate = new Date(maxDate);
        }

        const offsetInMinutes = moment().utcOffset();
        const resultDateString = moment(resultDate).add(offsetInMinutes, 'minutes').toISOString();

        changeDatePickerValue(resultDateString);

        /**
         * This is so that TextField will reset the changes entered by the user and pick up the ones that came from outside
         */
        setTextFieldValue(undefined);
        setTextFieldValue(formatTextFieldValue(datePickerValue, view, noUTCTransform));
    }, [changeDatePickerValue, datePickerValue, maxDate, minDate, noUTCTransform, textFieldValue, view]);

    useKeyPressEvent('Enter', submitEntered);

    return {
        textFieldValue,
        handleTextFieldChange,
    };
};

export const useCalendarValue = (props: Pick<ReturnType<typeof useDatePickerValue>, 'datePickerValue'>) => {
    const { datePickerValue } = props;

    const [calendarValue, setCalendarValue] = useState<Date>();

    useEffect(() => {
        setCalendarValue(formatCalendarValue(datePickerValue));
    }, [datePickerValue]);

    return {
        calendarValue,
    };
};

export const useDatePickerValue = (props: Pick<DatePickerProps, 'value' | 'noUTCTransform'>) => {
    const { value, noUTCTransform } = props;

    const [datePickerValue, setDatePickerValue] = useState<string>();

    useEffect(() => {
        setDatePickerValue(formatDatePickerValue(value, noUTCTransform));
    }, [noUTCTransform, value]);

    const changeDatePickerValue = useCallback(
        (changeValue: Parameters<typeof formatDatePickerValue>[0]) => {
            setDatePickerValue(formatDatePickerValue(changeValue, noUTCTransform));
        },
        [noUTCTransform]
    );

    return {
        datePickerValue,
        changeDatePickerValue,
    };
};

export const useCallbackActions = (
    props: Pick<
        DatePickerProps,
        'onOpen' | 'onFocus' | 'onChange' | 'onBlur' | 'onClear' | 'initOpen' | 'initFocus' | 'view' | 'noUTCTransform'
    > &
        Pick<ReturnType<typeof useDatePickerValue>, 'datePickerValue' | 'changeDatePickerValue'>
) => {
    const {
        onOpen,
        onFocus,
        onChange,
        datePickerValue,
        changeDatePickerValue,
        onBlur,
        onClear,
        initOpen,
        initFocus,
        view,
        noUTCTransform,
    } = props;

    const [isOpen, toggleIsOpen] = useToggle(Boolean(initOpen));
    const [isFocus, toggleIsFocus] = useToggle(Boolean(initFocus));
    const latestIsOpen = useLatest(isOpen);

    const handleOnOpen = useCallback<Required<DatePickerProps>['onOpen']>(
        (open) => {
            if (view === 'year') {
                const args = noUTCTransform
                    ? {
                          year: moment(datePickerValue).year(),
                          month: moment(datePickerValue).month() + 1,
                      }
                    : {
                          year: moment(datePickerValue).utc().year(),
                          month: moment(datePickerValue).utc().month() + 1,
                      };

                (onChange as (value?: YearModeValue) => void)?.(args);
            } else {
                (onChange as (value?: MonthModeValue) => void)?.(datePickerValue);
            }

            toggleIsOpen(open);
            onOpen && onOpen(open);
        },
        [datePickerValue, noUTCTransform, onChange, onOpen, toggleIsOpen, view]
    );

    useEffect(() => {
        if (latestIsOpen.current && datePickerValue) {
            handleOnOpen(false);
        }
    }, [datePickerValue, handleOnOpen, latestIsOpen]);

    const handleOnFocus = useCallback<Required<DatePickerProps>['onFocus']>(
        (event) => {
            toggleIsOpen(true);

            setTimeout(() => toggleIsFocus(true), 0);

            onFocus && onFocus(event);
        },
        [onFocus, toggleIsFocus, toggleIsOpen]
    );

    const handleIconClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
        toggleIsOpen(true);

        setTimeout(() => toggleIsFocus(true), 0);

        // change to FocusEvent<any> because can be called by html element as well as by SVG icon
        onFocus && onFocus(event as unknown as FocusEvent<HTMLTextFieldElement>);
    }, [onFocus, toggleIsFocus, toggleIsOpen]);

    const handleOnBlur = useCallback<Required<DatePickerProps>['onFocus']>(
        (event) => {
            toggleIsFocus(false);

            onBlur && onBlur(event);
        },
        [onBlur, toggleIsFocus]
    );

    const handleOnClear = useCallback<Required<DatePickerProps>['onClear']>(() => {
        changeDatePickerValue(undefined);

        onClear && onClear();
    }, [changeDatePickerValue, onClear]);

    return {
        isOpen,
        handleOnOpen,
        isFocus,
        handleOnFocus,
        handleIconClick,
        handleOnBlur,
        handleOnClear,
    };
};
