import { HelpCircleIcon } from '@approvalmax/ui';
import { Alert, Button, Flex, Popup, Spacing, toast } from '@approvalmax/ui/src/components';
import { RequestListFilter } from 'pages/requestList/config';
import { FC, memo, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { routingService } from 'services/routing';
import { OcrFileContentType, useBulkUploadToOcr } from 'shared/data';
import { getPath, Path } from 'urlBuilder';

import { AdditionalOptions } from './AdditionalOptions/AdditionalOptions';
import { messages } from './BulkCreationPopupContent.messages';
import { bulkCreationPopupCompanyIdState } from './BulkCreationPopupContent.states';
import { AttachmentWithInnerId, BulkCreationPopupContentProps } from './BulkCreationPopupContent.types';
import { FileContentType } from './FileContentType/FileContentType';
import { UploadFiles } from './UploadFiles/UploadFiles';

export const BulkCreationPopupContent: FC<BulkCreationPopupContentProps> = memo((props) => {
    const { closePopup } = props;

    const [bulkCreationPopupCompanyId] = useRecoilState(bulkCreationPopupCompanyIdState);

    const [attachments, setAttachments] = useState<AttachmentWithInnerId[]>([]);
    const [editMode, setEditMode] = useState(false);

    const { mutateAsync, isLoading } = useBulkUploadToOcr();

    const [fileContentType, setFileContentType] = useState<OcrFileContentType>(OcrFileContentType.DocumentPerFile);

    const onUpload = useCallback(() => {
        mutateAsync({
            pathParams: { companyId: bulkCreationPopupCompanyId },
            data: {
                fileUploadOptions: { fileContentType },
                fileRecordIds: attachments.map((attachment) => attachment.id),
            },
        })
            .then(() => {
                routingService.push(getPath(Path.requestMyList, RequestListFilter.MyOcrRequests));
                toast.success(messages.bulkUploadSuccess({ numberBills: attachments.length }));
            })
            .finally(() => {
                closePopup();
            });
    }, [attachments, bulkCreationPopupCompanyId, closePopup, fileContentType, mutateAsync]);

    return (
        <>
            <Popup.Header
                title={messages.title}
                progress={isLoading}
                actions={
                    <Button
                        disabled={editMode || isLoading || attachments.length === 0}
                        color='blue80'
                        type='submit'
                        title={messages.uploadButtonTitle}
                        onClick={onUpload}
                    >
                        {messages.uploadButtonTitle}
                    </Button>
                }
            />

            <Popup.Body>
                {attachments.length > 1 && (
                    <>
                        <Alert startIcon={<HelpCircleIcon />} size='small' color='midnight20' closable>
                            {messages.multipleAttachmentsWarning}
                        </Alert>

                        <Spacing height={16} />
                    </>
                )}

                <Flex container spacing='16'>
                    <FileContentType
                        disabled={isLoading}
                        fileContentType={fileContentType}
                        setFileContentType={setFileContentType}
                    />

                    <AdditionalOptions disabled={isLoading} editMode={editMode} setEditMode={setEditMode} />
                </Flex>

                <UploadFiles
                    disabled={isLoading}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    fileContentType={fileContentType}
                />
            </Popup.Body>
        </>
    );
});

BulkCreationPopupContent.displayName = 'BulkCreationPopupContent';
