import { mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ItemProps } from './Item.types';

export const Content = styled.div``;

export const Root = styled.li<StyledMods<Pick<ItemProps, 'divider'> & { spacing: string[] }>>`
    ${mods('spacing')(
        (value) => css`
            padding-left: ${value[1]}px;
            padding-right: ${value[1]}px;
            padding-bottom: ${value[0]}px;

            ${mods.divider.true`
            margin-bottom: ${value[0]}px;
        `};
        `
    )};

    ${mods.divider.true`
        border-bottom: 1px solid ${theme.color.midnight40};
    `};
`;
