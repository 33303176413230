import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

/**
 * Get company data based on company id from URL
 *
 * @return A company or throws exception when no found
 */
export const useCompanyBasedOnUrl = () => {
    // some of our components located outside any <Route />, so we can't get Route match there
    // this approach works despite the component location
    const match = useRouteMatch<{ companyId: string }>('/:companyId');

    return useSelector((state: State) => selectors.company.getCompanyById(state, match?.params.companyId ?? ''));
};
