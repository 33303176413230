import { CloseCircleFilledIcon } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { CloseButton, Root } from './OrUserCell.styles';
import { OrUserCellProps } from './OrUserCell.types';

const qa = bemFactory.qa('wfc-or-user-cell');

const OrUserCell: FC<OrUserCellProps> = memo((props) => {
    const { user, rule, readonly, orIfText, onRemoveRule } = props;

    const removeRule = useCallback(() => onRemoveRule(user, rule), [onRemoveRule, rule, user]);

    return (
        <Root spacing='0 0 0 80' width='236px' data-qa={qa()}>
            <Flex inline spacing='8'>
                <Text font='label' fontSize='small' color='midnight100'>
                    {orIfText}
                </Text>

                {!readonly && (
                    <CloseButton
                        onClick={removeRule}
                        size='small'
                        noPadding
                        startIcon={<CloseCircleFilledIcon size={12} color='midnight100' onClick={removeRule} />}
                        data-qa={qa('delete-button')}
                    />
                )}
            </Flex>
        </Root>
    );
});

export default OrUserCell;
