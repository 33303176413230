import {
    AddTFAEnablingBackupCodesBackend,
    AddTFAEnablingBackupCodesResponse,
} from './useAddTFAEnablingBackupCodes.types';

export const mapData = (data: AddTFAEnablingBackupCodesBackend): AddTFAEnablingBackupCodesResponse => {
    return {
        backupCodes: data.BackupCodes,
        stateId: data.StateId,
    };
};
