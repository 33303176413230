const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;

function escapeChar(chr: string) {
    chr = chr.replace(/&/g, '&amp;');
    chr = chr.replace(/</g, '&lt;');
    chr = chr.replace(/>/g, '&gt;');
    chr = chr.replace(/"/g, '&quot;');
    chr = chr.replace(/'/g, '&#039;');
    chr = chr.replace(/=/g, '&#61;');
    chr = chr.replace(/`/g, '&#96;');

    return chr;
}

export const escapeExpression = (str: any) => {
    if (typeof str !== 'string') {
        // don't escape SafeStrings, since they're already safe
        if (str && str.toHTML) {
            return str.toHTML();
        } else if (str == null) {
            return '';
        } else if (!str) {
            return str + '';
        }

        // Force a string conversion as this will be done by the append regardless and
        // the regex test will do this transparently behind the scenes, causing issues if
        // an object's to string has escaped characters in it.
        str = String(str);
    }

    if (!possible.test(str)) {
        return str;
    }

    return str.replace(badChars, escapeChar);
};
