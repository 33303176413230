import { Reference } from '@approvalmax/types';
import { PaymentDetails } from 'shared/data';

import { domain } from '../index';

export enum AmaxPayPaymentStatus {
    None = 'none',
    ReadyToPay = 'ready-to-pay',
    CancelledByUser = 'cancelled-by-user',
    Processing = 'processing',
    Paid = 'paid',
    Failed = 'failed',
}

export enum AmaxPayXeroBatchPaymentStatus {
    None = 'none',
    AwaitingPayment = 'awaiting-payment',
    Processing = 'processing',
    PartiallyPaid = 'partially-paid',
    Paid = 'paid',
    Failed = 'failed',
    Cancelled = 'cancelled',
}

export enum PaymentDetailsPaymentStatus {
    NotChecked = 'not-checked',
    PaidPreviously = 'paid-previously',
    NeverPaid = 'never-paid',
    PaymentDetailsChanged = 'payment-details-changed',
}

export interface AmaxPayPaymentItem {
    xeroBillInvoiceRequestId: string;
    amaxPayBankAccount: Reference | null;
    amount: number;
    amountDue: number;
    amountOnApproval: number;
    amountInProcessing: number;
    amountAwaitingPayment: number;
    date: string;
    dueDate: string;
    friendlyName: string;
    paymentDetails: Omit<PaymentDetails, 'isDefault'> | null;
    paymentReference: string | null;
    contact: (Reference & { defaultReference?: string }) | null;
    currency: string;
    attachments?: domain.RequestAttachment[];
    paymentStatus?: AmaxPayPaymentStatus;
    paymentDetailsPaymentStatus?: PaymentDetailsPaymentStatus;
    xeroUrl?: string;
    statusUpdatedAt?: string;
}

export interface XeroAmaxPayBatchPayment {
    status: AmaxPayXeroBatchPaymentStatus | null;
    items: AmaxPayPaymentItem[];
    isMergedBySupplier: boolean;
    referencesForMergedItems?: Record<string, string>;
}
