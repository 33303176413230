import { dataProviders } from 'modules/common';

import { messages } from './BoolConditionCell.messages';
import { DROPDOWN_VALUES } from './BoolConditionCell.types';

export const STATIC_VALUES = [
    dataProviders.FieldDataProvider.AnyValue,
    {
        id: DROPDOWN_VALUES.ENABLED,
        text: messages.enabled,
    },
    {
        id: DROPDOWN_VALUES.DISABLED,
        text: messages.disabled,
    },
];
