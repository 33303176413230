import './urlReferenceCell.scss';

import { UrlReference } from '@approvalmax/types';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const i18nPrefix = 'reports.cells.UrlReferenceCell';

interface UrlReferenceCellProps {
    value: UrlReference;
    className?: string;
    onOpen?: () => void;
}

const bem = bemFactory.block('rpt-url-reference-cell');

const UrlReferenceCell: FC<UrlReferenceCellProps> = (props) => {
    const { value, className, onOpen } = props;

    if (value.url) {
        return (
            <a
                href={value.url}
                className={bem.add(className)()}
                target='_blank'
                rel='noopener noreferrer'
                onClick={
                    onOpen
                        ? (e) => {
                              onOpen();
                              e.preventDefault();
                          }
                        : undefined
                }
            >
                {value.text}
            </a>
        );
    } else {
        return <div className={bem.add(className)()}>{value.text}</div>;
    }
};

export default memo(UrlReferenceCell);
