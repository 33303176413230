import { DotsIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Menu, Select, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { removeFieldFromActiveTemplate, renameField, updateFieldAccessTypeInActiveMatrix } from '../../../../actions';
import { getActiveEditingMatrix, getActiveMatrix } from '../../../../selectors/pageSelectors';
import { AccessType, MatrixType } from '../../../../types/matrix';
import { accessTypeTextMap, items } from './ConditionHeaderColumn.constants';
import { messages } from './ConditionHeaderColumn.messages';
import { DropdownContainer, Root } from './ConditionHeaderColumn.styles';
import { ConditionHeaderColumnProps } from './ConditionHeaderColumn.types';

const ConditionHeaderColumn: FC<ConditionHeaderColumnProps> = memo((props) => {
    const {
        fieldId,
        fieldName,
        readonly,
        canRemove,
        canRename,
        hasAccessTypeBlock,
        defaultAccessType,
        accessTypeOptions,
        matrixType,
    } = props;

    const [title, setTitle] = useState('');
    const [inTitleEdit, setInTitleEdit] = useState(false);
    const dispatch = useDispatch();
    const matrix = useSelector(getActiveMatrix);
    const activeEditingMatrix = useSelector(getActiveEditingMatrix);
    const isEditingMatrix = matrixType === MatrixType.Editing;
    const currentMatrix = isEditingMatrix ? activeEditingMatrix : matrix;

    useMount(() => {
        if (!isEditingMatrix) return;

        const isAlwaysMandatory =
            !accessTypeOptions || (accessTypeOptions.length === 1 && accessTypeOptions[0] === AccessType.Mandatory);
        const isAlreadySetAsRequired = currentMatrix?.requiredFieldIds.includes(fieldId);

        if (isAlwaysMandatory && !isAlreadySetAsRequired) {
            dispatch(updateFieldAccessTypeInActiveMatrix(fieldId, AccessType.Mandatory, matrixType));
        }
    });

    const accessType = useMemo(() => {
        if (!accessTypeOptions) {
            return AccessType.Mandatory;
        }

        if (currentMatrix?.requiredFieldIds.includes(fieldId)) {
            return AccessType.Mandatory;
        }

        if (currentMatrix?.readonlyFieldIds.includes(fieldId)) {
            return AccessType.Readonly;
        }

        if (!defaultAccessType || !accessTypeOptions.includes(defaultAccessType)) {
            console.error(
                `'accessTypeOptions' (${accessTypeOptions.join(
                    ', '
                )}) does not include the default access type ${defaultAccessType}. [fieldName: ${fieldName}]`
            );

            return accessTypeOptions[0];
        }

        return defaultAccessType;
    }, [
        accessTypeOptions,
        defaultAccessType,
        fieldId,
        fieldName,
        currentMatrix?.requiredFieldIds,
        currentMatrix?.readonlyFieldIds,
    ]);

    const onRemove = useCallback(() => {
        dispatch(removeFieldFromActiveTemplate(fieldId));
    }, [dispatch, fieldId]);

    const onBeginEdit = useCallback(() => {
        setInTitleEdit(true);
        setTitle(fieldName || '');
    }, [fieldName]);

    const onEnter = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                setInTitleEdit(false);

                if (title) {
                    dispatch(renameField(fieldId, title));
                }
            }
        },
        [dispatch, fieldId, title]
    );

    const onTitleBlur = useCallback(() => {
        setInTitleEdit(false);

        if (title) {
            dispatch(renameField(fieldId, title));
        }
    }, [dispatch, fieldId, title]);

    const onChange = useCallback(
        (value: AccessType) => {
            dispatch(updateFieldAccessTypeInActiveMatrix(fieldId, value, matrixType));
        },
        [dispatch, fieldId, matrixType]
    );

    const actionItems = useMemo(
        () => [
            {
                name: messages.rename,
                title: messages.rename,
                onClick: onBeginEdit,
            },
            {
                name: messages.remove,
                title: messages.remove,
                onClick: onRemove,
            },
        ],
        [onBeginEdit, onRemove]
    );

    const availableItems = useMemo(
        () => items.filter((item) => accessTypeOptions && accessTypeOptions.includes(item.id)),
        [accessTypeOptions]
    );

    if (hasAccessTypeBlock) {
        if (!accessTypeOptions || accessTypeOptions.length <= 1) {
            return (
                <Box width='172px' spacing='12 0'>
                    <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                        {fieldName}
                    </Text>

                    <Spacing height={4} />

                    <Text font='body' fontSize='small' color='midnight70'>
                        {accessTypeTextMap[accessType]}
                    </Text>
                </Box>
            );
        }

        return (
            <Box width='172px' spacing='12 0'>
                <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                    {fieldName}
                </Text>

                <Spacing height={4} />

                <Select
                    value={accessType}
                    items={availableItems}
                    onChange={onChange}
                    textActivatorColor='midnight70'
                    size='small'
                    noInput
                    dropdownWidth='max-content'
                    disabled={readonly}
                />
            </Box>
        );
    }

    return (
        <Root width='172px' spacing='12 0'>
            {inTitleEdit ? (
                <TextField
                    value={title}
                    onChange={setTitle}
                    onKeyDown={onEnter}
                    onBlur={onTitleBlur}
                    initFocus
                    size='xsmall'
                    clearable={false}
                />
            ) : (
                <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                    {fieldName}
                </Text>
            )}

            {!readonly && (canRemove || canRename) && (
                <DropdownContainer>
                    <Dropdown
                        closeOnClickInnerButton
                        activator={
                            <Button noPadding>
                                <DotsIcon color='blue100' />
                            </Button>
                        }
                        width='auto'
                    >
                        <Menu size='small' items={actionItems} />
                    </Dropdown>
                </DropdownContainer>
            )}
        </Root>
    );
});

export default ConditionHeaderColumn;
