import { getEmailDomain } from '@approvalmax/data';
import { Radio, Text, TextField } from '@approvalmax/ui/src/components';
import { escapeSpecificExpressions } from '@approvalmax/utils/src/helpers/string/escapeSpecificExpressions';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useRef } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue } from 'recoil';
import { getDefaultEnvironment } from 'shared/data';

import { workflowSettingsScrollPositionState } from '../../WorkflowSettingsPopupContent.states';
import { WorkflowSettingsScrollPositionState } from '../../WorkflowSettingsPopupContent.types';
import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionCaptureViaEmail.messages';
import { SectionCaptureViaEmailProps } from './SectionCaptureViaEmail.types';

export const SectionCaptureViaEmail: FC<SectionCaptureViaEmailProps> = memo((props) => {
    const { templateSettings, onChangeOcrEmailAddress, onChangeOcrRequestInitialStatus, readonly, company, qa } = props;

    const workflowSettingsScrollPosition = useRecoilValue(workflowSettingsScrollPositionState);
    const fullEmailAddress = (templateSettings.ocrEmailAddress || '') + getEmailDomain(getDefaultEnvironment());
    const ref = useRef<HTMLInputElement>(null);

    useMount(() => {
        if (workflowSettingsScrollPosition === WorkflowSettingsScrollPositionState.SectionCaptureViaEmail) {
            ref.current?.scrollIntoView();
        }
    });

    const transform = useCallback((value: string) => {
        return escapeSpecificExpressions(value, ['@']);
    }, []);

    const ocrRequestInitialStatusEnable =
        company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureEmailCreationStep) &&
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture);

    return (
        <SettingsSection title={messages.title} data-qa={qa('capture-via-email')}>
            <SettingsSubsection subtitle={messages.ocrEmailAddressSubtitle}>
                <TextField
                    ref={ref}
                    readOnly={readonly}
                    placeholder={messages.placeholder}
                    onChange={onChangeOcrEmailAddress}
                    value={templateSettings.ocrEmailAddress}
                    transform={transform}
                />

                <Text font='label' fontSize='large' fontWeight='medium'>
                    {fullEmailAddress}
                </Text>
            </SettingsSubsection>

            {ocrRequestInitialStatusEnable && (
                <SettingsSubsection subtitle={messages.ocrRequestInitialStatusSubtitle}>
                    <Radio.Group
                        disabled={readonly}
                        value={templateSettings.ocrRequestInitialStatus}
                        onChange={onChangeOcrRequestInitialStatus}
                        name='ocrRequestInitialStatus'
                        block
                    >
                        <Radio value={domain.RequestStatusV2.Draft}>{messages.draftStatusText}</Radio>

                        <Radio value={domain.RequestStatusV2.OnApproval}>{messages.onApprovalStatusText}</Radio>
                    </Radio.Group>
                </SettingsSubsection>
            )}
        </SettingsSection>
    );
});

SectionCaptureViaEmail.displayName = 'SectionCaptureViaEmail';
