import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { put, select, takeEvery } from 'redux-saga/effects';

import { reloadRequestList } from '../actions';
import { PAGE_ID } from '../config';

export default function* () {
    let alreadyReloading = false;
    let oldState = (yield select()) as stateTree.State;

    yield takeEvery('*', function* () {
        const newState = (yield select()) as stateTree.State;
        const inPage = selectors.navigation.getActivePageId(newState) === PAGE_ID;
        const companiesChanged = oldState.entities.companies !== newState.entities.companies;

        oldState = newState;

        if (!alreadyReloading && inPage && companiesChanged) {
            alreadyReloading = true;
            // Companies changed: invite accepted, delegates updated etc. => reload list
            yield put(reloadRequestList() as any);
            alreadyReloading = false;
        }
    });
}
