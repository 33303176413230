export const companiesApiPaths = {
    create: 'companies/create',
    select: 'companies/select',
    edit: 'companies/edit',
    delete: 'companies/delete',
    migrateToMatchingV2: 'companies/migrateToMatchingV2',
    getUserContext: 'companies/getUserContext',
    currencies: 'companies/:companyId/currencies',
    delegations: 'companies/delegations',
    companyDelegations: 'companies/:companyId/delegations',
    connectToNetSuiteAccount: 'companies/finishNetSuiteIntegration',
    selectFields: 'companies/selectFields',
    getMatchingSettings: 'companies/getMatchingSettings',
    getAllParticipantWorkflowVersions: 'companies/getAllParticipantWorkflowVersions',
    getAllOpenRequestsWithParticipantDecisionRequired: 'companies/getAllOpenRequestsWithParticipantDecisionRequired',
    getQuantityOfAllOpenRequestsWithParticipantDecisionRequired:
        'companies/getQuantityOfAllOpenRequestsWithParticipantDecisionRequired',
    offboardParticipantWithAllRequestsReplacement: 'companies/offboardParticipantWithAllRequestsReplacement',
    editParticipantPermissions: 'companies/:companyId/participants/:participantId/permissions',
    xeroCounterpartyPaymentDetailsHistory:
        'companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails/history',
};
