import { Reference } from '@approvalmax/types';
import { compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import moment from 'moment';
import { api } from 'services/api';

import { getSearchContext } from '../selectors/moduleSelectors';
import { CompanySearchContext, TemplateReference } from '../types';

export const LOAD_SEARCH_CONTEXT = 'SEARCH/LOAD_SEARCH_CONTEXT';
export const LOAD_SEARCH_CONTEXT_RESPONSE = 'SEARCH/LOAD_SEARCH_CONTEXT_RESPONSE';
export const LOAD_SEARCH_CONTEXT_FAILURE = 'SEARCH/LOAD_SEARCH_CONTEXT_FAILURE';
export const loadSearchContext = () =>
    createAsyncAction({
        shouldSendRequest: (state: State) => {
            const activeContext = getSearchContext(state);

            if (
                activeContext &&
                activeContext.lastLoaded &&
                moment.utc().diff(moment.utc(activeContext.lastLoaded), 'm') < 60
            ) {
                return false;
            }

            return true;
        },

        request: (state: State) =>
            createAction(LOAD_SEARCH_CONTEXT, {
                companies: selectors.company.getCompanies(state),
            }),

        response: async (request) => {
            const response = await api.search.getSearchContext();

            return createAction(LOAD_SEARCH_CONTEXT_RESPONSE, {
                request,
                loadTime: moment.utc().toISOString(),
                companyContexts: response.CompanySearchContexts.filter((c) =>
                    request.companies.some((x) => x.id === c.CompanyId)
                )
                    .map(
                        (c): CompanySearchContext => ({
                            id: c.CompanyId,
                            text: c.CompanyName,
                            templates: c.Templates.map((t): TemplateReference => {
                                const integrationCode = t.IntegrationCode as domain.IntegrationCode;

                                return {
                                    id: t.TemplatId,
                                    text:
                                        selectors.template.getTemplateDisplayNameByCode(integrationCode) ||
                                        t.TemplateName,
                                    integrationCode,
                                };
                            }).sort(
                                compareHelpers.comparatorFor<Reference>(compareHelpers.stringComparator2AscI, 'text')
                            ),
                            fields: (c.SearchFields || []).map(
                                (f): domain.Field => ({
                                    id: f.fieldId!,
                                    name: f.fieldName,
                                    systemPurpose: schemas.field.mapFieldSystemPurpose(f.fieldPurpose),
                                    type: f.fieldType,
                                    companyId: c.CompanyId,
                                    exactValues: [],
                                    submitterApplicable: false,
                                    referenceRecordFieldSystemPurpose: null,
                                    netSuiteField: f.netSuiteField
                                        ? schemas.field.mapNetSuiteField(f.netSuiteField)
                                        : undefined,
                                })
                            ),
                        })
                    )
                    .sort(compareHelpers.comparatorFor<Reference>(compareHelpers.stringComparator2AscI, 'text')),
            });
        },

        failure: (error, request) => createErrorAction(LOAD_SEARCH_CONTEXT_FAILURE, error, {}),
    });

export const INVALIDATE_SEARCH_CONTEXT = 'SEARCH/INVALIDATE_SEARCH_CONTEXT';
export const invalidateSearchContext = () => createAction(INVALIDATE_SEARCH_CONTEXT, {});

export type Action = ExtractActions<typeof invalidateSearchContext | typeof loadSearchContext>;
