import { domain } from 'modules/data';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, SHOW_WORKFLOWS_LIST_PAGE } from '../../actions/index';
import {
    APPLY_TEMPLATE_SETTINGS,
    DISCARD_SETTINGS_POPUP,
    OPEN_SETTINGS_POPUP_RESPONSE,
} from '../../actions/templateSettings';

export interface SettingsData {
    data: domain.TemplateSettings;
}

export type ActiveSettings = ImmutableObject<SettingsData> | null;

export default function activeSettingsReducer(state: ActiveSettings = null, action: Action): ActiveSettings {
    switch (action.type) {
        case OPEN_SETTINGS_POPUP_RESPONSE:
            return immutable<SettingsData>({
                data: action.payload.response,
            });

        case SHOW_WORKFLOWS_LIST_PAGE:
        case APPLY_TEMPLATE_SETTINGS:
        case DISCARD_SETTINGS_POPUP:
            return null;

        default:
            return state;
    }
}
