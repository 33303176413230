import { Reference } from '@approvalmax/types';
import { constants } from '@approvalmax/utils';
import { immutable } from 'modules/immutable';
import moment, { MomentInput } from 'moment/moment';

export const timeZoneReferences = process.env.DEBUG ? constants.timezones : immutable<Reference>(window.timezones);

const maybeAddZero = (time: number) => (time < 10 ? `0${time}` : time);

const stringifyOffset = (offset: number) => {
    // signs are opposite
    const sign = Math.sign(offset) === 1 ? '-' : '+';
    const unsigned = Math.abs(offset);
    const hours = Math.floor(unsigned / 60);
    const minutes = unsigned % 60;

    return `UTC${sign}${maybeAddZero(hours)}:${maybeAddZero(minutes)}`;
};

/**
 * @deprecated this might be redundant. We need this to make sure we get the correct
 * ME timezone. Our current approach must be clarified because our local list of timezones
 * not fit our remote list and MY local TZ (window.ApprovalMax.userTimeZone) will be different
 * from the same MY TZ on dev04. Right now I don't have time to experiment why this works
 * differently, that's why this function was created as a temporary 100% correct solution
 * but it's better to get rid of it after timezones list will be identical on all ENVs
 */
export const findTimeZoneByOffset = (offset: number) => {
    const stringifiedOffset = stringifyOffset(offset);

    return timeZoneReferences.find((x) => x.text.startsWith(`(${stringifiedOffset})`));
};

export const findTimeZoneById = (id: string): Reference | undefined => {
    return timeZoneReferences.find((x) => x.id === id);
};

/**
 * Retrieves the UTC offset of a time zone in minutes from the time zone's text representation.
 * @returns {number} The UTC offset of the time zone in minutes. Returns 0 if the timeZone argument is undefined.
 */
export const getUtcOffset = (timeZone: Reference | undefined): number => {
    if (!timeZone) return 0;

    const regex = /\((UTC[+-])(\d{2}):(\d{2})\)/;
    const match = timeZone.text.match(regex);

    if (match) {
        const sign = match[1] === 'UTC+' ? 1 : -1;
        const hours = Number(match[2]);
        const minutes = Number(match[3]);

        return sign * (hours * 60 + minutes);
    } else {
        return 0;
    }
};

export const getLocalUtcOffset = () => new Date().getTimezoneOffset() * -1;

/**
 * Add UTC offset for some date.
 * @returns {string} String date with added UTC offset. Default format is YYYY-MM-DDTHH:mm:ssZ.
 * Reference: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/#default-format
 */
export const addUtcOffset = ({
    date,
    utcOffset,
    format,
}: {
    date: MomentInput;
    utcOffset: number;
    format?: string;
}): string => {
    return moment.utc(date).add(utcOffset, 'minutes').format(format);
};

export const addOffset = (date: MomentInput, utcOffset: number) => {
    return moment(date).add(utcOffset, 'minutes');
};

export const subtractOffset = (date: MomentInput, utcOffset: number) => {
    return moment(date).subtract(utcOffset, 'minutes');
};

/**
 * ```
 * UTC-12:00 --------------- UTC -------------- UTC+14:00
 *  case 1:       newTz                  oldTz
 *  case 2:       oldTz                  newTz
 * ```
 */
export const getOffsetShift = (newUtcOffset: number, oldUtcOffset: number) => {
    const sign = oldUtcOffset > newUtcOffset ? -1 : 1;

    return Math.abs(oldUtcOffset - newUtcOffset) * sign;
};
