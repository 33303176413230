/**
 * Developer: Stepan Burguchev
 * Date: 4/14/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './errorIcon.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('wfc-error-icon');
const qa = bemFactory.qa('wfc-error-icon');

interface ErrorIconProps {
    className?: string;
}

const ErrorIcon: FC<ErrorIconProps> = (props) => {
    const { className } = props;

    return (
        <div data-qa={qa()} className={bem.add(className)()}>
            !
        </div>
    );
};

export default memo(ErrorIcon);
