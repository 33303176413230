import { useRef } from 'react';

/**
 * Get the previous value (that was used during the previous render).
 * Use it when useEffect dependencies list is not enough.
 */
export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    const result = ref.current;

    ref.current = value;

    return result;
}
