import { domain } from 'modules/data';

export enum Link {
    ApprovalConditions = 'approval-conditions',
    SafetyCatch = 'safety-catch',
    LockDateHandling = 'lock-date-handling',
    InstructionForRequesters = 'instructions-for-requesters',
    ApprovalDecisionPolicy = 'approval-decision-policy',
    LineItems = 'line-items',
    EmailToSupplier = 'email-to-supplier',
    SupplierBankAccount = 'supplier-bank-account',
    FraudDetectionBypassing = 'fraud-detection-bypass',
    FraudDetectionAfterApproval = 'fraud-detection-after-approval',
    AddRejectedPrefix = 'add-rejected-prefix',
    PostingPreferences = 'posting-preferences',
    GoodsReceivedNote = 'goods-received-note',
    PriceChecker = 'price-checker',
    AmaxUrlFieldUpdate = 'approvalmax-url-field-create',
}

export type LinksCollection = { [key in Link]?: string };

export type LinksData = {
    [key in domain.IntegrationType]: {
        [key in domain.IntegrationCode | 'common']?: LinksCollection;
    };
};

enum FieldSettingsLearnMoreLink {
    ExpensesFields = 'ExpensesFields',
    HeaderFields = 'HeaderFields',
    ItemsFields = 'ItemsFields',
}

export type FieldSettingsLinkCollection = { [key in FieldSettingsLearnMoreLink]?: string };

export type FieldSettingsLearnMoreLinksData = {
    [key in domain.IntegrationType]: {
        [key in domain.IntegrationCode | 'common']?: FieldSettingsLinkCollection;
    };
};
