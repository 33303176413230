import { enabledByParams, QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import {
    UseGetXeroCounterpartyPaymentDetailsForRequestPathParams,
    UseGetXeroCounterpartyPaymentDetailsForRequestResponse,
    UseGetXeroCounterpartyPaymentDetailsForRequestResponseBackend,
} from './useGetXeroCounterpartyPaymentDetailsForRequest.types';

export const useGetXeroCounterpartyPaymentDetailsForRequest = (
    pathParams: QueryParams<UseGetXeroCounterpartyPaymentDetailsForRequestPathParams>,
    queryOptions?: QueryOptions<
        UseGetXeroCounterpartyPaymentDetailsForRequestResponseBackend,
        UseGetXeroCounterpartyPaymentDetailsForRequestResponse
    >
) => {
    return useGetRequestLegacy<
        UseGetXeroCounterpartyPaymentDetailsForRequestResponseBackend,
        UseGetXeroCounterpartyPaymentDetailsForRequestResponse
    >(
        integrationsApiPaths.getXeroCounterpartyPaymentDetailsForRequest,
        {},
        {
            pathParams,
            queryOptions: {
                enabled: enabledByParams<UseGetXeroCounterpartyPaymentDetailsForRequestPathParams>(pathParams),
                ...queryOptions,
            },
            method: 'get',
        }
    );
};
