import { errorHelpers } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import { useMemo } from 'react';

import { ExpandedMatrixLine } from '../../../../selectors/pageSelectors';
import { ActiveMatrixData } from '../../../../types/activeMatrixData';
import { ActiveWorkflow } from '../../../../types/activeWorkflow';
import { MatrixType } from '../../../../types/matrix';
import { messages } from './Matrix.messages';

const { netSuiteConstants } = constants;

export const useUserColumnTitle = (matrixType: MatrixType) => {
    switch (matrixType) {
        case MatrixType.Approval:
            return messages.approvers;

        case MatrixType.Reviewer:
        case MatrixType.Editor:
            return messages.reviewers;

        case MatrixType.Requester:
            return messages.requesters;

        case MatrixType.AutoApproval:
            return messages.ruleDescription;

        case MatrixType.Editing:
            return messages.editors;

        default:
            throw errorHelpers.assertNever(matrixType);
    }
};

export const useUnusedFields = ({
    integrationType,
    fields,
    matrix,
    template,
}: {
    integrationType: domain.IntegrationType;
    fields: domain.Field[];
    matrix: ActiveMatrixData<ExpandedMatrixLine>;
    template: ActiveWorkflow;
}) => {
    return useMemo(() => {
        if (matrix.type === MatrixType.AutoApproval || integrationType === domain.IntegrationType.None) {
            return fields.filter(
                (f) =>
                    f.systemPurpose === domain.FieldSystemPurpose.General &&
                    matrix.generalFieldOrder.every((fieldId) => f.id !== fieldId)
            );
        }

        if (integrationType === domain.IntegrationType.NetSuite) {
            const result = fields.filter(
                (field) =>
                    field.systemPurpose === domain.FieldSystemPurpose.NetSuiteCustom &&
                    !field.isArchived &&
                    field.workflows?.some((workflow) => workflow.workflowId === template.id && workflow.isActive) &&
                    matrix.generalFieldOrder.every((fieldId) => field.id !== fieldId)
            );

            return matrix.type === MatrixType.Requester
                ? result
                : result.filter(
                      (field) =>
                          field.netSuiteField?.type &&
                          netSuiteConstants.REFERENCE_CUSTOM_FIELDS.includes(field.netSuiteField.type)
                  );
        }

        return [];
    }, [fields, integrationType, matrix.generalFieldOrder, matrix.type, template.id]);
};

export const useRequiredFieldIds = ({
    matrixType,
    template,
    requiredFieldIds,
}: {
    matrixType: MatrixType;
    template: ActiveWorkflow;
    requiredFieldIds: string[];
}) => {
    return useMemo(() => {
        switch (matrixType) {
            case MatrixType.Approval:
                return template.requiredFieldIds;

            case MatrixType.Editor:
                return template.requiredFieldIds;

            case MatrixType.Requester:
                return requiredFieldIds;

            case MatrixType.AutoApproval:
                return template.requiredFieldIds;

            case MatrixType.Reviewer:
                return template.reviewStep.requiredFieldIds;

            case MatrixType.Editing:
                return template.requiredFieldIds;

            default:
                throw errorHelpers.assertNever(matrixType);
        }
    }, [matrixType, requiredFieldIds, template.requiredFieldIds, template.reviewStep.requiredFieldIds]);
};
