import { compareHelpers, errorHelpers } from '@approvalmax/utils';
import { backend, domain, schemas, State, stateTree } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { api } from 'services/api';

import { getActiveTemplate, getVersionHistory } from '../selectors/pageSelectors';
import { messages } from './versionHistory.messages';

export const FETCH_VERSION_HISTORY = 'LEGACY_WORKFLOW_TEMPLATES/FETCH_VERSION_HISTORY';
export const FETCH_VERSION_HISTORY_RESPONSE = 'LEGACY_WORKFLOW_TEMPLATES/FETCH_VERSION_HISTORY_RESPONSE';
export const FETCH_VERSION_HISTORY_FAILURE = 'LEGACY_WORKFLOW_TEMPLATES/FETCH_VERSION_HISTORY_FAILURE';
export const fetchVersionHistory = () =>
    createAsyncAction({
        request: (state: State) => {
            const template = getActiveTemplate(state);
            const versionsHistory = getVersionHistory(state);

            if (!template) {
                throw errorHelpers.notFoundError(messages.activeTemplateNotFound);
            }

            return createAction(FETCH_VERSION_HISTORY, {
                templateId: template.id,
                startFrom: versionsHistory.templates.length,
                companyId: template.companyId,
            });
        },

        response: async (request) => {
            const itemsPerPage = 25;

            const response = await api.templates.selectWorkflowVersions({
                workflowId: request.templateId,
                companyId: request.companyId,
                startFrom: request.startFrom,
                rowNum: itemsPerPage,
            });

            const templates: domain.WorkflowVersion[] = schemas.template.mapWorkflowVersionAnswer(response.Templates);

            const sortedTemplates = templates.sort(
                compareHelpers.comparatorFor<(typeof templates)[0]>(compareHelpers.numberComparator2Desc, 'version')
            );

            const hasMore = response.HasMore;

            return createAction(FETCH_VERSION_HISTORY_RESPONSE, {
                templates: sortedTemplates,
                hasMore,
            });
        },

        failure: (error, request) => createErrorAction(FETCH_VERSION_HISTORY_FAILURE, error, {}),
    });

export const CLEAR_VERSION_HISTORY = 'LEGACY_WORKFLOW_TEMPLATES/CLEAR_VERSION_HISTORY';
export const clearVersionHistory = () => createAction(CLEAR_VERSION_HISTORY, {});

export const OPEN_VERSION_HISTORY_PANEL = 'LEGACY_WORKFLOW_TEMPLATES/OPEN_VERSION_HISTORY_PANEL';
export const openVersionHistoryPanel = () => createAction(OPEN_VERSION_HISTORY_PANEL, {});

export const CLOSE_VERSION_HISTORY_PANEL = 'LEGACY_WORKFLOW_TEMPLATES/CLOSE_VERSION_HISTORY_PANEL';
export const closeVersionHistoryPanel = () => createAction(CLOSE_VERSION_HISTORY_PANEL, {});

export const FETCH_WORKFLOW_WITH_VERSION = 'LEGACY_WORKFLOW_TEMPLATES/FETCH_WORKFLOW_WITH_VERSION';
export const FETCH_WORKFLOW_WITH_VERSION_RESPONSE = 'LEGACY_WORKFLOW_TEMPLATES/FETCH_WORKFLOW_WITH_VERSION_RESPONSE';
export const FETCH_WORKFLOW_WITH_VERSION_FAILURE = 'LEGACY_WORKFLOW_TEMPLATES/FETCH_WORKFLOW_WITH_VERSION_FAILURE';
export const fetchWorkflowWithVersion = (version: number) =>
    createAsyncAction({
        request: (state: State) => {
            const template = getActiveTemplate(state);

            if (!template) {
                throw errorHelpers.notFoundError(messages.activeTemplateNotFound);
            }

            return createAction(FETCH_WORKFLOW_WITH_VERSION, {
                templateId: template.id,
                version: version,
                companyId: template.companyId,
            });
        },

        response: async (request) => {
            const response = await api.templates.select({
                templateId: request.templateId,
                companyId: request.companyId,
                version: request.version,
            });

            if (response.Templates.length !== 1 || response.Templates[0].TemplateId !== request.templateId) {
                createErrorAction(
                    FETCH_WORKFLOW_WITH_VERSION_FAILURE,
                    errorHelpers.createError(messages.errorWhileFetching),
                    {}
                );
            }

            const { entities } = normalize<backend.TemplateSelectAnswer, stateTree.Entities>(response, {
                Templates: [schemas.templateSchema],
            });

            const template = entities.templates[request.templateId];

            return createAction(FETCH_WORKFLOW_WITH_VERSION_RESPONSE, {
                template,
                normilizedUsers: entities.users,
            });
        },

        failure: (error, request) => createErrorAction(FETCH_WORKFLOW_WITH_VERSION_FAILURE, error, {}),
    });

export type Action = ExtractActions<
    | typeof clearVersionHistory
    | typeof closeVersionHistoryPanel
    | typeof fetchVersionHistory
    | typeof fetchWorkflowWithVersion
    | typeof openVersionHistoryPanel
>;
