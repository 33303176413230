/**
 * Developer: Stepan Burguchev
 * Date: 5/18/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Action } from '../../actions/index';
import {
    OPEN_START_OVER_POPUP,
    OPEN_START_OVER_POPUP_FAILURE,
    OPEN_START_OVER_POPUP_RESPONSE,
} from '../../actions/startOver';
import {
    DELETE_TEMPLATE,
    DELETE_TEMPLATE_FAILURE,
    DELETE_TEMPLATE_RESPONSE,
    SAVE_TEMPLATE,
    SAVE_TEMPLATE_FAILURE,
    SAVE_TEMPLATE_RESPONSE,
} from '../../actions/template';
import {
    OPEN_SETTINGS_POPUP,
    OPEN_SETTINGS_POPUP_FAILURE,
    OPEN_SETTINGS_POPUP_RESPONSE,
} from '../../actions/templateSettings';

export default function loadingReducer(state: boolean = false, action: Action): boolean {
    switch (action.type) {
        case SAVE_TEMPLATE:
        case DELETE_TEMPLATE:
        case OPEN_START_OVER_POPUP:
        case OPEN_SETTINGS_POPUP:
            return true;

        case SAVE_TEMPLATE_RESPONSE:
        case SAVE_TEMPLATE_FAILURE:
        case DELETE_TEMPLATE_RESPONSE:
        case DELETE_TEMPLATE_FAILURE:
        case OPEN_START_OVER_POPUP_RESPONSE:
        case OPEN_START_OVER_POPUP_FAILURE:
        case OPEN_SETTINGS_POPUP_RESPONSE:
        case OPEN_SETTINGS_POPUP_FAILURE:
            return false;

        default:
            return state;
    }
}
