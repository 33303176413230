import { Reference } from '@approvalmax/types';
import { validators } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { backend, domain, State } from 'modules/data';

import {
    getConditionsByFieldSystemPurposeOrId,
    getNegativeValuesFromConditions,
    getPositiveValuesFromConditions,
} from '../../data/Request';
import { getRequiredFields } from '../requestSelectors';
import { getQBooksAccountLineItems, getQBooksAccountLineItemsSummary } from './accountLineItemSelectors';
import { getQBooksLineItems, getQBooksLineItemsSummary, mergeSummaries } from './lineItemSelectors';
import { getQBooksContext } from './qbooksSelectors';
import { messages } from './validationSelectors.messages';

const { qBooksConstants } = constants;

const validateQBooksPurchaseOrder = (state: State, request: domain.QBooksPoRequest): string[] => {
    let errors: string[] = [];

    const d = request.details;
    const lineItems = getQBooksLineItems(state, request);
    const accountLineItems = getQBooksAccountLineItems(state, request);
    const summary = mergeSummaries(
        getQBooksLineItemsSummary(state, request),
        getQBooksAccountLineItemsSummary(state, request)
    );
    const requiredFields = getRequiredFields(state, request);
    const qbooksContext = getQBooksContext(state);

    let validFields = Boolean(d.vendor && d.date);

    if (
        qbooksContext.hasDepartmentFeature &&
        qbooksContext.departmentField &&
        requiredFields.fieldIds.includes(qbooksContext.departmentField.id)
    ) {
        validFields = validFields && Boolean(d.department);
    }

    let validEmailToVendorTo = true;
    let validEmailToVendorBody = true;
    let validEmailToVendorSubject = true;

    if (d.sendToSupplier) {
        validEmailToVendorTo = Boolean(d.emailToSupplier && d.emailToSupplier.to.length > 0);
        validEmailToVendorBody = Boolean(d.emailToSupplier && d.emailToSupplier.body);
        validEmailToVendorSubject = Boolean(d.emailToSupplier && d.emailToSupplier.subject);

        if (d.emailToSupplier && d.emailToSupplier.to.join(',').length > qBooksConstants.VENDOR_EMAIL_MAX_LENGTH) {
            errors.push(
                messages.emailToVendorLengthExceeded({
                    charactersNumber: qBooksConstants.VENDOR_EMAIL_MAX_LENGTH,
                })
            );
        }

        if (d.emailToSupplier && d.emailToSupplier.cc.join(',').length > qBooksConstants.VENDOR_EMAIL_CC_MAX_LENGTH) {
            errors.push(
                messages.emailToVendorCcLengthExceeded({
                    charactersNumber: qBooksConstants.VENDOR_EMAIL_CC_MAX_LENGTH,
                })
            );
        }
    }

    if (summary.totalAmount < qBooksConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.poNegativeAmountErrorText);
    }

    if (summary.totalAmount > qBooksConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.poExceededAmountErrorText);
    }

    // Assumption: line items are invalid => mandatory fields error
    const allLineItems = [...lineItems, ...accountLineItems];

    let validLineItems = allLineItems.some((li) => !li.empty) && allLineItems.every((li) => li.valid);

    if (!validFields || !validLineItems || !validEmailToVendorBody || !validEmailToVendorSubject) {
        errors.push(messages.mandatoryFieldsErrorText);
    } else if (!validEmailToVendorTo) {
        errors.push(messages.emailToVendorToEmpty);
    }

    if (
        summary.totalAmount <= qBooksConstants.TOTAL_MAX_AMOUNT &&
        summary.totalInDefaultCurrency > qBooksConstants.TOTAL_MAX_AMOUNT
    ) {
        errors.push(
            messages.exceededAmountInCurrencyErrorText({
                currency: summary.defaultCurrency,
            })
        );
    }

    return errors;
};

const validateQBooksBill = (state: State, request: domain.QBooksBillRequest): string[] => {
    let errors: string[] = [];

    const d = request.details;
    const lineItems = getQBooksLineItems(state, request);
    const accountLineItems = getQBooksAccountLineItems(state, request);
    const summary = mergeSummaries(
        getQBooksLineItemsSummary(state, request),
        getQBooksAccountLineItemsSummary(state, request)
    );
    const requiredFields = getRequiredFields(state, request);
    const qbooksContext = getQBooksContext(state);

    let validFields = Boolean(d.vendor && d.date && d.dueDate);

    if (
        qbooksContext.hasDepartmentFeature &&
        qbooksContext.departmentField &&
        requiredFields.fieldIds.includes(qbooksContext.departmentField.id)
    ) {
        validFields = validFields && Boolean(d.department);
    }

    // Assumption: line items are invalid => mandatory fields error
    const allLineItems = [...lineItems, ...accountLineItems];

    let validLineItems = allLineItems.some((li) => !li.empty) && allLineItems.every((li) => li.valid);

    if (!(validFields && validLineItems)) {
        errors.push(messages.mandatoryFieldsErrorText);
    }

    if (summary.totalAmount < qBooksConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.billNegativeAmountErrorText);
    }

    if (summary.totalAmount > qBooksConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.billExceededAmountErrorText);
    }

    if (
        summary.totalAmount <= qBooksConstants.TOTAL_MAX_AMOUNT &&
        summary.totalInDefaultCurrency > qBooksConstants.TOTAL_MAX_AMOUNT
    ) {
        errors.push(
            messages.exceededAmountInCurrencyErrorText({
                currency: summary.defaultCurrency,
            })
        );
    }

    const someUnbillableAccount = allLineItems.some((li) => {
        if ('account' in li && li.account) {
            return li.isBillable && !li.canBeBillable;
        }

        return false;
    });

    if (someUnbillableAccount) {
        errors.push(messages.unbillibleAccount);
    }

    let areBillableLinesIncorrect = !allLineItems.some((li) => li.isBillable && !li.customer);

    if (!areBillableLinesIncorrect) {
        errors.push(messages.customerNotSpecifiedErrorText);
    }

    let areTaxableLinesIncorrect = allLineItems.some((li) => li.isTaxable && !li.isBillable);

    if (areTaxableLinesIncorrect) {
        errors.push(messages.billableNotSpecifiedErrorText);
    }

    return errors;
};

const validateQBooksSalesInvoice = (state: State, request: domain.QBooksInvoiceRequest): string[] => {
    // TODO: qbo sales invoice: implement validation
    let errors: string[] = [];

    const details = request.details;
    const lineItems = getQBooksLineItems(state, request);
    const summary = getQBooksLineItemsSummary(state, request);
    const requiredFields = getRequiredFields(state, request);
    const qbooksContext = getQBooksContext(state);

    let validFields = Boolean(details.customer && details.date);

    if (
        qbooksContext.hasDepartmentFeature &&
        qbooksContext.departmentField &&
        requiredFields.fieldIds.includes(qbooksContext.departmentField.id)
    ) {
        validFields = validFields && Boolean(details.department);
    }

    let validEmailToCustomerTo = true;
    let validEmailToCustomerBody = true;
    let validEmailToCustomerSubject = true;

    if (details.emailToContact?.emailHasToBeSent) {
        validEmailToCustomerTo = Boolean(details.emailToContact && details.emailToContact.to.length > 0);
        validEmailToCustomerBody = Boolean(details.emailToContact && details.emailToContact.body);
        validEmailToCustomerSubject = Boolean(details.emailToContact && details.emailToContact.subject);

        if (
            details.emailToContact &&
            details.emailToContact.to.join(',').length > qBooksConstants.CUSTOMER_EMAIL_MAX_LENGTH
        ) {
            errors.push(
                messages.emailToVendorLengthExceeded({
                    charactersNumber: qBooksConstants.CUSTOMER_EMAIL_MAX_LENGTH,
                })
            );
        }

        if (
            details.emailToContact &&
            details.emailToContact.cc.join(',').length > qBooksConstants.CUSTOMER_EMAIL_CC_MAX_LENGTH
        ) {
            errors.push(
                messages.emailToVendorCcLengthExceeded({
                    charactersNumber: qBooksConstants.CUSTOMER_EMAIL_CC_MAX_LENGTH,
                })
            );
        }
    }

    if (summary.totalAmount < qBooksConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.poNegativeAmountErrorText);
    }

    //
    if (summary.totalAmount > qBooksConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.poExceededAmountErrorText);
    }

    // Assumption: line items are invalid => mandatory fields error
    let validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    if (!validFields || !validLineItems || !validEmailToCustomerBody || !validEmailToCustomerSubject) {
        errors.push(messages.mandatoryFieldsErrorText);
    } else if (!validEmailToCustomerTo) {
        errors.push(messages.emailToVendorToEmpty);
    }

    if (
        summary.totalAmount <= qBooksConstants.TOTAL_MAX_AMOUNT &&
        summary.totalInDefaultCurrency > qBooksConstants.TOTAL_MAX_AMOUNT
    ) {
        errors.push(
            messages.exceededAmountInCurrencyErrorText({
                currency: summary.defaultCurrency,
            })
        );
    }

    return errors;
};

export function getIsRequiredLocation(state: State, request: domain.Request) {
    const requiredFields = getRequiredFields(state, request);
    const qbooksContext = getQBooksContext(state);
    const departmentFieldId = qbooksContext.departmentField?.id;

    return Boolean(
        qbooksContext.hasDepartmentFeature && departmentFieldId && requiredFields.fieldIds.includes(departmentFieldId)
    );
}

export function getIsRequiredPaymentMethod(state: State, request: domain.Request) {
    const requiredFields = getRequiredFields(state, request);
    const qbooksContext = getQBooksContext(state);
    const paymentMethodFieldId = qbooksContext.paymentMethodField?.id;

    return Boolean(paymentMethodFieldId && requiredFields.fieldIds.includes(paymentMethodFieldId));
}

export function getIsRequiredPayee(request: domain.QBooksExpenseRequest) {
    const details = request.details;

    const matrixPositiveAndNegativeExactValuesByPayeeType = [
        backend.IntegrationsQBooksPayeeTypes.Vendor,
        backend.IntegrationsQBooksPayeeTypes.Customer,
        backend.IntegrationsQBooksPayeeTypes.Employee,
    ].reduce(
        (acc, payeeType) => {
            const systemPurposeByPayeeType = {
                [backend.IntegrationsQBooksPayeeTypes.Vendor]: domain.FieldSystemPurpose.QBooksPayeeVendor,
                [backend.IntegrationsQBooksPayeeTypes.Customer]: domain.FieldSystemPurpose.QBooksPayeeCustomer,
                [backend.IntegrationsQBooksPayeeTypes.Employee]: domain.FieldSystemPurpose.QBooksPayeeEmployee,
            };

            const conditions = getConditionsByFieldSystemPurposeOrId(
                request.authorRules,
                systemPurposeByPayeeType[payeeType]
            );

            acc.positive[payeeType] = getPositiveValuesFromConditions(conditions);
            acc.negative[payeeType] = getNegativeValuesFromConditions(conditions);

            return acc;
        },
        {
            positive: {},
            negative: {},
        } as {
            positive: Record<backend.IntegrationsQBooksPayeeTypes, Reference[]>;
            negative: Record<backend.IntegrationsQBooksPayeeTypes, Reference[]>;
        }
    );
    const positiveExactValues = matrixPositiveAndNegativeExactValuesByPayeeType.positive;
    const negativeExactValues = matrixPositiveAndNegativeExactValuesByPayeeType.negative;

    let isRequiredPayeeType = false;
    let isRequiredPayeeId = false;

    if (details.payeeType?.id) {
        const positiveExactValuesByPayeeTypeId = positiveExactValues[details.payeeType.id];
        const positiveExactValuesByPayeeTypeIdHasEmptyValue = positiveExactValuesByPayeeTypeId.some(
            (positiveValue) => positiveValue.id === constants.commonConstants.EMPTY_VALUE_ID
        );
        const negativeExactValuesHasNotEmpty = negativeExactValues[details.payeeType.id].some(
            (exactValue) => exactValue.id === constants.commonConstants.EMPTY_VALUE_ID
        );

        isRequiredPayeeId =
            (Boolean(positiveExactValuesByPayeeTypeId.length) && !positiveExactValuesByPayeeTypeIdHasEmptyValue) ||
            negativeExactValuesHasNotEmpty;
    }

    const isAllPayeeTypesHasMatrixExactValues = Object.values(positiveExactValues).every(
        (exactValues) =>
            exactValues.length && exactValues.every((value) => value.id !== constants.commonConstants.EMPTY_VALUE_ID)
    );
    const isSomeMatrixExactValuesHasNotEmpty = Object.values(positiveExactValues).some(
        (exactValues) => exactValues.length > 2 || exactValues[0]?.id !== constants.commonConstants.EMPTY_VALUE_ID
    );
    const isEveryMatrixNegativeExactValuesHasNotEmpty = Object.values(negativeExactValues).every((exactValues) =>
        exactValues.some((v) => v.id === constants.commonConstants.EMPTY_VALUE_ID)
    );

    if (
        (isAllPayeeTypesHasMatrixExactValues && isSomeMatrixExactValuesHasNotEmpty) ||
        isEveryMatrixNegativeExactValuesHasNotEmpty
    ) {
        isRequiredPayeeType = true;
        isRequiredPayeeId = true;
    }

    return {
        payeeType: isRequiredPayeeType,
        payeeId: isRequiredPayeeId,
    };
}

const validateQBooksExpense = (state: State, request: domain.QBooksExpenseRequest): string[] => {
    const errors: string[] = [];

    const details = request.details;
    const lineItems = getQBooksLineItems(state, request);
    const accountLineItems = getQBooksAccountLineItems(state, request);
    const summary = mergeSummaries(
        getQBooksLineItemsSummary(state, request),
        getQBooksAccountLineItemsSummary(state, request)
    );
    const isRequiredPayee = getIsRequiredPayee(request);
    const isRequiredLocation = getIsRequiredLocation(state, request);
    const isRequiredPaymentMethod = getIsRequiredPaymentMethod(state, request);
    const isValidPayeeType = !isRequiredPayee.payeeType || details.payeeType?.id;
    const isValidPayeeId = !isRequiredPayee.payeeId || details.payee?.id;
    const isValidLocation = !isRequiredLocation || details.department;
    const isValidPaymentMethod = !isRequiredPaymentMethod || details.paymentMethod?.id;

    const isValidFields = Boolean(
        details.paymentAccount?.id &&
            details.date &&
            details.paymentType?.id &&
            isValidPayeeType &&
            isValidPayeeId &&
            isValidLocation &&
            isValidPaymentMethod
    );

    // Assumption: line items are invalid => mandatory fields error
    const allLineItems = [...lineItems, ...accountLineItems];

    let validLineItems = allLineItems.some((li) => !li.empty) && allLineItems.every((li) => li.valid);

    if (!(isValidFields && validLineItems)) {
        errors.push(messages.mandatoryFieldsErrorText);
    }

    if (summary.totalAmount < qBooksConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.expenseNegativeAmountErrorText);
    }

    if (summary.totalAmount > qBooksConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.expenseExceededAmountErrorText);
    }

    if (
        summary.totalAmount <= qBooksConstants.TOTAL_MAX_AMOUNT &&
        summary.totalInDefaultCurrency > qBooksConstants.TOTAL_MAX_AMOUNT
    ) {
        errors.push(
            messages.exceededAmountInCurrencyErrorText({
                currency: summary.defaultCurrency,
            })
        );
    }

    const someUnbillableAccount = allLineItems.some((li) => {
        if ('account' in li && li.account) {
            return li.isBillable && !li.canBeBillable;
        }

        return false;
    });

    if (someUnbillableAccount) {
        errors.push(messages.expenseBillableAccount);
    }

    const areBillableLinesIncorrect = !allLineItems.some((li) => li.isBillable && !li.customer);

    if (!areBillableLinesIncorrect) {
        errors.push(messages.customerNotSpecifiedErrorText);
    }

    const areTaxableLinesIncorrect = allLineItems.some((li) => li.isTaxable && !li.isBillable);

    if (areTaxableLinesIncorrect) {
        errors.push(messages.billableNotSpecifiedErrorText);
    }

    return errors;
};

export function isValidQBOVendorName(vendorName: string) {
    const restrictedCharsRegExp = new RegExp(':|[<>]|(&#)');

    return !restrictedCharsRegExp.test(vendorName);
}

const validateQBooksVendor = (state: State, request: domain.QBooksVendorRequest) => {
    const details = request.details;
    const errors = [];

    if (!details.displayName) {
        errors.push(messages.displayNameNotSpecifiedErrorText);
    }

    if (details.displayName && !isValidQBOVendorName(details.displayName)) {
        errors.push(messages.invalidDisplayNameErrorText);
    }

    if (details.displayName && details.displayName.length > qBooksConstants.DISPLAY_NAME_MAX_LENGTH) {
        errors.push(messages.displayNameLengthErrorText);
    }

    if (details.displayName && /\p{Extended_Pictographic}/u.test(details.displayName)) {
        errors.push(messages.noEmojiInContactName);
    }

    if (details.companyName && details.companyName.length > qBooksConstants.COMPANY_NAME_MAX_LENGTH) {
        errors.push(messages.companyNameLengthErrorText);
    }

    if (details.title && details.title.length > qBooksConstants.TITLE_MAX_LENGTH) {
        errors.push(messages.titleLengthErrorText);
    }

    if (details.firstName && details.firstName.length > qBooksConstants.FIRST_NAME_MAX_LENGTH) {
        errors.push(messages.firstNameLengthErrorText);
    }

    if (details.middleName && details.middleName.length > qBooksConstants.MIDDLE_NAME_MAX_LENGTH) {
        errors.push(messages.middleNameLengthErrorText);
    }

    if (details.lastName && details.lastName.length > qBooksConstants.LAST_NAME_MAX_LENGTH) {
        errors.push(messages.lastNameLengthErrorText);
    }

    if (details.suffix && details.suffix.length > qBooksConstants.SUFFIX_MAX_LENGTH) {
        errors.push(messages.suffixLengthErrorText);
    }

    if (details.emailAddress && details.emailAddress.length > qBooksConstants.EMAIL_ADDRESS_MAX_LENGTH) {
        errors.push(messages.emailAddressLengthErrorText);
    }

    if (details.webSite && details.webSite.length > qBooksConstants.WEBSITE_MAX_LENGTH) {
        errors.push(messages.websiteLengthErrorText);
    }

    if (details.phone && details.phone.length > qBooksConstants.PHONE_MAX_LENGTH) {
        errors.push(messages.phoneLengthErrorText);
    }

    if (details.mobile && details.mobile.length > qBooksConstants.MOBILE_MAX_LENGTH) {
        errors.push(messages.mobileLengthErrorText);
    }

    if (details.fax && details.fax.length > qBooksConstants.FAX_MAX_LENGTH) {
        errors.push(messages.faxLengthErrorText);
    }

    if (details.address.street && details.address.street.length > qBooksConstants.ADDRESS_STREET_MAX_LENGTH) {
        errors.push(messages.addressStreetLengthErrorText);
    }

    if (details.address.city && details.address.city.length > qBooksConstants.ADDRESS_CITY_MAX_LENGTH) {
        errors.push(messages.cityLengthErrorText);
    }

    if (details.address.region && details.address.region.length > qBooksConstants.ADDRESS_REGION_MAX_LENGTH) {
        errors.push(messages.regionLengthErrorText);
    }

    if (details.address.postCode && details.address.postCode.length > qBooksConstants.ADDRESS_POSTCODE_MAX_LENGTH) {
        errors.push(messages.postalCodeLengthErrorText);
    }

    if (details.address.country && details.address.country.length > qBooksConstants.ADDRESS_COUNTRY_MAX_LENGTH) {
        errors.push(messages.countryLengthErrorText);
    }

    if (details.businessNumber && details.businessNumber.length > qBooksConstants.BUSINESS_NUMBER_MAX_LENGTH) {
        errors.push(messages.accountNumberLengthErrorText);
    }

    if (details.paymentDetails) {
        if (!details.paymentDetails.bankAccountName?.trim()) {
            errors.push(messages.isRequiredField({ field: messages.bankAccountName }));
        }

        if (details.paymentDetails.bankBranchIdentifier?.length < qBooksConstants.BANK_BRANCH_IDENTIFIER_MAX_LENGTH) {
            if (!details.paymentDetails.bankBranchIdentifier?.trim()) {
                errors.push(messages.isRequiredField({ field: messages.bankBranchIdentifier }));
            } else {
                errors.push(messages.bsbIncorrectFormat);
            }
        }

        if (!details.paymentDetails.bankAccountNumber?.trim()) {
            errors.push(messages.isRequiredField({ field: messages.bankAccountNumber }));
        }

        if (!details.paymentDetails.statementText?.trim()) {
            errors.push(messages.isRequiredField({ field: messages.statement }));
        }
    }

    if (details.emailAddress && !validators.isEmail(details.emailAddress)) {
        errors.push(messages.wrongEmailErrorText);
    }

    if (details.webSite && !selectors.ui.isValidWebsite(details.webSite)) {
        errors.push(messages.wrongWebsiteErrorText);
    }

    if ([details.phone, details.mobile, details.fax].some((phone) => !!phone && !selectors.ui.isValidPhone(phone))) {
        errors.push(messages.wrongPhoneErrorText);
    }

    return errors;
};

const validateQBooksJournalEntryRequest = (state: State, request: domain.QBooksJournalEntryRequest): string[] => {
    const errors: string[] = [];

    const details = request.details;

    return errors;
};

export const validateQBooksRequest = (state: State, request: domain.QBooksRequest) => {
    switch (request.integrationCode) {
        case domain.IntegrationCode.QBooksPo:
            return validateQBooksPurchaseOrder(state, request);

        case domain.IntegrationCode.QBooksInvoice:
            return validateQBooksSalesInvoice(state, request);

        case domain.IntegrationCode.QBooksBill:
            return validateQBooksBill(state, request);

        case domain.IntegrationCode.QBooksExpense:
            return validateQBooksExpense(state, request);

        case domain.IntegrationCode.QBooksVendor:
            return validateQBooksVendor(state, request);

        case domain.IntegrationCode.QBooksJournalEntry:
            return validateQBooksJournalEntryRequest(state, request);

        default:
            throw errorHelpers.notSupportedError();
    }
};
