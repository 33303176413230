import { Guid, Reference } from '@approvalmax/types';
import { arrayHelpers, errorHelpers, intl, miscHelpers } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { domain } from 'modules/data';
import React from 'react';
import { defineMessages } from 'react-intl';

import AirwallexBenificiaryCreationConditionCell from '../components/card/cells/AirwallexBenificiaryCreationConditionCell/AirwallexBenificiaryCreationConditionCell';
import AmountConditionCell from '../components/card/cells/AmountConditionCell';
import CheckboxCell from '../components/card/cells/CheckboxCell';
import ExactAsyncConditionCell from '../components/card/cells/ExactAsyncConditionCell';
import XeroSupplierEditingConditionCell from '../components/card/cells/XeroSupplierEditingConditionCell/XeroSupplierEditingConditionCell';
import AmountConditionHeaderColumn from '../containers/card/matrix/headers/AmountConditionHeaderColumn';
import ConditionHeaderColumn from '../containers/card/matrix/headers/ConditionHeaderColumn';
import { AccessType, MatrixType } from '../types/matrix';

const i18nPrefix = 'workflows/config/matrixDefinitions.shared';
const messages = defineMessages({
    mandatoryPanelNoteApprovalMatrix: {
        id: `${i18nPrefix}.mandatoryPanelNoteApprovalMatrix`,
        defaultMessage: 'Set the field as mandatory to ensure that Approvers fill it in.',
    },
    mandatoryPanelNoteEditorMatrix: {
        id: `${i18nPrefix}.mandatoryPanelNoteEditorMatrix`,
        defaultMessage: 'Set the field as mandatory to ensure that Reviewers fill it in.',
    },
    mandatoryPanelNoteRequesterMatrix: {
        id: `${i18nPrefix}.mandatoryPanelNoteRequesterMatrix`,
        defaultMessage: 'Set the field as mandatory to ensure that Requesters fill it in.',
    },
});

export interface RenderOptions {
    matrixType: MatrixType;
    integrationCode: domain.IntegrationCode | null;
    field: domain.Field;
    readonly: boolean;
    company: domain.Company;
}

export interface RenderCellOptions {
    matrixType: MatrixType;
    integrationCode: domain.IntegrationCode | null;
    field: domain.Field;
    readonly: boolean;
    rule: domain.MatrixRule;
    condition: domain.MatrixCondition;
    onConditionChange(
        lineId: string,
        rule: domain.MatrixRule,
        field: domain.Field,
        newCondition: domain.MatrixCondition
    ): void;
    lineId: string;
    company: domain.Company;
    templateSubmitters: selectors.types.ExpandedCompanyUser[];
    onCreateFieldOption(field: domain.Field, newOption: Reference): void;
    requiredFieldIds: Guid[];
    noEmptyValue?: boolean;
}

export interface MatrixDefinition {
    columns: Array<{
        systemPurpose: domain.FieldSystemPurpose;
        renderHeader: (options: RenderOptions) => React.ReactNode;
        renderCell: (options: RenderCellOptions) => React.ReactNode;
    }>;
}

export interface InternalMatrixDefinitionMap {
    [integrationCode: string]: {
        [matrixType: string]: MatrixDefinition | null;
    };
}

export function renderDefaultHeader(
    { field, readonly, matrixType }: RenderOptions,
    config?: {
        fieldName?: string;
    }
) {
    let fieldName = field.name;

    if (config && config.fieldName) {
        fieldName = config.fieldName;
    }

    return (
        <ConditionHeaderColumn fieldId={field.id} fieldName={fieldName} readonly={readonly} matrixType={matrixType} />
    );
}

export function renderAmountHeader({ integrationCode, readonly, company }: RenderOptions) {
    const currencyText = statics.currency.getCurrencyShortText(company.defaultCurrency);
    const canChangeAmount =
        integrationCode !== domain.IntegrationCode.XeroBillBatchPayment &&
        integrationCode !== domain.IntegrationCode.XeroAirwallexBatchPayment &&
        integrationCode !== domain.IntegrationCode.XeroManualJournal;

    return (
        <AmountConditionHeaderColumn currency={currencyText} canChangeAmount={canChangeAmount} readonly={readonly} />
    );
}

export function renderHeaderWithAccessType(
    { field, readonly, company, matrixType }: RenderOptions,
    config: {
        accessTypeOptions: AccessType[];
        defaultAccessType: AccessType;
    }
) {
    const { defaultAccessType, accessTypeOptions } = config;

    let mandatoryPanelNote;

    switch (matrixType) {
        case MatrixType.Approval:
            mandatoryPanelNote = intl.formatMessage(messages.mandatoryPanelNoteApprovalMatrix);
            break;

        case MatrixType.Editor:
            mandatoryPanelNote = intl.formatMessage(messages.mandatoryPanelNoteEditorMatrix);
            break;

        case MatrixType.Requester:
            mandatoryPanelNote = intl.formatMessage(messages.mandatoryPanelNoteRequesterMatrix);
            break;

        case MatrixType.AutoApproval:
            mandatoryPanelNote = intl.formatMessage(messages.mandatoryPanelNoteRequesterMatrix);
            break;

        default:
            throw errorHelpers.assertNever(matrixType);
    }

    return (
        <ConditionHeaderColumn
            fieldId={field.id}
            fieldName={field.name}
            readonly={readonly}
            defaultAccessType={defaultAccessType}
            accessTypeOptions={accessTypeOptions}
            hasAccessTypeBlock
            accessTypeNote={mandatoryPanelNote}
            matrixType={matrixType}
        />
    );
}

interface RenderAmountCellOptions extends RenderCellOptions {
    minValue?: number;
    maxValue?: number;
    showCurrency?: boolean;
}

export function renderAmountCell({
    rule,
    field,
    readonly,
    integrationCode,
    lineId,
    condition,
    onConditionChange,
    company,
    minValue,
    maxValue,
    showCurrency = true,
}: RenderAmountCellOptions) {
    return (
        <AmountConditionCell
            rule={rule}
            field={field}
            readonly={readonly}
            integrationCode={integrationCode}
            lineId={lineId}
            onConditionChange={onConditionChange}
            condition={condition as domain.NumericRangeCondition}
            companyDefaultCurrency={company.defaultCurrency}
            minValue={minValue}
            maxValue={maxValue}
            showCurrency={showCurrency}
        />
    );
}

export function renderExactAsyncCell({
    rule,
    field,
    readonly,
    integrationCode,
    matrixType,
    lineId,
    condition,
    onConditionChange,
    company,
    templateSubmitters,
    requiredFieldIds,
    noEmptyValue,
}: RenderCellOptions) {
    return (
        <ExactAsyncConditionCell
            rule={rule}
            field={field}
            readonly={readonly}
            integrationCode={integrationCode}
            lineId={lineId}
            onConditionChange={onConditionChange}
            condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
            templateSubmitters={matrixType !== MatrixType.Requester ? templateSubmitters : arrayHelpers.emptyArray()}
            requiredFieldIds={requiredFieldIds}
            matrixType={matrixType}
            noEmptyValue={Boolean(noEmptyValue)}
        />
    );
}

export function getFilteredDefinitions(
    matrixDefinitions: InternalMatrixDefinitionMap,
    options: {
        integrationCode: domain.IntegrationCode | null;
        matrixType: MatrixType;
    }
): MatrixDefinition {
    const def = matrixDefinitions[options.integrationCode || 'null'][options.matrixType];

    if (typeof def === 'undefined') {
        throw errorHelpers.notSupportedError(
            `Matrix definition not found for integration code ${options.integrationCode} (matrixType ${options.matrixType})`
        );
    }

    return {
        columns: def !== null ? def.columns.filter(miscHelpers.notEmptyFilter) : [],
    };
}

interface RenderBoxCellOptions extends RenderCellOptions {
    label: string;
}

export function renderBoxCell(options: RenderBoxCellOptions) {
    return <CheckboxCell {...options} />;
}

export function renderXeroSupplierEditingConditionCell(options: RenderBoxCellOptions) {
    return <XeroSupplierEditingConditionCell {...options} />;
}

export function renderAirwallexBenificaryCreationConditionCell(options: RenderBoxCellOptions) {
    return <AirwallexBenificiaryCreationConditionCell {...options} />;
}
