import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseTwoFaDisablingVerifyAppCodeRequest,
    UseTwoFaDisablingVerifyAppCodeResponse,
} from './useTwoFaDisablingVerifyAppCode.types';

export const useTwoFaDisablingVerifyAppCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseTwoFaDisablingVerifyAppCodeRequest>,
        UseTwoFaDisablingVerifyAppCodeResponse
    >(twoFaApiPaths.disablingVerifyAppCode);
};
