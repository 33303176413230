import { defineMessages } from 'react-intl';

const i18nPrefix = 'workflows/components/card/cells/XeroSupplierEditingConditionCell';

export const messages = defineMessages({
    allowToEditBankAccount: {
        id: `${i18nPrefix}.allowToEditBankAccount`,
        defaultMessage: 'Allow to edit bank account',
    },
});
