import { Popup } from '@approvalmax/ui';
import { Popup as NewPopup } from '@approvalmax/ui/src/components';
import { useDispatch, useSelector } from 'modules/react-redux';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { closeActivePopup } from './actions';
import { BulkCreationPopupContent } from './components/xero/BulkCreationPopupContent/BulkCreationPopupContent';
import { bulkCreationPopupOpenState } from './components/xero/BulkCreationPopupContent/BulkCreationPopupContent.states';
import { OcrNotificationPopupContent } from './components/xero/OcrNotificationPopupContent/OcrNotificationPopupContent';
import { ocrNotificationPopupOpenState } from './components/xero/OcrNotificationPopupContent/OcrNotificationPopupContent.states';
import NewRequestPopup from './containers/NewRequestPopup';
import { ActivePopup } from './reducers/moduleReducer';
import { getActivePopup } from './selectors/moduleSelectors';

export const ModuleComponent = () => {
    const activePopup = useSelector(getActivePopup);
    const dispatch = useDispatch();

    const [bulkCreationPopupOpen, setBulkCreationPopupOpen] = useRecoilState(bulkCreationPopupOpenState);
    const [ocrNotificationPopupOpen, setOcrNotificationPopupOpen] = useRecoilState(ocrNotificationPopupOpenState);

    const closeBulkCreationPopup = useCallback(() => {
        setBulkCreationPopupOpen(false);
    }, [setBulkCreationPopupOpen]);

    const onToggleBulkCreationPopup = useCallback(
        (open: boolean) => {
            !open && setBulkCreationPopupOpen(false);
        },
        [setBulkCreationPopupOpen]
    );

    const onToggleOcrFailedPopup = useCallback(
        (open: boolean) => {
            !open && setOcrNotificationPopupOpen(false);
        },
        [setOcrNotificationPopupOpen]
    );

    const onRequestClose = useCallback(() => {
        dispatch(closeActivePopup());
    }, [dispatch]);

    return (
        <>
            <Popup isOpen={activePopup === ActivePopup.NewRequest} onRequestClose={onRequestClose}>
                <NewRequestPopup />
            </Popup>

            <NewPopup size='large' open={bulkCreationPopupOpen} closable onToggle={onToggleBulkCreationPopup}>
                <BulkCreationPopupContent closePopup={closeBulkCreationPopup} />
            </NewPopup>

            <NewPopup size='small' open={ocrNotificationPopupOpen} closable onToggle={onToggleOcrFailedPopup}>
                <OcrNotificationPopupContent />
            </NewPopup>
        </>
    );
};

ModuleComponent.displayName = 'ModuleComponent';
