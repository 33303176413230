import { DotsFilledIcon, ErrorLogo, FetchIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, Menu, Progress, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { integrationActions, IntegrationSuccessRedirectPage } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import IntegrationSyncDialog from '../IntegrationSyncDialog/IntegrationSyncDialog';
import { getAdjustedIntegrationState } from './IntegrationHeader.helpers';
import { useCompanyLink, useErrorLink, useIntegrationParams } from './IntegrationHeader.hooks';
import { messages } from './IntegrationHeader.messages';
import { IntegrationHeaderProps } from './IntegrationHeader.types';

const IntegrationHeader = memo<IntegrationHeaderProps>((props) => {
    const { company, finalRedirectToPage = 'workflow', showSyncButton } = props;

    const dispatch = useDispatch();
    const workflows = useSelector((state) => selectors.template.getIntegrationTemplatesByCompanyId(state, company.id));
    const integration = useSelector((state) => selectors.integration.getIntegrationByCompanyId(state, company.id));
    const { isDisconnected, isConnecting, isConnected, isDisconnecting, isConnectingFinalizing } =
        getAdjustedIntegrationState(selectors.integration.getExplainedIntegrationState(integration), workflows);
    const canUpdateCompanySettings = useSelector((state) => selectors.company.canUpdateCompanySettings(state, company));

    const readOnly = !canUpdateCompanySettings || company.isReadonly;
    const progress = isConnecting || isConnectingFinalizing || isDisconnecting;
    const disconnected = isDisconnected || isConnecting;

    const { Icon, title, descriptionConnecting } = useIntegrationParams({ integration, disconnected });
    const errorLink = useErrorLink({ integration });
    const companyLink = useCompanyLink({ integration });

    const description = isConnectingFinalizing
        ? descriptionConnecting
        : isConnected
          ? messages.connected({ company: companyLink })
          : isDisconnecting
            ? messages.disconnecting({ company: companyLink })
            : messages.disconnected({ company: companyLink });

    const onDisconnect = useCallback(() => {
        dispatch(integrationActions.disconnectFromIntegration(company.id));
    }, [company.id, dispatch]);

    const onReconnect = useCallback(() => {
        if (!integration) return;

        switch (integration.integrationType) {
            case domain.IntegrationType.NetSuite:
                dispatch(
                    integrationActions.showNetSuiteIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );
                break;

            case domain.IntegrationType.Dear:
                dispatch(
                    integrationActions.showDearIntegrationPopup(company.id, IntegrationSuccessRedirectPage.Workflow)
                );
                break;

            default:
                dispatch(
                    integrationActions.connectToIntegration({
                        integrationType: integration.integrationType,
                        companyId: company.id,
                        showGlobalProgress: false,
                        finalRedirectToPage,
                    })
                );
        }
    }, [company.id, dispatch, finalRedirectToPage, integration]);

    if (!integration || integration.integrationType === domain.IntegrationType.None) return null;

    const isIntegrationTypeXero = integration.integrationType === domain.IntegrationType.Xero;

    const isIntegrationTypeNetSuiteAndHasRefDataCache =
        integration.integrationType === domain.IntegrationType.NetSuite &&
        company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteRefDataCache);

    const showIntegrationSyncButton =
        showSyncButton &&
        !disconnected &&
        !company.isReadonly &&
        (isIntegrationTypeXero || isIntegrationTypeNetSuiteAndHasRefDataCache);

    return (
        <>
            <Box spacing='24' color={progress ? 'silver80' : 'transparent'} radius='small'>
                <Flex inline spacing='12' wrap={false} alignItems='center'>
                    <Flex shrink={0}>{integration.error ? <ErrorLogo size={40} /> : Icon && <Icon size={40} />}</Flex>

                    <Flex inline direction='column' spacing='4' grow={1}>
                        <Text font='label' fontSize='large' as='h3' color={progress ? 'midnight70' : 'midnight80'}>
                            {title}
                        </Text>

                        <Text
                            font='label'
                            fontSize='small'
                            color={progress ? 'midnight70' : 'midnight100'}
                            data-starter-guide='choose_wf_type_gl'
                        >
                            {integration.error
                                ? messages.error({
                                      br: <br />,
                                      company: companyLink,
                                      reason: integration.error.description,
                                  })
                                : description}

                            {errorLink && (
                                <a href={errorLink} target='_blank' rel='noopener noreferrer'>
                                    {messages.learnMore}
                                </a>
                            )}
                        </Text>
                    </Flex>

                    {showIntegrationSyncButton && (
                        <Dropdown
                            width='max-content'
                            height='max-content'
                            placement='bottom-end'
                            activator={
                                <Button icon noPadding>
                                    <FetchIcon size={24} />
                                </Button>
                            }
                        >
                            <IntegrationSyncDialog company={company} />
                        </Dropdown>
                    )}

                    {disconnected && (
                        <Button
                            onClick={onReconnect}
                            color={integration.error ? 'red40' : 'blue80'}
                            disabled={progress || readOnly}
                            data-qa='wfl-int-card-normal-header__reconnect-button'
                        >
                            {messages.reconnectButton}
                        </Button>
                    )}

                    {!disconnected && !company.isReadonly && (
                        <Dropdown
                            width={110}
                            placement='bottom-end'
                            activator={
                                <Button data-qa='wfl-int-card-normal-header__action-menu' icon noPadding>
                                    <DotsFilledIcon size={24} />
                                </Button>
                            }
                        >
                            <Menu size='small'>
                                <Menu.Item
                                    name={messages.disconnectButton}
                                    onClick={onDisconnect}
                                    data-qa='wfl-int-card-normal-header__action-item-disconnect'
                                />
                            </Menu>
                        </Dropdown>
                    )}
                </Flex>
            </Box>

            {progress && <Progress size='small' />}
        </>
    );
});

export default IntegrationHeader;
