import { QueryOptions } from '@approvalmax/data';
import { requestsApiPaths, useGetRequest } from 'shared/data';

import {
    UseGetRequestDuplicatesQbooksBillRespone,
    UseGetRequestDuplicatesQbooksParams,
    UseGetRequestDuplicatesQbooksPathParams,
} from './useGetRequestDuplicatesQbooks.types';

export const useGetRequestDuplicatesQbooks = (
    pathParams: UseGetRequestDuplicatesQbooksPathParams,
    params: UseGetRequestDuplicatesQbooksParams,
    queryOptions?: QueryOptions<UseGetRequestDuplicatesQbooksBillRespone>
) => {
    return useGetRequest<UseGetRequestDuplicatesQbooksBillRespone>(requestsApiPaths.duplicatesQbookPO, {
        pathParams,
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
