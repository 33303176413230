import Dropdown from '@approvalmax/ui/src/components/Dropdown/Dropdown';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes, useMemo } from 'react';

import { Controller } from './components';
import { useCalendarValue, useCallbackActions, useDatePickerValue, useTextFieldValue } from './DatePicker.hooks';
import { Activator, Calendar, Icon } from './DatePicker.styles';
import { ChildrenComponents, DatePickerProps } from './DatePicker.types';

/**
 * DatePicker allows you to select a date in an easy-to-use interface
 */
const DatePicker = memo(
    forwardRef((props, ref) => {
        const {
            placement = 'bottom-start',
            onOpen,
            initOpen,
            value,
            onChange,
            onFocus,
            onBlur,
            onClear,
            initFocus,
            view,
            clearable = true,
            minDate,
            maxDate,
            noUTCTransform,
            ...restProps
        } = props;

        const { datePickerValue, changeDatePickerValue } = useDatePickerValue({ value, noUTCTransform });
        const { calendarValue } = useCalendarValue({ datePickerValue });
        const { textFieldValue, handleTextFieldChange } = useTextFieldValue({
            datePickerValue,
            changeDatePickerValue,
            view,
            minDate,
            maxDate,
            noUTCTransform,
        });
        const { handleOnOpen, isOpen, handleOnFocus, handleIconClick, isFocus, handleOnBlur, handleOnClear } =
            useCallbackActions({
                onOpen,
                onChange,
                onFocus,
                onBlur,
                onClear,
                initOpen,
                initFocus,
                datePickerValue,
                changeDatePickerValue,
                view,
                noUTCTransform,
            });

        const activator = useMemo(
            () => (
                <Activator
                    {...restProps}
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onClear={handleOnClear}
                    focus={isFocus}
                    clearable={clearable}
                    readOnly={view === 'year'}
                    endIcon={
                        textFieldValue && clearable ? undefined : <Icon onClick={handleIconClick} color='midnight70' />
                    }
                    ref={ref}
                />
            ),
            [
                restProps,
                textFieldValue,
                handleTextFieldChange,
                handleOnFocus,
                handleOnBlur,
                handleIconClick,
                handleOnClear,
                isFocus,
                clearable,
                view,
                ref,
            ]
        );

        return (
            <Dropdown
                activator={activator}
                placement={placement}
                open={isOpen}
                onOpen={handleOnOpen}
                maxHeight={0}
                width='max-content'
                isClicking={false}
            >
                <Calendar
                    onChange={changeDatePickerValue}
                    value={calendarValue}
                    locale='en'
                    maxDetail={view}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </Dropdown>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<DatePickerProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

DatePicker.Controller = Controller;

export default DatePicker;
