import { Box } from '@approvalmax/ui/src/components';
import { saveTemplate } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/actions/template';
import { WorkflowDetails } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/components';
import { setWorkflowWizardPreview } from 'modules/common/actions/ui';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import { messages } from './PreviewStep.messages';
import { PreviewStepProps } from './PreviewStep.types';

export const PreviewStep: FC<PreviewStepProps> = memo((props) => {
    const { template, amplitudeParams, onClose } = props;

    const dispatch = useDispatch();

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: preview step', amplitudeParams);
    });

    const handleSave = useCallback(() => {
        onClose();
        dispatch(saveTemplate(template));
        amplitudeService.sendData(
            'Workflow: land on wizard: preview step: clicked on save without activating',
            amplitudeParams
        );
    }, [amplitudeParams, dispatch, onClose, template]);

    const handleActivate = useCallback(() => {
        onClose();
        dispatch(
            saveTemplate(template, {
                enabled: true,
            })
        );
        amplitudeService.sendData(
            'Workflow: land on wizard: preview step: clicked on save and activate',
            amplitudeParams
        );
    }, [amplitudeParams, dispatch, onClose, template]);

    useEffect(() => {
        dispatch(setWorkflowWizardPreview(true));

        return () => {
            dispatch(setWorkflowWizardPreview(false));
        };
    }, [dispatch]);

    return (
        <ScrollableContent
            header={<HeaderTitle spacing='32 0' title={messages.title} subtitle={messages.subtitle} />}
            footer={
                <FooterButtons
                    onNext={handleActivate}
                    onBack={handleSave}
                    nextButtonText={messages.saveAndActivate}
                    backButtonText={messages.saveWithoutActivating}
                />
            }
        >
            <Box width='max-content'>
                <WorkflowDetails />
            </Box>
        </ScrollableContent>
    );
});

PreviewStep.displayName = 'PreviewStep';
