import { Mods } from '@approvalmax/theme';
import { domain } from 'modules/data';

export enum SsoPanelVariant {
    login = 'login',
    register = 'register',
}

export interface SsoPanelProps extends Mods<'disabled'> {
    variant: keyof typeof SsoPanelVariant;
    app?: domain.SsoLoginApplication;
    afterLoginRedirectUrl?: string;
    afterSignUpRedirectUrl?: string;
    shouldReturnToCurrentPage?: boolean;
    allowedEmail?: string;
}
