/**
 * Developer: Stepan Burguchev
 * Date: 12/6/2016
 * Copyright: 2015-2016 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { stringHelpers } from '@approvalmax/utils';

import { ErrorType } from '../const';
import { messages } from './messages';

export default function email(value: any) {
    let error = {
        type: ErrorType.Email,
        message: messages.requiredField,
    };

    if (!value) {
        return undefined;
    }

    if (!stringHelpers.parseEmail(value)) {
        return error;
    }

    return undefined;
}

export function isEmail(value: any) {
    return value && !email(value);
}
