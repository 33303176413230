/**
 * Developer: Stepan Burguchev
 * Date: 2/16/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers, intl } from '@approvalmax/utils';
import map from 'lodash/map';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';

import { getMatrixDefinition } from '../config/matrixDefinitions';
import { MatrixDefinition } from '../config/matrixDefinitions.shared';
import { MatrixType } from '../types/matrix';
import { ExpandedTemplateUser } from '../types/selectors';
import { getActiveTemplate, getPage, getPureActiveMatrix } from './pageSelectors';
import { sortConditions } from './stepSelectors';

const i18nPrefix = 'workflows.selectors.templateSelectors';

const messages = defineMessages({
    submitter_rules_preview_field_not_value_pattern: {
        id: `${i18nPrefix}.submitter_rules_preview_field_not_value_pattern`,
        defaultMessage: '"{value}"',
    },

    submitter_rules_preview_field_value_pattern: {
        id: `${i18nPrefix}.submitter_rules_preview_field_value_pattern`,
        defaultMessage: '"{value}"',
    },

    submitter_rules_preview_not_field_pattern: {
        id: `${i18nPrefix}.submitter_rules_preview_not_field_pattern`,
        defaultMessage: '{fieldName} that does not match {values}',
    },

    submitter_rules_preview_field_pattern: {
        id: `${i18nPrefix}.submitter_rules_preview_field_pattern`,
        defaultMessage: '{fieldName} that matches {values}',
    },

    submitter_rules_preview_submits_with: {
        id: `${i18nPrefix}.submitter_rules_preview_submits_with`,
        defaultMessage: 'can submit with {rules}',
    },

    supplierFieldAnyContactText: {
        id: `${i18nPrefix}.supplierFieldAnyContactText`,
        defaultMessage: 'any existing Contact',
    },

    submitter_rules_preview_always_submit: {
        id: `${i18nPrefix}.submitter_rules_preview_always_submit`,
        defaultMessage: 'without any restrictions',
    },

    supplierFieldAnyContactOrNewText: {
        id: `${i18nPrefix}.supplierFieldAnyContactOrNewText`,
        defaultMessage: 'any existing or new Contact',
    },

    supplierFieldAnySupplierText: {
        id: `${i18nPrefix}.supplierFieldAnySupplierText`,
        defaultMessage: 'any Contact marked as supplier',
    },

    submitter_rules_preview_and_text: {
        id: `${i18nPrefix}.submitter_rules_preview_and_text`,
        defaultMessage: '{left} and {right}',
    },

    submitter_rules_preview_or_text: {
        id: `${i18nPrefix}.submitter_rules_preview_or_text`,
        defaultMessage: '{left} or {right}',
    },

    vendorFieldAnyText: {
        id: `${i18nPrefix}.vendorFieldAnyText`,
        defaultMessage: 'any existing Vendor',
    },

    vendorFieldAnyOrNewText: {
        id: `${i18nPrefix}.vendorFieldAnyOrNewText`,
        defaultMessage: 'any existing or new Vendor',
    },

    beneficiaryFieldAnyText: {
        id: `${i18nPrefix}.beneficiaryFieldAnyText`,
        defaultMessage: 'any existing Beneficiary',
    },

    beneficiaryFieldAnyOrNewText: {
        id: `${i18nPrefix}.beneficiaryFieldAnyOrNewText`,
        defaultMessage: 'any existing or new Beneficiary',
    },
});

export function getAdjustedIntegrationState(
    explainedState: selectors.types.IntegrationState,
    templates: domain.Template[]
): selectors.types.IntegrationState {
    // Integration card should treat empty templates list as a sign that loading is still in progress
    // It means that templates list is being reloaded after successful integration
    if (explainedState.isConnectedFirstTime && (!templates || templates.length === 0)) {
        explainedState = {
            ...explainedState,
            isConnecting: false,
            isConnectingFinalizing: true,
            isConnected: false,
            isConnectedFirstTime: false,
        };
    }

    return explainedState;
}

const manualRateFields = [
    domain.FieldSystemPurpose.XeroManualExchangeRate,
    domain.FieldSystemPurpose.QBooksManualExchangeRate,
];

const qbooksPayeeFields = [
    domain.FieldSystemPurpose.QBooksPayeeCustomer,
    domain.FieldSystemPurpose.QBooksPayeeEmployee,
    domain.FieldSystemPurpose.QBooksPayeeVendor,
];

function getSubmitterRulesPreviewText(
    integrationCode: domain.IntegrationCode | null,
    rules: domain.MatrixRule[],
    matrixDefinition: MatrixDefinition
) {
    const showCustomSupplierText =
        integrationCode && [domain.IntegrationCode.XeroPo, domain.IntegrationCode.XeroBill].includes(integrationCode);

    const showCustomVendorText =
        integrationCode &&
        [domain.IntegrationCode.QBooksBill, domain.IntegrationCode.QBooksPo].includes(integrationCode);

    const showCustomNetSuiteVendorText =
        integrationCode &&
        [domain.IntegrationCode.NetSuiteBill, domain.IntegrationCode.NetSuitePO].includes(integrationCode);

    const showCustomBeneficiaryText =
        integrationCode && [domain.IntegrationCode.XeroAirwallexBatchPayment].includes(integrationCode);

    const conditionText = (condition: domain.MatrixCondition, conditionPosition?: number) => {
        if (manualRateFields.includes(condition.fieldSystemPurpose)) {
            return conditionPosition && conditionPosition !== 0
                ? 'with a manual exchange rate'
                : 'a manual exchange rate';
        }

        const isNegativeExactConstraint = condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;
        const fvpPattern = isNegativeExactConstraint
            ? messages.submitter_rules_preview_field_not_value_pattern
            : messages.submitter_rules_preview_field_value_pattern;

        let values: Reference[] = (condition as any).exactValues || [];
        let valuesText: string;

        if (values.length === 0) {
            return '';
        }

        if (values.length < 3) {
            valuesText = map(values, (fcv) => intl.formatMessage(fvpPattern, { value: fcv.text })).join(', ');
        } else {
            const v1 = intl.formatMessage(fvpPattern, { value: values[0].text });
            const v2 = intl.formatMessage(fvpPattern, { value: values[1].text });

            valuesText = `${v1}, ${v2} ...`;
        }

        return isNegativeExactConstraint
            ? intl.formatMessage(messages.submitter_rules_preview_not_field_pattern, {
                  fieldName: condition.fieldName,
                  values: valuesText,
              })
            : intl.formatMessage(messages.submitter_rules_preview_field_pattern, {
                  fieldName: condition.fieldName,
                  values: valuesText,
              });
    };

    if (!rules || !rules.length) {
        if (showCustomSupplierText) {
            return intl.formatMessage(messages.submitter_rules_preview_submits_with, {
                rules: intl.formatMessage(messages.supplierFieldAnyContactText),
            });
        }

        if (showCustomVendorText) {
            return intl.formatMessage(messages.submitter_rules_preview_submits_with, {
                rules: intl.formatMessage(messages.vendorFieldAnyText),
            });
        }

        if (showCustomNetSuiteVendorText) {
            return intl.formatMessage(messages.submitter_rules_preview_submits_with, {
                rules: intl.formatMessage(messages.vendorFieldAnyText),
            });
        }

        if (showCustomBeneficiaryText) {
            return intl.formatMessage(messages.submitter_rules_preview_submits_with, {
                rules: intl.formatMessage(messages.beneficiaryFieldAnyText),
            });
        }

        return intl.formatMessage(messages.submitter_rules_preview_always_submit);
    }

    let res = '';

    rules.forEach((rule) => {
        let rtext: string | null = null;

        if (showCustomSupplierText) {
            const supplierCondition = rule.conditions.find(
                (c) => c.fieldSystemPurpose === domain.FieldSystemPurpose.XeroSupplier
            );

            if (!supplierCondition) {
                rtext = intl.formatMessage(messages.supplierFieldAnyContactText);
            } else {
                switch (supplierCondition.conditionType) {
                    case null:
                        rtext = intl.formatMessage(messages.supplierFieldAnyContactText);
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (supplierCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                rtext = supplierCondition.allowCreation
                                    ? intl.formatMessage(messages.supplierFieldAnyContactOrNewText)
                                    : intl.formatMessage(messages.supplierFieldAnyContactText);
                                break;

                            case domain.ServerConditionType.SuppliersOnly:
                                rtext = intl.formatMessage(messages.supplierFieldAnySupplierText);
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError();
                        }

                        break;

                    case domain.ConditionType.ExactValuesCondition:
                    case domain.ConditionType.NegativeExactValuesCondition:
                        rtext = conditionText(supplierCondition);
                        break;

                    default:
                        errorHelpers.throwInvalidOperationError();
                }
            }
        }

        if (showCustomVendorText) {
            const vendorCondition = rule.conditions.find(
                (c) => c.fieldSystemPurpose === domain.FieldSystemPurpose.QBooksVendor
            );

            if (!vendorCondition) {
                rtext = intl.formatMessage(messages.vendorFieldAnyText);
            } else {
                switch (vendorCondition.conditionType) {
                    case null:
                        rtext = intl.formatMessage(messages.vendorFieldAnyText);
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (vendorCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                rtext = vendorCondition.allowCreation
                                    ? intl.formatMessage(messages.vendorFieldAnyOrNewText)
                                    : intl.formatMessage(messages.vendorFieldAnyText);
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError();
                        }

                        break;

                    case domain.ConditionType.ExactValuesCondition:
                    case domain.ConditionType.NegativeExactValuesCondition:
                        rtext = conditionText(vendorCondition);
                        break;

                    default:
                        errorHelpers.throwInvalidOperationError();
                }
            }
        }

        if (showCustomBeneficiaryText) {
            const beneficiaryCondition = rule.conditions.find(
                (c) => c.fieldSystemPurpose === domain.FieldSystemPurpose.AirwallexBeneficiary
            );

            if (!beneficiaryCondition) {
                rtext = intl.formatMessage(messages.beneficiaryFieldAnyText);
            } else {
                switch (beneficiaryCondition.conditionType) {
                    case null:
                        rtext = intl.formatMessage(messages.beneficiaryFieldAnyText);
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (beneficiaryCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                rtext = beneficiaryCondition.allowCreation
                                    ? intl.formatMessage(messages.beneficiaryFieldAnyOrNewText)
                                    : intl.formatMessage(messages.beneficiaryFieldAnyText);
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError(
                                    'Unsupported server condition type for field with system purpose "Airwallex Beneficiary"'
                                );
                        }

                        break;

                    default:
                        errorHelpers.throwInvalidOperationError(
                            'Unsupported condition type for field with system purpose "Airwallex Beneficiary"'
                        );
                }
            }
        }

        if (showCustomNetSuiteVendorText) {
            const vendorCondition = rule.conditions.find(
                (condition) => condition.fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteVendor
            );

            if (!vendorCondition) {
                rtext = intl.formatMessage(messages.vendorFieldAnyText);
            } else {
                switch (vendorCondition.conditionType) {
                    case null:
                        rtext = intl.formatMessage(messages.vendorFieldAnyText);
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (vendorCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                rtext = vendorCondition.allowCreation
                                    ? intl.formatMessage(messages.vendorFieldAnyOrNewText)
                                    : intl.formatMessage(messages.vendorFieldAnyText);
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError();
                        }

                        break;

                    case domain.ConditionType.ExactValuesCondition:
                    case domain.ConditionType.NegativeExactValuesCondition:
                        rtext = conditionText(vendorCondition);
                        break;

                    default:
                        errorHelpers.throwInvalidOperationError();
                }
            }
        }

        const conditions = sortConditions(rule.conditions, matrixDefinition);

        conditions.forEach((condition, index) => {
            if (showCustomSupplierText && condition.fieldSystemPurpose === domain.FieldSystemPurpose.XeroSupplier) {
                return;
            }

            if (showCustomVendorText && condition.fieldSystemPurpose === domain.FieldSystemPurpose.QBooksVendor) {
                return;
            }

            if (
                showCustomNetSuiteVendorText &&
                condition.fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteVendor
            ) {
                return;
            }

            let ctext = conditionText(condition, index);

            let connectionMessage = messages.submitter_rules_preview_and_text;

            if (integrationCode === domain.IntegrationCode.QBooksExpense) {
                const prevCondition = index > 0 ? conditions[index - 1] : undefined;

                if (
                    prevCondition &&
                    qbooksPayeeFields.includes(condition.fieldSystemPurpose) &&
                    qbooksPayeeFields.includes(prevCondition.fieldSystemPurpose)
                ) {
                    connectionMessage = messages.submitter_rules_preview_or_text;
                }
            }

            if (ctext) {
                rtext = rtext
                    ? intl.formatMessage(connectionMessage, {
                          left: rtext,
                          right: ctext,
                      })
                    : ctext;
            }
        });

        if (rtext) {
            res = res
                ? intl.formatMessage(messages.submitter_rules_preview_or_text, {
                      left: res,
                      right: rtext,
                  })
                : rtext;
        }
    });

    if (!res && showCustomSupplierText) {
        res = intl.formatMessage(messages.supplierFieldAnyContactText);
    }

    if (!res && showCustomVendorText) {
        res = intl.formatMessage(messages.vendorFieldAnyText);
    }

    if (!res && showCustomNetSuiteVendorText) {
        res = intl.formatMessage(messages.vendorFieldAnyText);
    }

    res = res
        ? intl.formatMessage(messages.submitter_rules_preview_submits_with, {
              rules: res,
          })
        : intl.formatMessage(messages.submitter_rules_preview_always_submit);

    return res;
}

export const getTemplateSubmitters: (state: State, companyId: string) => ExpandedTemplateUser[] = createSelector(
    (state: State) => getActiveTemplate(state)!.integrationCode,
    (state: State) => getActiveTemplate(state)!.submitterMatrix,
    (state: State, companyId: string) => selectors.company.getCompanyById(state, companyId),
    selectors.user.getUsers,
    (
        integrationCode: domain.IntegrationCode,
        submitterMatrix: domain.Template['submitterMatrix'],
        company,
        users: ExpandedTemplateUser[]
    ) => {
        const matrixDefinition = getMatrixDefinition({
            integrationCode,
            betaFeatures: company.betaFeatures,
            matrixType: MatrixType.Requester,
        });

        return arrayHelpers.arraySort(
            submitterMatrix.map((x) => {
                const user = users.find((u) => u.id === x.lineId);

                if (!user) {
                    throw errorHelpers.notFoundError();
                }

                return {
                    ...user,
                    rulesPreviewText: getSubmitterRulesPreviewText(integrationCode, x.rules, matrixDefinition),
                };
            }),
            (u1: ExpandedTemplateUser, u2: ExpandedTemplateUser) =>
                compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
);

export const getTemplateAirwallexPayers: (state: State, companyId: string) => ExpandedTemplateUser[] = createSelector(
    (state: State) => getActiveTemplate(state)!.integrationCode,
    (state: State) => getActiveTemplate(state)!.payerMatrix,
    (state: State, companyId: string) => selectors.company.getCompanyById(state, companyId),
    selectors.user.getUsers,
    (
        integrationCode: domain.IntegrationCode,
        payerMatrix: domain.Template['payerMatrix'],
        company,
        users: ExpandedTemplateUser[]
    ) => {
        if (!payerMatrix) {
            return [];
        }

        const unsortedAirwallexPayers = payerMatrix.map((x) => {
            const user = users.find((u) => u.id === x.lineId);

            if (!user) {
                throw errorHelpers.notFoundError();
            }

            return user;
        });

        return arrayHelpers.arraySort(unsortedAirwallexPayers, (u1: ExpandedTemplateUser, u2: ExpandedTemplateUser) =>
            compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
);

export function getTemplateExternalSubmitter(state: State): selectors.types.ExpandedUser | null {
    const userId = getActiveTemplate(state)!.externalSubmitter;

    if (!userId) {
        return null;
    }

    return selectors.user.getUserById(state, userId);
}

export function getTemplateReceiptBankExternalSubmitter(state: State): selectors.types.ExpandedUser | null {
    const userId = getActiveTemplate(state)!.receiptBankExternalSubmitter;

    if (!userId) {
        return null;
    }

    return selectors.user.getUserById(state, userId);
}

export function getTemplateHasSubmitterRules(state: State) {
    const template = getActiveTemplate(state)!;

    return template.submitterMatrix.some((x) => x.rules.length !== 0);
}

export function hasChanges(state: State): boolean {
    const template = getActiveTemplate(state);

    if (!template) {
        return false;
    }

    const company = selectors.company.getCompanyById(state, template.companyId);
    const integrationErrorPreventsWork = Boolean(!company.flags.hasActiveIntegration && template.integrationCode);

    if (integrationErrorPreventsWork) {
        // Changes cannot be saved in this case
        return false;
    }

    const activeMatrix = getPureActiveMatrix(state);

    return getPage(state).activeTemplateModified || Boolean(activeMatrix && activeMatrix.modified);
}

export function isMatrixUnavailable(state: State) {
    const template = getActiveTemplate(state);

    if (!template) {
        return true;
    }

    const company = selectors.company.getCompanyById(state, template.companyId);
    const integrationErrorPreventsWork = Boolean(!company.flags.hasActiveIntegration && template.integrationCode);

    return integrationErrorPreventsWork || company.isReadonly;
}
