import { TransparentButton } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';

import ImageAttention from '../ImageIcons/ImageAttention';

export const Root = styled.div`
    background-color: #cb4e30;
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    max-width: 1600px;
    height: 62px;
    padding: 10px 30px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    ${font(14, '#fff', 'semibold')}
`;

export const Text = styled.div`
    ${font(12, '#fff')}
`;

export const ButtonContainer = styled.div`
    display: flex;
    padding: 4px 0;
`;

export const LearnMoreButton = styled(TransparentButton)`
    margin-left: 10px;
    padding: 5px 20px;
    background: #cb4e30;
    border: 1px solid #fff;
    text-transform: uppercase;
    ${font(13, '#fff', 'semibold')};
`;

export const CloseButton = styled(TransparentButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    margin-left: 10px;
    padding: 5px 20px;
    background: transparent;
    ${font(13, '#fff')};
`;

export const AttentionIcon = styled(ImageAttention)`
    height: 20px;
    width: 20px;
    background-position: 0px -65px;
    background-size: 200%;
`;
