import { miscHelpers } from '@approvalmax/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCallback } from 'react';

import { typedMemo } from '../../Table.helpers';
import { defaultSpacing } from '../../Table.styles';
import type { BaseItem } from '../../Table.types';
import { getHeaderColumnContent } from './ColumnHeader.helpers';
import { useStickyShadow, useTableSort } from './ColumnHeader.hooks';
import { HeaderCell } from './ColumnHeader.styles';
import type { ColumnHeaderProps } from './ColumnHeader.types';

const ColumnHeader = typedMemo(<Item extends BaseItem>(props: ColumnHeaderProps<Item>) => {
    const { children, columnDefinition, onSort, color, stickyHeader, bordered, reorderable, ...otherProps } = props;

    const handleClick = useTableSort(columnDefinition, onSort);

    const { id, name, sortable, spacing = defaultSpacing } = columnDefinition;
    const content = children ?? getHeaderColumnContent(props);

    const { setCellRef, withStickyShadow } = useStickyShadow(columnDefinition.sticky);
    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id,
        data: { type: 'column' },
        disabled: !reorderable,
    });

    const combinedRef = useCallback(
        (node: HTMLTableCellElement | null) => {
            setCellRef(node);
            setNodeRef(node);
        },
        [setCellRef, setNodeRef]
    );

    return (
        <HeaderCell
            ref={combinedRef}
            title={name}
            $sortable={sortable}
            $color={color}
            $bordered={bordered}
            $spacing={miscHelpers.spacingPropToCss(spacing)}
            $stickyHeader={stickyHeader}
            $sticky={columnDefinition.sticky}
            $withStickyShadow={withStickyShadow}
            $textAlign={columnDefinition.headerTextAlign}
            $verticalAlign={columnDefinition.headerVerticalAlign}
            onClick={handleClick}
            $transform={CSS.Transform.toString(transform)}
            $transition={transition}
            $isDraggable={reorderable}
            $isDragging={active?.id === id}
            {...otherProps}
            {...attributes}
            {...listeners}
        >
            {content}
        </HeaderCell>
    );
});

export default ColumnHeader;
