import { AmaxPayShortLogo } from '@approvalmax/ui';
import { arrayHelpers, compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { getPath, Path } from 'urlBuilder';

import DisabledWorkflowPanel from '../DisabledWorkflowPanel/DisabledWorkflowPanel';
import { getWorkflowsMeta } from './WorkflowsMenu.helpers';
import { messages, workflowsMessages } from './WorkflowsMenu.messages';
import { WorkflowsMenuProps } from './WorkflowsMenu.types';

const qa = bemFactory.qa('requests-section');

export const useWorkflowsLinkItems = (company: WorkflowsMenuProps['company'] | null) => {
    const canSeeDisabledWorkflows =
        company?.flags.isAuditor || company?.flags.isManager || company?.flags.isWorkflowManager;
    const canConfigureDisabledWorkflows = company?.flags.isManager || company?.flags.isWorkflowManager;

    const workflows = useSelector((state) =>
        company ? selectors.meta.getViewableTemplatesByCompanyId(state, company.id) : []
    );
    const creatableWorkflowsIds = useSelector(selectors.meta.getCreatableTemplates);

    /**
     * Adding data from the api response to the meta information
     */
    const enrichedWorkflows = useMemo(() => {
        if (!company) return [];

        const workflowsMeta = getWorkflowsMeta(company);

        return (
            workflows
                /**
                 * Filtering workflows if they are not found in the metadata
                 */
                .filter((workflow) => {
                    const workflowMeta = workflowsMeta[workflow.integrationCode || 'Standalone'];

                    if (!workflowMeta) {
                        console.error(`Workflow meta not found for ${workflow.integrationCode}!`);
                    }

                    return workflowMeta;
                })
                /**
                 * Enriching workflows with metadata
                 */
                .map((workflow) => {
                    const workflowMeta = workflowsMeta[workflow.integrationCode || 'Standalone'];
                    const workflowName = workflowMeta.name || workflow.name || workflowsMessages.unnamedName;
                    const workflowDisabled = !workflow.enabled && canConfigureDisabledWorkflows && !company.isReadonly;
                    const workflowAvailable =
                        (workflowMeta.available ?? true) && (workflow.enabled || canSeeDisabledWorkflows);

                    const enrichedSubItems = workflowMeta.subItems.map((subItem) => ({
                        ...subItem,
                        title: `${workflowName}: ${subItem.name}`,
                        to: getPath(Path.requestTemplateList, workflow.companyId, workflow.id, subItem.id),
                        dataQa: qa('navigation-item'),
                    }));

                    const workflowHasAddButton =
                        (workflowMeta.hasAddButton ?? true) &&
                        !company?.isReadonly &&
                        creatableWorkflowsIds.includes(workflow.id) &&
                        (!workflow.integrationCode || company?.flags.hasActiveIntegration);
                    const addButton =
                        workflowHasAddButton && company
                            ? {
                                  title: messages.addButtonTitle,
                                  to: getPath(Path.newRequest, workflow.id, company.id),
                              }
                            : undefined;

                    const startIcon =
                        workflow.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment ? (
                            <AmaxPayShortLogo color='midnight100' width={32} height={12} />
                        ) : undefined;

                    return {
                        ...workflow,
                        ...workflowMeta,
                        name: workflowName,
                        title: workflowName,
                        subItems: enrichedSubItems,
                        available: workflowAvailable,
                        id: workflow.integrationCode || workflow.id,
                        workflowId: workflow.id,
                        disabled: workflowDisabled,
                        disabledFullWidth: Boolean(startIcon),
                        disabledComponent: company ? (
                            <DisabledWorkflowPanel company={company} workflowId={workflow.id} startIcon={startIcon} />
                        ) : null,
                        addButton,
                        dataQa: qa(),
                    };
                }, [])
        );
    }, [canConfigureDisabledWorkflows, canSeeDisabledWorkflows, company, creatableWorkflowsIds, workflows]);

    /**
     * Sort standalone workflows by name and sort workflows with integration relative to order in the meta
     */
    return useMemo(() => {
        if (!company) return [];

        const workflowsMeta = getWorkflowsMeta(company);
        const workflowsMetaKeys = Object.keys(workflowsMeta);
        const sortedIntegrationWorkflows: typeof enrichedWorkflows = [];

        sortedIntegrationWorkflows.length = enrichedWorkflows.length;

        const sortedStandaloneWorkflows = arrayHelpers.arraySort(
            enrichedWorkflows.filter((workflow) => {
                if (workflow.integrationCode) {
                    const index = workflowsMetaKeys.indexOf(workflow.integrationCode);

                    sortedIntegrationWorkflows[index] = workflow;
                }

                return !workflow.integrationCode;
            }),
            compareHelpers.comparatorFor(compareHelpers.stringComparator2Asc, 'name')
        );

        return sortedIntegrationWorkflows.filter(Boolean).concat(sortedStandaloneWorkflows);
    }, [company, enrichedWorkflows]);
};
