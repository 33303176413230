import { isBrowser } from './isBrowser';

export const isTablet = () => {
    if (isBrowser()) {
        const agent = navigator.userAgent.toLowerCase();
        const isWindows = /Windows/i.test(agent);
        const isNokia = /Nokia/i.test(agent);
        const isIpad = /ipad/i.test(agent);
        const isIphone = !isIpad && /iphone|ipod/i.test(agent) && !(isWindows || isNokia || isIpad);
        const isAndroid = /android/i.test(agent) && !(isWindows || isNokia);
        const isMobileAndroidTablet =
            isAndroid && window.matchMedia('only screen and (min-device-width: 768px)').matches;

        return Boolean(isIpad || isMobileAndroidTablet);
    }

    return false;
};
