import { Guid } from '@approvalmax/types';
import { errorHelpers, intl, oauthHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import * as companyModule from 'modules/company';
import { dataApi, domain, Entities, factories, schemas, State } from 'modules/data';
import { mergeDeep } from 'modules/immutable';
import {
    Action as ActionBase,
    ActionMeta,
    createAction,
    createAsyncAction,
    createErrorAction,
    ExtractActions,
    ThunkAction,
} from 'modules/react-redux';
import { sendChameleonEvent } from 'modules/utils/helpers/chameleon';
import { normalize } from 'normalizr';
import { defineMessages } from 'react-intl';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { routingService } from 'services/routing';
import { getUrl, Path } from 'urlBuilder';

import * as qbooksIntegration from './integration.qbooks';
import * as xeroIntegration from './integration.xero';

export * as qbooksIntegration from './integration.qbooks';

const { TEMPLATE_ID_PREFIX } = constants.commonConstants;

const i18nPrefix = 'common/actions/integration';
const messages = defineMessages({
    integrationSyncSuccessText: {
        id: `${i18nPrefix}.integrationSyncSuccessText`,
        defaultMessage: '"{companyName}" requests pulled from {integrationName}.',
    },
    disconnectFromIntegrationSuccessText: {
        id: `${i18nPrefix}.disconnectFromIntegrationSuccessText`,
        defaultMessage: 'Disconnected from {integrationName}',
    },
});

export const CONNECT_TO_INTEGRATION = 'COMMON/CONNECT_TO_INTEGRATION';
export const CONNECT_TO_INTEGRATION_RESPONSE = 'COMMON/CONNECT_TO_INTEGRATION_RESPONSE';
export const CONNECT_TO_INTEGRATION_FAILURE = 'COMMON/CONNECT_TO_INTEGRATION_FAILURE';
export const connectToIntegration = (options: {
    companyId: Guid | null;
    integrationType: domain.IntegrationType;
    finalRedirectToPage: domain.OAuthIntegrationFinalPage;
    showGlobalProgress: boolean;
}) =>
    createAsyncAction({
        request: (state: State) => {
            let payload:
                | { integrationFirst: true; companyId: null }
                | {
                      integrationFirst: false;
                      companyId: Guid;
                      integrationId: Guid | null;
                      newIntegration: domain.Integration;
                  };

            if (options.companyId) {
                const company = selectors.company.getCompanyById(state, options.companyId);
                const newIntegration = factories.integration.createIntegration({
                    companyId: options.companyId,
                    integrationType: options.integrationType,
                    status: domain.IntegrationStatus.Connecting,
                });

                payload = {
                    integrationFirst: false,
                    companyId: company.id,
                    integrationId: company.integrationId,
                    newIntegration,
                };
            } else {
                payload = {
                    integrationFirst: true,
                    companyId: null,
                };
            }

            return createAction(CONNECT_TO_INTEGRATION, payload);
        },

        response: async (request) => {
            let redirectUrl;
            let callbackUrl = getUrl(Path.oauth2Redirect);

            switch (options.integrationType) {
                case domain.IntegrationType.Xero: {
                    redirectUrl = (
                        await xeroIntegration.connectToIntegration({
                            callbackUrl,
                        })
                    ).redirectUrl;
                    break;
                }

                case domain.IntegrationType.QBooks: {
                    redirectUrl = (
                        await qbooksIntegration.connectToIntegration({
                            callbackUrl,
                        })
                    ).redirectUrl;
                    break;
                }

                case domain.IntegrationType.NetSuite:
                case domain.IntegrationType.Dear:
                case domain.IntegrationType.None:
                    throw errorHelpers.formatError();

                default:
                    throw errorHelpers.assertNever(options.integrationType);
            }

            redirectUrl = oauthHelpers.encodeOAuthState<domain.OAuthUiState>(redirectUrl, {
                type: 'oauth',
                companyId: request.companyId,
                integrationType: options.integrationType,
                finalPage: options.finalRedirectToPage,
            });

            return createAction(CONNECT_TO_INTEGRATION_RESPONSE, {
                request,
                redirectUrl,
            });
        },

        failure: (error, request) =>
            createErrorAction(CONNECT_TO_INTEGRATION_FAILURE, error, {
                request,
            }),

        showAsyncLoadingBar: options.showGlobalProgress,

        didDispatchResponse: (request, response) => {
            routingService.navigateToExternalUrl(response.redirectUrl);
        },
    });

export const DISCONNECT_FROM_INTEGRATION = 'COMMON/DISCONNECT_FROM_INTEGRATION';
export const DISCONNECT_FROM_INTEGRATION_RESPONSE = 'COMMON/DISCONNECT_FROM_INTEGRATION_RESPONSE';
export const DISCONNECT_FROM_INTEGRATION_FAILURE = 'COMMON/DISCONNECT_FROM_INTEGRATION_FAILURE';
export const disconnectFromIntegration = (companyId: Guid) =>
    createAsyncAction({
        request: (state: State) => {
            const company = selectors.company.getCompanyById(state, companyId);

            return createAction(DISCONNECT_FROM_INTEGRATION, {
                integrationId: company.integration!.id,
                companyId,
                integrationName: company.integration!.displayName,
            });
        },

        response: async (request) => {
            let response = await api.companies.disableIntegration({
                integrationId: request.integrationId,
                companyId,
            });

            return createAction(DISCONNECT_FROM_INTEGRATION_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error, request) =>
            createErrorAction(DISCONNECT_FROM_INTEGRATION_FAILURE, error, {
                request,
            }),

        schema: { raw: { Companies: [schemas.companySchemaLegacy] } },

        successToast: (state, request) => {
            return intl.formatMessage(messages.disconnectFromIntegrationSuccessText, {
                integrationName: request.integrationName,
            });
        },
    });

export const COMPLETE_INTEGRATION_AUTH = 'COMMON/COMPLETE_INTEGRATION_AUTH';
export const COMPLETE_INTEGRATION_AUTH_RESPONSE = 'COMMON/COMPLETE_INTEGRATION_AUTH_RESPONSE';
export const COMPLETE_INTEGRATION_AUTH_FAILURE = 'COMMON/COMPLETE_INTEGRATION_AUTH_FAILURE';
export const completeIntegrationAuth = (
    options:
        | {
              integrationType: domain.IntegrationType.Xero;
              newCompany?: boolean;
              companyId: Guid;
              state: string;
              tenantId: string;
          }
        | {
              integrationType: domain.IntegrationType.QBooks;
              newCompany?: boolean;
              companyId: Guid;
              code: string;
              state: string;
              realmId: string;
          },
    callback?: () => void
) =>
    createAsyncAction({
        request: (state: State) => {
            let realmId: string;

            switch (options.integrationType) {
                case domain.IntegrationType.Xero:
                    realmId = options.tenantId;
                    break;

                case domain.IntegrationType.QBooks:
                    realmId = options.realmId;
                    break;

                default:
                    throw errorHelpers.assertNever(options);
            }

            let companyId = options.companyId;

            const existingIntegration = selectors.integration.getIntegrationByCompanyId(state, companyId);

            let newIntegration;

            if (!existingIntegration) {
                newIntegration = factories.integration.createIntegration({
                    companyId,
                    integrationType: options.integrationType,
                    status: domain.IntegrationStatus.ConnectingFinalizing,
                });
            }

            return createAction(COMPLETE_INTEGRATION_AUTH, {
                integrationType: options.integrationType,
                companyId,
                existingIntegrationId: existingIntegration?.id,
                newIntegration,
            });
        },

        response: async (request, getState) => {
            let entities: Entities = { templates: {} } as Entities;

            // Complete integration on this company
            let result;

            switch (options.integrationType) {
                case domain.IntegrationType.Xero:
                    result = await xeroIntegration.completeIntegrationAuth({
                        companyId: request.companyId,
                        state: options.state,
                        tenantId: options.tenantId,
                    });
                    break;

                case domain.IntegrationType.QBooks:
                    result = await qbooksIntegration.completeIntegrationAuth({
                        companyId: request.companyId,
                        code: options.code,
                        state: options.state,
                        realmId: options.realmId,
                    });
                    break;

                default:
                    throw errorHelpers.assertNever(options);
            }

            entities = mergeDeep(entities, result.entities);

            // Enrich the entities object with templates if there are none in the store
            const hasServerObjects = selectors.template
                .getTemplatesByCompanyId(getState(), request.companyId)
                .some((t) => t.integrationCode !== null);

            if (!hasServerObjects) {
                const templateResponse = await dataApi.templates.select({
                    companyId: request.companyId,
                });
                const templateEntities = normalize(templateResponse.Templates, [schemas.templateSchema]).entities;

                entities = mergeDeep(entities, templateEntities);
            }

            if (options.newCompany) {
                amplitudeService.sendData('signup: created org');
            } else {
                amplitudeService.sendData('workflows list: complete connection', {
                    'connection type': options.integrationType.toLocaleLowerCase(),
                });
            }

            sendChameleonEvent('connected_gl');

            return createAction(COMPLETE_INTEGRATION_AUTH_RESPONSE, {
                request,
                entities,
                templates: Object.values(entities.templates),
            });
        },

        failure: (error, request) =>
            createErrorAction(COMPLETE_INTEGRATION_AUTH_FAILURE, error, {
                request,
            }),

        didDispatchResponse: async (request, response, state, dispatch) => {
            callback?.();

            const hasEnabledIntegrationTemplates = selectors.template
                .getTemplatesByCompanyId(state, options.companyId)
                .some((t) => t.integrationCode !== null && t.enabled);

            if (hasEnabledIntegrationTemplates) {
                // Should get dispatched async
                dispatch(syncIntegration({ companyId: options.companyId }));
            }

            if (options.newCompany) {
                dispatch(
                    companyModule.loadOrganisationPostCreationWizard({
                        companyId: options.companyId,
                    })
                );
            }
        },
    });

export const SYNC_INTEGRATION = 'COMMON/SYNC_INTEGRATION';
export const SYNC_INTEGRATION_RESPONSE = 'COMMON/SYNC_INTEGRATION_RESPONSE';
export const SYNC_INTEGRATION_FAILURE = 'COMMON/SYNC_INTEGRATION_FAILURE';
export const syncIntegration = (options: { companyId: string }) =>
    createAsyncAction({
        request: (state: State) => {
            const company = selectors.company.getCompanyById(state, options.companyId);

            if (!company.integration || !company.flags.hasActiveIntegration) {
                throw errorHelpers.invalidOperationError();
            }

            return createAction(SYNC_INTEGRATION, {
                integration: company.integration,
            });
        },

        response: async (request) => {
            await api.companies.pullIntegrations({
                companyId: request.integration.companyId,
                integrationIds: [request.integration.id],
            });

            return createAction(SYNC_INTEGRATION_RESPONSE, {
                request,
            });
        },

        failure: (error, request) =>
            createErrorAction(SYNC_INTEGRATION_FAILURE, error, {
                request,
            }),
    });

export const syncAllIntegrations = (): ThunkAction => {
    return async (dispatch, getState) => {
        const state = getState();

        await Promise.all(
            selectors.integration
                .getConnectedIntegrations(state)
                .filter((i) => {
                    const c = selectors.company.getCompanyById(state, i.companyId);

                    return !c.isReadonly;
                })
                .map((i) => dispatch(syncIntegration({ companyId: i.companyId })))
        );
    };
};

export const INTEGRATION_ERROR = 'COMMON/INTEGRATION_ERROR';
export type IntegrationErrorAction = ActionBase<
    typeof INTEGRATION_ERROR,
    {
        integrationId: Guid;
    },
    ActionMeta,
    any
>;

export const possibleIntegrationError = (companyId: Guid, error: Error): ThunkAction => {
    /*
     * We cannot just check "any" backend error for an integration error, as it doesn't
     * indicate to which company it relates. Therefore the genericError() action doesn't check it,
     * use this method instead.
     */
    return (dispatch, getState) => {
        const company = selectors.company.getCompanyById(getState(), companyId);
        const isIntegrationError = selectors.integration.isIntegrationError(error);

        if (!company.integration || !isIntegrationError) {
            return;
        }

        dispatch<IntegrationErrorAction>(
            createErrorAction(INTEGRATION_ERROR, error, {
                integrationId: company.integration.id,
            })
        );
    };
};

export const GET_INTEGRATION_CACHE_STATUS = 'COMMON/GET_INTEGRATION_CACHE_STATUS';
export const GET_INTEGRATION_CACHE_STATUS_RESPONSE = 'COMMON/GET_INTEGRATION_CACHE_STATUS_RESPONSE';
export const GET_INTEGRATION_CACHE_STATUS_FAILURE = 'COMMON/GET_INTEGRATION_CACHE_STATUS_FAILURE';
export const getIntegrationCacheStatus = (companyId: string, integrationId: string, callback?: () => void) =>
    createAsyncAction({
        request: (state: State) => {
            const cacheItems = selectors.integration.getIntegrationCacheItems(
                state,
                selectors.integration.getIntegrationById(state, integrationId)
            );

            return createAction(GET_INTEGRATION_CACHE_STATUS, {
                integrationId,
                companyId,
                cacheItems,
            });
        },

        response: async (request) => {
            const result = await api.integration.getIntegrationCacheStatus({ integrationId, companyId });

            let cacheObjects: domain.IntegrationCacheItem[] = result.data.cacheObjects as any;

            const beforeItem = request.cacheItems.find(
                (c) => c.cacheType === domain.IntegrationCacheType.XeroOrganizations
            );
            const afterItem = cacheObjects.find((c) => c.cacheType === domain.IntegrationCacheType.XeroOrganizations);

            if (
                beforeItem &&
                afterItem &&
                (afterItem.loadingInProgress || afterItem.lastEndDate === beforeItem.lastEndDate)
            ) {
                // organisation hasn't changed
                return createAction(GET_INTEGRATION_CACHE_STATUS_RESPONSE, {
                    request,
                    cacheObjects,
                    raw: { Companies: [] },
                });
            }

            // organisation might have changed
            const companyResponse = await api.companies.select({ companyId });

            return createAction(GET_INTEGRATION_CACHE_STATUS_RESPONSE, {
                request,
                cacheObjects,
                raw: {
                    Companies: companyResponse.Companies,
                },
            });
        },

        failure: (error, request) => createErrorAction(GET_INTEGRATION_CACHE_STATUS_FAILURE, error),

        schema: { raw: { Companies: [schemas.companySchemaLegacy] } },

        didDispatchResponse: () => {
            callback?.();
        },

        willDispatchError: (request, error, state, dispatch) => {
            dispatch(possibleIntegrationError(companyId, error));
        },
    });

export const UPDATE_INTEGRATION_CACHE = 'COMMON/UPDATE_INTEGRATION_CACHE';
export const UPDATE_INTEGRATION_CACHE_RESPONSE = 'COMMON/UPDATE_INTEGRATION_CACHE_RESPONSE';
export const UPDATE_INTEGRATION_CACHE_FAILURE = 'COMMON/UPDATE_INTEGRATION_CACHE_FAILURE';
export const updateIntegrationCache = (
    companyId: string,
    integrationId: string,
    cacheTypes: domain.IntegrationCacheType[],
    callback?: () => void
) =>
    createAsyncAction({
        request: (state: State) => {
            const cacheItems = selectors.integration.getIntegrationCacheItems(
                state,
                selectors.integration.getIntegrationById(state, integrationId)
            );

            return createAction(UPDATE_INTEGRATION_CACHE, {
                integrationId,
                cacheTypes,
                cacheItems,
            });
        },

        response: async (request) => {
            const result = await api.integration.refreshIntegrationCache({
                integrationId,
                cacheTypes: cacheTypes as any,
                companyId,
            });

            let cacheObjects: domain.IntegrationCacheItem[] = result.data.cacheObjects as any;

            const beforeItem = request.cacheItems.find(
                (c) => c.cacheType === domain.IntegrationCacheType.XeroOrganizations
            );
            const afterItem = cacheObjects.find((c) => c.cacheType === domain.IntegrationCacheType.XeroOrganizations);

            if (
                beforeItem &&
                afterItem &&
                (afterItem.loadingInProgress || afterItem.lastEndDate === beforeItem.lastEndDate)
            ) {
                // organisation hasn't changed
                return createAction(UPDATE_INTEGRATION_CACHE_RESPONSE, {
                    request,
                    cacheObjects,
                    raw: { Companies: [] },
                });
            }

            // organisation might have changed
            const companyResponse = await api.companies.select({ companyId });

            return createAction(UPDATE_INTEGRATION_CACHE_RESPONSE, {
                request,
                cacheObjects,
                raw: {
                    Companies: companyResponse.Companies,
                },
            });
        },

        failure: (error, request) => createErrorAction(UPDATE_INTEGRATION_CACHE_FAILURE, error, {}),

        schema: { raw: { Companies: [schemas.companySchemaLegacy] } },

        didDispatchResponse: () => {
            callback?.();
        },

        willDispatchError: (request, error, state, dispatch) => {
            dispatch(possibleIntegrationError(companyId, error));
        },
    });

export const RELOAD_INTEGRATIONS = 'COMMON/RELOAD_INTEGRATIONS';
export const RELOAD_INTEGRATIONS_RESPONSE = 'COMMON/RELOAD_INTEGRATIONS_RESPONSE';
export const RELOAD_INTEGRATIONS_FAILURE = 'COMMON/RELOAD_INTEGRATIONS_FAILURE';
export const reloadIntegrations = (options: { showLoading: boolean } = { showLoading: true }) =>
    createAsyncAction({
        request: (state: State) => {
            const companiesWithIntegration = selectors.company.getCompanies(state).filter((c) => c.integrationId);

            return createAction(RELOAD_INTEGRATIONS, {
                data: companiesWithIntegration.map((c) => ({
                    companyId: c.id,
                    integrationIds: c.integrationId ? [c.integrationId] : [],
                })),
            });
        },

        response: async (request) => {
            const allIntegrationIds = request.data
                .reduce<string[]>((accumulator, item) => {
                    accumulator.push(...item.integrationIds);

                    return accumulator;
                }, [])
                .filter((integrationId) => !integrationId || !integrationId.startsWith(TEMPLATE_ID_PREFIX));

            const response = await api.companies.getIntegrationSyncProgress({
                integrationIds: allIntegrationIds,
            });

            return createAction(RELOAD_INTEGRATIONS_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error, request) => createErrorAction(RELOAD_INTEGRATIONS_FAILURE, error, {}),

        showAsyncLoadingBar: options.showLoading,

        schema: { raw: { Integrations: [schemas.integrationSchema] } },
    });

export type Action =
    | ExtractActions<
          | typeof completeIntegrationAuth
          | typeof connectToIntegration
          | typeof disconnectFromIntegration
          | typeof getIntegrationCacheStatus
          | typeof reloadIntegrations
          | typeof syncIntegration
          | typeof updateIntegrationCache
      >
    | IntegrationErrorAction;
