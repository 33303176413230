import { Text } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { learnMoreLink } from './XeroInvoiceStartStep.constants';
import { messages } from './XeroInvoiceStartStep.messages';

const XeroInvoiceStartStep = memo(() => {
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedPullingSection, setCollapsedPullingSection] = useState<boolean>();

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedSubmitterSection(collapsed);
        setCollapsedPullingSection(collapsed);
    }, []);

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ templateName: template.displayName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <CollapseAllButton
                collapsed={Boolean(collapsedSubmitterSection && collapsedPullingSection)}
                onCollapse={onCollapse}
            />

            <StepSubmitterSection
                cacheId='XeroInvoiceStartStepSubmitter'
                headerText={messages.createInApprovalMax}
                description={messages.description({ pluralTemplateName })}
                required
                collapsed={collapsedSubmitterSection}
                onCollapse={setCollapsedSubmitterSection}
            />

            <XeroExternalSubmitterSection
                cacheId='XeroInvoiceStartStepExternalSubmitter'
                collapsed={collapsedPullingSection}
                onCollapse={setCollapsedPullingSection}
                description={messages.externalSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ pluralTemplateName })}
                title={messages.externalSubmitterTitle({ pluralTemplateName })}
                learnMoreLink={learnMoreLink}
            />
        </FixedStepContainer>
    );
});

export default XeroInvoiceStartStep;
