import { messages } from './CompanyAlerts.messages';
import { CompanyAlertsProps } from './CompanyAlerts.types';

export const getAlertText = (company: CompanyAlertsProps['company']) => {
    const { flags, isReadonly } = company;

    if (flags.isRetired) return messages.retired;

    if (flags.isExpired && isReadonly && flags.isTrialLicense) return messages.trialExpiredAndReadonly;

    if (flags.isExpired && isReadonly) return messages.expiredAndReadonly;

    return '';
};
