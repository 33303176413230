/**
 * Developer: Stepan Burguchev
 * Date: 2/7/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

export enum MatrixType {
    Approval = 'Approval',
    Editor = 'Editor',
    Requester = 'Requester',
    AutoApproval = 'AutoApproval',
}

export enum AccessType {
    Readonly = 'Readonly',
    Mandatory = 'Mandatory',
    Optional = 'Optional',
}
