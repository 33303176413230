import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { ResponseType } from 'axios';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApiLegacy from '../rootApiLegacy';
import { ApiError, ApiMethods, ApiSource, ApiVersion, ParamsLegacy, PathParamsLegacy, QueryOptions } from '../types';

export const createUseGetRequestLegacy = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <ResponseData, MappedResponseData = ResponseData>(
        path: string,
        data: Record<string, any>,
        options: {
            /**
             * `pathParams` are marked as optional to allow more flexible hook usage, avoiding unnecessary conditions.
             * Then in the code, it checks whether all required pathParams are received;
             * if not, `enabled` is set to false.
             */
            pathParams?: PathParamsLegacy;
            mapData?: (data: ResponseData) => MappedResponseData;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            params?: ParamsLegacy;
            method?: ApiMethods;
            queryOptions?: QueryOptions<ResponseData, MappedResponseData>;
            responseType?: ResponseType;
        } = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            params,
            method = 'post',
            queryOptions,
            mapData,
            pathParams,
            responseType,
        } = options;
        const apiUrl = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, pathParams) ? routerHelpers.pathToUrl(path, pathParams) : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData);

        return useQuery<MappedResponseData, ApiError>(
            [url, data, params].filter(Boolean),
            () =>
                handleApiCall(
                    rootApiLegacy({
                        baseURL: apiUrl,
                        method,
                        url,
                        headers: {
                            ...(Authorization && { Authorization }),
                        },
                        params,
                        responseType,
                        data,
                    })
                ),
            {
                enabled: Boolean(url),
                ...queryOptions,
                meta: {
                    meta: queryOptions?.meta,
                    data,
                    path,
                    params,
                    pathParams,
                },
            }
        );
    };
};
