import './toastNotifications.scss';

import { SwitchTransition } from '@approvalmax/ui';
import { reactWindowService } from '@approvalmax/utils';
import { PortalDef } from '@approvalmax/utils/src/services/reactWindow/reactWindow';
import { stateTree } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useLayoutEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';
import ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group';

import * as selectors from '../selectors';

const bem = bemFactory.block('common-ui-toast-notifications');
const qa = bemFactory.qa('common-ui-toast-notifications');

const ToastNotifications: FC = () => {
    const toasts = useSelector(selectors.ui.getToastNotifications);

    const [portal, setPortal] = useState<PortalDef | null>(null);

    useLayoutEffect(() => {
        function buildPortal() {
            const p = reactWindowService.createControlledPortal({
                hostEl: null,
                transient: true,
                onRequestClose: () => undefined,
            });

            p.el.classList.add(bem());
            p.el.dataset.qa = qa();

            return p;
        }

        function destroyPortal(p: PortalDef) {
            // p.closeRelatedPortals(); // <= NOTE: we do not close related portals
            p.dispose();
        }

        if (toasts.length > 0 && !portal) {
            setPortal(buildPortal());
        } else if (toasts.length === 0 && portal) {
            destroyPortal(portal);
            setPortal(null);
        }
    }, [portal, toasts.length]);

    if (!portal) {
        return null;
    }

    // Note: mouseenter/mouseleave are no longer supported.
    return ReactDOM.createPortal(
        <TransitionGroup component={null}>
            {toasts.map((t) => (
                <SwitchTransition key={t.id} timeout='auto' in={false} inClass={bem('toast-item') + '--active'}>
                    <div
                        key={t.id}
                        className={bem('toast-item', {
                            info: t.kind === stateTree.ToastNotificationKind.Info,
                            error: t.kind === stateTree.ToastNotificationKind.Error,
                        })}
                        data-qa={qa('toast-item')}
                        data-qa-name={t.message}
                    >
                        {t.message}
                    </div>
                </SwitchTransition>
            ))}
        </TransitionGroup>,
        portal.el
    );
};

export default memo(ToastNotifications);
