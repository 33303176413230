import { Checkbox } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    flex: none;
    width: 200px;
    padding: 8px 15px 12px 15px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const StyledCheckbox = styled(Checkbox)`
    flex-shrink: 0;
`;

export const LabelText = styled.div`
    margin-left: 5px;
`;
