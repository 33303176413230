import { formatDate } from './formatDate';
import { formatDateTime } from './formatDateTime';
import { formatDuration } from './formatDuration';
import { formatTime } from './formatTime';
import { getEndOfDateTimestampUTC } from './getEndOfDateTimestampUTC';
import { getEndOfLocalDay } from './getEndOfLocalDay';
import { isBetweenDates } from './isBetweenDates';
import { toDuration } from './toDuration';

export const dateTimeHelpers = {
    formatDate,
    formatDateTime,
    formatDuration,
    formatTime,
    toDuration,
    isBetweenDates,
    getEndOfDateTimestampUTC,
    getEndOfLocalDay,
};
