import './amountConditionHeaderColumn.scss';

import { Dropdown, TransparentButton } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain, State } from 'modules/data';
import { FC, memo, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { updateAmountTypeInActiveMatrix } from '../../../../actions';
import { getPureActiveMatrix } from '../../../../selectors/pageSelectors';

const i18nPrefix = 'workflows.components.card.headers.AmountConditionHeaderColumn';

const messages = defineMessages({
    request_form_amount_field_title: {
        id: `${i18nPrefix}.request_form_amount_field_title`,
        defaultMessage: 'Amount',
    },
    rule_amount_column_label_gross: {
        id: `${i18nPrefix}.rule_amount_column_label_gross`,
        defaultMessage: 'Total amount ({currency})',
    },
    rule_amount_column_label_net: {
        id: `${i18nPrefix}.rule_amount_column_label_net`,
        defaultMessage: 'Subtotal amount ({currency})',
    },
});

const bem = bemFactory.block('wfc-amt-condition-header-col');
const qa = bemFactory.block('wfc-amt-condition-header-col');

interface AmountConditionHeaderColumnProps {
    currency: string;
    readonly?: boolean;
    canChangeAmount?: boolean;
}

const AmountConditionHeaderColumn: FC<AmountConditionHeaderColumnProps> = (props) => {
    const { readonly, canChangeAmount, currency } = props;

    const dispatch = useDispatch();
    const amountType = useSelector((state: State) => {
        return getPureActiveMatrix(state)!.amountType;
    });

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const openDropdown = useCallback(() => {
        setIsDropdownOpen(true);
    }, []);

    const closeDropdown = useCallback(() => {
        setIsDropdownOpen(false);
    }, []);

    const setAsGross = () => {
        closeDropdown();
        dispatch(updateAmountTypeInActiveMatrix(domain.AmountType.Gross));
    };

    const setAsNet = () => {
        closeDropdown();
        dispatch(updateAmountTypeInActiveMatrix(domain.AmountType.Net));
    };

    if (readonly || !canChangeAmount) {
        const title = intl.formatMessage(messages.request_form_amount_field_title, {
            currency,
        });

        return (
            <div className={bem()}>
                <div className={bem('title')}>{title}</div>
            </div>
        );
    }

    const selectedTypeText =
        amountType === domain.AmountType.Gross
            ? intl.formatMessage(messages.rule_amount_column_label_gross, {
                  currency,
              })
            : intl.formatMessage(messages.rule_amount_column_label_net, {
                  currency,
              });

    return (
        <div className={bem()} data-qa={qa()}>
            <Dropdown
                onRequestClose={closeDropdown}
                panelFlow='to-right'
                isOpen={isDropdownOpen}
                button={
                    <TransparentButton qa={qa('title')} className={bem('title-button')} execute={openDropdown}>
                        {selectedTypeText}
                    </TransparentButton>
                }
            >
                <div className={bem('amt-type-panel')}>
                    <div
                        className={bem('amt-type-panel-i')}
                        data-qa={qa('amt-type-panel-i-as-gross')}
                        onClick={setAsGross}
                    >
                        <FormattedMessage
                            id={`${i18nPrefix}.rule_amount_column_menu_gross`}
                            defaultMessage='Total amount'
                        />
                    </div>

                    <div className={bem('amt-type-panel-i')} data-qa={qa('amt-type-panel-i-as-net')} onClick={setAsNet}>
                        <FormattedMessage
                            id={`${i18nPrefix}.rule_amount_column_menu_net`}
                            defaultMessage='Subtotal amount (No tax)'
                        />
                    </div>
                </div>
            </Dropdown>
        </div>
    );
};

export default memo(AmountConditionHeaderColumn);
