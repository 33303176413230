import './dateCell.scss';

import moment from 'moment';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const i18nPrefix = 'reports.cells.DateCell';

interface Props {
    value: string;
    className?: string;
}

const bem = bemFactory.block('rpt-date-cell');

const DateCell: FC<Props> = (props) => {
    const { value, className } = props;

    // Server format: 2018-06-09
    return <div className={bem.add(className)()}>{moment(value).format('ll')}</div>;
};

export default memo(DateCell);
