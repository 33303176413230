export enum OcrFileContentType {
    DocumentPerFile = 'DocumentPerFile',
    MultiDocumentFile = 'MultiDocumentFile',
}

export type UseBulkUploadToOcrPathParams = {
    companyId: string;
};

export interface UseBulkUploadToOcrData {
    fileUploadOptions: {
        fileContentType: OcrFileContentType;
    };
    fileRecordIds: string[];
}
