import { Guid } from '@approvalmax/types';

export function mapReference<T extends Guid>(
    value: ({ Id?: T; Name?: string } & { id?: T; name?: string }) | null | undefined
): {
    id: T;
    text: string;
} | null {
    const id = value?.Id || value?.id;
    const name = value?.Name || value?.name;

    if (!id || !name) {
        return null;
    }

    return {
        id,
        text: name,
    };
}

export function mapReferenceWithValue<T extends Guid>(
    value: { Value?: T; Name?: string } | undefined
): {
    id: T;
    text: string;
} | null {
    if (!value || !value.Value || !value.Name) {
        return null;
    }

    return {
        id: value.Value,
        text: value.Name,
    };
}
