import { hooks } from '@approvalmax/utils';
import { memo, NamedExoticComponent, useCallback, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Header, Navigation } from './components';
import { useDisplaying } from './Drawer.hooks';
import { collapseDrawerState, openDrawerState, pinDrawerState } from './Drawer.states';
import { Body, Content, ExpandLine, Overlay, Root } from './Drawer.styles';
import { ChildrenComponents, DrawerProps } from './Drawer.types';

/**
 * Main Drawer component for the application
 */
const Drawer = memo((props) => {
    const { children, notifications, notificationsCount, contentRef, ...restProps } = props;

    const ref = useRef<HTMLDivElement>(null);
    const composedRefs = hooks.useComposedRefs(ref, contentRef);

    const isDrawerCollapsed = useRecoilValue(collapseDrawerState);
    const isDrawerPinned = useRecoilValue(pinDrawerState);
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(openDrawerState);
    const closeDrawer = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);
    const openDrawer = useCallback(() => setIsDrawerOpen(true), [setIsDrawerOpen]);
    const [isDrawerDisplaying] = useDisplaying();

    return (
        <Root {...restProps} $pinned={isDrawerPinned}>
            {isDrawerOpen && <Overlay onClick={closeDrawer} />}

            <Content
                $pinned={isDrawerPinned}
                $open={isDrawerOpen}
                $collapsed={isDrawerCollapsed}
                $displaying={isDrawerDisplaying}
                ref={composedRefs}
            >
                <Header root={ref.current} notifications={notifications} notificationsCount={notificationsCount} />

                <Body $collapsed={isDrawerCollapsed} $displaying={isDrawerDisplaying}>
                    {children}
                </Body>
            </Content>

            {isDrawerCollapsed && !isDrawerDisplaying && <ExpandLine onClick={openDrawer} />}
        </Root>
    );
}) as NamedExoticComponent<DrawerProps> & ChildrenComponents;

Drawer.Navigation = Navigation;

export default Drawer;
