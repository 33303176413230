import { Field, Popup, TextAreaEditor, TextEditor } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { useDispatch } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, FormattedMessage } from 'react-intl';
import { amplitudeService } from 'services/amplitude';
import { notificationService } from 'services/notification';
import styled from 'styled-components';

import { cancelActivePopup } from '../../actions';
import { useRequestToSalesMutation } from './hooks';

const i18nPrefix = 'profile/containers/ContactSalesPopup';

const messages = defineMessages({
    popupTitle: {
        id: `${i18nPrefix}.popupTitle`,
        defaultMessage: 'Contact sales',
    },
    doneButtonTitle: {
        id: `${i18nPrefix}.doneButtonTitle`,
        defaultMessage: 'Send the sales request',
    },
    validationErrorRequired: {
        id: `${i18nPrefix}.validationErrorRequired`,
        defaultMessage: 'Please fill in the required fields',
    },
    createTicketSuccess: {
        id: `${i18nPrefix}.createTicketSuccess`,
        defaultMessage: 'Your request has been submitted, our sales representative will contact you shortly.',
    },
    createTicketFail: {
        id: `${i18nPrefix}.createTicketFail`,
        defaultMessage: 'Oops... something went wrong',
    },
    phonePlaceholder: {
        id: `${i18nPrefix}.phonePlaceholder`,
        defaultMessage: 'Enter phone number...',
    },
    descriptionPlaceholder: {
        id: `${i18nPrefix}.descriptionPlaceholder`,
        defaultMessage: 'Please submit your request to a sales representative',
    },
});

const PopupContent = styled(Popup.DefaultContent)`
    width: 800px;
`;

const Content = styled.div`
    margin: 0px 80px 0px 80px;
    padding-bottom: 40px;
`;

const Row = styled.div`
    margin-top: 20px;
    display: flex;

    & > * {
        flex: 1;
        overflow: hidden;
    }

    & > :not(:last-child) {
        margin-right: 20px;
    }
`;

const qa = bemFactory.qa('contact-sales-popup');

const ContactSalesPopup: FC = () => {
    const dispatch = useDispatch();
    const profile = useUserProfile();
    const [phoneNumber, setPhoneNumber] = useState(profile.phone || '');
    const [description, setDescription] = useState('');
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const { requestToSales, isLoading: isCreating } = useRequestToSalesMutation();

    const onSend = async () => {
        if (!phoneNumber.trim() || !description.trim()) {
            notificationService.showErrorToast(intl.formatMessage(messages.validationErrorRequired));
            setShowValidationErrors(true);

            return;
        }

        amplitudeService.sendData('help: send contact sales request');

        await requestToSales({
            phone: phoneNumber,
            message: description,
            accountId: profile.account!.accountId,
        });

        dispatch(cancelActivePopup());
    };

    return (
        <PopupContent
            qa={qa()}
            title={intl.formatMessage(messages.popupTitle)}
            buttons={
                <Popup.DefaultContent.Button
                    qa={qa('done-button')}
                    execute={onSend}
                    title={intl.formatMessage(messages.doneButtonTitle)}
                >
                    <FormattedMessage id={`${i18nPrefix}.doneButtonText`} defaultMessage='Send' />
                </Popup.DefaultContent.Button>
            }
            customCloseHandler={() => {
                dispatch(cancelActivePopup());
            }}
        >
            <Content>
                <Row>
                    <Field
                        required
                        title={<FormattedMessage id={`${i18nPrefix}.phoneTitle`} defaultMessage='Phone number' />}
                    >
                        <TextEditor
                            focusOnMount
                            invalid={showValidationErrors && !phoneNumber}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            placeholder={intl.formatMessage(messages.phonePlaceholder)}
                        />
                    </Field>
                </Row>

                <Row>
                    <Field
                        required
                        title={<FormattedMessage id={`${i18nPrefix}.descriptionTitle`} defaultMessage='Description' />}
                    >
                        <TextAreaEditor
                            value={description}
                            invalid={showValidationErrors && !description}
                            onChange={setDescription}
                            minHeight={4}
                            maxHeight={10}
                            placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
                        />
                    </Field>
                </Row>
            </Content>
        </PopupContent>
    );
};

export default memo(ContactSalesPopup);
