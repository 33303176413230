import { validators } from '@approvalmax/ui';
import { useCallback, useRef, useState } from 'react';
import { useTwoFaEnablingEmailSet } from 'shared/data';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import { messages } from './StepEmailEnter.messages';

export const useStepEmailEnter = (onNext: () => void) => {
    const { context, setContext } = useTwoFaEnablingWizardContext();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const { mutate: addEmail, reset, isError, isLoading } = useTwoFaEnablingEmailSet();
    const canSubmit = Boolean(context.email?.trim()) && !isLoading;

    const onChange = useCallback(
        (value: string) => {
            if (isError) reset();

            setContext((prevContext) => ({ ...prevContext, email: value }));
        },
        [isError, reset, setContext]
    );

    const onSubmit = useCallback(() => {
        if (!canSubmit) return;

        const email = context.email?.trim();
        const stateId = context.stateId;

        if (!validators.isEmail(context.email)) {
            setErrorMessage(messages.invalidEmailErrorText);

            return;
        }

        if (stateId && email) {
            addEmail(
                {
                    data: { stateId, email },
                },
                {
                    onSuccess: () => {
                        onNext();
                    },
                    onError: (err) => {
                        setErrorMessage(err.message);

                        inputRef.current!.focus();
                    },
                }
            );
        }
    }, [addEmail, canSubmit, context.email, context.stateId, onNext]);

    return {
        inputRef,
        email: context.email,
        onChange,
        onSubmit,
        isLoading,
        errorMessage,
        canSubmit,
    };
};
