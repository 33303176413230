import './xeroSupplierCreationConditionCell.scss';

import { Reference } from '@approvalmax/types';
import {
    ButtonElement,
    Checkbox,
    Dropdown,
    DropdownBoxItemProps,
    DropdownEditor,
    TransparentButton,
} from '@approvalmax/ui';
import { arrayHelpers, defineMessages, errorHelpers } from '@approvalmax/utils';
import { dataProviders, selectors } from 'modules/common';
import { domain } from 'modules/data';
import React from 'react';
import bemFactory from 'react-bem-factory';

const messages = defineMessages('pages.workflows.XeroSupplierCreationConditionCell', {
    onlySuppliersDropdownText: 'Any Contact marked as Supplier',
    onlyCustomersDropdownText: 'Any Contact marked as Customer',
    contactsExactValuesDropdownText: 'Contact that matches',
    contactsNotExactValuesDropdownText: 'Any Contact except for',
    anyExistingContactDropdownText: 'Any existing Contact',
    exactValuesPlaceholder: 'Select {fieldName}',
    ruleEditorXeroPoSupplierAllowCreateCheck: 'Allow to create new Contacts',
});

enum ExpandedConditionType {
    AllContacts = 'AllContacts',
    SuppliersOnly = 'SuppliersOnly',
    CustomersOnly = 'CustomersOnly',
    ExactValues = 'ExactValues',
    NegativeExactValues = 'NegativeExactValues',
}

const conditionTypeTextMap: {
    [conditionType: string]: string;
} = {
    [ExpandedConditionType.AllContacts]: messages.anyExistingContactDropdownText,
    [ExpandedConditionType.ExactValues]: messages.contactsExactValuesDropdownText,
    [ExpandedConditionType.NegativeExactValues]: messages.contactsNotExactValuesDropdownText,
    [ExpandedConditionType.SuppliersOnly]: messages.onlySuppliersDropdownText,
    [ExpandedConditionType.CustomersOnly]: messages.onlyCustomersDropdownText,
};

interface Props {
    lineId: string;
    rule: domain.MatrixRule;
    field: domain.Field;
    integrationCode: domain.IntegrationCode | null;
    condition: domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition;
    readonly?: boolean;
    features: domain.CompanyBetaFeature[];
    hideCreateContactCheckbox?: boolean;
    onConditionChange(
        lineId: string,
        rule: domain.MatrixRule,
        field: domain.Field,
        newCondition: domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition
    ): void;
}

interface State {
    isConditionTypeDropdownOpen: boolean;
}

class SupplierCreationConditionCell extends React.Component<Props, State> {
    public state = {
        isConditionTypeDropdownOpen: false,
    };

    private _conditionTypeDropdown!: ButtonElement;

    public shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            this.props.condition !== nextProps.condition ||
            this.state.isConditionTypeDropdownOpen !== nextState.isConditionTypeDropdownOpen
        );
    }

    constructor(props: Props) {
        super(props);
    }

    public render() {
        const { condition, field, readonly, hideCreateContactCheckbox, integrationCode } = this.props;
        const { isConditionTypeDropdownOpen } = this.state;

        let effectiveConditionType: ExpandedConditionType;

        switch (condition.conditionType) {
            case null:
                effectiveConditionType = ExpandedConditionType.AllContacts;
                break;

            case domain.ConditionType.ExactValuesCondition:
                effectiveConditionType = ExpandedConditionType.ExactValues;
                break;

            case domain.ConditionType.NegativeExactValuesCondition:
                effectiveConditionType = ExpandedConditionType.NegativeExactValues;
                break;

            case domain.ConditionType.ServerCondition:
                switch (condition.serverConditionType) {
                    case domain.ServerConditionType.AllContacts:
                        effectiveConditionType = ExpandedConditionType.AllContacts;
                        break;

                    case domain.ServerConditionType.SuppliersOnly:
                        effectiveConditionType = ExpandedConditionType.SuppliersOnly;
                        break;

                    case domain.ServerConditionType.CustomersOnly:
                        effectiveConditionType = ExpandedConditionType.CustomersOnly;
                        break;

                    default:
                        throw errorHelpers.invalidOperationError();
                }

                break;

            default:
                throw errorHelpers.invalidOperationError();
        }

        const conditionTypeText = conditionTypeTextMap[effectiveConditionType];
        const isEmptyCondition = effectiveConditionType === ExpandedConditionType.AllContacts;
        const invalid = !selectors.matrix.isValidCondition(condition);

        const bem = bemFactory.block('wfc-xero-supplier-creation-condition-cell');
        const qa = bemFactory.qa('wfc-supplier-creation-condition-cell');

        const options = [
            ExpandedConditionType.AllContacts,
            integrationCode === domain.IntegrationCode.XeroQuote ||
            integrationCode === domain.IntegrationCode.XeroInvoice
                ? ExpandedConditionType.CustomersOnly
                : ExpandedConditionType.SuppliersOnly,
            ExpandedConditionType.ExactValues,
            ExpandedConditionType.NegativeExactValues,
        ];

        const rangeTypeOptions = options.map((x) => {
            return (
                <div
                    key={x}
                    className={bem('condition-type-panel-i')}
                    data-qa={qa(`condition-type-dropdown-item-${x}`)}
                    onClick={() => this._changeConditionType(x)}
                >
                    {conditionTypeTextMap[x]}
                </div>
            );
        });

        return (
            <div className={bem()} data-qa={qa()} data-qa-id={field.id} data-qa-name={field.name}>
                <Dropdown
                    onRequestClose={this._closeConditionTypeDropdown}
                    panelFlow='to-right'
                    isOpen={isConditionTypeDropdownOpen}
                    button={
                        <TransparentButton
                            execute={this._openConditionTypeDropdown}
                            onFocus={this._openConditionTypeDropdown}
                            onBlur={this._closeConditionTypeDropdown}
                            disabled={readonly}
                            ref={(ref) => (this._conditionTypeDropdown = ref!)}
                            qa={qa('condition-type-menu')}
                            className={bem('condition-dropdown-btn', {
                                empty: isEmptyCondition,
                                readonly,
                            })}
                            title={conditionTypeText}
                        >
                            {conditionTypeText}
                        </TransparentButton>
                    }
                >
                    <div className={bem('condition-type-panel')} onMouseDown={this._preventBlur}>
                        {rangeTypeOptions}
                    </div>
                </Dropdown>

                {(effectiveConditionType === ExpandedConditionType.ExactValues ||
                    effectiveConditionType === ExpandedConditionType.NegativeExactValues) && (
                    <dataProviders.FieldDataProvider field={field} integrationCode={integrationCode}>
                        <DropdownEditor
                            multiple
                            theme='matrix'
                            invalid={invalid}
                            placeholder={messages.exactValuesPlaceholder({
                                fieldName: (field.name || '').toLowerCase(),
                            })}
                            disabled={readonly}
                            value={(condition as domain.ExactValuesCondition).exactValues}
                            qa={qa('values-editor')}
                            onChange={this._changeExactValues}
                            boxItem={this._renderBoxItem}
                        />
                    </dataProviders.FieldDataProvider>
                )}

                {effectiveConditionType === ExpandedConditionType.AllContacts && !hideCreateContactCheckbox && (
                    <div
                        onClick={!readonly ? this._toggleAllowCreation : undefined}
                        data-qa={qa('allow-creation')}
                        className={bem('checkbox', { readonly })}
                    >
                        <Checkbox
                            className={bem('checkbox-box')}
                            checked={condition.allowCreation}
                            disabled={readonly}
                        />

                        <div className={bem('checkbox-text')}>{messages.ruleEditorXeroPoSupplierAllowCreateCheck}</div>
                    </div>
                )}
            </div>
        );
    }

    private _preventBlur = (e: React.SyntheticEvent<any>) => {
        e.preventDefault();
    };

    private _pickBaseConditionFields = () => {
        return {
            fieldId: this.props.condition.fieldId,
            fieldName: this.props.condition.fieldName,
            fieldSystemPurpose: this.props.condition.fieldSystemPurpose,
        };
    };

    private _changeConditionType = (expandedConditionType: ExpandedConditionType) => {
        let newCondition;

        switch (expandedConditionType) {
            case ExpandedConditionType.AllContacts:
                if (this.props.condition.allowCreation) {
                    newCondition = {
                        ...this._pickBaseConditionFields(),
                        conditionType: domain.ConditionType.ServerCondition,
                        serverConditionType: domain.ServerConditionType.AllContacts,
                    } as domain.ServerCondition;
                } else {
                    newCondition = {
                        ...this._pickBaseConditionFields(),
                        conditionType: null,
                    } as domain.AlwaysTrueCondition;
                }

                (newCondition as any).exactValues = [];
                break;

            case ExpandedConditionType.SuppliersOnly:
                newCondition = {
                    ...this._pickBaseConditionFields(),
                    allowCreation: false,
                    conditionType: domain.ConditionType.ServerCondition,
                    serverConditionType: domain.ServerConditionType.SuppliersOnly,
                } as domain.ServerCondition;
                (newCondition as any).exactValues = [];
                break;

            case ExpandedConditionType.CustomersOnly:
                newCondition = {
                    ...this._pickBaseConditionFields(),
                    allowCreation: false,
                    conditionType: domain.ConditionType.ServerCondition,
                    serverConditionType: domain.ServerConditionType.CustomersOnly,
                } as domain.ServerCondition;
                (newCondition as any).exactValues = [];
                break;

            case ExpandedConditionType.ExactValues:
                newCondition = {
                    ...this._pickBaseConditionFields(),
                    conditionType: domain.ConditionType.ExactValuesCondition,
                    // this update is important since DropdownEditor is a PureComponent
                    // and doesn't know that its BoxItem depends on conditionType
                    exactValues: arrayHelpers.cloneImmutableArray(
                        (this.props.condition as domain.ExactValuesCondition).exactValues || []
                    ),
                } as domain.ExactValuesCondition;
                break;

            case ExpandedConditionType.NegativeExactValues:
                newCondition = {
                    ...this._pickBaseConditionFields(),
                    conditionType: domain.ConditionType.NegativeExactValuesCondition,
                    // this update is important since DropdownEditor is a PureComponent
                    // and doesn't know that its BoxItem depends on conditionType
                    exactValues: arrayHelpers.cloneImmutableArray(
                        (this.props.condition as domain.ExactValuesCondition).exactValues || []
                    ),
                } as domain.ExactValuesCondition;
                break;

            default:
                throw errorHelpers.invalidOperationError();
        }

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);
        this._conditionTypeDropdown.blur();
    };

    private _changeExactValues = (exactValues: Reference[]) => {
        let newCondition = {
            ...this.props.condition,
            exactValues,
        };

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);
    };

    private _toggleAllowCreation = () => {
        const newAllowCreation = !this.props.condition.allowCreation;

        let newCondition;

        if (newAllowCreation) {
            newCondition = {
                ...this._pickBaseConditionFields(),
                conditionType: domain.ConditionType.ServerCondition,
                serverConditionType: domain.ServerConditionType.AllContacts,
                allowCreation: true,
            } as domain.ServerCondition;
        } else {
            newCondition = {
                ...this._pickBaseConditionFields(),
                conditionType: null,
            } as domain.AlwaysTrueCondition;
        }

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);
    };

    private _openConditionTypeDropdown = () => {
        this.setState({
            isConditionTypeDropdownOpen: true,
        });
    };

    private _closeConditionTypeDropdown = () => {
        this.setState({
            isConditionTypeDropdownOpen: false,
        });
    };

    private _renderBoxItem = (boxItemProps: DropdownBoxItemProps) => {
        const negative = this.props.condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;

        return (
            <DropdownEditor.BoxItem
                {...boxItemProps}
                theme={negative ? DropdownEditor.BoxItemTheme.Grey : DropdownEditor.BoxItemTheme.Green}
            />
        );
    };
}

export default SupplierCreationConditionCell;
