import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { stickyHeaderColumnZIndex, stickyHeaderZIndex } from '../../Table.constants';
import { stickyHeaderCellBorder, stickyShadow, verticalAlign } from '../../Table.mixins';
import { BaseItem, ColumnDefinition, SortOrder } from '../../Table.types';

export const iconSize = 16;

export const SortIcon = styled.div<StyledMods<Pick<ColumnDefinition<BaseItem>, 'sortOrder'>>>`
    width: ${iconSize}px;
    height: ${iconSize}px;
    transition: all ${theme.duration.medium}ms ease-in-out;
    transition-property: transform, opacity;

    ${mods.not('sortOrder')`
        opacity: 0;
    `};

    ${mods('sortOrder', SortOrder.desc)`
        transform: rotate(180deg);
    `};
`;

export const HeaderCell = styled.th.attrs<{
    $transform?: string;
    $transition?: string;
}>((props) => ({
    style: {
        transform: props.$transform,
        transition: props.$transition,
    },
}))<
    StyledMods<
        Pick<ColumnDefinition<BaseItem>, 'sortable' | 'sticky' | 'textAlign' | 'verticalAlign'> &
            Mods<'color', 'silver80' | 'white100'> &
            Mods<'bordered'> & {
                stickyHeader?: boolean;
                spacing?: string;
                withStickyShadow?: boolean;
                transform?: string;
                transition?: string;
                isDraggable?: boolean;
                isDragging?: boolean;
            }
    >
>`
    background-color: ${theme.color.silver80};
    padding: ${({ $spacing }) => $spacing};
    text-align: start;
    font-weight: ${theme.fontWeight.medium};
    user-select: none;
    position: relative;

    ${mods('isDraggable', true)`
        cursor: pointer;
    `}

    ${mods('isDragging', true)`
        z-index: 2000;
        cursor: grab;
    `}

    ${mods.color.white100`
        background-color: ${theme.color.white100};
    `};

    ${mods('stickyHeader', true)`
        position: sticky;
        top: 0;
        z-index: ${stickyHeaderZIndex};

        ${({ $bordered }) => stickyHeaderCellBorder($bordered, !$bordered, !$bordered)}
    `};

    ${mods('sticky', 'left')`
        position: sticky;
        left: -1px; // -1 for Intersection Observer

        ${mods.bordered.true`
            ${stickyHeaderCellBorder(true)}
        `};
        ${mods('withStickyShadow', true)`
            ${stickyShadow(false)}
        `};
    `}

    ${mods('sticky', 'right')`
        position: sticky;
        right: -1px; // -1 for Intersection Observer

        ${mods.bordered.true`
            ${stickyHeaderCellBorder(true)}
        `};
        ${mods('withStickyShadow', true)`
            ${stickyShadow(true)}
        `};
    `}

    ${mods('stickyHeader', true)`
        ${mods('sticky', ['left', 'right'])`
            z-index: ${stickyHeaderColumnZIndex};
        `};
    `};

    ${mods('sortable', true)`
        cursor: pointer;

        &:hover {
            color: ${theme.color.blue100};
        }
    `};

    ${mods.textAlign(mixins.textAlign)};

    ${mods('verticalAlign')(verticalAlign)};
`;

export const HeaderCellDragHandler = styled.th<StyledMods<Mods<'hide'>>>`
    background-color: transparent;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;

    ${mods.hide.true`
        display: none;
    `};
`;

export const HeaderContent = styled.div`
    display: inline-flex;
    align-items: center;
`;
