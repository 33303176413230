import { Reference } from '@approvalmax/types';
import { compareHelpers, defineMessages, errorHelpers, miscHelpers } from '@approvalmax/utils';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { constants, selectors } from 'modules/common';
import { backend, domain, stateTree } from 'modules/data';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { isValidIntermediateStep } from './templateStepSelectors';
import { EffectiveDate, EffectiveDateLimits, ExpandedTemplate } from './types';

const { TEMPLATE_ID_PREFIX } = constants.commonConstants;

const messages = defineMessages('common.selectors.templateSelectors', {
    airwallexBatchPaymentNamePlural: 'Airwallex Batch Payments',
    airwallexBatchPaymentObjectName: 'Airwallex Batch Payment',
    amaxPayBatchPaymentObjectName: 'Batch Payment',
    amaxPayBatchPaymentNamePlural: 'Batch Payments',
    amaxPayBatchPaymentWorkflowName: 'Batch Payment workflow',
    xeroBatchPaymentNamePlural: 'Xero Batch Payments',
    xeroBatchPaymentObjectName: 'Xero Batch Payment',
    billObjectName: 'Bill',
    billObjectNamePlural: 'Bills',
    billWorkflowName: 'Bill workflow',
    contactNamePlural: 'Contacts',
    contactObjectName: 'Contact',
    creditNodesReceivableObjectName: 'AR Credit Note',
    creditNodesReceivableObjectNamePlural: 'AR Credit Notes',
    creditNodesReceivableWorkflowName: 'AR Credit Note workflow',
    creditNotesPayableObjectName: 'AP Credit Note',
    creditNotesPayableObjectNamePlural: 'AP Credit Notes',
    creditNotesPayableWorkflowName: 'AP Credit Note workflow',
    expenseObjectName: 'Expense',
    expenseObjectNamePlural: 'Expenses',
    invalidUserApproverInStep: '{user} is on the Approver list of step "{stepName}".',
    invalidUserDextExternalSubmitter: '{user} assigned as a default requester for Dext transactions.',
    invalidUserExternalSubmitter: '{user} is assigned as an external Requester to the initial step.',
    invalidUserRequester: '{user} is on the Requester list of the initial step.',
    invalidUserReviewerInStep: '{user} is on the Reviewer list of step "{stepName}".',
    invoiceObjectName: 'Sales Invoice',
    invoiceObjectNamePlural: 'Sales Invoices',
    invoiceWorkflowName: 'Sales Invoice workflow',
    netSuiteBillPaymentObjectName: 'Bill Payment',
    netSuiteBillPaymentObjectNamePlural: 'Bill Payments',
    netSuiteBillPaymentWorkflowName: 'Bill Payment workflow',
    netSuiteBillWorkflowName: 'Vendor Bill workflow',
    netSuiteExpenseReportObjectName: 'Expense Report',
    netSuiteExpenseReportObjectNamePlural: 'Expense Reports',
    netSuiteExpenseReportWorkflowName: 'Expense Report workflow',
    netSuitePOWorkflowName: 'Purchase Order workflow',
    netSuiteRAObjectName: 'Return Authorization',
    netSuiteRAObjectNamePlural: 'Return Authorizations',
    netSuiteRAWorkflowName: 'Return Authorization workflow',
    netSuiteSalesOrderObjectName: 'Sales Order',
    netSuiteSalesOrderObjectNamePlural: 'Sales Orders',
    netSuiteSalesOrderWorkflowName: 'Sales Order workflow',
    netSuiteInvoiceWorkflowName: 'Invoice workflow',
    netSuiteInvoiceObjectName: 'Invoice',
    netSuiteInvoiceObjectNamePlural: 'Invoices',
    netSuiteVRAObjectName: 'Vendor Return Authorization',
    netSuiteVRAObjectNamePlural: 'Vendor Return Authorizations',
    netSuiteVRAWorkflowName: 'Vendor Return Authorization workflow',
    poObjectName: 'Purchase Order',
    poObjectNamePlural: 'Purchase Orders',
    poWorkflowName: 'Purchase Order workflow',
    qboBillWorkflowName: 'Bill workflow',
    qboExpenseWorkflowName: 'Expense workflow',
    qboJournalEntryObjectName: 'Journal Entry',
    qboJournalEntryObjectNamePlural: 'Journal Entries',
    qboJournalEntryWorkflowName: 'Journal Entry workflow',
    qboPoWorkflowName: 'Purchase Order workflow',
    qboVendorWorkflowName: 'Vendor workflow',
    quoteObjectName: 'Quote',
    quoteObjectNamePlural: 'Quotes',
    vendorObjectName: 'Vendor',
    vendorObjectNamePlural: 'Vendors',
    xeroAirwallexBatchPaymentWorkflowName: 'Airwallex Batch Payment workflow',
    xeroBillBatchPaymentWorkflowName: 'Xero Batch Payment workflow',
    xeroContactWorkflowName: 'Contact workflow',
    xeroManualJournalObjectName: 'Manual Journal',
    xeroManualJournalObjectNamePlural: 'Manual Journals',
    xeroManualJournalWorkflowName: 'Xero Manual Journal workflow',
    xeroQuoteWorkflowName: 'Quote workflow',
});

export const getTemplateDisplayNameByCode = (
    integrationCode: domain.IntegrationCode | null,
    plural?: boolean
): string | null => {
    if (!integrationCode) {
        return null;
    }

    const displayNameMap: Record<domain.IntegrationCode, string> = {
        [domain.IntegrationCode.DearPo]: messages.poObjectName,
        [domain.IntegrationCode.NetSuiteBillPayment]: messages.netSuiteBillPaymentObjectName,
        [domain.IntegrationCode.NetSuiteBill]: messages.billObjectName,
        [domain.IntegrationCode.NetSuiteExpenseReport]: messages.netSuiteExpenseReportObjectName,
        [domain.IntegrationCode.NetSuitePO]: messages.poObjectName,
        [domain.IntegrationCode.NetSuiteRA]: messages.netSuiteRAObjectName,
        [domain.IntegrationCode.NetSuiteSalesOrder]: messages.netSuiteSalesOrderObjectName,
        [domain.IntegrationCode.NetSuiteInvoice]: messages.netSuiteInvoiceObjectName,
        [domain.IntegrationCode.NetSuiteVRA]: messages.netSuiteVRAObjectName,
        [domain.IntegrationCode.QBooksBill]: messages.billObjectName,
        [domain.IntegrationCode.QBooksExpense]: messages.expenseObjectName,
        [domain.IntegrationCode.QBooksInvoice]: messages.invoiceObjectName,
        [domain.IntegrationCode.QBooksJournalEntry]: messages.qboJournalEntryObjectName,
        [domain.IntegrationCode.QBooksPo]: messages.poObjectName,
        [domain.IntegrationCode.QBooksVendor]: messages.vendorObjectName,
        [domain.IntegrationCode.XeroAirwallexBatchPayment]: messages.airwallexBatchPaymentObjectName,
        [domain.IntegrationCode.XeroAmaxPayBatchPayment]: messages.amaxPayBatchPaymentObjectName,
        [domain.IntegrationCode.XeroBillBatchPayment]: messages.xeroBatchPaymentObjectName,
        [domain.IntegrationCode.XeroBill]: messages.billObjectName,
        [domain.IntegrationCode.XeroContact]: messages.contactObjectName,
        [domain.IntegrationCode.XeroCreditNotesPayable]: messages.creditNotesPayableObjectName,
        [domain.IntegrationCode.XeroCreditNotesReceivable]: messages.creditNodesReceivableObjectName,
        [domain.IntegrationCode.XeroInvoice]: messages.invoiceObjectName,
        [domain.IntegrationCode.XeroManualJournal]: messages.xeroManualJournalObjectName,
        [domain.IntegrationCode.XeroPo]: messages.poObjectName,
        [domain.IntegrationCode.XeroQuote]: messages.quoteObjectName,
    };
    const pluralDisplayNameMap: Record<domain.IntegrationCode, string> = {
        [domain.IntegrationCode.DearPo]: messages.poObjectNamePlural,
        [domain.IntegrationCode.NetSuiteBillPayment]: messages.netSuiteBillPaymentObjectNamePlural,
        [domain.IntegrationCode.NetSuiteBill]: messages.billObjectNamePlural,
        [domain.IntegrationCode.NetSuiteExpenseReport]: messages.netSuiteExpenseReportObjectNamePlural,
        [domain.IntegrationCode.NetSuitePO]: messages.poObjectNamePlural,
        [domain.IntegrationCode.NetSuiteRA]: messages.netSuiteRAObjectNamePlural,
        [domain.IntegrationCode.NetSuiteSalesOrder]: messages.netSuiteSalesOrderObjectNamePlural,
        [domain.IntegrationCode.NetSuiteInvoice]: messages.netSuiteInvoiceObjectNamePlural,
        [domain.IntegrationCode.NetSuiteVRA]: messages.netSuiteVRAObjectNamePlural,
        [domain.IntegrationCode.QBooksBill]: messages.billObjectNamePlural,
        [domain.IntegrationCode.QBooksExpense]: messages.expenseObjectNamePlural,
        [domain.IntegrationCode.QBooksInvoice]: messages.invoiceObjectNamePlural,
        [domain.IntegrationCode.QBooksJournalEntry]: messages.qboJournalEntryObjectNamePlural,
        [domain.IntegrationCode.QBooksPo]: messages.poObjectNamePlural,
        [domain.IntegrationCode.QBooksVendor]: messages.vendorObjectNamePlural,
        [domain.IntegrationCode.XeroAirwallexBatchPayment]: messages.airwallexBatchPaymentNamePlural,
        [domain.IntegrationCode.XeroAmaxPayBatchPayment]: messages.amaxPayBatchPaymentNamePlural,
        [domain.IntegrationCode.XeroBillBatchPayment]: messages.xeroBatchPaymentNamePlural,
        [domain.IntegrationCode.XeroBill]: messages.billObjectNamePlural,
        [domain.IntegrationCode.XeroContact]: messages.contactNamePlural,
        [domain.IntegrationCode.XeroCreditNotesPayable]: messages.creditNotesPayableObjectNamePlural,
        [domain.IntegrationCode.XeroCreditNotesReceivable]: messages.creditNodesReceivableObjectNamePlural,
        [domain.IntegrationCode.XeroInvoice]: messages.invoiceObjectNamePlural,
        [domain.IntegrationCode.XeroManualJournal]: messages.xeroManualJournalObjectNamePlural,
        [domain.IntegrationCode.XeroPo]: messages.poObjectNamePlural,
        [domain.IntegrationCode.XeroQuote]: messages.quoteObjectNamePlural,
    };

    return plural ? pluralDisplayNameMap[integrationCode] : displayNameMap[integrationCode];
};

export const getTemplateWorkflowNameByCode = (integrationCode: domain.IntegrationCode | null): string | null => {
    const templatesDisplayName = {
        [domain.IntegrationCode.XeroBill]: messages.billWorkflowName,
        [domain.IntegrationCode.XeroCreditNotesPayable]: messages.creditNotesPayableWorkflowName,
        [domain.IntegrationCode.XeroInvoice]: messages.invoiceWorkflowName,
        [domain.IntegrationCode.XeroCreditNotesReceivable]: messages.creditNodesReceivableWorkflowName,
        [domain.IntegrationCode.XeroPo]: messages.poWorkflowName,
        [domain.IntegrationCode.XeroQuote]: messages.xeroQuoteWorkflowName,
        [domain.IntegrationCode.XeroContact]: messages.xeroContactWorkflowName,
        [domain.IntegrationCode.QBooksPo]: messages.qboPoWorkflowName,
        [domain.IntegrationCode.QBooksBill]: messages.qboBillWorkflowName,
        [domain.IntegrationCode.QBooksExpense]: messages.qboExpenseWorkflowName,
        [domain.IntegrationCode.QBooksInvoice]: messages.invoiceWorkflowName,
        [domain.IntegrationCode.QBooksVendor]: messages.qboVendorWorkflowName,
        [domain.IntegrationCode.QBooksJournalEntry]: messages.qboJournalEntryWorkflowName,
        [domain.IntegrationCode.NetSuiteBill]: messages.netSuiteBillWorkflowName,
        [domain.IntegrationCode.NetSuitePO]: messages.netSuitePOWorkflowName,
        [domain.IntegrationCode.NetSuiteSalesOrder]: messages.netSuiteSalesOrderWorkflowName,
        [domain.IntegrationCode.NetSuiteInvoice]: messages.netSuiteInvoiceWorkflowName,
        [domain.IntegrationCode.NetSuiteExpenseReport]: messages.netSuiteExpenseReportWorkflowName,
        [domain.IntegrationCode.NetSuiteVRA]: messages.netSuiteVRAWorkflowName,
        [domain.IntegrationCode.NetSuiteBillPayment]: messages.netSuiteBillPaymentWorkflowName,
        [domain.IntegrationCode.NetSuiteRA]: messages.netSuiteRAWorkflowName,
        [domain.IntegrationCode.XeroAmaxPayBatchPayment]: messages.amaxPayBatchPaymentWorkflowName,
        [domain.IntegrationCode.XeroBillBatchPayment]: messages.xeroBillBatchPaymentWorkflowName,
        [domain.IntegrationCode.XeroAirwallexBatchPayment]: messages.xeroAirwallexBatchPaymentWorkflowName,
        [domain.IntegrationCode.DearPo]: messages.poWorkflowName,
        [domain.IntegrationCode.XeroManualJournal]: messages.xeroManualJournalWorkflowName,
    };

    return integrationCode ? templatesDisplayName[integrationCode] : null;
};

export const getTemplateSettingsFraudEffectiveDateLimits = (
    lastEffectiveDate: EffectiveDate | null
): EffectiveDateLimits => {
    const maxDate = moment().startOf('day').utcOffset(0, true).toISOString();

    const currentDate = moment();
    const currentYearMarch = moment().month('March').startOf('month');
    const currentYearJanuary = moment().month('January').startOf('month');

    let minDate =
        currentDate < currentYearMarch
            ? currentYearMarch.subtract(1, 'year').startOf('month').utcOffset(0, true).toISOString()
            : currentYearJanuary.utcOffset(0, true).toISOString();

    if (lastEffectiveDate) {
        const lastEffectiveDateM = moment.utc(lastEffectiveDate);

        minDate = moment.max(moment.utc(minDate), lastEffectiveDateM).toISOString();
    }

    return {
        minDate,
        maxDate,
    };
};

export const getTemplateHasAnySubmitters: (state: stateTree.State, template: domain.Template) => boolean =
    createCachedSelector(
        (state: stateTree.State, template: domain.Template) => template,
        (state: stateTree.State, template: domain.Template) =>
            selectors.company.getCompanyById(state, template.companyId),
        (template, company) => {
            const integrationCode = template.integrationCode;
            const hasRegularSubmitter = template.submitterMatrix.length > 0;
            const hasExternalSubmitter = Boolean(template.externalSubmitter);
            const hasRBSubmitter = Boolean(template.receiptBankExternalSubmitter);
            const rBSubmitterRequired = company.receiptBankIntegration?.isConnected;

            switch (integrationCode) {
                case domain.IntegrationCode.XeroAmaxPayBatchPayment:
                case domain.IntegrationCode.QBooksExpense:
                case domain.IntegrationCode.QBooksInvoice:
                case domain.IntegrationCode.QBooksJournalEntry:
                case domain.IntegrationCode.QBooksPo:
                case domain.IntegrationCode.QBooksVendor:
                case domain.IntegrationCode.XeroAirwallexBatchPayment:
                case domain.IntegrationCode.XeroBillBatchPayment:
                case domain.IntegrationCode.XeroContact:
                case domain.IntegrationCode.XeroManualJournal:
                case null:
                    return hasRegularSubmitter;

                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                case domain.IntegrationCode.NetSuiteSalesOrder:
                case domain.IntegrationCode.NetSuiteInvoice:
                case domain.IntegrationCode.NetSuiteVRA:
                case domain.IntegrationCode.NetSuiteBillPayment:
                case domain.IntegrationCode.NetSuiteRA:
                case domain.IntegrationCode.DearPo:
                    return hasExternalSubmitter;

                case domain.IntegrationCode.XeroPo:
                case domain.IntegrationCode.XeroInvoice:
                case domain.IntegrationCode.XeroQuote:
                case domain.IntegrationCode.NetSuiteBill:
                case domain.IntegrationCode.NetSuiteExpenseReport:
                case domain.IntegrationCode.NetSuitePO:
                    return hasRegularSubmitter || hasExternalSubmitter;

                case domain.IntegrationCode.XeroBill:
                    if (!hasRBSubmitter && rBSubmitterRequired) {
                        return false;
                    }

                    return hasExternalSubmitter || hasRegularSubmitter;

                case domain.IntegrationCode.QBooksBill:
                    if (!hasRBSubmitter && rBSubmitterRequired) {
                        return false;
                    }

                    return hasRegularSubmitter;

                default:
                    throw errorHelpers.assertNever(integrationCode);
            }
        }
    )((state: stateTree.State, template: domain.Template) => template.id);

export const getTemplateHasAnyPayers: (state: stateTree.State, template: domain.Template) => boolean =
    createCachedSelector(
        (state: stateTree.State, template: domain.Template) => template,
        (state: stateTree.State, template: domain.Template) =>
            selectors.company.getCompanyById(state, template.companyId),
        (template, company) => {
            const integrationCode = template.integrationCode;
            const hasPayer = Boolean(template.payerMatrix?.length);

            switch (integrationCode) {
                case domain.IntegrationCode.XeroAirwallexBatchPayment:
                case domain.IntegrationCode.XeroAmaxPayBatchPayment:
                    return hasPayer;

                case domain.IntegrationCode.DearPo:
                case domain.IntegrationCode.NetSuiteBill:
                case domain.IntegrationCode.NetSuiteBillPayment:
                case domain.IntegrationCode.NetSuiteExpenseReport:
                case domain.IntegrationCode.NetSuitePO:
                case domain.IntegrationCode.NetSuiteRA:
                case domain.IntegrationCode.NetSuiteSalesOrder:
                case domain.IntegrationCode.NetSuiteInvoice:
                case domain.IntegrationCode.NetSuiteVRA:
                case domain.IntegrationCode.QBooksBill:
                case domain.IntegrationCode.QBooksExpense:
                case domain.IntegrationCode.QBooksInvoice:
                case domain.IntegrationCode.QBooksJournalEntry:
                case domain.IntegrationCode.QBooksPo:
                case domain.IntegrationCode.QBooksVendor:
                case domain.IntegrationCode.XeroBill:
                case domain.IntegrationCode.XeroBillBatchPayment:
                case domain.IntegrationCode.XeroContact:
                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                case domain.IntegrationCode.XeroInvoice:
                case domain.IntegrationCode.XeroManualJournal:
                case domain.IntegrationCode.XeroPo:
                case domain.IntegrationCode.XeroQuote:
                case null:
                    return true;

                default:
                    throw errorHelpers.assertNever(integrationCode);
            }
        }
    )((state: stateTree.State, template: domain.Template) => template.id);

export const isTemplateValid = createCachedSelector(
    (state: stateTree.State, template: domain.Template) => template,
    (state: stateTree.State, template: domain.Template) => getTemplateHasAnySubmitters(state, template),
    (state: stateTree.State, template: domain.Template) => getTemplateHasAnyPayers(state, template),
    (state: stateTree.State, template: domain.Template) =>
        getTemplateDisplayNameByCode(template.integrationCode) || template.templateName,
    (template, hasTemplateAnySubmitters, hasTemplateAnyPayers, displayName) => {
        const validSteps =
            template.steps.length > 0 && template.steps.every((s) => isValidIntermediateStep(s) && s.name);
        const hasName = Boolean(displayName);

        return validSteps && hasTemplateAnySubmitters && hasTemplateAnyPayers && hasName;
    }
)((state: stateTree.State, template: domain.Template, displayName: string) => displayName + template.id);

export const expandTemplate: (template: domain.Template) => ExpandedTemplate = createCachedSelector(
    (template: domain.Template) => template,
    (template) => {
        const displayName = getTemplateDisplayNameByCode(template.integrationCode) || template.templateName;
        const displayNamePlural = getTemplateDisplayNameByCode(template.integrationCode, true) || template.templateName;

        return {
            ...template,
            isNew: template.id.startsWith(TEMPLATE_ID_PREFIX),
            displayName,
            displayNamePlural,
            workflowName: getTemplateWorkflowNameByCode(template.integrationCode) || template.templateName,
        };
    }
)((template: domain.Template) => template.id);

export function findTemplateById(state: stateTree.State, templateId: string): ExpandedTemplate | null {
    const template = state.entities.templates && state.entities.templates[templateId];

    if (!template) {
        return null;
    }

    return expandTemplate(template);
}

export function getTemplateById(state: stateTree.State, templateId: string): ExpandedTemplate {
    const template = state.entities.templates[templateId];

    if (!template) {
        throw errorHelpers.notFoundError();
    }

    return expandTemplate(template);
}

export function getTemplateByIdSilent(state: stateTree.State, templateId: string): ExpandedTemplate | null {
    const template = state.entities.templates[templateId];

    if (!template) {
        return null;
    }

    return expandTemplate(template);
}

export const getTemplatesByCompanyId: (state: stateTree.State, companyId: string) => ExpandedTemplate[] =
    createCachedSelector(
        (state: stateTree.State, companyId: string) => state.entities.templates,
        (state: stateTree.State, companyId: string) => selectors.company.getCompanyById(state, companyId),
        (templates, company) => {
            return filter(templates, (t) => {
                if (t.companyId !== company.id) {
                    return false;
                }

                const isXeroManualJournalAvailable =
                    company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroManualJournals) ||
                    company.betaFeatures.includes(domain.CompanyBetaFeature.XeroManualJournal);

                const isXeroContactAvailable = company.licenseFeatures.includes(
                    domain.CompanyLicenseFeature.XeroContactWorkflows
                );
                const isQbooksVendorAvailable = company.licenseFeatures.includes(
                    domain.CompanyLicenseFeature.QBOVendorWorkflows
                );
                const isXeroBatchPaymentAvailable = company.licenseFeatures.includes(
                    domain.CompanyLicenseFeature.XeroBillBatchPayments
                );
                const hasRights = company.flags.isManager || company.flags.isAuditor || company.flags.isWorkflowManager;

                const isQBOJournalEntryAvailable = company.licenseFeatures.includes(
                    domain.CompanyLicenseFeature.QBOJournalEntryWorkflows
                );

                if (t.integrationCode === domain.IntegrationCode.XeroContact) {
                    return hasRights && isXeroContactAvailable;
                }

                if (t.integrationCode === domain.IntegrationCode.QBooksVendor) {
                    return hasRights && isQbooksVendorAvailable;
                }

                if (t.integrationCode === domain.IntegrationCode.XeroBillBatchPayment) {
                    return hasRights && isXeroBatchPaymentAvailable;
                }

                if (t.integrationCode === domain.IntegrationCode.QBooksJournalEntry) {
                    return hasRights && isQBOJournalEntryAvailable;
                }

                if (t.integrationCode === domain.IntegrationCode.XeroManualJournal) {
                    return hasRights && isXeroManualJournalAvailable;
                }

                return true;
            })
                .map((template) => expandTemplate(template))
                .sort(
                    compareHelpers.comparatorFor<ExpandedTemplate>(compareHelpers.stringComparator2AscI, 'displayName')
                );
        }
    )((state: stateTree.State, companyId: string) => companyId);

export const getAllTemplates: (state: stateTree.State) => ExpandedTemplate[] = createSelector(
    (state: stateTree.State) => state.entities.templates,
    (templates) => {
        return Object.values(templates || {}).map((template) => expandTemplate(template));
    }
);

export const getIntegrationTemplatesByCompanyId: (state: stateTree.State, companyId: string) => ExpandedTemplate[] =
    createCachedSelector(
        (state: stateTree.State, companyId: string) => state.entities.templates,
        (state: stateTree.State, companyId: string) => companyId,
        (templates, companyId) => {
            return filter(
                templates,
                (t) =>
                    t.companyId === companyId &&
                    selectors.integration.getIntegrationType(t.integrationCode) !== domain.IntegrationType.None
            ).map((template) => expandTemplate(template));
        }
    )((state: stateTree.State, companyId: string) => companyId);

export const getStandaloneTemplatesByCompanyId: (state: stateTree.State, companyId: string) => ExpandedTemplate[] =
    createCachedSelector(
        (state: stateTree.State, companyId: string) => state.entities.templates,
        (state: stateTree.State, companyId: string) => companyId,
        (templates, companyId) => {
            return filter(
                templates,
                (t) =>
                    t.companyId === companyId &&
                    selectors.integration.getIntegrationType(t.integrationCode) === domain.IntegrationType.None
            ).map((template) => expandTemplate(template));
        }
    )((state: stateTree.State, companyId: string) => companyId);

export function getTemplateUsers(state: stateTree.State, template: domain.Template): string[] {
    let users: string[] = [];

    if (template.externalSubmitter) {
        users.push(template.externalSubmitter);
    }

    if (template.receiptBankExternalSubmitter) {
        users.push(template.receiptBankExternalSubmitter);
    }

    if (template.emailExternalSubmitter) {
        users.push(template.emailExternalSubmitter);
    }

    template.submitterMatrix.forEach((x) => users.push(x.lineId));

    template.steps.forEach((s) => {
        s.participantMatrix.forEach((x) => users.push(x.lineId));
        s.editorMatrix.forEach((x) => users.push(x.lineId));
    });

    if (template.payerMatrix) {
        template.payerMatrix.forEach((payer) => {
            users.push(payer.lineId);
        });
    }

    template.reviewStep.reviewers.forEach((reviewer) => {
        users.push(reviewer.lineId);
    });

    return users;
}

export function validateTemplateUsers(state: stateTree.State, template: domain.Template): string[] {
    const team = selectors.company.getCompanyById(state, template.companyId).allMembers.map((x) => x.id);

    function isValid(userId: string) {
        return team.includes(userId);
    }

    function getUserText(userId: string) {
        return selectors.user.getUserById(state, userId).displayName;
    }

    let errors: string[] = [];

    if (template.externalSubmitter && !isValid(template.externalSubmitter)) {
        errors.push(
            messages.invalidUserExternalSubmitter({
                user: getUserText(template.externalSubmitter),
            })
        );
    }

    if (template.emailExternalSubmitter && !isValid(template.emailExternalSubmitter)) {
        errors.push(
            messages.invalidUserExternalSubmitter({
                user: getUserText(template.emailExternalSubmitter),
            })
        );
    }

    if (template.receiptBankExternalSubmitter && !isValid(template.receiptBankExternalSubmitter)) {
        errors.push(
            messages.invalidUserDextExternalSubmitter({
                user: getUserText(template.receiptBankExternalSubmitter),
            })
        );
    }

    template.submitterMatrix
        .filter((x) => !isValid(x.lineId))
        .forEach((x) => {
            errors.push(
                messages.invalidUserRequester({
                    user: getUserText(x.lineId),
                })
            );
        });
    template.steps.forEach((s, i) => {
        s.participantMatrix
            .filter((x) => !isValid(x.lineId))
            .forEach((x) => {
                errors.push(
                    messages.invalidUserApproverInStep({
                        user: getUserText(x.lineId),
                        stepName: s.name,
                    })
                );
            });

        if (i === 0) {
            s.editorMatrix
                .filter((x) => !isValid(x.lineId))
                .forEach((x) => {
                    errors.push(
                        messages.invalidUserReviewerInStep({
                            user: getUserText(x.lineId),
                            stepName: s.name,
                        })
                    );
                });
        }
    });

    return errors;
}

interface GetTemplateTransferConfig {
    skipEmptyAutoApprovalRules?: boolean;
}

const getTemplateReviewStepTransfer = (
    reviewStep: domain.TemplateReviewStep,
    mapRules: (rules: domain.MatrixRule[]) => backend.transfers.RuleTransfer[],
    lookupUserEmail: (userId: string) => string
): backend.transfers.TemplateReviewStepTransfer | null => {
    if (reviewStep.reviewers.length === 0) return null;

    return {
        requiredFieldIds: reviewStep.requiredFieldIds,
        readonlyFieldIds: reviewStep.readonlyFieldIds,
        deadlineRule: reviewStep.deadlineRule || null,
        reviewers: reviewStep.reviewers.map((reviewer) => {
            const rules = mapRules(reviewer.rules);
            const uniqRules = uniqWith(rules, (a, b) => isEqual(a, b));

            return {
                email: lookupUserEmail(reviewer.lineId),
                rules: uniqRules,
                isBackup: Boolean(reviewer.isBackup),
            };
        }),
    };
};

export function getTemplateTransfer(
    state: stateTree.State,
    template: domain.Template,
    config: GetTemplateTransferConfig = {
        skipEmptyAutoApprovalRules: false,
    }
): backend.transfers.TemplateCreateTransfer | backend.transfers.TemplateEditTransfer {
    const fieldsLoaded = !selectors.field.fieldSetExpired(state, {
        type: stateTree.FieldSetType.TemplateFields,
        companyId: template.companyId,
        templateIntegrationCode: template.integrationCode,
    });
    const fields = selectors.field.getFieldsByCompanyId(state, template.companyId);

    const { skipEmptyAutoApprovalRules = false } = config;

    function isValidFieldId(fieldId: string) {
        return !fieldsLoaded || fields.some((f) => f.id === fieldId);
    }

    function lookupUserEmail(userId: string): string {
        const user = selectors.user.getUserById(state, userId);

        return user.userEmail;
    }

    function mapExactValues(reference: Reference) {
        // Fixes AM-1203 (broken values in the database, can be removed later)
        const user = Object.values(state.entities.users).find((x) => x.userEmail === reference.id);
        const id = user ? user.databaseId : reference.id;

        return {
            Id: id,
            Value: reference.text,
        };
    }

    function mapRules(rules: domain.MatrixRule[]): backend.transfers.RuleTransfer[] {
        const result: (backend.transfers.RuleTransfer | null)[] = rules.map((r) => {
            const conditions: (backend.transfers.RuleConditionTransfer | null)[] = r.conditions
                .filter((condition) => isValidFieldId(condition.fieldId))
                .map((condition: domain.MatrixCondition) => {
                    let conditionType;
                    let fieldValuesFilterType;
                    let exactConstraint;
                    let numericRangeLess;
                    let numericRangeGreaterEquals;
                    let amountTypeTransfer;
                    let exactConstraintBool;

                    switch (condition.conditionType) {
                        case domain.ConditionType.NumericRangeCondition:
                            conditionType = backend.FieldType.NumericRange;
                            numericRangeLess =
                                condition.numericRangeConditionType !== domain.NumericRangeConditionType.Above
                                    ? condition.numericRangeLess
                                    : undefined;
                            numericRangeGreaterEquals =
                                condition.numericRangeConditionType !== domain.NumericRangeConditionType.Below
                                    ? condition.numericRangeGreaterEquals
                                    : null;

                            switch (condition.amountType) {
                                case domain.AmountType.Gross:
                                    amountTypeTransfer = backend.AmountConditionType.Gross;
                                    break;

                                case domain.AmountType.Net:
                                    amountTypeTransfer = backend.AmountConditionType.Net;
                                    break;

                                default:
                                    errorHelpers.throwInvalidOperationError();
                            }

                            break;

                        case domain.ConditionType.ExactValuesCondition: {
                            conditionType = backend.FieldType.ExactValueRange;

                            let exactValues = condition.exactValues;

                            if (condition.fieldSystemPurpose === domain.FieldSystemPurpose.Requester) {
                                const submitters = template.submitterMatrix.map((x) =>
                                    selectors.user.getUserById(state, x.lineId)
                                );

                                exactValues = exactValues.filter((v) =>
                                    submitters.some((x) => x.userEmail === v.id || x.databaseId === v.id)
                                );

                                if (exactValues.length === 0) {
                                    return null;
                                }
                            }

                            exactConstraint = exactValues.map(mapExactValues);
                            break;
                        }

                        case domain.ConditionType.NegativeExactValuesCondition:
                            conditionType = backend.FieldType.ExactValueRangeNegative;
                            exactConstraint = condition.exactValues.map(mapExactValues);
                            break;

                        case domain.ConditionType.ServerCondition:
                            conditionType = backend.FieldType.ExactValueRange;

                            switch (condition.serverConditionType) {
                                case domain.ServerConditionType.AllContacts:
                                    fieldValuesFilterType = backend.ServerConditionType.AllContacts;
                                    break;

                                case domain.ServerConditionType.CustomersOnly:
                                    fieldValuesFilterType = backend.ServerConditionType.CustomersOnly;
                                    break;

                                case domain.ServerConditionType.SuppliersOnly:
                                    fieldValuesFilterType = backend.ServerConditionType.SuppliersOnly;
                                    break;

                                default:
                                    throw errorHelpers.invalidOperationError();
                            }

                            exactConstraint = [];
                            break;

                        case domain.ConditionType.BoolCondition:
                            conditionType = backend.FieldType.ExactValueRange;
                            exactConstraint = undefined;
                            exactConstraintBool = condition.exactConstraintBool;

                            break;

                        case null:
                            if ('allowEditing' in condition || 'allowCreation' in condition) {
                                conditionType = backend.FieldType.ExactValueRangeNegative;
                                exactConstraint = [];
                            } else {
                                return null;
                            }

                            break;

                        default:
                            throw errorHelpers.invalidOperationError();
                    }

                    return {
                        fieldId: condition.fieldId,
                        name: condition.fieldName,
                        amountType: amountTypeTransfer,
                        conditionType,
                        allowCreation: condition.allowCreation,
                        fieldValuesFilterType,
                        exactConstraint,
                        rangeConstraintLess: numericRangeLess ?? undefined,
                        rangeConstraintGreaterEquals: numericRangeGreaterEquals ?? undefined,
                        allowEditing: condition.allowEditing,
                        exactBooleanConstraint: exactConstraintBool,
                    };
                });

            const filteredConditions = conditions.reduce((accum, condition) => {
                if (!condition) return accum;

                return [...accum, condition];
            }, []);

            if (filteredConditions.length === 0) {
                return null;
            }

            return { conditions: filteredConditions };
        });

        return result.reduce((accum, rule) => {
            if (!rule) return accum;

            return [...accum, rule];
        }, []);
    }

    return {
        ...(!expandTemplate(template).isNew && { templateId: template.id }),
        companyId: template.companyId,
        templateName: template.templateName,
        version: template.version,
        comment: template.comment,
        // RB submitter is not empty for Bills if connection with Receipt Bank is established
        receiptBankExternalSubmitter: template.receiptBankExternalSubmitter
            ? lookupUserEmail(template.receiptBankExternalSubmitter)
            : '',
        externalSubmitter: template.externalSubmitter ? lookupUserEmail(template.externalSubmitter) : '',
        emailExternalSubmitter: template.emailExternalSubmitter ? lookupUserEmail(template.emailExternalSubmitter) : '',
        submitters: template.submitterMatrix.map((x) => {
            return {
                email: lookupUserEmail(x.lineId),
                rules: mapRules(x.rules),
            };
        }),
        submitterRuleOrders: template.submitterRuleOrders,
        payers: template.payerMatrix?.map((x) => {
            return {
                email: lookupUserEmail(x.lineId),
                rules: mapRules(x.rules),
            };
        }),
        autoapprovalRules: template.autoApprovalRules
            .map((x) => {
                return {
                    name: x.lineId,
                    rules: mapRules(x.rules),
                };
            })
            .map((item) => {
                const conditions = item.rules[0]?.conditions || [];

                if (skipEmptyAutoApprovalRules && !conditions.length) {
                    return null;
                }

                return {
                    name: item.name,
                    conditions,
                };
            })
            .filter(miscHelpers.notEmptyFilter),
        steps: template.steps.map((s) => {
            return {
                name: s.name,
                type: backend.StepType[s.type],
                defaultDuration: s.defaultDuration,
                deadlineRule: s.deadlineRule || null,
                participants: s.participantMatrix.map((matrixLine) => {
                    const editingMatrixLine = s.editingMatrix.find((line) => line.lineId === matrixLine.lineId);
                    const rules = mapRules(matrixLine.rules);
                    const editPermissions = editingMatrixLine ? mapRules(editingMatrixLine.rules) : [];

                    return {
                        email: lookupUserEmail(matrixLine.lineId),
                        rules: uniqWith(rules, (a, b) => isEqual(a, b)),
                        editPermissions: uniqWith(editPermissions, (a, b) => isEqual(a, b)),
                        isBackup: matrixLine.isBackup,
                    };
                }),
                editors: s.editorMatrix.map((x) => {
                    return {
                        email: lookupUserEmail(x.lineId),
                        rules: mapRules(x.rules),
                    };
                }),
                ruleOrder: s.generalFieldOrder,
                requiredFieldIds: (s.requiredFieldIds || []).filter((fId) => isValidFieldId(fId)),
                readonlyFieldIds: (s.readonlyFieldIds || []).filter((fId) => isValidFieldId(fId)),
                editPermissionsRequiredFieldIds: (s.editPermissionsRequiredFieldIds || []).filter((fId) =>
                    isValidFieldId(fId)
                ),
                approvalCount: s.approvalCount,
            };
        }),
        integrationCode: template.integrationCode,
        enabled: template.enabled,
        requiredFieldIds: (template.requiredFieldIds || []).filter((fId) => isValidFieldId(fId)),
        fieldVersions: (template.documentFields || []).map((field) => ({
            purpose: field.purpose,
            state: field.state,
        })),
        reviewStep: getTemplateReviewStepTransfer(template.reviewStep, mapRules, lookupUserEmail),
    };
}

export const getHasAccessToReviewStepByIntegrationCode = (integrationCode?: domain.IntegrationCode | null) => {
    return Boolean(
        integrationCode &&
            ![
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
                domain.IntegrationCode.XeroCreditNotesPayable,
                domain.IntegrationCode.XeroCreditNotesReceivable,
                domain.IntegrationCode.QBooksInvoice,
            ].includes(integrationCode)
    );
};
