import decimal from './lib/commonjs/decimal';

function mathEval(expression: string): number | null {
    const mulDiv = /([+-]?\d*\.?\d+(?:e[+-]\d+)?)\s*([*/])\s*([+-]?\d*\.?\d+(?:e[+-]\d+)?)/;
    const plusMin = /([+-]?\d*\.?\d+(?:e[+-]\d+)?)\s*([+-])\s*([+-]?\d*\.?\d+(?:e[+-]\d+)?)/;
    const parentheses = /(\d)?\s*\(([^()]*)\)\s*/;

    let current;

    while (expression.search(/^\s*([+-]?\d*\.?\d+(?:e[+-]\d+)?)\s*$/) === -1) {
        expression = fParentheses(expression);

        if (expression === current) {
            return null;
        }

        current = expression;
    }

    return +expression;

    function fParentheses(expression: string) {
        while (expression.search(parentheses) !== -1) {
            expression = expression.replace(parentheses, function (a, b, c) {
                c = fMulDiv(c);
                c = fPlusMin(c);

                return typeof b === 'string' ? b + '*' + c : c;
            });
        }

        expression = fMulDiv(expression);
        expression = fPlusMin(expression);

        return expression;
    }

    function fMulDiv(expression: string) {
        while (expression.search(mulDiv) !== -1) {
            expression = expression.replace(mulDiv, function (a: string) {
                const sides = mulDiv.exec(a);

                if (!sides) {
                    return '';
                }

                const result =
                    sides[2] === '*' ? decimal.multiply(+sides[1], +sides[3]) : decimal.divide(+sides[1], +sides[3]);

                return result >= 0 ? '+' + result : String(result);
            });
        }

        return expression;
    }

    function fPlusMin(expression: string) {
        expression = expression.replace(/([+-])([+-])(\d|\.)/g, function (a, b, c, d) {
            return (b === c ? '+' : '-') + d;
        });

        while (expression.search(plusMin) !== -1) {
            expression = expression.replace(plusMin, function (a) {
                const sides = plusMin.exec(a);

                if (!sides) {
                    return '';
                }

                const result =
                    sides[2] === '+' ? decimal.add(+sides[1], +sides[3]) : decimal.subtract(+sides[1], +sides[3]);

                return String(result);
            });
        }

        return expression;
    }
}

export default mathEval;
