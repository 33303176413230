import './exactAsyncConditionCell.scss';

import { Guid, Reference } from '@approvalmax/types';
import { ButtonElement, Dropdown, DropdownBoxItemProps, DropdownEditor, TransparentButton } from '@approvalmax/ui';
import { arrayHelpers, intl } from '@approvalmax/utils';
import { constants, dataProviders, selectors, ui } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import { mapExactValuesForRequesterSystemPurpose } from 'pages/workflows/utils/helpers';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { MatrixType } from '../../../types/matrix';

const i18nPrefix = 'workflows.components.card.cells.ExactAsyncConditionCell';

const messages = defineMessages({
    rule_editor_field_combo_approve_AnyValue: {
        id: `${i18nPrefix}.rule_editor_field_combo_approve_AnyValue`,
        defaultMessage: 'Any {fieldName}',
    },

    rule_editor_field_combo_approve_Specific: {
        id: `${i18nPrefix}.rule_editor_field_combo_approve_Specific`,
        defaultMessage: '{fieldName} matches',
    },

    rule_editor_field_combo_approve_Except: {
        id: `${i18nPrefix}.rule_editor_field_combo_approve_Except`,
        defaultMessage: '{fieldName} does not match',
    },

    rule_editor_field_value_placeholder: {
        id: `${i18nPrefix}.rule_editor_field_value_placeholder`,
        defaultMessage: 'Select {fieldName}',
    },
});

interface OwnProps {
    lineId: string;
    rule: domain.MatrixRule;
    field: domain.Field;
    integrationCode: domain.IntegrationCode | null;
    condition: domain.ExactValuesCondition | domain.AlwaysTrueCondition;
    readonly?: boolean;
    templateSubmitters: selectors.types.ExpandedCompanyUser[];
    onConditionChange(
        lineId: string,
        rule: domain.MatrixRule,
        field: domain.Field,
        newCondition: domain.ExactValuesCondition | domain.AlwaysTrueCondition
    ): void;
    requiredFieldIds: Guid[];
    matrixType: MatrixType;
    compactPaddingBottom?: boolean;
    noEmptyValue?: boolean;
}

const mapStateToProps = (state: stateTree.State, props: OwnProps) => {
    return {
        users: selectors.user.getUsers(state),
    };
};

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, {}>;

interface State {
    isConditionTypeDropdownOpen: boolean;
}

class ExactAsyncConditionCell extends React.Component<Props, State> {
    public state = {
        isConditionTypeDropdownOpen: false,
    };

    private _conditionTypeDropdown!: ButtonElement;
    private _getTemplateSubmitters = createSelector(
        (props: Props) => this.props.templateSubmitters,
        (props: Props) => {
            return this.props.condition.conditionType !== null
                ? this.props.condition.exactValues
                : arrayHelpers.emptyArray();
        },
        (templateSubmitters, exactValues) => {
            const data = templateSubmitters.map((s) => ({
                ...s,
                id: s.userEmail,
            }));
            const submittersWithoutSelected = data.filter(
                (s) => !exactValues.some((exactValue) => exactValue.id === s.databaseId)
            );

            return submittersWithoutSelected;
        }
    );

    public shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            this.props.condition !== nextProps.condition ||
            this.state.isConditionTypeDropdownOpen !== nextState.isConditionTypeDropdownOpen ||
            this.props.requiredFieldIds.length !== nextProps.requiredFieldIds.length
        );
    }

    public render() {
        const {
            condition,
            field,
            readonly,
            matrixType,
            integrationCode,
            requiredFieldIds,
            compactPaddingBottom,
            users,
            noEmptyValue,
        } = this.props;
        const { isConditionTypeDropdownOpen } = this.state;

        const empty = condition.conditionType === null;
        const invalid = !selectors.matrix.isValidCondition(condition);
        const conditionTypeText = this._getConditionTypeText(condition.conditionType);
        const exactValuesPlaceholder = intl.formatMessage(messages.rule_editor_field_value_placeholder, {
            fieldName: (
                selectors.field.getFieldNameBySystemPurpose(field.systemPurpose, integrationCode, field.name) || ''
            ).toLowerCase(),
        });

        const isOptionalField = !requiredFieldIds.includes(field.id);

        let staticValues: Reference[] = [];

        if (selectors.field.allowsEmptyValue(field, matrixType, isOptionalField) && !noEmptyValue) {
            staticValues = [dataProviders.FieldDataProvider.EmptyValue];
        }

        const bem = bemFactory.block('wfc-exact-async-condition-cell');
        const qa = bemFactory.qa('wfc-exact-async-condition-cell');

        const rangeTypeOptions = [
            null,
            domain.ConditionType.ExactValuesCondition,
            domain.ConditionType.NegativeExactValuesCondition,
        ].map((x) => {
            return (
                <div
                    key={x || 'null'}
                    className={bem('condition-type-panel-i')}
                    data-qa={qa('condition-type-dropdown-item')}
                    data-qa-id={x}
                    onClick={() => this._changeConditionType(x)}
                >
                    {this._getConditionTypeText(x)}
                </div>
            );
        });

        const templateSubmitters = this._getTemplateSubmitters(this.props);

        let exactValues = condition.conditionType !== null ? condition.exactValues : [];

        if (condition.fieldSystemPurpose === domain.FieldSystemPurpose.Requester) {
            exactValues = mapExactValuesForRequesterSystemPurpose(exactValues, users);
        }

        return (
            <div
                className={bem('', { 'compact-padding-bottom': compactPaddingBottom })}
                data-qa={qa()}
                data-qa-id={field.id}
                data-qa-name={field.name}
            >
                <Dropdown
                    onRequestClose={this._closeConditionTypeDropdown}
                    panelFlow='to-right'
                    isOpen={isConditionTypeDropdownOpen}
                    button={
                        <TransparentButton
                            execute={this._openConditionTypeDropdown}
                            onFocus={this._openConditionTypeDropdown}
                            onBlur={this._closeConditionTypeDropdown}
                            disabled={readonly}
                            ref={(ref) => (this._conditionTypeDropdown = ref!)}
                            qa={qa('condition-type-menu')}
                            className={bem('condition-dropdown-btn', { empty, readonly })}
                            title={conditionTypeText}
                        >
                            {conditionTypeText}
                        </TransparentButton>
                    }
                >
                    <div className={bem('condition-type-panel')} onMouseDown={this._preventBlur}>
                        {rangeTypeOptions}
                    </div>
                </Dropdown>

                {!empty && (
                    <dataProviders.FieldDataProvider
                        staticValues={staticValues}
                        field={field}
                        templateSubmitters={templateSubmitters}
                        integrationCode={integrationCode}
                    >
                        <DropdownEditor
                            multiple
                            theme='matrix'
                            invalid={invalid}
                            placeholder={exactValuesPlaceholder}
                            disabled={readonly}
                            value={exactValues}
                            onChange={this._changeExactValues}
                            qa={qa('values-editor')}
                            boxItem={this._renderBoxItem}
                            listItem={
                                field.systemPurpose === domain.FieldSystemPurpose.Requester
                                    ? ui.CompanyUserListItem
                                    : DropdownEditor.ListItem
                            }
                        />
                    </dataProviders.FieldDataProvider>
                )}
            </div>
        );
    }

    private _preventBlur = (e: React.SyntheticEvent<any>) => {
        e.preventDefault();
    };

    private _changeConditionType = (conditionType: domain.ConditionType | null) => {
        let newCondition;

        if (conditionType === null) {
            newCondition = {
                ...({
                    ...this.props.condition,
                    exactValues: [],
                } as domain.ExactValuesCondition),
                conditionType,
            } as domain.AlwaysTrueCondition;
        } else {
            newCondition = {
                ...this.props.condition,
                conditionType,
                // this update is important since DropdownEditor is a PureComponent
                // and doesn't know that its BoxItem depends on conditionType
                exactValues: arrayHelpers.cloneImmutableArray(
                    (this.props.condition as domain.ExactValuesCondition).exactValues || []
                ),
            } as domain.ExactValuesCondition;
        }

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);
        this._closeConditionTypeDropdown();
    };

    private _changeExactValues = (exactValues: Reference[]) => {
        let newCondition = {
            ...this.props.condition,
            exactValues,
        };

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);
    };

    private _openConditionTypeDropdown = () => {
        this.setState({
            isConditionTypeDropdownOpen: true,
        });
    };

    private _closeConditionTypeDropdown = () => {
        this.setState({
            isConditionTypeDropdownOpen: false,
        });
    };

    private _getConditionTypeText = (conditionType: domain.ConditionType | null) => {
        const conditionTypeMap: {
            [conditionType: string]: MessageDescriptor;
        } = {
            null: messages.rule_editor_field_combo_approve_AnyValue,
            [domain.ConditionType.ExactValuesCondition]: messages.rule_editor_field_combo_approve_Specific,
            [domain.ConditionType.NegativeExactValuesCondition]: messages.rule_editor_field_combo_approve_Except,
        };

        return intl.formatMessage(conditionTypeMap[conditionType || 'null'] || conditionTypeMap.null, {
            fieldName: selectors.field.getFieldNameBySystemPurpose(
                this.props.field.systemPurpose,
                this.props.integrationCode,
                this.props.field.name
            ),
        });
    };

    private _renderBoxItem = (boxItemProps: DropdownBoxItemProps) => {
        const negative = this.props.condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;
        const emptyItem = boxItemProps.item.id === constants.commonConstants.EMPTY_VALUE_ID;

        let strikeThrough = false;

        const templateSubmitters = this.props.templateSubmitters;

        if (this.props.field.systemPurpose === domain.FieldSystemPurpose.Requester) {
            strikeThrough = !templateSubmitters.some(
                (ts) => ts.userEmail === boxItemProps.item.id || ts.databaseId === boxItemProps.item.id
            );
        }

        return (
            <DropdownEditor.BoxItem
                {...boxItemProps}
                strikeThrough={strikeThrough}
                theme={negative || emptyItem ? DropdownEditor.BoxItemTheme.Grey : DropdownEditor.BoxItemTheme.Green}
            />
        );
    };
}

export default connect(mapStateToProps)(ExactAsyncConditionCell);
