import { FC, PropsWithChildren } from 'react';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { ErrorPlaceholder, ErrorPlaceholderProps } from './components';

/**
 * This is an ABSTRACT implementation ComponentErrorBoundary.
 * You can't use it directly, because each app has each own implementation of
 * "contact support" popup, that's why you need to use (or create) an
 * implementation within your app
 */
export const ComponentErrorBoundary: FC<PropsWithChildren<ErrorPlaceholderProps>> = (props) => {
    const { onContactSupport, children } = props;

    return (
        <ErrorBoundary errorPlaceholder={<ErrorPlaceholder onContactSupport={onContactSupport} />}>
            {children}
        </ErrorBoundary>
    );
};

ComponentErrorBoundary.displayName = 'ComponentErrorBoundary';
