import { messages } from './NetSuiteIntegrationPopup.messages';
import { FormFieldKey } from './NetSuiteIntegrationPopup.types';

export const formFields: {
    id: FormFieldKey;
    required: boolean;
    title: string;
    type: 'text' | 'password';
}[] = [
    {
        id: 'accountId',
        required: true,
        title: messages.accountIdFieldTitle,
        type: 'text',
    },
    {
        id: 'consumerKey',
        required: true,
        title: messages.consumerKeyFieldTitle,
        type: 'text',
    },
    {
        id: 'consumerSecret',
        required: true,
        title: messages.consumerSecretFieldTitle,
        type: 'password',
    },
    {
        id: 'tokenId',
        required: true,
        title: messages.tokenIdFieldTitle,
        type: 'text',
    },
    {
        id: 'tokenSecret',
        required: true,
        title: messages.tokenSecretFieldTitle,
        type: 'password',
    },
    {
        id: 'subsidiaryId',
        required: true,
        title: messages.subsidiaryIdFieldTitle,
        type: 'text',
    },
];

export const requiredFieldIds: FormFieldKey[] = formFields.filter((field) => field.required).map((field) => field.id);
