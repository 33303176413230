import { Popup, TextEditor } from '@approvalmax/ui';
import { GlobalLoadingBar } from 'modules/page';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { useDearIntegrationForm, useDearIntegrationPopup } from './DearIntegrationPopup.hooks';
import { messages } from './DearIntegrationPopup.messages';
import { FormItem, FormRoot, Root, StyledPopup } from './DearIntegrationPopup.styles';

const qa = bemFactory.qa('dear-integration-popup');

const DearIntegrationPopup: FC = memo(() => {
    const { values, errors, onChangeAccountId, onChangeApplicationKey, validate } = useDearIntegrationForm();
    const { isLoading, onConnect, onCheck, onClose } = useDearIntegrationPopup();

    const connect = useCallback(async () => {
        if (!validate()) {
            return;
        }

        try {
            await onCheck(values);
        } catch (err) {
            // Error notification is displayed by react-query
            // from BE message

            return;
        }

        await onConnect(values);
    }, [onCheck, onConnect, validate, values]);

    return (
        <StyledPopup
            title={messages.popupTitle}
            customCloseHandler={onClose}
            buttons={
                <Popup.DefaultContent.Button
                    disabled={isLoading}
                    execute={connect}
                    qa={qa('connect-button')}
                    title={messages.connectButton}
                >
                    {messages.connectButton}
                </Popup.DefaultContent.Button>
            }
        >
            <>
                <GlobalLoadingBar isLoading={isLoading} />

                <Root>
                    <FormRoot>
                        <FormItem title={messages.fieldAccountId} required>
                            <TextEditor
                                type='text'
                                value={values['accountId']}
                                onChange={onChangeAccountId}
                                invalid={errors.includes('accountId')}
                            />
                        </FormItem>

                        <FormItem title={messages.fieldApplicationKey} required>
                            <TextEditor
                                type='text'
                                value={values['applicationKey']}
                                invalid={errors.includes('applicationKey')}
                                onChange={onChangeApplicationKey}
                            />
                        </FormItem>
                    </FormRoot>
                </Root>
            </>
        </StyledPopup>
    );
});

export default DearIntegrationPopup;
