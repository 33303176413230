import { GetNextPageParam, InfiniteQueryOptions } from '@approvalmax/data';
import { useInfiniteGetRequestLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import {
    UseGetXeroCounterpartiesParams,
    UseGetXeroCounterpartiesPathParams,
    UseGetXeroCounterpartiesResponse,
    UseGetXeroCounterpartiesResponseBackend,
} from './useGetXeroCounterparties.types';

export const useGetXeroCounterparties = (
    pathParams: UseGetXeroCounterpartiesPathParams,
    params: UseGetXeroCounterpartiesParams,
    getNextPageParam: GetNextPageParam<
        UseGetXeroCounterpartiesResponseBackend,
        UseGetXeroCounterpartiesResponse,
        UseGetXeroCounterpartiesParams
    >,
    queryOptions?: InfiniteQueryOptions<UseGetXeroCounterpartiesResponseBackend, UseGetXeroCounterpartiesResponse>
) => {
    return useInfiniteGetRequestLegacy<UseGetXeroCounterpartiesResponseBackend, UseGetXeroCounterpartiesResponse>(
        integrationsApiPaths.getXeroCounterparties,
        {},
        {
            params,
            pathParams,
            getNextPageParam,
            queryOptions,
            method: 'get',
        }
    );
};
