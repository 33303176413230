import { Avatar, Button, Flex, Popup, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { discardStartOverPopup, startOver } from '../../actions';
import { StartOverPopupMode } from '../../reducers/page/activeStartOverReducer';
import { getActiveStartOver, getActiveTemplate } from '../../selectors/pageSelectors';
import { RequestList } from './components';
import { messages } from './StartOverPopup.messages';

const StartOverPopup = memo(() => {
    const dispatch = useDispatch();

    const popupData = useSelector(getActiveStartOver);
    const template = useSelector(getActiveTemplate)!;
    const [commentText, setCommentText] = useState('');
    const [checkedRequests, setCheckedRequests] = useState([] as string[]);
    const isWorkflowManager = !!popupData && 'isWorkflowManager' in popupData && popupData.isWorkflowManager;
    const profile = useSelector(selectors.profile.getProfileUser);

    const discardPopup = useCallback(() => {
        dispatch(discardStartOverPopup());
    }, [dispatch]);

    const onClose = useCallback((open: boolean) => !open && discardPopup(), [discardPopup]);

    const applyAndClose = useCallback(() => {
        if (!popupData) return;

        dispatch(
            startOver({
                templateId: template.id,
                requestIds: checkedRequests,
                companyId: template.companyId,
                commentText,
                isWorkflowManager,
                requestCount: isWorkflowManager ? popupData.quantityRequests : checkedRequests.length,
            })
        );
    }, [popupData, dispatch, template.id, template.companyId, checkedRequests, commentText, isWorkflowManager]);

    if (!popupData) return null;

    return (
        <Popup open={Boolean(popupData)} onToggle={onClose} size='large'>
            <Popup.Header
                title={
                    popupData.mode === StartOverPopupMode.Regular
                        ? messages.startOverDialogTitle
                        : messages.startOverDialogTitleAfterUpdate
                }
                actions={
                    <Flex inline spacing='16' wrap={false}>
                        {(popupData.mode === StartOverPopupMode.AfterUpdate || isWorkflowManager) && (
                            <Button size='medium' onClick={discardPopup} title={messages.skipButtonTitle}>
                                {messages.skipButtonText}
                            </Button>
                        )}

                        <Button
                            size='medium'
                            color='blue80'
                            onClick={applyAndClose}
                            disabled={!isWorkflowManager && checkedRequests.length === 0}
                            title={messages.startOverDialogButtonTitle}
                        >
                            {messages.startOverDialogButton}
                        </Button>
                    </Flex>
                }
            />

            <Popup.Body>
                <Flex inline direction='column' spacing='16'>
                    {isWorkflowManager && (
                        <>
                            <Text font='body' fontSize='medium'>
                                {messages.workflowManagerRestart1}
                            </Text>

                            <Text font='body' fontSize='medium'>
                                {messages.workflowManagerRestart2({ count: popupData.quantityRequests })}
                            </Text>
                        </>
                    )}

                    {!isWorkflowManager && (
                        <Text font='body' fontSize='medium'>
                            {popupData.mode === StartOverPopupMode.Regular
                                ? messages.startOverDialogHintLine1
                                : messages.startOverDialogHintLine1AfterUpdate1}
                        </Text>
                    )}

                    <Text font='body' fontSize='medium'>
                        {messages.startOverDialogHintLine2}
                    </Text>
                </Flex>

                <Spacing height={24} />

                <Flex inline>
                    <Flex shrink={0}>
                        <Avatar
                            alt={`${profile.firstName} ${profile.lastName}`}
                            src={profile.avatar}
                            size={32}
                            color='blue10'
                        />
                    </Flex>

                    <Flex grow={1}>
                        <TextField
                            onChange={setCommentText}
                            name={commentText}
                            multiline
                            maxLength={255}
                            initFocus
                            placeholder={messages.addCommentPlaceholder}
                        />
                    </Flex>
                </Flex>

                <Spacing height={32} />

                {!isWorkflowManager && 'requestIds' in popupData && (
                    <RequestList
                        requestIds={popupData.requestIds}
                        checkedRequests={checkedRequests}
                        setCheckedRequests={setCheckedRequests}
                    />
                )}
            </Popup.Body>
        </Popup>
    );
});

export default StartOverPopup;
