import { Grid, Label, Text } from '@approvalmax/ui/src/components';
import { ComponentType, memo, MemoExoticComponent } from 'react';

import { Loading } from './PaymentDetailsAudit.loading';
import { messages } from './PaymentDetailsAudit.messages';
import { ChildrenComponents, PaymentDetailsAuditProps } from './PaymentDetailsAudit.types';

export const PaymentDetailsAudit = memo<PaymentDetailsAuditProps>((props) => {
    const { createdBy, createdAt, modifiedBy, modifiedAt } = props;

    return (
        <Grid gap={8}>
            <div>
                <Label size='xsmall'>{messages.createdBy({ name: createdBy })}</Label>

                <Text font='label' fontSize='xxsmall'>
                    {props.createdAt}
                </Text>
            </div>

            {modifiedBy ? (
                <div>
                    <Label size='xsmall'>{messages.modifiedBy({ name: modifiedBy })}</Label>

                    <Text font='label' fontSize='xxsmall'>
                        {modifiedAt}
                    </Text>
                </div>
            ) : (
                <Text font='label' fontSize='xsmall' color='midnight70'>
                    {messages.noUpdatesYet}
                </Text>
            )}
        </Grid>
    );
}) as MemoExoticComponent<ComponentType<PaymentDetailsAuditProps>> & ChildrenComponents;

PaymentDetailsAudit.Loading = Loading;
