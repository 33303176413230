import { PolymorphicPropsWithRef, PolymorphicRef } from '@approvalmax/types';
import { ElementType, forwardRef, memo } from 'react';

import Progress from '../Progress/Progress';
import { DisabledOverlay, Inner, ProgressWrapper, Root } from './Button.styles';
import { ButtonComponent, ButtonProps } from './Button.types';

/**
 * Button Component for displaying buttons or links with button styles.
 * If you pass the `href` property, the component will be a link.
 */
export const Button = memo(
    forwardRef(
        <Component extends ElementType = 'button'>(
            props: PolymorphicPropsWithRef<Component, ButtonProps>,
            ref?: PolymorphicRef<Component>
        ) => {
            const {
                color = 'transparent',
                size = 'medium',
                children,
                startIcon,
                endIcon,
                type = 'button',
                justifyContent = 'center',
                fontWeight = 'medium',
                uppercase = true,
                noPadding,
                block,
                icon,
                outline,
                disabled,
                as,
                link,
                progress,
                wrap = true,
                ...restProps
            } = props;

            const As = as || 'button';
            const buttonType = As === 'button' ? type : undefined;

            return (
                <Root
                    {...restProps}
                    as={As}
                    type={buttonType}
                    disabled={disabled}
                    ref={ref}
                    $color={color}
                    $size={size}
                    $justifyContent={justifyContent}
                    $fontWeight={fontWeight}
                    $uppercase={uppercase}
                    $block={block}
                    $wrap={wrap}
                    $icon={icon}
                    $noPadding={noPadding}
                    $disabled={disabled || progress}
                    $outline={outline}
                    $link={link}
                >
                    {startIcon}

                    <Inner $hide={progress}>{children}</Inner>

                    {progress && (
                        <ProgressWrapper $size={size}>
                            <Progress shape='circle' />
                        </ProgressWrapper>
                    )}

                    {endIcon}

                    {disabled && <DisabledOverlay />}
                </Root>
            );
        }
    )
) as ButtonComponent;

export default Button;
