import { messages } from './NetSuiteVendorCreationConditionCell.messages';
import { ExpandedConditionType } from './NetSuiteVendorCreationConditionCell.types';

export const options = [
    ExpandedConditionType.AllVendors,
    ExpandedConditionType.ExactValues,
    ExpandedConditionType.NegativeExactValues,
];

export const conditionTypeTextMap: Record<ExpandedConditionType, string> = {
    [ExpandedConditionType.AllVendors]: messages.anyExistingVendorDropdownText,
    [ExpandedConditionType.ExactValues]: messages.contactsExactValuesDropdownText,
    [ExpandedConditionType.NegativeExactValues]: messages.contactsNotExactValuesDropdownText,
};
