import { selectors } from 'modules/common';
import { xeroConstants } from 'modules/common/constants';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { NotificationWarningView } from '../NotificationWraningView/NotificationWarningView';
import { messages } from './MatchingSupportingBanner.messages';

const MatchingSupportingBanner = memo(() => {
    const companies = useSelector(selectors.company.getCompanies);
    const activeCompaniesWithMatchingV1 = companies.filter(
        (c) =>
            c.flags.isXeroMatchingV1ReadOnly &&
            c.matchingMetadata?.hasMatchedV1Documents &&
            !c.flags.isRetired &&
            !c.flags.isExpired
    );

    if (activeCompaniesWithMatchingV1.length === 0) return null;

    return (
        <NotificationWarningView
            title={messages.title}
            text={messages.text}
            learnMoreHref={xeroConstants.XERO_MATCHING_MIGRATION_LINK}
        />
    );
});

export default MatchingSupportingBanner;
