import { arrayHelpers, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import { MatrixType } from '../../../../types/matrix';
import ExactAsyncConditionCell from '../ExactAsyncConditionCell';
import { messages } from './XeroSupplierEditingConditionCell.messages';
import { CheckboxContainer, LabelText, Root, StyledCheckbox } from './XeroSupplierEditingConditionCell.styles';

const XeroSupplierEditingConditionCell: FC<RenderCellOptions> = (props) => {
    const {
        rule,
        field,
        readonly,
        lineId,
        condition,
        onConditionChange,
        integrationCode,
        templateSubmitters,
        requiredFieldIds,
        matrixType,
    } = props;

    const onChange = () => {
        const newCondition = {
            ...condition,
            allowEditing: !condition.allowEditing,
        } as domain.ExactValuesCondition;

        onConditionChange(lineId, rule, field, newCondition);
    };

    return (
        <Root>
            <ExactAsyncConditionCell
                rule={rule}
                field={field}
                readonly={readonly}
                integrationCode={integrationCode}
                lineId={lineId}
                onConditionChange={onConditionChange}
                condition={condition as domain.ExactValuesCondition}
                templateSubmitters={
                    matrixType !== MatrixType.Requester ? templateSubmitters : arrayHelpers.emptyArray()
                }
                requiredFieldIds={requiredFieldIds}
                matrixType={matrixType}
                compactPaddingBottom
            />

            <CheckboxContainer onClick={!readonly ? onChange : undefined}>
                <StyledCheckbox checked={Boolean(condition.allowEditing)} disabled={readonly} />

                <LabelText>{intl.formatMessage(messages.allowToEditBankAccount)}</LabelText>
            </CheckboxContainer>
        </Root>
    );
};

export default memo(XeroSupplierEditingConditionCell);
