import Box from '../../../Box/Box';
import { defaultCheckboxWidthPx } from '../../Table.constants';
import { checkboxColumnId } from '../../Table.helpers';
import { BaseItem } from '../../Table.types';
import { ColumnsGroupProps } from './ColumnsGroup.types';

export const ColumnsGroup = <Item extends BaseItem>(props: ColumnsGroupProps<Item>) => {
    const { columns, bordered, checkedItems, checkboxColumnCover } = props;

    return (
        <colgroup>
            {checkedItems && (
                <Box
                    key={checkboxColumnId}
                    width={checkboxColumnCover?.width || defaultCheckboxWidthPx}
                    bordered={bordered}
                    as='col'
                />
            )}

            {columns.map((columnDefinition) => (
                <Box key={columnDefinition.id} width={columnDefinition.width} bordered={bordered} as='col' />
            ))}
        </colgroup>
    );
};

ColumnsGroup.displayName = 'ColumnsGroup';
