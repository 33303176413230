import { MutationOptions, RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { integrationsApiPaths } from '../paths';
import {
    UseCreateXeroBankAccountsData,
    UseCreateXeroBankAccountsPathParams,
    UseCreateXeroBankAccountsResponse,
} from './useCreateXeroBankAccounts.types';

export const useCreateXeroBankAccounts = (
    mutationOptions?: MutationOptions<
        UseCreateXeroBankAccountsResponse,
        RequestDataParams<UseCreateXeroBankAccountsData, UseCreateXeroBankAccountsPathParams>
    >
) =>
    useMutateLegacy(integrationsApiPaths.xeroBankAccounts, {
        mutationOptions,
    });
