import { intl } from '@approvalmax/utils';
import intersection from 'lodash/intersection';
import { selectors } from 'modules/common';
import { domain, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { getActiveTemplate } from '../selectors/pageSelectors';
import { isNewUser } from '../selectors/userSelectors';

const i18nPrefix = 'workflows/actions/inviteUsers';
const messages = defineMessages({
    inviteUsersSuccess: {
        id: `${i18nPrefix}.inviteUsersSuccess`,
        defaultMessage: '{count, plural, one {User} other {Users}} invited',
    },
});

export const OPEN_INVITE_USERS_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/OPEN_INVITE_USERS_POPUP';

interface OpenInviteUsersPopupAction {
    type: typeof OPEN_INVITE_USERS_POPUP;
    users: string[];
    companyId: string;
    preselectedUsers: string[];
}

export function openInviteUsersPopup(preselectedUsers: string[] = []): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();
        const template = getActiveTemplate(state)!;
        const company = selectors.company.getCompanyById(state, template.companyId);
        const companyTeam = selectors.company.getCompanyTeam(state, company);
        const notActiveTeamMembers = companyTeam
            .filter((u) => u.status !== domain.CompanyUserStatus.Active)
            .map((u) => u.id);
        const templateUsers = selectors.template.getTemplateUsers(state, template);
        const notInvitedTemplateUsers = intersection(notActiveTeamMembers, templateUsers);

        if (notInvitedTemplateUsers.length > 0) {
            dispatch<OpenInviteUsersPopupAction>({
                type: OPEN_INVITE_USERS_POPUP,
                users: notInvitedTemplateUsers,
                companyId: template.companyId,
                preselectedUsers,
            });
        }
    };
}

export const INVITE_USERS = 'LEGACY_WORKFLOW_TEMPLATES/INVITE_USERS';
export const INVITE_USERS_RESPONSE = 'LEGACY_WORKFLOW_TEMPLATES/INVITE_USERS_RESPONSE';
export const INVITE_USERS_FAILURE = 'LEGACY_WORKFLOW_TEMPLATES/INVITE_USERS_FAILURE';
export const inviteUsers = (
    company: domain.Company,
    invitationMessage: string,
    users: selectors.types.ExpandedUser[]
) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(INVITE_USERS, {
                company,
                users,
            }),

        response: async (request) => {
            let response = {
                Participants: [] as any,
            };

            const newUsers = users.filter((u) => isNewUser(u, request.company));
            const existingUsers = users.filter((u) => !isNewUser(u, request.company));

            if (newUsers.length > 0) {
                const result = await api.companies.addParticipants({
                    companyId: company.id,
                    userEmailsWithRoles: {},
                    doInvite: true,
                    invitationText: invitationMessage,
                });

                response.Participants.push(...(result.Participants as any));
            }

            if (existingUsers.length > 0) {
                const result = await api.companies.inviteParticipants({
                    companyId: company.id,
                    participantUserIdsWithRoles: Object.fromEntries(
                        existingUsers.map((user) => [
                            user.databaseId,
                            selectors.company.getCompanyUserRole(company, user.id),
                        ])
                    ),
                    invitationText: invitationMessage,
                });

                response.Participants.push(...(result.Participants as any));
            }

            return createAction(INVITE_USERS_RESPONSE, {
                request,
                company,
                raw: response,
                userStatuses: schemas.company.extractUserStatusFromAnswerArray(response.Participants, company.author),
            });
        },

        failure: (error, request) => createErrorAction(INVITE_USERS_FAILURE, error, {}),

        schema: {
            raw: { Participants: [schemas.userSchema] },
        },

        didDispatchResponse: () => {
            notificationService.showInfoToast(
                intl.formatMessage(messages.inviteUsersSuccess, {
                    count: users.length,
                })
            );
        },
    });

export const DISCARD_INVITE_USERS_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/DISCARD_INVITE_USERS_POPUP';
export const discardInviteUsersPopup = () => createAction(DISCARD_INVITE_USERS_POPUP, {});

export type Action = ExtractActions<typeof discardInviteUsersPopup | typeof inviteUsers> | OpenInviteUsersPopupAction;
