import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, LOAD_PAGE_DATA } from '../actions';
import activeCopyTemplate, { ActiveCopyTemplate } from './page/activeCopyTemplate';
import activeFieldSettings, { ActiveFieldSettings } from './page/activeFieldSettingsReducer';
import activeHappyActivation, { ActiveHappyActivation } from './page/activeHappyActivationReducer';
import activeInviteUsers, { ActiveInviteUsers } from './page/activeInviteUsersReducer';
import activeMatrix, { ActiveMatrix } from './page/activeMatrixReducer';
import activeSettings, { ActiveSettings } from './page/activeSettingsReducer';
import activeStartOver, { ActiveStartOver } from './page/activeStartOverReducer';
import activeTemplateLastVersion, { ActiveTemplateLastVersion } from './page/activeTemplateLastVersion';
import activeTemplateModified from './page/activeTemplateModifiedReducer';
import activeTemplate, { ActiveTemplate } from './page/activeTemplateReducer';
import activeVersionConflict, { ActiveVersionConflict } from './page/activeVersionConflictReducer';
import addCommentPopup, { AddCommentPopup } from './page/addCommentPopupReducer';
import addingNewStep from './page/addingNewStepReducer';
import externalSubmitterEnabled from './page/externalSubmitterEnabledReducer';
import loading from './page/loadingReducer';
import showCardValidationErrors from './page/showCardValidationErrorsReducer';
import versionHistory, { VersionHistory } from './page/versionHistory';

interface PageData {
    loading: boolean;
    activeStartOver: ActiveStartOver;
    activeSettings: ActiveSettings;
    activeTemplate: ActiveTemplate;
    activeMatrix: ActiveMatrix;
    activeInviteUsers: ActiveInviteUsers;
    activeHappyActivation: ActiveHappyActivation;
    activeVersionConflict: ActiveVersionConflict;
    activeFieldSettings: ActiveFieldSettings;
    addingNewStep: boolean;
    externalSubmitterEnabled: boolean;
    showCardValidationErrors: boolean;
    activeTemplateModified: boolean;
    activeCopyTemplate: ActiveCopyTemplate;
    addCommentPopup: AddCommentPopup;
    versionHistory: VersionHistory;
    activeTemplateLastVersion: ActiveTemplateLastVersion;
}

export type Page = ImmutableObject<PageData>;

const INITIAL_STATE: Page = immutable({}) as any;

export default function (state: Page = INITIAL_STATE, action: Action): Page {
    if (action.type === LOAD_PAGE_DATA) {
        state = INITIAL_STATE;
    }

    const newActiveTemplate = activeTemplate(state.activeTemplate, action);
    const nextState = immutable<PageData>({
        loading: loading(state.loading, action),
        activeStartOver: activeStartOver(state.activeStartOver, action),
        activeSettings: activeSettings(state.activeSettings, action),
        activeTemplate: newActiveTemplate,
        activeMatrix: activeMatrix(state.activeMatrix, action),
        activeInviteUsers: activeInviteUsers(state.activeInviteUsers, action),
        activeHappyActivation: activeHappyActivation(state.activeHappyActivation, action),
        activeVersionConflict: activeVersionConflict(state.activeVersionConflict, action),
        addingNewStep: addingNewStep(state.addingNewStep, action),
        showCardValidationErrors: showCardValidationErrors(state.showCardValidationErrors, action),
        externalSubmitterEnabled: externalSubmitterEnabled(state.externalSubmitterEnabled, action, newActiveTemplate),
        activeTemplateModified: state.activeTemplateModified,
        activeCopyTemplate: activeCopyTemplate(state.activeCopyTemplate, action),
        addCommentPopup: addCommentPopup(state.addCommentPopup, action),
        versionHistory: versionHistory(state.versionHistory, action),
        activeTemplateLastVersion: activeTemplateLastVersion(state.activeTemplateLastVersion, action),
        activeFieldSettings: activeFieldSettings(state.activeFieldSettings, action),
    });

    return activeTemplateModified(state, action, nextState);
}
