import { Flex, Progress } from '@approvalmax/ui/src/components';
import { memo } from 'react';

const LoadingScreen = memo((props) => (
    <Flex inline alignItems='center' justifyContent='center' height='100%'>
        <Progress shape='circle' />
    </Flex>
));

export default LoadingScreen;
