import { Button, DropdownEditor, Popup } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { fetchActionsForNewCompany } from 'modules/company/actions';
import { getActiveCompany } from 'modules/company/selectors/moduleSelectors';
import { PlainDataProvider } from 'modules/data-providers';
import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganisationCountOptions } from './CollectAccountNurturingDataPopup.constants';
import { useCollectNurturingData } from './CollectAccountNurturingDataPopup.hooks';
import { messages } from './CollectAccountNurturingDataPopup.messages';
import {
    Content,
    Greeting,
    Header,
    MainMessage,
    OrganisationCountField,
    Title,
} from './CollectAccountNurturingDataPopup.styles';
import {
    CollectAccountNurturingDataPopupProps,
    OrganisationCountReferenceOption,
} from './CollectAccountNurturingDataPopup.types';

const CollectAccountNurturingDataPopup: FC<CollectAccountNurturingDataPopupProps> = memo((props) => {
    const dispatch = useDispatch();
    const company = useSelector(getActiveCompany);
    const me = useSelector(selectors.profile.getProfileUser);
    const account = useSelector(selectors.profile.getProfileAccount);
    const { isLoading, collectNurturingData } = useCollectNurturingData();
    const [organisationCountOption, setOrganisationCountOption] = useState<OrganisationCountReferenceOption | null>(
        null
    );

    const onSend = useCallback(async () => {
        if (organisationCountOption) {
            await collectNurturingData({
                accountId: account.accountId,
                organisationCountOption: organisationCountOption.value,
            });

            await dispatch(fetchActionsForNewCompany(company.id));
        }
    }, [account.accountId, collectNurturingData, company.id, dispatch, organisationCountOption]);

    return (
        <Popup.EmptyContent>
            <Header>
                <Title>{messages.title}</Title>

                <Button disabled={isLoading || !organisationCountOption} execute={onSend}>
                    {messages.sendButton}
                </Button>
            </Header>

            <Content>
                <Greeting>
                    {messages.greeting({
                        userName: me.displayName,
                    })}
                </Greeting>

                <MainMessage>{messages.mainMessage1}</MainMessage>

                <MainMessage>{messages.mainMessage2}</MainMessage>

                <OrganisationCountField title={messages.question} wrapLabel>
                    <PlainDataProvider items={OrganisationCountOptions}>
                        <DropdownEditor
                            placeholder={messages.chooseOption}
                            buttonComponent={DropdownEditor.NoFilterButton}
                            value={organisationCountOption}
                            onChange={(value: OrganisationCountReferenceOption) => {
                                setOrganisationCountOption(value);
                            }}
                        />
                    </PlainDataProvider>
                </OrganisationCountField>
            </Content>
        </Popup.EmptyContent>
    );
});

export default CollectAccountNurturingDataPopup;
