export function getSelectArgument1<T>() {
    return (arg1: T) => arg1;
}

export function getSelectArgument2<T>() {
    return (arg1: any, arg2: T) => arg2;
}

export function getSelectArgument3<T>() {
    return (arg1: any, arg2: any, arg3: T) => arg3;
}

export function getSelectArgument4<T>() {
    return (arg1: any, arg2: any, arg3: any, arg4: T) => arg4;
}

export function getSelectArgument5<T>() {
    return (arg1: any, arg2: any, arg3: any, arg4: any, arg5: T) => arg5;
}

export function getSelectArgument6<T>() {
    return (arg1: any, arg2: any, arg3: any, arg4: any, arg5: any, arg6: T) => arg6;
}
