import { openMenuSubItemsDuration } from '@approvalmax/ui/src/components/Menu/components/Item/Item.constants';
import { useSpring } from '@react-spring/web';
import { FocusEventHandler, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';

import { ItemProps } from './Item.types';

export const useItem = (props: Pick<ItemProps, 'open' | 'onClick' | 'id' | 'onOpen'>) => {
    const { onClick, open, id, onOpen } = props;

    const controlled = open !== undefined && onOpen;
    const [isOpenSubItems, setIsOpenSubItems] = useState(Boolean(open));

    /**
     * Updating the opening status for an externally controlled menu item
     */
    useEffect(() => {
        if (!controlled) return;

        setIsOpenSubItems(Boolean(open));
    }, [controlled, open]);

    /**
     * Updating the opening state for uncontrolled menu item and call the callbacks
     */
    const handleClickLabel = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            if (!controlled) {
                setIsOpenSubItems(!isOpenSubItems);
            }

            onOpen && onOpen(!isOpenSubItems, id);
            onClick && onClick(event, id);
        },
        [controlled, onOpen, isOpenSubItems, id, onClick]
    );

    return {
        isOpenSubItems,
        handleClickLabel,
    };
};

export const useAnimation = (isOpenSubItems: boolean) => {
    const [subItemsRef, { height }] = useMeasure<HTMLDivElement>();
    const subItemsStyles = useSpring({
        config: {
            duration: openMenuSubItemsDuration,
        },
        from: {
            height: 0,
            opacity: 0,
            y: 0,
        },
        to: {
            height: isOpenSubItems ? height : 0,
            opacity: Number(isOpenSubItems),
            y: isOpenSubItems ? 0 : -10,
        },
    });

    return {
        subItemsRef,
        subItemsStyles,
    };
};

/**
 * Special version of `useCaptureFocus` hook with a fix for Drawer visibility loosing
 */
export const useItemCaptureFocus = () => {
    const [focused, setFocused] = useState(false);
    const focusedRef = useRef<HTMLElement | null>(null);

    /**
     * Covers a case described here https://approvalmax.atlassian.net/browse/AM-18220
     *
     * In case then parent element (i.e. Drawer) loose visibility we need get
     * know about that and reset the focus state
     */
    useEffect(() => {
        const handleFocus = (event: FocusEvent) => {
            if (event.target !== focusedRef.current) {
                setFocused(false);
            }
        };

        if (focused) {
            window.addEventListener('focus', handleFocus, true);
        }

        return () => {
            window.removeEventListener('focus', handleFocus, true);
        };
    }, [focused]);

    const handleParentFocus: FocusEventHandler<HTMLElement> = (event) => {
        focusedRef.current = event.target;
        setFocused(true);
    };

    const handleParentBlur: FocusEventHandler<HTMLElement> = () => {
        setFocused(false);
    };

    return {
        className: focused ? 'focused' : undefined,
        onFocus: handleParentFocus,
        onBlur: handleParentBlur,
    };
};
