export const formatPathForSentryStatistic = (path: string): string => {
    return (
        path
            // /:companyId/requests/:requestId/edit -> /<hash>>/requests/<hash>>/edit
            // /:companyId/requests/:workflowId/approved -> /<hash>>/requests/<hash>>/approved
            .replace(/\/.*\/requests\/.*\//, '/<hash>/requests/<hash>/')

            // /:companyId/requests/all -> /<hash>/requests/all
            .replace(/\/.*\/requests/, '/<hash>/requests')

            // /:companyId/companyInfo -> /<hash>/companyInfo
            .replace(/\/.*\/companyInfo/, '/<hash>/companyInfo')

            // /:companyId/users -> /<hash>/users
            .replace(/\/.*\/users/, '/<hash>/users')

            // /:companyId/budgets -> /<hash>/budgets
            .replace(/\/.*\/budgets/, '/<hash>/budgets')

            // /acceptCompanyInvitation/:companyId -> /acceptCompanyInvitation/<hash>
            .replace(/\/acceptCompanyInvitation\/.*/, '/acceptCompanyInvitation/<hash>')

            // /:companyId/downloadArchiveCollection/:collectionId -> /<hash>/downloadArchiveCollection/<hash>
            .replace(/\/.*\/downloadArchiveCollection\/.*/, '/<hash>/downloadArchiveCollection/<hash>')

            // /customerFeedback/:boardId -> /customerFeedback/<hash>
            .replace(/\/customerFeedback\/.*/, '/customerFeedback/<hash>')

            // /requests/myDecisionRequired/:hash -> /requests/myDecisionRequired/<hash>
            .replace(/\/requests\/myDecisionRequired\/.*/, '/requests/myDecisionRequired/<hash>')

            // /requests/myOnlyOpen/:hash -> /requests/myOnlyOpen/<hash>
            .replace(/\/requests\/myOnlyOpen\/.*/, '/requests/myOnlyOpen/<hash>')

            // /requests/myDraft/:hash -> /requests/myDraft/<hash>
            .replace(/\/requests\/myDraft\/.*/, '/requests/myDraft/<hash>')

            // /:companyId/reports/request/:reportId -> /<hash>/reports/request/<hash>
            .replace(/\/.*\/reports\/request\/.*/, '/<hash>/reports/request/<hash>')

            // /:companyId/reports/lineItem/:reportId -> /<hash>/reports/lineItem/<hash>
            .replace(/\/.*\/reports\/lineItem\/.*/, '/<hash>/reports/lineItem/<hash>')

            // /:companyId/reports -> /<hash>/reports
            .replace(/\/.*\/reports/, '/<hash>/reports')

            // /:companyId/workflows/:workflowId -> /<hash>/workflows/<hash>
            // /:companyId/workflows/new -> /<hash>/workflows/new
            .replace(/\/.*\/workflows\/(?!new).*/, '/<hash>/workflows/<hash>')

            // /:companyId/workflows -> /<hash>/workflows
            .replace(/\/.*\/workflows/, '/<hash>/workflows')
    );
};
