import { objectHelpers, routerHelpers } from '@approvalmax/utils';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApi from '../rootApi';
import {
    ApiError,
    ApiSource,
    ApiVersion,
    GetNextPageParam,
    InfiniteQueryOptions,
    ResponseDataWithContinuationToken,
} from '../types';

export const createUseInfiniteGetRequest = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <
        ResponseData extends ResponseDataWithContinuationToken,
        MappedResponseData extends ResponseDataWithContinuationToken = ResponseData,
        PathParams extends Record<string, string> = Record<string, string>,
    >(
        path: string,
        options: {
            pathParams?: PathParams;
            mapData?: (data: ResponseData) => MappedResponseData;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            params?: Record<string, string>;
            getNextPageParam?: GetNextPageParam<ResponseData, MappedResponseData>;
            queryOptions?: InfiniteQueryOptions<ResponseData, MappedResponseData>;
        }
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            params,
            queryOptions,
            pathParams,
            mapData,
            getNextPageParam = (lastPage) => {
                if (!lastPage.continuationToken) {
                    return;
                }

                return {
                    params: {
                        ...params,
                        continuationToken: lastPage.continuationToken,
                    },
                };
            },
        } = options;
        const url = routerHelpers.testPathByParams(path, pathParams) ? routerHelpers.pathToUrl(path, pathParams) : '';
        const apiUrl = getApiUrl(apiSource, defaultEnv, apiVersion);
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData);

        return useInfiniteQuery<MappedResponseData, ApiError>(
            [url, params, pathParams].filter(Boolean),
            ({ pageParam }) =>
                handleApiCall(
                    rootApi({
                        baseURL: apiUrl,
                        method: 'GET',
                        url,
                        params: objectHelpers.has(pageParam, 'params') ? pageParam.params : params,
                        headers: {
                            ...(Authorization && { Authorization }),
                        },
                    })
                ),
            {
                enabled: Boolean(url),
                ...queryOptions,
                getNextPageParam,
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    params,
                    pathParams,
                },
            }
        );
    };
};
