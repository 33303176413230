import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { actions } from 'modules/profile';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { useSaveToLocalStorage } from './StandaloneUpgrade.hooks';
import { messages } from './StandaloneUpgrade.messages';

const StandaloneUpgrade = memo(() => {
    const dispatch = useDispatch();
    const company = useSelector(selectors.navigation.getActiveCompany);
    const subscription = useSelector((state: State) =>
        selectors.subscription.findCompanySubscription(state, company.id)
    );

    const myAccountUrl = subscription?.isActivePaid
        ? getAccountUrl(AccountPath.upgradeSubscription, {
              subscriptionId: subscription.id,
              origin: 'wa_standalone_upgrade',
          })
        : getAccountUrl(AccountPath.subscriptions, {
              origin: 'wa_standalone_upgrade',
          });

    const isPromotionOfAdvancedFeatures =
        company.flags.isManager && company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation;
    const { closePromotion, isClosedPromotion } = useSaveToLocalStorage(company.id);

    const tryNow = useCallback(
        () => dispatch(actions.openConsentToStartTrialPopup(company, 'stand-alone_wf')),
        [company, dispatch]
    );

    return (
        <Box spacing='24'>
            <Flex inline direction='column' spacing='16' alignItems='start'>
                <Text font='headline' fontSize='xsmall' as='h2'>
                    {messages.title}
                </Text>

                <Text font='body' fontSize='small' color='midnight70'>
                    {messages.description}

                    <Spacing height={8} />

                    <a
                        href='https://support.approvalmax.com/portal/kb/articles/how-can-i-restrict-the-requesters-of-purchase-orders'
                        target='_blank'
                        rel='nofollow noreferrer'
                    >
                        {messages.learnMore}
                    </a>
                </Text>

                {!isClosedPromotion && isPromotionOfAdvancedFeatures ? (
                    <Flex inline>
                        <Button onClick={closePromotion} color='midnight40' outline startIcon={<CloseIcon />}>
                            {messages.close}
                        </Button>

                        <Button color='green80' onClick={tryNow}>
                            {messages.tryNow}
                        </Button>
                    </Flex>
                ) : (
                    <Button color='green80' href={myAccountUrl} as='a' target='_blank' rel='nofollow noreferrer'>
                        {messages.upgradeSubscription}
                    </Button>
                )}
            </Flex>
        </Box>
    );
});

export default StandaloneUpgrade;
