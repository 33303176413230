import { Box } from '@approvalmax/ui/src/components';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';

import { Item, List } from './components';
import { MenuContext } from './Menu.context';
import { ChildrenComponents, MenuProps } from './Menu.types';

/**
 * The Menu allows you to create lists with items that, when clicked, perform certain actions
 */
const Menu = memo(
    forwardRef<HTMLUListElement, MenuProps>((props, ref) => {
        const { items, size, divider, color, children, ...restProps } = props;

        return (
            <MenuContext.Provider value={{ size, divider, color }}>
                {items?.length ? (
                    <List {...restProps} items={items} ref={ref} />
                ) : (
                    <Box {...restProps} as='ul' ref={ref} role='menu'>
                        {children}
                    </Box>
                )}
            </MenuContext.Provider>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<MenuProps & RefAttributes<HTMLUListElement>>> & ChildrenComponents;

Menu.Item = Item;

export default Menu;
