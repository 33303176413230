import './editableAvatar.scss';

import { useExtensions } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { actions, selectors, ui } from 'modules/common';
import { ChangeAvatarIcon, MiniCameraCoverIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import Dropzone, { FileRejection } from 'react-dropzone';
import { defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { setAvatar } from '../actions';

const MAX_AVATAR_SIZE = 10000000;
const MAX_AVATAR_SIZE_TEXT = '10 MB';

const i18nPrefix = 'profile/containers/EditableAvatar';
const messages = defineMessages({
    avatarDropzoneTitle: {
        id: `${i18nPrefix}.avatarDropzoneTitle`,
        defaultMessage: 'Change photo',
    },
    avatarSizeExceedsTheLimit: {
        id: `${i18nPrefix}.avatarSizeExceedsTheLimit`,
        defaultMessage: 'Avatar size exceeds {size}',
    },
    invalidFileExtension: {
        id: `${i18nPrefix}.invalidFileExtension`,
        defaultMessage: 'Invalid file extension, please upload an image file (.png, .jpg)',
    },
});

const bem = bemFactory.block('profile-editable-avatar');

interface EditableAvatarProps {
    className?: string;
}

const EditableAvatar: FC<EditableAvatarProps> = (props) => {
    const { className } = props;

    const dispatch = useDispatch();

    const me = useSelector(selectors.profile.getProfileUser);
    const extensions = useExtensions(['png', 'jpg']);

    const onDrop = (accepted: File[], rejected: FileRejection[]) => {
        if (accepted.length === 0 && rejected.length === 1) {
            const { file: rejectedFile } = rejected[0];
            const size = rejectedFile.size;

            if (size > MAX_AVATAR_SIZE) {
                dispatch(
                    actions.addErrorToast(
                        intl.formatMessage(messages.avatarSizeExceedsTheLimit, {
                            size: MAX_AVATAR_SIZE_TEXT,
                        })
                    )
                );
            }

            if (
                !Object.values(extensions).some((extensionType) =>
                    extensionType.some((ext) => rejectedFile.name.endsWith(ext))
                )
            ) {
                dispatch(actions.addErrorToast(intl.formatMessage(messages.invalidFileExtension)));
            }
        }

        if (accepted.length !== 1) {
            return;
        }

        const file = accepted[0];

        dispatch(setAvatar(file));
    };

    return (
        <div>
            <Dropzone maxSize={MAX_AVATAR_SIZE} multiple={false} accept={extensions} onDrop={onDrop}>
                {({ getRootProps, getInputProps, isDragActive, open }) => (
                    <section {...getRootProps()} className={bem.add(className)(null, { over: isDragActive })}>
                        <input {...getInputProps()} />

                        {me.avatar ? (
                            <div
                                title={intl.formatMessage(messages.avatarDropzoneTitle)}
                                className={bem('avatar-dropzone-content')}
                            >
                                <ui.Avatar size={118} user={me} />

                                <div className={bem('avatar-edit-overlay')}>
                                    <ChangeAvatarIcon width={22} height={22} />
                                </div>
                            </div>
                        ) : (
                            <div
                                title={intl.formatMessage(messages.avatarDropzoneTitle)}
                                className={bem('avatar-dropzone-content')}
                            >
                                <MiniCameraCoverIcon width={50} height={40} />
                            </div>
                        )}
                    </section>
                )}
            </Dropzone>
        </div>
    );
};

export default memo(EditableAvatar);
