import { addStylesLink } from './addStylesLink';
import { copyStyles } from './copyStyles';
import { downloadBlob } from './downloadBlob';
import { downloadUrl } from './downloadUrl';
import { getScrollParent } from './getScrollParent';
import { highlightElement } from './highlightElement';
import { highlightText } from './highlightText';
import { highlightUrls } from './highlightUrls';
import { loadScript } from './loadScript';
import { setRef } from './setRef';
import { stopPropagation } from './stopPropagation';
import { transitionEnd } from './transitionEnd';

export const domHelpers = {
    setRef,
    addStylesLink,
    copyStyles,
    downloadUrl,
    downloadBlob,
    getScrollParent,
    highlightText,
    highlightUrls,
    loadScript,
    transitionEnd,
    stopPropagation,
    highlightElement,
};
