import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseTwoFaEnablingEmailCodeVerifyRequest,
    UseTwoFaEnablingEmailCodeVerifyResponse,
} from './useTwoFaEnablingEmailCodeVerify.types';

export const useTwoFaEnablingEmailCodeVerify = () => {
    return useMutateLegacy<
        RequestDataParams<UseTwoFaEnablingEmailCodeVerifyRequest>,
        UseTwoFaEnablingEmailCodeVerifyResponse
    >(twoFaApiPaths.enablingEmailCodeVerify);
};
