import { set } from 'modules/immutable';

import {
    Action,
    ADD_NEW_STANDALONE_TEMPLATE,
    DELETE_TEMPLATE,
    DISCARD_TEMPLATE_CHANGES,
    FETCH_WORKFLOW_WITH_VERSION_RESPONSE,
    SAVE_TEMPLATE_RESPONSE,
    SHOW_WORKFLOW_PAGE,
    START_OVER_RESPONSE,
} from '../../actions';
import { Page } from '../pageReducer';

const INITIAL_STATE = false;

export default function activeTemplateModifiedReducer(state: Page, action: Action, nextState: Page): Page {
    switch (action.type) {
        case SAVE_TEMPLATE_RESPONSE:
        case DISCARD_TEMPLATE_CHANGES:
        case SHOW_WORKFLOW_PAGE:
        case ADD_NEW_STANDALONE_TEMPLATE:
        case DELETE_TEMPLATE:
        case FETCH_WORKFLOW_WITH_VERSION_RESPONSE:
            return set(nextState, 'activeTemplateModified', false);

        case START_OVER_RESPONSE:
            return nextState;

        default:
            if (state.activeTemplate !== nextState.activeTemplate) {
                return set(nextState, 'activeTemplateModified', true);
            }

            if (nextState.activeTemplateModified === undefined) {
                return set(nextState, 'activeTemplateModified', INITIAL_STATE);
            }

            return nextState;
    }
}
