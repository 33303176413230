import { Box, Button, Form, Grid, Popup, Text, TextField, toast } from '@approvalmax/ui/src/components';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { statics } from 'modules/common';
import { useDispatch } from 'modules/react-redux';
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { companiesApiPaths, useUpdateDelegations, UseUpdateDelegationsData } from 'shared/data';

import { cancelActivePopup, optimisticallySetDelegate } from '../../actions';
import { FormContent, Header } from './components';
import { useDelegatesFilter, useMyDelegatesPopupData } from './MyDelegatesPopup.hooks';
import { messages } from './MyDelegatesPopup.messages';

export const MyDelegatesPopup = memo(() => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const {
        delegates: unfilteredDelegates,
        filterOutUnchanged,
        me,
        myTimeZone,
        companyId,
        isLoading,
    } = useMyDelegatesPopupData();
    const { delegates, handleSearchQueryChange, searchQuery } = useDelegatesFilter(unfilteredDelegates);

    const { mutateAsync: updateDelegations, isLoading: isLoadingUpdateDelegations } = useUpdateDelegations({
        onSuccess: () => {
            if (companyId) {
                queryClient.invalidateQueries([
                    routerHelpers.pathToUrl(companiesApiPaths.companyDelegations, { companyId }),
                ]);
            }
        },
    });

    const form = useForm({ values: { delegates } });
    const { fields } = useFieldArray({ control: form.control, name: 'delegates' });
    const watchFieldArray = form.watch('delegates');
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });
    const {
        formState: { touchedFields },
    } = form;
    const noVirtualization = delegates.length < 15;

    const handleSubmit = form.handleSubmit((values) => {
        const delegations = values.delegates
            .filter((delegate) => {
                const { company } = delegate;
                const isExpired = company.flags.isExpired && !company.flags.isGraceSubscription;
                const isDisabledCompany = company.flags.isRetired || isExpired || company.isReadonly;

                // this is a bit of extra optimization. We exclude all unset (and untouched) companies.
                // but this doesn't check companies where were set before, but this time was untouched.
                // to handle that we need to transform dates to UTC first and later do that filtration
                // before submit
                const setOrChanged = Boolean(
                    delegate.dateFrom || delegate.dateTo || delegate.originalDateFrom || delegate.originDateTo
                );

                return !isDisabledCompany && setOrChanged;
            })
            .map((delegate) => {
                const delegation: UseUpdateDelegationsData[number] = {
                    companyId: delegate.company.id,
                    delegates: [],
                };
                const possibleDelegate = delegate.possibleDelegates.find((user) => user.id === delegate.delegateId);

                if (possibleDelegate) {
                    // These are revert time zones shifting modification which may have
                    // been done earlier within `form.register(`delegates.${index}.timeZone`)`
                    const delegateTimeZone = delegate.timeZone
                        ? statics.timeZone.findTimeZoneById(delegate.timeZone)
                        : undefined;
                    const selectedTzOffset = statics.timeZone.getUtcOffset(delegateTimeZone);

                    let dateFrom;

                    if (delegate.dateFromSetTimeZone !== myTimeZone) {
                        const dateFromSetTimeZoneOffset = statics.timeZone.getUtcOffset(
                            statics.timeZone.findTimeZoneById(delegate.dateFromSetTimeZone)
                        );

                        dateFrom = statics.timeZone
                            .subtractOffset(delegate.dateFrom, dateFromSetTimeZoneOffset)
                            .toISOString();
                    } else {
                        dateFrom = statics.timeZone.subtractOffset(delegate.dateFrom, selectedTzOffset).toISOString();
                    }

                    let dateTo;

                    if (delegate.dateToSetTimeZone !== myTimeZone) {
                        const dateToSetTimeZoneOffset = statics.timeZone.getUtcOffset(
                            statics.timeZone.findTimeZoneById(delegate.dateToSetTimeZone)
                        );

                        dateTo = delegate.dateTo
                            ? statics.timeZone.subtractOffset(delegate.dateTo, dateToSetTimeZoneOffset).toISOString()
                            : undefined;
                    } else {
                        dateTo = statics.timeZone.subtractOffset(delegate.dateTo, selectedTzOffset).toISOString();
                    }

                    delegation.delegates.push({
                        fromUser: { userId: me.databaseId },
                        toUser: { userId: possibleDelegate.databaseId },
                        dateFrom,
                        dateTo,
                    });
                } else {
                    delegation.delegates.push({
                        fromUser: { userId: me.databaseId },
                    });
                }

                return delegation;
            });

        const filteredDelegations = filterOutUnchanged(delegations);

        if (!filteredDelegations.length) {
            dispatch(cancelActivePopup());

            return;
        }

        void updateDelegations({ data: filteredDelegations })
            .then(() => {
                dispatch(
                    optimisticallySetDelegate({
                        me,
                        delegates: filteredDelegations.map((delegation, index) => ({
                            companyId: delegation.companyId,
                            delegateUserProfileId: values.delegates[index].delegate?.userId,
                            delegateId: values.delegates[index].delegateId,
                            delegateFrom: delegation.delegates?.[0].dateFrom ?? null,
                            delegateTo: delegation.delegates?.[0].dateTo ?? null,
                        })),
                    })
                );
                toast.success(messages.delegatesSet);
            })
            .catch(() => {});
    });

    const handleDelete = useCallback(
        (index: number) => () => {
            // additionally reset validation errors
            form.resetField(`delegates.${index}.delegateId`, { defaultValue: null });
            form.resetField(`delegates.${index}.dateFrom`, { defaultValue: null });
            form.resetField(`delegates.${index}.dateTo`, { defaultValue: null });
            form.resetField(`delegates.${index}.timeZone`, { defaultValue: undefined });
        },
        [form]
    );

    const applyDelegateToChangeSideEffects = (delegate: (typeof controlledFields)[number], index: number) => {
        form.register(`delegates.${index}.delegateId`, {
            onChange: (event) => {
                if (event.type === 'change') {
                    if (!delegate.dateFrom) {
                        // this is always your profile tz, so no need to shift
                        const startOfDay = moment.utc().startOf('day').toISOString();

                        form.setValue(`delegates.${index}.dateFrom`, startOfDay);
                    }
                }
            },
        });

        form.register(`delegates.${index}.timeZone`, {
            onChange: (event) => {
                if (event.target.value) {
                    // changing time zones within this field is a read-only action. We shouldn't change
                    // the real dates because of that. The purpose of that is to show to user the time
                    // in a time zone of his delegate.
                    //
                    // We decided to not modify DatePicker for that, but do a real form modifications and
                    // later during SUBMIT do a revert modifications
                    const oldTz = delegate.timeZone ? statics.timeZone.findTimeZoneById(delegate.timeZone) : undefined;
                    const newTz = statics.timeZone.findTimeZoneById(event.target.value);
                    const oldUtcOffset = statics.timeZone.getUtcOffset(oldTz);
                    const newUtcOffset = statics.timeZone.getUtcOffset(newTz);
                    const tzShift = statics.timeZone.getOffsetShift(newUtcOffset, oldUtcOffset);

                    if (delegate.dateFrom) {
                        const dateFrom = statics.timeZone.addOffset(delegate.dateFrom, tzShift);

                        form.setValue(`delegates.${index}.dateFrom`, dateFrom.toISOString(), { shouldTouch: false });
                    }

                    if (delegate.dateTo) {
                        const dateTo = statics.timeZone.addOffset(delegate.dateTo, tzShift);

                        form.setValue(`delegates.${index}.dateTo`, dateTo.toISOString(), { shouldTouch: false });
                    }
                }
            },
        });

        form.register(`delegates.${index}.dateFrom`, {
            onChange: (event) => {
                // can't compare w/ original because user can change field
                // several times and eventually back to the original
                if (event.target.value && touchedFields.delegates?.[index]?.dateFrom) {
                    const startOfDay = moment.utc(event.target.value).startOf('day').toISOString();

                    form.setValue(`delegates.${index}.dateFrom`, startOfDay);
                    // reset `isTouched` state to prevent setValue on `timeZone` field change
                    form.resetField(`delegates.${index}.dateFrom`, {
                        defaultValue: startOfDay,
                    });

                    form.setValue(`delegates.${index}.dateFromSetTimeZone`, delegate.timeZone);
                }
            },
        });

        form.register(`delegates.${index}.dateTo`, {
            onChange: (event) => {
                if (event.target.value) {
                    if (event.target.value && touchedFields.delegates?.[index]?.dateTo) {
                        const endOfDay = moment.utc(event.target.value).endOf('day').toISOString();

                        form.setValue(`delegates.${index}.dateTo`, endOfDay);
                        // reset `isTouched` state to prevent setValue on `timeZone` field change
                        form.resetField(`delegates.${index}.dateTo`, {
                            defaultValue: endOfDay,
                        });

                        form.setValue(`delegates.${index}.dateToSetTimeZone`, delegate.timeZone);
                    }

                    if (!delegate.dateFrom) {
                        form.setValue(`delegates.${index}.dateFrom`, new Date().toISOString());
                    }
                }
            },
        });
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Popup.Header
                progress={isLoading}
                title={messages.popupTitle}
                actions={
                    <Button
                        size='medium'
                        color='blue80'
                        type='submit'
                        disabled={isLoading || isLoadingUpdateDelegations}
                    >
                        {messages.setDelegateButton}
                    </Button>
                }
            />

            <Popup.Body spacing='32' className='fs-mask'>
                <Grid gap={24}>
                    <div>
                        <Text font='body' fontSize='medium'>
                            {messages.descriptionText}
                        </Text>

                        <br />

                        <Text font='body' fontSize='medium'>
                            {messages.timezoneHintText({
                                pleaseNote: <b>{messages.pleaseNoteText}</b>,
                            })}
                        </Text>
                    </div>

                    <Box width={260}>
                        <TextField
                            placeholder={messages.searchOrgPlaceholder}
                            value={searchQuery}
                            onChange={handleSearchQueryChange}
                        />
                    </Box>

                    <div>
                        <Grid gridTemplateColumns='196px 220px 200px 105px 105px' gap={8}>
                            <Header>{messages.companyColumnTitle}</Header>

                            <Header>{messages.delegateColumnTitle}</Header>

                            <Header>{messages.timeZone}</Header>

                            <Header>{messages.startDateColumnTitle}</Header>

                            <Header>{messages.endDateColumnTitle}</Header>
                        </Grid>

                        {noVirtualization ? (
                            <Grid gap={16}>
                                {controlledFields.map((delegate, index) => {
                                    applyDelegateToChangeSideEffects(delegate, index);

                                    return (
                                        <FormContent
                                            key={delegate.company.id}
                                            delegate={delegate}
                                            index={index}
                                            onDelete={handleDelete}
                                        />
                                    );
                                })}
                            </Grid>
                        ) : (
                            <Virtuoso
                                style={{ height: '60vh' }}
                                data={controlledFields}
                                itemContent={(index, delegate) => {
                                    applyDelegateToChangeSideEffects(delegate, index);

                                    return (
                                        <Box spacing='8 0'>
                                            <FormContent delegate={delegate} index={index} onDelete={handleDelete} />
                                        </Box>
                                    );
                                }}
                            />
                        )}
                    </div>
                </Grid>
            </Popup.Body>
        </Form>
    );
});

MyDelegatesPopup.displayName = 'MyDelegatesPopup';
