import { Flex } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FETCH_WORKFLOW_WITH_VERSION } from '../../actions';
import { getActiveTemplate, getVersionHistory } from '../../selectors/pageSelectors';
import DearWorkflowDetails from '../DearWorkflowDetails/DearWorkflowDetails';
import NetSuiteWorkflowDetails from '../NetSuiteWorkflowDetails/NetSuiteWorkflowDetails';
import QBooksWorkflowDetails from '../QBooksWorkflowDetails/QBooksWorkflowDetails';
import StandaloneWorkflowDetails from '../StandaloneWorkflowDetails/StandaloneWorkflowDetails';
import XeroWorkflowDetails from '../XeroWorkflowDetails/XeroWorkflowDetails';
import WorkflowDetailsLoading from './WorkflowDetails.loading';
import { Root } from './WorkflowDetails.styles';

const WorkflowDetails = memo(() => {
    const versionHistory = useSelector(getVersionHistory);
    const template = useSelector(getActiveTemplate);
    const integrationType = selectors.integration.getIntegrationType(template?.integrationCode ?? null);

    const isFetchingWorkflow = useSelector((state: stateTree.State) =>
        selectors.ui.isOperationInProgress(state, FETCH_WORKFLOW_WITH_VERSION)
    );

    const card = useMemo(() => {
        if (isFetchingWorkflow) {
            return <WorkflowDetailsLoading />;
        } else {
            switch (integrationType) {
                case domain.IntegrationType.Xero:
                    return <XeroWorkflowDetails />;

                case domain.IntegrationType.QBooks:
                    return <QBooksWorkflowDetails />;

                case domain.IntegrationType.NetSuite:
                    return <NetSuiteWorkflowDetails />;

                case domain.IntegrationType.Dear:
                    return <DearWorkflowDetails />;

                case domain.IntegrationType.None:
                    return <StandaloneWorkflowDetails />;

                default:
                    throw errorHelpers.assertNever(integrationType);
            }
        }
    }, [integrationType, isFetchingWorkflow]);

    return (
        <Root $open={versionHistory.isOpen} className='fs-mask'>
            <Flex inline spacing='8' wrap={false} alignItems='start'>
                {card}
            </Flex>
        </Root>
    );
});

export default WorkflowDetails;
