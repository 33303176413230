import './devToolsPopup.scss';

import { Popup } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { stateTree } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { connect } from 'react-redux';

import BackendSection from './BackendSection/BackendSection';
import CompanySection from './CompanySection';
import WorkflowSection from './WorkflowSection';

const bem = bemFactory.block('automation-dev-tools-popup');

const i18nPrefix = 'automation.DevToolsPopup';

enum ActiveSection {
    General = 'General',
    Company = 'Company',
    Workflow = 'Workflow',
}

interface OwnProps {}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

interface OwnState {
    section: ActiveSection;
    needsReload: boolean;
}

function mapStateToProps(state: stateTree.State) {
    return {};
}

const mapDispatchToProps = {};

class DevToolsPopup extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            section: ActiveSection.General,
            needsReload: false,
        };
    }

    public render() {
        const {} = this.props;

        let menuItems = [
            {
                id: ActiveSection.General,
                text: 'General',
            },
            {
                id: ActiveSection.Company,
                text: 'Company',
            },
            {
                id: ActiveSection.Workflow,
                text: 'Workflow',
            },
        ].map((x) => (
            <div
                key={x.id}
                className={bem('menu-item', { selected: x.id === this.state.section })}
                onClick={() => this._changeSection(x.id)}
            >
                {x.text}
            </div>
        ));

        let section;

        switch (this.state.section) {
            case ActiveSection.General:
                section = <BackendSection onNeedsReload={this._onNeedsReload} />;
                break;

            case ActiveSection.Company:
                section = <CompanySection onNeedsReload={this._onNeedsReload} className={bem('section-content')} />;
                break;

            case ActiveSection.Workflow:
                section = <WorkflowSection onNeedsReload={this._onNeedsReload} className={bem('section-content')} />;
                break;

            default:
                errorHelpers.throwInvalidOperationError();
        }

        return (
            <Popup.DefaultContent
                title='[Internals] ApprovalMax Power Tools'
                className={bem()}
                closeCommand={{ disabled: this.state.needsReload }}
                buttons={
                    this.state.needsReload ? (
                        <Popup.DefaultContent.Button execute={this._finishAndReload}>
                            Reload
                        </Popup.DefaultContent.Button>
                    ) : (
                        []
                    )
                }
            >
                <div className={bem('content')}>
                    <div className={bem('menu')}>{menuItems}</div>

                    <div className={bem('section')}>{section}</div>
                </div>
            </Popup.DefaultContent>
        );
    }

    private _onNeedsReload = () => {
        this.setState({
            needsReload: true,
        });
    };

    private _changeSection = (section: ActiveSection) => {
        this.setState({
            section,
        });
    };

    private _finishAndReload = () => {
        window.location.reload();
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DevToolsPopup);
