export enum WizardStep {
    Initial = 'Initial',
    TypeOfDocs = 'TypeOfDocs',
    SourceOfDocs = 'SourceOfDocs',
    ApprovalSteps = 'ApprovalSteps',
    Users = 'Users',
    Requesters = 'Requesters',
    Conditions = 'Conditions',
    Preview = 'Preview',
}

export enum SourceOfDocs {
    ApprovalMax = 'ApprovalMax',
    Xero = 'Xero',
    Dext = 'Dext',
}

export interface ApprovalStep {
    name: string;
}

export interface WorkflowWizardPopupProps {
    onClose?: VoidFunction;
}
