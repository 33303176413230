import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.QBooksStartStep', {
    bubbleText: 'Creation / Pulling of {templateName}',
    submitterListDescriptionEmpty:
        'Add at least one user authorised to create {templateName} and submit them for approval in ApprovalMax.',
    submitterListDescriptionNonEmpty:
        'Add users authorised to create {templateName} and submit them for approval in ApprovalMax.',
    createInAmBubbleText: 'Create in ApprovalMax',
});
