import { PascalCaseToCamelCase } from '@approvalmax/types';
import { backend, domain } from 'modules/data';

export interface RequestVersion {
    version: number;
    versionType: RequestVersionType;
    createdAt: string;
    actor?: PascalCaseToCamelCase<backend.UserAnswer>;
    comment: PascalCaseToCamelCase<backend.RequestCommentAnswer> | null;
    userPlatform: domain.RequestHistoryUserPlatform;
}

export interface RequestVersionExtended extends RequestVersion {
    snapshot: RequestVersionSnapshot;
}

export interface RequestVersionSnapshot {
    amount: number;
    attachments?: PascalCaseToCamelCase<backend.AttachmentAnswer>[];
    createdAt: string;
    currency: string;
    friendlyName: string;
    modifiedAt: string;
    requestId: string;
    requestNote: string;
    version: number;
    xeroInvoiceDetails: PascalCaseToCamelCase<backend.IntegrationsXeroInvoiceAnswer>;
    xeroPurchaseOrderDetails: PascalCaseToCamelCase<backend.IntegrationsXeroPurchaseOrderAnswer>;
    xeroSalesInvoiceDetails: PascalCaseToCamelCase<backend.IntegrationsXeroInvoiceAnswer>;
    xeroCreditNoteDetails: PascalCaseToCamelCase<backend.IntegrationsXeroCreditNoteAnswer>;
    xeroBillBatchPaymentDetails: PascalCaseToCamelCase<backend.IntegrationXeroBillBatchPaymentAnswer>;
    xeroContactDetails: domain.XeroContact;
    airwallexXeroBatchPaymentDetails: backend.IntegrationXeroAirwallexBatchPaymentAnswer;
    xeroQuoteDetails: PascalCaseToCamelCase<backend.IntegrationsXeroQuoteAnswer>;
    exchangeRate: number | null;
    exchangeRateSource: domain.ExchangeRateSource | null;
}

export enum RequestVersionType {
    RequesterSubmitted = 'RequesterSubmitted',
    SubmittedViaExternalSystem = 'SubmittedViaExternalSystem',
    RequesterResubmitted = 'RequesterResubmitted',
    StartOverByExternalUpdate = 'StartOverByExternalUpdate',
    ReviewerEdited = 'ReviewerEdited',
    EditorEdited = 'EditorEdited',
    ApproverEdited = 'ApproverEdited',
    PostApprovalChangesDetected = 'PostApprovalChangesDetected',
}
