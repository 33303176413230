import { PageSlice } from 'modules/page';

import { PAGE_ID } from './config';
import entitiesReducer from './reducers/entitiesReducer';
import metaReducer from './reducers/metaReducer';
import navigationReducer from './reducers/navigationReducer';
import pageReducer from './reducers/pageReducer';
import userPreferencesReducer from './reducers/userPreferencesReducer';
import cacheManagement from './sagas/cacheManagement';
import { hasChanges } from './selectors/templateSelectors';

export const page: PageSlice = {
    id: PAGE_ID,
    pageReducer: pageReducer,
    reducers: {
        entities: entitiesReducer,
        navigation: navigationReducer,
        meta: metaReducer,
        userPreferences: userPreferencesReducer,
    },
    sagas: [cacheManagement],
    hasChanges,
};
