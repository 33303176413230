import { Field, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

import { font } from '../../../../styles';

export const Root = styled.div`
    padding: 0 60px 50px 80px;
    overflow: auto;
`;

export const Title = styled.div`
    ${font(16, '#000', 'semibold')}
`;

export const StyledField = styled(Field)`
    margin-top: 20px;
`;

export const StyledPopup = styled(Popup.DefaultContent)`
    width: 800px;
`;
