import { RequestDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UseMigrateToMatchingV2Data } from './useMigrateToMatchingV2.types';

export const useMigrateToMatchingV2 = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<UseMigrateToMatchingV2Data>>(companiesApiPaths.migrateToMatchingV2, {
        mutationOptions: {
            onSuccess: (_response, requestData) => {
                queryClient.invalidateQueries([companiesApiPaths.select, { companyId: requestData.data.companyId }]);
            },
        },
    });
};
