import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addAutoApprovalStep } from '../../../../actions';
import { messages } from './AutoApprovalActivator.messages';
import { AutoApprovalActivatorProps } from './AutoApprovalActivator.types';

export const AutoApprovalActivator: FC<AutoApprovalActivatorProps> = memo((props) => {
    const { hide } = props;

    const dispatch = useDispatch();

    const onEnable = useCallback(() => {
        dispatch(addAutoApprovalStep());
        hide();
    }, [dispatch, hide]);

    return (
        <Box color='midnight20' spacing='8' width={220} height='fit-content' radius='xsmall'>
            <Flex inline justifyContent='space-between' alignItems='center' wrap={false}>
                <Text font='label' fontSize='small' color='midnight80'>
                    {messages.enableAutoApproval}
                </Text>

                <Button icon noPadding onClick={hide}>
                    <CloseIcon size={12} color='midnight80' />
                </Button>
            </Flex>

            <Spacing height={8} />

            <Text font='body' fontSize='small' color='midnight70'>
                {messages.description}
            </Text>

            <Spacing height={4} />

            <Flex inline spacing='8' justifyContent='end'>
                <Button color='transparent' size='xsmall' onClick={hide}>
                    {messages.hide}
                </Button>

                <Button outline color='blue80' size='xsmall' onClick={onEnable}>
                    {messages.enable}
                </Button>
            </Flex>
        </Box>
    );
});

AutoApprovalActivator.displayName = 'AutoApprovalActivator';
