/**
 * Developer: Stepan Burguchev
 * Date: 1/15/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './autoGrowInput.scss';

/**
 * Copies CSS properties from one element to another.
 *
 * @param {object} $from
 * @param {object} $to
 * @param {array} properties
 */
function transferStyles(fromEl: HTMLElement, toEl: HTMLElement, properties: Array<keyof CSSStyleDeclaration>) {
    const sourceStyle = getComputedStyle(fromEl);

    properties.forEach((propName) => {
        toEl.style[propName as any] = sourceStyle[propName] as string;
    });
}

/**
 * Measures the width of a string within a parent element (in pixels).
 *
 * @param {string} str
 * @param {object} $parent
 * @returns {int}
 */
function measureString(str: string, parentEl: HTMLElement) {
    if (!str) {
        return 0;
    }

    const testEl = document.createElement('div');

    testEl.classList.add('auto-grow-input__hidden-div');
    testEl.textContent = str;
    document.body.appendChild(testEl);

    transferStyles(parentEl, testEl, [
        'letterSpacing',
        'fontSize',
        'fontStyle',
        'fontFamily',
        'fontWeight',
        'textTransform',
    ]);

    const width = testEl.offsetWidth;

    testEl.remove();

    return width;
}

/**
 * Sets up an input to grow horizontally as the user
 * types. If the value is changed manually, you can
 * trigger the "update" handler to resize:
 *
 * $input.trigger('update');
 *
 * @param {object} $input
 * @param manualUpdate
 */
export function autoGrowInput(inputEl: HTMLInputElement, { manualUpdate } = { manualUpdate: false }) {
    const EXTRA_SPACE_PX = 4;

    let currentWidth: number | null = null;

    const update = () => {
        let value = inputEl.value;
        let placeholder = inputEl.placeholder;

        if (!value && placeholder) {
            value = placeholder;
        }

        let width = measureString(value, inputEl) + EXTRA_SPACE_PX;

        if (width !== currentWidth) {
            currentWidth = width;
            inputEl.style.width = `${width}px`;
        }
    };

    if (!manualUpdate) {
        inputEl.addEventListener('input', update);
        inputEl.addEventListener('keyup', update);
        inputEl.addEventListener('update', update);
        inputEl.addEventListener('change', update);
    }

    update();

    return update;
}
