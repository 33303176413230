import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroInvoiceStartStep', {
    bubbleText: 'Creation / Pulling of {templateName}',
    title: `
        There are several methods to create or pull {pluralTemplateName} in ApprovalMax.
        Please see the options below and choose as applicable.`,
    createInApprovalMax: 'Create in ApprovalMax',
    description: 'Add users authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    externalSubmitterDescriptionNonEmpty: `
        {pluralTemplateName} will be created in ApprovalMax under the name of this User.
        They will be notified about approvals, rejections, and comments.`,
    externalSubmitterDescriptionEmpty: `
        Enter the user who will be notified about {pluralTemplateName} status changes in ApprovalMax.\n
        A generic email address, e.g. finance@mycompany.com, can also be used.`,
    externalSubmitterTitle:
        '{pluralTemplateName} in the "Awaiting approval" status will be automatically pulled from Xero.',
});
