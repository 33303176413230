import { useCallback } from 'react';

import type { BaseItem, TableProps } from '../../Table.types';

export const useCheckedItems = <Item extends BaseItem>(
    itemId: string,
    checkedItems: TableProps<Item>['checkedItems'],
    onCheckedItemsChange: TableProps<Item>['onCheckedItemsChange']
) => {
    const handleItemCheck = useCallback(
        (checked: boolean) => {
            if (!onCheckedItemsChange) {
                return;
            }

            const checkedSet = new Set(checkedItems);

            if (checked) {
                checkedSet.add(itemId);
                onCheckedItemsChange([...checkedSet]);
            } else {
                checkedSet.delete(itemId);
                onCheckedItemsChange([...checkedSet]);
            }
        },
        [itemId, checkedItems, onCheckedItemsChange]
    );

    return onCheckedItemsChange ? handleItemCheck : undefined;
};
