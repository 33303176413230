/**
 * Developer: Stepan Burguchev
 * Date: 4/23/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './textAreaEditor.scss';

import { autosizeService, errorHelpers, hooks } from '@approvalmax/utils';
import debounce from 'lodash/debounce';
import React, {
    FocusEvent,
    forwardRef,
    TextareaHTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import bemFactory from 'react-bem-factory';

import { FieldContext } from '../../field/Field';
import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';
import { messages } from './TextAreaEditor.messages';

export const ALL_TEXT_AREA_THEMES = ['form', 'transparent'] as const;
export type TextAreaTheme = (typeof ALL_TEXT_AREA_THEMES)[number];

export const ALL_TEXT_AREA_SIZES = [
    'auto' /** see minHeight, maxHeight options */,
    'fixed' /** see height option */,
] as const;
export type TextAreaSize = (typeof ALL_TEXT_AREA_SIZES)[number];

interface Props extends BaseEditorProps<string> {
    placeholder?: string;
    maxLength?: number;
    size?: TextAreaSize;
    theme?: TextAreaTheme;
    className?: string;
    height?: number;
    minHeight?: number;
    maxHeight?: number;
    onCtrlEnter?: () => void;
    changeOnBlur?: boolean;
    onFocus?: (e?: FocusEvent<HTMLTextAreaElement>) => void;
    title?: string;
}

export interface TextAreaEditorElement {
    forceAutosizeUpdate(): void;
    focus(): void;
    blur(): void;
}

const TextAreaEditor = forwardRef<TextAreaEditorElement, Props>((props, ref) => {
    const {
        className,
        theme = 'form',
        size = 'auto',
        invalid,
        qa,
        placeholder = messages.defaultPlaceholder,
        maxLength,
        height = 4,
        minHeight = 2,
        maxHeight = 20,
        changeOnBlur,
        focusOnMount,
        title,
    } = props;
    const bem = bemFactory.block('form-text-area-editor').themed(theme as any);

    const disabled = getDisabled(props.disabled, props.command);

    const [stateValue, setStateValue] = useState(props.value || '');
    const value = changeOnBlur ? stateValue : props.value || '';

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const doingInternalChangeRef = useRef(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayFinishInternalChange = useCallback(
        debounce(() => {
            doingInternalChangeRef.current = false;
        }, 100),
        []
    );

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && props.onCtrlEnter) {
            props.onCtrlEnter();
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        doingInternalChangeRef.current = true;

        if (changeOnBlur) {
            setStateValue(e.target.value);
        } else {
            props.onChange(e.target.value);
        }

        delayFinishInternalChange();
    };

    const onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    const onBlur = () => {
        if (changeOnBlur) {
            props.onChange(stateValue);
        }

        if (props.onBlur) {
            props.onBlur();
        }
    };

    const { fieldId } = useContext(FieldContext);

    hooks.useFocusOnMount(focusOnMount, textAreaRef);

    // Update the stateValue if props.value changes
    useEffect(() => {
        if (changeOnBlur) {
            setStateValue(props.value);
        }
    }, [changeOnBlur, props.value]);

    // Update the height if props.value changes
    useEffect(() => {
        if (size === 'auto') {
            // await of dom change. if we call update without timeout then text area will have an old value
            setTimeout(() => autosizeService.update(textAreaRef.current!), 0);
        }
    }, [size, props.value]);

    // Update maxHeight
    useEffect(() => {
        switch (size) {
            case 'auto':
                if (maxHeight) {
                    const lineHeightStr = getComputedStyle(textAreaRef.current!).lineHeight || '0px';
                    const lineHeight = parseFloat(lineHeightStr.substring(0, lineHeightStr.length - 2));
                    const maxHeightPx = Math.ceil(lineHeight * maxHeight);

                    textAreaRef.current!.style.maxHeight = `${maxHeightPx}px`;
                }

                break;

            case 'fixed':
                break;

            default:
                errorHelpers.assertNever(size);
        }
    }, [maxHeight, size]);

    // Set up autosize
    useEffect(() => {
        const textAreaEl = textAreaRef.current!;

        switch (size) {
            case 'auto':
                autosizeService(textAreaEl);
                break;

            case 'fixed':
                break;

            default:
                errorHelpers.assertNever(size);
        }

        return () => {
            if (size === 'auto') {
                autosizeService.destroy(textAreaEl);
            }
        };
    }, [size]);

    // Manually trigger text-area autosize update when props.value is changed externally.
    // autosizeService() lib cannot detect them properly.
    useEffect(() => {
        if (size === 'auto' && !doingInternalChangeRef.current) {
            autosizeService.update(textAreaRef.current!);
        }

        doingInternalChangeRef.current = false; // avoid trigger autosizeService.update on first render
    }, [size, props.value]);

    useImperativeHandle(
        ref,
        () => ({
            forceAutosizeUpdate() {
                autosizeService.update(textAreaRef.current!);
            },
            focus() {
                textAreaRef.current!.focus();
            },
            blur() {
                textAreaRef.current!.blur();
            },
        }),
        [textAreaRef]
    );

    const textAreaProps: TextareaHTMLAttributes<HTMLTextAreaElement> = {
        className: bem.add(className)(null, {
            invalid,
        }),
        value,
        onKeyDown,
        onChange,
        onFocus,
        onBlur,
        disabled,
        placeholder,
        maxLength,
        title,
        rows: size === 'auto' ? minHeight : height,
    };

    return <textarea id={fieldId} ref={textAreaRef} data-qa={qa} {...textAreaProps} />;
});

export default TextAreaEditor;
