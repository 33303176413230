import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';

import { PAGE_ID } from '../config';
import { ActiveMatrix } from '../reducers/page/activeMatrixReducer';
import { Page } from '../reducers/pageReducer';
import { ActiveMatrixData } from '../types/activeMatrixData';

const i18nPrefix = 'workflow';
const messages = defineMessages({
    dashboardPageTitle: {
        id: `${i18nPrefix}.dashboardPageTitle`,
        defaultMessage: 'Approval Workflows | {companyName}',
    },
    templatePageTitle: {
        id: `${i18nPrefix}.templatePageTitle`,
        defaultMessage: '{templateName} Workflow | {companyName}',
    },
    unnamedTemplateName: {
        id: `${i18nPrefix}.unnamedTemplateName`,
        defaultMessage: 'Unnamed',
    },
});

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function getWorkflowPageDocumentTitle(state: State) {
    const companyName = selectors.navigation.getActiveCompany(state).displayName;

    const templateName = getActiveTemplate(state)?.displayName;

    return intl.formatMessage(messages.templatePageTitle, {
        templateName: templateName || intl.formatMessage(messages.unnamedTemplateName),
        companyName,
    });
}

export function getWorkflowsListPageDocumentTitle(state: State) {
    const companyName = selectors.navigation.getActiveCompany(state).displayName;

    return intl.formatMessage(messages.dashboardPageTitle, {
        companyName,
    });
}

export interface IActiveTemplate extends selectors.types.ExpandedTemplate {
    showAutoApprovalStepWithoutRules?: boolean;
}

export function getActiveTemplate(state: State): IActiveTemplate | null {
    const template = getPage(state).activeTemplate;

    return template
        ? {
              ...selectors.template.expandTemplate(template),
              showAutoApprovalStepWithoutRules: template.showAutoApprovalStepWithoutRules,
          }
        : null;
}

export function getActiveSettings(state: State) {
    return getPage(state).activeSettings;
}

export function getActiveHappyActivation(state: State) {
    return getPage(state).activeHappyActivation;
}

export function getActiveStartOver(state: State) {
    return getPage(state).activeStartOver;
}

export function getActiveInviteUsers(state: State) {
    return getPage(state).activeInviteUsers;
}

export function getActiveVersionConflict(state: State) {
    return getPage(state).activeVersionConflict;
}

export function getExternalSubmitterEnabled(state: State) {
    return getPage(state).externalSubmitterEnabled;
}

export const getActiveFieldSettings = (state: State) => {
    return getPage(state).activeFieldSettings;
};

export interface ExpandedMatrixLine extends domain.MatrixLine {
    user: selectors.types.ExpandedUser;
}

export const getPureActiveMatrix: (state: State) => ActiveMatrixData<domain.MatrixLine> | null = createSelector(
    (state: State) => getPage(state).activeMatrix,
    (matrix: ActiveMatrix) => {
        if (!matrix) {
            return null;
        }

        return matrix;
    }
);

export const getActiveMatrix: (state: State) => ActiveMatrixData<ExpandedMatrixLine> | null = createSelector(
    (state: State) => getPage(state).activeMatrix,
    (state: State) => selectors.user.getUsers(state),
    (matrix: ActiveMatrix, users: selectors.types.ExpandedUser[]) => {
        if (!matrix) {
            return null;
        }

        return {
            ...matrix,
            data: matrix.data.map((x) => {
                const user = users.find((u) => u.id === x.lineId);

                if (!user) {
                    throw errorHelpers.notFoundError(`The user '${x.lineId}' is missing in the store.`);
                }

                return {
                    ...x,
                    user,
                };
            }),
        };
    }
);

export function showCardValidationErrors(state: State) {
    return getPage(state).showCardValidationErrors;
}

export function addingNewStep(state: State) {
    return getPage(state).addingNewStep;
}

export function pageLoading(state: State) {
    return getPage(state).loading;
}

export function isPageReadonly(state: State) {
    const company = selectors.navigation.getActiveCompany(state);
    const template = getPage(state)?.activeTemplate;

    const integrationErrorPreventsEditingTemplate = Boolean(
        !company.flags.hasActiveIntegration && template && template.integrationCode
    );

    const isTemplateNotActual = Boolean(template && !(template.isActual ?? true));
    // INFO: template.isActual is not defined if it's a new (not ever been saved) standalone template

    const isAirwallexTemplate = template?.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment;
    const hasConnectedAirwallex = company.airwallexIntegration?.isConnected;

    return (
        !selectors.company.canUpdateActiveCompanySettings(state) ||
        integrationErrorPreventsEditingTemplate ||
        company.isReadonly ||
        isTemplateNotActual ||
        (isAirwallexTemplate && !hasConnectedAirwallex)
    );
}

export function getActiveCopyTemplate(state: State) {
    return getPage(state).activeCopyTemplate;
}

export function getAddCommentPopup(state: State) {
    return getPage(state).addCommentPopup;
}

export function getVersionHistory(state: State) {
    return getPage(state).versionHistory;
}
