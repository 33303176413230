import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { messages } from './WarningList.messages';

const getQBooksWarnings = (
    fromWorkflow: selectors.types.ExpandedTemplate,
    toWorkflow: selectors.types.ExpandedTemplate
): string[] => {
    if (fromWorkflow.companyId === toWorkflow.companyId) {
        return [];
    }

    const QBooksWarningList: string[] = [];

    if (fromWorkflow.companyId !== toWorkflow.companyId) {
        QBooksWarningList.push(messages.QBooksDifferentValues);
    }

    return QBooksWarningList;
};

const getXeroWarnings = (
    fromWorkflow: selectors.types.ExpandedTemplate,
    toWorkflow: selectors.types.ExpandedTemplate
): string[] => {
    const combinedIntegrationCode = `${fromWorkflow.integrationCode}/${toWorkflow.integrationCode}`;

    if (fromWorkflow.companyId === toWorkflow.companyId) {
        switch (combinedIntegrationCode) {
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroPo}`:
                return [
                    messages.xeroDextRequesterWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroReviewerStepWarning,
                ];

            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
                return [
                    messages.xeroDextRequesterWarning,
                    messages.xeroCreateBillRequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroReviewerStepWarning,
                ];

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroBill}`:
                return [messages.xeroNoDefaultSubmitterWarning, messages.xeroNoCreateBillRequestersWarning];

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroPo}`:
                return [messages.xeroNoDefaultSubmitterWarning, messages.xeroNoCreatePORequestersWarning];

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                return [messages.xeroNoDefaultSubmitterWarning];

            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                return [
                    messages.xeroCreatePORequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                ];
        }
    }

    if (fromWorkflow.companyId !== toWorkflow.companyId) {
        switch (combinedIntegrationCode) {
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroPo}`:
                return [
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroDextRequesterWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroReviewerStepWarning,
                ];

            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
                return [
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroDextRequesterWarning,
                    messages.xeroCreateBillRequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroReviewerStepWarning,
                ];

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroBill}`:
                return [
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroNoCreateBillRequestersWarning,
                ];

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroPo}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroPo}`:
                return [
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                    messages.xeroNoCreatePORequestersWarning,
                ];

            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                return [messages.differentValuesInTargetOrgWarning, messages.xeroNoDefaultSubmitterWarning];

            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
                return [
                    messages.differentValuesInTargetOrgWarning,
                    messages.xeroCreatePORequestersWarning,
                    messages.xeroMatrixRulesWarning,
                    messages.xeroNoDefaultSubmitterWarning,
                ];

            case `${domain.IntegrationCode.XeroBill}/${domain.IntegrationCode.XeroBill}`:
            case `${domain.IntegrationCode.XeroCreditNotesPayable}/${domain.IntegrationCode.XeroCreditNotesPayable}`:
            case `${domain.IntegrationCode.XeroCreditNotesReceivable}/${domain.IntegrationCode.XeroCreditNotesReceivable}`:
            case `${domain.IntegrationCode.XeroInvoice}/${domain.IntegrationCode.XeroInvoice}`:
            case `${domain.IntegrationCode.XeroPo}/${domain.IntegrationCode.XeroPo}`:
                return [messages.differentValuesInTargetOrgWarning];
        }
    }

    return [];
};

export const getWarnings = (
    fromWorkflow: selectors.types.ExpandedTemplate | null,
    toWorkflow: selectors.types.ExpandedTemplate | null
): string[] => {
    if (!fromWorkflow || !toWorkflow) {
        return [];
    }

    if (!fromWorkflow.integrationCode && !toWorkflow.integrationCode) {
        return [];
    }

    const warningList: string[] = [];

    if (fromWorkflow.integrationCode !== toWorkflow.integrationCode) {
        warningList.push(messages.applicableWorkflowSettingsCopyWarning);
    }

    if (fromWorkflow.integrationCode?.startsWith('QBooks')) {
        return [...warningList, ...getQBooksWarnings(fromWorkflow, toWorkflow)];
    }

    if (fromWorkflow.integrationCode?.startsWith('Xero')) {
        return [...warningList, ...getXeroWarnings(fromWorkflow, toWorkflow)];
    }

    return warningList;
};
