import { componentHelpers, hooks } from '@approvalmax/utils';
import { ElementType, KeyboardEventHandler, useContext } from 'react';

import { TabsContext } from '../../Tabs.context';
import { EndIcon, Inner, Root, StartIcon } from './Tab.styles';
import { TabProps } from './Tab.types';

/**
 * A Tab component for displaying a tab label.
 */
const Tab = <Component extends ElementType = 'div'>(props: TabProps<Component>) => {
    const {
        as,
        value = 0,
        label,
        disabled,
        startIcon,
        endIcon,
        width,
        textAlign,
        wrap = true,
        divider = true,
        ...restProps
    } = props;

    const { value: selectedValue, onChange, color, size } = useContext(TabsContext);
    const active = value === selectedValue;
    const { inputRef, parentProps } = hooks.useCaptureFocus();

    const handleClick = () => onChange && typeof value !== 'undefined' && onChange(value);

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (['Enter', ' '].includes(event.key)) {
            handleClick();
        }
    };

    return (
        <Root role='tab' aria-selected={active} $width={width}>
            <Inner
                as={as ?? 'div'}
                $active={active}
                $color={color}
                $size={size}
                $disabled={disabled}
                $textAlign={textAlign}
                $divider={divider}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                {...restProps}
                {...parentProps}
                ref={inputRef}
            >
                {startIcon && <StartIcon>{startIcon}</StartIcon>}

                {label}

                {endIcon && <EndIcon>{endIcon}</EndIcon>}
            </Inner>
        </Root>
    );
};

Tab.displayName = 'Tab';

export default componentHelpers.genericMemo(Tab);
