import { ApiSource, ApiVersion, HEADER_SESSION_EXPIRATION_DATE, HEADER_WEB_APP_VERSION } from '@approvalmax/data';
import { getApiUrl, rootApiLegacy } from 'shared/data';

import { EventRouterService } from '../eventRouter';
import { ApiMethods } from './methods';
import AccountApi from './methods/AccountApi';
import DearApi from './methods/DearApi';
import NetSuiteApi from './methods/NetSuiteApi';
import QBooksMatchingApi from './methods/QBooksMatchingApi';
import ReportsApi from './methods/ReportsApi';
import SupportApi from './methods/SupportApi';
import XeroGrnApi from './methods/XeroGrnApi';
import XeroMatchingApi from './methods/XeroMatchingApi';
import { ApiCallOptions, ApiServiceEventMap } from './types';

export class ApiService extends ApiMethods {
    private _eventRouter = new EventRouterService<ApiServiceEventMap>();

    constructor() {
        super();

        const handler = {
            doApiCall: this._doApiCall.bind(this),
            getApiUrlPrefix: this._getApiUrlPrefix.bind(this),
        };

        this.account = new AccountApi(handler);
        this.reports = new ReportsApi(handler);
        this.xeroMatching = new XeroMatchingApi(handler);
        this.xeroGrn = new XeroGrnApi(handler);
        this.qBooksMatching = new QBooksMatchingApi(handler);
        this.support = new SupportApi(handler);
        this.netSuite = new NetSuiteApi(handler);
        this.dear = new DearApi(handler);
    }

    public addEventListener = this._eventRouter.addEventListener;
    public removeEventListener = this._eventRouter.removeEventListener;

    public expandUrl(actionUrl: string, apiSource: ApiSource = 'webApp', apiVersion: ApiVersion = 'v1') {
        return getApiUrl({ apiSource, apiVersion }) + actionUrl;
    }

    public postFormData(actionUrl: string, formData: FormData) {
        return this._doApiCall({
            action: actionUrl,
            allowsAnonymous: false,
            data: formData,
            method: 'POST',
        });
    }

    protected _getApiUrlPrefix(options: { apiVersion?: ApiVersion } = {}) {
        return getApiUrl(options);
    }

    protected async _doApiCall(options: ApiCallOptions) {
        const apiUrl = getApiUrl({ apiVersion: options.apiVersion });
        const url = this.expandUrl(options.action, options.apiSource, options.apiVersion);
        const isFormData = options.data instanceof FormData;

        if (options.method !== 'GET') {
            this._eventRouter.trigger('activeOperation', { type: 'increase' });
        }

        return await rootApiLegacy({
            method: options.apiVersion === 'v2' ? options.method : 'POST',
            data: options.data,
            url,
            baseURL: apiUrl,
            params: options.apiVersion === 'v2' && options.method === 'GET' ? options.data : undefined,
            headers: { 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' },
        })
            .then((response) => {
                this._eventRouter.trigger('requestSuccess', {
                    sessionExpirationDate: response.headers[HEADER_SESSION_EXPIRATION_DATE],
                    webAppVersion: response.headers[HEADER_WEB_APP_VERSION],
                });

                return response.data;
            })
            .catch((error) => {
                this._eventRouter.trigger('requestError', {
                    error,
                    allowsAnonymous: options.allowsAnonymous,
                });

                throw error;
            })
            .finally(() => {
                if (options.method !== 'GET') {
                    this._eventRouter.trigger('activeOperation', { type: 'decrease' });
                }
            });
    }

    public account: AccountApi;
    public reports: ReportsApi;
    public xeroMatching: XeroMatchingApi;
    public xeroGrn: XeroGrnApi;
    public qBooksMatching: QBooksMatchingApi;
    public netSuite: NetSuiteApi;
    public dear: DearApi;
}

export const api = new ApiService();
