import { Guid } from '@approvalmax/types';
import { openSplitViewState } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { setRecoil } from 'recoil-nexus';

import { createReportConfig } from '../data/reportConfig';
import { Entities } from '../reducers/entitiesReducer';
import { PreviewMode } from '../reducers/page/documentPreviewReducer';
import { getReportType } from '../selectors/pageSelectors';
import { getReportConfigById } from '../selectors/reportConfigSelectors';
import { CustomFields } from '../selectors/types';
import { ReportConfig } from '../types/ReportConfig';

export const LOAD_PAGE_DATA = 'REPORTS/LOAD_PAGE_DATA';
export const loadPageData = (
    companyId: string,
    entities: Partial<Entities>,
    reportType: domain.ReportType,
    innerActivePageId: string,
    fields?: CustomFields
) => createAction(LOAD_PAGE_DATA, { companyId, entities, reportType, innerActivePageId, fields });

export const SHOW_DASHBOARD = 'REPORTS/SHOW_DASHBOARD';
export const showDashboard = () => createAction(SHOW_DASHBOARD, {});

export const SHOW_REPORT_CARD = 'REPORTS/SHOW_REPORT_CARD';

interface ShowReportCardAction {
    type: typeof SHOW_REPORT_CARD;
    reportConfig: ReportConfig;
}

export function showReportCard(reportConfigId: string): ThunkAction<State> {
    return (dispatch, getState) => {
        const reportConfig = getReportConfigById(getState(), reportConfigId);

        dispatch<ShowReportCardAction>({
            type: SHOW_REPORT_CARD,
            reportConfig,
        });
    };
}

export const SHOW_NEW_REPORT_CARD = 'REPORTS/SHOW_NEW_REPORT_CARD';

interface ShowNewReportCardAction {
    type: typeof SHOW_NEW_REPORT_CARD;
    reportConfig: ReportConfig;
}

export function showNewReportCard(): ThunkAction<State> {
    return (dispatch, getState) => {
        const company = selectors.navigation.getActiveCompany(getState());
        const reportType = getReportType(getState());
        const reportConfig = createReportConfig(company.id, reportType);

        dispatch<ShowNewReportCardAction>({
            type: SHOW_NEW_REPORT_CARD,
            reportConfig,
        });
    };
}

export const SHOW_DOCUMENT_PREVIEW = 'REPORTS/SHOW_DOCUMENT_PREVIEW';

export const showDocumentPreview = (options: { requestId: Guid; attachmentId?: Guid; previewMode: PreviewMode }) => {
    setRecoil(openSplitViewState, false);

    return createAction(SHOW_DOCUMENT_PREVIEW, options);
};

export const CLOSE_DOCUMENT_PREVIEW = 'REPORTS/CLOSE_DOCUMENT_PREVIEW';
export const closeDocumentPreview = () => createAction(CLOSE_DOCUMENT_PREVIEW, {});

export const LEAVE_PAGE = 'REPORTS/LEAVE_PAGE';
export const leavePage = () => createAction(LEAVE_PAGE, {});

export type Action =
    | ExtractActions<
          | typeof closeDocumentPreview
          | typeof leavePage
          | typeof loadPageData
          | typeof showDashboard
          | typeof showDocumentPreview
      >
    | ShowReportCardAction
    | ShowNewReportCardAction;
