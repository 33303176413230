import './companySection.scss';

import { ActionMenu, DataTable, DataTableColumnDefinition, ToolbarButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import { TbEllipsisIcon } from 'modules/sprites';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { connect } from 'react-redux';

import { deleteCompanies } from '../actions';

const i18nPrefix = 'automation.CompanySection';

interface OwnProps {
    className?: string;
    onNeedsReload(): void;
}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

interface OwnState {
    checkedItems: string[];
}

function mapStateToProps(state: stateTree.State) {
    return {
        companies: selectors.company.getCompanies(state),
    };
}

const mapDispatchToProps = {
    deleteCompanies,
};

enum ColumnId {
    DisplayName = 'displayName',
}

const tableColumns: DataTableColumnDefinition[] = [
    {
        id: ColumnId.DisplayName.toString(),
        name: 'Company',
        cellComponent: (props) => <DataTable.CheckboxCell {...props} cellComponent={DataTable.DefaultCell} />,
        columnComponent: (props) => (
            <DataTable.CheckboxColumnHeader {...props} columnComponent={DataTable.DefaultColumnHeader} />
        ),
    },
];

class CompanySection extends React.Component<Props, OwnState> {
    public state: OwnState = {
        checkedItems: [],
    };

    public render() {
        const { companies, className } = this.props;
        const bem = bemFactory.block('automation-company-section');

        return (
            <div className={bem.add(className)()}>
                <div className={bem('toolbar')}>
                    <ActionMenu
                        panelFlow='to-left'
                        button={
                            <ToolbarButton>
                                <TbEllipsisIcon width={24} height={6} />
                            </ToolbarButton>
                        }
                    >
                        <ActionMenu.Item execute={this._deleteSelectedCompanies}>
                            Delete {this.state.checkedItems.length} companies
                        </ActionMenu.Item>
                    </ActionMenu>
                </div>

                <DataTable
                    columns={tableColumns}
                    data={companies}
                    className={bem('table')}
                    checkedItems={this.state.checkedItems}
                    onCheckedItemsChange={this._onCheckedItemsChange}
                />
            </div>
        );
    }

    private _deleteSelectedCompanies = () => {
        this.props.deleteCompanies(this.state.checkedItems);
        this.setState({
            checkedItems: [],
        });
        this.props.onNeedsReload();
    };

    private _onCheckedItemsChange = (checkedItems: string[]) => {
        this.setState({
            checkedItems,
        });
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySection);
