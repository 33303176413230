import { variables } from '@approvalmax/theme';
import { Decorator, StoryContext, StoryFn } from '@storybook/react';
import camelCase from 'lodash/camelCase';
import * as prettierPluginBabel from 'prettier/plugins/babel';
import * as prettierPluginEstree from 'prettier/plugins/estree';
import * as prettierPluginHtml from 'prettier/plugins/html';
import { format } from 'prettier/standalone';
import { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';
import { GlobalStyleComponent, ThemeProvider } from 'styled-components';

import { StoryBox } from './components';
import { storyByPropParameter, transformCodePropParameter } from './storybook.constants';
import { Root } from './stylebook.styles';

const transformDocSource = async (source: string, context: StoryContext) => {
    const transformCodeProp = context.parameters[transformCodePropParameter];

    /**
     * Storybook can't handle icon components yet, so we replace an object with an Icon
     */
    source = source.replace(/<\[object Object]/g, '<Icon');

    if (!transformCodeProp || !transformCodeProp.options) return source;

    const name = transformCodeProp.name;
    const values = transformCodeProp.options;

    /**
     * We need to wrap the prop value in quotes if it's a string
     */
    const propWrapper = typeof values[0] === 'string' ? "'.'" : '{.}';
    const componentName = context.kind.replace(/.*\/(.+)/g, '$1');

    /**
     * From the code we get the current component
     */
    const pattern = `(<${componentName})`;
    const reg = new RegExp(pattern, 'g');

    /**
     * We replace the current component with the same component with the prop
     */
    const code = values.reduce((acc: string, item: any) => {
        const value = propWrapper.replace('.', item);
        const valueString = typeof item === 'boolean' && item ? '' : `=${value}`;

        acc += source.replace(reg, `$1 ${name + valueString}`);

        return acc + '\n\n';
    }, '');

    let result: string | Promise<string> = source;

    try {
        /**
         * We format the code with prettier
         */
        result = await format(`<>${code}</>`, {
            tabWidth: 4,
            singleQuote: true,
            bracketSameLine: false,
            bracketSpacing: true,
            trailingComma: 'es5',
            jsxSingleQuote: true,
            quoteProps: 'preserve',
            arrowParens: 'always',
            htmlWhitespaceSensitivity: 'css',
            useTabs: false,
            parser: 'babel',
            plugins: [prettierPluginHtml, prettierPluginBabel, prettierPluginEstree],
        });
    } catch (e) {
        console.warn(`Can't format code:\n`, e);
        console.warn(`The reason could be this bug:`, 'https://github.com/storybookjs/storybook/issues/22127');
    }

    return result;
};

/**
 * This decorator is used to show the component with different themes and global styles
 */
const withRoot =
    (GlobalStyles: GlobalStyleComponent<any, any>, variables: Record<string, any>) =>
    (Story: StoryFn, context: StoryContext) => {
        const themeNameFromContext = context.parameters.backgrounds.values.find(({ value }: { value: string }) => {
            return value === context.globals?.backgrounds?.value;
        })?.name;
        const selectedThemeName = themeNameFromContext || 'light';

        const selectedTheme = {
            ...variables,
            ...variables.theme[selectedThemeName],
        };

        return (
            <RecoilRoot>
                <ThemeProvider theme={selectedTheme}>
                    <GlobalStyles />

                    <Root>
                        <Story {...context} />
                    </Root>

                    <br />
                </ThemeProvider>
            </RecoilRoot>
        );
    };

/**
 * Returns a string with a value for the title of the story box
 */
const getStoryBoxTitleValue = (propName: string, propValue: any) => {
    /**
     * If the value is a string, we cut it to 40 characters
     */
    if (typeof propValue === 'string' && propValue.length > 40) return propValue.substring(0, 40) + '...';

    /**
     * If propName is startIcon or endIcon, we return an Icon
     */
    if (propName === 'startIcon' || propName === 'endIcon') return 'Icon';

    /**
     * If propName is propValue is function, we return a Function title
     */
    if (typeof propValue === 'function') return 'Function';

    /**
     * If the value is an array or an object, we stringify it
     */
    if (Array.isArray(propValue) || (typeof propValue === 'object' && !propValue.$$typeof))
        return JSON.stringify(propValue);

    return propValue;
};

/**
 * This decorator is used to show the component with different prop values
 */
const withStoriesByProp: Decorator = (Story, context) => {
    const { args, argTypes, moduleExport, parameters, name: storyName } = context;

    const hasStoryBox =
        (typeof parameters.storyBox === 'undefined' ? true : parameters.storyBox) && storyName !== 'Default';

    /**
     * If the story has a storyByProp parameter, we use it, otherwise we use the story name
     */
    const storyByPropConfig = parameters[storyByPropParameter];
    const isPropParameterWithValues = typeof storyByPropConfig === 'object';
    const name =
        (isPropParameterWithValues ? Object.keys(storyByPropConfig)[0] : storyByPropConfig) || camelCase(storyName);

    const prop: StoryContext['argTypes'][string] | null = argTypes[name] ? { ...argTypes[name] } : null;

    /**
     * Merge custom args with the default args
     */
    const Component = (props?: object) => Story({ ...context, args: { ...context.args, ...props } });

    if (prop && storyByPropConfig !== false) {
        /**
         * We add the prop to the context to use it in the transformDocSource function
         */
        context.parameters[transformCodePropParameter] = prop;

        /**
         * If the prop is boolean, we add the options true and false
         */
        if (
            prop.control === 'boolean' ||
            (prop.control &&
                typeof prop.control === 'object' &&
                'type' in prop.control &&
                prop.control.type === 'boolean')
        ) {
            prop.options = [true, false];
        }

        /**
         * If prop values were passed in parameters, we use it
         */
        if (isPropParameterWithValues) {
            prop.options = Array.isArray(storyByPropConfig[name]) ? storyByPropConfig[name] : [storyByPropConfig[name]];
        }

        if (prop.options) {
            const elements: ReactNode[] = [];

            /**
             * We render the component by the target prop values
             */
            prop.options.forEach((value: any, index: number) => {
                const newProp = { [name]: value };

                if (hasStoryBox) {
                    return elements.push(
                        <StoryBox
                            title={`${name}=${getStoryBoxTitleValue(name, value)}`}
                            description={prop.description}
                            key={index}
                        >
                            <Component {...newProp} />
                        </StoryBox>
                    );
                }

                return elements.push(<Component {...newProp} key={index} />);
            });

            return <>{elements}</>;
        }
    }

    if (hasStoryBox) {
        return (
            <StoryBox
                title={prop ? `${name}=${getStoryBoxTitleValue(name, args[name])}` : storyName}
                description={prop?.description}
            >
                <Component />
            </StoryBox>
        );
    }

    return <Component />;
};

export const parameters = {
    controls: {
        expanded: true,
        sort: 'requiredFirst',
    },
    backgrounds: {
        default: 'light',
        values: [
            {
                name: 'light',
                value: '#ffffff',
            },
            {
                name: 'dark',
                value: '#292A33',
            },
        ],
    },
    viewport: {
        viewports: {
            large: {
                name: 'Large desktop',
                styles: {
                    width: variables.container.viewport.large,
                    height: '100%',
                },
            },
            medium: {
                name: 'Medium desktop',
                styles: {
                    width: variables.container.viewport.medium,
                    height: '100%',
                },
            },
            small: {
                name: 'Tablet',
                styles: {
                    width: variables.container.viewport.small,
                    height: '100%',
                },
            },
            xsmall: {
                name: 'Large mobile',
                styles: {
                    width: variables.container.viewport.xsmall,
                    height: '100%',
                },
            },
            xxsmall: {
                name: 'Small mobile',
                styles: {
                    width: variables.container.viewport.xxsmall,
                    height: '100%',
                },
            },
        },
    },
    docs: {
        source: {
            transform: transformDocSource,
        },
    },
    design: {
        type: 'figma',
        url: 'https://www.figma.com/file/sGaoIKO5T7Eb7XWi5fkL95/AM-%E2%80%94-APP-%E2%80%94-Components?t=4ag2qIo0pglgDGJv-6',
    },
};

export const storybookService = {
    withRoot,
    parameters,
    withStoriesByProp,
};
