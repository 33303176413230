import './amountConditionCell.scss';

import { ButtonElement, Dropdown, NumberEditor, TransparentButton } from '@approvalmax/ui';
import { browserHelpers, intl, numberHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';

import ErrorIcon from '../ErrorIcon';

const i18nPrefix = 'workflows.components.card.cells.AmountConditionCell';

const messages = defineMessages({
    rule_amount_condition_toggle_text_above: {
        id: `${i18nPrefix}.rule_amount_condition_toggle_text_above`,
        defaultMessage: 'Over or equal to',
    },

    rule_amount_condition_toggle_text_within: {
        id: `${i18nPrefix}.rule_amount_condition_toggle_text_within`,
        defaultMessage: 'Within',
    },

    rule_amount_condition_toggle_text_below: {
        id: `${i18nPrefix}.rule_amount_condition_toggle_text_below`,
        defaultMessage: 'Under',
    },

    rule_amount_condition_toggle_text_any: {
        id: `${i18nPrefix}.rule_amount_condition_toggle_text_any`,
        defaultMessage: 'Any amount',
    },

    rule_editor_amount_condition_click_title: {
        id: `${i18nPrefix}.rule_editor_amount_condition_click_title`,
        defaultMessage: 'Change amount rule',
    },

    rule_amount_condition_above_preview: {
        id: `${i18nPrefix}.rule_amount_condition_above_preview`,
        defaultMessage: 'Over or equal to {gv}',
    },

    rule_amount_condition_any_preview: {
        id: `${i18nPrefix}.rule_amount_condition_any_preview`,
        defaultMessage: 'Any amount',
    },

    rule_amount_condition_below_preview: {
        id: `${i18nPrefix}.rule_amount_condition_below_preview`,
        defaultMessage: 'Under {lv}',
    },

    rule_amount_condition_within_preview: {
        id: `${i18nPrefix}.rule_amount_condition_within_preview`,
        defaultMessage: 'Within {gv}-{lv}',
    },

    rule_amount_condition_below_not_set: {
        id: `${i18nPrefix}.rule_amount_condition_below_not_set`,
        defaultMessage: 'not set',
    },

    rule_amount_condition_above_not_set: {
        id: `${i18nPrefix}.rule_amount_condition_above_not_set`,
        defaultMessage: 'not set',
    },
});

const rangeTypeMap = {
    [domain.NumericRangeConditionType.Above]: intl.formatMessage(messages.rule_amount_condition_toggle_text_above),
    [domain.NumericRangeConditionType.Within]: intl.formatMessage(messages.rule_amount_condition_toggle_text_within),
    [domain.NumericRangeConditionType.Below]: intl.formatMessage(messages.rule_amount_condition_toggle_text_below),
    [domain.NumericRangeConditionType.Any]: intl.formatMessage(messages.rule_amount_condition_toggle_text_any),
};

interface Props {
    lineId: string;
    rule: domain.MatrixRule;
    field: domain.Field;
    integrationCode: domain.IntegrationCode | null;
    condition: domain.NumericRangeCondition;
    readonly?: boolean;
    companyDefaultCurrency: string;
    minValue?: number;
    maxValue?: number;
    showCurrency?: boolean;
    onConditionChange(
        lineId: string,
        rule: domain.MatrixRule,
        field: domain.Field,
        newCondition: domain.NumericRangeCondition
    ): void;
}

interface State {
    inEdit: boolean;
    isRangeTypeDropdownOpen: boolean;
}

class AmountConditionCell extends React.Component<Props, State> {
    public state = {
        inEdit: false,
        isRangeTypeDropdownOpen: false,
    };
    private _el: HTMLDivElement | null = null;
    private _dropdownComponent: ButtonElement | null = null;
    private _value1Component: HTMLInputElement | null = null;
    private _value2Component: HTMLInputElement | null = null;

    public shouldComponentUpdate(nextProps: Props, nextState: State) {
        return (
            this.props.condition !== nextProps.condition ||
            this.state.inEdit !== nextState.inEdit ||
            this.state.isRangeTypeDropdownOpen !== nextState.isRangeTypeDropdownOpen
        );
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevState.inEdit && this.state.inEdit) {
            this._focusAppropriateEditor(this.props.condition.numericRangeConditionType);
        }
    }

    public render() {
        const { condition, readonly, field, minValue, maxValue } = this.props;
        const { isRangeTypeDropdownOpen, inEdit } = this.state;

        const previewText = this._getConditionPreviewText();
        const active = condition.numericRangeConditionType !== domain.NumericRangeConditionType.Any;
        const invalid = !selectors.matrix.isValidCondition(condition);
        const rangeTypeText = rangeTypeMap[condition.numericRangeConditionType];

        const bem = bemFactory.block('wfc-amt-condition-cell');
        const qa = bemFactory.qa('wfc-amt-condition-cell');

        const rangeTypeOptions = [
            domain.NumericRangeConditionType.Above,
            domain.NumericRangeConditionType.Below,
            domain.NumericRangeConditionType.Within,
            domain.NumericRangeConditionType.Any,
        ].map((x) => {
            return (
                <div
                    key={x}
                    className={bem('range-type-panel-i')}
                    data-qa={qa('condition-type-dropdown-item')}
                    data-qa-id={x}
                    onClick={() => this._changeRangeType(x)}
                >
                    {rangeTypeMap[x]}
                </div>
            );
        });

        return (
            <div
                className={bem()}
                onMouseDown={this._preventBlurExceptRefocus}
                data-qa={qa()}
                data-qa-id={field.id}
                data-qa-name={field.name}
            >
                <div ref={(ref) => (this._el = ref)}>
                    {!inEdit ? (
                        <div
                            className={bem('preview', { readonly })}
                            title={intl.formatMessage(messages.rule_editor_amount_condition_click_title)}
                            data-qa={qa('preview')}
                            onClick={!readonly ? this._beginEdit : undefined}
                        >
                            <div className={bem('preview-text', { active, readonly, invalid })}>{previewText}</div>

                            {invalid && <ErrorIcon />}
                        </div>
                    ) : (
                        <div>
                            <Dropdown
                                onRequestClose={this._closeRangeTypeDropdown}
                                panelFlow='to-right'
                                isOpen={isRangeTypeDropdownOpen}
                                button={
                                    <TransparentButton
                                        execute={this._onOpenRangeTypeButtonClick}
                                        onFocus={this._openRangeTypeDropdown}
                                        onBlur={this._onEditorBlur}
                                        disabled={readonly}
                                        ref={(ref) => (this._dropdownComponent = ref!)}
                                        qa={qa('range-menu')}
                                        className={bem('range-dropdown-btn', {
                                            empty: !active,
                                            readonly,
                                        })}
                                        title={rangeTypeText}
                                    >
                                        {rangeTypeText}
                                    </TransparentButton>
                                }
                            >
                                <div className={bem('range-type-panel')} onMouseDown={this._preventBlur}>
                                    {rangeTypeOptions}
                                </div>
                            </Dropdown>

                            {this._hasEditor1() && (
                                <div className={bem('value-editor-wrp')}>
                                    <NumberEditor
                                        ref={(ref) => (this._value1Component = ref)}
                                        className={bem('value-editor', { invalid })}
                                        qa={qa('value1')}
                                        onChange={this._changeValue1}
                                        onEnter={this._onEnter}
                                        onBlur={this._onEditorBlur}
                                        allowFloat
                                        value={this._getValue1()}
                                        theme='transparent'
                                        changeOnBlur
                                        min={minValue}
                                        max={maxValue}
                                    />

                                    <ErrorIcon className={bem('error-icon', { invalid })} />
                                </div>
                            )}

                            {this._hasEditor2() && (
                                <div className={bem('value-editor-wrp')}>
                                    <NumberEditor
                                        ref={(ref) => (this._value2Component = ref)}
                                        className={bem('value-editor', { invalid })}
                                        qa={qa('value2')}
                                        onChange={this._changeValue2}
                                        onEnter={this._onEnter}
                                        onBlur={this._onEditorBlur}
                                        allowFloat
                                        value={this._getValue2()}
                                        theme='transparent'
                                        changeOnBlur
                                        min={minValue}
                                        max={maxValue}
                                    />

                                    <ErrorIcon className={bem('error-icon', { invalid })} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private _onEnter = () => {
        this._endEdit();
    };

    private _getFormattedValue(value: number) {
        const { companyDefaultCurrency, showCurrency = true } = this.props;

        return showCurrency ? intl.formatCurrency(value, companyDefaultCurrency) : intl.formatNumber(value);
    }

    private _getConditionPreviewText() {
        const { condition } = this.props;

        let lv = numberHelpers.isNumber(condition.numericRangeLess)
            ? this._getFormattedValue(condition.numericRangeLess)
            : intl.formatMessage(messages.rule_amount_condition_below_not_set);
        let gv = numberHelpers.isNumber(condition.numericRangeGreaterEquals)
            ? this._getFormattedValue(condition.numericRangeGreaterEquals)
            : intl.formatMessage(messages.rule_amount_condition_above_not_set);

        const textMap = {
            [domain.NumericRangeConditionType.Above]: intl.formatMessage(messages.rule_amount_condition_above_preview, {
                lv,
                gv,
            }),
            [domain.NumericRangeConditionType.Any]: intl.formatMessage(messages.rule_amount_condition_any_preview, {
                lv,
                gv,
            }),
            [domain.NumericRangeConditionType.Below]: intl.formatMessage(messages.rule_amount_condition_below_preview, {
                lv,
                gv,
            }),
            [domain.NumericRangeConditionType.Within]: intl.formatMessage(
                messages.rule_amount_condition_within_preview,
                {
                    lv,
                    gv,
                }
            ),
        };

        return textMap[condition.numericRangeConditionType];
    }

    private _beginEdit = () => {
        this.setState({
            inEdit: true,
        });
    };

    private _focusAppropriateEditor = (rangeType: domain.NumericRangeConditionType) => {
        if (rangeType === domain.NumericRangeConditionType.Any) {
            this._dropdownComponent?.focus();
        } else if (rangeType === domain.NumericRangeConditionType.Within && numberHelpers.isNumber(this._getValue1())) {
            this._value2Component?.focus();
        } else {
            this._value1Component?.focus();
        }
    };

    private _preventBlur = (e: React.SyntheticEvent<any>) => {
        e.preventDefault();
    };

    private _preventBlurExceptRefocus = (e: React.MouseEvent<HTMLDivElement>) => {
        const tgt = e.currentTarget;

        if (!this._el!.contains(tgt)) {
            return;
        }

        if (tgt.tabIndex < 0) {
            e.preventDefault();
        }
    };

    /**
     * Determines whether or not to close the cell editor. It depends on new active element in document.
     * Delay is required to make sure that the new element is focused.
     * Safari can't focus the button element during UI interaction (only input with text can be focused there), but
     * it can be done pragmatically via handling onClick event and calling el.focus().
     * It requires some time, looks like 150ms is fine
     */
    private _onEditorBlur = () => {
        const delay = browserHelpers.isSafari() ? 150 : 1;

        setTimeout(() => {
            if (this._el && !this._el.contains(document.activeElement)) {
                this._endEdit();
            }
        }, delay);
    };

    private _endEdit = () => {
        this.setState({
            inEdit: false,
        });
    };

    private _changeRangeType = (rangeType: domain.NumericRangeConditionType) => {
        if (rangeType === this.props.condition.numericRangeConditionType) {
            this._closeRangeTypeDropdown();

            return;
        }

        let newCondition = {
            ...this.props.condition,
            numericRangeConditionType: rangeType,
        };

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);

        this._closeRangeTypeDropdown();

        if (rangeType === domain.NumericRangeConditionType.Any) {
            this._endEdit();
        } else {
            setTimeout(() => {
                const isMounted = Boolean(this._dropdownComponent);

                if (!isMounted) {
                    return;
                }

                this._focusAppropriateEditor(rangeType);
            }, 100);
        }
    };

    private _changeValue1 = (newValue: number | null) => {
        if (newValue !== null) {
            newValue = numberHelpers.round(newValue, 2);
        }

        let newCondition = { ...this.props.condition };

        if (this.props.condition.numericRangeConditionType === domain.NumericRangeConditionType.Below) {
            newCondition.numericRangeLess = newValue;
            newCondition.numericRangeGreaterEquals = null;
        } else {
            newCondition.numericRangeGreaterEquals = newValue;
        }

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, newCondition);
    };

    private _changeValue2 = (newValue: number | null) => {
        if (newValue !== null) {
            newValue = numberHelpers.round(newValue, 2);
        }

        this.props.onConditionChange(this.props.lineId, this.props.rule, this.props.field, {
            ...this.props.condition,
            numericRangeLess: newValue,
        });
    };

    private _onOpenRangeTypeButtonClick = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e?.currentTarget?.focus();
        this._openRangeTypeDropdown();
    };

    private _openRangeTypeDropdown = () => {
        this.setState({
            isRangeTypeDropdownOpen: true,
        });
    };

    private _closeRangeTypeDropdown = () => {
        this.setState({
            isRangeTypeDropdownOpen: false,
        });
    };

    private _getValue1(): number | null {
        const condition = this.props.condition;
        const rangeType = condition.numericRangeConditionType;

        if (rangeType === domain.NumericRangeConditionType.Any) {
            return null;
        }

        if (rangeType === domain.NumericRangeConditionType.Below) {
            return condition.numericRangeLess;
        }

        return condition.numericRangeGreaterEquals;
    }

    private _getValue2(): number | null {
        const condition = this.props.condition;
        const rangeType = condition.numericRangeConditionType;

        return rangeType === domain.NumericRangeConditionType.Within ? condition.numericRangeLess : null;
    }

    private _hasEditor1() {
        const rangeType = this.props.condition.numericRangeConditionType;

        return rangeType !== domain.NumericRangeConditionType.Any;
    }

    private _hasEditor2() {
        const rangeType = this.props.condition.numericRangeConditionType;

        return rangeType === domain.NumericRangeConditionType.Within;
    }
}

export default AmountConditionCell;
