import { AlertCircleFilledIcon } from '@approvalmax/ui';
import { Button, Flex, Text, Tooltip } from '@approvalmax/ui/src/components';
import { FC, useState } from 'react';

import { messages } from './ErrorPlaceholder.messages';
import { Root } from './ErrorPlaceholder.styles';
import { ErrorPlaceholderProps } from './ErrorPlaceholder.types';

export const ErrorPlaceholder: FC<ErrorPlaceholderProps> = (props) => {
    const { onContactSupport } = props;

    const [root, setRoot] = useState<HTMLDivElement | null>(null);

    return (
        <Root
            ref={(node) => {
                if (node) {
                    setRoot(node);
                }
            }}
        >
            {/* We need to wait before root-node will be cached within `root`
             to make portal append to it. This is required to prevent loosing
             hover effect on moving mouse over tooltip (because otherwise, body
             will be the parent of the portal */}
            {root && (
                <Tooltip
                    safePolygon
                    activator={
                        <AlertCircleFilledIcon
                            // used as css-selector
                            id='alert-icon'
                            color='midnight70'
                        />
                    }
                    floatingPortalProps={{ root }}
                >
                    <Flex inline direction='column' spacing='2' alignItems='start'>
                        <Text font='label' fontSize='xxsmall' fontWeight='medium'>
                            {messages.weCouldntLoad}
                        </Text>

                        <Text font='label' fontSize='xxsmall'>
                            {messages.pleaseReloadThisPage}
                        </Text>

                        <Button link noPadding size='xsmall' onClick={onContactSupport}>
                            {messages.contactSupport}
                        </Button>
                    </Flex>
                </Tooltip>
            )}
        </Root>
    );
};

ErrorPlaceholder.displayName = 'ErrorPlaceholder';
