import { Reference } from '@approvalmax/types';
import { backend, domain, du } from 'modules/data';

import { parseXeroAccount } from './XeroAccount';
import { parseXeroAddress, XeroAddress } from './XeroAddress';
import { parseXeroPaymentTerm } from './XeroPaymentTerm';
import { parseXeroTaxCode } from './XeroTaxCode';

export function parseXeroContact(
    value: backend.IntegrationsXeroCounterparty & {
        PurchaseLineAmountType?: domain.LineAmountType;
        SalesLineAmountType?: domain.LineAmountType;
    }
): XeroContact {
    return {
        id: value.Id,
        text: value.Name,
        addresses: value.Addresses ? value.Addresses.map(parseXeroAddress).filter(({ text }) => text) : [],
        contactPersons: value.ContactPersons
            ? value.ContactPersons.map((x) => ({
                  firstName: x.FirstName || '',
                  lastName: x.LastName || '',
                  emailAddress: x.EmailAddress || '',
                  includeInEmails: Boolean(x.IncludeInEmails),
              }))
            : [],
        currency: value.DefaultCurrency,
        brandingTheme: du.parseServerReferenceMaybe(value.BrandingTheme) || undefined,
        purchase: {
            account: value.PurchaseAccount ? parseXeroAccount(value.PurchaseAccount) : undefined,
            taxCode: value.PurchaseTaxCode ? parseXeroTaxCode(value.PurchaseTaxCode) : value.PurchaseTaxCode,
            trackingCategories: value.PurchaseTrackingCategories
                ? value.PurchaseTrackingCategories.map((x) => ({
                      category: du.parseServerReference(x),
                      value: du.parseServerReferenceMaybe(x.Options[0]),
                  }))
                : [],
            lineAmountType: value.PurchaseLineAmountType || undefined,
        },
        sales: {
            account: value.PurchaseAccount ? parseXeroAccount(value.PurchaseAccount) : undefined,
            taxCode: value.PurchaseTaxCode ? parseXeroTaxCode(value.PurchaseTaxCode) : value.PurchaseTaxCode,
            trackingCategories: value.PurchaseTrackingCategories
                ? value.PurchaseTrackingCategories.map((x) => ({
                      category: du.parseServerReference(x),
                      value: du.parseServerReferenceMaybe(x.Options[0]),
                  }))
                : [],
            lineAmountType: value.SalesLineAmountType || undefined,
        },
        paymentTerm: {
            bill: value.PaymentTerms?.Bills ? parseXeroPaymentTerm(value.PaymentTerms.Bills) : undefined,
            sales: value.PaymentTerms?.Sales ? parseXeroPaymentTerm(value.PaymentTerms.Sales) : undefined,
        },
        accountNumber: value.AccountNumber,
        taxNumber: value.TaxNumber,
        emailAddress: value.EmailAddress,
    };
}

export interface XeroContactPerson {
    firstName: string;
    lastName: string;
    emailAddress: string;
    includeInEmails: boolean;
}

export interface XeroContact extends Reference {
    accountNumber?: string;
    addresses?: XeroAddress[];
    brandingTheme?: Reference;
    contactPersons?: XeroContactPerson[];
    currency?: string;
    emailAddress?: string;
    paymentTerm?: {
        bill?: domain.XeroContactPaymentTermDetails;
        sales?: domain.XeroContactPaymentTermDetails;
    };
    purchase?: {
        account?: domain.XeroAccount;
        taxCode?: domain.XeroTaxCode;
        lineAmountType?: domain.LineAmountType;
        trackingCategories: Array<{
            category: Reference;
            value: Reference | null;
        }>;
    };
    sales?: {
        account?: domain.XeroAccount;
        taxCode?: domain.XeroTaxCode;
        lineAmountType?: domain.LineAmountType;
        trackingCategories: Array<{
            category: Reference;
            value: Reference | null;
        }>;
    };
    taxNumber?: string;
}
