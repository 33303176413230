import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.PaymentDetailsBankAccountInfo', {
    bankAccountName: 'Account name:',
    bankAccountCountry: 'Country:',
    bankAccountCurrency: 'Currency:',
    bankAccountSortCode: 'Sort code:',
    bankAccountNumber: 'Account number:',
    previous: 'Previous',
    actual: 'Actual',
});
