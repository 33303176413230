import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useTwoFaDisablingRequestEmailCode.map';
import {
    UseTwoFaDisablingRequestEmailCodeRequest,
    UseTwoFaDisablingRequestEmailCodeResponse,
    UseTwoFaDisablingRequestEmailCodeResponseBackend,
} from './useTwoFaDisablingRequestEmailCode.types';

export const useTwoFaDisablingRequestEmailCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseTwoFaDisablingRequestEmailCodeRequest>,
        UseTwoFaDisablingRequestEmailCodeResponseBackend,
        UseTwoFaDisablingRequestEmailCodeResponse
    >(twoFaApiPaths.disablingRequestEmailCode, { mapData });
};
