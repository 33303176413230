import { domain } from 'modules/data';
import { useCallback, useState } from 'react';
import { useMount } from 'react-use';

export const useDropdownValue = (defaultValue: string, defaultType: domain.DeadlineRuleType | 'none') => {
    const [isError, setIsError] = useState(false);

    const [type, setType] = useState(defaultType);
    const [values, setValues] = useState<Record<domain.DeadlineRuleType, string>>({
        [domain.DeadlineRuleType.onSubmission]: '',
        [domain.DeadlineRuleType.onApproval]: '',
    });

    const onValueChange = useCallback(
        (value: string) => {
            if (type) {
                setIsError(false);

                setValues((prevValues) => ({ ...prevValues, [type]: value }));
            }
        },
        [type]
    );

    const onTypeChange = useCallback((value: typeof defaultType) => {
        setIsError(false);

        setType(value);
    }, []);

    useMount(() => {
        setType(defaultType);

        onValueChange(defaultValue);
    });

    return {
        type,
        value: type === 'none' ? '' : values[type],
        onValueChange,
        onTypeChange,
        isError,
        setIsError,
    };
};
