import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroBillStartStep', {
    bubbleText: 'Creation / Pulling of {pluralTemplateName}',
    title: `
        There are several methods to create or pull {pluralTemplateName} in ApprovalMax.
        Please see the options below and choose as applicable.`,
    createInApprovalMax: 'Create in ApprovalMax',
    description: 'Add users authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    descriptionEmpty:
        'Add at least one user authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    externalSubmitterTitle:
        '{pluralTemplateName} in the "Awaiting approval" status will be automatically pulled from Xero.',
    externalSubmitterDescriptionNonEmpty: `
        {pluralTemplateName} will be created in ApprovalMax under the name of this User.
        They will be notified about approvals, rejections, and comments.`,
    externalSubmitterDescriptionEmpty: `
        Enter the user who will be notified about {templateName} status changes in ApprovalMax.\n
        A generic email address, e.g. finance@mycompany.com, can also be used.`,
    emailExternalSubmitterTitle:
        'ApprovalMax will create a new bill for each email attachment sent to the email address below.',
    emailExternaSubmitterDescriptionNonEmpty: `
        {pluralTemplateName} will be created in ApprovalMax under the name of this User.
        They will be notified about approvals, rejections, and comments.`,
    emailExternalSubmitterDescriptionEmpty: `
        Enter the user who will be notified about {templateName} status changes in ApprovalMax.\n
        A generic email address, e.g. finance@mycompany.com, can also be used.`,
});
