import { BackupCodes, Button, Popup } from '@approvalmax/ui';
import { domHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { notificationService } from 'services/notification';
import { useGenerateTFABackupCodes, useGetTFAAlternativeMethods } from 'shared/data';

import { messages } from './UpdateBackupCodesPopup.messages';
import { Content, Description, Root } from './UpdateBackupCodesPopup.styles';
import { UpdateBackupCodesPopupProps } from './UpdateBackupCodesPopup.types';

const UpdateBackupCodesPopup: FC<UpdateBackupCodesPopupProps> = memo((props) => {
    const { onClose } = props;

    const [backupCodes, setBackupCodes] = useState<domain.BackupCode[]>([]);
    const { data: alternativeMethods, isLoading: isLoadingAlternativeMethods } = useGetTFAAlternativeMethods();
    const { mutate: generate, isLoading: isLoadingGenerate } = useGenerateTFABackupCodes();
    const isLoading = isLoadingAlternativeMethods || isLoadingGenerate;
    const unusedBackupCodes = useMemo(() => backupCodes.filter(({ isUsed }) => !isUsed), [backupCodes]);

    useEffect(() => {
        if (alternativeMethods?.backupCodes) {
            setBackupCodes(alternativeMethods?.backupCodes);
        }
    }, [alternativeMethods?.backupCodes]);

    const onGenerate = useCallback(() => {
        generate(
            { data: {} },
            {
                onSuccess: (response) => {
                    setBackupCodes(
                        response.backupCodes.map((code) => ({
                            code,
                            isUsed: false,
                        }))
                    );
                    notificationService.showInfoToast(messages.onSuccess);
                },
            }
        );
    }, [generate]);

    const onDownload = useCallback(() => {
        const content = unusedBackupCodes.map(({ code }) => code).join('\n');

        domHelpers.downloadBlob([content], {
            fileName: 'Backup_codes',
            type: 'text/plain',
            extension: 'txt',
        });
    }, [unusedBackupCodes]);

    return (
        <Popup isOpen onRequestClose={onClose}>
            <Root title={messages.popupTitle}>
                <Content>
                    <BackupCodes
                        isLoading={isLoading}
                        backupCodes={backupCodes}
                        showInfoToast={notificationService.showInfoToast}
                    />

                    <Description>{messages.description}</Description>

                    <Button execute={onGenerate} disabled={isLoading}>
                        {messages.generateNewCodes}
                    </Button>
                </Content>
            </Root>
        </Popup>
    );
});

export default UpdateBackupCodesPopup;
