import { ApiError } from '@approvalmax/data';
import { createAction, createErrorAction, ExtractActions } from 'modules/react-redux';

import { cacheStoragePrefixAmaxPayPaymentDetails } from '../constants';
import { DataItem } from '../typings/DataItem';

export const LOAD_ITEMS = 'DATA_PROVIDERS/LOAD_ITEMS';
export const loadItems = ({
    onLoad,
    filterText,
    excludedIds,
    pageSize,
    cacheStorageId,
}: {
    onLoad: (filterText: string | null, startFrom: number, count: number) => Promise<DataItem[]>;
    filterText: string | null;
    excludedIds: string[];
    pageSize: number;
    cacheStorageId: string;
}) =>
    createAction(LOAD_ITEMS, {
        onLoad,
        filterText: filterText || '',
        excludedIds,
        pageSize,
        cacheStorageId,
    });

export const CLEAR_CACHE_STORAGE = 'DATA_PROVIDERS/CLEAR_CACHE_STORAGE';
export const clearCacheStorage = (storageIdPredicate: (storageId: string | number) => boolean) =>
    createAction(CLEAR_CACHE_STORAGE, {
        storageIdPredicate,
    });

export const clearCacheStorageAmaxPayPaymentDetails = () =>
    createAction(CLEAR_CACHE_STORAGE, {
        storageIdPredicate: (storageId: string | number) =>
            String(storageId).startsWith(cacheStoragePrefixAmaxPayPaymentDetails),
    });

export const FETCH_DATA_REQUEST = 'DATA_PROVIDERS/FETCH_DATA_REQUEST';
export const fetchDataRequest = (options: { cacheStorageId: string; filterText: string }) =>
    createAction(FETCH_DATA_REQUEST, options);

export const FETCH_DATA_RESPONSE = 'DATA_PROVIDERS/FETCH_DATA_RESPONSE';
export const fetchDataResponse = (options: {
    cacheStorageId: string;
    filterText: string;
    items: DataItem[];
    hasMore: boolean;
}) => createAction(FETCH_DATA_RESPONSE, options);

export const FETCH_DATA_FAILURE = 'DATA_PROVIDERS/FETCH_DATA_FAILURE';
export const fetchDataFailure = (options: { cacheStorageId: string; filterText: string; error: ApiError }) =>
    createErrorAction(FETCH_DATA_FAILURE, options.error, options);

export type Action = ExtractActions<
    | typeof clearCacheStorage
    | typeof clearCacheStorageAmaxPayPaymentDetails
    | typeof fetchDataFailure
    | typeof fetchDataRequest
    | typeof fetchDataResponse
    | typeof loadItems
>;
