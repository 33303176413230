import { Reference } from '@approvalmax/types';
import { domain } from 'modules/data';

export enum AirwallexFee {
    Payer = 'Payer',
    Shared = 'Shared',
}

export enum AirwallexPaymentMethod {
    Swift = 'swift',
    Local = 'local',
}

export enum AirwallexPaymentStatus {
    None = 'None',
    AwaitingPayment = 'Awaiting Payment',
    Processing = 'Processing',
    Paid = 'Paid',
    PartiallyPaid = 'Partially Paid',
    Failed = 'Failed',
}

export interface AirwallexPaymentItem {
    xeroBillInvoiceRequestId: string;
    amount: number;
    amountDue: number;
    date: string;
    dueDate: string;
    friendlyName: string;
    currency: string;
    amountOnApproval: number;
    amountInProcessing: number;
    amountAwaitingPayment: number;
    attachments?: domain.RequestAttachment[];
    beneficiary: AirwallexBeneficiary | null;
    paymentReference: string | null;
    paymentPurpose: Reference | null;
    sourceCurrency: Reference | null;
    bankAccount: Reference | null;
    contact: Reference | null;
    paymentFee: Reference | null;
    sourceAmount?: number;
    feeAmount?: number;
    status?: domain.AirwallexInvoiceStatus;
    errorMessage?: string;
    statusUpdatedAt?: string;
    xeroUrl?: string;
    airwallexUrl?: string;
    beneficiaryPaymentStatus: BeneficiaryPaymentStatus | null;
    beneficiaryDiscrepancy: BeneficiaryLastPaymentDiscrepancy | null;
}

export interface AirwallexPaymentProcessingDetails {
    totalPayments: number;
    paymentsSent: number;
    paymentsFailed: number;
    paymentsInProcessing: number;
}

export interface XeroAirwallexBatchPayment {
    items: AirwallexPaymentItem[];
    paymentStatus: AirwallexPaymentStatus | null;
    paymentProcessingDetails?: AirwallexPaymentProcessingDetails;
}

export interface AirwallexBeneficiaryBankDetails {
    accountName: string;
    bankCountryCode: string;
    bankName: string;
    iban: string | null;
    swiftCode: string | null;
    accountNumber: string;
    bankBranch: string | null;
    localClearingSystem: string | null;
    accountingRoutingType1: string | null;
    accountingRoutingType2: string | null;
    accountingRoutingValue1: string | null;
    accountingRoutingValue2: string | null;
}
export interface AirwallexBeneficiary extends Reference {
    accountCurrency: string;
    bankDetails: AirwallexBeneficiaryBankDetails;
    version: number;
    paymentMethod: Reference | null;
    paymentFee: Reference | null;
}

export type BeneficiaryPaymentStatus = 'paid-previously' | 'never-paid' | 'payment-details-changed';

export type BeneficiaryLastPaymentDiscrepancy = {
    airwallexBeneficiaryId: string;
    beneficiaryName: string;
    isBeneficiaryDeleted: boolean;
    lastPaymentProperties: {
        name: string;
        value: string;
    }[];
};

export enum AirwallexInvoiceStatus {
    Sent = 'Sent',
    Processing = 'Processing',
    Failed = 'Failed',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
}
