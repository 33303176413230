import { memo, useCallback } from 'react';

import { Popup } from '../../../components';
import { AllFeatureTrialPaywallPopupProps } from './AllFeatureTrialPaywallPopup.types';
import { Content } from './components';

export const AllFeatureTrialPaywallPopup = memo<AllFeatureTrialPaywallPopupProps>((props) => {
    const {
        isOpen,
        onClose,
        companies,
        initialCompanyId,
        initialFeature,
        loading,
        onUpgradeSubcription,
        onStartTrial,
    } = props;

    const handleToggle = useCallback(
        (isOpen: boolean) => {
            if (!isOpen) {
                onClose();
            }
        },
        [onClose]
    );

    return (
        <Popup open={isOpen} onToggle={handleToggle} size='large' preventAutoClose>
            <Content
                companies={companies}
                initialCompanyId={initialCompanyId}
                initialFeature={initialFeature}
                onUpgradeSubcription={onUpgradeSubcription}
                onStartTrial={onStartTrial}
                loading={loading}
            />
        </Popup>
    );
});

AllFeatureTrialPaywallPopup.displayName = 'AllFeatureTrialPaywallPopup';
