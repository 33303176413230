import { FC, memo, useCallback, useState } from 'react';

import { Button, Flex, Label, Popup, Select, Spacing, Text } from '../../../../../components';
import { TrialCompany } from '../../AllFeatureTrialPaywallPopup.types';
import { CompanyType, FeatureDescription, FeaturesList } from '../';
import { getInitialFeature } from './Content.helpers';
import { messages } from './Content.messages';
import { ContentProps } from './Content.types';

export const Content: FC<ContentProps> = memo((props) => {
    const { companies, initialCompanyId, initialFeature, loading, onUpgradeSubcription, onStartTrial } = props;

    const [selectedCompany, setSelectedCompany] = useState<TrialCompany | undefined>(() =>
        initialCompanyId ? companies.find((company) => company.id === initialCompanyId) ?? companies[0] : companies[0]
    );
    const [activeFeature, setActiveFeature] = useState(initialFeature || getInitialFeature(selectedCompany));

    const handleChangeCompany = (value: string) => {
        const nextCompany = companies.find((company) => company.id === value);

        setSelectedCompany(nextCompany);

        if (nextCompany) {
            setActiveFeature(getInitialFeature(nextCompany));
        }
    };

    const handleUpgradeSubcription = useCallback(() => {
        if (!selectedCompany) {
            return;
        }

        onUpgradeSubcription(selectedCompany.id);
    }, [onUpgradeSubcription, selectedCompany]);

    const handleStartTrial = useCallback(() => {
        if (!selectedCompany) {
            return;
        }

        onStartTrial(selectedCompany.id);
    }, [onStartTrial, selectedCompany]);

    return (
        <>
            <Popup.Header title={messages.title} progress={loading} />

            <Popup.Body spacing='32'>
                <Flex inline direction='row' wrap={false} spacing='24'>
                    <Flex grow={0} shrink={0}>
                        {selectedCompany && activeFeature && (
                            <FeatureDescription company={selectedCompany} activeFeature={activeFeature} />
                        )}
                    </Flex>

                    <div>
                        <Label size='medium' required spacing='0 0 8'>
                            {messages.chooseOrganisation}
                        </Label>

                        <Select
                            items={companies}
                            value={selectedCompany?.id}
                            onChange={handleChangeCompany}
                            clearable={false}
                            disabled={loading}
                        />

                        <Spacing height={8} />

                        {selectedCompany && <CompanyType integrationType={selectedCompany.integrationType} />}

                        <Spacing height={24} />

                        <FeaturesList
                            company={selectedCompany}
                            activeFeature={activeFeature}
                            setActiveFeature={setActiveFeature}
                        />

                        <Spacing height={24} />

                        <Text font='body' fontSize='medium' fontWeight='medium' color='midnight80' spacing='0 0 16'>
                            {messages.moreThan}
                        </Text>

                        {selectedCompany?.isUpgradeSubcriptionVisible && (
                            <>
                                <Button
                                    color='midnight40'
                                    outline
                                    onClick={handleUpgradeSubcription}
                                    block
                                    disabled={loading}
                                >
                                    {messages.upgrade}
                                </Button>

                                <Spacing height={16} />
                            </>
                        )}

                        <Button color='mint80' block onClick={handleStartTrial} disabled={loading}>
                            {messages.tryFree}
                        </Button>

                        <Spacing height={16} />

                        <Text font='label' fontSize='small' color='midnight70' textAlign='center'>
                            {messages.cancelAnytime}
                        </Text>
                    </div>
                </Flex>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
