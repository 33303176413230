import './conditionHeaderColumn.scss';

import { ActionMenu, Dropdown, TextEditor, TransparentButton } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { State } from 'modules/data';
import { EllipsisIcon } from 'modules/sprites';
import { FC, memo, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { removeFieldFromActiveTemplate, renameField, updateFieldAccessTypeInActiveMatrix } from '../../../../actions';
import { getPureActiveMatrix } from '../../../../selectors/pageSelectors';
import { AccessType, MatrixType } from '../../../../types/matrix';

const i18nPrefix = 'workflows.components.card.headers.ConditionHeaderColumn';

const messages = defineMessages({
    rule_manager_mandatory_setting_always_mandatory: {
        id: `${i18nPrefix}.rule_manager_mandatory_setting_always_mandatory`,
        defaultMessage: 'Always mandatory',
    },
    rule_manager_mandatory_setting_always_optional: {
        id: `${i18nPrefix}.rule_manager_mandatory_setting_always_optional`,
        defaultMessage: 'Always optional',
    },
    rule_manager_mandatory_setting_readonly: {
        id: `${i18nPrefix}.rule_manager_mandatory_setting_readonly`,
        defaultMessage: 'Readonly',
    },
    rule_manager_mandatory_setting_mandatory_button: {
        id: `${i18nPrefix}.rule_manager_mandatory_setting_mandatory_button`,
        defaultMessage: 'Set as mandatory',
    },
    rule_manager_mandatory_setting_optional_button: {
        id: `${i18nPrefix}.rule_manager_mandatory_setting_optional_button`,
        defaultMessage: 'Set as optional',
    },
    rule_manager_mandatory_setting_readonly_button: {
        id: `${i18nPrefix}.rule_manager_mandatory_setting_readonly_button`,
        defaultMessage: 'Set as readonly',
    },
});

const bem = bemFactory.block('wfc-condition-header-col');
const qa = bemFactory.qa('wfc-condition-header-col');

interface ConditionHeaderColumnProps {
    fieldId: string;
    fieldName: string;
    readonly?: boolean;
    canRename?: boolean;
    canRemove?: boolean;
    hasAccessTypeBlock?: boolean;
    defaultAccessType?: AccessType;
    accessTypeOptions?: AccessType[];
    accessTypeNote?: string;
    matrixType: MatrixType;
}

const ConditionHeaderColumn: FC<ConditionHeaderColumnProps> = (props) => {
    const {
        fieldId,
        fieldName,
        readonly,
        canRemove,
        canRename,
        hasAccessTypeBlock,
        accessTypeNote,
        defaultAccessType,
        accessTypeOptions,
        matrixType,
    } = props;

    const [title, setTitle] = useState('');
    const [inTitleEdit, setInTitleEdit] = useState(false);
    const [isMandatoryDropdownOpen, setIsMandatoryDropdownOpen] = useState(false);

    const dispatch = useDispatch();
    const accessType = useSelector((state: State) => {
        if (!accessTypeOptions) {
            return AccessType.Mandatory;
        }

        const matrix = getPureActiveMatrix(state)!;

        let result;

        if (matrix.requiredFieldIds.includes(fieldId)) {
            result = AccessType.Mandatory;
        } else if (matrix.readonlyFieldIds.includes(fieldId)) {
            result = AccessType.Readonly;
        } else {
            if (!defaultAccessType || !accessTypeOptions.includes(defaultAccessType)) {
                console.error(
                    `'accessTypeOptions' (${accessTypeOptions.join(
                        ', '
                    )}) does not include the default access type ${defaultAccessType}. [fieldName: ${fieldName}]`
                );
                result = accessTypeOptions[0];
            } else {
                result = defaultAccessType;
            }
        }

        return result;
    });

    const onRemove = useCallback(() => {
        dispatch(removeFieldFromActiveTemplate(fieldId));
    }, [dispatch, fieldId]);

    const onBeginEdit = useCallback(() => {
        setInTitleEdit(true);
        setTitle(fieldName || '');
    }, [fieldName]);

    const onTitleBlur = () => {
        setInTitleEdit(false);

        if (title) {
            dispatch(renameField(fieldId, title));
        }
    };

    const openMandatoryDropdown = useCallback(() => {
        setIsMandatoryDropdownOpen(true);
    }, []);

    const closeMandatoryDropdown = useCallback(() => {
        setIsMandatoryDropdownOpen(false);
    }, []);

    const setAsMandatory = () => {
        setIsMandatoryDropdownOpen(false);
        dispatch(updateFieldAccessTypeInActiveMatrix(fieldId, AccessType.Mandatory, matrixType));
    };

    const setAsOptional = () => {
        setIsMandatoryDropdownOpen(false);
        dispatch(updateFieldAccessTypeInActiveMatrix(fieldId, AccessType.Optional, matrixType));
    };

    const setAsReadonly = () => {
        setIsMandatoryDropdownOpen(false);
        dispatch(updateFieldAccessTypeInActiveMatrix(fieldId, AccessType.Readonly, matrixType));
    };

    return (
        <div className={bem()} data-qa={qa()} data-qa-id={fieldId} data-qa-name={fieldName}>
            <div className={bem('header')}>
                <div className={bem('title-wrp', { active: inTitleEdit })}>
                    {!inTitleEdit ? (
                        <div
                            className={bem('title', { readonly: !canRename || readonly })}
                            data-qa={qa('title')}
                            onClick={canRename && !readonly ? onBeginEdit : undefined}
                        >
                            {fieldName}
                        </div>
                    ) : (
                        <TextEditor
                            value={title}
                            onChange={setTitle}
                            qa={'title-input'}
                            theme='transparent'
                            className={bem('title-input')}
                            onEnter={onTitleBlur}
                            onBlur={onTitleBlur}
                            focusOnMount
                        />
                    )}
                </div>

                {hasAccessTypeBlock &&
                    (!accessTypeOptions || accessTypeOptions.length <= 1 ? (
                        <div className={bem('mandatory-title')}>
                            {accessType === AccessType.Mandatory
                                ? intl.formatMessage(messages.rule_manager_mandatory_setting_always_mandatory)
                                : accessType === AccessType.Optional
                                  ? intl.formatMessage(messages.rule_manager_mandatory_setting_always_optional)
                                  : intl.formatMessage(messages.rule_manager_mandatory_setting_readonly)}
                        </div>
                    ) : (
                        <Dropdown
                            onRequestClose={closeMandatoryDropdown}
                            panelFlow='to-right'
                            isOpen={isMandatoryDropdownOpen}
                            button={
                                <TransparentButton
                                    qa={qa('mandatory-menu')}
                                    className={bem('mandatory-button', { disabled: readonly })}
                                    disabled={readonly}
                                    execute={openMandatoryDropdown}
                                >
                                    {accessType === AccessType.Mandatory
                                        ? intl.formatMessage(messages.rule_manager_mandatory_setting_mandatory_button)
                                        : accessType === AccessType.Optional
                                          ? intl.formatMessage(messages.rule_manager_mandatory_setting_optional_button)
                                          : intl.formatMessage(messages.rule_manager_mandatory_setting_readonly_button)}
                                </TransparentButton>
                            }
                        >
                            <div className={bem('mandatory-panel')}>
                                {accessTypeOptions.includes(AccessType.Mandatory) && (
                                    <div
                                        className={bem('mandatory-panel-i')}
                                        data-qa={qa('mandatory-panel-i-mandatory')}
                                        onClick={setAsMandatory}
                                    >
                                        <div
                                            className={bem('mandatory-panel-i-checkbox', {
                                                active: accessType === AccessType.Mandatory,
                                            })}
                                        />

                                        <FormattedMessage
                                            id={`${i18nPrefix}.rule_editor_mandatory_field`}
                                            defaultMessage='Field is mandatory'
                                        />
                                    </div>
                                )}

                                {accessTypeOptions.includes(AccessType.Optional) && (
                                    <div
                                        className={bem('mandatory-panel-i')}
                                        data-qa={qa('mandatory-panel-i-optional')}
                                        onClick={setAsOptional}
                                    >
                                        <div
                                            className={bem('mandatory-panel-i-checkbox', {
                                                active: accessType === AccessType.Optional,
                                            })}
                                        />

                                        <FormattedMessage
                                            id={`${i18nPrefix}.rule_editor_optional_field`}
                                            defaultMessage='Field is optional'
                                        />
                                    </div>
                                )}

                                {accessTypeOptions.includes(AccessType.Readonly) && (
                                    <div
                                        className={bem('mandatory-panel-i')}
                                        data-qa={qa('mandatory-panel-i-readonly')}
                                        onClick={setAsReadonly}
                                    >
                                        <div
                                            className={bem('mandatory-panel-i-checkbox', {
                                                active: accessType === AccessType.Readonly,
                                            })}
                                        />

                                        <FormattedMessage
                                            id={`${i18nPrefix}.rule_editor_readonly_field`}
                                            defaultMessage='Field is readonly'
                                        />
                                    </div>
                                )}

                                <div className={bem('mandatory-panel-note')}>{accessTypeNote}</div>
                            </div>
                        </Dropdown>
                    ))}
            </div>

            {!readonly && (canRemove || canRename) && (
                <div className={bem('side-actions')}>
                    <ActionMenu
                        panelFlow='center'
                        button={
                            <TransparentButton className={bem('action-icon')} qa={qa('action-menu')}>
                                <EllipsisIcon width={18} height={4} />
                            </TransparentButton>
                        }
                    >
                        {canRename && (
                            <ActionMenu.Item execute={onBeginEdit} qa={qa('rename')}>
                                <FormattedMessage
                                    id={`${i18nPrefix}.step_rules_field_menu_rename`}
                                    defaultMessage='Rename'
                                />
                            </ActionMenu.Item>
                        )}

                        {canRemove && (
                            <ActionMenu.Item execute={onRemove} qa={qa('remove')}>
                                <FormattedMessage
                                    id={`${i18nPrefix}.step_rules_field_menu_remove`}
                                    defaultMessage='Remove'
                                />
                            </ActionMenu.Item>
                        )}
                    </ActionMenu>
                </div>
            )}
        </div>
    );
};

export default memo(ConditionHeaderColumn);
