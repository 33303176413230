import './ContactSupportPopup.scss';

import { Field, Popup, TextAreaEditor, TextEditor, TransparentButton, usePopupContext } from '@approvalmax/ui';
import { useExtensions } from '@approvalmax/ui/src/components';
import { hooks, intl } from '@approvalmax/utils';
import { removeArrayItem } from 'modules/immutable';
import { BubbleCloseIcon, DragNDropIcon, PaperClipIcon } from 'modules/sprites';
import { FC, memo, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FileRejection, useDropzone } from 'react-dropzone';
import { defineMessages, FormattedMessage } from 'react-intl';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { notificationService } from 'services/notification';
import styled from 'styled-components';

const i18nPrefix = 'page/containers/navBarAvatarMenu/ContactSupportPopup';
const messages = defineMessages({
    popupTitle: {
        id: `${i18nPrefix}.popupTitle`,
        defaultMessage: 'Contact support',
    },
    doneButtonTitle: {
        id: `${i18nPrefix}.doneButtonTitle`,
        defaultMessage: 'Send the support request.',
    },
    fileTooBigError: {
        id: `${i18nPrefix}.fileTooBigError`,
        defaultMessage: 'Sorry, the file "{fileName}" exceeds the maximum size of {maxSize}.',
    },
    fileExtensionNotSupportedError: {
        id: `${i18nPrefix}.fileExtensionNotSupportedError`,
        defaultMessage: 'Sorry, the submitted file type is not supported.',
    },
    maxNumberOfAttachmentsExceeded: {
        id: `${i18nPrefix}.maxNumberOfAttachmentsExceeded`,
        defaultMessage: 'Sorry, you cannot attach more that 10 files to a comment.',
    },
    validationErrorRequired: {
        id: `${i18nPrefix}.validationErrorRequired`,
        defaultMessage: 'Please fill in the required fields',
    },
    postErrorResult: {
        id: `${i18nPrefix}.postErrorResult`,
        defaultMessage: 'Some error occured',
    },
    postSuccessResult: {
        id: `${i18nPrefix}.postSuccessResult`,
        defaultMessage: 'Support request has been successfully submitted',
    },
    subjectPlaceholder: {
        id: `${i18nPrefix}.subjectPlaceholder`,
        defaultMessage: 'Enter subject...',
    },
    descriptionPlaceholder: {
        id: `${i18nPrefix}.descriptionPlaceholder`,
        defaultMessage: 'Please provide a step-by-step description of your issue',
    },
});

const MAX_FILE_SIZE = 10000000;
const MAX_FILE_SIZE_TEXT = '10MB';
const MAX_ATTACHMENT_COUNT = 10;

const bem = bemFactory.block('page-contact-support-popup');
const qa = bemFactory.qa('page-contact-support-popup');

const CloseIconBox = styled.div`
    display: flex;
    align-items: center;
`;

interface ContactSupportPopupProps {
    className?: string;
    transformMessage?: (message: string) => string;
    onSend?: () => void;
}

const defaultTransformMessage = (message: string) => message;

const ContactSupportPopup: FC<ContactSupportPopupProps> = (props) => {
    const { className, transformMessage = defaultTransformMessage, onSend } = props;

    const { onRequestClose } = usePopupContext();

    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [attachments, setAttachments] = useState([] as File[]);
    const [sending, setSending] = useState(false);
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const extensions = useExtensions([
        'bmp',
        'numbers',
        'rtf',
        'csv',
        'odp',
        'text',
        'doc',
        'ods',
        'tiff',
        'docx',
        'odt',
        'eml',
        'pages',
        'gif',
        'pdf',
        'xls',
        'jpg',
        'png',
        'xlsx',
        'ppt',
        'zip',
        'keynote',
        'pptx',
        '7z',
        'msg',
        'rar',
    ]);

    const onDrop = useCallback(
        async (accepted: File[], rejected: FileRejection[]) => {
            rejected.forEach(({ file }) => {
                if (file.size > MAX_FILE_SIZE) {
                    notificationService.showErrorToast(
                        intl.formatMessage(messages.fileTooBigError, {
                            fileName: file.name,
                            maxSize: MAX_FILE_SIZE_TEXT,
                        })
                    );
                } else if (
                    !Object.values(extensions).some((extensionType) =>
                        extensionType.some((ext) => file.name.endsWith(ext))
                    )
                ) {
                    {
                        notificationService.showErrorToast(intl.formatMessage(messages.fileExtensionNotSupportedError));
                    }
                }
            });

            if (accepted.length === 0) {
                return;
            }

            if (accepted.length + attachments.length > MAX_ATTACHMENT_COUNT) {
                notificationService.showErrorToast(intl.formatMessage(messages.maxNumberOfAttachmentsExceeded));

                return;
            }

            setAttachments([...attachments, ...accepted]);
        },
        [attachments, extensions]
    );

    const handleSend = async () => {
        setSending(true);

        try {
            if (!subject || !description) {
                notificationService.showErrorToast(intl.formatMessage(messages.validationErrorRequired));
                setShowValidationErrors(true);

                return;
            }

            amplitudeService.sendData('help: send contact support request');

            let form = new FormData();

            form.append('subject', subject);
            form.append('description', transformMessage(description));
            attachments.forEach((f) => {
                form.append('attachments[]', f, f.name);
            });

            try {
                await api.postFormData('support/createIssue', form);
                notificationService.showInfoToast(intl.formatMessage(messages.postSuccessResult));
                onRequestClose();
                onSend?.();
            } catch (e) {
                notificationService.showErrorToast(intl.formatMessage(messages.postErrorResult));
            }
        } finally {
            setSending(false);
        }
    };

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        maxSize: MAX_FILE_SIZE,
        accept: extensions,
        onDrop,
        noClick: true,
        noKeyboard: true,
        disabled: sending,
    });
    const { isGlobalDragActive } = hooks.useDragAndDropInfo();

    return (
        <Popup.DefaultContent
            className={bem.add(className)()}
            qa={qa()}
            title={intl.formatMessage(messages.popupTitle)}
            customCloseHandler={() => onRequestClose()}
            buttons={
                <Popup.DefaultContent.Button
                    qa={qa('done-button')}
                    disabled={sending}
                    execute={handleSend}
                    title={intl.formatMessage(messages.doneButtonTitle)}
                >
                    <FormattedMessage id={`${i18nPrefix}.doneButtonText`} defaultMessage='Send' />
                </Popup.DefaultContent.Button>
            }
        >
            <span {...getRootProps()} className={bem('content')}>
                <Field
                    className={bem('field')}
                    required
                    title={<FormattedMessage id={`${i18nPrefix}.titleSubject`} defaultMessage='Subject' />}
                >
                    <TextEditor
                        focusOnMount
                        invalid={showValidationErrors && !subject}
                        value={subject}
                        onChange={setSubject}
                        placeholder={intl.formatMessage(messages.subjectPlaceholder)}
                    />
                </Field>

                <Field
                    className={bem('field')}
                    required
                    title={<FormattedMessage id={`${i18nPrefix}.descriptionSubject`} defaultMessage='Description' />}
                >
                    <TextAreaEditor
                        value={description}
                        invalid={showValidationErrors && !description}
                        onChange={setDescription}
                        minHeight={4}
                        maxHeight={10}
                        placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
                    />
                </Field>

                <div className={bem('files-section')}>
                    <div className={bem('files-header')}>
                        <FormattedMessage id={`${i18nPrefix}.filesHeader`} defaultMessage='Attachments' />
                    </div>

                    <div className={bem('files-list')}>
                        {attachments.map((file, i) => (
                            <a
                                className={bem('attachment-item')}
                                key={i}
                                href={window.URL.createObjectURL(file)}
                                download={file.name}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <PaperClipIcon className={bem('attachment-item-icon')} width={16} height={16} />

                                <div className={bem('attachment-item-text')}>{file.name}</div>

                                {!sending && (
                                    <CloseIconBox
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setAttachments(removeArrayItem(attachments, file));
                                        }}
                                    >
                                        <BubbleCloseIcon
                                            className={bem('attachment-item-delete-button')}
                                            width={9}
                                            height={9}
                                        />
                                    </CloseIconBox>
                                )}
                            </a>
                        ))}
                    </div>

                    <TransparentButton
                        className={bem('files-add-new-button')}
                        qa={qa('files-add-new-button')}
                        execute={open}
                    >
                        <div className={bem('files-add-new-content')}>
                            <PaperClipIcon className={bem('files-add-new-icon')} width={16} height={16} />

                            <FormattedMessage id={`${i18nPrefix}.ButtonText`} defaultMessage='Add attachment' />
                        </div>
                    </TransparentButton>
                </div>

                <input {...getInputProps()} />

                <div
                    className={bem('drop-zone', {
                        global: isGlobalDragActive,
                        local: isDragActive,
                    })}
                >
                    <div className={bem('drop-content')}>
                        <DragNDropIcon className={bem('drop-icon')} width={39} height={37} />

                        <div className={bem('drop-text')}>
                            <FormattedMessage id={`${i18nPrefix}.dropHereText`} defaultMessage='Drop files here' />
                        </div>
                    </div>
                </div>
            </span>
        </Popup.DefaultContent>
    );
};

ContactSupportPopup.displayName = 'ContactSupportPopup';

export default memo(ContactSupportPopup);
