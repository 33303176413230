import { Guid } from '@approvalmax/types';
import { progress } from '@approvalmax/ui';
import { groupBy } from 'lodash';
import flatten from 'lodash/flatten';
import merge from 'lodash/merge';
import { backend, domain, Entities, schemas, State, stateTree } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { normalize, schema } from 'normalizr';
import { setRecoil } from 'recoil-nexus';
import { api } from 'services/api';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { activeCompanyIdState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

export function getUserContextEntities(context: backend.CompaniesUserCompaniesContextAnswer) {
    function mapProfileToUser(userProfile: any) {
        return {
            ...userProfile,
            UserEmail: userProfile.Email,
            UserName: '',
        };
    }

    // Subscription entities
    const subscriptionEntities = normalize(context.Subscriptions, [schemas.subscriptionSchema]).entities;

    // Profile entities
    if (!(context.UserProfile as any).Account && context.Account) {
        (context.UserProfile as any).Account = context.Account;
    }

    let profileEntities = normalize(context.UserProfile, schemas.profileSchema).entities;
    let profileUserEntities = normalize(mapProfileToUser(context.UserProfile), schemas.userSchema).entities;

    const profile = profileEntities.profiles ? Object.values(profileEntities.profiles)[0] : null;

    delete profileEntities.profiles;
    // Company entities
    context.CompanyContexts.forEach((c) => {
        if (c.Company.SubscriptionId && subscriptionEntities.subscriptions?.[c.Company.SubscriptionId]) {
            const subscription = subscriptionEntities.subscriptions[c.Company.SubscriptionId];

            subscription.companyIds.push(c.Company.CompanyId);
        }

        // The two fields below extend the server answer and are parsed by companySchema
        (c.Company as any).BillMatchingSettings = c.BillMatchingSettings;
        (c.Company as any).PurchaseOrderMatchingSettings = c.PurchaseOrderMatchingSettings;
        (c.Company as any).XeroPurchaseOrderSettings = c.XeroPurchaseOrderSettings;
        (c.Company as any).QBooksPurchaseOrderSettings = c.QBooksPurchaseOrderSettings;
        (c.Company as any).QBooksPriceCheckerSettings = c.QBooksPriceCheckerSettings;
        (c.Company as any).MatchingMetadata = c.MatchingMetadata;
        (c.Company as any).HasActiveWorkflowWithUserAsPayer = c.HasActiveWorkflowWithUserAsPayer;
    });

    // Company invitation entities (filter out those which have been accepted)
    const invitationContext = context.Invitations
        ? context.Invitations.filter((x: any) => x.response !== domain.CompanyInvitationResponse.Accepted)
        : [];
    const companyInvitationEntities = normalize(
        invitationContext,
        new schema.Array(schemas.companyInvitationSchema)
    ).entities;

    const practiceStaffInvitationContext = context.PracticeInvitations ? context.PracticeInvitations : [];

    const practiceStaffInvitationEntities = normalize(
        practiceStaffInvitationContext,
        new schema.Array(schemas.practiceStaffInvitationSchema)
    ).entities;

    // Company entities (filter out those which we shouldn't be aware of)
    const companyContexts = context.CompanyContexts.filter(
        (x) => !Object.keys(companyInvitationEntities.companyInvitations || {}).includes(x.Company.CompanyId)
    );
    const companyEntities = normalize(
        companyContexts,
        new schema.Array({
            Company: schemas.companySchemaLegacy,
            TemplatesForEdit: [schemas.templateSchema],
            TemplatesForRequest: [schemas.templateSchema],
        })
    ).entities;

    // Filter out subscriptions for companies that we have no access to;
    // Fix accountOwnerId field in subscriptions
    Object.keys(subscriptionEntities.subscriptions || {}).forEach((sid) => {
        const subscription = subscriptionEntities.subscriptions?.[sid];

        if (subscription) {
            subscription.companyIds = subscription.companyIds.filter((cid) => companyEntities.companies?.[cid]);

            if (subscription.companyIds.length === 0) {
                delete subscriptionEntities.subscriptions?.[sid];
            }
        }
    });
    subscriptionEntities.subscription;

    const entities: Entities = merge(
        {},
        companyEntities,
        companyInvitationEntities,
        practiceStaffInvitationEntities,
        subscriptionEntities,
        profileEntities,
        profileUserEntities
    );

    const creatableTemplates: Guid[] = flatten(
        companyContexts.map((companyCtx) => companyCtx.TemplatesForRequest.map((template) => template.TemplateId))
    );

    const viewableTemplates: domain.TemplateInfo[] = companyContexts.flatMap((c) =>
        (c.WorkflowsForView || []).map((t) => ({
            id: t.id,
            companyId: c.Company.CompanyId,
            name: t.name,
            integrationCode: (t.integrationCode as domain.IntegrationCode) || null,
            enabled: t.enabled,
        }))
    );
    const allWorkflows: domain.TemplateInfo[] = companyContexts.flatMap((c) =>
        (c.Workflows || []).map((t) => ({
            id: t.id,
            companyId: c.Company.CompanyId,
            name: t.name,
            integrationCode: (t.integrationCode as domain.IntegrationCode) || null,
            enabled: t.enabled,
        }))
    );

    return {
        entities,
        creatableTemplates,
        viewableTemplates,
        allWorkflows,
        profile,
    };
}

export const SET_AUTHENTICATED_STATE = 'COMMON/SET_AUTHENTICATED_STATE';

export const setAuthenticatedState = (authenticated: boolean) => {
    return createAction(SET_AUTHENTICATED_STATE, { authenticated });
};

export const CONTEXT_INITIALIZATION_COMPLETED = 'COMMON/CONTEXT_INITIALIZATION_COMPLETED';

export const contextInitializationCompleted = () => {
    return createAction(CONTEXT_INITIALIZATION_COMPLETED);
};

export const LOAD_INITIAL_APP_DATA = 'COMMON/LOAD_INITIAL_APP_DATA';

export const loadInitialAppData = ({ context }: { context: backend.CompaniesUserCompaniesContextAnswer }) => {
    const needsToCompleteSignupWizard = !context.UserProfile.IsPasswordSet && !context.UserProfile.HasSSOPassword;
    const session: stateTree.Session = {
        authenticated: true,
        startup: {
            needsToCompleteSignupWizard,
        },
        contextInitializationCompleted: true,
    };

    const { entities, profile, creatableTemplates, viewableTemplates, allWorkflows } = getUserContextEntities(context);

    return createAction(LOAD_INITIAL_APP_DATA, {
        entities,
        profile,
        session,
        creatableTemplates,
        viewableTemplates,
        allWorkflows,
    });
};

export interface InvitationResponse {
    invitation: domain.CompanyInvitation;
    response: domain.CompanyInvitationResponse;
}

export const RESPOND_TO_INVITATION = 'COMMON/RESPOND_TO_INVITATION';
export const RESPOND_TO_INVITATION_RESPONSE = 'COMMON/RESPOND_TO_INVITATION_RESPONSE';
export const RESPOND_TO_INVITATION_FAILURE = 'COMMON/RESPOND_TO_INVITATION_FAILURE';

export const respondToInvitation = (responses: InvitationResponse[]) => {
    const validResponses = responses.filter((r) => {
        return r.invitation.status === domain.CompanyInvitationStatus.Untouched;
    });

    return createAsyncAction({
        shouldSendRequest: (state, dispatch) => {
            if (validResponses.length === 0) {
                return false;
            }

            return true;
        },

        request: (state: State) =>
            createAction(RESPOND_TO_INVITATION, {
                responses: validResponses,
            }),

        response: async (request) => {
            let context;

            progress.inc();

            try {
                const responsesByCompanyId = groupBy(validResponses, (r) => r.invitation.companyId);

                await Promise.all(
                    Object.entries(responsesByCompanyId).map(([companyId, responses]) =>
                        api.companies.respondToInvitation({
                            companyId,
                            responses: responses.map((x) => {
                                const response =
                                    x.response === domain.CompanyInvitationResponse.Accepted
                                        ? backend.ResponseToInvitation.Accepted
                                        : backend.ResponseToInvitation.Declined;

                                return {
                                    companyId: x.invitation.companyId,
                                    response,
                                };
                            }),
                        })
                    )
                );

                context = await api.companies.getUserContext({});
            } finally {
                progress.dec();
            }

            const { entities, viewableTemplates, creatableTemplates, allWorkflows } = getUserContextEntities(context);

            return createAction(
                RESPOND_TO_INVITATION_RESPONSE,
                {
                    request,
                    creatableTemplates,
                    viewableTemplates,
                    allWorkflows,
                },
                entities
            );
        },

        failure: (error, request) =>
            createErrorAction(RESPOND_TO_INVITATION_FAILURE, error, {
                request,
            }),
    });
};

export const UPDATE_PROFILE = 'COMMON/UPDATE_PROFILE';
export const updateProfile = (
    profileUserId: string,
    userPartial: Partial<domain.User>,
    profilePartial: Partial<domain.Profile>
) =>
    createAction(UPDATE_PROFILE, {
        profileUserId,
        userPartial,
        profilePartial,
    });

export const UPDATE_PROFILE_DELEGATES = 'COMMON/UPDATE_PROFILE_DELEGATES';

interface ProfileCompanyDelegate {
    companyId: string;
    delegateUserId: string | null;
}

export const updateProfileDelegates = (profileUserId: string, delegates: ProfileCompanyDelegate[]) =>
    createAction(UPDATE_PROFILE_DELEGATES, {
        profileUserId,
        delegates,
    });

export function logout(clearPersistStore: () => Promise<void>): ThunkAction {
    return async (dispatch, getState) => {
        progress.inc();

        try {
            await api.auth.logout();
        } catch (error) {
            // Do nothing
        } finally {
            await clearPersistStore();
            setRecoil(activeCompanyIdState, '');
            progress.dec();
            storageService.setAuthenticated(false);
            routingService.reloadToUrl(getPath(Path.login));
        }
    };
}

export const LOAD_USER_PROFILE_DATA = 'COMMON/LOAD_USER_PROFILE_DATA';
export const loadUserProfileData = (userId: string) =>
    createAction(LOAD_USER_PROFILE_DATA, {
        userId,
    });

export const LOAD_USER_PROFILE_DATA_RESPONSE = 'COMMON/LOAD_USER_PROFILE_DATA_RESPONSE';
export const loadUserProfileDataResponse = (userId: string, profileInfo: domain.UserProfileInfo) =>
    createAction(LOAD_USER_PROFILE_DATA_RESPONSE, {
        userId,
        profileInfo,
    });

export const UPDATE_USER_PREFERENCE = 'COMMON/UPDATE_USER_PREFERENCE';
export const updateUserPreference = (userPreferenceKey: string, value: any) =>
    createAction(UPDATE_USER_PREFERENCE, { userPreferenceKey, value });

export const UPDATE_HIDDEN_HELP_ITEM = 'COMMON/UPDATE_HIDDEN_HELP_ITEM';
export const updateHiddenHelpItem = (itemId: string, hidden: boolean) =>
    createAction(UPDATE_HIDDEN_HELP_ITEM, {
        itemId,
        hidden,
    });

export type Action = ExtractActions<
    | typeof contextInitializationCompleted
    | typeof loadInitialAppData
    | typeof loadUserProfileData
    | typeof loadUserProfileDataResponse
    | typeof respondToInvitation
    | typeof setAuthenticatedState
    | typeof updateHiddenHelpItem
    | typeof updateProfile
    | typeof updateProfileDelegates
    | typeof updateUserPreference
>;
