import { QueryOptions } from '@approvalmax/data';
import { domain } from 'modules/data';
import { useGetRequest } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseTwoFaGetDefaultEnforcementTypeResponse } from './useTwoFaGetDefaultEnforcementType.types';

export const useTwoFaGetDefaultEnforcementType = (
    integrationType: domain.IntegrationType,
    queryOptions?: QueryOptions<UseTwoFaGetDefaultEnforcementTypeResponse>
) => {
    return useGetRequest<UseTwoFaGetDefaultEnforcementTypeResponse>(twoFaApiPaths.defaultTwoFaEnforcementType, {
        pathParams: {
            integrationType,
        },
        queryOptions: {
            retry: 0,
            ...queryOptions,
        },
    });
};
