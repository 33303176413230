import { Radio } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';

import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionDearPORejectedNoteToMemo.messages';
import { RejectedNoteOption, SectionDearPORejectedNoteToMemoProps } from './SectionDearPORejectedNoteToMemo.types';

const SectionDearPORejectedNoteToMemo: FC<SectionDearPORejectedNoteToMemoProps> = memo((props) => {
    const { templateSettings, readonly, onChange, qa } = props;

    const handleChange = useCallback(
        (value: RejectedNoteOption) => {
            onChange((prevState) => ({ ...prevState, sendRejectedNoteToDear: value === RejectedNoteOption.True }));
        },
        [onChange]
    );

    return (
        <SettingsSection title={messages.title}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={templateSettings.sendRejectedNoteToDear ? RejectedNoteOption.True : RejectedNoteOption.False}
                    onChange={handleChange}
                    name='rejectedNoteToDear'
                    block
                    data-qa={qa('dear-po-rejected-note-to-demo-section')}
                >
                    <Radio value={RejectedNoteOption.True}>{messages.optionTrue}</Radio>

                    <Radio value={RejectedNoteOption.False}>{messages.optionFalse}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionDearPORejectedNoteToMemo;
