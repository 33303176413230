import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    FinishTFAEnablingWithBackupCodesRequest,
    FinishTFAEnablingWithBackupCodesResponse,
} from './useFinishTFAEnablingWithBackupCodes.types';

export const useFinishTFAEnablingWithBackupCodes = () => {
    return useMutateLegacy<
        RequestDataParams<FinishTFAEnablingWithBackupCodesRequest>,
        FinishTFAEnablingWithBackupCodesResponse
    >(twoFaApiPaths.finishTFAEnablingWithBackupCodes);
};
