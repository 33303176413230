import { TransparentButton } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const Notification = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7b53b2;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 40px;
    max-height: 300px;
    max-width: initial;
    padding: 0 60px;
`;

export const Message = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    height: 100%;
    ${font(13, '#fff')}
    padding: 5px 20px 5px 0;
`;

export const UpgradeButton = styled(TransparentButton)`
    margin-left: 10px;
    padding: 5px 20px;
    background: #fff;
    text-transform: uppercase;
    ${font(12, '#7b53b2', 'semibold')};
`;

export const CloseButton = styled(TransparentButton)`
    margin-right: 15px;
    margin-left: auto;
    fill: #fff;
`;

export const HideButton = styled(TransparentButton)`
    color: #fff;
`;
