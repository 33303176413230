import { CloseIcon } from '@approvalmax/ui';
import { memo } from 'react';
import { routingService } from 'services/routing';

import { messages } from './NotificationWarningView.messages';
import {
    AttentionIcon,
    ButtonContainer,
    CloseButton,
    Content,
    LearnMoreButton,
    Root,
    Text,
    TextContainer,
    Title,
} from './NotificationWarningView.styled';
import { NotificationWarningViewProps } from './NotificationWarningView.types';

export const NotificationWarningView = memo<NotificationWarningViewProps>((props) => {
    const { title, text, learnMoreHref, onClose } = props;

    const onInfoClick = () => {
        routingService.navigateToExternalUrlInNewTab(learnMoreHref);
    };

    return (
        <Root>
            <Content>
                <TextContainer>
                    <Title>
                        <AttentionIcon />

                        {title}

                        <AttentionIcon />
                    </Title>

                    <Text>{text}</Text>
                </TextContainer>

                <ButtonContainer>
                    {learnMoreHref && <LearnMoreButton execute={onInfoClick}>{messages.learnMore}</LearnMoreButton>}

                    {onClose && (
                        <CloseButton execute={onClose}>
                            <CloseIcon />

                            {messages.close}
                        </CloseButton>
                    )}
                </ButtonContainer>
            </Content>
        </Root>
    );
});
