import { Divider, Grid, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { memo, useMemo } from 'react';

import { PaymentDetailsField } from './components/PaymentDetailsField/PaymentDetailsField';
import { messages } from './PaymentDetailsBankAccountInfo.messages';
import { PaymentDetailsBankAccountInfoProps } from './PaymentDetailsBankAccountInfo.types';

export const PaymentDetailsBankAccountInfo = memo<PaymentDetailsBankAccountInfoProps>((props) => {
    const { status, bankAccount, lastUsedPaymentDetailsProperties } = props;

    const changedFields = useMemo(
        () =>
            lastUsedPaymentDetailsProperties?.reduce<Record<string, string>>((acc, item) => {
                acc[item.name] = item.value;

                return acc;
            }, {}) || {},
        [lastUsedPaymentDetailsProperties]
    );

    if (status !== domain.PaymentDetailsPaymentStatus.PaymentDetailsChanged) {
        return (
            <Grid gap={8}>
                <PaymentDetailsField name={messages.bankAccountName} value={bankAccount?.name} />

                <PaymentDetailsField name={messages.bankAccountCountry} value={bankAccount?.country} />

                <PaymentDetailsField name={messages.bankAccountCurrency} value={bankAccount?.currency} />

                <PaymentDetailsField name={messages.bankAccountSortCode} value={bankAccount?.sortCode} />

                <PaymentDetailsField name={messages.bankAccountNumber} value={bankAccount?.accountNumber} />
            </Grid>
        );
    }

    return (
        <div>
            <Grid gridTemplateColumns='1fr 1fr' columnGap={4}>
                <Text font='body' fontSize='small' fontWeight='medium' color='midnight80' spacing='4'>
                    {messages.previous}
                </Text>

                <Text font='body' fontSize='small' fontWeight='medium' color='midnight80' spacing='4'>
                    {messages.actual}
                </Text>
            </Grid>

            <Divider spacing='2' />

            <Grid gridTemplateColumns='1fr 1fr' columnGap={4}>
                <Grid gap={8}>
                    <PaymentDetailsField
                        name={messages.bankAccountName}
                        value={changedFields['BankAccountName'] || bankAccount?.name}
                        isOld={!!changedFields['BankAccountName']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountCountry}
                        value={changedFields['BankAccountCountry'] || bankAccount?.country}
                        isOld={!!changedFields['BankAccountCountry']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountCurrency}
                        value={changedFields['Currency'] || bankAccount?.currency}
                        isOld={!!changedFields['Currency']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountSortCode}
                        value={changedFields['SortCode'] || bankAccount?.sortCode}
                        isOld={!!changedFields['SortCode']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountNumber}
                        value={changedFields['BankAccountNumber'] || bankAccount?.accountNumber}
                        isOld={!!changedFields['BankAccountNumber']}
                    />
                </Grid>

                <Grid gap={8}>
                    <PaymentDetailsField
                        name={messages.bankAccountName}
                        value={bankAccount?.name}
                        isNew={!!changedFields['BankAccountName']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountCountry}
                        value={bankAccount?.country}
                        isNew={!!changedFields['BankAccountCountry']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountCurrency}
                        value={bankAccount?.currency}
                        isNew={!!changedFields['Currency']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountSortCode}
                        value={bankAccount?.sortCode}
                        isNew={!!changedFields['SortCode']}
                    />

                    <PaymentDetailsField
                        name={messages.bankAccountNumber}
                        value={bankAccount?.accountNumber}
                        isNew={!!changedFields['BankAccountNumber']}
                    />
                </Grid>
            </Grid>
        </div>
    );
});

PaymentDetailsBankAccountInfo.displayName = 'PaymentDetailsBankAccountInfo';
