import { ExtractComponentProp } from '@approvalmax/types';
import { Dropzone, FileRecord, Spacing, Text, useExtensions } from '@approvalmax/ui/src/components';
import { xeroConstants } from 'modules/common/constants';
import { FC, memo, useCallback } from 'react';
import { commonErrorMessages } from 'services/error/messages';
import { OcrFileContentType, useUploadAttachment } from 'shared/data';
import { handleFileUploadLimits, isPdfFileProtected } from 'shared/helpers';

import { documentPerFileMaxSize, documentPerPageMaxSize } from './UploadFiles.constants';
import { messages } from './UploadFiles.messages';
import { UploadFilesProps } from './UploadFiles.types';

export const UploadFiles: FC<UploadFilesProps> = memo((props) => {
    const { attachments, setAttachments, disabled, fileContentType } = props;
    const extensions = useExtensions(xeroConstants.ocrAllowedFileExtensions);
    const pdfExtension = useExtensions(['pdf']);

    const { mutateAsync, isLoading } = useUploadAttachment();

    const onUpload = useCallback(
        async (file: FileRecord) => {
            if (file.source.type === 'application/pdf') {
                const isProtected = await isPdfFileProtected(file.source);

                if (isProtected) {
                    throw new Error(commonErrorMessages.pdfFileProtectedError({ fileName: file.source.name }));
                }
            }

            return mutateAsync({ file: file.source }).then((value) => {
                setAttachments((attachments) => [...attachments, { innerId: file.id, ...value.data[0] }]);
            });
        },
        [mutateAsync, setAttachments]
    );

    const onRemove = useCallback<ExtractComponentProp<typeof Dropzone, 'onRemove'>>(
        (file) => {
            setAttachments((attachments) => attachments.filter((attachment) => attachment.innerId !== file.id));
        },
        [setAttachments]
    );

    return (
        <>
            <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                {messages.uploadFilesTitle}
            </Text>

            <Spacing height={16} />

            <Dropzone
                accept={fileContentType === OcrFileContentType.DocumentPerFile ? extensions : pdfExtension}
                multiple
                disabled={disabled}
                bordered={attachments.length === 0 && !isLoading}
                onRemove={onRemove}
                onUpload={onUpload}
                filterFiles={handleFileUploadLimits}
                maxFilesInList={30}
                noDuplicateNames
                maxSize={
                    fileContentType === OcrFileContentType.DocumentPerFile
                        ? documentPerFileMaxSize
                        : documentPerPageMaxSize
                }
            />
        </>
    );
});

UploadFiles.displayName = 'UploadFiles';
