import { ErrorCode } from '@approvalmax/data';
import { errorHelpers } from '@approvalmax/utils';
import { produce } from 'immer';
import { domain } from 'modules/data';

import { Action, COMPLETE_INTEGRATION_AUTH_FAILURE, SHOW_ANOTHER_COMPANY_INTEGRATED_POPUP } from '../../../actions';

export const ANOTHER_COMPANY_INTEGRATED_POPUP = 'ANOTHER_COMPANY_INTEGRATED_POPUP';

export interface AnotherCompanyIntegratedPopupData {
    id: typeof ANOTHER_COMPANY_INTEGRATED_POPUP;
    integrationType: domain.IntegrationType;
}

export default produce(
    (draft: AnotherCompanyIntegratedPopupData | null, action: Action): AnotherCompanyIntegratedPopupData | null => {
        switch (action.type) {
            case COMPLETE_INTEGRATION_AUTH_FAILURE: {
                const errorCode = errorHelpers.getErrorCode(action.error);

                if (errorCode === ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED) {
                    return {
                        id: ANOTHER_COMPANY_INTEGRATED_POPUP,
                        integrationType: action.payload.request.integrationType,
                    };
                }

                return draft;
            }

            case SHOW_ANOTHER_COMPANY_INTEGRATED_POPUP:
                return {
                    id: ANOTHER_COMPANY_INTEGRATED_POPUP,
                    integrationType: action.payload.integrationType,
                };

            default:
                return draft;
        }
    },
    null
);
