import { ErrorCode } from '@approvalmax/data';
import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { immutable, ImmutableArray, insertArrayItem, removeArrayItem } from 'modules/immutable';

import {
    Action,
    COMPLETE_REVIEW,
    COMPLETE_REVIEW_FAILURE,
    DELETE_REQUEST_RESPONSE,
    LOAD_MORE_REQUESTS_RESPONSE,
    LOAD_MORE_SEARCH_RESULTS_RESPONSE,
    LOAD_PAGE_DATA,
    LOAD_SEARCH_RESULTS_DATA_RESPONSE,
    MAKE_DECISION,
    MAKE_DECISION_FAILURE,
    RELOAD_REQUEST_FAILURE,
    RELOAD_REQUEST_LIST_RESPONSE,
    RELOAD_REQUEST_RESPONSE,
    RELOAD_REQUEST_SYNC_RESPONSE,
    RETURN_TO_REVIEW,
    RETURN_TO_REVIEW_FAILURE,
    REVOKE_DECISION_FAILURE,
} from '../../actions';

export type RequestsType = ImmutableArray<Guid>;

const INITIAL_STATE: RequestsType = immutable([]);

export default function (state = INITIAL_STATE, action: Action): RequestsType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
        case LOAD_MORE_REQUESTS_RESPONSE:
        case LOAD_MORE_SEARCH_RESULTS_RESPONSE: {
            return immutable(state.concat(action.payload.requests.filter((r) => !state.includes(r))));
        }

        case RELOAD_REQUEST_LIST_RESPONSE:
            return immutable(action.payload.requests || []);

        case RELOAD_REQUEST_SYNC_RESPONSE:
        case RELOAD_REQUEST_RESPONSE: {
            const inList = state.includes(action.payload.requestId);

            if (action.payload.requestMatchesFilter && !inList) {
                return insertArrayItem(state, action.payload.requestId, 0);
            }

            if (!action.payload.requestMatchesFilter && inList) {
                return removeArrayItem(state, action.payload.requestId);
            }

            return state;
        }

        case RELOAD_REQUEST_FAILURE:
            return removeArrayItem(state, action.payload.requestId);

        case RETURN_TO_REVIEW:
        case COMPLETE_REVIEW:
        case MAKE_DECISION:
            if (action.payload.removeFromList) {
                return removeArrayItem(state, action.payload.target.id);
            }

            return state;

        case DELETE_REQUEST_RESPONSE:
            return removeArrayItem(state, action.payload.requestId);

        case RETURN_TO_REVIEW_FAILURE:
        case COMPLETE_REVIEW_FAILURE:
        case MAKE_DECISION_FAILURE: {
            const errorCode = errorHelpers.getErrorCode(action.error);

            if (action.payload.removeFromList && errorCode !== ErrorCode.E2011_REQUEST_NOT_FOUND) {
                return insertArrayItem(state, action.payload.requestId, 0);
            }

            return state;
        }

        case REVOKE_DECISION_FAILURE: {
            const errorCode = errorHelpers.getErrorCode(action.error);

            if (errorCode === ErrorCode.E2011_REQUEST_NOT_FOUND) {
                return removeArrayItem(state, action.payload.requestId);
            }

            return state;
        }

        case LOAD_SEARCH_RESULTS_DATA_RESPONSE:
            return immutable(action.payload.requests);

        default:
            return state;
    }
}
