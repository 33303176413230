import { Guid, Reference } from '@approvalmax/types';
import { toast } from '@approvalmax/ui/src/components';
import { defineMessages, errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain, schemas, State } from 'modules/data';
import {
    createAction,
    createAsyncAction,
    createErrorAction,
    createSelectingAction,
    ExtractActions,
} from 'modules/react-redux';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';

import { getActiveMatrix, getActiveTemplate } from '../selectors/pageSelectors';
import { ActiveMatrixData } from '../types/activeMatrixData';
import { AccessType, MatrixType } from '../types/matrix';

const messages = defineMessages('app.WorkflowIdPage.actions.matrix', {
    changesSaved: 'Changes saved',
});

const getMatrixTypeForAmplitude = (matrixType: MatrixType) => {
    switch (matrixType) {
        case MatrixType.Approval:
            return 'approval matrix';

        case MatrixType.AutoApproval:
            return 'auto-approval matrix';

        case MatrixType.Reviewer:
        case MatrixType.Editor:
            return 'reviewer matrix';

        case MatrixType.Requester:
            return 'submitter matrix';

        case MatrixType.Editing:
            return 'editing matrix';

        default:
            throw errorHelpers.assertNever(matrixType);
    }
};

const openMatrixAmpltiudeEvent = (integrationCode: domain.IntegrationCode | null, matrixType: MatrixType) => {
    amplitudeService.sendData('workflow: open matrix', {
        workflow: integrationCode?.toLocaleLowerCase() || 'standalone',
        'matrix type': getMatrixTypeForAmplitude(matrixType),
    });
};

const addOrRemoveMatrixRowAmpltiudeEvent = (
    action: 'add' | 'remove',
    integrationCode: domain.IntegrationCode | null,
    matrixType: MatrixType,
    rowType: 'user' | 'alternative rule' | 'auto-approval rule'
) => {
    const eventName = action === 'add' ? 'workflow: add matrix row' : 'workflow: remove matrix row';

    amplitudeService.sendData(eventName, {
        workflow: integrationCode?.toLocaleLowerCase() || 'standalone',
        'matrix type': getMatrixTypeForAmplitude(matrixType),
        'row type': rowType,
    });
};

export const OPEN_APPROVAL_MATRIX = 'WORKFLOW_TEMPLATES/OPEN_APPROVAL_MATRIX';
export const openApprovalMatrix = (
    stepIndex: number,
    highlightLineId?: string,
    onApplyAndClose?: (stepIndex: number) => void,
    onClose?: (stepIndex: number) => void
) =>
    createSelectingAction(OPEN_APPROVAL_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        openMatrixAmpltiudeEvent(template.integrationCode, MatrixType.Approval);

        return {
            template,
            stepIndex,
            users: selectors.user.getUsers(state),
            team: selectors.company.getCompanyById(state, template.companyId).allMembers,
            highlightLineId,
            onApplyAndClose,
            onClose,
        };
    });

export const OPEN_EDITING_MATRIX = 'WORKFLOW_TEMPLATES/OPEN_EDITING_MATRIX';
export const openEditingMatrix = (
    stepIndex: number,
    participantMatrix: domain.MatrixLine[],
    highlightLineId?: string
) =>
    createSelectingAction(OPEN_EDITING_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        openMatrixAmpltiudeEvent(template.integrationCode, MatrixType.Editing);

        return {
            template,
            stepIndex,
            participantMatrix,
            highlightLineId,
        };
    });

export const OPEN_REVIEWER_MATRIX = 'WORKFLOW_TEMPLATES/OPEN_REVIEWER_MATRIX';
export const openReviewerMatrix = (highlightLineId?: string) =>
    createSelectingAction(OPEN_REVIEWER_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        openMatrixAmpltiudeEvent(template.integrationCode, MatrixType.Reviewer);

        return {
            template,
            users: selectors.user.getUsers(state),
            team: selectors.company.getCompanyById(state, template.companyId).allMembers,
            highlightLineId,
        };
    });

export const OPEN_EDITORS_MATRIX = 'WORKFLOW_TEMPLATES/OPEN_EDITORS_MATRIX';
export const openEditorsMatrix = (stepIndex: number, highlightLineId?: string) =>
    createSelectingAction(OPEN_EDITORS_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        openMatrixAmpltiudeEvent(template.integrationCode, MatrixType.Editor);

        return {
            template,
            stepIndex,
            users: selectors.user.getUsers(state),
            team: selectors.company.getCompanyById(state, template.companyId).allMembers,
            highlightLineId,
        };
    });

export const OPEN_SUBMITTER_MATRIX = 'WORKFLOWS/OPEN_SUBMITTER_MATRIX';
export const openSubmitterMatrix = (highlightLineId?: string) =>
    createSelectingAction(OPEN_SUBMITTER_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        openMatrixAmpltiudeEvent(template.integrationCode, MatrixType.Requester);

        return {
            template,
            users: selectors.user.getUsers(state),
            team: selectors.company.getCompanyById(state, template.companyId).allMembers,
            highlightLineId,
        };
    });

export const OPEN_AUTO_APPROVER_MATRIX = 'WORKFLOWS/OPEN_AUTO_APPROVER_MATRIX';
export const openAutoApproverMatrix = (highlightLineId?: string) =>
    createSelectingAction(OPEN_AUTO_APPROVER_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        openMatrixAmpltiudeEvent(template.integrationCode, MatrixType.AutoApproval);

        return { template, highlightLineId };
    });

export const DISCARD_OPEN_MATRIX = 'WORKFLOW_TEMPLATES/DISCARD_OPEN_MATRIX';
export const discardOpenMatrix = () => createAction(DISCARD_OPEN_MATRIX, {});

export const DISCARD_OPEN_EDITING_MATRIX = 'WORKFLOW_TEMPLATES/DISCARD_OPEN_EDITING_MATRIX';
export const discardOpenEditingMatrix = () => createAction(DISCARD_OPEN_EDITING_MATRIX, {});

export const APPLY_MATRIX = 'WORKFLOW_TEMPLATES/APPLY_MATRIX';
export const applyMatrix = (matrix: ActiveMatrixData, fields: domain.Field[]) =>
    createAction(APPLY_MATRIX, {
        matrix,
        fields,
    });

export const SET_CONDITION = 'WORKFLOW_TEMPLATES/SET_CONDITION';
export const setCondition = (
    lineId: string,
    lineIndex: number,
    rule: domain.MatrixRule,
    ruleIndex: number,
    field: domain.Field,
    newCondition: domain.MatrixCondition,
    matrixType: MatrixType
) =>
    createSelectingAction(SET_CONDITION, (state) => {
        const template = getActiveTemplate(state)!;

        amplitudeService.sendData('workflow: edit matrix cell', {
            workflow: template.integrationCode?.toLocaleLowerCase() || 'standalone',
            'matrix type': getMatrixTypeForAmplitude(matrixType),
            column: field.name.toLocaleLowerCase(),
        });

        return {
            lineId,
            lineIndex,
            rule,
            ruleIndex,
            field,
            newCondition,
            matrixType,
        };
    });

export const ADD_USER_TO_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/ADD_USER_TO_ACTIVE_MATRIX';
export const addUserToActiveMatrix = (user: domain.User) =>
    createSelectingAction(ADD_USER_TO_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;
        const activeMatrix = getActiveMatrix(state)!;

        addOrRemoveMatrixRowAmpltiudeEvent('add', template.integrationCode, activeMatrix?.type, 'user');

        return {
            user,
        };
    });
export const ADD_APPROVAL_RULE_TO_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/ADD_APPROVAL_RULE_TO_ACTIVE_MATRIX';
export const addApprovalRuleToActiveMatrix = (ruleName: string) =>
    createSelectingAction(ADD_APPROVAL_RULE_TO_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        addOrRemoveMatrixRowAmpltiudeEvent(
            'add',
            template.integrationCode,
            MatrixType.AutoApproval,
            'auto-approval rule'
        );

        return {
            ruleName,
        };
    });
export const REMOVE_LINE_FROM_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/REMOVE_LINE_FROM_ACTIVE_MATRIX';
export const removeLineFromActiveMatrix = (lineId: string) =>
    createSelectingAction(REMOVE_LINE_FROM_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;
        const activeMatrix = getActiveMatrix(state)!;

        addOrRemoveMatrixRowAmpltiudeEvent('remove', template.integrationCode, activeMatrix?.type, 'user');

        return {
            lineId,
        };
    });

export const REMOVE_LINE_FROM_ACTIVE_MATRIX_AUTO_APPROVAL =
    'WORKFLOW_TEMPLATES/REMOVE_LINE_FROM_ACTIVE_MATRIX_AUTO_APPROVAL';
export const removeLineFromActiveMatrixAutoApproval = (lineIndex: number) =>
    createSelectingAction(REMOVE_LINE_FROM_ACTIVE_MATRIX_AUTO_APPROVAL, (state) => {
        const template = getActiveTemplate(state)!;

        addOrRemoveMatrixRowAmpltiudeEvent(
            'remove',
            template.integrationCode,
            MatrixType.AutoApproval,
            'auto-approval rule'
        );

        return {
            lineIndex,
        };
    });

export const RENAME_LINE_OF_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/RENAME_LINE_OF_ACTIVE_MATRIX';
export const renameLineOfActiveMatrix = (newLineId: string, lineIndex: number) =>
    createAction(RENAME_LINE_OF_ACTIVE_MATRIX, {
        newLineId,
        lineIndex,
    });

export const SET_DEFAULT_REVIEWER_TO_ACTIVE_MATRIX = 'WORKFLOWS/SET_DEFAULT_REVIEWER_TO_ACTIVE_MATRIX';
export const setDefaultReviewerToActiveMatrix = (userId: Guid | null) =>
    createSelectingAction(SET_DEFAULT_REVIEWER_TO_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        amplitudeService.sendData('workflow: add default reviewer', {
            workflow: template.integrationCode?.toLocaleLowerCase() || 'standalone',
            'action source': 'review matrix',
        });

        return {
            userId,
        };
    });

export const SET_DEFAULT_APPROVER_TO_ACTIVE_MATRIX = 'WORKFLOWS/SET_DEFAULT_APPROVER_TO_ACTIVE_MATRIX';
export const setDefaultApproverToActiveMatrix = (userId: Guid | null) =>
    createSelectingAction(SET_DEFAULT_APPROVER_TO_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;

        amplitudeService.sendData('workflow: add default approver', {
            workflow: template.integrationCode?.toLocaleLowerCase() || 'standalone',
            'action source': 'approval matrix',
        });

        return {
            userId,
        };
    });

export const ADD_RULE_TO_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/ADD_RULE_TO_ACTIVE_MATRIX';
export const addRuleToActiveMatrix = (user: domain.User) =>
    createSelectingAction(ADD_RULE_TO_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;
        const activeMatrix = getActiveMatrix(state)!;

        addOrRemoveMatrixRowAmpltiudeEvent('add', template.integrationCode, activeMatrix?.type, 'alternative rule');

        return {
            user,
        };
    });

export const REMOVE_RULE_FROM_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/REMOVE_RULE_FROM_ACTIVE_MATRIX';
export const removeRuleFromActiveMatrix = (user: domain.User, rule: domain.MatrixRule) =>
    createSelectingAction(REMOVE_RULE_FROM_ACTIVE_MATRIX, (state) => {
        const template = getActiveTemplate(state)!;
        const activeMatrix = getActiveMatrix(state)!;

        addOrRemoveMatrixRowAmpltiudeEvent('remove', template.integrationCode, activeMatrix?.type, 'alternative rule');

        return {
            user,
            rule,
        };
    });

export const COPY_RULES_TO_SAME_STEP_USERS = 'WORKFLOW_TEMPLATES/COPY_RULES_TO_SAME_STEP_USERS';
export const copyRuleToSameStepUsers = (payload: {
    fromUser: domain.User;
    toUsers: domain.User[];
    checkedColumns: string[];
    matrixType: MatrixType;
}) => createAction(COPY_RULES_TO_SAME_STEP_USERS, payload);

export const ADD_FIELD_TO_ACTIVE_TEMPLATE = 'WORKFLOW_TEMPLATES/ADD_FIELD_TO_ACTIVE_TEMPLATE';
export const addFieldToActiveTemplate = (field: domain.Field) =>
    createAction(ADD_FIELD_TO_ACTIVE_TEMPLATE, {
        field,
    });

export const REMOVE_FIELD_FROM_ACTIVE_TEMPLATE = 'WORKFLOW_TEMPLATES/REMOVE_FIELD_FROM_ACTIVE_TEMPLATE';
export const removeFieldFromActiveTemplate = (fieldId: string) =>
    createAction(REMOVE_FIELD_FROM_ACTIVE_TEMPLATE, {
        fieldId,
    });

export const CREATE_FIELD_OPTION = 'WORKFLOW_TEMPLATES/CREATE_FIELD_OPTION';
export const createFieldOption = (field: domain.Field, newOption: Reference) =>
    createAction(CREATE_FIELD_OPTION, {
        field,
        newOption,
    });

export const UPDATE_AMOUNT_TYPE_IN_ACTIVE_MATRIX = 'WORKFLOW_TEMPLATES/UPDATE_AMOUNT_TYPE_IN_ACTIVE_MATRIX';
export const updateAmountTypeInActiveMatrix = (amountType: domain.AmountType) =>
    createAction(UPDATE_AMOUNT_TYPE_IN_ACTIVE_MATRIX, {
        amountType,
    });

export const UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX = 'WORKFLOWS/UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX';
export const updateFieldAccessTypeInActiveMatrix = (
    fieldId: string,
    newAccessType: AccessType,
    matrixType: MatrixType
) =>
    createAction(UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX, {
        fieldId,
        newAccessType,
        matrixType,
    });

export const RENAME_FIELD = 'WORKFLOW_TEMPLATES/RENAME_FIELD';
export const RENAME_FIELD_RESPONSE = 'WORKFLOW_TEMPLATES/RENAME_FIELD_RESPONSE';
export const RENAME_FIELD_FAILURE = 'WORKFLOW_TEMPLATES/RENAME_FIELD_FAILURE';
export const renameField = (fieldId: string, fieldName: string) =>
    createAsyncAction({
        request: (state: State) => {
            return createAction(RENAME_FIELD, {
                field: selectors.field.getFieldById(state, fieldId),
                name: fieldName,
            });
        },

        response: async (request) => {
            await api.companies.editFields({
                companyId: request.field.companyId,
                fields: [
                    {
                        fieldId: request.field.id,
                        name: request.name,
                        fieldType: request.field.type,
                        exactValues: request.field.exactValues.map((x) => ({
                            fieldValueId: x.id,
                            value: x.text,
                        })),
                    },
                ],
            });

            return createAction(RENAME_FIELD_RESPONSE, {
                request,
            });
        },

        failure: (error, request) =>
            createErrorAction(RENAME_FIELD_FAILURE, error, {
                request,
            }),

        didDispatchResponse: () => {
            toast.success(messages.changesSaved);
        },
    });

export const CREATE_FIELD = 'WORKFLOW_TEMPLATES/CREATE_FIELD';
export const CREATE_FIELD_RESPONSE = 'WORKFLOW_TEMPLATES/CREATE_FIELD_RESPONSE';
export const CREATE_FIELD_FAILURE = 'WORKFLOW_TEMPLATES/CREATE_FIELD_FAILURE';
export const createField = (template: domain.Template, fieldName: string) =>
    createAsyncAction({
        request: (state: State) => {
            return createAction(CREATE_FIELD, {
                transfer: {
                    fields: [
                        {
                            name: fieldName,
                            fieldType: backend.FieldType.ExactValueRange,
                            exactValues: [],
                        },
                    ],
                    companyId: template.companyId,
                },
            });
        },

        response: async (request) => {
            const response = await api.companies.createFields(request.transfer);

            return createAction(CREATE_FIELD_RESPONSE, {
                request,
                raw: {
                    Fields: response.Fields.map((f: any) => ({
                        ...f,
                        companyId: template.companyId,
                    })),
                },
            });
        },

        failure: (error, request) =>
            createErrorAction(CREATE_FIELD_FAILURE, error, {
                request,
            }),

        schema: {
            raw: { Fields: [schemas.fieldSchema] },
        },
    });

export type Action = ExtractActions<
    | typeof addApprovalRuleToActiveMatrix
    | typeof addFieldToActiveTemplate
    | typeof addRuleToActiveMatrix
    | typeof addUserToActiveMatrix
    | typeof applyMatrix
    | typeof copyRuleToSameStepUsers
    | typeof createField
    | typeof createFieldOption
    | typeof discardOpenEditingMatrix
    | typeof discardOpenMatrix
    | typeof openApprovalMatrix
    | typeof openAutoApproverMatrix
    | typeof openEditingMatrix
    | typeof openEditorsMatrix
    | typeof openReviewerMatrix
    | typeof openSubmitterMatrix
    | typeof removeFieldFromActiveTemplate
    | typeof removeLineFromActiveMatrix
    | typeof removeLineFromActiveMatrixAutoApproval
    | typeof removeRuleFromActiveMatrix
    | typeof renameField
    | typeof renameLineOfActiveMatrix
    | typeof setCondition
    | typeof setDefaultApproverToActiveMatrix
    | typeof setDefaultReviewerToActiveMatrix
    | typeof updateAmountTypeInActiveMatrix
    | typeof updateFieldAccessTypeInActiveMatrix
>;
