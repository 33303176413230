import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StepDeadline.DeadlinePanel', {
    title: 'Deadline',
    confirm: 'Confirm',
    optionNotSet: 'Not set',
    optionBasedOnSubmission: 'Based on submission',
    optionBasedOnApproval: 'Based on approval',
    optionBasedOnDueDate: 'Based on due date',
    descriptionBasedOnSubmission: 'Based on submission – deadline will be set on the request submission.',
    descriptionBasedOnApproval: 'Based on approval – deadline will be set when the request enters the approval step.',
    descriptionBasedOnDueDate: 'Based on due date – deadline will be set based on the due date minus n-days',
    errorEmpty: 'Enter a time value for the deadline or select the Not set option.',
});
