import { Mods, mods } from '@approvalmax/theme';
import { Checkbox, font, oDropdownPanel, TransparentButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.div`
    flex: none;
    width: 200px;
    padding: 8px 15px 12px 15px;
    display: flex;
    flex-flow: column;
`;

export const ConditionDropdownBtn = styled(TransparentButton)<StyledMods<Mods<'readOnly'> & { empty: boolean }>>`
    ${font(12, '#000')}
    position: relative;
    border-bottom: 1px solid transparent;
    width: 100%;

    &::before {
        content: '';
        width: 0;
        margin: -1px 0 0;
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        border: 5px solid transparent;
        border-top-color: #4c8b5d;
        border-bottom-width: 0;
        border-radius: 3px;
        z-index: 1;
    }

    &::after {
        content: '';
        width: 0;
        margin: -1px 0 0;
        display: none;
        position: absolute;
        top: 50%;
        right: 2px;
        border: 3px solid transparent;
        border-top-color: #fff;
        border-bottom-width: 0;
        z-index: 2;
    }

    ${mods.readOnly.false`
        &:hover {
            color: #333;
            border-bottom-color: #777;

            &::after,
            &::before {
                display: block;
            }
        }

        &:focus {
            color: #333;
            border-bottom-color: #4c8b5d;

            &::after,
            &::before {
                display: block;
            }
        }
    `}

    ${mods('empty', true)`
        color: #777;
    `}
`;

export const ConditionTypePanel = styled.div`
    ${oDropdownPanel()}
    padding: 5px 0;
`;

export const ConditionTypePanelItem = styled.div`
    line-height: 26px;
    padding-left: 5px;
    padding-right: 10px;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
`;

export const CheckboxContainer = styled.div<StyledMods<Mods<'readOnly'>>>`
    display: flex;
    cursor: pointer;
    margin-top: 4px;

    ${mods.readOnly.true`
        cursor: default;
        opacity: 0.7;
    `}
`;

export const CheckboxBox = styled(Checkbox)`
    margin: 0 5px 0 0;
`;

export const CheckboxText = styled.div`
    ${font(12, '#000')}
`;
