import { ErrorCode } from '@approvalmax/data';
import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';

import {
    Action,
    COMPLETE_REVIEW,
    COMPLETE_REVIEW_FAILURE,
    DELETE_REQUEST_RESPONSE,
    LOAD_PAGE_DATA,
    LOAD_SEARCH_RESULTS_DATA_RESPONSE,
    MAKE_DECISION,
    MAKE_DECISION_FAILURE,
    RELOAD_REQUEST_FAILURE,
    RELOAD_REQUEST_LIST_RESPONSE,
    RELOAD_REQUEST_RESPONSE,
    RELOAD_REQUEST_SYNC_RESPONSE,
    RETURN_TO_REVIEW,
    RETURN_TO_REVIEW_FAILURE,
    REVOKE_DECISION_FAILURE,
    SET_ACTIVE_REQUEST_ID,
} from '../../actions';
import { getNextAvailableRequest } from '../../selectors/requestSelectors';

export type ActiveRequestIdType = Guid | null;

const INITIAL_STATE: ActiveRequestIdType = null;

export default function (state = INITIAL_STATE, action: Action): ActiveRequestIdType {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return action.payload.requestId;

        case SET_ACTIVE_REQUEST_ID:
            return action.payload.requestId;

        case RELOAD_REQUEST_SYNC_RESPONSE:
        case RELOAD_REQUEST_RESPONSE: {
            const inList = action.payload.requestMatchesFilter;

            if (!state && inList) {
                // empty list -> non-empty list => select new item
                return action.payload.requestId;
            }

            if (!inList && state === action.payload.requestId) {
                // removed from list => change selection
                return getNextAvailableRequest(action.payload.activeRequestList, action.payload.requestId);
            }

            // Preserve active selection
            return state;
        }

        case RELOAD_REQUEST_FAILURE:
            return getNextAvailableRequest(action.payload.requestList, action.payload.requestId);

        case RETURN_TO_REVIEW:
        case COMPLETE_REVIEW:
        case MAKE_DECISION: {
            if (!action.payload.removeFromList || !state) {
                return state;
            }

            return getNextAvailableRequest(action.payload.requestList, action.payload.target.id);
        }

        case RETURN_TO_REVIEW_FAILURE:
        case COMPLETE_REVIEW_FAILURE:
        case MAKE_DECISION_FAILURE: {
            const errorCode = errorHelpers.getErrorCode(action.error);

            if (
                action.payload.removeFromList &&
                ((!state && errorCode !== ErrorCode.E2011_REQUEST_NOT_FOUND) ||
                    errorCode === ErrorCode.E8003_OVERBUDGET_BILLS_APPROVAL_NOT_ALLOWED ||
                    errorCode === ErrorCode.E8005_MATCHING_AMOUNT_IS_EXCEEDED)
            ) {
                return action.payload.requestId;
            }

            return state;
        }

        case DELETE_REQUEST_RESPONSE: {
            if (state !== action.payload.requestId) {
                return state;
            }

            return getNextAvailableRequest(action.payload.requestList, action.payload.requestId);
        }

        case REVOKE_DECISION_FAILURE: {
            const errorCode = errorHelpers.getErrorCode(action.error);

            if (state === action.payload.requestId && errorCode === ErrorCode.E2011_REQUEST_NOT_FOUND) {
                return getNextAvailableRequest(action.payload.requestList, action.payload.requestId);
            }

            return state;
        }

        case RELOAD_REQUEST_LIST_RESPONSE:
            if (!state || !action.payload.requests.includes(state)) {
                return action.payload.requests[0] || null;
            }

            return state;

        case LOAD_SEARCH_RESULTS_DATA_RESPONSE:
            if (action.payload.requests.length > 0) {
                return action.payload.requests[0];
            }

            return state;

        default:
            return state;
    }
}
