import { domain } from 'modules/data';
import { memo, useCallback } from 'react';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import { messages } from './AirwallexBenificiaryCreationConditionCell.messages';
import { CheckboxContainer, LabelText, Root, StyledCheckbox } from './AirwallexBenificiaryCreationConditionCell.styles';

const AirwallexBenificiaryCreationConditionCell = memo<RenderCellOptions>((props) => {
    const { rule, field, readonly, lineId, condition, onConditionChange } = props;

    const onChange = useCallback(() => {
        const newCondition: domain.ServerCondition | domain.AlwaysTrueCondition =
            condition.conditionType === null
                ? {
                      fieldId: condition.fieldId,
                      fieldName: condition.fieldName,
                      fieldSystemPurpose: condition.fieldSystemPurpose,
                      conditionType: domain.ConditionType.ServerCondition,
                      serverConditionType: domain.ServerConditionType.AllContacts,
                      allowCreation: true,
                  }
                : {
                      fieldId: condition.fieldId,
                      fieldName: condition.fieldName,
                      fieldSystemPurpose: condition.fieldSystemPurpose,
                      conditionType: null,
                  };

        onConditionChange(lineId, rule, field, newCondition);
    }, [
        condition.conditionType,
        condition.fieldId,
        condition.fieldName,
        condition.fieldSystemPurpose,
        field,
        lineId,
        onConditionChange,
        rule,
    ]);

    return (
        <Root>
            <CheckboxContainer onClick={readonly ? undefined : onChange}>
                <StyledCheckbox checked={condition.allowCreation} disabled={readonly} />

                <LabelText>{messages.label}</LabelText>
            </CheckboxContainer>
        </Root>
    );
});

export default AirwallexBenificiaryCreationConditionCell;
