import { Reference } from '@approvalmax/types';
import { arrayHelpers, errorHelpers } from '@approvalmax/utils';
import uniq from 'lodash/uniq';
import { domain } from 'modules/data';
import {
    addArrayItem,
    ImmutableObject,
    insertArrayItem,
    mergeIn,
    removeArrayItem,
    set,
    setIn,
    updateArrayItem,
} from 'modules/immutable';

import {
    Action,
    ADD_XERO_LINE_ITEM,
    CHANGE_XERO_DELIVERY_DETAILS,
    CHANGE_XERO_LINE_AMOUNT_TYPE,
    CHANGE_XERO_LINE_ITEM_ACCOUNT,
    CHANGE_XERO_LINE_ITEM_CHECKED,
    CHANGE_XERO_LINE_ITEM_DESCRIPTION,
    CHANGE_XERO_LINE_ITEM_DISCOUNT,
    CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT,
    CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE,
    CHANGE_XERO_LINE_ITEM_ITEM,
    CHANGE_XERO_LINE_ITEM_QTY,
    CHANGE_XERO_LINE_ITEM_TAX,
    CHANGE_XERO_LINE_ITEM_TAX_AMOUNT,
    CHANGE_XERO_LINE_ITEM_TRACKING_CATEGORY_VALUE,
    CHANGE_XERO_LINE_ITEM_UNIT_PRICE,
    CHANGE_XERO_SUPPLIER,
    CHANGE_XERO_TERMS,
    CLONE_XERO_LINE_ITEM,
    CREATE_XERO_CONTACT_RESPONSE,
    REMOVE_ATTACHMENT,
    REMOVE_XERO_LINE_ITEM,
    REORDER_XERO_LINE_ITEMS,
    SELECT_XERO_DELIVERY_ADDRESS,
    UPDATE_XERO_AIRWALLEX_BP_DETAILS,
    UPDATE_XERO_AMAXPAY_BP_DETAILS,
    UPDATE_XERO_BP_DETAILS,
    XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA,
    XERO_EMAIL_TO_SUPPLIER_INIT,
    XERO_EMAIL_TO_SUPPLIER_TOGGLE,
} from '../../actions';
import { XeroContact } from '../../data/xero/XeroContact';
import { XeroContext } from '../../data/xero/XeroContext';
import { RequestEditMode } from '../../selectors/requestSelectors';
import { isEmailToSupplierEmpty } from '../../utils/supplierEmailUtils';

export type RequestType = ImmutableObject<domain.Request>;

function getSupplierToEmails(supplier: XeroContact | null) {
    return (
        supplier?.contactPersons
            ?.filter((person) => person.emailAddress && person.includeInEmails)
            .map((person) => person.emailAddress) || []
    );
}

function getEmailDetails(details: domain.XeroPurchaseOrderDetails | domain.XeroQuoteDetails, context: XeroContext) {
    const supplier = details.contact as XeroContact | null;
    const contactEmails = getSupplierToEmails(supplier);

    const emailSettings = context.supplierEmailSettings;

    const to = contactEmails || [];
    const cc = uniq<string>(emailSettings?.cc || []);
    const subject = emailSettings?.subject || '';
    const body = emailSettings?.body || '';

    return { to, subject, body, cc };
}

function updateLineItem(
    state: RequestType,
    lineItemId: string,
    mutator: (li: domain.XeroLineItem, details: domain.XeroSharedDetails) => domain.XeroLineItem
) {
    const details = state.details as domain.XeroSharedDetails;

    let lineItem = details.lineItems.find((li) => li.id === lineItemId);

    if (!lineItem) {
        throw errorHelpers.invalidOperationError(`Failed to find line item with id ${lineItemId}`);
    }

    return setIn(
        state,
        ['details', 'lineItems'],
        updateArrayItem(
            details.lineItems,
            (li) => li.id === lineItemId,
            mutator(
                {
                    ...lineItem,
                },
                details
            )
        )
    );
}

function getAdjustedTax(newTax: domain.XeroTaxCode | null, lineItem: domain.XeroLineItem, request: domain.Request) {
    const details = request.details as domain.XeroSharedDetails;

    if (details.lineAmountType === domain.LineAmountType.NoTax) {
        return undefined;
    }

    return newTax || undefined;
}

function resetAmountFields(li: domain.XeroLineItem) {
    li.taxAmount = undefined;
    li.amount = undefined;
}

export default function (state: RequestType, action: Action): RequestType {
    switch (action.type) {
        case SELECT_XERO_DELIVERY_ADDRESS: {
            const details = state.details as domain.XeroPurchaseOrderDetails;
            const address = action.payload.address;
            const addressText = [action.payload.contact ? action.payload.contact.text : undefined, address.text]
                .filter((x) => x)
                .join('\n');

            return mergeIn(state, ['details', 'delivery'], {
                address: addressText,
                attentionTo: address.attentionTo || details.delivery.attentionTo,
            } as domain.XeroPurchaseOrderDetails['delivery']);
        }

        case CHANGE_XERO_DELIVERY_DETAILS:
            return setIn(state, ['details', 'delivery'], action.payload.deliveryDetails);

        case XERO_EMAIL_TO_SUPPLIER_INIT: {
            const { context, author, details, isNew, initialSendToSupplier } = action.payload;

            const emailSettings = context.supplierEmailSettings;

            const emailDetails: domain.EmailToSupplierDetails = isNew
                ? getEmailDetails(details, context)
                : details.emailToSupplier || { to: [], cc: [], subject: '', body: '' };

            const sendToSupplier = isNew
                ? emailSettings?.state === domain.TemplateSettingsEmailToSupplierState.EnabledAndActive
                : details.sendToSupplier || initialSendToSupplier;

            let emailToSupplier: domain.EmailToSupplier = {
                from: author.displayName,
                to: uniq<string>([...emailDetails.to, ...(details.emailToSupplier?.to || [])]),
                cc: uniq<string>(
                    isNew
                        ? [author.userEmail, ...emailDetails.cc, ...(details.emailToSupplier?.cc || [])]
                        : emailDetails.cc
                ),
                replyTo: isNew ? author.userEmail : details.emailToSupplier?.replyTo || '',
                subject: emailDetails.subject,
                body: emailDetails.body,
                attachments: details.emailToSupplier?.attachments || [],
            };

            const isEmailToSupplierDisabled =
                context.supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (isEmailToSupplierEmpty(emailToSupplier) && details.contact && !isEmailToSupplierDisabled) {
                const supplierEmailDetails = getEmailDetails(details, context);

                emailToSupplier = {
                    ...emailToSupplier,
                    ...supplierEmailDetails,
                };
            }

            return mergeIn(state, ['details'], { sendToSupplier, emailToSupplier });
        }

        case XERO_EMAIL_TO_SUPPLIER_TOGGLE:
            return setIn(state, ['details', 'sendToSupplier'], action.payload.sendToSupplier);

        case XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA:
            return setIn(state, ['details', 'emailToSupplier', action.payload.key], action.payload.value);

        case REMOVE_ATTACHMENT: {
            let xeroPurchaseOrder = state.details as domain.XeroPurchaseOrderDetails;

            if (xeroPurchaseOrder && xeroPurchaseOrder.emailToSupplier) {
                let attachments: domain.RequestAttachment[] = removeArrayItem(
                    xeroPurchaseOrder.emailToSupplier.attachments || [], // INFO: somehow attachments can be undefined, but types said not (AM-10481) (temporary fix)
                    (a) => a.id === action.payload.attachmentId
                );

                return setIn(state, ['details', 'emailToSupplier', 'attachments'], attachments);
            }

            return state;
        }

        case CREATE_XERO_CONTACT_RESPONSE:
        case CHANGE_XERO_SUPPLIER: {
            const { supplier, details, context } = action.payload;

            const supplierPrev = details.contact ? { ...(details.contact as XeroContact) } : null;

            const isEmailToSupplierDisabled =
                context.supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (!supplier) {
                let stateNext = { ...state };

                const emailToSupplier = {
                    ...(stateNext.details as domain.XeroPurchaseOrderDetails).emailToSupplier,
                    to: [],
                };

                if (!isEmailToSupplierDisabled) {
                    stateNext = setIn(stateNext, ['details', 'emailToSupplier'], emailToSupplier);
                }

                stateNext = setIn(stateNext, ['details', 'contact'], null);

                return stateNext;
            }

            const detailsNext = { ...(state.details as domain.XeroPurchaseOrderDetails), contact: supplier };
            const supplierEmailDetails = getEmailDetails(detailsNext, context);

            if (
                state.integrationCode &&
                [
                    domain.IntegrationCode.XeroPo,
                    domain.IntegrationCode.QBooksPo,
                    domain.IntegrationCode.XeroQuote,
                    domain.IntegrationCode.XeroInvoice,
                ].includes(state.integrationCode)
            ) {
                if (detailsNext.emailToSupplier) {
                    const emailToSupplier = { ...detailsNext.emailToSupplier };

                    const toExcluded = getSupplierToEmails(supplierPrev);
                    const toEmails = [...emailToSupplier.to, ...supplierEmailDetails.to].filter(
                        (email) => !toExcluded.includes(email)
                    );

                    const ccEmails = [...emailToSupplier.cc];

                    detailsNext.emailToSupplier = {
                        ...emailToSupplier,
                        to: uniq<string>(toEmails),
                        cc: uniq<string>(ccEmails),
                    };
                }

                if (supplier.brandingTheme) {
                    detailsNext.brandingTheme = supplier.brandingTheme;
                }
            }

            if (supplier?.purchase?.lineAmountType) {
                detailsNext.lineAmountType = supplier.purchase.lineAmountType;
                detailsNext.lineItems = detailsNext.lineItems.map((li) => {
                    li = { ...li };
                    resetAmountFields(li);

                    return li;
                });
            }

            const defaultCurrency = state.companyCurrency;
            const currentRequestCurrency = state.currency;
            const newCurrency = supplier.currency || defaultCurrency;

            if (currentRequestCurrency !== newCurrency) {
                state = set(state, 'currency', newCurrency);
                state = set(state, 'currencyExchangeRate', null);
            }

            return set(state, 'details', detailsNext);
        }

        case CHANGE_XERO_TERMS: {
            const { terms } = action.payload;

            const detailsNext = { ...(state.details as domain.XeroPurchaseOrderDetails), terms: terms };

            return set(state, 'details', detailsNext);
        }

        case CHANGE_XERO_LINE_ITEM_ITEM: {
            switch (action.payload.editMode) {
                case RequestEditMode.Reviewer:
                case RequestEditMode.Submitter:
                    return updateLineItem(state, action.payload.lineItemId, (li) => {
                        const details = state.details as domain.XeroSharedDetails;
                        const newItem = action.payload.newItem;

                        li.item = newItem || undefined;

                        if (newItem) {
                            const contact = details.contact as XeroContact;
                            const itemPurchaseDetails = newItem.purchaseDetails;
                            const itemSalesDetails = newItem.salesDetails;
                            const contactPurchaseDetails = contact?.purchase;
                            const contactSalesDetails = contact?.sales;

                            let account;
                            let taxCode;

                            switch (state.integrationCode) {
                                case domain.IntegrationCode.XeroPo: {
                                    account = itemPurchaseDetails?.account || contactPurchaseDetails?.account;
                                    taxCode =
                                        itemPurchaseDetails?.taxCode ||
                                        contactPurchaseDetails?.taxCode ||
                                        account?.taxCode;
                                    break;
                                }

                                case domain.IntegrationCode.XeroQuote: {
                                    account = itemSalesDetails?.account || contactSalesDetails?.account;
                                    taxCode =
                                        itemSalesDetails?.taxCode || contactSalesDetails?.taxCode || account?.taxCode;

                                    if (itemSalesDetails) {
                                        li.description = itemSalesDetails.description;
                                        li.unitPrice = itemSalesDetails.unitPrice;
                                        li.qty = itemSalesDetails.unitPrice != null ? 1 : undefined;
                                        resetAmountFields(li);
                                    }

                                    break;
                                }

                                case domain.IntegrationCode.XeroInvoice: {
                                    account = itemSalesDetails?.account || contactSalesDetails?.account;
                                    taxCode =
                                        itemSalesDetails?.taxCode || contactSalesDetails?.taxCode || account?.taxCode;

                                    if (itemSalesDetails) {
                                        li.description = itemSalesDetails.description;
                                        li.unitPrice = itemSalesDetails.unitPrice;
                                        li.qty = itemSalesDetails.unitPrice != null ? 1 : undefined;
                                        resetAmountFields(li);
                                    }

                                    break;
                                }

                                case domain.IntegrationCode.XeroBill: {
                                    account = itemPurchaseDetails?.account || contactPurchaseDetails?.account;
                                    taxCode =
                                        contactPurchaseDetails?.taxCode ||
                                        itemPurchaseDetails?.taxCode ||
                                        account?.taxCode;
                                    break;
                                }

                                default:
                                    throw errorHelpers.notSupportedError();
                            }

                            if (
                                itemPurchaseDetails &&
                                state.integrationCode !== domain.IntegrationCode.XeroQuote &&
                                state.integrationCode !== domain.IntegrationCode.XeroInvoice
                            ) {
                                li.description = itemPurchaseDetails.description;
                                li.unitPrice = itemPurchaseDetails.unitPrice;
                                li.qty = itemPurchaseDetails.unitPrice != null ? 1 : undefined;
                                li.discount = undefined;
                                resetAmountFields(li);
                            }

                            li.account = account;

                            if (taxCode) {
                                li.tax = getAdjustedTax(taxCode, li, state);
                                resetAmountFields(li);
                            }
                        }

                        return li;
                    });

                case RequestEditMode.Editor:
                case RequestEditMode.Approver:
                    return updateLineItem(state, action.payload.lineItemId, (li) => {
                        const newItem = action.payload.newItem;

                        li.item = newItem || undefined;

                        return li;
                    });

                default:
                    throw errorHelpers.assertNever(action.payload.editMode);
            }
        }

        case CHANGE_XERO_LINE_ITEM_ACCOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                const newAccount = action.payload.newAccount || undefined;

                li.account = newAccount;

                if (action.payload.recalculateTax && newAccount) {
                    const details = state.details as domain.XeroSharedDetails;

                    let itemDetails = li.item && li.item.purchaseDetails;

                    const contact = details.contact as XeroContact;

                    let contactTaxCode = contact?.purchase?.taxCode;

                    if (
                        state.integrationCode === domain.IntegrationCode.XeroQuote ||
                        state.integrationCode === domain.IntegrationCode.XeroInvoice
                    ) {
                        itemDetails = li.item && li.item.salesDetails;
                        contactTaxCode = contact?.sales?.taxCode;
                    }

                    const taxCode =
                        contactTaxCode || (itemDetails && itemDetails.taxCode) || newAccount.taxCode || null;

                    li.tax = getAdjustedTax(taxCode, li, state);
                    resetAmountFields(li);
                }

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_TAX:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.tax = action.payload.newTax || undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DESCRIPTION:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.description = action.payload.newDescription;

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_QTY:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.qty = action.payload.newQty != null ? action.payload.newQty : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_UNIT_PRICE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.unitPrice = action.payload.newUnitPrice != null ? action.payload.newUnitPrice : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DISCOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.discount = action.payload.newDiscount != null ? action.payload.newDiscount : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.discountAmount =
                    action.payload.newDiscountAmount != null ? action.payload.newDiscountAmount : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE:
            return updateLineItem(state, action.payload.lineItemId, (li, newDiscountType) => {
                li.discountType = action.payload.newDiscountType;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_TAX_AMOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.taxAmount = action.payload.newTaxAmount || undefined;

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_TRACKING_CATEGORY_VALUE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                // - update tracking category with new value
                // - remove tracking categories which are missing in XeroContext (have been disabled in Xero)
                li.tracking = action.payload.xeroContext.trackingCategories
                    .map((tc) => {
                        const oldTrackingItem = li.tracking.find((x) => x.category.id === tc.category.id);
                        const newValue =
                            tc.category.id === action.payload.trackingCategoryId
                                ? action.payload.newValue
                                : oldTrackingItem
                                  ? oldTrackingItem.value
                                  : null;

                        return {
                            category: tc.category,
                            value: newValue as Reference,
                        };
                    })
                    .filter((x) => x.value);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_CHECKED:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.checked = action.payload.checked;

                return li;
            });

        case CHANGE_XERO_LINE_AMOUNT_TYPE: {
            const newLineAmountType = action.payload.newLineAmountType;

            if (newLineAmountType === domain.LineAmountType.NoTax) {
                const details = state.details as domain.XeroSharedDetails;

                return mergeIn(state, ['details'], {
                    lineAmountType: newLineAmountType,
                    lineItems: details.lineItems.map((li) => {
                        li = { ...li };
                        li.tax = undefined;
                        resetAmountFields(li);

                        return li;
                    }),
                } as domain.XeroSharedDetails);
            } else {
                const details = state.details as domain.XeroSharedDetails;

                return mergeIn(state, ['details'], {
                    lineAmountType: newLineAmountType,
                    lineItems: details.lineItems.map((li) => {
                        li = { ...li };
                        resetAmountFields(li);

                        return li;
                    }),
                } as domain.XeroSharedDetails);
            }
        }

        case REORDER_XERO_LINE_ITEMS: {
            const details = state.details as domain.XeroSharedDetails;

            return setIn(
                state,
                ['details', 'lineItems'],
                arrayHelpers.arrayMove(details.lineItems, action.payload.oldIndex, action.payload.newIndex)
            );
        }

        case REMOVE_XERO_LINE_ITEM: {
            const details = state.details as domain.XeroSharedDetails;

            let lineItems: domain.XeroLineItem[] = removeArrayItem(
                details.lineItems,
                (li) => li.id === action.payload.lineItemId
            );

            if (lineItems.length === 0) {
                lineItems = [action.payload.newLineItem];
            }

            return setIn(state, ['details', 'lineItems'], lineItems);
        }

        case CLONE_XERO_LINE_ITEM: {
            const details = state.details as domain.XeroSharedDetails;

            return setIn(
                state,
                ['details', 'lineItems'],
                insertArrayItem(
                    details.lineItems,
                    action.payload.newLineItem,
                    details.lineItems.findIndex((li) => li.id === action.payload.lineItemId) + 1
                )
            );
        }

        case ADD_XERO_LINE_ITEM: {
            const details = state.details as domain.XeroSharedDetails;

            return setIn(state, ['details', 'lineItems'], addArrayItem(details.lineItems, action.payload.newLineItem));
        }

        case UPDATE_XERO_AMAXPAY_BP_DETAILS:
        case UPDATE_XERO_AIRWALLEX_BP_DETAILS:
        case UPDATE_XERO_BP_DETAILS: {
            return mergeIn(state, ['details'], action.payload.details);
        }

        default:
            return state;
    }
}
