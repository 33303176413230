import { useTheme } from '@approvalmax/theme';
import { Box, Button, Flex, Progress } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { useMedia } from 'react-use';

import { useSsoAuth, useSsoItems } from './SsoPanel.hooks';
import { SsoPanelProps } from './SsoPanel.types';

export const SsoPanel: FC<SsoPanelProps> = memo((props) => {
    const { disabled } = props;

    const { progress, onLogin } = useSsoAuth(props);
    const ssoItems = useSsoItems();

    const { theme } = useTheme();
    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    return (
        <Box color='midnight30' spacing='24' radius='xsmall'>
            {progress ? (
                <Flex inline justifyContent='center'>
                    <Box spacing='32 0'>
                        <Progress shape='circle' />
                    </Box>
                </Flex>
            ) : (
                <Flex container>
                    {ssoItems.map(({ provider, Image }) => (
                        <Flex inline size={isMobileScreen ? 24 : 12} justifyContent='center' key={provider}>
                            <Button onClick={() => onLogin(provider)} disabled={disabled} color='transparent' link>
                                <Image width='100%' />
                            </Button>
                        </Flex>
                    ))}
                </Flex>
            )}
        </Box>
    );
});

SsoPanel.displayName = 'SsoPanel';
