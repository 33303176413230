import { domain } from 'modules/data';

export enum ExpandedConditionType {
    AllVendors = 'AllVendors',
    ExactValues = 'ExactValues',
    NegativeExactValues = 'NegativeExactValues',
}

export interface NetSuiteVendorCreationConditionCellProps {
    lineId: string;
    rule: domain.MatrixRule;
    field: domain.Field;
    integrationCode: domain.IntegrationCode | null;
    condition: domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition;
    readonly?: boolean;
    hideCreateContactCheckbox?: boolean;
    onConditionChange(
        lineId: string,
        rule: domain.MatrixRule,
        field: domain.Field,
        newCondition: domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition
    ): void;
}
