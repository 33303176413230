import { Button, DropdownEditor, TextEditor } from '@approvalmax/ui';
import { PlainDataProvider } from 'modules/data-providers';
import { memo, useCallback, useEffect, useState } from 'react';
import { notificationService } from 'services/notification';
import { getApiUrl, resetApiUrl, setApiUrl, setApiUrlByEnv } from 'shared/data';

import {
    ApiSourceOption,
    apiSourceOptions,
    BackendOption,
    backendOptions,
    customOption,
    defaulApiSourceOption,
    mockOption,
} from './BackendSection.constants';
import { messages } from './BackendSection.messages';
import {
    ApiSourceContainer,
    Body,
    Buttons,
    CustomBackendSection,
    EditorsRow,
    Header,
    Root,
    Status,
    StatusText,
} from './BackendSection.styles';
import { BackendSectionProps } from './BackendSection.types';

const BackendDevToolsSection = memo<BackendSectionProps>((props) => {
    const { onNeedsReload } = props;

    const [backend, setBackend] = useState<BackendOption | null>(null);
    const [selectedApiUrl, setSelectedApiUrl] = useState('');
    const [customBackendId, setCustomBackendId] = useState<string>(customOption.id);
    const [customBackendApiSource, seCustomBackendApiSource] = useState<ApiSourceOption | null>(defaulApiSourceOption);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        const apiUrl = getApiUrl();
        const url = new URL(apiUrl);

        const backend = backendOptions.find((opt) => opt.text === url.hostname.split('.')[0]);

        setBackend(backend || mockOption || customOption || null);
        setSelectedApiUrl(url.hostname);
    }, []);

    const changeBackend = useCallback((newBackend: BackendOption | null) => {
        setModified(true);
        setBackend(newBackend);
    }, []);

    const applyBackend = useCallback(() => {
        if (!backend) {
            notificationService.showErrorToast(messages.selectBackendFirst);

            return;
        }

        switch (backend.id) {
            case customOption.id:
            case customBackendId:
                setApiUrl({
                    apiUrl: customBackendId,
                    apiSource: customBackendApiSource?.id,
                });
                break;

            case mockOption.id:
                setApiUrl({
                    apiUrl: mockOption.id,
                    apiSource: 'webApp',
                });
                break;

            default:
                setApiUrlByEnv({ env: backend.id, apiSource: 'all' });
        }

        const apiUrl = getApiUrl();
        const url = new URL(apiUrl);

        setSelectedApiUrl(url.hostname);
        setModified(false);
        onNeedsReload();
    }, [backend, customBackendApiSource, customBackendId, onNeedsReload]);

    const resetBackend = useCallback(() => {
        resetApiUrl({ apiSource: 'all' });

        const apiUrl = getApiUrl();
        const url = new URL(apiUrl);
        const backend = backendOptions.find((opt) => opt.text === url.hostname.split('.')[0]);

        setBackend(backend || mockOption || customOption || null);
        setSelectedApiUrl(url.hostname);
        onNeedsReload();
    }, [onNeedsReload]);

    const changeBackendApiSource = useCallback((newApiSource: ApiSourceOption | null) => {
        setModified(true);
        seCustomBackendApiSource(newApiSource);
    }, []);

    return (
        <Root>
            <Header>{messages.backend}</Header>

            <Body>
                <EditorsRow>
                    <PlainDataProvider items={backendOptions}>
                        <DropdownEditor value={backend} onChange={changeBackend} />
                    </PlainDataProvider>
                </EditorsRow>

                {backend && backend.id === customBackendId && (
                    <CustomBackendSection>
                        <ApiSourceContainer>
                            <PlainDataProvider items={apiSourceOptions}>
                                <DropdownEditor value={customBackendApiSource} onChange={changeBackendApiSource} />
                            </PlainDataProvider>
                        </ApiSourceContainer>

                        <TextEditor
                            focusOnMount
                            placeholder={messages.example}
                            value={customBackendId}
                            onChange={setCustomBackendId}
                        />
                    </CustomBackendSection>
                )}

                <Buttons>
                    <Status>
                        {messages.active}

                        <StatusText>{selectedApiUrl}</StatusText>
                    </Status>

                    <Button execute={applyBackend} disabled={!modified}>
                        {messages.apply}
                    </Button>

                    <Button execute={resetBackend}>{messages.reset}</Button>
                </Buttons>
            </Body>
        </Root>
    );
});

export default BackendDevToolsSection;
