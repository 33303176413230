import * as Sentry from '@sentry/browser';

import { isApiError } from './isApiError';

const isError = (error: Error | any): boolean => {
    try {
        switch ({}.toString.call(error)) {
            case '[object Error]':
                return true;

            case '[object Exception]':
                return true;

            case '[object DOMException]':
                return true;

            default:
                return error instanceof Error;
        }
    } catch (e) {
        return false;
    }
};

export const captureException = (e: any) => {
    if (isError(e)) {
        if (isApiError(e)) {
            // API errors are always tracked by API service.
            return;
        }

        Sentry.captureException(e);
        console.error(e);
    } else {
        Sentry.captureMessage(JSON.stringify(e));
    }
};
