import { ForwardedRef, forwardRef, memo, ReactElement, RefAttributes } from 'react';
import { RecoilRoot } from 'recoil';

import { Controller } from './components';
import Content from './components/Content/Content';
import { ChildrenComponents, SelectProps } from './Select.types';

/**
 * Select component allows a user to select one or more items from a list of options.
 */
const Select = memo(
    forwardRef(
        <Item extends Record<string, any>, Multiple extends boolean | undefined = undefined>(
            props: SelectProps<Item, Multiple>,
            ref: ForwardedRef<HTMLDivElement>
        ) => {
            return (
                <RecoilRoot>
                    <Content {...props} ref={ref} />
                </RecoilRoot>
            );
        }
    )
) as unknown as (<Item extends Record<string, any>, Multiple extends boolean | undefined = undefined>(
    props: SelectProps<Item, Multiple> & RefAttributes<HTMLDivElement>
) => ReactElement) &
    ChildrenComponents & { displayName?: string };

Select.Controller = Controller;

Select.displayName = 'Select';

export default Select;
