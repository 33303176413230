import { openSplitViewState } from '@approvalmax/ui/src/components';
import { browserHelpers } from '@approvalmax/utils';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { AddonsWarningBanner } from '../AddonsWarningBanner/AddonsWarningBanner';
import Announcement from '../Announcement/Announcement';
import LicensingNotification from '../LicensingNotification/LicensingNotification';
import MatchingSupportingBanner from '../MatchingSupportingBanner/MatchingSupportingBanner';

const Notifications = memo(() => {
    const openSplitView = useRecoilValue(openSplitViewState);

    if (browserHelpers.isMobile() || openSplitView) return null;

    return (
        <div>
            <Announcement />

            <AddonsWarningBanner />

            <MatchingSupportingBanner />

            <LicensingNotification />
        </div>
    );
});

export default Notifications;
