import { errorHelpers, mathService } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import {
    getSelectArgument1,
    getSelectArgument2,
    getSelectArgument3,
    getSelectArgument4,
    getSelectArgument5,
    getSelectArgument6,
} from 'modules/react-redux';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import * as qbooksAccountLineItem from '../../data/qbooks/QBooksAccountLineItem';
import { QBooksContext } from '../../data/qbooks/QBooksContext';
import { Context } from '../../reducers/page/contextReducer';
import { getContext } from '../pageSelectors';
import { getRequiredFields } from '../requestSelectors';
import { BillableAccountType, ExpandedQBooksAccountLineItem } from '../types/ExpandedQBooksAccountLineItem';
import { QBooksLineItemsSummary } from './lineItemSelectors';
import { getQBooksContext } from './qbooksSelectors';
import { calculateAccountLineItemsTaxSummary } from './taxSelectors';

export function isEmptyQBooksAccountLineItem(li: domain.QBooksAccountLineItem): boolean {
    return !li.account && li.amount == null && !li.description && !li.customer && !li.class;
}

function isValidQBooksAccountLineItem(
    li: domain.QBooksAccountLineItem,
    lineAmountType: domain.LineAmountType,
    context: Context,
    qbooksContext: QBooksContext,
    requiredFields: ReturnType<typeof getRequiredFields>
): boolean {
    if (isEmptyQBooksAccountLineItem(li)) {
        return true;
    }

    let hasAllMandatoryFields = Boolean(li.amount != null && li.account);

    if (lineAmountType !== domain.LineAmountType.NoTax && qbooksContext.isTrackingTaxesEnabled) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.taxCode);
    }

    if (
        qbooksContext.hasClassFeature &&
        qbooksContext.classField &&
        requiredFields.fieldIds.includes(qbooksContext.classField.id)
    ) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.class);
    }

    const customerField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.QBooksCustomer);

    if (qbooksContext.hasCustomerFeature && customerField && requiredFields.fieldIds.includes(customerField.id)) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.customer);
    }

    return hasAllMandatoryFields;
}

export const expandQBooksAccountLineItem: (
    lineAmountType: domain.LineAmountType,
    date: string,
    accountLineItem: domain.QBooksAccountLineItem,
    context: Context,
    qbooksContext: QBooksContext,
    requiredFields: ReturnType<typeof getRequiredFields>
) => ExpandedQBooksAccountLineItem = createCachedSelector(
    getSelectArgument1<domain.LineAmountType>(),
    getSelectArgument2<string>(),
    getSelectArgument3<domain.QBooksAccountLineItem>(),
    getSelectArgument4<Context>(),
    getSelectArgument5<QBooksContext>(),
    getSelectArgument6<ReturnType<typeof getRequiredFields>>(),
    (lineAmountType, date, li, context, qbooksContext, requiredFields): ExpandedQBooksAccountLineItem => {
        const isNew = qbooksAccountLineItem.isNewQBooksAccountLineItem(li);

        const accountType = qbooksContext.accounts.find((account) => account.id === li.account?.id)?.type || '';

        return {
            ...li,
            canBeBillable: accountType in BillableAccountType,
            isNew,
            empty: isEmptyQBooksAccountLineItem(li),
            valid: isValidQBooksAccountLineItem(li, lineAmountType, context, qbooksContext, requiredFields),
        };
    }
)((lineAmountType, date, lineItem) => lineItem.id);

export const getQBooksAccountLineItems: (
    state: State,
    request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest
) => ExpandedQBooksAccountLineItem[] = createSelector(
    (state: State, request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest) =>
        request.details.accountLineItems,
    (state: State, request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest) =>
        request.details.lineAmountType,
    (state: State, request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest) =>
        request.details.date,
    (state: State, request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest) =>
        getContext(state),
    (state: State, request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest) =>
        getQBooksContext(state),
    (state: State, request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest) =>
        getRequiredFields(state, request),
    (lineItems, lineAmountType, date, context, qbooksContext, requiredFields) => {
        if (!lineItems) {
            return [];
        }

        return lineItems.map((li) =>
            expandQBooksAccountLineItem(
                lineAmountType,
                date || moment.utc().startOf('day').toISOString(),
                li,
                context,
                qbooksContext,
                requiredFields
            )
        );
    }
);

export const getQBooksAccountLineItemById = (
    state: State,
    request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest,
    lineItemId: string
) => {
    const accountLineItem = getQBooksAccountLineItems(state, request).find((li) => li.id === lineItemId);

    if (!accountLineItem) {
        throw errorHelpers.notFoundError(`Account line item ${lineItemId}`);
    }

    return accountLineItem;
};

export function getQBooksAccountLineItemsSummary(
    state: State,
    request: domain.QBooksPoRequest | domain.QBooksBillRequest | domain.QBooksExpenseRequest
): QBooksLineItemsSummary {
    const qbooksContext = getQBooksContext(state);
    const lineItems = getQBooksAccountLineItems(state, request);
    const details = request.details;
    const lineAmountType = details.lineAmountType;

    let subtotalAmount = 0;

    lineItems.forEach((li) => {
        if (li.amount) {
            subtotalAmount = mathService.add(subtotalAmount, li.amount || 0);
        }
    });

    const taxSummary = calculateAccountLineItemsTaxSummary(
        lineAmountType,
        details.date || moment.utc().startOf('day').toISOString(),
        lineItems,
        qbooksContext
    );
    const defaultCurrency = selectors.company.getCompanyById(state, request.companyId).defaultCurrency;
    const nonDefaultCurrency = request.currency !== defaultCurrency;
    const totalAmount =
        lineAmountType == domain.LineAmountType.TaxInclusive
            ? subtotalAmount
            : mathService.add(subtotalAmount, taxSummary.totalTax);

    const exchangeRate = request.currencyExchangeRate || request.exchangeRate;

    const totalInDefaultCurrency = nonDefaultCurrency && exchangeRate ? totalAmount / exchangeRate : totalAmount;

    return {
        subtotalAmount,
        taxComponents: taxSummary.taxComponents,
        totalAmount,
        defaultCurrency,
        nonDefaultCurrency,
        totalInDefaultCurrency,
    };
}
