import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';
import { useMount } from 'react-use';
import { useSetRecoilState } from 'recoil';

import { workflowsWizardAllowedTemplatesState } from '../components/WorkflowWizardPopup/WorkflowWizardPopup.states';
import { possibleQbooksWorkflowsForWizard, possibleXeroWorkflowsForWizard } from '../constants';
import { useCanShowWorkflowWizardPopup } from './useCanShowWorkflowWizardPopup';

export const useWorkflowWizard = (
    templates: selectors.types.ExpandedTemplate[],
    company: selectors.types.ExpandedCompany
) => {
    const canShowWorkflowWizardPopup = useCanShowWorkflowWizardPopup();

    const setWorkflowsWizardState = useSetRecoilState(workflowsWizardAllowedTemplatesState);

    useMount(() => {
        if (!canShowWorkflowWizardPopup) {
            return;
        }

        if (
            !templates.every(
                (template) =>
                    template.isActual &&
                    !template.enabled &&
                    (!template.modifiedDate || moment(template.modifiedDate).isSame(template.createDate))
            )
        ) {
            return;
        }

        const allowedWizardTemplates: selectors.types.ExpandedTemplate[] = [];

        const allPossibleIntegrateWorkflows =
            company.integration?.integrationType === domain.IntegrationType.Xero
                ? possibleXeroWorkflowsForWizard
                : possibleQbooksWorkflowsForWizard;

        allPossibleIntegrateWorkflows.forEach((code) => {
            const template = templates.find((wf) => wf.integrationCode === code);

            if (template) {
                allowedWizardTemplates.push(template);
            }
        });

        if (allowedWizardTemplates.length > 0) {
            setWorkflowsWizardState(allowedWizardTemplates);
        }
    });
};
