import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent.SectionCaptureViaEmail', {
    title: 'Capture via Email',
    ocrEmailAddressSubtitle:
        'Set up email to receive files from suppliers and create requests in ApprovalMax automatically',
    ocrRequestInitialStatusSubtitle: 'Default status for requests created via email',
    draftStatusText: 'Draft',
    onApprovalStatusText: 'On review/On approval',
    placeholder: 'Enter the email',
});
