import { domain } from 'modules/data';

import { ExpandedCompany } from './types/Company';
import { ExpandedRequest } from './types/Request';

export const getStandaloneCommands = (
    request: domain.StandaloneRequest,
    company: ExpandedCompany,
    hasCreatableTemplate: boolean,
    myDecisions: ExpandedRequest['myDecisions'],
    flags: ExpandedRequest['flags']
): ExpandedRequest['commands'] => {
    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const canForceDecision =
        company.flags.isManager &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            isOnHold ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin === domain.RequestOrigin.ApprovalMax));
    const isMyDraft = flags.isAuthor && flags.status.isDraft;

    return {
        editPrimary: {
            hidden: true,
            disabled: true,
        },
        completeReview: {
            hidden: true,
            disabled: true,
        },
        approve: {
            hidden: !flags.isActiveApprover && !isOnHold,
            disabled: isOnHold,
        },
        reject: {
            hidden: !flags.isActiveApprover && !isOnHold,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision,
            disabled: false,
        },
        forceReview: {
            hidden: true,
            disabled: true,
        },
        forceReject: {
            hidden: !canForceDecision,
            disabled: request.statusV2 === domain.RequestStatusV2.Rejected,
        },
        revoke: {
            hidden: flags.status.isClosed || myDecisions.length === 0 || isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden: true,
            disabled: false,
        },
        editSecondary: {
            hidden: flags.status.isClosed || !flags.isAuthor || isOnHold,
            disabled: false,
        },
        delete: {
            hidden: !isMyDraft || isOnHold,
            disabled: false,
        },
        cancel: {
            hidden: !(flags.isAuthor && flags.status.isOpen) || isOnHold,
            disabled: false,
        },
        clone: {
            hidden: !hasCreatableTemplate,
            disabled: false,
        },
        clonePOToBill: {
            hidden: true,
            disabled: true,
        },
        cloneQuoteToSalesInvoice: {
            hidden: true,
            disabled: true,
        },
        reverse: {
            hidden: true,
            disabled: true,
        },
        returnToReview: {
            hidden: true,
            disabled: true,
        },
    };
};
