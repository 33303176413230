import './defaultCell.scss';

import { FC, PropsWithChildren } from 'react';
import bemFactory from 'react-bem-factory';

import { DataTableCellComponentProps, DataTablePadding } from './index';

const bem = bemFactory.block('ui-data-table-default-cell');

export interface DefaultCellProps extends PropsWithChildren, DataTableCellComponentProps {}

const DefaultCell: FC<DefaultCellProps> = (props) => {
    const {
        item,
        value,
        children,
        title,
        columnDefinition,
        qa,
        style,
        className,
        padding = DataTablePadding.Default,
    } = props;

    return (
        <div
            className={bem.add(className)(null, padding)}
            data-qa={qa}
            data-qa-id={columnDefinition.id}
            style={style}
            title={title}
        >
            {children || value}
        </div>
    );
};

export default DefaultCell;
