import './referenceCell.scss';

import { Reference } from '@approvalmax/types';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const i18nPrefix = 'reports.cells.ReferenceCell';

interface ReferenceCellProps {
    value: Reference | null;
    className?: string;
}

const bem = bemFactory.block('rpt-reference-cell');

const ReferenceCell: FC<ReferenceCellProps> = (props) => {
    const { value, className } = props;

    return <div className={bem.add(className)()}>{value ? value.text : null}</div>;
};

export default memo(ReferenceCell);
