import {
    UseTwoFaDisablingRequestEmailCodeResponse,
    UseTwoFaDisablingRequestEmailCodeResponseBackend,
} from './useTwoFaDisablingRequestEmailCode.types';

export const mapData = (
    data: UseTwoFaDisablingRequestEmailCodeResponseBackend
): UseTwoFaDisablingRequestEmailCodeResponse => ({
    stateId: data.StateId,
});
