import { errorHelpers } from '@approvalmax/utils';
import { schema } from 'normalizr';

import * as backend from '../../backend';
import { Field, FieldSystemPurpose } from '../Field';

export function mapFieldSystemPurpose(systemPurpose: backend.FieldSystemPurpose): FieldSystemPurpose {
    // TODO: get rid of this switch-case, there are 2 ways to do this:
    // 1. use getForwardEnumMapper and return FieldSystemPurpose.General as fallback
    // 2. do not map enum, use it as is
    switch (systemPurpose) {
        case backend.FieldSystemPurpose.General:
            return FieldSystemPurpose.General;

        case backend.FieldSystemPurpose.Amount:
            return FieldSystemPurpose.Amount;

        case backend.FieldSystemPurpose.Requester:
            return FieldSystemPurpose.Requester;

        case backend.FieldSystemPurpose.XeroTracking:
            return FieldSystemPurpose.XeroTracking;

        case backend.FieldSystemPurpose.XeroSupplier:
            return FieldSystemPurpose.XeroSupplier;

        case backend.FieldSystemPurpose.XeroCustomer:
            return FieldSystemPurpose.XeroCustomer;

        case backend.FieldSystemPurpose.XeroAccount:
            return FieldSystemPurpose.XeroAccount;

        case backend.FieldSystemPurpose.XeroItem:
            return FieldSystemPurpose.XeroItem;

        case backend.FieldSystemPurpose.XeroBranding:
            return FieldSystemPurpose.XeroBranding;

        case backend.FieldSystemPurpose.XeroTax:
            return FieldSystemPurpose.XeroTax;

        case backend.FieldSystemPurpose.XeroBankAccount:
            return FieldSystemPurpose.XeroBankAccount;

        case backend.FieldSystemPurpose.XeroManualExchangeRate:
            return FieldSystemPurpose.XeroManualExchangeRate;

        case backend.FieldSystemPurpose.QBooksVendor:
            return FieldSystemPurpose.QBooksVendor;

        case backend.FieldSystemPurpose.QBooksEmployee:
            return FieldSystemPurpose.QBooksEmployee;

        case backend.FieldSystemPurpose.QBooksPoItem:
            return FieldSystemPurpose.QBooksPoItem;

        case backend.FieldSystemPurpose.QBooksDepartment:
            return FieldSystemPurpose.QBooksDepartment;

        case backend.FieldSystemPurpose.QBooksDocumentStatus:
            return FieldSystemPurpose.QBooksDocumentStatus;

        case backend.FieldSystemPurpose.QBooksClass:
            return FieldSystemPurpose.QBooksClass;

        case backend.FieldSystemPurpose.QBooksCustomer:
            return FieldSystemPurpose.QBooksCustomer;

        case backend.FieldSystemPurpose.QBooksSalesItem:
            return FieldSystemPurpose.QBooksSalesItem;

        case backend.FieldSystemPurpose.QBooksCustom:
            return FieldSystemPurpose.QBooksCustom;

        case backend.FieldSystemPurpose.QBooksAccount:
            return FieldSystemPurpose.QBooksAccount;

        case backend.FieldSystemPurpose.QBooksTerm:
            return FieldSystemPurpose.QBooksTerm;

        case backend.FieldSystemPurpose.QBooksPaymentMethod:
            return FieldSystemPurpose.QBooksPaymentMethod;

        case backend.FieldSystemPurpose.QBooksPaymentType:
            return FieldSystemPurpose.QBooksPaymentType;

        case backend.FieldSystemPurpose.QBooksPaymentAccount:
            return FieldSystemPurpose.QBooksPaymentAccount;

        case backend.FieldSystemPurpose.QBooksPayeeVendor:
            return FieldSystemPurpose.QBooksPayeeVendor;

        case backend.FieldSystemPurpose.QBooksPayeeCustomer:
            return FieldSystemPurpose.QBooksPayeeCustomer;

        case backend.FieldSystemPurpose.QBooksPayeeEmployee:
            return FieldSystemPurpose.QBooksPayeeEmployee;

        case backend.FieldSystemPurpose.QBooksManualExchangeRate:
            return FieldSystemPurpose.QBooksManualExchangeRate;

        case backend.FieldSystemPurpose.QBooksCurrency:
            return FieldSystemPurpose.QBooksCurrency;

        case backend.FieldSystemPurpose.QBooksTax:
            return FieldSystemPurpose.QBooksTax;

        case backend.FieldSystemPurpose.NetSuiteSubsidiary:
            return FieldSystemPurpose.NetSuiteSubsidiary;

        case backend.FieldSystemPurpose.NetSuiteVendor:
            return FieldSystemPurpose.NetSuiteVendor;

        case backend.FieldSystemPurpose.NetSuiteAccount:
            return FieldSystemPurpose.NetSuiteAccount;

        case backend.FieldSystemPurpose.NetSuitePayee:
            return FieldSystemPurpose.NetSuitePayee;

        case backend.FieldSystemPurpose.NetSuiteExpenseCategory:
            return FieldSystemPurpose.NetSuiteExpenseCategory;

        case backend.FieldSystemPurpose.NetSuiteItem:
            return FieldSystemPurpose.NetSuiteItem;

        case backend.FieldSystemPurpose.NetSuiteDepartment:
            return FieldSystemPurpose.NetSuiteDepartment;

        case backend.FieldSystemPurpose.NetSuiteClass:
            return FieldSystemPurpose.NetSuiteClass;

        case backend.FieldSystemPurpose.NetSuiteLocation:
            return FieldSystemPurpose.NetSuiteLocation;

        case backend.FieldSystemPurpose.NetSuiteCurrency:
            return FieldSystemPurpose.NetSuiteCurrency;

        case backend.FieldSystemPurpose.NetSuiteCustom:
            return FieldSystemPurpose.NetSuiteCustom;

        case backend.FieldSystemPurpose.NetSuiteExpenseReportEmployee:
            return FieldSystemPurpose.NetSuiteEmployee;

        case backend.FieldSystemPurpose.NetSuiteCustomer:
            return FieldSystemPurpose.NetSuiteCustomer;

        case backend.FieldSystemPurpose.NetSuiteTaxCode:
            return FieldSystemPurpose.NetSuiteTaxCode;

        case backend.FieldSystemPurpose.DearSupplier:
            return FieldSystemPurpose.DearSupplier;

        case backend.FieldSystemPurpose.DearAccount:
            return FieldSystemPurpose.DearAccount;

        case backend.FieldSystemPurpose.DearProduct:
            return FieldSystemPurpose.DearProduct;

        case backend.FieldSystemPurpose.DearProductCategory:
            return FieldSystemPurpose.DearProductCategory;

        case backend.FieldSystemPurpose.DearProductFamily:
            return FieldSystemPurpose.DearProductFamily;

        case backend.FieldSystemPurpose.DearBrand:
            return FieldSystemPurpose.DearBrand;

        case backend.FieldSystemPurpose.DearLocation:
            return FieldSystemPurpose.DearLocation;

        case backend.FieldSystemPurpose.AirwallexBeneficiary:
            return FieldSystemPurpose.AirwallexBeneficiary;

        case backend.FieldSystemPurpose.AirwallexCurrency:
            return FieldSystemPurpose.AirwallexCurrency;

        case backend.FieldSystemPurpose.AirwallexPaymentFee:
            return FieldSystemPurpose.AirwallexPaymentFee;

        case backend.FieldSystemPurpose.AirwallexPaymentPurpose:
            return FieldSystemPurpose.AirwallexPaymentPurpose;

        case backend.FieldSystemPurpose.AirwallexPaymentMethod:
            return FieldSystemPurpose.AirwallexPaymentMethod;

        case backend.FieldSystemPurpose.AirwallexSourceAmount:
            return FieldSystemPurpose.AirwallexSourceAmount;

        case backend.FieldSystemPurpose.AirwallexFeeAmount:
            return FieldSystemPurpose.AirwallexFeeAmount;

        case backend.FieldSystemPurpose.AirwallexBatchPaymentStatus:
            return FieldSystemPurpose.AirwallexBatchPaymentStatus;

        case backend.FieldSystemPurpose.AirwallexPaymentItemStatus:
            return FieldSystemPurpose.AirwallexPaymentItemStatus;

        case backend.FieldSystemPurpose.AmaxPayBankAccount:
            return FieldSystemPurpose.AmaxPayBankAccount;

        case backend.FieldSystemPurpose.XeroDate:
            return FieldSystemPurpose.XeroDate;

        case backend.FieldSystemPurpose.XeroDueDate:
            return FieldSystemPurpose.XeroDueDate;

        case backend.FieldSystemPurpose.XeroLineDescription:
            return FieldSystemPurpose.XeroLineDescription;

        case backend.FieldSystemPurpose.XeroLineDiscount:
            return FieldSystemPurpose.XeroLineDiscount;

        case backend.FieldSystemPurpose.NoteForApprovers:
            return FieldSystemPurpose.NoteForApprovers;

        case backend.FieldSystemPurpose.EmailToSupplier:
            return FieldSystemPurpose.EmailToSupplier;

        case backend.FieldSystemPurpose.XeroCurrency:
            return FieldSystemPurpose.XeroCurrency;

        case backend.FieldSystemPurpose.XeroReference:
            return FieldSystemPurpose.XeroReference;

        case backend.FieldSystemPurpose.XeroAttachment:
            return FieldSystemPurpose.XeroAttachment;

        case backend.FieldSystemPurpose.XeroLineAmountType:
            return FieldSystemPurpose.XeroLineAmountType;

        case backend.FieldSystemPurpose.XeroLineQty:
            return FieldSystemPurpose.XeroLineQty;

        case backend.FieldSystemPurpose.XeroLineUnitPrice:
            return FieldSystemPurpose.XeroLineUnitPrice;

        case backend.FieldSystemPurpose.XeroLineTaxAmount:
            return FieldSystemPurpose.XeroLineTaxAmount;

        case backend.FieldSystemPurpose.XeroDeliveryAddress:
            return FieldSystemPurpose.XeroDeliveryAddress;

        case backend.FieldSystemPurpose.XeroContactName:
            return FieldSystemPurpose.XeroContactName;

        case backend.FieldSystemPurpose.XeroContactAccountNumber:
            return FieldSystemPurpose.XeroContactAccountNumber;

        case backend.FieldSystemPurpose.XeroContactPrimaryPersonFirstName:
            return FieldSystemPurpose.XeroContactPrimaryPersonFirstName;

        case backend.FieldSystemPurpose.XeroContactPrimaryPersonLastName:
            return FieldSystemPurpose.XeroContactPrimaryPersonLastName;

        case backend.FieldSystemPurpose.XeroContactPrimaryPersonEmail:
            return FieldSystemPurpose.XeroContactPrimaryPersonEmail;

        case backend.FieldSystemPurpose.XeroContactPersons:
            return FieldSystemPurpose.XeroContactPersons;

        case backend.FieldSystemPurpose.XeroContactPhone:
            return FieldSystemPurpose.XeroContactPhone;

        case backend.FieldSystemPurpose.XeroContactFax:
            return FieldSystemPurpose.XeroContactFax;

        case backend.FieldSystemPurpose.XeroContactMobile:
            return FieldSystemPurpose.XeroContactMobile;

        case backend.FieldSystemPurpose.XeroContactDirectDial:
            return FieldSystemPurpose.XeroContactDirectDial;

        case backend.FieldSystemPurpose.XeroContactSkypeNameOrNumber:
            return FieldSystemPurpose.XeroContactSkypeNameOrNumber;

        case backend.FieldSystemPurpose.XeroContactPostalAddress:
            return FieldSystemPurpose.XeroContactPostalAddress;

        case backend.FieldSystemPurpose.XeroContactStreetAddress:
            return FieldSystemPurpose.XeroContactStreetAddress;

        case backend.FieldSystemPurpose.XeroContactSalesAccount:
            return FieldSystemPurpose.XeroContactSalesAccount;

        case backend.FieldSystemPurpose.XeroContactSalesTrackingCategories:
            return FieldSystemPurpose.XeroContactSalesTrackingCategories;

        case backend.FieldSystemPurpose.XeroContactPurchaseAccount:
            return FieldSystemPurpose.XeroContactPurchaseAccount;

        case backend.FieldSystemPurpose.XeroContactPurchaseTrackingCategories:
            return FieldSystemPurpose.XeroContactPurchaseTrackingCategories;

        case backend.FieldSystemPurpose.XeroContactTaxNumber:
            return FieldSystemPurpose.XeroContactTaxNumber;

        case backend.FieldSystemPurpose.XeroContactCompanyNumber:
            return FieldSystemPurpose.XeroContactCompanyNumber;

        case backend.FieldSystemPurpose.XeroContactSalesTax:
            return FieldSystemPurpose.XeroContactSalesTax;

        case backend.FieldSystemPurpose.XeroContactPurchaseTax:
            return FieldSystemPurpose.XeroContactPurchaseTax;

        case backend.FieldSystemPurpose.XeroContactDefaultCurrency:
            return FieldSystemPurpose.XeroContactDefaultCurrency;

        case backend.FieldSystemPurpose.XeroContactBankAccountDetails:
            return FieldSystemPurpose.XeroContactBankAccountDetails;

        case backend.FieldSystemPurpose.XeroQuoteNumber:
            return FieldSystemPurpose.XeroQuoteNumber;

        case backend.FieldSystemPurpose.XeroQuoteTitle:
            return FieldSystemPurpose.XeroQuoteTitle;

        case backend.FieldSystemPurpose.XeroQuoteSummary:
            return FieldSystemPurpose.XeroQuoteSummary;

        case backend.FieldSystemPurpose.XeroQuoteTerms:
            return FieldSystemPurpose.XeroQuoteTerms;

        case backend.FieldSystemPurpose.XeroQuoteExpiry:
            return FieldSystemPurpose.XeroQuoteExpiry;

        case backend.FieldSystemPurpose.XeroDeliveryDate:
            return FieldSystemPurpose.XeroDeliveryDate;

        default:
            throw errorHelpers.assertNever(systemPurpose);
    }
}

export const mapNetSuiteField = (value: backend.NetSuiteField) => ({
    id: value.Field.Id,
    fieldName: value.Field.Name,
    fieldScriptId: value.Field.ScriptId,
    level: value.Level,
    referenceRecordType: value.ReferenceRecordType
        ? {
              id: value.ReferenceRecordType.Id,
              name: value.ReferenceRecordType.Name,
          }
        : null,
    type: value.Type,
    order: value.Order,
});

const mapWorkflowItem = (value: backend.FieldWorkflowItem) => ({
    workflowId: value.WorkflowId,
    isActive: value.IsActive,
    isEditable: value.IsEditable,
    isMandatory: value.IsMandatory,
});

export function mapField(value: backend.FieldAnswer): Field {
    return {
        id: value.FieldId,
        name: value.Name,
        companyId: (value as any).companyId, // Set externally by actions
        exactValues: (value?.ExactValues || []).map((x: any) => ({
            id: x.FieldValueId,
            text: x.Value,
        })),
        submitterApplicable: value.SubmitterApplicable,
        systemPurpose: mapFieldSystemPurpose(value.SystemPurpose),
        type: value.Type,
        netSuiteField: value.NetSuiteField ? mapNetSuiteField(value.NetSuiteField) : undefined,
        isArchived: value.IsArchived || false,
        workflows: Array.isArray(value.Workflows) ? value.Workflows.map(mapWorkflowItem) : undefined,
        referenceRecordFieldSystemPurpose: value.ReferenceRecordFieldSystemPurpose ?? null,
    };
}

const fieldSchema = new schema.Entity(
    'fields',
    {},
    {
        idAttribute: 'FieldId',
        processStrategy: mapField,
    }
);

export default fieldSchema;
