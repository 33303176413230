export interface AllFeatureTrialPaywallPopupProps {
    /**
     * Is the popup open
     */
    isOpen: boolean;

    /**
     * Callbak when the popup is closed
     */
    onClose: () => void;

    /**
     * List of companies
     */
    companies: TrialCompany[];

    /**
     * Initial company
     */
    initialCompanyId?: string;

    /**
     * Initial feature
     */
    initialFeature?: TrialFeature;

    /**
     * Show loading indicator and disable buttons
     */
    loading?: boolean;

    /**
     * Callback when user wants to upgrade subcription
     */
    onUpgradeSubcription: (companyId: string) => void;

    /**
     * Callback when user wants to start trial
     */
    onStartTrial: (companyId: string) => void;
}

export enum TrialIntegrationType {
    Xero = 'Xero',
    Quickbooks = 'Quickbooks',
}

export type CompanyPlans = 'standard' | 'advanced' | 'premium';

export interface TrialCompany {
    id: string;
    name: string;
    integrationType: TrialIntegrationType;
    plan?: CompanyPlans;
    isUpgradeSubcriptionVisible: boolean;
    hasAddOnCapture?: boolean;
    hasAddOnPay?: boolean;
    currency?: string;
    isSubscriptionActive?: boolean;
}

export enum TrialFeature {
    XeroAdvancedBillToPOMatching = 'XeroAdvancedBillToPOMatching',
    XeroAdvancedBudgetChecking = 'XeroAdvancedBudgetChecking',
    XeroAdvancedContactApprovals = 'XeroAdvancedContactApprovals',
    XeroAdvancedAutoApprovals = 'XeroAdvancedAutoApprovals',
    XeroAdvancedSalesQuoteApprovals = 'XeroAdvancedSalesQuoteApprovals',
    XeroAdvancedManualJournalApprovals = 'XeroAdvancedManualJournalApprovals',
    XeroAdvancedGoodsReceivedNotes = 'XeroAdvancedGoodsReceivedNotes',
    XeroAdvancedWorkflowVersionHistory = 'XeroAdvancedWorkflowVersionHistory',
    XeroAdvancedRequestReviewStep = 'XeroAdvancedRequestReviewStep',
    XeroAdvancedAccrualReports = 'XeroAdvancedAccrualReports',

    XeroPremiumBatchPaymentApprovals = 'XeroPremiumBatchPaymentApprovals',
    XeroPremiumPaymentsWithAirwallex = 'XeroPremiumPaymentsWithAirwallex',
    XeroPremiumStandAloneWorkflows = 'XeroPremiumStandAloneWorkflows',
    XeroPremiumPublicAPI = 'XeroPremiumPublicAPI',
    XeroPremiumPrioritySupport = 'XeroPremiumPrioritySupport',

    QboAdvancedBillToPOMatching = 'QboAdvancedBillToPOMatching',
    QboAdvancedVendorApprovals = 'QboAdvancedVendorApprovals',
    QboAdvancedAutoApprovals = 'QboAdvancedAutoApprovals',
    QboAdvancedJournalEntryApprovals = 'QboAdvancedJournalEntryApprovals',
    QboAdvancedGoodsReceivedNotes = 'QboAdvancedGoodsReceivedNotes',
    QboAdvancedWorkflowVersionHistory = 'QboAdvancedWorkflowVersionHistory',
    QboAdvancedRequestReviewStep = 'QboAdvancedRequestReviewStep',

    QboPremiumStandAloneWorkflows = 'QboPremiumStandAloneWorkflows',
    QboPremiumPrioritySupport = 'QboPremiumPrioritySupport',

    AddonsCapture = 'AddonsCapture',
    AddonsPay = 'AddonsPay',
}
