import {
    AllFeatureTrialPaywallPopup,
    TrialCompany,
    TrialIntegrationType,
} from '@approvalmax/ui/src/components/complex';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { FC, memo, useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { useActivateTrialOfAllFeatures } from 'shared/data';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { getInitialFeatureByOrigin, getPlanType } from './StartTrialPeriodPopup.helpers';
import { useWaitActivateTrial } from './StartTrialPeriodPopup.hooks';
import { StartTrialPeriodPopupProps } from './StartTrialPeriodPopup.types';

export const StartTrialPeriodPopup: FC<StartTrialPeriodPopupProps> = memo((props) => {
    const { data, isOpen, onClose } = props;

    const profile = useUserProfile();
    const awaitingActivationTrialCompanies = useSelector(selectors.company.getAwaitingActivationTrialCompanies);
    const subscriptions = useSelector(selectors.subscription.getAllSubscriptions);
    const { setCompanyId, companyId } = useWaitActivateTrial();

    const companies = useMemo(() => {
        const companies: TrialCompany[] = [];

        awaitingActivationTrialCompanies.forEach((company) => {
            if (company.integration?.integrationType === domain.IntegrationType.Xero) {
                const subscription = subscriptions.find(({ id }) => company.subscriptionId === id);

                companies.push({
                    id: company.id,
                    name: company.name,
                    integrationType: TrialIntegrationType.Xero,
                    plan: getPlanType(company?.licenseProductPlan),
                    isUpgradeSubcriptionVisible: company.author === profile.email && !subscription?.isFrozenForUpgrade,
                    hasAddOnCapture: subscription?.addonStatistics.some(
                        ({ feature }) => feature === domain.AddonTypes.Capture
                    ),
                    hasAddOnPay:
                        subscription?.addonStatistics.some(({ feature }) => feature === domain.AddonTypes.Pay) ||
                        company.betaFeatures.includes(domain.CompanyBetaFeature.PayOpenBankingUK),
                    isSubscriptionActive:
                        subscription?.status &&
                        subscription.status !== domain.SubscriptionStatus.Canceled &&
                        subscription.status !== domain.SubscriptionStatus.Expired,
                    currency: subscription?.nextBillingCurrency ?? undefined,
                });
            } else if (company.integration?.integrationType === domain.IntegrationType.QBooks) {
                companies.push({
                    id: company.id,
                    name: company.name,
                    integrationType: TrialIntegrationType.Quickbooks,
                    plan: getPlanType(company?.licenseProductPlan),
                    isUpgradeSubcriptionVisible: company.author === profile.email,
                });
            }
        });

        return companies;
    }, [awaitingActivationTrialCompanies, profile.email, subscriptions]);

    useMount(() => {
        amplitudeService.sendData('billing: land on all feature trial paywall', {
            origin:
                data.origin === 'accrual_reports_popup' ? 'billing: land on all feature trial paywall' : data.origin,
            org_id: data.company.id,
            current_plan: data.company.licenseProductPlan,
            gl: data.company.integration?.integrationType,
        });
    });

    const handleUpgradeSubcription = useCallback(
        (companyId: string) => {
            const company = awaitingActivationTrialCompanies.find((el) => el.id === companyId);

            if (!company?.subscriptionId) {
                return;
            }

            const isAdvanced =
                company?.licenseProductPlan === domain.CompanyPlan.XeroAdvanced ||
                company?.licenseProductPlan === domain.CompanyPlan.QBooksAdvanced;

            routingService.navigateToExternalUrlInNewTab(
                getAccountUrl(AccountPath.upgradeSubscription, {
                    subscriptionId: company?.subscriptionId,
                    plan: isAdvanced
                        ? domain.OrganisationPlanPrivilege.Premium
                        : domain.OrganisationPlanPrivilege.Advanced,
                    origin: 'wa_aft_paywall',
                })
            );
        },
        [awaitingActivationTrialCompanies]
    );

    const { mutateAsync: mutateAsyncActivateTrialOfAllFeatures, isLoading: isLoadingActivateTrial } =
        useActivateTrialOfAllFeatures();

    const handleStartTrial = useCallback(
        async (companyId: string) => {
            await mutateAsyncActivateTrialOfAllFeatures({
                data: {
                    organisationId: companyId,
                    accountId: profile.account?.accountId,
                },
                getParams: profile.account?.accountId
                    ? {
                          accountId: profile.account?.accountId,
                      }
                    : undefined,
            });

            setCompanyId(companyId);
        },
        [mutateAsyncActivateTrialOfAllFeatures, profile.account?.accountId, setCompanyId]
    );

    return (
        <AllFeatureTrialPaywallPopup
            companies={companies}
            isOpen={isOpen}
            onClose={onClose}
            onUpgradeSubcription={handleUpgradeSubcription}
            onStartTrial={handleStartTrial}
            initialFeature={getInitialFeatureByOrigin(data.company, data.origin)}
            initialCompanyId={data.company?.id}
            loading={isLoadingActivateTrial || !!companyId}
        />
    );
});

StartTrialPeriodPopup.displayName = 'StartTrialPeriodPopup';
