import { State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';

import { PasswordResetFinalStepModuleData } from '../reducers/page/moduleReducer';
import { getModule } from '../selectors/pageSelectors';
import { finishLoginFlow } from './login';

export const SEND_RESET_PASSWORDTFA_EMAIL_CODE = 'AUTH/SEND_RESET_PASSWORDTFA_EMAIL_CODE';
export const SEND_RESET_PASSWORDTFA_EMAIL_CODE_RESPONSE = 'AUTH/SEND_RESET_PASSWORDTFA_EMAIL_CODE_RESPONSE';
export const SEND_RESET_PASSWORDTFA_EMAIL_CODE_FAILURE = 'AUTH/SEND_RESET_PASSWORDTFA_EMAIL_CODE_FAILURE';
export const sendResetPasswordTFAEmailCode = () =>
    createAsyncAction({
        request: (state: State) =>
            createAction(SEND_RESET_PASSWORDTFA_EMAIL_CODE, {
                ...getModule<PasswordResetFinalStepModuleData>(state),
            }),

        response: async (request) => {
            await api.profile.sendResetPasswordTFAEmailCode({
                stateId: request.stateId,
            });

            return createAction(SEND_RESET_PASSWORDTFA_EMAIL_CODE_RESPONSE, {
                request,
            });
        },

        failure: (error, request) => createErrorAction(SEND_RESET_PASSWORDTFA_EMAIL_CODE_FAILURE, error, {}),
    });

export const VERIFY_RESET_PASSWORDTFA_CODE = 'AUTH/VERIFY_RESET_PASSWORDTFA_CODE';
export const VERIFY_RESET_PASSWORDTFA_CODE_RESPONSE = 'AUTH/VERIFY_RESET_PASSWORDTFA_CODE_RESPONSE';
export const VERIFY_RESET_PASSWORDTFA_CODE_FAILURE = 'AUTH/VERIFY_RESET_PASSWORDTFA_CODE_FAILURE';
export const verifyResetPasswordTFACode = (code: string) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(VERIFY_RESET_PASSWORDTFA_CODE, {
                ...getModule<PasswordResetFinalStepModuleData>(state),
            }),

        response: async (request) => {
            await api.profile.verifyResetPasswordTFACode({
                stateId: request.stateId,
                code,
            });

            return createAction(VERIFY_RESET_PASSWORDTFA_CODE_RESPONSE, {
                request,
            });
        },

        failure: (error, request) => createErrorAction(VERIFY_RESET_PASSWORDTFA_CODE_FAILURE, error, {}),

        rejectOnFailure: true,
    });

export const FINISH_RESET_PASSWORD = 'AUTH/FINISH_RESET_PASSWORD';
export const FINISH_RESET_PASSWORD_RESPONSE = 'AUTH/FINISH_RESET_PASSWORD_RESPONSE';
export const FINISH_RESET_PASSWORD_FAILURE = 'AUTH/FINISH_RESET_PASSWORD_FAILURE';
export const finishResetPassword = (newPassword: string) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(FINISH_RESET_PASSWORD, {
                ...getModule<PasswordResetFinalStepModuleData>(state),
            }),

        response: async (request) => {
            await api.profile.finishResetPassword({
                stateId: request.stateId,
                newPassword: newPassword,
            });

            return createAction(FINISH_RESET_PASSWORD_RESPONSE, {
                request,
            });
        },

        failure: (error, request) => createErrorAction(FINISH_RESET_PASSWORD_FAILURE, error, {}),

        rejectOnFailure: true,

        didDispatchResponse: async (request, response) => {
            finishLoginFlow({
                app: undefined,
                postAction: request.postAction,
            });
        },
    });

export type Action = ExtractActions<
    typeof finishResetPassword | typeof sendResetPasswordTFAEmailCode | typeof verifyResetPasswordTFACode
>;
