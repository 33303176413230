import './userCell.scss';

import { selectors, ui } from 'modules/common';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const i18nPrefix = 'reports.UserCell';

interface UserCellProps {
    value: selectors.types.ExpandedUser;
    className?: string;
}

const bem = bemFactory.block('rpt-user-cell');

const UserCell: FC<UserCellProps> = (props) => {
    const { value, className } = props;

    return (
        <ui.UserHover key={value.id} user={value} className={bem.add(className)()}>
            {value.displayName}
        </ui.UserHover>
    );
};

export default memo(UserCell);
