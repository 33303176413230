import { addArrayItem, immutable, ImmutableArray, removeArrayItem } from 'modules/immutable';

import { Action, CLONE_XERO_LINE_ITEM, LOAD_PAGE_DATA, REMOVE_XERO_LINE_ITEM } from '../../actions';

export interface XeroLineItemExtra {
    lineItemId: string;
    rootSplitItemId: string;
}

export type XeroLineItemExtraType = ImmutableArray<XeroLineItemExtra>;

const INITIAL_STATE: XeroLineItemExtraType = immutable<XeroLineItemExtra>([]);

export default function (state = INITIAL_STATE, action: Action): XeroLineItemExtraType {
    switch (action.type) {
        case CLONE_XERO_LINE_ITEM: {
            if (!state.some((x) => x.lineItemId === action.payload.lineItemId)) {
                // Root item, it should be added as well
                state = addArrayItem<XeroLineItemExtra>(state, {
                    lineItemId: action.payload.lineItemId,
                    rootSplitItemId: action.payload.lineItemId,
                });
            }

            const rootSplitItemId = state.find((x) => x.lineItemId === action.payload.lineItemId)!.rootSplitItemId;

            return addArrayItem<XeroLineItemExtra>(state, {
                lineItemId: action.payload.newLineItem.id,
                rootSplitItemId,
            });
        }

        case REMOVE_XERO_LINE_ITEM:
            return removeArrayItem(state, (li) => li.lineItemId === action.payload.lineItemId);

        case LOAD_PAGE_DATA:
            return immutable([]);

        default:
            return state;
    }
}
