import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { animated } from '@react-spring/web';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ItemProps } from './Item.types';

export const ClickableLayer = styled.button`
    &,
    > * {
        ${mixins.position.absoluteFill};
        cursor: pointer;
    }
`;

export const OpenIcon = styled.div`
    flex-shrink: 0;
    margin-left: 8px;
    display: flex;

    > svg {
        transition: ${theme.duration.fast}ms;
    }
`;

export const Label = styled.div`
    color: inherit;
    cursor: pointer;
    word-break: break-word;
`;

export const SubLabel = styled.div`
    margin-top: 2px;
    opacity: 0.6;
    transition: ${theme.duration.fast}ms;
`;

export const EndText = styled.div`
    opacity: 0.6;
    transition: ${theme.duration.fast}ms;
    white-space: nowrap;
`;

export const Labels = styled.div``;

export const SubItems = styled.div``;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
`;

export const StartIcon = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;

    > svg {
        transition: ${theme.duration.fast}ms;
        align-self: center;
    }

    button,
    a,
    input {
        position: relative;
        z-index: 1;
    }
`;

export const EndIcon = styled.div`
    flex-shrink: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;

    > svg {
        transition: ${theme.duration.fast}ms;
        align-self: center;
    }

    button,
    a,
    input {
        position: relative;
        z-index: 1;
    }
`;

export const Inner = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    transition: ${theme.duration.fast}ms;

    &:hover,
    &:focus,
    &.focused {
        color: ${theme.color.blue100};
    }
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    subLabelFontSize: RequiredMods['fontSize'],
    elementSpacing: RequiredMods['spacing'],
    verticalSpacing: RequiredMods['spacing'],
    iconSize: RequiredMods['iconSize']
) => css`
    ${Inner} {
        padding-left: ${elementSpacing}px;
        padding-right: ${elementSpacing}px;
    }

    ${Content} {
        padding-top: ${verticalSpacing}px;
        padding-bottom: ${verticalSpacing}px;
    }

    ${StartIcon} {
        margin-right: ${elementSpacing}px;
    }

    ${EndIcon},
    ${EndText},
            ${OpenIcon} {
        margin-left: ${elementSpacing}px;
    }

    ${OpenIcon} {
        > svg {
            ${mixins.iconSize(iconSize)};
        }
    }

    ${Label},
    ${EndText} {
        ${mixins.font('label', fontSize)};
    }

    ${SubLabel} {
        ${mixins.font('label', subLabelFontSize)};
    }
`;

const rootColorMixin = (
    bg: RequiredMods['color'],
    active: RequiredMods['color'],
    activeHover: RequiredMods['color'],
    selected: RequiredMods['color'],
    activeSelected: RequiredMods['color']
) => css`
    ${mods.active.true`
        ${mods.disabled.false`
            > ${Inner} {
                background-color: ${theme.color[bg]};
                color: ${theme.color[active]};

                &:hover,
                &:focus,
                &.focused  {
                    color: ${theme.color[activeHover]};
                }
            }

            ${StartIcon},
            ${EndIcon} {
                svg {
                    color: ${theme.color[active]};

                    &:hover {
                        color: ${theme.color[activeHover]};
                    }
                }
            }
        `};
    `};

    ${mods.selected.true`
        > ${Inner} {
            background-color: ${theme.color[selected]};

            ${mods.active.true`
                background-color: ${theme.color[activeSelected]};
            `}

            &:hover {
                color: ${theme.color[activeHover]};
            }
        }

        ${StartIcon},
            ${EndIcon} {
                svg {
                    color: ${theme.color[active]};

                    &:hover {
                        color: ${theme.color[activeHover]};
                    }
                }
            }
    `}
`;

export const Root = styled.li<
    StyledMods<Pick<ItemProps, 'divider' | 'open' | 'active' | 'selected' | 'size' | 'color' | 'disabled'>>
>`
    &:first-child {
        ${Inner} {
            border-top-left-radius: ${theme.radius.xsmall};
            border-top-right-radius: ${theme.radius.xsmall};
        }
    }

    &:last-child {
        ${Inner} {
            border-bottom-left-radius: ${theme.radius.xsmall};
            border-bottom-right-radius: ${theme.radius.xsmall};
        }
    }

    ${mods.size.xsmall(rootSizeMixin('xsmall', 'xxsmall', 8, 4, 12))};
    ${mods.size.small(rootSizeMixin('small', 'xsmall', 8, 8, 12))};
    ${mods.size.medium(rootSizeMixin('medium', 'small', 12, 12, 16))};
    ${mods.size.large(rootSizeMixin('large', 'medium', 16, 12, 20))};

    ${mods.color.silver80(rootColorMixin('silver80', 'midnight100', 'blue100', 'blue10', 'blue20'))};
    ${mods.color.midnight80(rootColorMixin('midnight80', 'white100', 'blue50', 'blue10', 'blue20'))};

    ${mods.disabled.true`
        > ${Inner} {
            &,
            &:hover {
                color: ${theme.color.midnight60};
            }
        }

        ${ClickableLayer} {
            cursor: default;
        }
    `};

    ${mods.divider.true`
        border-bottom: 1px solid ${theme.color.midnight40};

        &:last-child {
            border-bottom: none;
        }
    `};

    ${mods.divider.false`
        ${Inner} {
            border-radius: ${theme.radius.xsmall};
        }
    `};

    ${mods.open.true`
        ${OpenIcon} > svg {
            transform: rotate(180deg);
        }
    `};
`;

export const AnimatedBox = styled(animated.div as any)`
    overflow: hidden;
`;
