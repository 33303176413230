import { Mods } from '@approvalmax/theme';
import { Identifiable, SpacingProp, WidthProp } from '@approvalmax/types';
import { ComponentType, HTMLAttributes, ReactNode } from 'react';

import type { Cell, CellProps, ColumnHeader, ColumnHeaderProps } from './components';

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export interface BaseItem extends Identifiable {}

export interface TableProps<Item extends BaseItem>
    extends WidthProp,
        Mods<'bordered' | 'fontSize'>,
        HTMLAttributes<HTMLTableElement> {
    /** Columns definition */
    columns: ColumnDefinition<Item>[];

    /** Table data */
    data: Item[];

    /** Set header color */
    headerColor?: Mods<'color', 'silver80' | 'white100'>['color'];

    /** Sort callback function */
    onSort?: (columnId: string, newSortOrder: SortOrder) => void;

    /** Row click callback function */
    onRowClick?: (item: Item) => void;

    /**
     * Row ID.
     * Used as a row key and when selecting rows.
     * If not pass, then will be use value from "id" prop in Item.
     */
    getRowId?: (item: Item) => string;

    /** If set, the column with the checkboxes is activated. */
    checkedItems?: string[];

    /** Make rows invalid */
    invalidItems?: string[];

    /** Replace checkbox column */
    checkboxColumnCover?: ColumnDefinition<Item>;

    /** Checked items change callback function */
    onCheckedItemsChange?: (checkedItems: string[]) => void;

    /** Make header row is sticky */
    stickyHeader?: boolean;

    /** Make last row is sticky */
    stickyLastRow?: boolean;

    /** Virtualization */
    virtualized?: boolean;

    /** Make table reorderable: works with onReorderColumns and onReorderRows */
    reorderable?: boolean;

    /** Callback to reorder table columns: must have reorderable prop */
    onReorderColumns?: (reorderedColumns: ColumnDefinition<Item>[]) => void;

    /** Callback to reorder table rows: must have reorderable prop */
    onReorderRows?: (reorderRows: Item[]) => void;
}

export interface ColumnDefinition<Item extends BaseItem> extends SpacingProp, Mods<'textAlign'> {
    /** Column key. Used by default as a key on data props for get value. */
    id: string;

    /**
     * Column title: value of title attribute and value in header cell.
     * The Value of header cell may be overridden by "columnComponent" prop
     */
    name?: string;

    /** Function to override the way to get the value instead of used "id" prop */
    value?: (item: Item) => ReactNode;

    /** Function for overriding title attribute of cell */
    title?: (item: Item) => string;

    /** Component for overriding cell render */
    cellComponent?: ComponentType<CellProps<Item>>;

    /** Component for overriding header of column render */
    columnComponent?: ComponentType<ColumnHeaderProps<Item>>;

    /** Width of column */
    width?: number;

    /** Enable sort for column. See table's 'onSort' prop also. */
    sortable?: boolean;

    /** If set, displays the corresponding sort icon in the column header */
    sortOrder?: SortOrder;

    /**
     * Sticky column.
     */
    sticky?: 'left' | 'right';

    /**
     * Vertical align in cells.
     */
    verticalAlign?: 'top' | 'middle' | 'bottom';

    /**
     * Text align in header cell.
     */
    headerTextAlign?: 'left' | 'center' | 'right';

    /**
     * Vertical align in header cell.
     */
    headerVerticalAlign?: 'top' | 'middle' | 'bottom';
}

export interface ChildrenComponents {
    Cell: typeof Cell;
    ColumnHeader: typeof ColumnHeader;
}

export type TableDraggableItem<Item extends BaseItem> =
    | ({ type: 'row' } & Item)
    | ({ type: 'column' } & ColumnDefinition<Item>);
