import { Reference } from '@approvalmax/types';
import { compareHelpers, errorHelpers, intl, miscHelpers } from '@approvalmax/utils';
import camelCase from 'lodash/camelCase';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import * as common from 'modules/common';
import { constants, selectors } from 'modules/common';
import { domain, State, stateTree } from 'modules/data';
import createCachedSelector from 're-reselect';
import React, { FC } from 'react';
import { defineMessages } from 'react-intl';

import AnyColumnKind from '../config/columnKind';
import {
    getLineItemColumnDefinitionById,
    getLineItemColumnDefinitionByKind,
    getLineItemScopedColumns,
    LineItemReportColumnDefinition,
} from '../config/lineItem/columnLineItemDefinitions';
import columnLineItemPreviewOrder from '../config/lineItem/columnLineItemPreviewOrder';
import LineItemColumnKind from '../config/lineItem/lineItemColumnKind';
import getColumnDefinitions, {
    DataProviderProps,
    getColumnDefinitionById,
    getColumnDefinitionByKind,
    getScopedColumns,
    ReportColumnDefinition,
} from '../config/standard/columnDefinitions';
import ColumnKind from '../config/standard/columnKind';
import columnPreviewOrder from '../config/standard/columnPreviewOrder';
import { getFilterPreviewText } from '../data/filters';
import { DateRangeFilterRangeType } from '../data/filters/dateRangeFilter';
import FilterType from '../data/filters/FilterType';
import { ReferenceListFilter } from '../data/filters/referenceListFilter';
import { ReportConfig, ReportConfigColumn, SortingDirection } from '../data/reportConfig';
import { getReportType } from './pageSelectors';
import { GetCustomFieldsOptions } from './types';
import { ColumnTransfer, ExpandedReportConfig } from './types/ExpandedReportConfig';

const { TEMPLATE_ID_PREFIX } = constants.commonConstants;

const i18nPrefix = 'reports.selectors.reportConfigSelectors';
const messages = defineMessages({
    filterPreviewColumnText: {
        id: `${i18nPrefix}.filterPreviewColumnText`,
        defaultMessage: '{columnName} is {filterText}',
    },
    dateRangefilterPreviewColumnText: {
        id: `${i18nPrefix}.dateRangefilterPreviewColumnText`,
        defaultMessage: '{columnName} {filterText}',
    },
    resolutionTimeColumnName: {
        id: `${i18nPrefix}.resolutionTimeColumnName`,
        defaultMessage: 'How long decision took (seconds)',
    },
});

export const isCustomField = (kind: AnyColumnKind) => {
    return [LineItemColumnKind.QBooksCustomField, ColumnKind.CustomField, ColumnKind.QBooksCustomField].includes(kind);
};

function isNewReport(reportConfig: ReportConfig) {
    return reportConfig.id.startsWith(TEMPLATE_ID_PREFIX);
}

export function getDefinitionByKind(
    columnKind: ColumnKind | LineItemColumnKind,
    reportConfig: ReportConfig,
    company: selectors.types.ExpandedCompany
): ReportColumnDefinition | LineItemReportColumnDefinition {
    switch (reportConfig.reportType) {
        case domain.ReportType.Request:
            return getColumnDefinitionByKind(columnKind as ColumnKind, company);

        case domain.ReportType.LineItem:
            return getLineItemColumnDefinitionByKind(columnKind as any as LineItemColumnKind, reportConfig.reportCode!);

        default:
            break;
    }

    const colDef = getColumnDefinitions(company).find((column) => column.kind === columnKind);

    if (!colDef) {
        throw errorHelpers.notImplementedError();
    }

    return colDef;
}

function getDefinitionById(
    id: string,
    reportConfig: ReportConfig,
    company: selectors.types.ExpandedCompany
): ReportColumnDefinition | LineItemReportColumnDefinition {
    switch (reportConfig.reportType) {
        case domain.ReportType.Request:
            return getColumnDefinitionById(id, company);

        case domain.ReportType.LineItem:
            return getLineItemColumnDefinitionById(id, reportConfig.reportCode!);

        default:
            break;
    }

    const colDef = getColumnDefinitions(company).find((column) => column.id === id);

    if (!colDef) {
        throw errorHelpers.notImplementedError();
    }

    return colDef;
}

export function getUniqColumnsInScope(
    reportConfig: ReportConfig,
    company: selectors.types.ExpandedCompany,
    skipFiltering = false
): ReportConfigColumn[] {
    if (skipFiltering) {
        // skipFiltering flag is only used to display the table,
        // so that we don't filter columns that are not currently accessible,
        // but were accessed at the time the report was created.
        return getUniqColumns(reportConfig.columns);
    } else {
        const columns = getColumnsInScope(reportConfig, company);

        return getUniqColumns(columns);
    }
}

export const getUniqColumns = (columns: ReportConfigColumn[]) => {
    return uniqBy(columns, (value) => (isCustomField(value.kind) ? value.id : value.kind));
};

export function getColumnsInScope(
    reportConfig: ReportConfig,
    company: selectors.types.ExpandedCompany
): ReportConfigColumn[] {
    switch (reportConfig.reportType) {
        case domain.ReportType.Request:
            return getScopedColumns(reportConfig.columns, company);

        case domain.ReportType.LineItem:
            return getLineItemScopedColumns(reportConfig.columns, reportConfig.reportCode!, company);

        default:
            throw errorHelpers.assertNever(reportConfig.reportType);
    }
}

export const expandReportConfig: (
    reportConfig: ReportConfig,
    companyTeam: selectors.types.ExpandedCompanyUser[],
    company: selectors.types.ExpandedCompany
) => ExpandedReportConfig = createCachedSelector(
    (reportConfig: ReportConfig) => reportConfig,
    (reportConfig: ReportConfig, companyTeam: selectors.types.ExpandedCompanyUser[]) => companyTeam,
    (
        reportConfig: ReportConfig,
        companyTeam: selectors.types.ExpandedCompanyUser[],
        company: selectors.types.ExpandedCompany
    ) => company,
    (reportConfig, companyTeam, company) => {
        return {
            ...reportConfig,
            isNew: isNewReport(reportConfig),
            isValid: Boolean(reportConfig.name),
            filterPreviews: getUniqColumnsInScope(reportConfig, company)
                .sort((a, b) => {
                    let indexA = 0;
                    let indexB = 0;

                    if (reportConfig.reportType === domain.ReportType.Request) {
                        indexA = columnPreviewOrder.indexOf(a.kind as ColumnKind);
                        indexB = columnPreviewOrder.indexOf(b.kind as ColumnKind);
                    } else {
                        indexA = columnLineItemPreviewOrder.indexOf(a.kind as LineItemColumnKind);
                        indexB = columnLineItemPreviewOrder.indexOf(b.kind as LineItemColumnKind);
                    }

                    if (indexA === -1) {
                        indexA = Number.MAX_VALUE;
                    }

                    if (indexB === -1) {
                        indexB = Number.MAX_VALUE;
                    }

                    return compareHelpers.numberComparator2Asc(indexA, indexB);
                })
                .map((c) => {
                    const filterText = getFilterPreviewText(c.filter, {
                        companyTeam,
                    });

                    if (!filterText) {
                        return null;
                    }

                    switch (c.filter.type) {
                        case FilterType.DateRange:
                            if (c.filter.rangeType === DateRangeFilterRangeType.Custom) {
                                return intl.formatMessage(messages.dateRangefilterPreviewColumnText, {
                                    columnName: c.name,
                                    filterText,
                                });
                            }

                            return intl.formatMessage(messages.filterPreviewColumnText, {
                                columnName: c.name,
                                filterText,
                            });

                        default:
                            return intl.formatMessage(messages.filterPreviewColumnText, {
                                columnName: c.name,
                                filterText,
                            });
                    }
                })
                .filter((x) => x),
        } as ExpandedReportConfig;
    }
)((reportConfig: ReportConfig) => reportConfig?.id);

export function findReportConfigById(state: State, reportConfigId: string): ReportConfig | null {
    const reportConfig = state.entities.reportConfigs[reportConfigId];

    if (!reportConfig) {
        return null;
    }

    const company = selectors.navigation.getActiveCompany(state);
    const companyTeam = selectors.company.getCompanyTeam(state, company);

    return expandReportConfig(reportConfig, companyTeam, company);
}

export function getReportConfigById(state: State, reportConfigId: string): ReportConfig {
    const reportConfig = state.entities.reportConfigs[reportConfigId];

    if (!reportConfig) {
        throw errorHelpers.notFoundError();
    }

    const company = selectors.navigation.getActiveCompany(state);
    const companyTeam = selectors.company.getCompanyTeam(state, company);

    return expandReportConfig(reportConfig, companyTeam, company);
}

export function getReportSelectorTypes(type: domain.ReportType, integrationType?: domain.IntegrationType) {
    switch (type) {
        case domain.ReportType.Request:
            return [domain.ReportCode.Request];

        case domain.ReportType.LineItem:
            switch (integrationType) {
                case domain.IntegrationType.Xero:
                    return [
                        domain.ReportCode.XeroBillInvoiceLine,
                        domain.ReportCode.XeroQuoteLine,
                        domain.ReportCode.XeroSalesInvoiceLine,
                        domain.ReportCode.XeroPurchaseOrderLine,
                        domain.ReportCode.XeroAPCreditNoteLine,
                        domain.ReportCode.XeroARCreditNoteLine,
                        domain.ReportCode.AirwallexXeroBatchPaymentLine,
                        domain.ReportCode.XeroAmaxPayBatchPaymentLine,
                    ];

                case domain.IntegrationType.QBooks:
                    return [
                        domain.ReportCode.QBooksBillInvoiceLine,
                        domain.ReportCode.QBooksExpenseLine,
                        domain.ReportCode.QBooksPurchaseOrderLine,
                        domain.ReportCode.QBooksSalesInvoiceLine,
                    ];

                case domain.IntegrationType.NetSuite:
                    return [domain.ReportCode.NetSuiteBillLine];

                case domain.IntegrationType.Dear:
                    return [];

                case domain.IntegrationType.None:
                    throw errorHelpers.notSupportedError(integrationType);

                case undefined:
                    return [];

                default:
                    throw errorHelpers.assertNever(integrationType);
            }

        default:
            throw errorHelpers.assertNever(type);
    }
}

export function getReportTypeByCode(code: domain.ReportCode) {
    switch (code) {
        case domain.ReportCode.Request:
            return domain.ReportType.Request;

        case domain.ReportCode.AirwallexXeroBatchPaymentLine:
        case domain.ReportCode.DearPurchaseOrderLine:
        case domain.ReportCode.NetSuiteBillLine:
        case domain.ReportCode.NetSuitePurchaseOrderLine:
        case domain.ReportCode.NetSuiteSalesOrderLine:
        case domain.ReportCode.QBooksBillInvoiceLine:
        case domain.ReportCode.QBooksExpenseLine:
        case domain.ReportCode.QBooksPurchaseOrderLine:
        case domain.ReportCode.QBooksSalesInvoiceLine:
        case domain.ReportCode.XeroAPCreditNoteLine:
        case domain.ReportCode.XeroARCreditNoteLine:
        case domain.ReportCode.XeroBillInvoiceLine:
        case domain.ReportCode.XeroPurchaseOrderLine:
        case domain.ReportCode.XeroQuoteLine:
        case domain.ReportCode.XeroSalesInvoiceLine:
        case domain.ReportCode.XeroAmaxPayBatchPaymentLine:
            return domain.ReportType.LineItem;

        default:
            throw errorHelpers.assertNever(code);
    }
}

export const getReportConfigsByCompanyId: (state: State, companyId: string) => ExpandedReportConfig[] =
    createCachedSelector(
        (state: State, companyId: string) => state.entities.reportConfigs,
        (state: State, companyId: string) => companyId,
        (state: State, companyId: string) => selectors.company.getCompanyById(state, companyId),

        (state: State, companyId: string) => {
            const company = selectors.company.getCompanyById(state, companyId);

            return selectors.company.getCompanyTeam(state, company);
        },
        getReportType,
        (reportConfigs, companyId, company, companyTeam, reportType: domain.ReportType) => {
            return filter(reportConfigs, (t) => t.companyId === companyId && t.reportType === reportType)
                .map((r) => expandReportConfig(r, companyTeam, company))
                .sort(compareHelpers.comparatorFor<ExpandedReportConfig>(compareHelpers.stringComparator2AscI, 'name'));
        }
    )((state: State, companyId: string) => companyId);

export function fillFunctionalProperties(
    columnConfig: ReportConfigColumn,
    reportConfig: ReportConfig,
    company: selectors.types.ExpandedCompany
): ReportConfigColumn {
    let colDef = getDefinitionByKind(columnConfig.kind, reportConfig, company);

    if (
        colDef.kind !== LineItemColumnKind.Tracking1 &&
        colDef.kind !== LineItemColumnKind.Tracking2 &&
        colDef.kind !== ColumnKind.LineItemTrackings1 &&
        colDef.kind !== ColumnKind.LineItemTrackings2 &&
        colDef.kind !== LineItemColumnKind.QBooksCustomField &&
        colDef.kind !== ColumnKind.CustomField
    ) {
        return { ...columnConfig, name: colDef.name as string, title: colDef.title as string };
    }

    return columnConfig;
}

export function getExpandedColumnsInScope(
    state: State,
    reportConfig: ReportConfig
): Array<{
    column: ReportConfigColumn;
    columnDefinition: ReportColumnDefinition | LineItemReportColumnDefinition;
    options?: Reference[];
    dataProvider?: React.ComponentClass<DataProviderProps> | FC<DataProviderProps>;
    field?: domain.Field;
}> {
    const companyId = reportConfig.companyId;
    const company = selectors.company.getCompanyById(state, companyId);
    const trackingFields = selectors.field.getXeroTrackingFields(state, companyId);
    const reportType = getReportType(state);

    return (
        reportType === domain.ReportType.LineItem
            ? getLineItemScopedColumns(reportConfig.columns, reportConfig.reportCode!, company)
            : getScopedColumns(reportConfig.columns, company)
    )
        .map((c) => {
            let columnDefinition: ReportColumnDefinition | LineItemReportColumnDefinition | null = [
                LineItemColumnKind.QBooksCustomField,
                ColumnKind.CustomField,
                ColumnKind.QBooksCustomField,
            ].includes(c.kind)
                ? getDefinitionByKind(c.kind, reportConfig, company)
                : getDefinitionById(c.id, reportConfig, company);

            let field: domain.Field | undefined;

            if (columnDefinition.fieldSystemPurpose) {
                // We must match a field or skip the column completely
                if (columnDefinition.fieldSystemPurpose.includes(domain.FieldSystemPurpose.XeroTracking)) {
                    // XeroTracking is handled based on active fields. If we can't find a field to match, we skip the column
                    const index = [ColumnKind.LineItemTrackings1, LineItemColumnKind.Tracking1].includes(c.kind)
                        ? 0
                        : 1;

                    field = trackingFields[index];

                    if (!field) {
                        return null;
                    }
                } else {
                    field = columnDefinition.fieldSystemPurpose
                        .map((systemPurpose) => {
                            return selectors.field.getFieldsBySystemPurpose(state, companyId, systemPurpose)[0];
                        })
                        .find((x) => Boolean(x));

                    if (
                        columnDefinition.fieldSystemPurpose.includes(domain.FieldSystemPurpose.QBooksDepartment) ||
                        columnDefinition.fieldSystemPurpose.includes(domain.FieldSystemPurpose.QBooksClass) ||
                        columnDefinition.fieldSystemPurpose.includes(domain.FieldSystemPurpose.QBooksCustomer) ||
                        columnDefinition.fieldSystemPurpose.includes(
                            domain.FieldSystemPurpose.QBooksAccount // TODO: Temporary consider it OK, before bills are live
                        )
                    ) {
                        // QBO Classes, Locations and Customers might be disabled
                        if (!field) {
                            return null;
                        }
                    }

                    // otherwise, we must find a field.
                    if (!field) {
                        throw errorHelpers.notFoundError(
                            `Failed to find a field with systemPurpose one of ${columnDefinition.fieldSystemPurpose.join(
                                ', '
                            )}`
                        );
                    }
                }
            }

            return {
                column: fillFunctionalProperties(c, reportConfig, company),
                columnDefinition,
                options: columnDefinition.getOptions ? columnDefinition.getOptions(state, c, companyId) : undefined,
                dataProvider: columnDefinition.dataProvider,
                field: field!,
            };
        })
        .filter((x) => x)
        .map((x) => x!);
}

const defaultXeroContactColumnsSort: ColumnKind[] = [
    ColumnKind.XeroContactName,
    ColumnKind.Resolution,
    ColumnKind.CreationDate,
    ColumnKind.Currency,
    ColumnKind.ParticipantsApproved,
    ColumnKind.ParticipantsNonResponded,
];

const defaultQBooksVendorColumnsSort: ColumnKind[] = [
    ColumnKind.QBooksVendorName,
    ColumnKind.Resolution,
    ColumnKind.CreationDate,
    ColumnKind.Currency,
    ColumnKind.ParticipantsApproved,
    ColumnKind.ParticipantsNonResponded,
];

function sortColumns(columns: ReportConfigColumn[], orderArray: ColumnKind[]) {
    columns.sort((column1, column2) => {
        const columnOneIndex = orderArray.findIndex((c) => c === column1.kind);
        const columnTwoIndex = orderArray.findIndex((c) => c === column2.kind);

        if (columnOneIndex !== -1 && columnTwoIndex !== -1) {
            return columnOneIndex - columnTwoIndex;
        }

        if (columnOneIndex !== -1 && columnTwoIndex === -1) {
            return -1;
        }

        if (columnOneIndex === -1 && columnTwoIndex !== -1) {
            return 1;
        }

        return 0;
    });
}

export function getReportConfigTransfer(state: State, reportConfig: ReportConfig, isNewReportCreation = false) {
    let columnsTransfer: any = {};

    const company = selectors.navigation.getActiveCompany(state);

    const companyTeam = selectors.company.getCompanyTeam(state, company);

    const type = getReportType(state);
    const integrationType = company.integration?.integrationType;

    let viewingOrder = 0;

    const templateId = reportConfig.columns.find((column) => {
        return column.kind === ColumnKind.TemplateId;
    });

    let integrationCodes: string[] = [];

    if (templateId && templateId.filter.type === FilterType.ReferenceList) {
        integrationCodes = templateId.filter.values.map((value) => value.id);
    }

    // take only visible columns or with filter value
    const columns = getUniqColumnsInScope(reportConfig, company).filter((column) => {
        return getFilterPreviewText(column.filter, { companyTeam }) || column.visible;
    });

    if (isNewReportCreation) {
        const isXeroContactReport =
            integrationCodes.length === 1 && integrationCodes.includes(domain.IntegrationCode.XeroContact);
        const isQBooksVendorReports =
            integrationCodes.length === 1 && integrationCodes.includes(domain.IntegrationCode.QBooksVendor);

        if (isXeroContactReport) {
            sortColumns(columns, defaultXeroContactColumnsSort);
        }

        if (isQBooksVendorReports) {
            sortColumns(columns, defaultQBooksVendorColumnsSort);
        }
    }

    columns.forEach((column) => {
        const colDef = isCustomField(column.kind)
            ? getDefinitionByKind(column.kind, reportConfig, company)
            : getDefinitionById(column.id, reportConfig, company);

        if (column.kind === ColumnKind.CustomField) {
            let colTransfer: any = {
                id: column.id,
                fieldId: column.id,
                name: column.name,
                viewable: column.visible,
                viewingOrder: viewingOrder++,
                filteringValue: colDef.getFilterTransfer!(column.filter as ReferenceListFilter),
            };

            if (column.sorting) {
                colTransfer.sortingOrder = 0;
                colTransfer.sortingType = column.sorting === SortingDirection.Ascending ? 0 : 1;
            } else {
                colTransfer.sortingOrder = null;
            }

            if (!columnsTransfer.customFields) {
                columnsTransfer.customFields = [];
            }

            columnsTransfer.customFields.push(colTransfer);
        } else if (column.kind === ColumnKind.QBooksCustomField) {
            let colTransfer: any = {
                fieldId: column.id,
                name: column.name,
                viewable: column.visible,
                viewingOrder: viewingOrder++,
                filteringValue: null,
            };

            if (column.sorting) {
                colTransfer.sortingOrder = 0;
                colTransfer.sortingType = column.sorting === SortingDirection.Ascending ? 0 : 1;
            }

            if (!columnsTransfer.qBooksCustomFields) {
                columnsTransfer.qBooksCustomFields = [];
            }

            if (column.id !== ColumnKind.QBooksCustomField) {
                columnsTransfer.qBooksCustomFields.push(colTransfer);
            }
        } else if (column.kind === ColumnKind.ResolutionTime) {
            const colTransfer: ColumnTransfer = {
                name: intl.formatMessage(messages.resolutionTimeColumnName),
                viewable: column.visible,
                viewingOrder: viewingOrder++,
                filteringValue: colDef.filterType !== FilterType.None ? colDef.getFilterTransfer!(column.filter) : null,
                sortingOrder: null,
                sortingType: null,
            };

            if (column.sorting) {
                colTransfer.sortingOrder = 0;
                colTransfer.sortingType = column.sorting === SortingDirection.Ascending ? 0 : 1;
            }

            columnsTransfer[camelCase(column.kind)] = colTransfer;
        } else {
            miscHelpers.invariant(
                colDef.filterType === FilterType.None || colDef.getFilterTransfer,
                'getFilterTransfer is mandatory for columns with filter.'
            );

            let colTransfer: any = {
                name: column.name,
                viewable: column.visible,
                viewingOrder: viewingOrder++,
                filteringValue: colDef.filterType !== FilterType.None ? colDef.getFilterTransfer!(column.filter) : null,
            };

            if (column.sorting) {
                colTransfer.sortingOrder = 0;
                colTransfer.sortingType = column.sorting === SortingDirection.Ascending ? 0 : 1;
            } else {
                colTransfer.sortingOrder = null;
            }

            columnsTransfer[camelCase(column.kind)] = colTransfer;
        }
    });

    return {
        companyId: reportConfig.companyId,
        name: reportConfig.name,
        types: getReportSelectorTypes(type, integrationType),
        settings: {
            ...columnsTransfer,
            type: reportConfig.reportCode,
        },
    };
}

export const getCustomFields = (state: stateTree.State, options: GetCustomFieldsOptions) => {
    const { companyId, reportType, integrationType } = options;

    const xeroTrackingFields = common.selectors.field.getXeroTrackingFields(state, companyId);
    const standaloneFields = common.selectors.field.getFieldsBySystemPurpose(
        state,
        companyId,
        domain.FieldSystemPurpose.General
    );
    const qBooksCustomFields = common.selectors.field.getFieldsBySystemPurpose(
        state,
        companyId,
        domain.FieldSystemPurpose.QBooksCustom
    );

    const fields = {
        xeroTrackingFields,
        standaloneFields,
        qBooksCustomFields: [] as domain.Field[],
    };

    if (reportType !== domain.ReportType.LineItem && integrationType === domain.IntegrationType.QBooks) {
        fields.qBooksCustomFields = qBooksCustomFields;
    }

    return fields;
};
