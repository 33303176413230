import { RequestDataParams } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { BudgetsToggleSyncData } from './useToggleBudgetsSync.types';

export const useToggleBudgetsSync = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<RequestDataParams<BudgetsToggleSyncData>>(budgetsApiPaths.toggleBudgetsSync, {
        mutationOptions: {
            onSettled: async (_data, _error, requestData, cont) => {
                await queryClient.invalidateQueries([
                    routerHelpers.pathToUrl(budgetsApiPaths.select, requestData.pathParams),
                    { companyId: requestData.data.companyId },
                ]);
            },
        },
    });
};
