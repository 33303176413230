import './workflowSection.scss';

import { Button, CheckboxEditor, DropdownEditor, Field, RadioGroupEditor, TextAreaEditor } from '@approvalmax/ui';
import { arrayHelpers, compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { PlainDataProvider } from 'modules/data-providers';
import { ConnectedProps } from 'modules/react-redux';
import templateAutomation, { TemplateBackup } from 'pages/workflows/automation';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { connect } from 'react-redux';
import { notificationService } from 'services/notification';

import { copyRules, copyWorkflow, loadCompanyTemplates, restoreWorkflow } from '../actions';
import { getDevToolsPopupData } from '../selectors/moduleSelectors';
import Logger, { LoggerMessage, LoggerSeverity } from '../utils/Logger';

const bem = bemFactory.block('automation-workflow-section');

const i18nPrefix = 'automation.WorkflowSection';

interface OwnProps {
    className?: string;
    onNeedsReload(): void;
}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

enum ActiveTab {
    TemplateCopy = 'TemplateCopy',
    RulesCopy = 'RulesCopy',
    Logs = 'Logs',
}

interface OwnState {
    fields: domain.Field[];
    fieldsLoading: boolean;
    fromCompany: selectors.types.ExpandedCompany | null;
    fromTemplate: domain.Template | null;
    fromUser: domain.User | null;
    fromStepIndex: number | null;
    toCompany: selectors.types.ExpandedCompany | null;
    toTemplate: domain.Template | null;
    logMessages: LoggerMessage[];
    severityFilter: LoggerSeverity | null;
    targetCompanies: string;
    activeTab: ActiveTab;
    matchStandaloneByName: boolean;
    copySettings: boolean;
    deactivateOnCopy: boolean;
    selectedBackup: null | TemplateBackup;
    copyAllFields: boolean;
    fieldsToCopy: domain.Field[] | null;
    copyToAllOtherUserIds: boolean;
    targetUserIds: domain.User[] | null;
    mergeExistingValues: boolean;
}

function mapStateToProps(state: stateTree.State) {
    const data = getDevToolsPopupData(state)!;

    return {
        companies: selectors.company.getCompanies(state),
        allTemplates: selectors.template.getAllTemplates(state),
        loading: data.loading,
        templateBackups: templateAutomation.listTemplateBackups(),
        allUsers: selectors.user.getUsers(state),
    };
}

const mapDispatchToProps = {
    loadCompanyTemplates,
    copyRules,
    copyWorkflow,
    restoreWorkflow,
};

class WorkflowDevToolsSection extends React.Component<Props, OwnState> {
    private _logger: Logger;

    constructor(props: Props) {
        super(props);
        this.state = {
            fields: [],
            fieldsLoading: false,
            activeTab: ActiveTab.TemplateCopy,
            fromUser: null,
            fromStepIndex: null,
            fromCompany: null,
            fromTemplate: null,
            toCompany: null,
            toTemplate: null,
            targetCompanies: '',
            logMessages: [],
            severityFilter: null,
            matchStandaloneByName: false,
            copySettings: false,
            deactivateOnCopy: false,
            selectedBackup: null,
            copyAllFields: true,
            fieldsToCopy: null,
            mergeExistingValues: false,
            copyToAllOtherUserIds: true,
            targetUserIds: null,
        };
        this._logger = new Logger({
            onLog: this._onLog,
        });
        this._copyWorkflow = this._copyWorkflow.bind(this);
    }

    public render() {
        const { className } = this.props;

        return (
            <div className={bem.add(className)()}>
                <div className={bem('tabs')}>
                    <div
                        className={bem('tab-item', {
                            active: this.state.activeTab === ActiveTab.TemplateCopy,
                        })}
                        onClick={() => this._setActiveTab(ActiveTab.TemplateCopy)}
                    >
                        Template copy
                    </div>

                    <div
                        className={bem('tab-item', {
                            active: this.state.activeTab === ActiveTab.RulesCopy,
                        })}
                        onClick={() => this._setActiveTab(ActiveTab.RulesCopy)}
                    >
                        Rules copy
                    </div>

                    <div
                        className={bem('tab-item', {
                            active: this.state.activeTab === ActiveTab.Logs,
                        })}
                        onClick={() => this._setActiveTab(ActiveTab.Logs)}
                    >
                        Log
                    </div>
                </div>

                {this._renderTemplateCopyTabPanel()}

                {this._renderRulesCopyTabPanel()}

                {this._renderLogTabPanel()}
            </div>
        );
    }

    private _renderTemplateCopyTabPanel() {
        const { companies, loading, templateBackups } = this.props;
        const { fromCompany, toCompany, fromTemplate, toTemplate } = this.state;

        let fromTemplateOptions = fromCompany ? this._getTemplateOptions(fromCompany) : [];
        let toTemplateOptions = toCompany ? this._getTemplateOptions(toCompany) : [];

        const canCopyWorkflow = this.state.fromTemplate && (this.state.toCompany || this.state.targetCompanies);

        return (
            <div
                className={bem('tab-panel', {
                    active: this.state.activeTab === ActiveTab.TemplateCopy,
                })}
            >
                <div className={bem('address')}>
                    <Field title='From Company' className={bem('address-field')}>
                        <PlainDataProvider items={companies} filterAttribute='displayName'>
                            <DropdownEditor
                                value={fromCompany}
                                onChange={this._changeFromCompany}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>

                    <Field title='Workflow' className={bem('address-field')}>
                        <PlainDataProvider items={fromTemplateOptions} filterAttribute='displayName'>
                            <DropdownEditor
                                value={fromTemplate}
                                onChange={this._changeFromTemplate}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>
                </div>

                <div className={bem('address')}>
                    <Field title='To Company' className={bem('address-field')}>
                        <PlainDataProvider items={companies} filterAttribute='displayName'>
                            <DropdownEditor
                                value={toCompany}
                                onChange={this._changeToCompany}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>

                    <Field title='Workflow' className={bem('address-field')}>
                        <PlainDataProvider items={toTemplateOptions} filterAttribute='displayName'>
                            <DropdownEditor
                                value={toTemplate}
                                onChange={this._changeToTemplate}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>
                </div>

                <div className={bem('address')}>
                    <Field title='To Companies (bulk)' className={bem('address-field')}>
                        <TextAreaEditor
                            onChange={this._onTargetCompaniesChange}
                            value={this.state.targetCompanies}
                            placeholder="Each line = CompanyId OR CompanyName, clear 'To Company' field if not empty"
                            maxHeight={15}
                        />
                    </Field>
                </div>

                <div className={bem('address')}>
                    <div className={bem('address-field')}>
                        <CheckboxEditor
                            onChange={this._onChangeMatchStandaloneByName}
                            value={this.state.matchStandaloneByName}
                        >
                            (bulk) Do not create new standalone WF, match them by name
                        </CheckboxEditor>

                        <CheckboxEditor onChange={this._onChangeCopySettings} value={this.state.copySettings}>
                            Copy workflow settings along with workflow itself
                        </CheckboxEditor>

                        <CheckboxEditor onChange={this._onChangeDeactivateOnCopy} value={this.state.deactivateOnCopy}>
                            Deactivate workflows (otherwise, preserve target value)
                        </CheckboxEditor>
                    </div>
                </div>

                <div className={bem('buttons')}>
                    <Button execute={this._copyWorkflow} disabled={!canCopyWorkflow || loading}>
                        Copy workflow
                    </Button>
                </div>

                <div className={bem('restore-block')}>
                    <div>Restore:</div>

                    <PlainDataProvider
                        items={templateBackups.map((x: any) => ({
                            ...x,
                            id: `${x.template.id}/${x.creationDate}`,
                            text: `${selectors.template.expandTemplate(x.template).displayName} - ${x.creationDate}`,
                        }))}
                    >
                        <DropdownEditor
                            value={this.state.selectedBackup as any}
                            onChange={(selectedBackup: any) => {
                                this.setState({
                                    selectedBackup,
                                });
                            }}
                        />
                    </PlainDataProvider>

                    <Button execute={this._restoreWorkflow} disabled={loading}>
                        Restore
                    </Button>
                </div>
            </div>
        );
    }

    private _renderRulesCopyTabPanel() {
        const { companies, allUsers, loading } = this.props;
        const { fromCompany, fromTemplate, fromStepIndex, fromUser } = this.state;

        let fromTemplateOptions = fromCompany ? this._getTemplateOptions(fromCompany) : [];

        let fromStepOptions = fromTemplate
            ? fromTemplate.steps.map((s, i) => ({
                  id: String(i),
                  index: i,
                  text: s.name,
                  step: s,
              }))
            : [];

        const matrix = fromTemplate
            ? fromStepIndex != null
                ? fromTemplate.steps[fromStepIndex].participantMatrix
                : fromTemplate.submitterMatrix
            : null;

        let fromUserOptions = matrix ? matrix.map((x) => allUsers.find((u) => u.id === x.lineId)!) : [];
        let toUserOptions = matrix
            ? matrix
                  .map((x) => allUsers.find((u) => u.id === x.lineId)!)
                  .filter((x) => !fromUser || x.id !== fromUser.id)
            : [];

        const canCopyRules =
            fromTemplate &&
            fromUser &&
            (this.state.copyAllFields || (this.state.fieldsToCopy && this.state.fieldsToCopy.length > 0)) &&
            (this.state.copyToAllOtherUserIds || (this.state.targetUserIds && this.state.targetUserIds.length > 0));

        return (
            <div
                className={bem('tab-panel', {
                    active: this.state.activeTab === ActiveTab.RulesCopy,
                })}
            >
                <div className={bem('address')}>
                    <Field title='In Company' className={bem('address-field')}>
                        <PlainDataProvider items={companies} filterAttribute='displayName'>
                            <DropdownEditor
                                value={fromCompany}
                                onChange={this._changeFromCompany}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>

                    <Field title='Workflow' className={bem('address-field')}>
                        <PlainDataProvider items={fromTemplateOptions} filterAttribute='displayName'>
                            <DropdownEditor
                                value={fromTemplate}
                                onChange={this._changeFromTemplate}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>
                </div>

                <div className={bem('address')}>
                    <Field title='Step (if requesters, leave empty)' className={bem('address-field')}>
                        <PlainDataProvider items={fromStepOptions} filterAttribute='text'>
                            <DropdownEditor
                                value={
                                    this.state.fromStepIndex != null ? fromStepOptions[this.state.fromStepIndex] : null
                                }
                                onChange={this._changeFromStep}
                                displayAttribute='text'
                            />
                        </PlainDataProvider>
                    </Field>
                </div>

                <div className={bem('address')}>
                    <Field title='Choose the requestor to copy the rules FROM' className={bem('address-field')}>
                        <PlainDataProvider items={fromUserOptions} filterAttribute='displayName'>
                            <DropdownEditor
                                value={this.state.fromUser}
                                onChange={this._changeFromUser}
                                displayAttribute='displayName'
                            />
                        </PlainDataProvider>
                    </Field>
                </div>

                <div className={bem('address')}>
                    <div className={bem('address-field')}>
                        {this.state.fieldsLoading && (
                            <div className={bem('fields-loading-text')}>Loading template fields...</div>
                        )}
                    </div>
                </div>

                <div className={bem('address')}>
                    <div className={bem('address-field')}>
                        <Field title='What fields to copy'>
                            <RadioGroupEditor
                                value={this.state.copyAllFields}
                                onChange={(copyAllFields) => this.setState({ copyAllFields })}
                            >
                                <RadioGroupEditor.Item id={true}>Copy all fields</RadioGroupEditor.Item>

                                <RadioGroupEditor.Item id={false}>
                                    <PlainDataProvider items={this.state.fields} filterAttribute='name'>
                                        <DropdownEditor
                                            multiple
                                            value={this.state.fieldsToCopy}
                                            onChange={(fieldsToCopy: any) => this.setState({ fieldsToCopy })}
                                            displayAttribute='name'
                                        />
                                    </PlainDataProvider>
                                </RadioGroupEditor.Item>
                            </RadioGroupEditor>
                        </Field>
                    </div>
                </div>

                <div className={bem('address')}>
                    <div className={bem('address-field')}>
                        <Field title='Where do we copy the rules TO'>
                            <RadioGroupEditor
                                value={this.state.copyToAllOtherUserIds}
                                onChange={(copyToAllOtherUserIds) => this.setState({ copyToAllOtherUserIds })}
                            >
                                <RadioGroupEditor.Item id={true}>Copy to the other requesters</RadioGroupEditor.Item>

                                <RadioGroupEditor.Item id={false}>
                                    <PlainDataProvider items={toUserOptions} filterAttribute='displayName'>
                                        <DropdownEditor
                                            multiple
                                            value={this.state.targetUserIds}
                                            onChange={(targetUserIds: any) => this.setState({ targetUserIds })}
                                            displayAttribute='displayName'
                                        />
                                    </PlainDataProvider>
                                </RadioGroupEditor.Item>
                            </RadioGroupEditor>
                        </Field>
                    </div>
                </div>

                <div className={bem('address')}>
                    <div className={bem('address-field')} title='TODO, not implemented yet.'>
                        <CheckboxEditor
                            disabled
                            onChange={(mergeExistingValues) => this.setState({ mergeExistingValues })}
                            value={this.state.mergeExistingValues}
                        >
                            Merge with existing values (when rules are the same)
                        </CheckboxEditor>
                    </div>
                </div>

                <div className={bem('buttons')}>
                    <Button execute={this._copyRules} disabled={!canCopyRules || loading}>
                        COPY RULES
                    </Button>
                </div>
            </div>
        );
    }

    private _renderLogTabPanel() {
        return (
            <div className={bem('tab-panel', { active: this.state.activeTab === ActiveTab.Logs })}>
                <div className={bem('log-area')}>{this._renderLog()}</div>

                <div className={bem('log-footer')}>
                    <div
                        className={bem('counter', {
                            selected: this.state.severityFilter === LoggerSeverity.Warning,
                        })}
                        onClick={() => this._filterSeverity(LoggerSeverity.Warning)}
                    >
                        Warnings: {this.state.logMessages.filter((x) => x.severity === LoggerSeverity.Warning).length}
                    </div>

                    <div
                        className={bem('counter', {
                            selected: this.state.severityFilter === LoggerSeverity.Error,
                        })}
                        onClick={() => this._filterSeverity(LoggerSeverity.Error)}
                    >
                        Errors: {this.state.logMessages.filter((x) => x.severity === LoggerSeverity.Error).length}
                    </div>

                    <div className={bem('counter')} onClick={() => this._filterSeverity(null)}>
                        Clear filter
                    </div>
                </div>
            </div>
        );
    }

    private _onChangeDeactivateOnCopy = (deactivateOnCopy: boolean) => {
        this.setState({
            deactivateOnCopy,
        });
    };

    private _onChangeCopySettings = (copySettings: boolean) => {
        this.setState({
            copySettings,
        });
    };

    private _onChangeMatchStandaloneByName = (matchStandaloneByName: boolean) => {
        this.setState({
            matchStandaloneByName,
        });
    };

    private _setActiveTab = (activeTab: ActiveTab) => {
        this.setState({
            activeTab,
        });
    };

    private _onTargetCompaniesChange = (targetCompanies: string) => {
        this.setState({
            targetCompanies,
        });
    };

    private _filterSeverity = (severityFilter: LoggerSeverity | null) => {
        this.setState({
            severityFilter,
        });
    };

    private _renderLog = () => {
        return this.state.logMessages
            .filter((x) => {
                return !this.state.severityFilter || this.state.severityFilter === x.severity;
            })
            .map((msg, i) => {
                return (
                    <div key={i} className={bem('log-entry', msg.severity.toString())}>
                        {msg.message}
                    </div>
                );
            });
    };

    private _onLog = (logMessages: LoggerMessage[]) => {
        this.setState({
            logMessages,
        });
    };

    private async _copyWorkflow() {
        let toCompanies = this.state.targetCompanies
            .split('\n')
            .filter((c) => c)
            .map((c) => {
                const idOrName = c.trim();
                const matches = this.props.companies.filter(
                    (x) => x.id === idOrName.toLowerCase() || x.name === idOrName
                );

                if (matches.length > 1) {
                    notificationService.showErrorToast(
                        `Ambiguous match for organisation '${idOrName}. Candidates: ${matches
                            .map((m) => m.displayName)
                            .join(', ')}'`
                    );

                    return null;
                }

                if (matches.length === 0) {
                    notificationService.showErrorToast(`Failed to find an organisation with id/name '${idOrName}'`);

                    return null;
                }

                return matches[0];
            }) as domain.Company[];

        if (toCompanies.some((c) => !c)) {
            // Had errors
            return;
        }

        if (this.state.toCompany && toCompanies.length > 0) {
            notificationService.showErrorToast(
                'Both `To company` and `To Companies` fields are filled. Please clear the one you dont want to use.'
            );

            return;
        }

        const target: Array<{ company: domain.Company; template: domain.Template | null }> = [];

        for (const comp of toCompanies) {
            await this.props.loadCompanyTemplates({ companyId: comp.id });

            const ic = this.state.fromTemplate!.integrationCode;

            let template = null;

            if (ic) {
                template = this._getTemplateOptions(comp).find((t) => t.integrationCode === ic);

                if (!template) {
                    notificationService.showErrorToast(
                        `Failed to find template with code ${ic} in organisation ${comp.name}`
                    );

                    return;
                }
            } else if (this.state.matchStandaloneByName) {
                const name = this.state.fromTemplate!.templateName;

                template = this._getTemplateOptions(comp).find((t) => t.templateName === name);

                if (!template) {
                    notificationService.showErrorToast(
                        `Failed to find template with name ${name} in organisation ${comp.name}`
                    );

                    return;
                }
            }

            target.push({
                company: comp,
                template,
            });
        }

        this.props.copyWorkflow({
            fromCompany: this.state.fromCompany!,
            fromTemplate: this.state.fromTemplate!,
            toCompany: this.state.toCompany!,
            toTemplate: this.state.toTemplate,
            target,
            logger: this._logger,
            copySettings: this.state.copySettings,
            deactivateOnCopy: this.state.deactivateOnCopy,
            showDoneToastNotification: true,
        });
        this.props.onNeedsReload();
    }

    private _copyRules = () => {
        this.props.copyRules({
            fromCompany: this.state.fromCompany!,
            fromTemplate: this.state.fromTemplate!,
            fromStepIndex: this.state.fromStepIndex,
            fromUser: this.state.fromUser!.id,
            fromFields: this.state.copyAllFields ? [] : this.state.fieldsToCopy || [],
            targetUserIds: this.state.copyToAllOtherUserIds ? [] : (this.state.targetUserIds || []).map((x) => x.id),
            logger: this._logger,
            mergeExistingValues: this.state.mergeExistingValues,
        });
        this.props.onNeedsReload();
    };

    private _restoreWorkflow = () => {
        if (!this.state.selectedBackup) {
            notificationService.showErrorToast('Please first select a template.');

            return;
        }

        this.props.restoreWorkflow(this.state.selectedBackup.template);
        this.props.onNeedsReload();
    };

    private _getTemplateOptions = (company: domain.Company): selectors.types.ExpandedTemplate[] => {
        return arrayHelpers.arraySort(
            this.props.allTemplates.filter((t) => t.companyId === company.id),
            compareHelpers.comparatorFor<selectors.types.ExpandedTemplate>(
                compareHelpers.stringComparator2AscI,
                'displayName'
            )
        );
    };

    private _changeFromCompany = (company: selectors.types.ExpandedCompany | null) => {
        this.setState({
            fromCompany: company,
            fromTemplate: null,
        });

        if (!company) {
            return;
        }

        this.props.loadCompanyTemplates({ companyId: company.id });
    };

    private _changeToCompany = (company: selectors.types.ExpandedCompany | null) => {
        this.setState({
            toCompany: company,
            toTemplate: null,
        });

        if (!company) {
            return;
        }

        this.props.loadCompanyTemplates({ companyId: company.id });
    };

    private _changeFromTemplate = async (template: domain.Template) => {
        this.setState({
            fromTemplate: template,
            fromUser: null,
            fieldsLoading: true,
            fields: [],
        });

        try {
            const fields = await templateAutomation.getAllFields(this.state.fromCompany!.id, template.integrationCode);

            this.setState({
                fields,
                fieldsLoading: false,
            });
        } catch (e) {
            this.setState({
                fieldsLoading: false,
            });
        }
    };

    private _changeToTemplate = (template: domain.Template) => {
        this.setState({
            toTemplate: template,
        });
    };

    private _changeFromUser = (fromUser: domain.User) => {
        this.setState({
            fromUser,
        });
    };

    private _changeFromStep = (fromStep: { id: string; index: number } | null) => {
        this.setState({
            fromStepIndex: fromStep!.index,
        });
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDevToolsSection);
