import { produce } from 'immer';
import { domain } from 'modules/data';

import { Action, SHOW_SIGNUP_MODULE, SIGNUP_RESPONSE } from '../../actions';

export enum PageActiveModule {
    SignupModule = 'SignupModule',
}

export interface SignupModuleData {
    type: typeof PageActiveModule.SignupModule;
    success: boolean;
    app?: domain.SsoLoginApplication;
    email?: string;
    token?: string;
}

export type ActiveModule = SignupModuleData;

export default produce((draft: ActiveModule, action: Action): ActiveModule => {
    switch (action.type) {
        case SHOW_SIGNUP_MODULE:
            return {
                type: PageActiveModule.SignupModule,
                success: false,
                app: action.payload.app,
                email: action.payload.email,
                token: action.payload.token,
            };

        case SIGNUP_RESPONSE:
            (draft as SignupModuleData).success = true;

            return draft;

        default:
            return draft;
    }
});
