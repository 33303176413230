import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';

export enum ConditionTypeValue {
    All = 'All',
    ExactValues = 'ExactValues',
    NegativeExactValues = 'NegativeExactValues',
}

export interface AllowEditingCellProps extends RenderCellOptions {
    withSelect?: boolean;
}
