import { Guid } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import { selectors } from 'modules/common';
import { backend, domain, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';

import {
    PossibleApprover,
    XeroAddApproversFromPoPopupData,
} from '../reducers/page/activePopup/xeroAddApproversFromPoPopupReducer';
import { getActivePopup, getPage } from '../selectors/pageSelectors';
import { reloadRequest } from './actions';

const i18nPrefix = 'requestList/actions/xeroActions';
const messages = defineMessages({
    applyMatchingSuccessToast: {
        id: `${i18nPrefix}.applyMatchingSuccessToast`,
        defaultMessage: 'Matching has been changed for this request',
    },
    addApproversAfterXeroMatchingSuccessToast: {
        id: `${i18nPrefix}.addApproversAfterXeroMatchingSuccessToast`,
        defaultMessage: 'Approvers have been added',
    },
    newApproverReasonDelegate: {
        id: `${i18nPrefix}.newApproverReasonDelegate`,
        defaultMessage:
            'Add {userName} ({userEmail}) as delegate of {delegateName} ({delegateEmail}) who is requester of {requestName}',
    },
    newApproverReasonNormal: {
        id: `${i18nPrefix}.newApproverReasonNormal`,
        defaultMessage: 'Add {userName} ({userEmail}) as requester of {requestName}',
    },
});

export const SHOW_XERO_MATCHING_POPUP = 'REQUESTLIST/SHOW_XERO_MATCHING_POPUP';
export const SHOW_XERO_MATCHING_POPUP_RESPONSE = 'REQUESTLIST/SHOW_XERO_MATCHING_POPUP_RESPONSE';
export const SHOW_XERO_MATCHING_POPUP_FAILURE = 'REQUESTLIST/SHOW_XERO_MATCHING_POPUP_FAILURE';
export const showXeroMatchingPopup = (requestId: Guid) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(SHOW_XERO_MATCHING_POPUP, {
                requestId,
                companyId: selectors.request.getRequestById(state, requestId).companyId,
            }),

        response: async (request) => {
            const response = await api.requests.selectMatchingRequests({ requestId, companyId: request.companyId });

            return createAction(SHOW_XERO_MATCHING_POPUP_RESPONSE, {
                requestId,
                request,
                requests: response.Requests.map((r) => r.RequestId),
                raw: response,
            });
        },

        failure: (error, request) =>
            createErrorAction(SHOW_XERO_MATCHING_POPUP_FAILURE, error, {
                requestId,
            }),

        schema: { raw: { Requests: [schemas.requestSchema] } },
    });

export const APPLY_XERO_MATCHING = 'REQUESTLIST/APPLY_XERO_MATCHING';
export const APPLY_XERO_MATCHING_RESPONSE = 'REQUESTLIST/APPLY_XERO_MATCHING_RESPONSE';
export const APPLY_XERO_MATCHING_FAILURE = 'REQUESTLIST/APPLY_XERO_MATCHING_FAILURE';
export const applyXeroMatching = (
    requestId: Guid,
    selectedRequestIds: Guid[],
    matchingMode: 'manyBillToOnePo' | 'oneBillToManyPo'
) =>
    createAsyncAction({
        request: (state: State) => {
            const targetRequest = selectors.request.getRequestById(state, requestId);
            const isPo = targetRequest.integrationCode === domain.IntegrationCode.XeroPo;
            const isBill = !isPo;

            let unmatchBeforeMatch = false;

            if (targetRequest.matchingAgreement) {
                // !poList has selected documents
                unmatchBeforeMatch =
                    (isBill &&
                        !targetRequest.matchingAgreement.openingDocuments.some((d) =>
                            selectedRequestIds.includes(d.id)
                        )) ||
                    (isPo &&
                        !targetRequest.matchingAgreement.closingDocuments.some((d) =>
                            selectedRequestIds.includes(d.id)
                        ));
            }

            return createAction(APPLY_XERO_MATCHING, {
                targetRequest,
                selectedRequestIds,
                unmatchBeforeMatch,
                companyId: selectors.request.getRequestById(state, requestId).companyId,
            });
        },

        response: async (request, getState) => {
            function getMatchingTransfer() {
                const state = getState();
                const isPo = request.targetRequest.integrationCode === domain.IntegrationCode.XeroPo;

                let originRequestId;
                let oppositeRequestIds;

                if (isPo) {
                    if (matchingMode === 'manyBillToOnePo') {
                        originRequestId = requestId;
                        oppositeRequestIds = selectedRequestIds;
                    } else {
                        const firstBill = selectors.request.getRequestById(state, selectedRequestIds[0]);

                        oppositeRequestIds = [requestId];

                        if (firstBill.matchingAgreement) {
                            // Add all the other POs
                            oppositeRequestIds.push(
                                ...firstBill.matchingAgreement.openingDocuments
                                    .filter((d) => d.id !== requestId)
                                    .map((d) => d.id)
                            );
                        }

                        originRequestId = firstBill.id;
                    }
                } else {
                    if (matchingMode === 'manyBillToOnePo') {
                        const firstPo = selectors.request.getRequestById(state, selectedRequestIds[0]);

                        oppositeRequestIds = [requestId];

                        if (firstPo.matchingAgreement) {
                            // Add all the other bills
                            oppositeRequestIds.push(
                                ...firstPo.matchingAgreement.closingDocuments
                                    .filter((d) => d.id !== requestId)
                                    .map((d) => d.id)
                            );
                        }

                        originRequestId = firstPo.id;
                    } else {
                        originRequestId = requestId;
                        oppositeRequestIds = selectedRequestIds;
                    }
                }

                return {
                    originRequestId,
                    oppositeRequestIds,
                    rematchSilently: true,
                    companyId: request.companyId,
                };
            }

            function mergeMatchingResults(
                ur: backend.MatchingAgreementMatchAnswer | undefined,
                mr: backend.MatchingAgreementMatchAnswer
            ) {
                if (ur) {
                    if ((ur.UnmatchedRequests || []).length > 1) {
                        mr.UnmatchedRequests = (mr.UnmatchedRequests || []).concat(
                            ur.UnmatchedRequests.filter((x) => x.RequestId !== requestId)
                        );
                    }

                    if ((ur.MatchingAgreements || []).length > 0) {
                        mr.MatchingAgreements = mr.MatchingAgreements || [];

                        const newAgreements = mr.MatchingAgreements.map((x) => x.AgreementId);

                        mr.MatchingAgreements = mr.MatchingAgreements.concat(
                            ur.MatchingAgreements.filter((x) => !newAgreements.includes(x.AgreementId))
                        );
                    }
                }

                return mr;
            }

            function getAffectedRequests(data: backend.MatchingAgreementMatchAnswer) {
                const loadedRequests = getPage(getState()).requests;

                // We have to manually put 'requestId' there as in some cases it might no return from server
                // e.g. when matching is a noop as somebody has updated it in another tab
                let updateTargets: Guid[] = [requestId];

                if (data.UnmatchedRequests) {
                    updateTargets.push(...data.UnmatchedRequests.map((r) => r.RequestId));
                }

                if (data.MatchingAgreements) {
                    data.MatchingAgreements.forEach((ma) => {
                        if (ma.OpeningDocuments) {
                            updateTargets.push(...ma.OpeningDocuments.map((r) => r.RequestId));
                        }

                        if (ma.ClosingDocuments) {
                            updateTargets.push(...ma.ClosingDocuments.map((r) => r.RequestId));
                        }
                    });
                }

                updateTargets = uniq(updateTargets.filter((id) => loadedRequests.includes(id)));

                return updateTargets;
            }

            let result;

            if (selectedRequestIds.length === 0) {
                result = await api.requests.unmatchRequests({
                    requestIds: [request.targetRequest.id],
                    companyId: request.companyId,
                });
                result.UnmatchedRequests.push({
                    RequestId: request.targetRequest.id,
                } as backend.RequestAnswer);
            } else {
                let unmatchingResult;

                if (request.unmatchBeforeMatch) {
                    unmatchingResult = await api.requests.unmatchRequests({
                        requestIds: [request.targetRequest.id],
                        companyId: request.companyId,
                    });
                }

                const matchingResult = await api.requests.matchRequests(getMatchingTransfer());

                result = mergeMatchingResults(unmatchingResult, matchingResult);
            }

            const affectedRequestIds = getAffectedRequests(result);

            let shouldShowAddApproversFromPoPopup = false;
            let possibleNewApprovers: PossibleApprover[] = [];

            if (request.targetRequest.integrationCode === domain.IntegrationCode.XeroBill) {
                {
                    const amPOs = selectedRequestIds
                        .map((rid) => selectors.request.getRequestById(getState(), rid))
                        .filter((r) => r.origin === domain.RequestOrigin.ApprovalMax);
                    const existingApprovers = request.targetRequest.steps.flatMap((s) =>
                        s.participants.map((p) => p.userId)
                    );

                    possibleNewApprovers = uniqWith(
                        amPOs
                            .filter((r) => {
                                const delegateDef = r.company.delegates.find((d) => d.userId === r.authorId);

                                return !existingApprovers.includes(
                                    delegateDef ? delegateDef.delegateUserId : r.authorId
                                );
                            })
                            .map((r) => {
                                const delegateDef = r.company.delegates.find((d) => d.userId === r.authorId);

                                let delegate;

                                if (delegateDef) {
                                    delegate = r.company.allMembers.find((m) => m.id === delegateDef.delegateUserId)!;
                                }

                                return {
                                    userId: delegate ? delegate.id : r.authorId,
                                    reasonText: delegate
                                        ? intl.formatMessage(messages.newApproverReasonDelegate, {
                                              requestName: r.displayName,
                                              userName: r.author.displayName,
                                              userEmail: r.author.userEmail,
                                              delegateName: delegate.displayName,
                                              delegateEmail: delegate.userEmail,
                                          })
                                        : intl.formatMessage(messages.newApproverReasonNormal, {
                                              requestName: r.displayName,
                                              userName: r.author.displayName,
                                              userEmail: r.author.userEmail,
                                          }),
                                };
                            }),
                        (a, b) => a.userId === b.userId
                    );
                }

                shouldShowAddApproversFromPoPopup = Boolean(
                    possibleNewApprovers.length > 0 && request.targetRequest.activeStep
                );
            }

            return createAction(APPLY_XERO_MATCHING_RESPONSE, {
                request,
                affectedRequestIds,
                possibleNewApprovers,
                shouldShowAddApproversFromPoPopup,
            });
        },

        failure: (error, request) => createErrorAction(APPLY_XERO_MATCHING_FAILURE, error, {}),

        successToast: intl.formatMessage(messages.applyMatchingSuccessToast),

        didDispatchResponse: (
            request,
            response: { affectedRequestIds: Guid[]; shouldShowAddApproversFromPoPopup: boolean },
            state,
            dispatch
        ) => {
            response.affectedRequestIds.forEach((rid) => {
                dispatch(reloadRequest(rid, request.companyId));
            });
        },
    });

export const ADD_APPROVERS_AFTER_XERO_MATCHING = 'REQUESTLIST/ADD_APPROVERS_AFTER_XERO_MATCHING';
export const ADD_APPROVERS_AFTER_XERO_MATCHING_RESPONSE = 'REQUESTLIST/ADD_APPROVERS_AFTER_XERO_MATCHING_RESPONSE';
export const ADD_APPROVERS_AFTER_XERO_MATCHING_FAILURE = 'REQUESTLIST/ADD_APPROVERS_AFTER_XERO_MATCHING_FAILURE';
export const addApproversAfterXeroMatching = (newApprovers: Guid[]) =>
    createAsyncAction({
        request: (state: State) => {
            const popupData = getActivePopup<XeroAddApproversFromPoPopupData>(state);

            return createAction(ADD_APPROVERS_AFTER_XERO_MATCHING, {
                request: selectors.request.getRequestById(state, popupData.targetRequest),
                affectedRequestIds: popupData.affectedRequestIds,
                newApprovers: newApprovers.map((uid) => selectors.user.getUserById(state, uid)),
            });
        },

        response: async (request) => {
            const response = api.requests.changeParticipants({
                requestId: request.request.id,
                changes: [
                    {
                        stepId: request.request.activeStep!.id,
                        participantsToAdd: request.newApprovers.map((a) => a.userEmail),
                        participantsToDelete: [],
                    },
                ],
                companyId: request.request.companyId,
            });

            return createAction(ADD_APPROVERS_AFTER_XERO_MATCHING_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error, request) => createErrorAction(ADD_APPROVERS_AFTER_XERO_MATCHING_FAILURE, error, {}),

        successToast: intl.formatMessage(messages.addApproversAfterXeroMatchingSuccessToast),

        didDispatchResponse: (request, response, state, dispatch) => {
            request.affectedRequestIds.forEach((rid) => {
                dispatch(reloadRequest(rid, request.request.companyId));
            });
        },
    });

export const SHOW_XERO_REQUEST_BUDGET_POPUP = 'REQUESTLIST/SHOW_XERO_REQUEST_BUDGET_POPUP';
export const showXeroRequestBudgetPopup = (requestId: Guid, budgetId: Guid) =>
    createAction(SHOW_XERO_REQUEST_BUDGET_POPUP, {
        requestId,
        budgetId,
    });

export type Action = ExtractActions<
    | typeof addApproversAfterXeroMatching
    | typeof applyXeroMatching
    | typeof showXeroMatchingPopup
    | typeof showXeroRequestBudgetPopup
>;
