import { Popup } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import React from 'react';
import { connect } from 'react-redux';

import { cancelActivePopup, cancelCompanyConnectionErrorPopup } from './actions';
import AnotherCompanyIntegratedPopup from './containers/AnotherCompanyIntegratedPopup/AnotherCompanyIntegratedPopup';
import CompanyMismatchPopup from './containers/CompanyMismatchPopup/CompanyMismatchPopup';
import DearIntegrationPopup from './containers/dear/DearIntegrationPopup/DearIntegrationPopup';
import IntegrationErrorPopup from './containers/IntegrationErrorPopup';
import NetSuiteIntegrationPopup from './containers/netsuite/NetSuiteIntegrationPopup/NetSuiteIntegrationPopup';
import XeroIntegrationSelectPopup from './containers/xero/XeroIntegrationSelectPopup';
import { DEAR_INTEGRATION_POPUP } from './reducers/module/activePopup/dearIntegrationPopupReducer';
import { INTEGRATION_ERROR_POPUP } from './reducers/module/activePopup/integrationErrorPopupReducer';
import { NETSUITE_INTEGRATION_POPUP } from './reducers/module/activePopup/netSuiteIntegrationPopupReducer';
import { XERO_INTEGRATION_SELECT_POPUP } from './reducers/module/activePopup/xeroIntegrationSelectPopupReducer';
import { ANOTHER_COMPANY_INTEGRATED_POPUP } from './reducers/module/companyConnectionErrorPopup/anotherCompanyIntegratedPopupReducer';
import { COMPANY_MISMATCH_POPUP } from './reducers/module/companyConnectionErrorPopup/companyMismatchPopupReducer';
import { getActiveCompanyConnectionErrorPopupId, getActivePopupId } from './selectors/moduleSelectors';

interface OwnProps {}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

interface OwnState {}

function mapStateToProps(state: stateTree.State) {
    return {
        activePopupId: getActivePopupId(state),
        activeCompanyId: selectors.navigation.getActiveCompanyId(state),
        activeCompanyConnectionErrorPopupId: getActiveCompanyConnectionErrorPopupId(state),
    };
}

const mapDispatchToProps = {
    cancelActivePopup,
    cancelCompanyConnectionErrorPopup,
};

class Module extends React.Component<Props, OwnState> {
    public render() {
        const {
            activePopupId,
            cancelActivePopup,
            activeCompanyConnectionErrorPopupId,
            cancelCompanyConnectionErrorPopup,
        } = this.props;

        return (
            <>
                <Popup isOpen={activePopupId === XERO_INTEGRATION_SELECT_POPUP} onRequestClose={cancelActivePopup}>
                    <XeroIntegrationSelectPopup />
                </Popup>

                <Popup isOpen={activePopupId === INTEGRATION_ERROR_POPUP} onRequestClose={cancelActivePopup}>
                    <IntegrationErrorPopup />
                </Popup>

                <Popup
                    isOpen={activePopupId === DEAR_INTEGRATION_POPUP}
                    disableAutoClose
                    onRequestClose={cancelActivePopup}
                >
                    <DearIntegrationPopup />
                </Popup>

                <Popup
                    isOpen={activePopupId === NETSUITE_INTEGRATION_POPUP}
                    disableAutoClose
                    onRequestClose={cancelActivePopup}
                >
                    <NetSuiteIntegrationPopup />
                </Popup>

                <Popup
                    isOpen={activeCompanyConnectionErrorPopupId === COMPANY_MISMATCH_POPUP}
                    onRequestClose={cancelCompanyConnectionErrorPopup}
                >
                    <CompanyMismatchPopup />
                </Popup>

                <Popup
                    isOpen={activeCompanyConnectionErrorPopupId === ANOTHER_COMPANY_INTEGRATED_POPUP}
                    onRequestClose={cancelCompanyConnectionErrorPopup}
                >
                    <AnotherCompanyIntegratedPopup />
                </Popup>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Module);
