import { ApiError, ErrorCode } from '@approvalmax/data';
import { defineMessages, errorHelpers } from '@approvalmax/utils';
import isEqual from 'lodash/isEqual';
import { selectors } from 'modules/common';
import { backend, domain, factories, schemas, State } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import * as search from 'modules/search';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

import { TCopyRulePopupCheckedSteps } from '../components/CopyRulePopup/CopyRulePopup.types';
import {
    NEVER_SHOW_START_OVER_ON_SAVE_USER_PREFERENCE,
    StartOverPopupMode,
} from '../reducers/page/activeStartOverReducer';
import { AddCommentPopupPostAction } from '../reducers/page/addCommentPopupReducer';
import { getAllCommands } from '../selectors/cardCommandSelectors';
import { getActiveTemplate } from '../selectors/pageSelectors';
import { OpenActiveVersionConflictPopup } from '../types/actions';
import { ActiveMatrixData } from '../types/activeMatrixData';
import {
    prepareAutoapprovalRulesForComparison,
    prepareMatrixesForComparison,
    prepareStepsForComparison,
} from '../utils/helpers';
import { openStartOverPopup } from './startOver';

const messages = defineMessages('workflows.actions.template', {
    noChanges: 'There were no changes in the workflow.',
    templateCreatedSuccess: 'The approval workflow has been created.',
    templateActivatedSuccess: 'The approval workflow has been activated.',
    templateDeactivatedSuccess: 'The approval workflow has been deactivated.',
    templateUpdatedSuccess: 'The approval workflow has been updated.',
    errorNoStepsNotification: 'Please add at least one approval step to the workflow.',
    deleteTemplateSuccess: 'The approval workflow has been deleted.',
    errorNoStepName: 'Please enter the step name',
    removeUsersDeletedFromCompanyError:
        'Please remove the following users who were deleted from the Organisation:{users}',
});

export const VALIDATE_ACTIVE_TEMPLATE = 'LEGACY_WORKFLOW_TEMPLATES/VALIDATE_ACTIVE_TEMPLATE';
export const SAVE_TEMPLATE = 'LEGACY_WORKFLOW_TEMPLATES/SAVE_TEMPLATE';
export const SAVE_TEMPLATE_RESPONSE = 'LEGACY_WORKFLOW_TEMPLATES/SAVE_TEMPLATE_RESPONSE';
export const SAVE_TEMPLATE_FAILURE = 'LEGACY_WORKFLOW_TEMPLATES/SAVE_TEMPLATE_FAILURE';
export const HIDE_VALIDATE_ERRORS = 'LEGACY_WORKFLOW_TEMPLATES/HIDE_VALIDATE_ERRORS';

const checkChangesTransfer = (
    activeTemplateTransfer: backend.transfers.TemplateCreateTransfer | backend.transfers.TemplateEditTransfer,
    savedTemplateTransfer: backend.transfers.TemplateCreateTransfer | backend.transfers.TemplateEditTransfer | null
) =>
    !isEqual(
        {
            ...activeTemplateTransfer,
            steps: prepareStepsForComparison(activeTemplateTransfer.steps),
            submitters: prepareMatrixesForComparison(activeTemplateTransfer.submitters),
            payers: prepareMatrixesForComparison(activeTemplateTransfer.payers || []),
            autoapprovalRules: prepareAutoapprovalRulesForComparison(activeTemplateTransfer.autoapprovalRules),
            enabled: undefined,
            templateName: undefined,
        },
        {
            ...savedTemplateTransfer,
            steps: savedTemplateTransfer ? prepareStepsForComparison(savedTemplateTransfer.steps) : [],
            submitters: prepareMatrixesForComparison(savedTemplateTransfer?.submitters || []),
            payers: prepareMatrixesForComparison(savedTemplateTransfer?.payers || []),
            autoapprovalRules: prepareAutoapprovalRulesForComparison(savedTemplateTransfer?.autoapprovalRules || []),
            enabled: undefined,
            templateName: undefined,
        }
    );

interface ValidateActiveTemplateAction {
    type: typeof VALIDATE_ACTIVE_TEMPLATE;
    isValid: boolean;
}

interface HideValidateErrors {
    type: typeof HIDE_VALIDATE_ERRORS;
}

export const saveTemplate = (
    template: selectors.types.ExpandedTemplate,
    options: {
        enabled?: boolean;
        forceOverwrite?: boolean;
        addCommentIfNeed?: boolean;
        addCommentPopupPostAction?: AddCommentPopupPostAction;
    } = {}
) => {
    // Applying final changes
    let justActivated = false;
    let justDeactivated = false;

    if (options.enabled !== undefined) {
        template = {
            ...template,
            enabled: options.enabled,
        };
        justActivated = options.enabled;
        justDeactivated = !options.enabled;
    }

    const isNew = template.isNew;

    return createAsyncAction({
        shouldSendRequest(state: State, dispatch) {
            // Validation
            const isValid = selectors.template.isTemplateValid(state, template);

            if (!isValid) {
                if (template.steps.some((s) => !s.name)) {
                    notificationService.showErrorToast(messages.errorNoStepName);
                }
            }

            if (!isValid) {
                dispatch<ValidateActiveTemplateAction>({
                    type: VALIDATE_ACTIVE_TEMPLATE,
                    isValid: false,
                });

                setTimeout(() => {
                    dispatch<HideValidateErrors>({ type: HIDE_VALIDATE_ERRORS });
                }, 5000);

                if (template.steps.length === 0) {
                    notificationService.showErrorToast(messages.errorNoStepsNotification);
                }

                return false;
            }

            // Checking for removed participants
            const errors = selectors.template.validateTemplateUsers(state, template);

            if (errors.length > 0) {
                notificationService.showErrorToast(
                    messages.removeUsersDeletedFromCompanyError({
                        users: (
                            <ul>
                                {errors.map((e, i) => (
                                    <li key={i}>{` - ${e}`}</li>
                                ))}
                            </ul>
                        ),
                    })
                );

                return false;
                // ENDOF validation
            }

            if (options.addCommentIfNeed && options.addCommentPopupPostAction) {
                const savedTemplate = selectors.template.getTemplateByIdSilent(state, template.id);

                const activeTemplateTransfer = selectors.template.getTemplateTransfer(state, template);
                const savedTemplateTransfer = savedTemplate
                    ? selectors.template.getTemplateTransfer(state, savedTemplate)
                    : null;

                const templateDataChange = checkChangesTransfer(activeTemplateTransfer, savedTemplateTransfer);

                const templateEnabledChange = activeTemplateTransfer.enabled !== savedTemplateTransfer?.enabled;

                const templateOnlyNameChange =
                    !templateDataChange &&
                    !templateEnabledChange &&
                    activeTemplateTransfer.templateName !== savedTemplateTransfer?.templateName;

                if (!templateDataChange && !templateEnabledChange && !templateOnlyNameChange) {
                    notificationService.showInfoToast(messages.noChanges);

                    return false;
                }

                const isFirstActivatingSAWorkflow = template.version === undefined;

                if (templateDataChange && !isFirstActivatingSAWorkflow) {
                    dispatch(openAddCommentPopup(options.addCommentPopupPostAction));

                    return false;
                }

                return true;
            }

            return true;
        },

        request: (state: State) => {
            const newUsers = selectors.user.getNewUsers(state, template.companyId).map((u) => u.id);

            const company = selectors.company.findCompanyById(state, template.companyId);

            return createAction(SAVE_TEMPLATE, {
                isNew,
                companyId: template.companyId,
                integrationId: selectors.company.getCompanyById(state, template.companyId).integrationId,
                templateTransfer: selectors.template.getTemplateTransfer(state, template),
                template,
                newUsers,
                justActivated,
                justDeactivated,
                company,
                team: selectors.company.getCompanyTeam(state, {
                    id: template.companyId,
                } as domain.Company),
            });
        },

        response: async (request) => {
            let response;

            if (request.isNew) {
                response = await api.templates.create(request.templateTransfer);
            } else if ('templateId' in request.templateTransfer) {
                response = await api.templates.edit({
                    ...request.templateTransfer,
                    // do not transfer version to ignore version checking on the BE side if forceOverwrite === true
                    version: options.forceOverwrite ? undefined : request.templateTransfer.version,
                });
            } else {
                throw errorHelpers.invalidOperationError();
            }

            const userContext = await api.companies.getUserContext({
                companyId: request.companyId,
            });

            // Update integrations (sync progress)
            let integrations: backend.IntegrationAnswer[] = [];

            if ((request.justActivated || request.justDeactivated) && request.integrationId) {
                const integrationSyncResponse = await api.companies.getIntegrationSyncProgress({
                    integrationIds: [request.integrationId],
                });

                integrations = integrationSyncResponse.Integrations;
            }

            const isCreateable = Boolean(response?.Template.IsRequestCreationAvailable);

            if (options.addCommentPopupPostAction) {
                amplitudeService.sendData('workflow: save workflow', {
                    workflow: template.integrationCode?.toLocaleLowerCase() || 'standalone',
                    'action type': options.addCommentPopupPostAction,
                });
            }

            return createAction(SAVE_TEMPLATE_RESPONSE, {
                request,
                creatable: isCreateable,
                templateId: response?.Template.TemplateId,
                raw: {
                    Template: response?.Template,
                    Integrations: integrations,
                    Company: userContext.CompanyContexts[0].Company,
                },
            });
        },

        willDispatchError: (request, error: ApiError, state, dispatch) => {
            if (error.code === ErrorCode.E4148_WORKFLOW_VERSION_UPDATED) {
                dispatch(
                    openActiveVersionConflictPopup({
                        justActivated: request.justActivated,
                        justDeactivated: request.justDeactivated,
                        newTemplateComment: template.comment || '',
                    })
                );
            }
        },

        failure: (error, request) => createErrorAction(SAVE_TEMPLATE_FAILURE, error, {}),

        schema: {
            raw: {
                Template: schemas.templateSchema,
                Integrations: [schemas.integrationSchema],
                Company: schemas.companySchemaLegacy,
            },
        },

        didDispatchResponse: (request, response, state, dispatch) => {
            if (isNew && !options.enabled) {
                notificationService.showInfoToast(messages.templateCreatedSuccess);
            } else {
                if (options.enabled) {
                    notificationService.showInfoToast(messages.templateActivatedSuccess);
                } else {
                    if (options.enabled === false) {
                        notificationService.showInfoToast(messages.templateDeactivatedSuccess);
                    } else {
                        notificationService.showInfoToast(messages.templateUpdatedSuccess);
                    }
                }
            }

            if (options.enabled && template.integrationCode) {
                dispatch(integrationActions.syncIntegration({ companyId: template.companyId }));
            }

            const updatedTemplate = getActiveTemplate(state);
            const neverShowStartOver = Boolean(
                selectors.userPreferences.getUserPreference(state, NEVER_SHOW_START_OVER_ON_SAVE_USER_PREFERENCE)
            );

            if (updatedTemplate && !neverShowStartOver) {
                const startOverCommand = getAllCommands(state).startOver;

                if (!justActivated && !startOverCommand.hidden && !startOverCommand.disabled) {
                    dispatch(
                        openStartOverPopup(
                            updatedTemplate,
                            StartOverPopupMode.AfterUpdate,
                            Boolean(request.company?.flags.isWorkflowManager)
                        )
                    );
                }
            }

            if (isNew && !justActivated) {
                routingService.push(getPath(Path.workflow, request.companyId, response.templateId));
            }

            dispatch(search.actions.invalidateSearchContext());
        },
    });
};

export const ADD_NEW_STANDALONE_TEMPLATE = 'LEGACY_WORKFLOW_TEMPLATES/ADD_NEW_STANDALONE_TEMPLATE';

interface AddNewStandaloneTemplateAction {
    type: typeof ADD_NEW_STANDALONE_TEMPLATE;
    newTemplate: domain.Template;
}

export function addNewStandaloneTemplate(companyId: string): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();

        dispatch<AddNewStandaloneTemplateAction>({
            type: ADD_NEW_STANDALONE_TEMPLATE,
            newTemplate: factories.template.createStandaloneTemplate(
                companyId,
                selectors.profile.getProfileUser(state).id
            ),
        });
    };
}

export const COPY_RULES_TO_ANOTHER_STEP_USERS = 'LEGACY_WORKFLOW_TEMPLATES/COPY_RULES_TO_ANOTHER_STEP_USERS';
export const copyRuleToAnotherStepsUsers = (
    matrixActive: ActiveMatrixData,
    fromUser: domain.User,
    mapStepsToUser: TCopyRulePopupCheckedSteps = {},
    defaultApprover: string | null,
    template: domain.Template,
    checkedColumns: string[]
) =>
    createAction(COPY_RULES_TO_ANOTHER_STEP_USERS, {
        matrixActive,
        fromUser,
        mapStepsToUser,
        defaultApprover,
        template,
        checkedColumns,
    });

interface ShowTemplateAction {
    type: typeof SHOW_WORKFLOW_PAGE;
    template: selectors.types.ExpandedTemplate;
}

export const SHOW_WORKFLOW_PAGE = 'LEGACY_WORKFLOW_TEMPLATES/SHOW_WORKFLOW_PAGE';

export function showWorkflowPage(companyId: string, templateId: string): ThunkAction<State> {
    return (dispatch, getState) => {
        const template = selectors.template.findTemplateById(getState(), templateId);

        if (!template) {
            routingService.push(getPath(Path.companyWorkflows, companyId));

            return;
        }

        dispatch<ShowTemplateAction>({
            type: SHOW_WORKFLOW_PAGE,
            template,
        });
    };
}

export const DELETE_TEMPLATE = 'LEGACY_WORKFLOW_TEMPLATES/DELETE_TEMPLATE';
export const DELETE_TEMPLATE_RESPONSE = 'LEGACY_WORKFLOW_TEMPLATES/DELETE_TEMPLATE_RESPONSE';
export const DELETE_TEMPLATE_FAILURE = 'LEGACY_WORKFLOW_TEMPLATES/DELETE_TEMPLATE_FAILURE';
export const deleteTemplate = () =>
    createAsyncAction({
        request: (state: State) => {
            return createAction(DELETE_TEMPLATE, { template: getActiveTemplate(state)! });
        },

        response: async (request) => {
            await api.templates.delete({ workflowId: request.template.id, companyId: request.template.companyId });

            routingService.push(getPath(Path.companyWorkflows, request.template.companyId));

            return createAction(DELETE_TEMPLATE_RESPONSE, {
                request,
            });
        },

        failure: (error, request) => createErrorAction(DELETE_TEMPLATE_FAILURE, error, {}),

        didDispatchResponse: () => {
            notificationService.showInfoToast(messages.deleteTemplateSuccess);
        },
    });

export const DISCARD_TEMPLATE_CHANGES = 'LEGACY_WORKFLOW_TEMPLATES/DISCARD_TEMPLATE_CHANGES';

interface DiscardTemplateChangesAction {
    type: typeof DISCARD_TEMPLATE_CHANGES;
    untouchedTemplate: domain.Template;
}

export function discardTemplateChanges(): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();
        const templateId = getActiveTemplate(state)!.id;
        const untouchedTemplate = selectors.template.getTemplateById(state, templateId);

        dispatch<DiscardTemplateChangesAction>({
            type: DISCARD_TEMPLATE_CHANGES,
            untouchedTemplate,
        });
    };
}

export const RENAME_TEMPLATE = 'LEGACY_WORKFLOWS/RENAME_TEMPLATE';
export const renameTemplate = (newName: string) => createAction(RENAME_TEMPLATE, { newName });

export const ADD_SUBMITTER = 'LEGACY_WORKFLOW_TEMPLATES/ADD_SUBMITTER';
export const addSubmitter = (userId: string) =>
    createAction(ADD_SUBMITTER, {
        userId,
    });

export const REMOVE_SUBMITTER = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_SUBMITTER';
export const removeSubmitter = (userId: string, userDatabaseId?: string) =>
    createAction(REMOVE_SUBMITTER, {
        userId,
        userDatabaseId,
    });

export const ADD_PAYER = 'LEGACY_WORKFLOW_TEMPLATES/ADD_PAYER';
export const addPayer = (userId: string) =>
    createAction(ADD_PAYER, {
        userId,
    });

export const REMOVE_PAYER = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_PAYER';
export const removePayer = (userId: string, userDatabaseId?: string) =>
    createAction(REMOVE_PAYER, {
        userId,
        userDatabaseId,
    });

export const CHANGE_EXTERNAL_SUBMITTER_ENABLED = 'LEGACY_WORKFLOW_TEMPLATES/CHANGE_EXTERNAL_SUBMITTER_ENABLED';
export const changeExternalSubmitterEnabled = (enabled: boolean) =>
    createAction(CHANGE_EXTERNAL_SUBMITTER_ENABLED, {
        enabled,
    });

export const ASSIGN_EXTERNAL_REQUESTER = 'LEGACY_WORKFLOW_TEMPLATES/ASSIGN_EXTERNAL_REQUESTER';
export const assignExternalRequester = (userId: string) =>
    createAction(ASSIGN_EXTERNAL_REQUESTER, {
        userId,
    });

export const REMOVE_EXTERNAL_REQUESTER = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_EXTERNAL_REQUESTER';
export const removeExternalRequester = () => createAction(REMOVE_EXTERNAL_REQUESTER, {});

export const ADD_RB_SUBMITTER = 'LEGACY_WORKFLOW_TEMPLATES/ADD_RB_SUBMITTER';
export const addRBSubmitter = (userId: string) => createAction(ADD_RB_SUBMITTER, { userId });

export const REMOVE_RB_SUBMITTER = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_RB_SUBMITTER';
export const removeRBSubmitter = () => createAction(REMOVE_RB_SUBMITTER, {});

export const ADD_STEP_PARTICIPANT = 'LEGACY_WORKFLOW_TEMPLATES/ADD_STEP_PARTICIPANT';

interface AddStepParticipantAction {
    type: typeof ADD_STEP_PARTICIPANT;
    stepIndex: number;
    user: domain.User;
    isBackup: boolean;
}

export function addStepParticipant(stepIndex: number, user: domain.User, isBackup: boolean): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();

        const targetUser = selectors.user.findUserById(state, user.id) || user;

        dispatch<AddStepParticipantAction>({
            type: ADD_STEP_PARTICIPANT,
            stepIndex,
            user: targetUser,
            isBackup,
        });
    };
}

export const REMOVE_STEP_PARTICIPANT = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_STEP_PARTICIPANT';
export const removeStepParticipant = (stepIndex: number, user: domain.User, isBackup: boolean) =>
    createAction(REMOVE_STEP_PARTICIPANT, {
        stepIndex,
        user,
        isBackup,
    });

export const ADD_STEP_EDITOR = 'LEGACY_WORKFLOW_TEMPLATES/ADD_STEP_EDITOR';
export const addStepEditor = (stepIndex: number, user: domain.User) =>
    createAction(ADD_STEP_EDITOR, {
        stepIndex,
        user,
    });

export const REMOVE_STEP_EDITOR = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_STEP_EDITOR';
export const removeStepEditor = (stepIndex: number, user: domain.User) =>
    createAction(REMOVE_STEP_EDITOR, {
        stepIndex,
        user,
    });

export const ADD_STEP = 'LEGACY_WORKFLOW_TEMPLATES/ADD_STEP';

interface AddStepAction {
    type: typeof ADD_STEP;
    newStep: domain.TemplateStep;
}

export function addStep(): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();

        dispatch<AddStepAction>({
            type: ADD_STEP,
            newStep: factories.template.createStep(selectors.profile.getProfileUser(state).id),
        });
    };
}

export const ADD_AUTO_APPROVAL_STEP = 'LEGACY_WORKFLOW_TEMPLATES/ADD_AUTO_APPROVAL_STEP';

interface AddAutoApprovalAction {
    type: typeof ADD_AUTO_APPROVAL_STEP;
}

export function addAutoApprovalStep(): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();

        dispatch<AddAutoApprovalAction>({
            type: ADD_AUTO_APPROVAL_STEP,
        });
    };
}

export const DELETE_AUTO_APPROVAL_STEP = 'LEGACY_WORKFLOW_TEMPLATES/DELETE_AUTO_APPROVAL_STEP';

interface DeleteAutoApprovalAction {
    type: typeof DELETE_AUTO_APPROVAL_STEP;
}

export function deleteAutoApprovalStep(): ThunkAction<State> {
    return (dispatch, getState) => {
        dispatch<DeleteAutoApprovalAction>({
            type: DELETE_AUTO_APPROVAL_STEP,
        });
    };
}

export const FINALIZE_ADD_STEP = 'LEGACY_WORKFLOWS/FINALIZE_ADD_STEP';
export const finalizeAddStep = () => createAction(FINALIZE_ADD_STEP, {});

export const REMOVE_STEP = 'LEGACY_WORKFLOW_TEMPLATES/REMOVE_STEP';
export const removeStep = (stepIndex: number) =>
    createAction(REMOVE_STEP, {
        stepIndex,
    });

export const REORDER_STEPS = 'LEGACY_WORKFLOW_TEMPLATES/REORDER_STEPS';
export const reorderSteps = (oldIndex: number, newIndex: number) =>
    createAction(REORDER_STEPS, {
        oldIndex,
        newIndex,
    });

export const CHANGE_TEMPLATE_STEP_TYPE = 'LEGACY_WORKFLOWS/CHANGE_TEMPLATE_STEP_TYPE';
export const changeTemplateStepType = (stepIndex: number, newStepType: domain.TemplateStepType) =>
    createAction(CHANGE_TEMPLATE_STEP_TYPE, {
        stepIndex,
        newStepType,
    });

export const CHANGE_TEMPLATE_STEP_APPROVAL_COUNT = 'LEGACY_WORKFLOWS/CHANGE_TEMPLATE_STEP_APPROVAL_COUNT';
export const changeTemplateStepApprovalCount = (
    stepIndex: number,
    newStepApprovalCount: domain.TemplateStep['approvalCount']
) =>
    createAction(CHANGE_TEMPLATE_STEP_APPROVAL_COUNT, {
        stepIndex,
        newStepApprovalCount,
    });

export const CHANGE_STEP_DURATION = 'LEGACY_WORKFLOW_TEMPLATES/CHANGE_STEP_DURATION';
export const changeStepDuration = (
    stepIndex: number,
    newDuration: string,
    newDeadlineCalculator: domain.DeadlineRuleType | null = domain.DeadlineRuleType.onSubmission
) =>
    createAction(CHANGE_STEP_DURATION, {
        stepIndex,
        newDuration,
        newDeadlineCalculator,
    });

export const RENAME_STEP = 'LEGACY_WORKFLOW_TEMPLATES/RENAME_STEP';
export const renameStep = (stepIndex: number, newName: string) =>
    createAction(RENAME_STEP, {
        stepIndex,
        newName,
    });

export const CLOSE_ACTIVE_HAPPY_ACTIVATION_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/CLOSE_ACTIVE_HAPPY_ACTIVATION_POPUP';
export const closeActiveHappyActivationPopup = () => createAction(CLOSE_ACTIVE_HAPPY_ACTIVATION_POPUP, {});

export const OPEN_ACTIVE_VERSION_CONFLICT_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/OPEN_ACTIVE_VERSION_CONFLICT_POPUP';
export const openActiveVersionConflictPopup = (payload: OpenActiveVersionConflictPopup) =>
    createAction(OPEN_ACTIVE_VERSION_CONFLICT_POPUP, payload);

export const CLOSE_ACTIVE_VERSION_CONFLICT_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/CLOSE_ACTIVE_VERSION_CONFLICT_POPUP';
export const closeActiveVersionConflictPopup = () => createAction(CLOSE_ACTIVE_VERSION_CONFLICT_POPUP, {});

export const OPEN_COPY_TEMPLATE_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/OPEN_COPY_TEMPLATE_POPUP';
export const openCopyTemplatePopup = (template: selectors.types.ExpandedTemplate) =>
    createAction(OPEN_COPY_TEMPLATE_POPUP, { template });

export const DISCARD_COPY_TEMPLATE_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/DISCARD_COPY_TEMPLATE_POPUP';
export const discardCopyTemplatePopup = () => createAction(DISCARD_COPY_TEMPLATE_POPUP);

export const OPEN_ADD_COMMENT_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/OPEN_ADD_COMMENT_POPUP';
export const openAddCommentPopup = (postAction: AddCommentPopupPostAction) =>
    createAction(OPEN_ADD_COMMENT_POPUP, { postAction });

export const CLOSE_ADD_COMMENT_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/CLOSE_ADD_COMMENT_POPUP';
export const closeAddCommentPopup = () => createAction(CLOSE_ADD_COMMENT_POPUP, {});

export const OPEN_FIELD_SETTINGS_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/OPEN_FIELD_SETTINGS_POPUP';
export const openFieldSettingsPopup = (template: selectors.types.ExpandedTemplate) =>
    createAction(OPEN_FIELD_SETTINGS_POPUP, { template });

export const CLOSE_FIELD_SETTINGS_POPUP = 'LEGACY_WORKFLOW_TEMPLATES/CLOSE_FIELD_SETTINGS_POPUP';
export const closeFieldSettingsPopup = () => createAction(CLOSE_FIELD_SETTINGS_POPUP);

export const APPLY_FIELD_SETTINGS = 'LEGACY_WORKFLOW_TEMPLATES/APPLY_FIELD_SETTINGS';
export const applyFieldSettings = (fieldsSettings: domain.DocumentField[]) =>
    createAction(APPLY_FIELD_SETTINGS, { fieldsSettings });

export type Action =
    | ExtractActions<
          | typeof addPayer
          | typeof addRBSubmitter
          | typeof addStepEditor
          | typeof addSubmitter
          | typeof applyFieldSettings
          | typeof assignExternalRequester
          | typeof changeExternalSubmitterEnabled
          | typeof changeStepDuration
          | typeof changeTemplateStepApprovalCount
          | typeof changeTemplateStepType
          | typeof closeActiveHappyActivationPopup
          | typeof closeActiveVersionConflictPopup
          | typeof closeAddCommentPopup
          | typeof closeFieldSettingsPopup
          | typeof copyRuleToAnotherStepsUsers
          | typeof deleteTemplate
          | typeof discardCopyTemplatePopup
          | typeof finalizeAddStep
          | typeof openActiveVersionConflictPopup
          | typeof openAddCommentPopup
          | typeof openCopyTemplatePopup
          | typeof openFieldSettingsPopup
          | typeof removeExternalRequester
          | typeof removePayer
          | typeof removeRBSubmitter
          | typeof removeStep
          | typeof removeStepEditor
          | typeof removeStepParticipant
          | typeof removeSubmitter
          | typeof renameStep
          | typeof renameTemplate
          | typeof reorderSteps
          | typeof saveTemplate
      >
    | ShowTemplateAction
    | ValidateActiveTemplateAction
    | HideValidateErrors
    | AddNewStandaloneTemplateAction
    | DiscardTemplateChangesAction
    | AddAutoApprovalAction
    | AddStepParticipantAction
    | DeleteAutoApprovalAction
    | AddStepAction;
