import { Checkbox, Form } from '@approvalmax/ui/src/components';
import {
    addRBSubmitter,
    assignExternalRequester,
    removeExternalRequester,
    removeRBSubmitter,
    removeSubmitter,
} from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/actions';
import { getTemplateSubmitters } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/selectors/templateSelectors';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { FC, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { getPossibleSourceOfDocument } from '../../WorkflowWizardPopup.helpers';
import { SourceOfDocs } from '../../WorkflowWizardPopup.types';
import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import { formFieldName } from './SourceOfDocsStep.constants';
import { messages } from './SourceOfDocsStep.messages';
import { FormValues, SourceOfDocsStepProps } from './SourceOfDocsStep.types';

export const SourceOfDocsStep: FC<SourceOfDocsStepProps> = memo((props) => {
    const { template, defaultSourceOfDocs, amplitudeParams, onNext } = props;

    const form = useForm<FormValues>({
        defaultValues: {
            [formFieldName]: defaultSourceOfDocs ?? [],
        },
        resolver: (values) => {
            if (values?.[formFieldName]?.length > 0) {
                return {
                    values,
                    errors: {},
                };
            } else {
                return {
                    values: {},
                    errors: {
                        [formFieldName]: {
                            type: 'required',
                        },
                    },
                };
            }
        },
    });

    const dispatch = useDispatch();

    const profile = useSelector(selectors.profile.findProfile);
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const submitters = useSelector((state: State) => getTemplateSubmitters(state, company.id));

    const possibleSourceOfDocument = useMemo(() => getPossibleSourceOfDocument(template, company), [company, template]);

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: source of docs step', amplitudeParams);
    });

    const handleNext = form.handleSubmit((data) => {
        if (
            possibleSourceOfDocument.includes(SourceOfDocs.ApprovalMax) &&
            !data[formFieldName].includes(SourceOfDocs.ApprovalMax)
        ) {
            submitters.forEach((user) => {
                dispatch(removeSubmitter(user.id, user.databaseId));
            });
        }

        if (profile?.email) {
            if (possibleSourceOfDocument.includes(SourceOfDocs.Xero)) {
                dispatch(removeExternalRequester());

                if (data[formFieldName].includes(SourceOfDocs.Xero)) {
                    dispatch(assignExternalRequester(profile.email));
                }
            }

            if (possibleSourceOfDocument.includes(SourceOfDocs.Dext)) {
                dispatch(removeRBSubmitter());

                if (data[formFieldName].includes(SourceOfDocs.Dext)) {
                    dispatch(addRBSubmitter(profile.email));
                }
            }
        }

        onNext(data[formFieldName]);
    });

    return (
        <Form form={form} onSubmit={handleNext}>
            <ScrollableContent
                header={
                    <HeaderTitle
                        spacing='32 0'
                        title={messages.title}
                        subtitle={messages.subtitle}
                        subtitle2={messages.subtitle2}
                    />
                }
                footer={<FooterButtons isNextDisabled={!form.formState.isValid} isNextTypeSubmit />}
            >
                <Checkbox.Group name={formFieldName} direction='column' size='large'>
                    {possibleSourceOfDocument.map((value) => (
                        <Checkbox key={value} value={value}>
                            {value}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </ScrollableContent>
        </Form>
    );
});

SourceOfDocsStep.displayName = 'SourceOfDocsStep';
