import { Box, ConditionWrapper, Flex, Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { actions } from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PromotionButtons } from '../PromotionButtons/PromotionButtons';
import { SectionFeaturePromotion } from '../SectionFeaturePromotion/SectionFeaturePromotion';
import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { promoTemplateSettings } from './SectionGoodsReceivedNote.constants';
import { messages } from './SectionGoodsReceivedNote.messages';
import { GoodsReceivedNoteOption, SectionGoodsReceivedNoteProps } from './SectionGoodsReceivedNote.types';

export const SectionGoodsReceivedNote: FC<SectionGoodsReceivedNoteProps> = memo((props) => {
    const { links } = props;

    const company = 'promoView' in props ? props.company : null;
    const templateSettings = 'promoView' in props ? promoTemplateSettings : props.templateSettings;
    const readonly = 'promoView' in props ? true : props.readonly;
    const qa = 'promoView' in props ? null : props.qa;
    const onChange = 'promoView' in props ? null : props.onChange;
    const isPromoView = 'promoView' in props;

    const dispatch = useDispatch();

    const handleTryIt = useCallback(() => {
        if (!company) {
            return;
        }

        dispatch(actions.openConsentToStartTrialPopup(company, 'GRN_settings'));
    }, [company, dispatch]);

    const handleChange = useCallback(
        (value: GoodsReceivedNoteOption) => {
            if (!onChange) {
                return;
            }

            onChange((prevState) => ({
                ...prevState,
                isGrnEnabled: value === GoodsReceivedNoteOption.yes,
            }));
        },
        [onChange]
    );

    return (
        <ConditionWrapper
            condition={isPromoView}
            wrapperIf={(children) => (
                <SectionFeaturePromotion title={messages.title} onTryIt={handleTryIt}>
                    <Box spacing='0 24 16'>
                        <Flex inline spacing='24' direction='column'>
                            {children}
                        </Flex>
                    </Box>
                </SectionFeaturePromotion>
            )}
            wrapperElse={(children) => (
                <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.GoodsReceivedNote]}>
                    {children}
                </SettingsSection>
            )}
        >
            <>
                <SettingsSubsection subtitle={messages.subtitle} color={isPromoView ? 'midnight60' : undefined}>
                    <Radio.Group
                        disabled={readonly}
                        value={templateSettings.isGrnEnabled ? GoodsReceivedNoteOption.yes : GoodsReceivedNoteOption.no}
                        onChange={handleChange}
                        name='goodsReceivedNote'
                        block
                        data-qa={qa?.('good-recieved-note-section')}
                    >
                        <Radio value={GoodsReceivedNoteOption.yes}>{messages.optionTrue}</Radio>

                        <Radio value={GoodsReceivedNoteOption.no}>{messages.optionFalse}</Radio>
                    </Radio.Group>
                </SettingsSubsection>

                {isPromoView && (
                    <PromotionButtons
                        learnMoreLink={links[selectors.types.Link.GoodsReceivedNote]}
                        onClickTryIt={handleTryIt}
                    />
                )}
            </>
        </ConditionWrapper>
    );
});

SectionGoodsReceivedNote.displayName = 'SectionGoodsReceivedNote';
