import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, ADD_APPROVERS_AFTER_XERO_MATCHING_RESPONSE, APPLY_XERO_MATCHING_RESPONSE } from '../../../actions';

export const XERO_ADD_APPROVERS_FROM_PO_POPUP = 'XERO_ADD_APPROVERS_FROM_PO_POPUP';

export interface PossibleApprover {
    userId: Guid;
    reasonText: string;
}

export interface XeroAddApproversFromPoPopupData {
    id: typeof XERO_ADD_APPROVERS_FROM_PO_POPUP;
    targetRequest: Guid;
    affectedRequestIds: Guid[];
    possibleNewApprovers: PossibleApprover[];
}

export type XeroAddApproversFromPoPopupType = ImmutableObject<XeroAddApproversFromPoPopupData> | null;

export default function (state: XeroAddApproversFromPoPopupType, action: Action): XeroAddApproversFromPoPopupType {
    switch (action.type) {
        case APPLY_XERO_MATCHING_RESPONSE:
            if (!action.payload.shouldShowAddApproversFromPoPopup) {
                return state;
            }

            return immutable<XeroAddApproversFromPoPopupData>({
                id: XERO_ADD_APPROVERS_FROM_PO_POPUP,
                targetRequest: action.payload.request.targetRequest.id,
                affectedRequestIds: action.payload.affectedRequestIds,
                possibleNewApprovers: action.payload.possibleNewApprovers,
            });

        case ADD_APPROVERS_AFTER_XERO_MATCHING_RESPONSE:
            return null;

        default:
            return state;
    }
}
