import { Popup } from '@approvalmax/ui';
import { miscHelpers } from '@approvalmax/utils';
import { useSelector } from 'modules/react-redux';
import { FC, memo } from 'react';

import CollectAccountNurturingDataPopup from './containers/CollectAccountNurturingDataPopup/CollectAccountNurturingDataPopup';
import SelectRBPlanPopup from './containers/SelectRBPlanPopup/SelectRBPlanPopup';
import SelectSubscriptionPopup from './containers/SelectSubscriptionPopup';
import XeroDemoOrganisationPopup from './containers/XeroDemoOrganisationPopup/XeroDemoOrganisationPopup';
import { ActivePopup } from './reducers/moduleReducer';
import { getActivePopup } from './selectors/moduleSelectors';

const ModuleComponent: FC = () => {
    const activePopup = useSelector(getActivePopup);

    return (
        <>
            <Popup
                isOpen={activePopup === ActivePopup.SelectSubscriptionPopup}
                onRequestClose={miscHelpers.noop}
                disableAutoClose
            >
                <SelectSubscriptionPopup />
            </Popup>

            <Popup
                isOpen={activePopup === ActivePopup.SelectPlanPopup}
                onRequestClose={miscHelpers.noop}
                disableAutoClose
            >
                <SelectRBPlanPopup />
            </Popup>

            <Popup
                isOpen={activePopup === ActivePopup.XeroDemoOrganisationPopup}
                onRequestClose={miscHelpers.noop}
                disableAutoClose
            >
                <XeroDemoOrganisationPopup />
            </Popup>

            <Popup
                isOpen={activePopup === ActivePopup.CollectAccountNurturingDataPopup}
                onRequestClose={miscHelpers.noop}
                disableAutoClose
            >
                <CollectAccountNurturingDataPopup />
            </Popup>
        </>
    );
};

export default memo(ModuleComponent);
