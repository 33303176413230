import { apiSources, Env, getAppConfig } from './configs';
import { getApiUrl, setApiUrl } from './helpers/getApi';
import { createUseGetLazyRequest } from './methods/useGetLazyRequest';
import { createUseGetLazyRequestLegacy } from './methods/useGetLazyRequestLegacy';
import { createUseGetRequest } from './methods/useGetRequest';
import { createUseGetRequestLegacy } from './methods/useGetRequestLegacy';
import { createUseInfiniteGetRequest } from './methods/useInfiniteGetRequest';
import { createUseInfiniteGetRequestLegacy } from './methods/useInfiniteGetRequestLegacy';
import { createUseMutate } from './methods/useMutate';
import { createUseMutateLegacy } from './methods/useMutateLegacy';
import rootApi from './rootApi';
import rootApiLegacy from './rootApiLegacy';
import { ApiSource, ApiVersion } from './types';

const init = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    const getApiUrlInner = (options: { apiSource?: ApiSource; env?: Env; apiVersion?: ApiVersion } = {}) => {
        const { apiSource = defaultApiSource, env = defaultEnv, apiVersion = 'v1' } = options;

        return getApiUrl(apiSource, env, apiVersion);
    };

    const setApiUrlInner = (options: { apiUrl: string; reload?: boolean; apiSource?: ApiSource }) => {
        const { apiUrl, reload = false, apiSource = defaultApiSource } = options;

        setApiUrl(apiUrl, apiSource, reload);
    };

    const setApiUrlByEnv = (options: { env?: Env; reload?: boolean; apiSource?: ApiSource | 'all' } = {}) => {
        const { env = defaultEnv, apiSource = defaultApiSource, reload = false } = options;

        if (apiSource === 'all') {
            apiSources.forEach((apiSourceInner) => {
                const apiUrl = getAppConfig(env)[apiSourceInner];

                setApiUrl(apiUrl, apiSourceInner, reload);
            });
        } else {
            const apiUrl = getAppConfig(env)[apiSource];

            setApiUrl(apiUrl, apiSource, reload);
        }
    };

    const resetApiUrl = (options: { reload?: boolean; apiSource?: ApiSource | 'all' } = {}) => {
        const { reload = false, apiSource = defaultApiSource } = options;

        if (apiSource === 'all') {
            apiSources.forEach((apiSourceInner) => {
                setApiUrl(null, apiSourceInner, reload);
            });
        } else {
            setApiUrl(null, apiSource, reload);
        }
    };

    const useGetRequest = createUseGetRequest(defaultApiSource, defaultEnv);
    const useGetLazyRequest = createUseGetLazyRequest(defaultApiSource, defaultEnv);
    const useGetRequestLegacy = createUseGetRequestLegacy(defaultApiSource, defaultEnv);
    const useGetLazyRequestLegacy = createUseGetLazyRequestLegacy(defaultApiSource, defaultEnv);
    const useMutate = createUseMutate(defaultApiSource, defaultEnv);
    const useMutateLegacy = createUseMutateLegacy(defaultApiSource, defaultEnv);
    const useInfiniteGetRequest = createUseInfiniteGetRequest(defaultApiSource, defaultEnv);
    const useInfiniteGetRequestLegacy = createUseInfiniteGetRequestLegacy(defaultApiSource, defaultEnv);

    return {
        useGetRequest,
        useGetLazyRequest,
        useGetRequestLegacy,
        useGetLazyRequestLegacy,
        useMutate,
        useMutateLegacy,
        useInfiniteGetRequest,
        useInfiniteGetRequestLegacy,
        getApiUrl: getApiUrlInner,
        setApiUrl: setApiUrlInner,
        resetApiUrl,
        setApiUrlByEnv,
        rootApi,
        rootApiLegacy,
    };
};

export default init;
