import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.components.WorkflowWizardPopup', {
    title: 'Workflow setup',
    wizardStepTypeOfDocs: 'Type of docs',
    wizardStepSourceOfDocs: 'Source of docs',
    wizardStepApprovalSteps: 'Approval steps',
    wizardStepUsers: 'Users',
    wizardStepRequesters: 'Requesters',
    wizardStepConditions: 'Conditions',
    wizardStepPreview: 'Preview',
});
