import { actions } from 'modules/company';
import { useDispatch } from 'modules/react-redux';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { routingService } from 'services/routing';

import ChoicePopup from './components/ChoicePopup/ChoicePopup';
import ProcessingPopup from './components/ProcessingPopup/ProcessingPopup';
import { useXeroDemoDataCreator, XeroDataCreationStatus } from './hooks/useXeroDemoDataCreator';

const i18nPrefix = 'company/containers/XeroDemoOrganisationPopup/XeroDemoOrganisationPopup';

enum PopupStage {
    CHOICE = 'choice',
    PROCESSING = 'processing',
}

const XeroDemoOrganisationPopup: FC = () => {
    const [stage, setStage] = useState<PopupStage>(PopupStage.CHOICE);

    const dispatch = useDispatch();

    const { status, startDataCreation } = useXeroDemoDataCreator();

    useEffect(() => {
        if (stage === PopupStage.PROCESSING) {
            startDataCreation();
        }
    }, [stage, startDataCreation]);

    useEffect(() => {
        if (status === XeroDataCreationStatus.Done) {
            routingService.reloadToDefaultPath();
        } else if (status === XeroDataCreationStatus.Error) {
            window.location.reload();
        }
    }, [status]);

    const onChoiceSubmit = useCallback(
        (shouldPrepopulateData: boolean) => {
            if (shouldPrepopulateData) {
                setStage(PopupStage.PROCESSING);
            } else {
                dispatch(actions.closeActivePopup());
            }
        },
        [dispatch]
    );

    if (stage === PopupStage.CHOICE) {
        return <ChoicePopup onSubmit={onChoiceSubmit} />;
    }

    return <ProcessingPopup status={status} />;
};

export default memo(XeroDemoOrganisationPopup);
