/**
 * Developer: Stepan Burguchev
 * Date: 6/16/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { DateTimeIsoString, Guid } from '@approvalmax/types';

import { AddOnStatuses, AddonTypes, AddonUsageThresholds } from './Addon';
import { CompanyPlan, CompanyPlanEdition } from './Company';

export enum SubscriptionStatus {
    Active = 'Active',
    Grace = 'Grace',
    Expired = 'Expired',
    Canceled = 'Canceled',
    PendingCancellation = 'PendingCancellation',
}

export enum SubscriptionBillingCycle {
    Monthly = 'Monthly',
    Annually = 'Annually',
    OneTime = 'OneTime',
}

export enum SubscriptionBillingSystem {
    None = 'None',
    Direct = 'Direct',
    Avangate = 'Avangate',
    Stripe = 'Stripe',
    XeroAppStore = 'XeroAppStore',
}

export enum NextBillingCurrency {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    AUD = 'AUD',
    NZD = 'NZD',
    SGD = 'SGD',
    ZAR = 'ZAR',
}

export interface Subscription {
    id: Guid;
    systemId: number;
    accountOwnerId: Guid;
    plan: CompanyPlan;
    edition: CompanyPlanEdition;
    status: SubscriptionStatus;
    billingCycle: SubscriptionBillingCycle;
    billingSystem: SubscriptionBillingSystem;
    dueDate: DateTimeIsoString | null;
    nextBillingDate: DateTimeIsoString | null;
    nextBillingCurrency: NextBillingCurrency | null;
    companyIds: Guid[];
    quantity: number;
    capacity: number;
    isFrozenForUpgrade: boolean;
    addonStatistics: {
        subscriptionId: string;
        subscriptionAddonId: string;
        status: AddOnStatuses;
        feature: AddonTypes;
        usageThreshold: AddonUsageThresholds;
    }[];
}

export interface PricingOptions {
    newPlan: CompanyPlan;
    edition: CompanyPlanEdition;
    cycle: SubscriptionBillingCycle;
    currency: string;
    ranges: Array<{
        minOrganisations: number;
        maxOrganisations: number;
        pricePerOrganisation: number;
    }>;
}
