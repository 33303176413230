import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseTwoFaEnablingCodeVerifyRequest,
    UseTwoFaEnablingCodeVerifyResponse,
} from './useTwoFaEnablingCodeVerify.types';

export const useTwoFaEnablingCodeVerify = () => {
    return useMutateLegacy<RequestDataParams<UseTwoFaEnablingCodeVerifyRequest>, UseTwoFaEnablingCodeVerifyResponse>(
        twoFaApiPaths.enablingAppCodeVerify
    );
};
