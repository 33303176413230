export const transitionEnd = (el: any, callback: () => void, ignoreBubbled = false) => {
    // TODO: something is wrong here, addEventListener should accept other arguments
    function onTransitionEnd(e: any) {
        if (e.target !== el && ignoreBubbled) {
            return;
        }

        callback();
    }

    el.addEventListener(el, onTransitionEnd);
};
