import { backend, domain } from 'modules/data';

export const parseXeroPaymentTerm = (
    value: backend.IntegrationsXeroPaymentTermDetails
): domain.XeroContactPaymentTermDetails => {
    return {
        day: value.Day,
        termType: value.TermType,
    };
};
