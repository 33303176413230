import { Guid } from '@approvalmax/types';
import { stateTree } from 'modules/data';
import { createAction, ExtractActions } from 'modules/react-redux';

export const LOAD_PAGE_DATA = 'LEGACY_WORKFLOW_TEMPLATES/LOAD_PAGE_DATA';
export const loadPageData = (
    companyId: string,
    entities: Partial<stateTree.Entities> = {},
    innerActivePageId: string,
    sectionPageId: string
) => createAction(LOAD_PAGE_DATA, { companyId, entities, innerActivePageId, sectionPageId });

export const SHOW_WORKFLOWS_LIST_PAGE = 'LEGACY_WORKFLOWS/SHOW_WORKFLOWS_LIST_PAGE';
export const showWorkflowsListPage = () => createAction(SHOW_WORKFLOWS_LIST_PAGE, {});

export const UPDATE_TEMPLATE_GROUP_COLLAPSE_PREFERENCE = 'LEGACY_WORKFLOWS/UPDATE_TEMPLATE_GROUP_COLLAPSE_PREFERENCE';
export const updateTemplateGroupCollapsePreference = (groupId: string, companyId: Guid, collapsed: boolean) =>
    createAction(UPDATE_TEMPLATE_GROUP_COLLAPSE_PREFERENCE, {
        groupId,
        companyId,
        collapsed,
    });

export type Action = ExtractActions<
    typeof loadPageData | typeof showWorkflowsListPage | typeof updateTemplateGroupCollapsePreference
>;
