const amaxPayXeroRequestContextPrefix = 'requests/companies/:companyId/requests/:requestId/amaxPay/xero';

export const requestsApiPaths = {
    setCustomerDecisionInQuote: 'requests/setCustomerDecisionInQuote',
    getQuoteForCustomerDecision: 'requests/xero/getQuoteForCustomerDecision',
    setQuoteDecisionByCustomer: 'requests/xero/setQuoteDecisionByCustomer',
    getQuoteForCustomerDecisionPdf: 'requests/xero/getQuoteForCustomerDecisionPdf',
    resendQuoteForCustomerDecision: 'requests/resendQuoteForCustomerDecision',
    reverse: 'requests/reverse',
    changeWatchers: 'requests/changeWatchers',
    create: 'requests/create',
    currencies: 'companies/:companyId/:integrationCode/requests/:requestId/currencies',
    currencyExchangeRate: 'companies/:companyId/requests/:requestId/currencyExchangeRate',
    publish: 'requests/publish',
    checkAirwallexBeneficiaries: 'requests/airwallex/checkBeneficiaries',
    edit: 'requests/edit',
    delete: 'requests/delete',
    getAuditReportDetails: 'requests/getAuditReportDetails',
    importManualJournal: 'requests/xero/importManualJournal',
    airwallexSchedulePayment: 'requests/airwallex/schedulePayment',
    airwallexCancelScheduledPayment: 'requests/airwallex/cancelScheduledPayment',
    retry: 'requests/respond/retry',
    requestVersion: 'companies/:companyId/requests/:requestId/versions/:requestVersion',
    versions: 'companies/:companyId/requests/:requestId/versions',
    duplicatesQbookPO: 'companies/:companyId/qbooks/requests/duplicates',
    duplicatesXeroPO: 'companies/:companyId/xero/requests/duplicates',
    amaxPayXeroRequestDetails: `${amaxPayXeroRequestContextPrefix}/details`,
    amaxPayXeroBankAccounts: `${amaxPayXeroRequestContextPrefix}/bankAccounts`,
    amaxPayXeroPay: `${amaxPayXeroRequestContextPrefix}/pay`,
    amaxPayXeroRefresh: `${amaxPayXeroRequestContextPrefix}/payments/refresh`,
    amaxPayBillCancel: `${amaxPayXeroRequestContextPrefix}/cancel`,
    amaxPayXeroRefreshByPaymentId: 'requests/companies/:companyId/amaxPay/xero/payments/:paymentId/refresh',
    amaxPayPaymentDetailsDiscrepancies: `${amaxPayXeroRequestContextPrefix}/paymentDetails/discrepancies`,
    billRequestPaymentDetails: `${amaxPayXeroRequestContextPrefix}/bills/:billRequestId/paymentDetails`,
    amaxPayBatchPaymentFromBill: 'companies/:companyId/requests/:requestId/amaxPay/xero/batchPayments',
    amaxPayXeroTransactionDetails:
        'companies/:companyId/requests/:requestId/amaxPay/xero/bills/:billRequestId/transaction',
    searchRequests: 'search/searchRequests',
    getAttachment: 'requests/getAttachment',
    putOnHold: 'companies/:companyId/requests/:requestId/putOnHold',
    takeOffHold: 'companies/:companyId/requests/:requestId/takeOffHold',
};
