import uniqueId from 'lodash/uniqueId';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';

import { getColumnDefinitionByKind } from '../../config/standard/columnDefinitions';
import ColumnKind from '../../config/standard/columnKind';
import { ReportConfig } from '../../types/ReportConfig';
import { ReportConfigColumn } from '../../types/ReportConfigColumn';
import * as noneFilter from '../filters/noneFilter';
import { createDefaultColumns } from './reportConfigSchema';

const { TEMPLATE_ID_PREFIX } = constants.commonConstants;

export function createReportConfig(companyId: string, reportType: domain.ReportType): ReportConfig {
    const createdDate = moment.utc().toISOString();

    return {
        id: uniqueId(TEMPLATE_ID_PREFIX),
        name: '',
        companyId,
        createdDate,
        reportType,
        reportCode: null,
        lastRunDate: null,
        columns: createDefaultColumns(companyId, reportType),
    };
}

export function createCustomColumn(field: domain.Field): ReportConfigColumn {
    const colDef = getColumnDefinitionByKind(ColumnKind.CustomField);

    return {
        id: field.id,
        kind: colDef.kind,
        name: field.name,
        title: field.name,
        sortable: colDef.sortable,
        sorting: null,
        visible: colDef.visibleByDefault,
        filter: colDef.parseFilter!([], null as any, null as any),
    };
}

export function createQBooksCustomColumn(field: domain.Field): ReportConfigColumn {
    const colDef = getColumnDefinitionByKind(ColumnKind.QBooksCustomField);

    return {
        id: field.id,
        kind: colDef.kind,
        name: field.name,
        title: field.name,
        sortable: colDef.sortable,
        sorting: null,
        visible: colDef.visibleByDefault,
        filter: noneFilter.createAlwaysTrueFilter(),
    };
}
