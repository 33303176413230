import { mixins, Mods, mods, RequiredMods, theme } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled, { css, keyframes } from 'styled-components';

import { TextFieldProps } from './TextField.types';

export const DisabledCover = styled(Button)`
    ${mixins.position.absoluteFill};
    opacity: 0;
`;

export const Hint = styled.div<StyledMods<Mods<'invalid'>>>`
    margin-top: 8px;
    color: ${theme.color.midnight70};
    ${mixins.font('body', 'xsmall', 'regular')};

    ${mods.invalid.true`
        color: ${theme.color.red100};
    `}
`;

export const StartIcon = styled.div`
    transition: ${theme.duration.medium}ms;
    display: flex;
    align-items: center;
`;

export const EndIcon = styled.div`
    transition: ${theme.duration.medium}ms;
    display: flex;
    align-items: center;
`;

const ShowClear = keyframes`
    from {
        transform: scale(.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`;

export const Clear = styled.div`
    transition: ${theme.duration.medium}ms;
    animation: ${ShowClear} ${theme.duration.medium}ms;
    color: ${theme.color.midnight100};
    display: flex;
    align-items: center;

    button {
        display: inline-flex;

        &,
        &:focus,
        &:hover {
            background-color: transparent;
            color: inherit;
        }
    }
`;

export const Input = styled.input<Pick<TextFieldProps, 'disabled'> & Mods<'readOnly' | 'textAlign'>>`
    display: block;
    background-color: transparent;
    border: none;
    width: 100%;

    ::placeholder {
        color: ${theme.color.midnight60};
    }

    :-webkit-autofill,
    :autofill {
        transition: border ${theme.duration.medium}ms;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        box-shadow: 0 0 0 1000px white inset;
    }

    ${mods.readOnly.true`
        cursor: default;
    `}

    ${mods.textAlign(mixins.textAlign)};
`;
export const Textarea = styled.textarea<
    Pick<TextFieldProps, 'disabled'> & Mods<'readOnly'> & StyledMods<Pick<TextFieldProps, 'height' | 'maxHeight'>>
>`
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    resize: none;

    ::placeholder {
        color: ${theme.color.midnight60};
    }

    ${mods.readOnly.true`
        cursor: default;
    `};

    ${mods('height')(
        (value) => css`
            height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};

    ${mods('maxHeight')(
        (value) => css`
            max-height: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    padding: RequiredMods['spacing'],
    iconSize: RequiredMods['iconSize'],
    spaceBetween: RequiredMods['spacing']
) => css`
    padding-left: ${padding}px;
    padding-right: ${padding}px;
    column-gap: ${spaceBetween}px;
    ${mixins.font('label', fontSize)};

    ${Input} {
        height: ${({ theme }) => parseInt(theme.lineHeight.label[fontSize], 10) + padding * 2}px;
    }

    ${Input} {
        padding: ${padding}px 0;
    }

    ${Textarea} {
        padding: ${padding - 1}px;
    }

    ${EndIcon},
    ${StartIcon},
    ${Clear} {
        svg {
            ${mixins.iconSize(iconSize)};
        }
    }

    ${Clear},
    ${EndIcon} {
        button {
            height: calc(100% - 2px);
            margin-right: -${padding - 1}px;
            padding-right: ${padding - 1}px;
        }
    }
`;

const controlBorderedMixin = (color: RequiredMods['color']) => css`
    box-shadow: inset 0 0 0 1px ${theme.color[color]};

    ${Input} {
        :-webkit-autofill,
        :autofill {
            border-color: ${theme.color[color]};
        }
    }
`;

export const Control = styled.div<
    StyledMods<
        Pick<TextFieldProps, 'bordered' | 'invalid' | 'size' | 'focus' | 'disabled' | 'grow' | 'multiline' | 'cursor'>
    >
>`
    ${mods.size.xsmall(rootSizeMixin('xsmall', 4, 12, 2))};
    ${mods.size.small(rootSizeMixin('small', 8, 12, 4))};
    ${mods.size.medium(rootSizeMixin('medium', 12, 16, 8))};
    ${mods.size.large(rootSizeMixin('large', 16, 20, 8))};

    display: flex;
    transition: ${theme.duration.medium}ms;
    position: relative;
    flex-grow: ${({ $grow }) => $grow};
    width: 100%;

    ${mods('cursor')(
        (value) => css`
            &,
            ${Input}, ${Textarea} {
                cursor: ${value};
            }
        `
    )};

    ${mods.bordered.true`
        border-radius: ${theme.radius.xsmall};
        background-color: ${theme.color.white100};

        ${controlBorderedMixin('midnight50')};

        ${mods.focus.true`
            ${controlBorderedMixin('blue100')};
        `};

        ${mods.disabled.false`
            ${mods.invalid.false`
                &:hover {
                    ${controlBorderedMixin('black100')};
                }
            `}
        `}
    `};

    ${mods.bordered.false`
        padding-right: 0;
        padding-left: 0;
    `};

    ${mods('multiline', true)`
        padding: 1px;
    `}

    ${mods.invalid.true`
        background-color: ${theme.color.red20};

        ${EndIcon},
        ${StartIcon},
        ${Clear} {
            color: ${theme.color.red100};
        }

        ${mods.bordered.true`
            ${controlBorderedMixin('red100')};
        `};
    `};

    ${mods.disabled.true`
        background-color: ${theme.color.midnight30};
        color: ${theme.color.midnight70};
    `};
`;

export const Root = styled.div<StyledMods<Pick<TextFieldProps, 'width'>>>`
    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;
