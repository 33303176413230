import { Radio } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionHistoryPushing.messages';
import { SectionHistoryPushingProps } from './SectionHistoryPushing.types';

const SectionHistoryPushing: FC<SectionHistoryPushingProps> = (props) => {
    const { settings, onChangeHistoryPushingType, readonly, qa } = props;

    const value = settings?.historyEventsPushingType || domain.HistoryPushingType.None;

    return (
        <SettingsSection title={messages.title} data-qa={qa('history_pushing')}>
            <SettingsSubsection subtitle={messages.subtitle({ br: <br /> })}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={onChangeHistoryPushingType}
                    name='historyPushingType'
                    block
                >
                    <Radio value={domain.HistoryPushingType.None}>{messages.optionNoneText}</Radio>

                    <Radio value={domain.HistoryPushingType.Comments}>{messages.optionCommentsText}</Radio>

                    <Radio value={domain.HistoryPushingType.All}>{messages.optionAllText}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
};

export default memo(SectionHistoryPushing);
