import { Radio } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';

import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionUrlOverriding.messages';
import { SectionUrlOverridingOption, SectionUrlOverridingProps } from './SectionUrlOverriding.types';

const SectionUrlOverriding: FC<SectionUrlOverridingProps> = memo((props) => {
    const { isAppUrlOverridingEnabled, onChangeUrlOverridingPolicy, readonly, qa } = props;

    const value = isAppUrlOverridingEnabled ? SectionUrlOverridingOption.yes : SectionUrlOverridingOption.no;

    const handleChange = useCallback(
        (selectedValue: SectionUrlOverridingOption) => {
            switch (selectedValue) {
                case SectionUrlOverridingOption.yes:
                    onChangeUrlOverridingPolicy(true);
                    break;

                case SectionUrlOverridingOption.no:
                    onChangeUrlOverridingPolicy(false);
                    break;
            }
        },
        [onChangeUrlOverridingPolicy]
    );

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink='https://support.approvalmax.com/portal/en/kb/articles/linking-back-from-xero-to-approvalmax'
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={handleChange}
                    name='urlOverridingPolicy'
                    block
                    data-qa={qa('url_overriding')}
                >
                    <Radio value={SectionUrlOverridingOption.yes}>{messages.optionYes}</Radio>

                    <Radio value={SectionUrlOverridingOption.no}>{messages.optionNo}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionUrlOverriding;
