import { Button, Label } from '@approvalmax/ui/src/components';
import { CloseCircleFilledIcon, LockIcon } from '@approvalmax/ui/src/icons';
import { hooks } from '@approvalmax/utils';
import {
    forwardRef,
    ForwardRefExoticComponent,
    KeyboardEvent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    RefObject,
    useCallback,
    useEffect,
    useRef,
} from 'react';

import { Controller } from './components';
import { defaultTransform } from './TextField.helpers';
import { useClearable, useFocus, useResizeHeight, useValidate, useValue } from './TextField.hooks';
import { Clear, Control, DisabledCover, EndIcon, Hint, Input, Root, StartIcon, Textarea } from './TextField.styles';
import { ChildrenComponents, HTMLTextFieldElement, TextFieldProps } from './TextField.types';

/**
 * Text Fields let users enter and edit text.
 */
const TextField = memo(
    forwardRef((props, ref) => {
        const {
            onChange,
            onClear,
            onClick,
            bordered = true,
            size = 'medium',
            clearable = true,
            startIcon,
            endIcon,
            invalid,
            focus,
            initFocus,
            onFocus,
            onBlur,
            autoComplete = 'off',
            value,
            multiline,
            required,
            disabled,
            label,
            hint,
            minLength,
            onMouseEnter,
            onMouseLeave,
            width,
            height,
            grow,
            onEnter,
            onKeyDown,
            transform = defaultTransform,
            className,
            autoHeight,
            maxHeight = multiline && autoHeight ? '400px' : undefined,
            cursor,
            ...restProps
        } = props;

        const fieldRef = useRef<HTMLTextFieldElement>(null);
        const composedRefs = hooks.useComposedRefs(ref, fieldRef);

        const { handleChange, fieldValue } = useValue({ value, onChange, transform });
        const { handleClear } = useClearable({ handleChange, fieldRef, onClear });
        const { handleBlur, handleFocus, isFocus } = useFocus({
            fieldRef,
            onFocus,
            onBlur,
            focus,
            initFocus,
        });
        const { invalidValue } = useValidate({ invalid, minLength, fieldValue, isFocus });

        const fieldProps = {
            ...restProps,
            value: fieldValue,
            onChange: handleChange,
            onBlur: handleBlur,
            onFocus: handleFocus,
            autoComplete,
            disabled,
            pattern: undefined,
            validate: undefined,
        };

        const handleKeyDown = useCallback(
            (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onKeyDown?.(e);

                if (e.key === 'Enter') {
                    onEnter?.(e);
                }
            },
            [onKeyDown, onEnter]
        );

        const resizeHeight = useResizeHeight(fieldRef);

        useEffect(() => {
            if (multiline && autoHeight) {
                resizeHeight();
            }
        }, [multiline, autoHeight, resizeHeight, fieldValue]);

        return (
            <Root $width={width}>
                {label && (
                    <Label
                        size={size === 'xsmall' ? size : 'small'}
                        required={required}
                        spacing={bordered ? undefined : '0'}
                    >
                        {label}
                    </Label>
                )}

                <Control
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    $invalid={invalidValue}
                    $bordered={bordered}
                    $size={size}
                    $focus={isFocus}
                    $disabled={disabled}
                    $grow={grow}
                    $multiline={multiline}
                    $cursor={cursor}
                >
                    {startIcon && <StartIcon>{startIcon}</StartIcon>}

                    {multiline ? (
                        <Textarea
                            {...fieldProps}
                            $height={height}
                            $maxHeight={maxHeight}
                            onKeyDown={handleKeyDown}
                            ref={composedRefs as RefObject<HTMLTextAreaElement>}
                        />
                    ) : (
                        <Input
                            {...fieldProps}
                            onKeyDown={handleKeyDown}
                            ref={composedRefs as RefObject<HTMLInputElement>}
                        />
                    )}

                    {(endIcon || disabled) && <EndIcon>{disabled ? <LockIcon /> : endIcon}</EndIcon>}

                    {!disabled && !multiline && clearable && fieldValue && (
                        <Clear>
                            <Button onClick={handleClear} icon noPadding tabIndex={-1}>
                                <CloseCircleFilledIcon />
                            </Button>
                        </Clear>
                    )}

                    {disabled && <DisabledCover disabled />}
                </Control>

                {hint && <Hint $invalid={invalidValue}>{hint}</Hint>}
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<TextFieldProps & RefAttributes<HTMLTextFieldElement>>> &
    ChildrenComponents;

TextField.Controller = Controller;

export default TextField;
