import { Action, SIGNUP, SIGNUP_FAILURE, SIGNUP_RESPONSE } from '../../actions';

const INITIAL_STATE = false;

export default function (state = INITIAL_STATE, action: Action): boolean {
    switch (action.type) {
        case SIGNUP:
            return true;

        case SIGNUP_RESPONSE:
        case SIGNUP_FAILURE:
            return false;

        default:
            return state;
    }
}
