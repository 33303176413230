import { useCallback, useRef } from 'react';
import { useTwoFaEnablingEmailCodeVerify, useTwoFaEnablingEmailSet } from 'shared/data';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';

export const useStepEmailConfirm = (onNext: () => void) => {
    const { context, setContext } = useTwoFaEnablingWizardContext();
    const inputRef = useRef<HTMLInputElement>(null);

    const { mutate: sendCode, isError: isSendError, isLoading: isSendLoading } = useTwoFaEnablingEmailCodeVerify();
    const { mutate: resendCode, isLoading: isResendLoading } = useTwoFaEnablingEmailSet();

    const email = context.email?.trim() || '';

    const isLoading = isSendLoading || isResendLoading;
    const canSubmit = Boolean(email) && !isLoading;

    const onChange = useCallback(
        (value: string) => {
            setContext((prevContext) => ({ ...prevContext, emailCode: value }));
        },
        [setContext]
    );

    const onSubmit = useCallback(async () => {
        if (!canSubmit) return;

        const emailCode = context.emailCode.trim();
        const stateId = context.stateId;

        if (stateId && emailCode) {
            sendCode(
                {
                    data: { stateId, emailCode },
                },
                {
                    onSuccess: () => {
                        onNext();
                    },
                    onError: () => {
                        inputRef.current!.focus();
                    },
                }
            );
        }
    }, [canSubmit, context.emailCode, context.stateId, onNext, sendCode]);

    const onResend = useCallback(() => {
        const stateId = context.stateId;

        if (stateId && email) {
            resendCode({
                data: { stateId, email },
            });
        }
    }, [context.stateId, email, resendCode]);

    return {
        email: context.email,
        emailCode: context.emailCode,
        inputRef,
        onChange,
        onSubmit,
        onResend,
        isLoading,
        isError: isSendError,
        canSubmit,
    };
};
