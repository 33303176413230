import { AsElement, PolymorphicPropsWithRef, PolymorphicRef } from '@approvalmax/types';
import { ConditionWrapper, Tooltip } from '@approvalmax/ui/src/components';
import { ChevronDownIcon } from '@approvalmax/ui/src/icons';
import { Children, forwardRef, memo, useContext, useMemo } from 'react';

import { MenuContext } from '../../Menu.context';
import List from '../List/List';
import { useAnimation, useItem, useItemCaptureFocus } from './Item.hooks';
import {
    AnimatedBox,
    ClickableLayer,
    Content,
    EndIcon,
    EndText,
    Inner,
    Label,
    Labels,
    OpenIcon,
    Root,
    StartIcon,
    SubItems,
    SubLabel,
} from './Item.styles';
import { ItemComponent, ItemProps } from './Item.types';

/* The Item component allows you to create lists with clickable items */
export const Item = memo(
    forwardRef(
        <C extends AsElement = 'button'>(props: PolymorphicPropsWithRef<C, ItemProps>, ref: PolymorphicRef<C>) => {
            const {
                subItems,
                name,
                subName,
                endText,
                startIcon,
                endIcon,
                onClick,
                children,
                open,
                divider,
                color = 'silver80',
                size = 'medium',
                id,
                dataItemActive,
                onOpen,
                as,
                active,
                selected,
                disabled,
                title,
                tooltip,
                tooltipProps,
                ...restProps
            } = props;

            const itemContextProps = useContext(MenuContext);
            const { isOpenSubItems, handleClickLabel } = useItem({ open, onClick, id, onOpen });
            const hasSubItems = useMemo(
                () => Boolean(subItems?.items || Children.toArray(children).filter(Boolean).length),
                [children, subItems?.items]
            );
            const captureFocusProps = useItemCaptureFocus();
            const { subItemsRef, subItemsStyles } = useAnimation(isOpenSubItems);
            const As = as || 'button';

            return (
                <Root
                    title={tooltip ? undefined : title}
                    data-item-active={dataItemActive}
                    ref={ref}
                    $divider={itemContextProps.divider || divider}
                    $color={itemContextProps.color || color}
                    $size={itemContextProps.size || size}
                    $open={isOpenSubItems}
                    $active={active}
                    $selected={selected}
                    $disabled={disabled}
                    aria-expanded={hasSubItems ? isOpenSubItems : undefined}
                    aria-haspopup={hasSubItems ? 'menu' : undefined}
                    role='presentation'
                >
                    <ConditionWrapper
                        condition={tooltip}
                        wrapperIf={(children) => (
                            <Tooltip activator={children} {...tooltipProps}>
                                {title}
                            </Tooltip>
                        )}
                    >
                        <Inner {...captureFocusProps}>
                            {startIcon && <StartIcon>{startIcon}</StartIcon>}

                            <Content>
                                <Labels>
                                    <Label aria-labelledby='name'>{name}</Label>

                                    {subName && <SubLabel>{subName}</SubLabel>}
                                </Labels>

                                {endText && <EndText>{endText}</EndText>}
                            </Content>

                            {endIcon && <EndIcon>{endIcon}</EndIcon>}

                            {hasSubItems && (
                                <OpenIcon>
                                    <ChevronDownIcon size={20} />
                                </OpenIcon>
                            )}

                            {disabled ? (
                                <ClickableLayer {...restProps} disabled={disabled} role='menuitem' />
                            ) : (
                                <ClickableLayer {...restProps} as={As} onClick={handleClickLabel} role='menuitem' />
                            )}
                        </Inner>
                    </ConditionWrapper>

                    {hasSubItems && (
                        <AnimatedBox style={subItemsStyles}>
                            <SubItems ref={subItemsRef}>{subItems ? <List {...subItems} /> : children}</SubItems>
                        </AnimatedBox>
                    )}
                </Root>
            );
        }
    )
) as ItemComponent;

export default Item;
