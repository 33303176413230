import { ApiError, ErrorCode } from '@approvalmax/data';
import { usePopupContext } from '@approvalmax/ui';
import { dataHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { useDispatch, useSelector } from 'modules/react-redux';
import { sendChameleonEvent } from 'modules/utils/helpers/chameleon';
import { useCallback, useState } from 'react';
import { amplitudeService } from 'services/amplitude';
import { facebookService } from 'services/facebook';
import { gaService } from 'services/ga';
import { linkedInService } from 'services/linkedIn';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { useCheckDearConnection, useCreateCompany, useDearConnectToAccount, usePullIntegrations } from 'shared/data';
import { getPath, Path } from 'urlBuilder';

import { DearIntegrationData } from '../../../reducers/module/activePopup/dearIntegrationPopupReducer';
import { getActivePopup } from '../../../selectors/moduleSelectors';
import { IntegrationSuccessRedirectPage } from '../../../types/IntegrationSuccessRedirectPage';
import { messages } from './DearIntegrationPopup.messages';
import { DearIntegrationFormState } from './DearIntegrationPopup.types';

export const useDearIntegrationPopup = () => {
    const { onRequestClose } = usePopupContext();

    const { companyId, redirectPage } = useSelector((state) => getActivePopup<DearIntegrationData>(state));
    const { company, companyCreated, createCompany } = useIntegrationCompany(companyId);

    const { isLoading: isConnecting, mutateAsync: connectToAccount } = useConnect();
    const { isLoading: isChecking, mutateAsync: connectCheck } = useCheckDearConnection();

    const redirect = useIntegrationRedirect(redirectPage);

    const onCheck = useCallback(
        (formValues: DearIntegrationFormState) => {
            return connectCheck({ data: { ...formValues } });
        },
        [connectCheck]
    );

    const onConnect = useCallback(
        async (values: DearIntegrationFormState) => {
            const companyId = company ? company.id : (await createCompany()).id;

            await connectToAccount({ companyId, values });

            redirect(companyId);
        },
        [company, connectToAccount, createCompany, redirect]
    );

    const onClose = useCallback(() => {
        const companyId = companyCreated?.id || company?.id;

        if (companyId) {
            routingService.reloadToUrl(getPath(Path.companyInfo, companyId));
        }

        onRequestClose();
    }, [company, companyCreated, onRequestClose]);

    return {
        isLoading: isChecking || isConnecting,
        isConnecting,
        isChecking,
        onConnect,
        onCheck,
        onClose,
    };
};

export const useIntegrationRedirect = (redirectPage: DearIntegrationData['redirectPage']) => {
    return useCallback(
        (companyId: string) => {
            switch (redirectPage) {
                case IntegrationSuccessRedirectPage.Company:
                    routingService.reloadToUrl(getPath(Path.companyInfo, companyId));
                    break;

                case IntegrationSuccessRedirectPage.Workflow:
                    routingService.reloadToUrl(getPath(Path.companyWorkflows, companyId));
                    break;
            }
        },
        [redirectPage]
    );
};

export const useIntegrationCompany = (companyId: string | null) => {
    const { data, mutateAsync: create } = useCreateCompany();

    const company = useSelector((state) => {
        if (!companyId) {
            return null;
        }

        return selectors.company.getCompanyById(state, companyId);
    });

    const createCompany = useCallback(async () => {
        return create(
            { data: { timeZone: window.ApprovalMax.userTimeZone } },
            {
                onError: () => {
                    notificationService.showErrorToast(messages.errorCompanyCreate);
                },
            }
        );
    }, [create]);

    return {
        company,
        createCompany,
        companyCreated: data || null,
    };
};

export const useDearIntegrationForm = () => {
    const [errors, setErrors] = useState<Array<keyof DearIntegrationFormState>>([]);
    const [values, setValues] = useState<DearIntegrationFormState>({
        accountId: '',
        applicationKey: '',
    });

    const onChangeAccountId = useCallback((value: string) => {
        setValues((current) => ({ ...current, accountId: value }));
        setErrors((prevState) => prevState.filter((key) => key === 'accountId'));
    }, []);

    const onChangeApplicationKey = useCallback((value: string) => {
        setValues((current) => ({ ...current, applicationKey: value }));
        setErrors((prevState) => prevState.filter((key) => key === 'applicationKey'));
    }, []);

    const onValidate = useCallback(() => {
        const validationErrors: typeof errors = [];

        if (!dataHelpers.isGuid(values.accountId)) {
            validationErrors.push('accountId');

            notificationService.showErrorToast(messages.errorFieldAccountId);
        }

        if (!dataHelpers.isGuid(values.applicationKey)) {
            validationErrors.push('applicationKey');

            notificationService.showErrorToast(messages.errorFieldApplicationKey);
        }

        setErrors(validationErrors);

        return !Object.keys(validationErrors).length;
    }, [values]);

    return {
        values,
        errors,
        onChangeAccountId,
        onChangeApplicationKey,
        validate: onValidate,
    };
};

export const useConnect = () => {
    const { mutateAsync: pullIntegrations } = usePullIntegrations();

    const dispatch = useDispatch();

    const profile = useSelector(selectors.profile.getProfile);

    const account = profile.account;

    return useDearConnectToAccount({
        onSuccess: async (response, data) => {
            if (response?.Companies?.length > 0) {
                const company = response.Companies.find((item) => item.CompanyId === data.companyId);
                const integration = company?.Integrations.length > 0 ? company.Integrations[0] : null;

                if (integration) {
                    await pullIntegrations({
                        companyId: data.companyId,
                        integrationIds: [integration.IntegrationId],
                    });
                }
            }

            amplitudeService.sendData('workflows list: complete connection', {
                'connection type': 'dear',
            });
            sendChameleonEvent('connected_gl');

            if (!account) {
                facebookService.trackSignUp();
                linkedInService.trackSignUp();

                await gaService.sendEvent('org_created', {
                    integration: 'cin7_core',
                });
            }
        },
        onError: (err: ApiError) => {
            if (err?.code === ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED) {
                if (err?.code === ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED) {
                    dispatch(integrationActions.showAnotherCompanyIntegratedPopup(domain.IntegrationType.Dear));

                    return;
                }

                return;
            }

            if (err?.code === ErrorCode.E5016_BOUND_COMPANY_ABOUT_TO_CHANGE) {
                dispatch(integrationActions.showCompanyMismatchPopup(domain.IntegrationType.Dear));

                return;
            }
        },
    });
};
