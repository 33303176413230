import each from 'lodash/each';
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';
import { FC, PropsWithChildren, useEffect } from 'react';
import { $PropertyType } from 'utility-types';

export interface GlobalHotKeysProps extends PropsWithChildren {
    handlers: {
        [key: string]: (e: ExtendedKeyboardEvent, combo: string) => any;
    };
    onlyTriggerWhenNoFocus?: boolean;
    disabled?: boolean;
}

const _originalStopCallback = Mousetrap.prototype?.stopCallback;

const GlobalHotKeys: FC<GlobalHotKeysProps> = (props) => {
    const { children, handlers, onlyTriggerWhenNoFocus, disabled = false } = props;

    useEffect(() => {
        function createMousetrap(
            handlers: $PropertyType<GlobalHotKeysProps, 'handlers'>,
            onlyTriggerWhenNoFocus?: boolean
        ) {
            const mouseTrapInstance = new Mousetrap(window as any);

            mouseTrapInstance.stopCallback = (...args) => {
                if (disabled) {
                    return true;
                }

                if (!onlyTriggerWhenNoFocus) {
                    return false;
                }

                return _originalStopCallback.call(mouseTrapInstance, ...args);
            };

            each(handlers, (handler, key) => {
                mouseTrapInstance.bind(key, handler);
            });

            return mouseTrapInstance;
        }

        const mouseTrap = createMousetrap(handlers, onlyTriggerWhenNoFocus);

        return () => {
            mouseTrap.reset();
        };
    }, [disabled, handlers, onlyTriggerWhenNoFocus]);

    return <>{children}</>;
};

export default GlobalHotKeys;
