import { Checkbox } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import styled from 'styled-components';

import { RenderCellOptions } from '../../../config/matrixDefinitions.shared';

const Root = styled.div`
    flex: none;
    width: 200px;
    padding: 8px 15px 12px 15px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

const StyledCheckbox = styled(Checkbox)`
    flex-shrink: 0;
`;

const LabelText = styled.div`
    margin-left: 5px;
`;

interface CheckboxCellProps extends RenderCellOptions {
    label: string;
}

const CheckboxCell: FC<CheckboxCellProps> = (props) => {
    const { rule, field, readonly, lineId, condition, onConditionChange, label } = props;

    const onChange = () => {
        const newCondition = {
            ...condition,
            conditionType: domain.ConditionType.ExactValuesCondition,
            allowEditing: !condition.allowEditing,
            exactValues: [],
        } as domain.ExactValuesCondition;

        onConditionChange(lineId, rule, field, newCondition);
    };

    return (
        <Root>
            <CheckboxContainer onClick={!readonly ? onChange : undefined}>
                <StyledCheckbox checked={Boolean(condition.allowEditing)} disabled={readonly} />

                <LabelText>{label}</LabelText>
            </CheckboxContainer>
        </Root>
    );
};

export default memo(CheckboxCell);
