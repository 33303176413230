import { domain } from 'modules/data';

export const possibleXeroWorkflowsForWizard = [
    domain.IntegrationCode.XeroPo,
    domain.IntegrationCode.XeroBill,
    domain.IntegrationCode.XeroQuote,
    domain.IntegrationCode.XeroInvoice,
    domain.IntegrationCode.XeroCreditNotesPayable,
    domain.IntegrationCode.XeroCreditNotesReceivable,
];

export const possibleQbooksWorkflowsForWizard = [
    domain.IntegrationCode.QBooksPo,
    domain.IntegrationCode.QBooksBill,
    domain.IntegrationCode.QBooksExpense,
];

export const storageKeyWorkflowWizardClosedCount = 'workflowWizardClosedCount';
export const sessionKeyWorkflowWizardClosedTime = 'workflowWizardClosedTime';
export const workflowWizardMaxCloseCount = 5;
