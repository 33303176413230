import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getActiveTemplate, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getTemplateExternalSubmitter } from '../../selectors/templateSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import NetSuiteExternalSubmitterSection from '../NetSuiteExternalSubmitterSection/NetSuiteExternalSubmitterSection';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import { messages } from './NetSuiteStartStep.messages';
import { NetSuiteStartStepProps } from './NetSuiteStartStep.types';

const NetSuiteStartStep = memo<NetSuiteStartStepProps>((props) => {
    const { showCreateInAMAX = false } = props;

    const template = useSelector(getActiveTemplate)!;
    const externalSubmitter = useSelector(getTemplateExternalSubmitter);
    const showValidationErrors = useSelector(showCardValidationErrors);

    const [collapsedPullingSection, setCollapsedPullingSection] = useState<boolean>();
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedPullingSection(collapsed);
        setCollapsedSubmitterSection(collapsed);
    }, []);

    const allCollapsed = showCreateInAMAX
        ? collapsedPullingSection && collapsedSubmitterSection
        : collapsedPullingSection;

    return (
        <FixedStepContainer bubbleText={messages.bubbleText} color='brandNetSuite100'>
            {showCreateInAMAX && <CollapseAllButton collapsed={Boolean(allCollapsed)} onCollapse={onCollapse} />}

            {showCreateInAMAX && (
                <StepSubmitterSection
                    cacheId='NetSuiteStartStepStepSubmitter'
                    required
                    headerText={messages.createInAmBubbleText}
                    description={messages.submitterListDescriptionNonEmpty({
                        templateName: template.displayNamePlural,
                    })}
                    descriptionEmpty={messages.submitterListDescriptionEmpty({
                        templateName: template.displayNamePlural,
                    })}
                    collapsed={collapsedSubmitterSection}
                    onCollapse={setCollapsedSubmitterSection}
                />
            )}

            <NetSuiteExternalSubmitterSection
                cacheId='NetSuiteStartStepExternalSubmitter'
                collapsed={collapsedPullingSection}
                onCollapse={setCollapsedPullingSection}
            />
        </FixedStepContainer>
    );
});

export default NetSuiteStartStep;
