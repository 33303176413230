import { TextField } from '@approvalmax/ui/src/components';
import { xeroConstants } from 'modules/common/constants';
import { memo, useCallback } from 'react';

import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionTerms.messages';
import { SectionTermsrProps } from './SectionTerms.types';

const SectionTerms = memo<SectionTermsrProps>((props) => {
    const { templateSettings, onChangeTerms, readonly, qa } = props;

    const handleChangeTerms = useCallback(
        (value?: string) => {
            onChangeTerms(value || '');
        },
        [onChangeTerms]
    );

    return (
        <SettingsSection title={messages.title} data-qa={qa('terms')}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <TextField
                    value={templateSettings?.terms || ''}
                    placeholder={messages.placeholder}
                    onChange={handleChangeTerms}
                    maxLength={xeroConstants.TERMS_MAX_LENGTH}
                    disabled={readonly}
                    multiline
                    autoHeight
                    clearable={false}
                />
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionTerms;
