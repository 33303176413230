import { stateTree } from 'modules/data';
import { ImmutableObject, merge } from 'modules/immutable';

import { pageConfig } from '../../config';
import { Action, SHOW_SIGNUP_MODULE } from '../actions';

export default function (
    state: ImmutableObject<stateTree.Navigation>,
    action: Action
): ImmutableObject<stateTree.Navigation> {
    switch (action.type) {
        case SHOW_SIGNUP_MODULE:
            return merge(state, {
                activePageId: pageConfig.id,
                innerActivePageId: action.payload.innerPageId || '',
                activeCompany: null,
            });

        default:
            return state;
    }
}
