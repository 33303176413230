import { AxiosError, AxiosResponse } from 'axios';

import { ApiBaseAnswer, ApiError, ApiVersion, ErrorCode } from '../types';

export const mapToApiError = <TResponseData>(
    response?: AxiosResponse<TResponseData & ApiBaseAnswer>,
    version?: ApiVersion
): ApiError => {
    const error = new Error(response?.data.errorMsg || response?.data.ErrorMsg || response?.data.title) as ApiError;

    error.version = version || 'v1';
    error.title = response?.data.errorMsg || response?.data.ErrorMsg || response?.data.title || 'Unknown error';
    error.detail =
        response?.data.errorDetails ||
        response?.data.ErrorDetails ||
        response?.data.detail ||
        response?.statusText ||
        response?.data.errorMsg ||
        response?.data.ErrorMsg ||
        'Something went wrong';
    error.code =
        response?.data.errorCode ||
        response?.data.ErrorCode ||
        response?.data.code ||
        response?.data.error?.code ||
        ErrorCode.UNKNOWN_ERROR;
    error.status = response?.status || 500;
    error.errors = response?.data.Errors;
    error.error = response?.data.error;
    error.billInvoiceRequestIds = response?.data.BillInvoiceRequestIds || response?.data.billInvoiceRequestIds;
    error.parameterName = response?.data.ParameterName || response?.data.parameterName;
    error.validationErrors = response?.data.validationErrors || response?.data.ValidationErrors;
    error.type = response?.data.type;

    if (response?.data.BeneficiaryDiscrepancies && response?.data.BeneficiaryDiscrepancies.length > 0) {
        error.beneficiaryDiscrepancies = response.data.BeneficiaryDiscrepancies;
    }

    if (response?.data.DeletedBeneficiaries && response?.data.DeletedBeneficiaries.length > 0) {
        error.deletedBeneficiaries = response.data.DeletedBeneficiaries;
    }

    if (response?.data.deletedPaymentDetails && response?.data.deletedPaymentDetails.length > 0) {
        error.deletedPaymentDetails = response.data.deletedPaymentDetails;
    }

    if (response?.data.paymentDetailsDiscrepancies && response?.data.paymentDetailsDiscrepancies.length > 0) {
        error.paymentDetailsDiscrepancies = response.data.paymentDetailsDiscrepancies;
    }

    return error;
};

export const legacyErrorHandler = <TResponseData>(response: AxiosResponse<TResponseData & ApiBaseAnswer>) => {
    if (response.data.Result !== 'OK' && response.data.result !== 'OK') {
        const error = mapToApiError(response);

        console.error(error);

        throw error;
    }

    return response;
};

export const errorCatch = <TResponseData>(
    error: AxiosError<TResponseData & ApiBaseAnswer, any> | ApiError,
    version: ApiVersion
): ApiError => {
    if ('isAxiosError' in error && error.isAxiosError) {
        const apiError = mapToApiError(error.response, version);

        return apiError;
    } else if ('title' in error) {
        return error;
    } else {
        const error = new Error('Unknown error') as ApiError;

        error.title = 'Unknown error';
        error.code = ErrorCode.UNKNOWN_ERROR;
        error.detail = 'Something went wrong';

        return error;
    }
};
