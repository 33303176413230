/**
 * Developer: Stepan Burguchev
 * Date: 4/4/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Command } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createSelector } from 'reselect';

import { createHelpItemId, WorkflowHelpItemType } from '../helpItems';
import { getActiveTemplate, pageLoading } from './pageSelectors';
import * as templateSelectors from './templateSelectors';

function getCreateTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    return {
        disabled: !canUpdateTemplate,
        hidden: !isNewTemplate,
    };
}

function getSaveDraftTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    return {
        disabled: !canUpdateTemplate,
        hidden: isNewTemplate || template!.enabled,
    };
}

function getUpdateEnabledTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    return {
        disabled: !canUpdateTemplate,
        hidden: isNewTemplate || !template!.enabled,
    };
}

function getDiscardTemplateChangesCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    return {
        disabled: !canUpdateTemplate || !hasChanges || isNewTemplate,
        hidden: false,
    };
}

function getDeleteTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    const isStandalone =
        selectors.integration.getIntegrationType(template!.integrationCode) === domain.IntegrationType.None;

    return {
        disabled: !canUpdateTemplate || isNewTemplate,
        hidden: !isStandalone,
    };
}

function getStartOverCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    // It means: Relaunch approval workflow for requests that are running with old workflow settings
    const isStandalone =
        selectors.integration.getIntegrationType(template!.integrationCode) === domain.IntegrationType.None;

    return {
        disabled: !canUpdateTemplate || hasChanges || !template!.enabled || !template!.hasOutdatedRequests,
        hidden: isStandalone || isNewTemplate,
    };
}

function getEnableTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    return {
        disabled: !canUpdateTemplate,
        hidden: template!.enabled,
    };
}

function getDisableTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean
): Command {
    return {
        disabled: !canUpdateTemplate,
        hidden: !template!.enabled,
    };
}

function getOpenTemplateSettingsCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    isNewTemplate: boolean,
    hasChanges: boolean,
    company?: selectors.types.ExpandedCompany
): Command {
    const enabled = canUpdateTemplate || company?.flags.isAuditor;

    return {
        disabled: !enabled,
        hidden: false,
    };
}

const getUpdateFieldSettingsCommand = (template: domain.Template, canUpdateTemplate: boolean): Command => {
    return {
        disabled: !canUpdateTemplate,
        hidden: domain.getIntegrationTypeByCode(template.integrationCode) !== domain.IntegrationType.NetSuite,
    };
};

function getShowHelpCommand(template: domain.Template, hiddenHelpItems: string[]): Command {
    const itemId = createHelpItemId(WorkflowHelpItemType.Card, template.integrationCode);

    return {
        disabled: !hiddenHelpItems.includes(itemId),
    };
}

function getExportPdfCommand(template: domain.Template, isNewTemplate: boolean, hasChanges: boolean): Command {
    return {
        disabled: !(!isNewTemplate && !hasChanges),
        hidden: false,
    };
}

function getCopyTemplateCommand(
    template: domain.Template,
    canUpdateTemplate: boolean,
    company?: domain.Company
): Command {
    return {
        disabled: !canUpdateTemplate,
        hidden: false,
    };
}

export interface AllCommands {
    createTemplate: Command;
    saveDraftTemplate: Command;
    updateEnabledTemplate: Command;
    discardTemplateChanges: Command;
    deleteTemplate: Command;
    startOver: Command;
    enableTemplate: Command;
    disableTemplate: Command;
    openTemplateSettings: Command;
    showHelp: Command;
    exportPdf: Command;
    copyTemplate: Command;
    updateFieldSettings: Command;
}

export const getAllCommands: (state: State) => AllCommands = createSelector(
    getActiveTemplate,
    selectors.company.canUpdateActiveCompanySettings,
    pageLoading,
    templateSelectors.hasChanges,
    selectors.userPreferences.getHiddenHelpItems,
    selectors.company.getCompanies,
    (templateMaybe, canUpdateCompanySettings, loading, hasChanges, hiddenHelpItems, companies) => {
        const template = templateMaybe!;
        const canUpdateTemplate = canUpdateCompanySettings && !loading;
        const isNewTemplate = template.isNew;
        const company = companies.find((item) => item.id === template.companyId);

        return {
            createTemplate: getCreateTemplateCommand(template, canUpdateTemplate, isNewTemplate, hasChanges),
            saveDraftTemplate: getSaveDraftTemplateCommand(template, canUpdateTemplate, isNewTemplate, hasChanges),
            updateEnabledTemplate: getUpdateEnabledTemplateCommand(
                template,
                canUpdateTemplate,
                isNewTemplate,
                hasChanges
            ),
            discardTemplateChanges: getDiscardTemplateChangesCommand(
                template,
                canUpdateTemplate,
                isNewTemplate,
                hasChanges
            ),
            deleteTemplate: getDeleteTemplateCommand(template, canUpdateTemplate, isNewTemplate, hasChanges),
            startOver: getStartOverCommand(template, canUpdateTemplate, isNewTemplate, hasChanges),
            enableTemplate: getEnableTemplateCommand(template, canUpdateTemplate, isNewTemplate, hasChanges),
            disableTemplate: getDisableTemplateCommand(template, canUpdateTemplate, isNewTemplate, hasChanges),
            openTemplateSettings: getOpenTemplateSettingsCommand(
                template,
                canUpdateTemplate,
                isNewTemplate,
                hasChanges,
                company
            ),
            showHelp: getShowHelpCommand(template, hiddenHelpItems),
            exportPdf: getExportPdfCommand(template, isNewTemplate, hasChanges),
            copyTemplate: getCopyTemplateCommand(template, canUpdateTemplate, company),
            updateFieldSettings: getUpdateFieldSettingsCommand(template, canUpdateTemplate),
        };
    }
);
