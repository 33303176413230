import { mixins, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { BoxProps } from './Box.types';

export const Root = styled.div<
    StyledMods<
        Pick<
            BoxProps,
            | 'width'
            | 'height'
            | 'bordered'
            | 'borderInside'
            | 'borderColor'
            | 'color'
            | 'shadow'
            | 'radius'
            | 'textAlign'
            | 'fontWeight'
            | 'maxWidth'
        > & {
            spacing?: string;
        }
    >
>`
    padding: ${({ $spacing }) => $spacing};

    ${mods.textAlign(mixins.textAlign)};

    ${mods.fontWeight(mixins.fontWeight)};

    ${mods.color(mixins.colorTextWithBg)};

    ${mods.shadow(mixins.shadow)};

    ${mods.radius(mixins.radius)};

    ${mods.bordered.true`
        ${mods('borderInside', true)`
            box-shadow: inset 0 0 0 1px ${theme.color.midnight50};
        `};

        ${mods('borderInside', false)`
            border: 1px solid ${theme.color.midnight50};
        `};
    `};

    ${mixins.whByModValue('width')};
    ${mixins.whByModValue('height')};
    ${mixins.whByModValue('maxWidth', 'max-width')};

    ${mods('borderColor')(
        (value) => css`
            border-color: ${theme.color[value]};
        `
    )}
`;
