import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseTwoFaDisablingVerifyBackupCodeRequest,
    UseTwoFaDisablingVerifyBackupCodeResponse,
} from './useTwoFaDisablingVerifyBackupCode.types';

export const useTwoFaDisablingVerifyBackupCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseTwoFaDisablingVerifyBackupCodeRequest>,
        UseTwoFaDisablingVerifyBackupCodeResponse
    >(twoFaApiPaths.disableTFAWithBackupCode);
};
