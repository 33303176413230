import { Identifiable, Reference } from '@approvalmax/types';
import { compareHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';

import { CopyRulePopupCellProps } from '../components/CopyRulePopup/CopyRulePopup.types';

const statusPriorityMap = {
    [domain.CompanyUserStatus.NotInvited]: 0,
    [domain.CompanyUserStatus.Rejected]: 0,
    [domain.CompanyUserStatus.Invited]: 1,
    [domain.CompanyUserStatus.Active]: 2,
};

const statusPriorityDisregardingNotInvitedMap = {
    [domain.CompanyUserStatus.NotInvited]: 1,
    [domain.CompanyUserStatus.Rejected]: 1,
    [domain.CompanyUserStatus.Invited]: 1,
    [domain.CompanyUserStatus.Active]: 2,
};

export function createUserComparator(
    team: selectors.types.ExpandedCompanyUser[],
    options: { disregardNotInvited?: boolean } = {}
) {
    const priorityMap = options.disregardNotInvited ? statusPriorityDisregardingNotInvitedMap : statusPriorityMap;

    return (a: selectors.types.ExpandedUser, b: selectors.types.ExpandedUser): number => {
        // user A
        let priorityA;
        let statusA;

        const memberA = team.find((m) => m.id === a.id);

        if (memberA) {
            statusA = memberA.status;
        } else {
            statusA = domain.CompanyUserStatus.Active;
        }

        priorityA = priorityMap[statusA];

        let priorityB;
        // user B
        let statusB;

        const memberB = team.find((m) => m.id === b.id);

        if (memberB) {
            statusB = memberB.status;
        } else {
            statusB = domain.CompanyUserStatus.Active;
        }

        priorityB = priorityMap[statusB];

        if (priorityA !== priorityB) {
            return priorityA < priorityB ? -1 : 1;
        }

        const nameA = statusA === domain.CompanyUserStatus.Active ? a.displayName : a.userEmail;
        const nameB = statusB === domain.CompanyUserStatus.Active ? b.displayName : b.userEmail;

        return compareHelpers.stringComparator2AscI(nameA, nameB);
    };
}

const possibleWorkflowTypePairs: [domain.IntegrationCode | null, domain.IntegrationCode | null][] = [
    [domain.IntegrationCode.XeroCreditNotesReceivable, domain.IntegrationCode.XeroCreditNotesPayable],
    [domain.IntegrationCode.XeroCreditNotesReceivable, domain.IntegrationCode.XeroInvoice],
    [domain.IntegrationCode.XeroCreditNotesPayable, domain.IntegrationCode.XeroInvoice],
];

export function checkCopySettingsPossibility(
    fromWorkflow: domain.Template | null,
    toWorkflow: domain.Template | null
): boolean {
    if (!fromWorkflow || !toWorkflow) {
        return false;
    }

    if (fromWorkflow?.integrationCode === toWorkflow?.integrationCode) {
        return true;
    }

    return (
        possibleWorkflowTypePairs.findIndex((pair) => {
            return pair.includes(fromWorkflow.integrationCode) && pair.includes(toWorkflow.integrationCode);
        }) !== -1
    );
}

export const calculateSelectedSteps = (steps: CopyRulePopupCellProps['meta']['steps'], values: Identifiable[]) => {
    const selectedIds = values ? values.map((value) => value.id) : [];

    return selectedIds.reduce<CopyRulePopupCellProps['meta']['steps']>((total, valueId) => {
        const step = steps.find((step) => step.id === valueId);

        if (step) {
            total.push(step);
        }

        return total;
    }, []);
};

export const calculateSelectedUsers = (team: selectors.types.ExpandedCompanyUser[], values: string[]) => {
    return values.reduce<selectors.types.ExpandedCompanyUser[]>((total, id) => {
        const user = team.find((teamMember) => teamMember.id === id);

        if (user) {
            total.push(user);
        }

        return total;
    }, []);
};

export const prepareMatrixesForComparison = (matrixTransfer: backend.transfers.TemplateStepParticipantTransfer[]) => {
    return [...matrixTransfer]
        .sort(compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'email'))
        .map((matrixLine) => ({
            ...matrixLine,
            rules: matrixLine.rules.map((rule) => ({
                conditions: [...rule.conditions].sort(
                    compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'fieldId')
                ),
            })),
        }));
};

export const prepareAutoapprovalRulesForComparison = (
    autoapprovalRules: backend.transfers.TemplateAutoApproveTransfer[]
) =>
    [...autoapprovalRules]
        .sort(compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'name'))
        .map((matrixLine) => ({
            ...matrixLine,
            conditions: [...matrixLine.conditions].sort(
                compareHelpers.comparatorFor(compareHelpers.stringComparator2AscI, 'fieldId')
            ),
        }));

export const prepareStepsForComparison = (steps: backend.transfers.TemplateStepTransfer[]) =>
    steps.map((step) => ({
        ...step,
        id: undefined,
        participants: prepareMatrixesForComparison(step.participants),
        editors: prepareMatrixesForComparison(step.editors),
    }));

export const mapExactValuesForRequesterSystemPurpose = (
    exactValues: Reference[],
    users: selectors.types.ExpandedUser[]
) => {
    return exactValues.map((val) => {
        const user = users.find((u) => u.databaseId === val.id);

        return {
            id: val.id,
            text: user?.displayName || val.text,
        };
    });
};
