import { Action } from '../../actions/index';
import { SHOW_WORKFLOWS_LIST_PAGE } from '../../actions/landing';
import { OPEN_APPROVAL_MATRIX, OPEN_EDITORS_MATRIX, OPEN_SUBMITTER_MATRIX } from '../../actions/matrix';
import { DELETE_TEMPLATE, HIDE_VALIDATE_ERRORS, SAVE_TEMPLATE, VALIDATE_ACTIVE_TEMPLATE } from '../../actions/template';

export default function (state: boolean = false, action: Action): boolean {
    switch (action.type) {
        case OPEN_APPROVAL_MATRIX:
        case OPEN_EDITORS_MATRIX:
        case OPEN_SUBMITTER_MATRIX:
        case DELETE_TEMPLATE:
        case SAVE_TEMPLATE:
        case SHOW_WORKFLOWS_LIST_PAGE:
        case HIDE_VALIDATE_ERRORS:
            return false;

        case VALIDATE_ACTIVE_TEMPLATE:
            return !action.isValid;

        default:
            return state;
    }
}
