import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.EditPaymentDetailsPopupContent', {
    titleEdit: 'Edit payment details',
    titleNew: 'New payment details',
    save: 'Save',
    helpText: 'What are contact payment details?',
    learnMore: 'Learn more',
    inputPlaceholder: 'Enter...',
    selectPlaceholder: 'Select...',
    defaultCheckbox: 'Set as default',
    bankAccountCountry: 'Bank account country',
    bankAccountCurrency: 'Bank account currency',
    accountName: 'Account name',
    accountNamePlaceholder: 'Business or person name of payee...',
    bankAccountNumber: 'Bank account number',
    sortCode: 'Sort code',
    remove: 'Remove payment details',
    sortCodeValidationError: 'Sort code should be 6 digits',
    bankAccountNumberValidationError: 'Bank account number should be 8 digits',
    bankAccountNameValidationError: 'Bank account name should be 2-200 characters long',
});
