import { ComponentErrorBoundary as ComponentErrorBoundaryUi } from '@approvalmax/ui/src/components';
import * as profileModule from 'modules/profile';
import { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

export const ComponentErrorBoundary: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const dispatch = useDispatch();

    const showContactSupportPopup = () => dispatch(profileModule.loadContactSupportPopup());

    return <ComponentErrorBoundaryUi onContactSupport={showContactSupportPopup}>{children}</ComponentErrorBoundaryUi>;
};

ComponentErrorBoundary.displayName = 'ComponentErrorBoundary';
