import {
    Action,
    ADD_NEW_STANDALONE_TEMPLATE,
    DELETE_TEMPLATE_RESPONSE,
    SAVE_TEMPLATE_RESPONSE,
    SHOW_WORKFLOW_PAGE,
    SHOW_WORKFLOWS_LIST_PAGE,
} from '../../actions';

export interface ActiveTemplateLastVersion {
    templateId: string | null;
    version: number | null;
}

const activeTemplateLastVersionReducer = (
    state: ActiveTemplateLastVersion = { templateId: null, version: null },
    action: Action
): ActiveTemplateLastVersion => {
    switch (action.type) {
        case SAVE_TEMPLATE_RESPONSE:
            return {
                templateId: action.payload.templateId,
                version: action.payload.raw.Template.Version,
            };

        case ADD_NEW_STANDALONE_TEMPLATE:
            return {
                templateId: action.newTemplate.id,
                version: action.newTemplate.version ?? null,
            };

        case SHOW_WORKFLOWS_LIST_PAGE:
        case DELETE_TEMPLATE_RESPONSE:
            return {
                templateId: null,
                version: null,
            };

        case SHOW_WORKFLOW_PAGE: {
            if (state.version && action.template.version && state.version > action.template.version) {
                return state;
            }

            return {
                templateId: action.template.id,
                version: action.template.version ?? null,
            };
        }

        default:
            return state;
    }
};

export default activeTemplateLastVersionReducer;
