import { Reference } from '@approvalmax/types';
import { ButtonElement, Dropdown, DropdownBoxItemProps, DropdownEditor } from '@approvalmax/ui';
import { arrayHelpers, errorHelpers } from '@approvalmax/utils';
import { dataProviders, selectors } from 'modules/common';
import { domain } from 'modules/data';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { conditionTypeTextMap, options } from './NetSuiteVendorCreationConditionCell.constants';
import { messages } from './NetSuiteVendorCreationConditionCell.messages';
import {
    CheckboxBox,
    CheckboxContainer,
    CheckboxText,
    ConditionDropdownBtn,
    ConditionTypePanel,
    ConditionTypePanelItem,
    Root,
} from './NetSuiteVendorCreationConditionCell.styles';
import {
    ExpandedConditionType,
    NetSuiteVendorCreationConditionCellProps,
} from './NetSuiteVendorCreationConditionCell.types';

const qa = bemFactory.qa('wfc-supplier-creation-condition-cell');

const NetSuiteVendorCreationConditionCell = memo<NetSuiteVendorCreationConditionCellProps>((props) => {
    const [isConditionTypeDropdownOpen, setIsConditionTypeDropdownOpen] = useState(false);

    const conditionTypeDropdownRef = useRef<ButtonElement | null>(null);

    const { condition, field, lineId, rule, readonly, integrationCode, hideCreateContactCheckbox, onConditionChange } =
        props;

    const effectiveConditionType = useMemo<ExpandedConditionType>(() => {
        switch (condition.conditionType) {
            case null:
                return ExpandedConditionType.AllVendors;

            case domain.ConditionType.ExactValuesCondition:
                return ExpandedConditionType.ExactValues;

            case domain.ConditionType.NegativeExactValuesCondition:
                return ExpandedConditionType.NegativeExactValues;

            case domain.ConditionType.ServerCondition:
                switch (condition.serverConditionType) {
                    case domain.ServerConditionType.AllContacts:
                        return ExpandedConditionType.AllVendors;

                    default:
                        throw errorHelpers.invalidOperationError();
                }

                break;

            default:
                throw errorHelpers.invalidOperationError();
        }
    }, [condition]);

    const preventBlur = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
    }, []);

    const baseConditionFields = useMemo(() => {
        return {
            fieldId: condition.fieldId,
            fieldName: condition.fieldName,
            fieldSystemPurpose: condition.fieldSystemPurpose,
        };
    }, [condition]);

    const changeConditionType = useCallback(
        (expandedConditionType: ExpandedConditionType) => {
            let newCondition;

            switch (expandedConditionType) {
                case ExpandedConditionType.AllVendors:
                    if (condition.allowCreation) {
                        newCondition = {
                            ...baseConditionFields,
                            conditionType: domain.ConditionType.ServerCondition,
                            serverConditionType: domain.ServerConditionType.AllContacts,
                        } as domain.ServerCondition;
                    } else {
                        newCondition = {
                            ...baseConditionFields,
                            conditionType: null,
                        } as domain.AlwaysTrueCondition;
                    }

                    (newCondition as any).exactValues = [];
                    break;

                case ExpandedConditionType.ExactValues:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: domain.ConditionType.ExactValuesCondition,
                        // this update is important since DropdownEditor is a PureComponent
                        // and doesn't know that its BoxItem depends on conditionType
                        exactValues: arrayHelpers.cloneImmutableArray(
                            (condition as domain.ExactValuesCondition).exactValues || []
                        ),
                    } as domain.ExactValuesCondition;
                    break;

                case ExpandedConditionType.NegativeExactValues:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: domain.ConditionType.NegativeExactValuesCondition,
                        // this update is important since DropdownEditor is a PureComponent
                        // and doesn't know that its BoxItem depends on conditionType
                        exactValues: arrayHelpers.cloneImmutableArray(
                            (condition as domain.ExactValuesCondition).exactValues || []
                        ),
                    } as domain.ExactValuesCondition;
                    break;

                default:
                    throw errorHelpers.invalidOperationError();
            }

            onConditionChange(lineId, rule, field, newCondition);

            conditionTypeDropdownRef.current?.blur();
        },
        [condition, lineId, rule, field, baseConditionFields, onConditionChange]
    );

    const changeExactValues = useCallback(
        (exactValues: Reference[]) => {
            let newCondition = {
                ...condition,
                exactValues,
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, lineId, rule, field, onConditionChange]
    );

    const toggleAllowCreation = useCallback(() => {
        const newAllowCreation = !condition.allowCreation;

        let newCondition;

        if (newAllowCreation) {
            newCondition = {
                ...baseConditionFields,
                conditionType: domain.ConditionType.ServerCondition,
                serverConditionType: domain.ServerConditionType.AllContacts,
                allowCreation: true,
            } as domain.ServerCondition;
        } else {
            newCondition = {
                ...baseConditionFields,
                conditionType: null,
            } as domain.AlwaysTrueCondition;
        }

        onConditionChange(lineId, rule, field, newCondition);
    }, [baseConditionFields, condition, lineId, rule, field, onConditionChange]);

    const openConditionTypeDropdown = useCallback(() => {
        setIsConditionTypeDropdownOpen(true);
    }, []);

    const closeConditionTypeDropdown = useCallback(() => {
        setIsConditionTypeDropdownOpen(false);
    }, []);

    const renderBoxItem = useCallback(
        (boxItemProps: DropdownBoxItemProps) => {
            const negative = condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;

            return (
                <DropdownEditor.BoxItem
                    {...boxItemProps}
                    theme={negative ? DropdownEditor.BoxItemTheme.Grey : DropdownEditor.BoxItemTheme.Green}
                />
            );
        },
        [condition]
    );

    const conditionTypeText = conditionTypeTextMap[effectiveConditionType];
    const isEmptyCondition = effectiveConditionType === ExpandedConditionType.AllVendors;
    const invalid = !selectors.matrix.isValidCondition(condition);

    return (
        <Root data-qa={qa()} data-qa-id={field.id} data-qa-name={field.name}>
            <Dropdown
                onRequestClose={closeConditionTypeDropdown}
                panelFlow='to-right'
                isOpen={isConditionTypeDropdownOpen}
                button={
                    <ConditionDropdownBtn
                        execute={openConditionTypeDropdown}
                        onFocus={openConditionTypeDropdown}
                        onBlur={closeConditionTypeDropdown}
                        disabled={readonly}
                        ref={conditionTypeDropdownRef}
                        qa={qa('condition-type-menu')}
                        title={conditionTypeText}
                        $empty={isEmptyCondition}
                        $readOnly={readonly}
                    >
                        {conditionTypeText}
                    </ConditionDropdownBtn>
                }
            >
                <ConditionTypePanel onMouseDown={preventBlur}>
                    {options.map((option) => {
                        return (
                            <ConditionTypePanelItem
                                key={option}
                                data-qa={qa(`condition-type-dropdown-item-${option}`)}
                                onClick={() => changeConditionType(option)}
                            >
                                {conditionTypeTextMap[option]}
                            </ConditionTypePanelItem>
                        );
                    })}
                </ConditionTypePanel>
            </Dropdown>

            {(effectiveConditionType === ExpandedConditionType.ExactValues ||
                effectiveConditionType === ExpandedConditionType.NegativeExactValues) && (
                <dataProviders.FieldDataProvider field={field} integrationCode={integrationCode}>
                    <DropdownEditor
                        multiple
                        theme='matrix'
                        invalid={invalid}
                        placeholder={messages.exactValuesPlaceholder({
                            fieldName: (field.name || '').toLowerCase(),
                        })}
                        disabled={readonly}
                        value={(condition as domain.ExactValuesCondition).exactValues}
                        qa={qa('values-editor')}
                        onChange={changeExactValues}
                        boxItem={renderBoxItem}
                    />
                </dataProviders.FieldDataProvider>
            )}

            {effectiveConditionType === ExpandedConditionType.AllVendors && !hideCreateContactCheckbox && (
                <CheckboxContainer
                    onClick={!readonly ? toggleAllowCreation : undefined}
                    data-qa={qa('allow-creation')}
                    $readOnly={readonly}
                >
                    <CheckboxBox checked={condition.allowCreation} disabled={readonly} />

                    <CheckboxText>{messages.ruleEditorAllowCreateCheck}</CheckboxText>
                </CheckboxContainer>
            )}
        </Root>
    );
});

export default NetSuiteVendorCreationConditionCell;
