import { errorHelpers } from '@approvalmax/utils';

import DateRangeFilter from '../../components/filters/DateRangeFilter';
import NumberRangeFilter from '../../components/filters/NumberRangeFilter';
import ReferenceListFilter from '../../components/filters/ReferenceListFilter';
import ReferenceValueFilter from '../../components/filters/ReferenceValueFilter';
import StringFilter from '../../components/filters/StringFilter';
import UserListFilter from '../../components/filters/UserListFilter';
import { ReportConfigColumn } from '../reportConfig';
import * as dateRangeFilter from './dateRangeFilter';
import FilterContext from './FilterContext';
import FilterType from './FilterType';
import * as noneFilter from './noneFilter';
import * as numberRangeFilter from './numberRangeFilter';
import * as referenceListFilter from './referenceListFilter';
import * as referenceValueFilter from './referenceValueFilter';
import * as stringFilter from './stringFilter';
import * as userListFilter from './userListFilter';

export type Filter =
    | referenceListFilter.ReferenceListFilter
    | referenceValueFilter.ReferenceValueFilter
    | userListFilter.UserListFilter
    | userListFilter.UserListByIdFilter
    | dateRangeFilter.DateRangeFilter
    | numberRangeFilter.NumberRangeFilter
    | noneFilter.NoneFilter
    | stringFilter.StringFilter;

export { FilterType };

export function getFilterPreviewText(filter: Filter, context: FilterContext): string | null {
    switch (filter.type) {
        case FilterType.ReferenceList:
            return referenceListFilter.getFilterPreviewText(filter);

        case FilterType.ReferenceValue:
            return referenceValueFilter.getFilterPreviewText(filter);

        case FilterType.DateRange:
            return dateRangeFilter.getFilterPreviewText(filter);

        case FilterType.NumberRange:
            return numberRangeFilter.getFilterPreviewText(filter);

        case FilterType.UserList:
            return userListFilter.getFilterPreviewText(filter, context.companyTeam);

        case FilterType.UserIdsList:
            return userListFilter.getFilterByIdPreviewText(filter, context.companyTeam);

        case FilterType.None:
            return null;

        case FilterType.String:
            return stringFilter.getFilterPreviewText(filter);

        default:
            throw errorHelpers.invalidOperationError();
    }
}

export function getFilterValue(filter: Filter) {
    switch (filter.type) {
        case FilterType.ReferenceValue:
            return filter.value.id;

        case FilterType.String:
            return filter.value;

        case FilterType.ReferenceList:
        case FilterType.DateRange:
        case FilterType.NumberRange:
        case FilterType.UserList:
        case FilterType.UserIdsList:
            throw errorHelpers.notSupportedError();

        case FilterType.None:
        default:
            return null;
    }
}

export function createAlwaysTrueFilter(filterType: FilterType): Filter {
    switch (filterType) {
        case FilterType.ReferenceList:
            return referenceListFilter.createAlwaysTrueFilter();

        case FilterType.ReferenceValue:
            return referenceValueFilter.createAlwaysTrueFilter();

        case FilterType.DateRange:
            return dateRangeFilter.createAlwaysTrueFilter();

        case FilterType.NumberRange:
            return numberRangeFilter.createAlwaysTrueFilter();

        case FilterType.UserList:
            return userListFilter.createAlwaysTrueFilter();

        case FilterType.UserIdsList:
            return userListFilter.createAlwaysTrueFilterById();

        case FilterType.None:
            return noneFilter.createAlwaysTrueFilter();

        case FilterType.String:
            return stringFilter.createAlwaysTrueFilter();

        default:
            throw errorHelpers.invalidOperationError();
    }
}

export function createFilterComponent(
    column: ReportConfigColumn,
    context: FilterContext,
    onFilterChange: (filter: Filter) => void
) {
    switch (column.filter.type) {
        case FilterType.ReferenceList:
            return (
                <ReferenceListFilter
                    filter={column.filter}
                    onFilterChange={onFilterChange}
                    options={context.options}
                    dataProvider={context.dataProvider}
                    field={context.field}
                />
            );

        case FilterType.ReferenceValue: {
            if (!context.options) {
                throw errorHelpers.invalidOperationError(`Missing 'options' in definitions for column ${column.name}.`);
            }

            return (
                <ReferenceValueFilter
                    filter={column.filter}
                    onFilterChange={onFilterChange}
                    options={context.options}
                />
            );
        }

        case FilterType.DateRange:
            return <DateRangeFilter filter={column.filter} onFilterChange={onFilterChange} />;

        case FilterType.NumberRange:
            return <NumberRangeFilter filter={column.filter} onFilterChange={onFilterChange} />;

        case FilterType.UserList:
        case FilterType.UserIdsList:
            return (
                <UserListFilter filter={column.filter} onFilterChange={onFilterChange} options={context.companyTeam} />
            );

        case FilterType.String:
            return <StringFilter filter={column.filter} onFilterChange={onFilterChange} />;

        default:
            throw errorHelpers.invalidOperationError();
    }
}
