import { FileSearchIcon, FilesIcon, FilesQuestionIcon, LockIcon } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { CompanyRequestListFilter } from 'pages/requestList/config';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { getPath, Path } from 'urlBuilder';

import { messages } from './AllRequestsMenu.messages';

const qa = bemFactory.qa('requests-section');

export const useRequestsLinkItems = (company: selectors.types.ExpandedCompany | null) => {
    const companyId = company?.id;
    const showMyReviewRequiredLink = company && selectors.company.getIsReviewStepAvailable(company);
    const showOnHoldLink = company && selectors.company.getIsOnHoldStatusAvailable(company);

    return useMemo(
        () =>
            companyId
                ? [
                      {
                          id: CompanyRequestListFilter.CompanyAll,
                          name: messages.allRequestsName,
                          to: getPath(Path.requestCompanyList, companyId, CompanyRequestListFilter.CompanyAll),
                          startIcon: FilesIcon,
                          dataQa: qa(),
                          dataQaName: 'all',
                      },
                      {
                          id: CompanyRequestListFilter.CompanyMyDecisionRequired,
                          name: messages.requiresMyDecisionName,
                          to: getPath(
                              Path.requestCompanyList,
                              companyId,
                              CompanyRequestListFilter.CompanyMyDecisionRequired
                          ),
                          startIcon: FilesQuestionIcon,
                          dataQa: qa(),
                          dataQaName: 'requiresMyDecision',
                      },
                      ...(showMyReviewRequiredLink
                          ? [
                                {
                                    id: CompanyRequestListFilter.CompanyMyReviewRequired,
                                    name: messages.requiresMyReviewName,
                                    to: getPath(
                                        Path.requestCompanyList,
                                        companyId,
                                        CompanyRequestListFilter.CompanyMyReviewRequired
                                    ),
                                    startIcon: FileSearchIcon,
                                    dataQa: qa(),
                                    dataQaName: 'requiresMyReview',
                                },
                            ]
                          : []),

                      ...(showOnHoldLink
                          ? [
                                {
                                    id: CompanyRequestListFilter.CompanyAllOnHold,
                                    name: messages.onHold,
                                    to: getPath(
                                        Path.requestCompanyList,
                                        companyId,
                                        CompanyRequestListFilter.CompanyAllOnHold
                                    ),
                                    startIcon: LockIcon,
                                    dataQa: qa(),
                                    dataQaName: 'onHold',
                                },
                            ]
                          : []),
                  ]
                : [],
        [companyId, showMyReviewRequiredLink, showOnHoldLink]
    );
};
