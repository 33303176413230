import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { addReviewStep } from '../../../../actions';
import { messages } from './ReviewStepActivator.messages';
import { ReviewStepActivatorProps } from './ReviewStepActivator.types';

export const ReviewStepActivator: FC<ReviewStepActivatorProps> = memo((props) => {
    const { hide, integrationCode } = props;

    const dispatch = useDispatch();

    const onEnable = useCallback(() => {
        dispatch(addReviewStep());
        hide();
    }, [dispatch, hide]);

    const description = useMemo(() => {
        switch (integrationCode) {
            case domain.IntegrationCode.XeroBill:
            case domain.IntegrationCode.QBooksBill:
                return messages.billMatchingDescription;

            default:
                return messages.description({
                    templateName: selectors.template.getTemplateDisplayNameByCode(integrationCode, true),
                });
        }
    }, [integrationCode]);

    return (
        <Box color='midnight20' spacing='8' width={220} height='fit-content' radius='xsmall'>
            <Flex inline justifyContent='space-between' alignItems='center' wrap={false}>
                <Text font='label' fontSize='small' color='midnight80'>
                    {messages.enableReviewStep}
                </Text>

                <Button icon noPadding onClick={hide}>
                    <CloseIcon size={12} color='midnight80' />
                </Button>
            </Flex>

            <Spacing height={8} />

            <Text font='body' fontSize='small' color='midnight70'>
                {description}
            </Text>

            <Spacing height={4} />

            <Flex inline spacing='8' justifyContent='end'>
                <Button color='transparent' size='xsmall' onClick={hide}>
                    {messages.hide}
                </Button>

                <Button outline color='blue80' size='xsmall' onClick={onEnable}>
                    {messages.enable}
                </Button>
            </Flex>
        </Box>
    );
});

ReviewStepActivator.displayName = 'ReviewStepActivator';
