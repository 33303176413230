import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export const isAllFeaturesTrialStartAllowed = (
    company: selectors.types.ExpandedCompany,
    integrationType: domain.IntegrationType.Xero | domain.IntegrationType.QBooks
) => {
    const isQbooks = integrationType === domain.IntegrationType.QBooks;
    const standardPlan = isQbooks ? domain.CompanyPlan.QBooks : domain.CompanyPlan.Xero;

    return (
        company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation &&
        // only for admins
        company.flags.isManager &&
        company.licenseProductPlan === standardPlan
    );
};
