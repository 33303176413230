import { mixins, mods, theme } from '@approvalmax/theme';
import { DatePickerProps } from '@approvalmax/ui/src/components/DatePicker/DatePicker.types';
import TextField from '@approvalmax/ui/src/components/TextField/TextField';
import { CalendarIcon } from '@approvalmax/ui/src/icons';
import { default as ReactCalendar } from 'react-calendar';
import styled from 'styled-components';

export const Icon = styled(CalendarIcon)`
    cursor: pointer;
`;

export const Activator = styled(TextField)<Pick<DatePickerProps, 'view'>>`
    ${mods('view', 'year')`
        cursor: pointer;
    `};
`;

export const Calendar = styled(ReactCalendar)`
    width: 312px;
    max-width: 100%;
    padding: 16px;

    .react-calendar {
        &--doubleView {
            width: 624px;
        }

        &--doubleView &__viewContainer {
            display: flex;

            > * {
                width: 50%;
            }
        }

        button {
            margin: 0;
            border: 0;
            outline: none;
            display: inline-flex;

            &:enabled:hover {
                cursor: pointer;
            }
        }

        &__navigation {
            display: flex;
            margin-bottom: 16px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: ${theme.radius.xsmall};
                cursor: pointer;
                transition: ${theme.duration.medium}ms;

                &:disabled {
                    color: ${theme.color.midnight50};
                    cursor: default;
                }

                &:enabled:hover {
                    background-color: ${theme.color.silver80};
                }
            }

            &__label {
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${theme.color.midnight90};
                ${mixins.font('label', 'medium', 'medium')};
            }

            &__next2-button,
            &__prev2-button,
            &__next-button,
            &__prev-button {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                color: ${theme.color.midnight80};
                font-size: 20px;
            }
        }

        &__month-view__weekdays {
            text-align: center;
            color: ${theme.color.midnight70};
            ${mixins.font('label', 'small', 'regular')};
        }

        &__month-view__weekdays__weekday {
            width: 40px;
            flex: initial !important;
            margin-bottom: 8px;

            abbr {
                text-decoration: none;
            }
        }

        &__month-view {
            &__days {
                &__day {
                    width: 40px;
                    color: ${theme.color.midnight80};

                    &--weekend {
                        color: ${theme.color.red80};
                    }

                    &--neighboringMonth {
                        color: ${theme.color.midnight60};
                    }
                }
            }
        }

        &__year-view {
            > * {
                height: 160px;
            }

            .react-calendar__tile {
                width: 33.333%;
            }
        }

        &__decade-view {
            > * {
                height: 160px;
            }

            .react-calendar__tile {
                width: 33.333%;
            }
        }

        &__century-view {
            > * {
                height: 200px;
            }

            .react-calendar__tile {
                width: 50%;
            }
        }

        &__year-view,
        &__decade-view,
        &__century-view {
            > * {
                flex-direction: column;
            }

            .react-calendar__tile {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__tile {
            background: none;
            height: 40px;
            border-radius: ${theme.radius.xsmall};
            flex: initial !important;
            cursor: pointer;
            transition: ${theme.duration.medium}ms;

            > abbr {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            &:disabled {
                color: ${theme.color.midnight50};
                cursor: default;
            }

            &:enabled:hover {
                background-color: ${theme.color.silver80};
            }

            &--now {
                box-shadow: inset 0 0 0 1px ${theme.color.blue80};

                &:enabled:hover {
                    box-shadow: inset 0 0 0 1px ${theme.color.blue100};
                    background-color: transparent;
                }
            }

            &--hasActive,
            &--active {
                background: ${theme.color.blue80};
                color: ${theme.color.white100};

                &:enabled:hover {
                    background: ${theme.color.blue100};
                }
            }
        }

        &--selectRange .react-calendar__tile--hover {
            background-color: red;
        }
    }
`;
