import { toast } from '@approvalmax/ui/src/components';
import { actions as automationActions } from 'modules/automation';
import { actions } from 'modules/common';
import { openTrialPeriodStartedPopup } from 'modules/profile/actions';
import { useDispatch } from 'modules/react-redux';
import { useEffect, useRef, useState } from 'react';
import { useGetCompanies, useGetUserContext } from 'shared/data';

import { checkActivateTrialInterval, maxReRequestsWaitActivate } from './StartTrialPeriodPopup.constants';
import { trialIsActive } from './StartTrialPeriodPopup.helpers';
import { messages } from './StartTrialPeriodPopup.messages';

export const useWaitActivateTrial = () => {
    const [companyId, setCompanyId] = useState('');

    const [isTrialActive, setIsTrialActive] = useState(false);

    const pollingCountRef = useRef(0);

    const {
        data: companies,
        isSuccess: isGetCompaniesSuccess,
        isError: isGetCompaniesError,
        fetchStatus: fetchStatusGetCompanies,
    } = useGetCompanies(
        { companyId },
        {
            enabled: !!companyId,
            refetchInterval: (data) => {
                if (!data || trialIsActive(data)) {
                    return false;
                }

                return checkActivateTrialInterval;
            },
        }
    );

    useEffect(() => {
        if (companyId && isGetCompaniesSuccess && fetchStatusGetCompanies === 'idle') {
            if (trialIsActive(companies)) {
                setIsTrialActive(true);
            } else {
                pollingCountRef.current++;

                if (pollingCountRef.current > maxReRequestsWaitActivate) {
                    toast.error(messages.trialActivationTimeoutError);

                    setCompanyId('');
                    pollingCountRef.current = 0;
                }
            }
        }
    }, [companyId, companies, fetchStatusGetCompanies, isGetCompaniesSuccess]);

    useEffect(() => {
        if (companyId && isGetCompaniesError) {
            setCompanyId('');
            pollingCountRef.current = 0;
        }
    }, [companyId, isGetCompaniesError]);

    const dispatch = useDispatch();

    const {
        isSuccess: isGetUserContextSuccess,
        isError: isGetUserContextError,
        data: context,
        fetchStatus: fetchStatusGetUserContext,
    } = useGetUserContext({
        enabled: isTrialActive,
    });

    useEffect(() => {
        if (isTrialActive && isGetUserContextSuccess && fetchStatusGetUserContext === 'idle') {
            dispatch(actions.loadInitialAppData({ context }));

            if (companyId) {
                dispatch(automationActions.loadCompanyTemplates({ companyId }));
            }

            dispatch(openTrialPeriodStartedPopup());
        }
    }, [companyId, context, dispatch, fetchStatusGetUserContext, isGetUserContextSuccess, isTrialActive]);

    useEffect(() => {
        if (isTrialActive && isGetUserContextError) {
            setCompanyId('');
            pollingCountRef.current = 0;
            setIsTrialActive(false);
        }
    }, [isTrialActive, isGetUserContextError]);

    return { companyId, setCompanyId };
};
