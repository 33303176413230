import { validators } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { moduleDef } from '..';
import { FirstStartModuleData, ProfileData } from '../reducers/moduleReducer';

function getModule(state: stateTree.State) {
    return selectors.module.getModule<FirstStartModuleData>(state, moduleDef.id);
}

export function getActivePopup(state: stateTree.State) {
    return getModule(state).activePopup;
}

export interface ExpandedProfileData extends ProfileData {
    isValid: boolean;
}

export function getProfileData(state: stateTree.State): ExpandedProfileData {
    const profileData = getModule(state).profileData;
    const invitations = selectors.companyInvitation.getPendingCompanyInvitations(state);
    const showPhoneField = invitations.length === 0;

    const isPhoneValid = showPhoneField ? isPossiblePhoneNumber(profileData.phone || '') : true;

    const isValid = Boolean(
        !validators.password(profileData.password) &&
            profileData.firstName.trim() &&
            profileData.lastName.trim() &&
            isPhoneValid
    );

    return {
        ...profileData,
        isValid,
    };
}

export function getAcceptedInvitationIds(state: stateTree.State) {
    return getModule(state).acceptedInvitationIds;
}

export function getSelectedPracticeInvitation(state: stateTree.State) {
    return getModule(state).selectedPracticeInvitation;
}

export function getLoading(state: stateTree.State) {
    return getModule(state).loading;
}

export function isSeparateCompanyCreation(state: stateTree.State) {
    return getModule(state).separateCompanyCreation;
}

export function getAccountSetupData(state: stateTree.State) {
    return getModule(state).accountSetupData;
}

export function getNewOrganisationData(state: stateTree.State) {
    return getModule(state).newOrganisationData;
}

export function getTrialData(state: stateTree.State) {
    return getModule(state).trialData;
}

export function getSsoData(state: stateTree.State) {
    return getModule(state).ssoData!;
}

export function getReloginResult(state: stateTree.State) {
    return getModule(state).reloginResult;
}
