import { ApiError, ErrorCode } from '@approvalmax/data';
import { Reference } from '@approvalmax/types';
import { usePopupContext } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { domain } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { useDispatch, useSelector } from 'modules/react-redux';
import { sendChameleonEvent } from 'modules/utils/helpers/chameleon';
import { useCallback, useState } from 'react';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { facebookService } from 'services/facebook';
import { gaService } from 'services/ga';
import { linkedInService } from 'services/linkedIn';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { useCheckNetSuiteConnection, useConnectToNetSuiteAccount, useCreateCompany, useEditCompany } from 'shared/data';
import { getPath, Path } from 'urlBuilder';

import { NetSuiteIntegrationPopupData } from '../../../reducers/module/activePopup/netSuiteIntegrationPopupReducer';
import { getActivePopup } from '../../../selectors/moduleSelectors';
import { requiredFieldIds } from './NetSuiteIntegrationPopup.config';
import { messages } from './NetSuiteIntegrationPopup.messages';
import { FormFieldKey, FormValuesState } from './NetSuiteIntegrationPopup.types';

const useCompanyTimezone = (company: domain.Company | null) => {
    const [timezone, setTimezone] = useState(() => {
        return company ? statics.timeZone.findTimeZoneById(company.timeZone) || null : null;
    });

    const changeTimezone = useCallback((timeZone: Reference | null) => {
        setTimezone(timeZone);
    }, []);

    return {
        timezone,
        changeTimezone,
    };
};

export const useFormValues = () => {
    const [values, setValues] = useState<FormValuesState>({
        accountId: '',
        consumerKey: '',
        consumerSecret: '',
        tokenId: '',
        tokenSecret: '',
        subsidiaryId: '',
    });

    const onValueChange = (fieldKey: FormFieldKey) => (value: string) => {
        setValues((state) => ({
            ...state,
            [fieldKey]: value,
        }));
    };

    return {
        values,
        onValueChange,
    };
};

export const useNetSuiteIntegrationPopup = (values: FormValuesState) => {
    const { onRequestClose } = usePopupContext();

    const data = useSelector((state) => getActivePopup<NetSuiteIntegrationPopupData>(state));

    const company = useSelector((state) =>
        data.companyId ? selectors.company.getCompanyById(state, data.companyId) : null
    );

    const { isCreating, onCreateCompany, companyCreated } = useCreateNetSuiteCompany();
    const { isEditing, onEditCompany } = useEditNetSuiteCompany();
    const { isConnecting, connect, isChecking, check } = useConnectToNetSuiteCompany(data.redirectPage);

    const { timezone, changeTimezone: onTimeZoneChange } = useCompanyTimezone(company);

    const onConnect = useCallback(async () => {
        const hasUnfilledField = requiredFieldIds.findIndex((fieldId) => !values[fieldId]) !== -1 || timezone === null;

        if (hasUnfilledField) {
            notificationService.showErrorToast(messages.requiredFieldsError);

            return;
        }

        try {
            await check(values);
        } catch (err) {
            // Error notification is displayed by react-query
            // from BE message

            return;
        }

        let companyId = company?.id ?? '';

        if (company) {
            await onEditCompany(company, timezone);
        } else {
            const response = await onCreateCompany(timezone);

            companyId = response.id;
        }

        await connect(values, companyId);
    }, [check, company, connect, onCreateCompany, onEditCompany, timezone, values]);

    const onClose = useCallback(() => {
        const companyId = companyCreated?.id || company?.id;

        if (companyId) {
            routingService.reloadToUrl(getPath(Path.companyInfo, companyId));
        }

        onRequestClose();
    }, [company?.id, companyCreated?.id, onRequestClose]);

    return {
        onConnect,
        onClose,
        onTimeZoneChange,
        timezone,
        isLoading: isConnecting || isEditing || isCreating || isChecking,
    };
};

const useConnectToNetSuiteCompany = (redirectPage: string) => {
    const dispatch = useDispatch();
    const { isLoading: isConnecting, mutateAsync: connectToAccount } = useConnectToNetSuiteAccount();
    const { isLoading: isChecking, mutateAsync: connectCheck } = useCheckNetSuiteConnection();

    const profile = useSelector(selectors.profile.getProfile);

    const account = profile.account;

    const check = useCallback(
        (formValues: FormValuesState) => {
            return connectCheck({ data: { ...formValues } });
        },
        [connectCheck]
    );

    const connect = useCallback(
        (formValues: FormValuesState, companyId: string) => {
            return connectToAccount(
                { data: { ...formValues, companyId } },
                {
                    onSuccess: async (response, variables) => {
                        const { companyId } = variables.data;

                        if (response.Companies.length > 0) {
                            const currentCompany = response.Companies.find((item) => item.CompanyId === companyId);

                            const integration =
                                currentCompany && currentCompany.Integrations.length > 0
                                    ? currentCompany.Integrations[0]
                                    : null;

                            if (integration) {
                                await api.companies.pullIntegrations({
                                    companyId,
                                    integrationIds: [integration.IntegrationId],
                                });
                            }
                        }

                        amplitudeService.sendData('workflows list: complete connection', {
                            'connection type': 'netsuite',
                        });
                        sendChameleonEvent('connected_gl');

                        if (!account) {
                            facebookService.trackSignUp();
                            linkedInService.trackSignUp();

                            await gaService.sendEvent('org_created', {
                                integration: 'netsuite',
                            });
                        }

                        switch (redirectPage) {
                            case 'company':
                                routingService.reloadToUrl(getPath(Path.companyInfo, companyId));

                                break;

                            case 'workflow':
                                routingService.reloadToUrl(getPath(Path.companyWorkflows, companyId));

                                break;

                            default:
                                throw errorHelpers.assertNever(redirectPage as never);
                        }
                    },
                    onError: (err: ApiError) => {
                        if (err?.code === ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED) {
                            dispatch(
                                integrationActions.showAnotherCompanyIntegratedPopup(domain.IntegrationType.NetSuite)
                            );

                            return;
                        }

                        if (err?.code === ErrorCode.E5016_BOUND_COMPANY_ABOUT_TO_CHANGE) {
                            dispatch(integrationActions.showCompanyMismatchPopup(domain.IntegrationType.NetSuite));

                            return;
                        }

                        notificationService.showErrorToast(err?.title || err?.detail || messages.connectionError);
                    },
                }
            );
        },
        [dispatch, connectToAccount, redirectPage, account]
    );

    return {
        isConnecting,
        isChecking,
        connect,
        check,
    };
};

export const useCreateNetSuiteCompany = () => {
    const { data, isLoading: isCreating, mutateAsync } = useCreateCompany();

    const onCreateCompany = useCallback(
        async (timezone: Reference) => {
            return mutateAsync(
                { data: { timeZone: timezone.id } },
                {
                    onError: () => {
                        notificationService.showErrorToast(messages.companyCreationError);
                    },
                }
            );
        },
        [mutateAsync]
    );

    return {
        isCreating,
        onCreateCompany,
        companyCreated: data || null,
    };
};

export const useEditNetSuiteCompany = () => {
    const { isLoading: isEditing, mutateAsync } = useEditCompany();

    const onEditCompany = useCallback(
        async (company: domain.Company, timezone: Reference) => {
            const params = selectors.company.getCompanyEditTransfer({ ...company, timeZone: timezone.id });

            try {
                await mutateAsync({ data: params });
            } catch (err) {
                notificationService.showErrorToast(messages.saveTimeZoneError);
            }
        },
        [mutateAsync]
    );

    return {
        isEditing,
        onEditCompany,
    };
};
