import { Guid } from '@approvalmax/types';
import { immutable, ImmutableObject } from 'modules/immutable';

import { Action, APPLY_XERO_MATCHING_RESPONSE, SHOW_XERO_MATCHING_POPUP_RESPONSE } from '../../../actions';

export const XERO_MATCHING_POPUP = 'XERO_MATCHING_POPUP';

export interface XeroMatchingPopupData {
    id: typeof XERO_MATCHING_POPUP;
    targetRequest: Guid;
    requests: Guid[];
}

export type XeroMatchingPopupType = ImmutableObject<XeroMatchingPopupData> | null;

export default function (state: XeroMatchingPopupType, action: Action): XeroMatchingPopupType {
    switch (action.type) {
        case SHOW_XERO_MATCHING_POPUP_RESPONSE:
            return immutable<XeroMatchingPopupData>({
                id: XERO_MATCHING_POPUP,
                targetRequest: action.payload.request.requestId,
                requests: action.payload.requests,
            });

        case APPLY_XERO_MATCHING_RESPONSE:
            return null;

        default:
            return state;
    }
}
