import { api } from '@approvalmax/data';

export const getDefaultEnvironment = () => {
    switch (window.ApprovalMax.environment) {
        case 'debug':
            return 'local';

        case 'debugweb':
            return 'dev04';

        default:
            return window.ApprovalMax.environment || 'production';
    }
};

export const defaultApiSource = 'webApp';

export const {
    useGetRequest,
    useGetLazyRequest,
    useGetRequestLegacy,
    useGetLazyRequestLegacy,
    useMutate,
    useMutateLegacy,
    useInfiniteGetRequest,
    useInfiniteGetRequestLegacy,
    getApiUrl,
    rootApi,
    rootApiLegacy,
    setApiUrlByEnv,
    setApiUrl,
    resetApiUrl,
} = api.init(defaultApiSource, getDefaultEnvironment());
