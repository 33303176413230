import React from 'react';

const Page = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './CustomerDecision'));

const routes = [
    {
        path: '/customerDecision',
        component: Page,
    },
];

export default routes;
