import { DropdownEditor, Popup, TextEditor } from '@approvalmax/ui';
import { statics } from 'modules/common';
import { PlainDataProvider } from 'modules/data-providers';
import { GlobalLoadingBar } from 'modules/page';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { formFields } from './NetSuiteIntegrationPopup.config';
import { useFormValues, useNetSuiteIntegrationPopup } from './NetSuiteIntegrationPopup.hooks';
import { messages } from './NetSuiteIntegrationPopup.messages';
import { Root, StyledField, StyledPopup, Title } from './NetSuiteIntegrationPopup.styles';

const qa = bemFactory.qa('netsuite-integration-popup');

const NetSuiteIntegrationPopup: FC = memo(() => {
    const { values, onValueChange } = useFormValues();

    const { isLoading, onConnect, onClose, timezone, onTimeZoneChange } = useNetSuiteIntegrationPopup(values);

    return (
        <StyledPopup
            title={messages.popupTitle}
            customCloseHandler={onClose}
            buttons={
                <Popup.DefaultContent.Button
                    disabled={isLoading}
                    execute={onConnect}
                    qa={qa('connect-button')}
                    title={messages.connectButton}
                >
                    {messages.connectButton}
                </Popup.DefaultContent.Button>
            }
        >
            <>
                <GlobalLoadingBar isLoading={isLoading} />

                <Root>
                    <Title>{messages.connectionTitle}</Title>

                    {formFields.map((field) => (
                        <StyledField key={field.id} title={field.title} required={field.required}>
                            <TextEditor
                                type={field.type}
                                value={values[field.id]}
                                autoComplete={field.type === 'password' ? 'new-password' : 'off'}
                                onChange={onValueChange(field.id)}
                            />
                        </StyledField>
                    ))}

                    <StyledField title={messages.timeZoneFieldTitle} required>
                        <PlainDataProvider items={statics.timeZone.timeZoneReferences}>
                            <DropdownEditor
                                buttonComponent={DropdownEditor.NoFilterButton}
                                value={timezone}
                                qa={qa('timezone-dropdown')}
                                placeholder={messages.timeZonePlaceholder}
                                onChange={onTimeZoneChange}
                            />
                        </PlainDataProvider>
                    </StyledField>
                </Root>
            </>
        </StyledPopup>
    );
});

export default NetSuiteIntegrationPopup;
