import { DropdownEditor, DropdownValueType } from '@approvalmax/ui';
import { dataProviders } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import { STATIC_VALUES } from './BoolConditionCell.constants';
import { messages } from './BoolConditionCell.messages';
import { Root } from './BoolConditionCell.styles';
import { DROPDOWN_VALUES } from './BoolConditionCell.types';

const BoolConditionCell: FC<RenderCellOptions> = memo((props) => {
    const { rule, field, readonly, lineId, condition, onConditionChange, integrationCode } = props;

    const value = condition.conditionType === domain.ConditionType.BoolCondition ? condition.exactConstraintBool : null;

    const onChange = useCallback(
        (newValue: DropdownValueType) => {
            let selectedValue: boolean | null = null;

            if (newValue && !Array.isArray(newValue)) {
                switch (newValue.id) {
                    case DROPDOWN_VALUES.ENABLED:
                        selectedValue = true;

                        break;

                    case DROPDOWN_VALUES.DISABLED:
                        selectedValue = false;

                        break;
                }
            }

            const newCondition: domain.BoolCondition = {
                ...condition,
                conditionType: domain.ConditionType.BoolCondition,
                exactConstraintBool: selectedValue,
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, lineId, rule, field, onConditionChange]
    );

    const selectedValue = useMemo(() => {
        if (value === null) {
            return dataProviders.FieldDataProvider.AnyValue;
        }

        return (
            STATIC_VALUES.find((ref) => {
                if (value && ref.id === DROPDOWN_VALUES.ENABLED) {
                    return true;
                }

                if (!value && ref.id === DROPDOWN_VALUES.DISABLED) {
                    return true;
                }

                return false;
            }) || dataProviders.FieldDataProvider.AnyValue
        );
    }, [value]);

    return (
        <Root>
            <dataProviders.FieldDataProvider
                field={field}
                staticValues={STATIC_VALUES}
                integrationCode={integrationCode}
            >
                <DropdownEditor
                    theme='matrix'
                    placeholder={messages.placeholder}
                    value={selectedValue || dataProviders.FieldDataProvider.AnyValue}
                    disabled={readonly}
                    onChange={onChange}
                    loading={false}
                />
            </dataProviders.FieldDataProvider>
        </Root>
    );
});

export default BoolConditionCell;
