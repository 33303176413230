import { messages } from './QBooksCustomerCreationConditionCell.messages';
import { ConditionTypeValues } from './QBooksCustomerCreationConditionCell.types';

export const conditionItems: { id: ConditionTypeValues; name: string }[] = [
    {
        id: ConditionTypeValues.AllContacts,
        name: messages.anyExistingContactDropdownText,
    },
    {
        id: ConditionTypeValues.CustomersOnly,
        name: messages.onlyCustomersDropdownText,
    },
    {
        id: ConditionTypeValues.ExactValues,
        name: messages.contactsExactValuesDropdownText,
    },
    {
        id: ConditionTypeValues.NegativeExactValues,
        name: messages.contactsNotExactValuesDropdownText,
    },
];
