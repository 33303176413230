import { Guid, Reference } from '@approvalmax/types';
import { XeroContact } from 'pages/requestForm/data/xero/XeroContact';
import { UseGetContactCisSettingsResponse } from 'shared/data';

import { domain } from '..';
import { DiscountType } from '../backend';
import { EmailToSupplier, GoodsReceivedNotesStatus, LineAmountType } from '.';
import { BaseRequest } from './RequestBase.ts';
import { TemplateSettingsLockDatePolicy } from './TemplateSettings';

export enum XeroObjectStatus {
    Submitted = 'Submitted',
    Authorised = 'Authorised',
    Paid = 'Paid',
    Billed = 'Billed',
    Deleted = 'Deleted',
    Voided = 'Voided',
    Draft = 'Draft',
}

export enum XeroQuoteStatus {
    Draft = 'Draft',
    Sent = 'Sent',
    Declined = 'Declined',
    Accepted = 'Accepted',
}

export interface XeroInvoiceTax {
    taxRatePercent: number;
    taxValue: number;
    taxRateString: string;
}

export interface XeroTaxCode extends Reference {
    applicableToAccountClasses: XeroAccountClass[];
    rate: number;
    rateEffective: number;
}

export enum XeroAccountClass {
    Asset = 'Asset',
    Equity = 'Equity',
    Expense = 'Expense',
    Liability = 'Liability',
    Revenue = 'Revenue',
}

export enum XeroSystemAccountType {
    Debtors = 'Debtors',
    Creditors = 'Creditors',
    BankCurrencyGain = 'BankCurrencyGain',
    GST = 'GST',
    GSTOnImports = 'GSTOnImports',
    Historical = 'Historical',
    RealisedCurrencyGain = 'RealisedCurrencyGain',
    RetainedEarnings = 'RetainedEarnings',
    Rounding = 'Rounding',
    TrackingTransfers = 'TrackingTransfers',
    UnpaidExpenseClaim = 'UnpaidExpenseClaim',
    UnrealisedCurrencyGain = 'UnrealisedCurrencyGain',
    WagePayables = 'WagePayables',
    CisAssets = 'CisAssets',
    CisAsset = 'CisAsset',
    CisLabour = 'CisLabour',
    CisLabourExpense = 'CisLabourExpense',
    CisLabourIncome = 'CisLabourIncome',
    CisLiability = 'CisLiability',
    CisMaterials = 'CisMaterials',
    Empty = 'Empty',
}

export interface XeroAccount extends Reference {
    accountClass?: XeroAccountClass;
    accountCode?: string;
    name?: string;
    taxCode?: XeroTaxCode;
    systemAccount?: XeroSystemAccountType;
}

export interface XeroItemDetails {
    description: string;
    unitPrice?: number;
    account?: XeroAccount;
    taxCode?: XeroTaxCode;
}

export interface XeroItem extends Reference {
    code: string;
    price?: number;
    isInventory?: boolean;
    salesDetails?: XeroItemDetails;
    purchaseDetails?: XeroItemDetails;
}

export interface XeroLineItem {
    id: string;
    description: string;
    item?: XeroItem;
    qty?: number;
    unitPrice?: number;
    amount?: number;
    taxAmount?: number;
    tax?: XeroTaxCode;
    account?: Reference;
    discount?: number;
    discountAmount?: number;
    discountType?: DiscountType;
    tracking: Array<{
        category: Reference;
        value: Reference;
    }>;
    isInventory: boolean;
    cisRate?: number | null;
    cisTaxAmount?: number | null;
    checked?: boolean;
}

export interface XeroSharedDetails {
    id: string;
    reference: string | null;
    contact: XeroContact | null;
    date: string;
    lockDate: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy;
    status: XeroObjectStatus | null;
    brandingTheme: Reference | null;
    url: string | null;
    editUrl: string | null;
    invoiceTaxes: XeroInvoiceTax[];
    lineAmountType: LineAmountType;
    lineItems: XeroLineItem[];
    subTotal: number;
    integrationEnabled: boolean;
    isStatusPushPending: boolean;
    number: string;
}

export interface XeroPurchaseOrderDetails extends XeroSharedDetails {
    integrationCode: domain.IntegrationCode.XeroPo;
    integrationType: domain.IntegrationType.Xero;
    isBilled: boolean;
    deliveryDate: string | null;
    delivery: {
        address: string;
        attentionTo: string;
        phone: string;
        instructions: string;
    };
    sendToSupplier: boolean;
    emailToSupplier: EmailToSupplier | null;
    grnStatus: GoodsReceivedNotesStatus | null;
}

export interface XeroContactDetails extends domain.XeroContact {
    integrationCode: domain.IntegrationCode.XeroContact;
    integrationType: domain.IntegrationType.Xero;
}

export interface XeroAmaxPayBatchPaymentDetails
    extends Omit<XeroSharedDetails, 'status'>,
        domain.XeroAmaxPayBatchPayment {
    integrationCode: domain.IntegrationCode.XeroAmaxPayBatchPayment;
    integrationType: domain.IntegrationType.Xero;
}

export interface XeroBillBatchPaymentDetails extends XeroSharedDetails, domain.XeroBillBatchPayment {
    integrationCode: domain.IntegrationCode.XeroBillBatchPayment;
    integrationType: domain.IntegrationType.Xero;
    workflowIsSupplierBankAccountRequired: boolean;
}

export interface XeroAirwallexBatchPaymentDetails extends XeroSharedDetails, domain.XeroAirwallexBatchPayment {
    integrationCode: domain.IntegrationCode.XeroAirwallexBatchPayment;
    integrationType: domain.IntegrationType.Xero;
    scheduledPaymentDate?: string;
    scheduledPaymentFailed?: boolean;
}

export interface XeroAmount {
    value: number;
    currency: string;
}

export interface XeroInvoicePaymentDetails {
    amount: number;
    date: string;
    account: {
        id: string;
        text: string;
        number: string;
        code: string;
        currencyCode: string;
    };
}

export interface XeroInvoiceDetails extends XeroSharedDetails {
    integrationCode: domain.IntegrationCode.XeroInvoice;
    integrationType: domain.IntegrationType.Xero;
    type: string;
    dueDate: string;
    matchedPurchaseOrder:
        | {
              id: string;
              name: string;
              amount: XeroAmount;
              overBudget: boolean;
              matchedBills: Array<{
                  id: string;
                  name: string;
                  amount: XeroAmount;
              }>;
              matchedBillsTotalAmount: XeroAmount;
          }
        | undefined;
    paymentDetails?: XeroInvoicePaymentDetails;
    cisTaxAmount?: number | null;
    hasCisDeduction?: boolean;
    contactCisSettings?: UseGetContactCisSettingsResponse;
    sendToSupplier: boolean;
    emailToSupplier: EmailToSupplier | null;
}

export interface XeroBillDetails extends XeroSharedDetails {
    integrationCode: domain.IntegrationCode.XeroBill;
    integrationType: domain.IntegrationType.Xero;
    type: string;
    dueDate: string;
    matchedPurchaseOrder?: {
        id: string;
        name: string;
        amount: XeroAmount;
        overBudget: boolean;
        matchedBills: Array<{
            id: string;
            name: string;
            amount: XeroAmount;
        }>;
        matchedBillsTotalAmount: XeroAmount;
    };
    paymentDetails?: XeroInvoicePaymentDetails;
    cisTaxAmount?: number | null;
    hasCisDeduction?: boolean;
    contactCisSettings?: UseGetContactCisSettingsResponse;
}

export interface XeroQuoteDetails extends Omit<XeroSharedDetails, 'status'> {
    integrationCode: domain.IntegrationCode.XeroQuote;
    integrationType: domain.IntegrationType.Xero;
    expiryDate: string | null;
    title: string | null;
    summary: string | null;
    sendToSupplier: boolean;
    emailToSupplier: EmailToSupplier | null;
    quoteStatus: XeroQuoteStatus | null;
    isAccepted: boolean | null;
    terms: string | null;
    status: XeroQuoteStatus | null;
}

export interface XeroCreditNoteDetails extends XeroSharedDetails {
    integrationCode: domain.IntegrationCode.XeroCreditNotesPayable | domain.IntegrationCode.XeroCreditNotesReceivable;
    integrationType: domain.IntegrationType.Xero;
    type: string;
    remainingCredit: number;
    cisTaxAmount?: number | null;
    hasCisDeduction?: boolean;
}

export enum XeroManualJournalLineAmountType {
    Debit = 'Debit',
    Credit = 'Credit',
}

export interface XeroManualJournalLine extends XeroLineItem {
    amountType: XeroManualJournalLineAmountType | null;
}

export interface XeroManualJournalDetails {
    integrationCode: domain.IntegrationCode.XeroManualJournal;
    integrationType: domain.IntegrationType.Xero;
    date: string;
    lockDate: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy;
    narration: string | null;
    showOnCashBasisReports: boolean;
    lineItems: XeroManualJournalLine[];
    lineAmountType: LineAmountType;
    url: string | null;
    editUrl: string | null;
    isStatusPushPending: boolean;
}

export type XeroPoRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroPo;
    details: XeroPurchaseOrderDetails;
};

export type XeroPoRequest = BaseRequest & XeroPoRequestSpecifics;

export type XeroBillRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroBill;
    details: XeroBillDetails;
    ocrAttachment?: Guid;
};

export type XeroBillRequest = BaseRequest & XeroBillRequestSpecifics;

export type XeroInvoiceRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroInvoice;
    details: XeroInvoiceDetails;
};

export type XeroInvoiceRequest = BaseRequest & XeroInvoiceRequestSpecifics;

export type XeroCreditNotesPayableRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroCreditNotesPayable;
    details: XeroCreditNoteDetails;
};

export type XeroCreditNotesPayableRequest = BaseRequest & XeroCreditNotesPayableRequestSpecifics;

export type XeroCreditNotesReceivableRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroCreditNotesReceivable;
    details: XeroCreditNoteDetails;
};

export type XeroCreditNotesReceivableRequest = BaseRequest & XeroCreditNotesReceivableRequestSpecifics;

export type XeroAmaxPayBatchPaymentRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroAmaxPayBatchPayment;
    details: XeroAmaxPayBatchPaymentDetails;
};

export type XeroAmaxPayBatchPaymentRequest = BaseRequest & XeroAmaxPayBatchPaymentRequestSpecifics;

export type XeroAirwallexBatchPaymentRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroAirwallexBatchPayment;
    details: XeroAirwallexBatchPaymentDetails;
};

export type XeroAirwallexBatchPaymentRequest = BaseRequest & XeroAirwallexBatchPaymentRequestSpecifics;

export type XeroBillBatchPaymentRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroBillBatchPayment;
    details: XeroBillBatchPaymentDetails;
};

export type XeroBillBatchPaymentRequest = BaseRequest & XeroBillBatchPaymentRequestSpecifics;

export type XeroContactRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroContact;
    details: XeroContactDetails;
};

export type XeroContactRequest = BaseRequest & XeroContactRequestSpecifics;

export type XeroQuoteRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroQuote;
    details: XeroQuoteDetails;
};

export type XeroQuoteRequest = BaseRequest & XeroQuoteRequestSpecifics;

export type XeroManualJournalRequestSpecifics = {
    integrationType: domain.IntegrationType.Xero;
    integrationCode: domain.IntegrationCode.XeroManualJournal;
    details: XeroManualJournalDetails;
};

export type XeroManualJournalRequest = BaseRequest & XeroManualJournalRequestSpecifics;

export type XeroRequestSpecifics =
    | XeroPoRequestSpecifics
    | XeroBillRequestSpecifics
    | XeroContactRequestSpecifics
    | XeroManualJournalRequestSpecifics
    | XeroQuoteRequestSpecifics
    | XeroAmaxPayBatchPaymentRequestSpecifics
    | XeroBillBatchPaymentRequestSpecifics
    | XeroAirwallexBatchPaymentRequestSpecifics
    | XeroCreditNotesPayableRequestSpecifics
    | XeroCreditNotesReceivableRequestSpecifics
    | XeroInvoiceRequestSpecifics;

export type XeroRequestDetails =
    | XeroPurchaseOrderDetails
    | XeroInvoiceDetails
    | XeroBillDetails
    | XeroContactDetails
    | XeroManualJournalDetails
    | XeroQuoteDetails
    | XeroAmaxPayBatchPaymentDetails
    | XeroBillBatchPaymentDetails
    | XeroAirwallexBatchPaymentDetails
    | XeroCreditNoteDetails;

export type XeroRequest =
    | XeroPoRequest
    | XeroBillRequest
    | XeroContactRequest
    | XeroManualJournalRequest
    | XeroQuoteRequest
    | XeroAmaxPayBatchPaymentRequest
    | XeroBillBatchPaymentRequest
    | XeroAirwallexBatchPaymentRequest
    | XeroCreditNotesPayableRequest
    | XeroCreditNotesReceivableRequest
    | XeroInvoiceRequest;

export interface XeroCisSettings {
    contractorEnabled: boolean;
    subContractorEnabled: boolean;
    companyRate?: number | null;
}
