import { ErrorCode } from '@approvalmax/data';
import { errorHelpers, miscHelpers } from '@approvalmax/utils';
import each from 'lodash/each';
import groupBy from 'lodash/groupBy';
import { backend } from 'modules/data';
import { TakeableChannel } from 'redux-saga';
import { actionChannel, all, call, delay, race, take } from 'redux-saga/effects';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { CHANGE_USER_ROLE, changeUserRole } from '../actions/index';
import { messagesSagas as messages } from '../messages';

type ChangeUserRoleType = ReturnType<typeof changeUserRole>;

const AUTO_SAVE_QUIET_DELAY = 1500;

enum ChangeType {
    Role,
    DelegateSet,
    DelegateClear,
}

function* doSave(allActions: Array<ChangeUserRoleType>) {
    let apiEffects: any = [];

    const byCompanyId = groupBy(allActions, (a) => a.payload.companyId);

    let changesCount = 0;
    let lastChange: ChangeType | null = null;
    let delegatedForName: string | null = null;

    each(byCompanyId, (actions, companyId) => {
        let users: {
            [userId: string]: backend.CompaniesParticipant;
        } = {};

        actions.forEach((action) => {
            switch (action.type) {
                case CHANGE_USER_ROLE: {
                    let data = users[action.payload.user.id];

                    if (!data) {
                        users[action.payload.user.id] = data = {
                            participantUserId: action.payload.user.databaseId,
                        };
                    }

                    data.participantRole = action.payload.role as any;
                    changesCount++;
                    lastChange = ChangeType.Role;
                    break;
                }
            }
        });

        const participants = Object.values(users);

        apiEffects.push(call([api.companies, 'editParticipants'], { companyId, participants }));
    });

    try {
        yield all(apiEffects);

        let message: string;

        miscHelpers.invariant(changesCount > 0, 'Changes count will always be > 0');

        if (changesCount > 1) {
            message = messages.changesSavedNotificationMessage;
        } else {
            switch (lastChange!) {
                case ChangeType.DelegateSet:
                    message = messages.delegateSetNotificationMessage({
                        username: delegatedForName,
                    });
                    break;

                case ChangeType.DelegateClear:
                    message = messages.delegateClearNotificationMessage;
                    break;

                case ChangeType.Role:
                    message = messages.roleChangedNotificationMessage;
                    break;

                default:
                    throw errorHelpers.notSupportedError();
            }
        }

        yield call([notificationService, notificationService.showInfoToast], message);
    } catch (err) {
        if (err.code === ErrorCode.E4066_DELEGATIONS_MUST_NOT_OVERLAP) {
            // TODO: get rid of this after hotfix and remove Saga for SET_DELEGATE
            setTimeout(() => window.location.reload(), 1500);
        }
    }
}

export default function* autoSaveSaga() {
    const requestChannel: TakeableChannel<unknown> = yield actionChannel([CHANGE_USER_ROLE]);

    let actions: Array<ChangeUserRoleType> = [];

    while (true) {
        try {
            // action accumulation cycle
            const { action, cancel } = yield race({
                action: take(requestChannel),
                cancel: delay(AUTO_SAVE_QUIET_DELAY),
            });

            if (action) {
                actions.push(action);
            } else if (actions.length > 0) {
                // Do the job
                yield call(doSave, actions);
                actions = [];
            }
        } catch (e) {
            actions.pop();
            // Catch all errors here so that the other sagas don't crash
            errorHelpers.captureException(e);
        }
    }
}
