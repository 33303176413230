import { domain } from 'modules/data';

export const WORKFLOWS_LIST_PAGE_ID = 'workflows_list';
export const WORKFLOW_PAGE_ID = 'workflow';
export const NEW_WORKFLOW_PAGE_ID = 'new_workflow';

export const roles = [
    domain.CompanyUserRole.Manager,
    domain.CompanyUserRole.Auditor,
    domain.CompanyUserRole.WorkflowManager,
];

export const PAGE_ID = 'workflows';
