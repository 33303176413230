import { ValidationRule } from 'react-hook-form';

import { messages } from './EditPaymentDetailsPopupContent.messages';

export const sortCodeFieldPattern: ValidationRule<RegExp> = {
    value: /^\d{6}$/,
    message: messages.sortCodeValidationError,
};

export const bankAccountNumberFieldPattern: ValidationRule<RegExp> = {
    value: /^\d{8}$/,
    message: messages.bankAccountNumberValidationError,
};

export const countries = [
    {
        id: 'UK',
        name: 'UK',
    },
];

export const currencies = [
    {
        id: 'GBP',
        name: 'GBP',
    },
];
