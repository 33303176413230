import { Box, Select } from '@approvalmax/ui/src/components';
import { ColumnDefinition } from '@approvalmax/ui/src/components/Table/Table.types';
import { domain } from 'modules/data';
import { useCallback, useMemo, useState } from 'react';

import { fieldStateList, getFieldName, getFieldStateValue } from './FieldSettingsPopup.helpers';
import { messages } from './FieldSettingsPopup.messages';
import { FieldState } from './FieldSettingsPopup.types';

export const useFieldsTable = (fieldList: domain.DocumentField[]) => {
    const [fieldSettingsState, setFieldSettingsState] = useState<FieldState[]>(() => {
        return fieldList.map((field) => ({
            id: field.purpose,
            fieldName: field.name,
            fieldState: field.state,
            fieldPurpose: field.purpose,
            availableFieldStates: field.availableStates,
            defaultFieldState: field.defaultState,
            hideClone: true,
            hideRemove: true,
        }));
    });

    const resetToDefaultState = useCallback(() => {
        setFieldSettingsState((current) => {
            return [...current].map((field) => {
                return {
                    ...field,
                    fieldState: field.defaultFieldState,
                };
            });
        });
    }, []);

    const tableDef = useMemo<ColumnDefinition<FieldState>[]>(
        () => [
            {
                id: 'fieldName',
                name: messages.fieldNameCol,
                value: (field) => getFieldName(field),
                width: 400,
            },
            {
                id: 'fieldState',
                spacing: '0',
                columnComponent: () => (
                    <Box as='th' color='silver80' spacing='12 8' width='100%' textAlign='left' fontWeight='regular'>
                        {messages.fieldStateCol}
                    </Box>
                ),
                value: (field) => {
                    const availableStateList = fieldStateList.filter((state) =>
                        field.availableFieldStates.includes(state.id)
                    );
                    const readonly = availableStateList.length < 2;

                    const onChange = (value?: domain.DocumentFieldState) => {
                        setFieldSettingsState((current) =>
                            current.map((item) => {
                                if (item.id === field.id) {
                                    return {
                                        ...item,
                                        fieldState: value ?? domain.DocumentFieldState.Editable,
                                    };
                                }

                                return item;
                            })
                        );
                    };

                    return (
                        <Box color={readonly ? 'midnight30' : 'white100'} spacing='12 8'>
                            <Select
                                items={availableStateList}
                                itemNameKey='text'
                                disabled={readonly}
                                value={getFieldStateValue(field.fieldState)?.id}
                                bordered={false}
                                clearable={false}
                                openIconOnHover
                                size='small'
                                onChange={onChange}
                            />
                        </Box>
                    );
                },
            },
        ],
        []
    );

    return {
        fieldSettingsState,
        resetToDefaultState,
        tableDef,
    };
};
