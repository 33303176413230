import { atom } from 'recoil';

export const bulkCreationPopupOpenState = atom({
    key: 'bulkCreationPopupOpenState',
    default: false,
});

export const bulkCreationPopupCompanyIdState = atom<string>({
    key: 'bulkCreationPopupCompanyIdState',
    default: '',
});
