import { CircleFilledIcon } from '@approvalmax/ui';
import { Box, Collapse, Flex, Link, Text, Tooltip } from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';

import { addTempUser, assignExternalRequester, openInviteUsersPopup, removeExternalRequester } from '../../actions';
import { getActiveTemplate, isPageReadonly, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getTemplateExternalSubmitter } from '../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import { addUserEvent, removeUserEvent } from '../../utils/events';
import AddUserButton from '../AddUserButton/AddUserButton';
import StepUser from '../StepUser/StepUser';
import { messages } from './DearExternalSubmitterSection.messages';
import { DearExternalSubmitterSectionProps } from './DearExternalSubmitterSection.types';

const qa = bemFactory.qa('dext-prepare-start-step');

const { dearConstants } = constants;

const DearExternalSubmitterSection: FC<DearExternalSubmitterSectionProps> = memo((props) => {
    const { collapsed, onCollapse, cacheId } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const readonly = useSelector(isPageReadonly);
    const externalSubmitter = useSelector(getTemplateExternalSubmitter);
    const showValidationErrors = useSelector(showCardValidationErrors);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const hasAnySubmitters = useSelector((state: State) =>
        selectors.template.getTemplateHasAnySubmitters(state, template)
    );

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));

            addUserEvent('requester', template.integrationCode, true);
        },
        [dispatch, template.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onAddExternalSubmitter = useCallback(
        (user: domain.User) => {
            dispatch(assignExternalRequester(user.id));

            if (user.databaseId) {
                addUserEvent('requester', template.integrationCode);
            }
        },
        [dispatch, template.integrationCode]
    );

    const onRemoveExternalSubmitter = useCallback(() => {
        dispatch(removeExternalRequester());

        removeUserEvent('requester', template.integrationCode);
    }, [dispatch, template.integrationCode]);

    const invalid = showValidationErrors && !hasAnySubmitters;

    const collapseColor = useMemo(() => {
        if (readonly) return 'silver80';

        if (invalid) return 'red30';

        return undefined;
    }, [invalid, readonly]);

    return (
        <Collapse
            name={messages.bubbleText}
            spacing='0 8'
            localStorageKey={cacheId}
            radius='xsmall'
            alignCollapseIcon='right'
            size='small'
            fontWeight='regular'
            onCollapse={onCollapse}
            collapsed={collapsed}
            bordered
            startIcon={
                externalSubmitter ? (
                    <CircleFilledIcon size={12} color={readonly ? 'silver100' : 'brandCin7100'} />
                ) : undefined
            }
            headerContent={
                <>
                    <Text font='body' fontSize='small' color='midnight100'>
                        {messages.description({ templateName: template.displayName })}
                    </Text>

                    <Link
                        font='label'
                        fontSize='small'
                        href={dearConstants.DEAR_AUTO_APPROVAL_LEARN_MORE_LINK}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={domHelpers.stopPropagation}
                    >
                        {messages.learnMore}
                    </Link>
                </>
            }
            color={collapseColor}
        >
            {externalSubmitter ? (
                <Box spacing='8 0'>
                    <Flex inline direction='column' spacing='8' data-qa={qa('add-rb-submitter-button')}>
                        <StepUser
                            team={team}
                            user={externalSubmitter}
                            onRemove={onRemoveExternalSubmitter}
                            readonly={readonly}
                            onInvite={onInviteUser}
                            isCompanyMember={team.some((t) => t.id === externalSubmitter.id)}
                        />
                    </Flex>
                </Box>
            ) : (
                <Flex height={40} spacing='8' inline alignItems='center'>
                    <Tooltip
                        open={invalid && !collapsed}
                        color='cobalt100'
                        activator={
                            <AddUserButton
                                users={team}
                                disabled={readonly}
                                onAddNew={onAddTempUser}
                                onSelect={onAddExternalSubmitter}
                                type='step'
                                title={messages.chooseRequester}
                                invalid={invalid}
                            />
                        }
                    >
                        {messages.chooseRequester}
                    </Tooltip>
                </Flex>
            )}
        </Collapse>
    );
});

export default DearExternalSubmitterSection;
