import { miscHelpers } from '@approvalmax/utils';
import { forwardRef } from 'react';

import { Root } from './Box.styles';
import { BoxProps } from './Box.types';

/**
 * The Box component serves as a wrapper component for the most popular styles that apply to wrappers.
 */
export const Box = forwardRef<any, BoxProps>((props, ref) => {
    const {
        spacing,
        shadow,
        radius,
        color,
        width,
        height,
        maxWidth,
        bordered,
        borderInside,
        borderColor,
        children,
        ...restProps
    } = props;

    return (
        <Root
            {...restProps}
            $bordered={bordered}
            $borderInside={borderInside}
            $borderColor={borderColor}
            $shadow={shadow}
            $color={color}
            $radius={radius}
            $spacing={miscHelpers.spacingPropToCss(spacing)}
            $width={width}
            $height={height}
            $maxWidth={maxWidth}
            ref={ref}
        >
            {children}
        </Root>
    );
});

Box.displayName = 'Box';

export default Box;
