import { domain } from 'modules/data';
import moment from 'moment';

import { ExpandedCompany, ExpandedRequest, ExpandedTemplate } from './types';

const getLockDateInEffect = (details: domain.NetSuiteRequestDetails) => {
    switch (details.integrationCode) {
        case domain.IntegrationCode.NetSuiteBill:
        case domain.IntegrationCode.NetSuitePO: {
            const isGeneralLodgerDocument = [domain.IntegrationCode.NetSuiteBill].includes(details.integrationCode);

            if (!details.lockDate || !isGeneralLodgerDocument) {
                return false;
            }

            const date = moment.utc(details.transactionDate);
            const lockDate = moment.utc(details.lockDate);
            const newDate = moment(lockDate).add(1, 'd');

            return date.isBefore(newDate);
        }

        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuiteSalesOrder:
        default:
            return false;
    }
};

const canCopyPOToBill = (request: domain.Request, companyTemplates: ExpandedTemplate[]) => {
    if (request.integrationCode !== domain.IntegrationCode.NetSuitePO) {
        return false;
    }

    const isApprovedCondition = request.statusV2 === domain.RequestStatusV2.Approved;

    const pushedToSourceCondition = request.history.some(
        (historyItem) => historyItem.type === domain.RequestHistoryEventType.PushedToSource
    );

    const templateCondition = companyTemplates.find(
        (template) => template.integrationCode === domain.IntegrationCode.NetSuiteBill && template.enabled
    );

    return Boolean(isApprovedCondition && pushedToSourceCondition && templateCondition);
};

export const getNetSuiteCommands = (
    request: domain.NetSuiteRequest,
    company: ExpandedCompany,
    hasCreatableTemplate: boolean,
    myDecisions: ExpandedRequest['myDecisions'],
    flags: ExpandedRequest['flags'],
    companyTemplates: ExpandedTemplate[],
    creatableTemplates: string[]
): ExpandedRequest['commands'] => {
    const details = request.details;
    const isManager = company.flags.isManager;
    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const canForceDecision =
        isManager &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            isOnHold ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin === domain.RequestOrigin.ApprovalMax));
    const hasActiveIntegration = company.flags.hasActiveIntegration;

    const lockDatePreventsApprove =
        (details.integrationCode === domain.IntegrationCode.NetSuiteBill ||
            details.integrationCode === domain.IntegrationCode.NetSuitePO) &&
        getLockDateInEffect(details) &&
        details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval;

    const creatableBillTemplateId = companyTemplates.find((companyTemplate) => {
        return companyTemplate.integrationCode === domain.IntegrationCode.NetSuiteBill;
    })?.id;
    const hasCreatableTemplateBill = creatableBillTemplateId
        ? creatableTemplates.includes(creatableBillTemplateId)
        : false;

    const hasCopyToBillOptions = canCopyPOToBill(request, companyTemplates);
    const createdInApprovalMax = request.origin === domain.RequestOrigin.ApprovalMax;
    const isMyDraft = flags.isAuthor && flags.status.isDraft;
    const canEdit = flags.isAuthor && createdInApprovalMax && !flags.status.isClosed && !isOnHold;
    const canDelete = isMyDraft && createdInApprovalMax && !isOnHold;
    const canCancel = flags.isAuthor && createdInApprovalMax && flags.status.isOpen && !isOnHold;
    const isNetSuiteSalesOrder = request.integrationCode === domain.IntegrationCode.NetSuiteSalesOrder;
    const isNetSuiteExpenseReport = request.integrationCode === domain.IntegrationCode.NetSuiteExpenseReport;
    const isNetSuiteVRA = request.integrationCode === domain.IntegrationCode.NetSuiteVRA;

    return {
        approve: {
            hidden: (!flags.isActiveApprover && !isOnHold) || lockDatePreventsApprove,
            disabled: lockDatePreventsApprove || isOnHold,
        },
        reject: {
            hidden: !flags.isActiveApprover && !isOnHold,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision,
            disabled: lockDatePreventsApprove,
        },
        forceReject: {
            hidden: !canForceDecision,
            disabled: request.statusV2 === domain.RequestStatusV2.Rejected,
        },
        revoke: {
            hidden: flags.status.isClosed || myDecisions.length === 0 || isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden: !(hasActiveIntegration && isManager && flags.status.isOpen && request.templateVersionIsObsolete),
            disabled: false,
        },
        editSecondary: {
            hidden: !canEdit,
            disabled: false,
        },
        delete: {
            hidden: !canDelete,
            disabled: false,
        },
        cancel: {
            hidden: !canCancel,
            disabled: false,
        },
        clone: {
            hidden: isNetSuiteVRA || isNetSuiteSalesOrder || !hasCreatableTemplate,
            disabled: false,
        },
        clonePOToBill: {
            hidden: !hasCreatableTemplateBill || !hasCopyToBillOptions,
            disabled: false,
        },
        cloneQuoteToSalesInvoice: {
            hidden: true,
            disabled: true,
        },
        reverse: {
            hidden: true,
            disabled: true,
        },
        editPrimary: {
            hidden: true,
            disabled: true,
        },
        completeReview: {
            hidden: true,
            disabled: true,
        },
        forceReview: {
            hidden: true,
            disabled: true,
        },
        returnToReview: {
            hidden: true,
            disabled: true,
        },
    };
};
