import { domHelpers } from '@approvalmax/utils';
import { Component, PropsWithChildren } from 'react';
import Transition, { TransitionProps } from 'react-transition-group/Transition';

type SwitchTransitionProps = TransitionProps &
    PropsWithChildren & {
        timeout: number | { enter?: number; exit?: number } | 'auto';

        /**
         * When true, the `children` component gets mounted.
         * First, `children` component gets mounted without `inClass`, then
         * `inClass` is applied, so that all CSS-transitions will trigger.
         *
         * When false, `inClass` gets removed from `children`,
         * then `outClass` is applied and after `timeout` the `children` get unmounted.
         */
        in?: boolean;

        /**
         * Whether to show transitions when `SwitchTransform` in mounted with the `in` property enabled.
         * Default is `true`, but in some cases you don't want animations to trigger when the element has just
         * mounted.
         */
        appear?: boolean;

        /**
         * The class that will be applied when state is ENTERING or ENTERED.
         * ReactDOM.findDOMNode(this) is used to find the node to apply this class.
         * @see in property
         * */
        inClass?: string;

        /**
         * The class that will be applied when state is EXITING or EXITED.
         * ReactDOM.findDOMNode(this) is used to find the node to apply this class.
         * @see in property
         * */
        outClass?: string;
    };

interface State {}

/**
 * This transition component is perfect if all you need is a variation of the following pattern:
 * 1. When out, `bem('element-name', 'modifier')` => `bem('element-name')`, then unmount.
 * 2. When in: mount, set `bem('element-name')`, then `bem('element-name', 'modifier')`.
 *
 * Compared with `CSSTransition`, you would have to write more boilerplate code to achieve the same.
 */
class SwitchTransition extends Component<SwitchTransitionProps, State> {
    public static defaultProps = {
        appear: true,
        mountOnEnter: true,
        unmountOnExit: true,
    };

    public render() {
        const { timeout, children, addEndListener, appear, mountOnEnter, unmountOnExit, enter, exit, onEnter, onExit } =
            this.props;
        const isAutoTimeout = timeout === 'auto';

        return (
            <Transition
                in={this.props.in}
                appear={appear}
                enter={enter}
                exit={exit}
                timeout={isAutoTimeout ? (undefined as any) : timeout}
                addEndListener={isAutoTimeout ? this._endEventListener : addEndListener}
                onEntering={this._onEntering}
                onEntered={this._onEntered}
                onExiting={this._onExiting}
                onExited={this._onExited}
                onEnter={onEnter}
                onExit={onExit}
                mountOnEnter={mountOnEnter}
                unmountOnExit={unmountOnExit}
            >
                {children}
            </Transition>
        );
    }

    private _endEventListener = (node: HTMLElement, done: () => void) => {
        if (this.props.addEndListener) {
            this.props.addEndListener(node, done);
        } else {
            domHelpers.transitionEnd(node, done);
        }
    };

    private _onEntering = (node: HTMLElement, isAppearing: boolean) => {
        this._forceReflow(node);
        this._switchClasses(node, this.props.outClass, this.props.inClass);

        if (this.props.onEntering) {
            this.props.onEntering(node, isAppearing);
        }
    };

    private _onEntered = (node: HTMLElement, isAppearing: boolean) => {
        this._switchClasses(node, this.props.outClass, this.props.inClass);

        if (this.props.onEntered) {
            this.props.onEntered(node, isAppearing);
        }
    };

    private _onExiting = (node: HTMLElement) => {
        this._forceReflow(node);
        this._switchClasses(node, this.props.inClass, this.props.outClass);

        if (this.props.onExiting) {
            this.props.onExiting(node);
        }
    };

    private _onExited = (node: HTMLElement) => {
        this._switchClasses(node, this.props.inClass, this.props.outClass);

        if (this.props.onExited) {
            this.props.onExited(node);
        }
    };

    private _forceReflow = (node: HTMLElement) => {
        node.scrollTop;
    };

    private _switchClasses = (node: HTMLElement, removeClass: string | undefined, addClass: string | undefined) => {
        if (removeClass) {
            node.classList.remove(removeClass);
        }

        if (addClass) {
            node.classList.add(addClass);
        }
    };
}

export default SwitchTransition;
