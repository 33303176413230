import { constants } from 'modules/common';
import { domain } from 'modules/data';

import XeroSupplierCreationConditionCell from '../components/card/cells/XeroSupplierCreationConditionCell';
import { AccessType, MatrixType } from '../types/matrix';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    MatrixDefinition,
    renderAirwallexBenificaryCreationConditionCell,
    renderAmountCell,
    renderAmountHeader,
    renderDefaultHeader,
    renderExactAsyncCell,
    renderHeaderWithAccessType,
    renderXeroSupplierEditingConditionCell,
} from './matrixDefinitions.shared';

const { xeroConstants } = constants;

const getAutoApproveStep = (options: {
    betaFeatures: domain.CompanyBetaFeature[];
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}) =>
    ({
        columns: [
            {
                systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: ({
                    rule,
                    field,
                    readonly,
                    integrationCode,
                    lineId,
                    condition,
                    onConditionChange,
                    company,
                }) => {
                    return (
                        <XeroSupplierCreationConditionCell
                            rule={rule}
                            field={field}
                            readonly={readonly}
                            hideCreateContactCheckbox={true}
                            integrationCode={integrationCode}
                            lineId={lineId}
                            onConditionChange={onConditionChange}
                            condition={
                                condition as
                                    | domain.ExactValuesCondition
                                    | domain.ServerCondition
                                    | domain.AlwaysTrueCondition
                            }
                            features={company.betaFeatures}
                        />
                    );
                },
            },
            {
                systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.XeroItem,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.XeroTax,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
        ],
    }) as MatrixDefinition;

export function getXeroMatrixDefinitions(options: {
    betaFeatures: domain.CompanyBetaFeature[];
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        [domain.IntegrationCode.XeroPo]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },

                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            company,
                        }) => {
                            return (
                                <XeroSupplierCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={
                                        condition as
                                            | domain.ExactValuesCondition
                                            | domain.ServerCondition
                                            | domain.AlwaysTrueCondition
                                    }
                                    features={company.betaFeatures}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroQuote]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },

                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            company,
                        }) => {
                            return (
                                <XeroSupplierCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={
                                        condition as
                                            | domain.ExactValuesCondition
                                            | domain.ServerCondition
                                            | domain.AlwaysTrueCondition
                                    }
                                    features={company.betaFeatures}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroBill]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Readonly, AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Readonly, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            company,
                        }) => {
                            return (
                                <XeroSupplierCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={
                                        condition as
                                            | domain.ExactValuesCondition
                                            | domain.ServerCondition
                                            | domain.AlwaysTrueCondition
                                    }
                                    features={company.betaFeatures}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },

            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroCreditNotesPayable]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroInvoice]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            company,
                        }) => {
                            return (
                                <XeroSupplierCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={
                                        condition as
                                            | domain.ExactValuesCondition
                                            | domain.ServerCondition
                                            | domain.AlwaysTrueCondition
                                    }
                                    features={company.betaFeatures}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroCreditNotesReceivable]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroContact]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            company,
                        }) => {
                            return (
                                <XeroSupplierCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={
                                        condition as
                                            | domain.ExactValuesCondition
                                            | domain.ServerCondition
                                            | domain.AlwaysTrueCondition
                                    }
                                    features={company.betaFeatures}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroAmaxPayBatchPayment]: {
            /**
             * not implemented in the old design, added here just to avoid crash if open AmaxPayXeroBatchPayment WF
             * see src/app/(workspace)/[companyId]/workflows/[workflowId]/resources/config/matrixDefinitions.xero.tsx
             */
            [MatrixType.Approval]: {
                columns: [],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [],
            },
            [MatrixType.AutoApproval]: {
                columns: [],
            },
        },
        [domain.IntegrationCode.XeroBillBatchPayment]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderXeroSupplierEditingConditionCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroAirwallexBatchPayment]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.AirwallexBeneficiary,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderAirwallexBenificaryCreationConditionCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroManualJournal]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
