import { ChevronRightIcon, CloseIcon, MatrixIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import * as common from 'modules/common';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
    addReviewer,
    addTempUser,
    changeReviewStepDuration,
    deleteReviewStep,
    openInviteUsersPopup,
    openReviewerMatrix,
    removeReviewer,
} from '../../../../actions';
import { getActiveTemplate, isPageReadonly } from '../../../../selectors/pageSelectors';
import { getReviewers } from '../../../../selectors/stepSelectors';
import { isMatrixUnavailable } from '../../../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../../../types/selectors';
import { addDefaultReviewer, addUserEvent, removeUserEvent } from '../../../../utils/events';
import { createUserComparator } from '../../../../utils/helpers';
import AddUserButton from '../../../AddUserButton/AddUserButton';
import StepDeadline from '../../../StepDeadline/StepDeadline';
import StepUser from '../../../StepUser/StepUser';
import { messages } from './ReviewStep.messages';

export const ReviewStep = memo(() => {
    const template = useSelector(getActiveTemplate);
    const company = useSelector(common.selectors.navigation.getActiveCompany);
    const matrixUnavailable = useSelector(isMatrixUnavailable);
    const isActive = !!(
        template &&
        (template.reviewStep.reviewers.length > 0 || template.showReviewStepWithoutReviewers)
    );
    const dispatch = useDispatch();
    const isReadonly = useSelector(isPageReadonly);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const reviewers = useSelector((state: State) => (template ? getReviewers(state, template) : []));
    const reviewStep = template?.reviewStep;
    const canAssignDefaultReviewer = selectors.templateStep.canAssignDefaultReviewer(reviewStep?.reviewers || []);
    const defaultReviewer = useSelector((state: State) =>
        reviewStep ? selectors.templateStep.getDefaultReviewer(state, reviewStep) : null
    );
    const hasReviewStepDeadlineBeta = company.betaFeatures.includes(domain.CompanyBetaFeature.ReviewStepDeadline);

    const isManager = company.flags.isManager || company.flags.isWorkflowManager;
    const enoughRightsToShowReviewStep =
        (company.flags.isAuditor || isManager) && selectors.company.getIsReviewStepAvailable(company);

    const handleDeleteReviewStep = useCallback(() => dispatch(deleteReviewStep()), [dispatch]);

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));

            addUserEvent('reviewStep', template?.integrationCode || null, true);
        },
        [dispatch, template?.integrationCode]
    );

    const onAddReviewer = useCallback(
        (user: domain.User) => {
            dispatch(addReviewer(user, false));

            if (user.databaseId) {
                addUserEvent('reviewStep', template?.integrationCode || null);
            }
        },
        [dispatch, template?.integrationCode]
    );

    const onRemoveReviewer = useCallback(
        (user: domain.User) => {
            dispatch(removeReviewer(user, false));

            removeUserEvent('reviewer', template?.integrationCode || null);
        },
        [dispatch, template?.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onOpenReviewerMatrix = useCallback(() => {
        if (matrixUnavailable) return;
        dispatch(openReviewerMatrix());
    }, [dispatch, matrixUnavailable]);

    const onUserOpenReviewerMatrix = useCallback(
        (userId: string) => {
            if (matrixUnavailable) return;
            dispatch(openReviewerMatrix(userId));
        },
        [dispatch, matrixUnavailable]
    );

    const sortedReviewers = useMemo(
        () => arrayHelpers.arraySort<ExpandedTemplateUser>(reviewers, createUserComparator(team)),
        [reviewers, team]
    );

    const onChangeDeadLine = useCallback(
        (value: string, calculator?: domain.DeadlineRuleType | null) => {
            dispatch(changeReviewStepDuration(value, calculator));
        },
        [dispatch]
    );

    const onAddDefaultReviewer = useCallback(
        (user: selectors.types.ExpandedCompanyUser) => {
            dispatch(addReviewer(user, true));

            addDefaultReviewer(template?.integrationCode || null);
        },
        [dispatch, template?.integrationCode]
    );

    const onRemoveDefaultReviewer = useCallback(
        (user: domain.User) => {
            dispatch(removeReviewer(user, true));

            removeUserEvent('reviewer', template?.integrationCode || null);
        },
        [dispatch, template?.integrationCode]
    );

    if (!isActive || !enoughRightsToShowReviewStep) {
        return null;
    }

    return (
        <>
            <Flex height={28} inline alignItems='center'>
                <ChevronRightIcon size={16} color='midnight60' />
            </Flex>

            <Box color='midnight20' spacing='8' width={228} height='fit-content'>
                <Flex inline justifyContent='space-between' alignItems='center' wrap={false}>
                    <Text font='label' fontSize='small' color='midnight80'>
                        {messages.header}
                    </Text>

                    {isManager && !isReadonly && (
                        <Button icon onClick={handleDeleteReviewStep}>
                            <CloseIcon size={12} color='midnight80' />
                        </Button>
                    )}
                </Flex>

                <Text font='body' fontSize='small' color='midnight80' spacing='8 0 12'>
                    {messages.note({ templateName: template.displayName })}
                </Text>

                <Text font='label' fontSize='small' color='midnight80' uppercase spacing='6 0 8'>
                    {messages.reviewers}
                </Text>

                {reviewers.length === 0 ? (
                    <>
                        <AddUserButton
                            disabled={isReadonly}
                            users={team}
                            excludedUsers={reviewers}
                            onAddNew={onAddTempUser}
                            onSelect={onAddReviewer}
                            title={messages.addReviewer}
                            type='step'
                        />

                        <Text font='body' fontSize='xsmall' color='midnight80' spacing='8 0 0'>
                            {messages.footerText}
                        </Text>
                    </>
                ) : (
                    <>
                        <Flex height={32} spacing='8' inline alignItems='center' wrap={false}>
                            <Flex shrink={0}>
                                <AddUserButton
                                    disabled={isReadonly}
                                    users={team}
                                    excludedUsers={reviewers}
                                    onAddNew={onAddTempUser}
                                    onSelect={onAddReviewer}
                                    title={messages.addReviewer}
                                    type='step'
                                />
                            </Flex>

                            <Button
                                icon
                                noPadding
                                onClick={onOpenReviewerMatrix}
                                disabled={matrixUnavailable}
                                title={messages.addReviewer}
                            >
                                <MatrixIcon size={20} color={matrixUnavailable ? 'midnight80' : 'blue80'} />
                            </Button>

                            {hasReviewStepDeadlineBeta && (
                                <StepDeadline
                                    readonly={isReadonly}
                                    value={reviewStep?.deadlineRule?.duration || ''}
                                    type={reviewStep?.deadlineRule?.calculator || null}
                                    onChange={onChangeDeadLine}
                                />
                            )}
                        </Flex>

                        <Box spacing='8 0' width='100%'>
                            <Flex inline direction='column' spacing='8'>
                                {sortedReviewers.map((reviewers) => (
                                    <StepUser
                                        team={team}
                                        key={reviewers.id}
                                        user={reviewers}
                                        isCompanyMember={team.some((t) => t.id === reviewers.id)}
                                        onRemove={onRemoveReviewer}
                                        readonly={isReadonly}
                                        onInvite={onInviteUser}
                                        onOpenMatrix={onUserOpenReviewerMatrix}
                                    />
                                ))}
                            </Flex>
                        </Box>
                    </>
                )}

                {canAssignDefaultReviewer && (
                    <Box width='100%'>
                        <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                            {messages.defaultReviewer}
                        </Text>

                        <Text font='body' fontSize='xsmall' color='midnight70' spacing='4 0 8'>
                            {messages.reviewsAllRequests}
                        </Text>

                        {defaultReviewer ? (
                            <StepUser
                                team={team}
                                key={defaultReviewer.id}
                                user={defaultReviewer}
                                isCompanyMember={team.some((t) => t.id === defaultReviewer.id)}
                                onRemove={onRemoveDefaultReviewer}
                                readonly={isReadonly}
                                onInvite={onInviteUser}
                            />
                        ) : (
                            <AddUserButton
                                users={team}
                                disabled={isReadonly}
                                onAddNew={onAddTempUser}
                                onSelect={onAddDefaultReviewer}
                                title={messages.addReviewer}
                                type='step'
                            />
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
});

ReviewStep.displayName = 'ReviewStep';
