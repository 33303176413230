import { stateTree } from 'modules/data';
import { ImmutableObject, merge } from 'modules/immutable';

import { Action } from '../actions/index';
import { LOAD_PAGE_DATA } from '../actions/landing';

export default function (
    state: ImmutableObject<stateTree.Navigation>,
    action: Action
): ImmutableObject<stateTree.Navigation> {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return merge(state, {
                innerActivePageId: action.payload.innerActivePageId,
                activePageId: action.payload.sectionPageId,
                activeCompany: action.payload.companyId,
            });

        default:
            return state;
    }
}
