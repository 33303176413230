import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.integration.netsuite.NetSuiteIntegrationPopup', {
    popupTitle: 'Oracle NetSuite account connection',
    connectButton: 'Connect',
    connectionError: 'Failed to connect to Oracle NetSuite',
    connectionTitle: 'ORACLE NETSUITE CONNECTION',
    saveTimeZoneError: 'Failed to save company time zone',
    requiredFieldsError: 'Please fill in all required fields',
    accountIdFieldTitle: 'Oracle NetSuite Account ID',
    consumerKeyFieldTitle: 'Consumer Key',
    consumerSecretFieldTitle: 'Consumer Secret',
    tokenIdFieldTitle: 'Token ID',
    tokenSecretFieldTitle: 'Token Secret',
    subsidiaryIdFieldTitle: 'Subsidiary ID',
    timeZoneFieldTitle: 'Time zone',
    timeZonePlaceholder: 'Time zone',
    companyCreationError: 'Failed to create company',
});
