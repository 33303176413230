import { RequiredMods } from '@approvalmax/theme';
import { Button, Flex, FlexSpacingProp, Text } from '@approvalmax/ui/src/components';
import { CloseIcon } from '@approvalmax/ui/src/icons';
import { hooks } from '@approvalmax/utils';
import { FC, memo, useCallback, useMemo } from 'react';

import { usePopupContext } from '../../Popup.context';
import { ActionsShiftedContainer, Root, StyledProgress } from './Header.styles';
import { HeaderProps } from './Header.types';

export const Header: FC<HeaderProps> = memo((props) => {
    const { children, postfix, title, subTitle, actions, progress, ...restProps } = props;

    const { setToggle, size, closable, root } = usePopupContext();
    const { sticky, stickyRef } = hooks.useSticky({ root });

    const close = useCallback(() => setToggle(false), [setToggle]);
    const [titleSize, subtitleSize, closeSize, subtitleSpacing] = useMemo<
        [RequiredMods['fontSize'], RequiredMods['fontSize'], RequiredMods['iconSize'], FlexSpacingProp]
    >(() => {
        switch (size) {
            case 'xsmall':
                return ['xsmall', 'small', 24, '4'];

            default:
                return ['small', 'medium', 28, '8'];
        }
    }, [size]);

    return (
        <>
            <div ref={stickyRef} />

            <Root $sticky={sticky} {...restProps}>
                {progress && <StyledProgress size='small' />}

                <Flex inline alignItems='start' wrap={false}>
                    {closable && (
                        <ActionsShiftedContainer $hasActions={Boolean(actions)}>
                            <Button onClick={close} icon color='white100' size='small' noPadding>
                                <CloseIcon size={closeSize} />
                            </Button>
                        </ActionsShiftedContainer>
                    )}

                    {children ? (
                        children
                    ) : (
                        <Flex grow={1} direction='column' spacing={subtitleSpacing} inline>
                            <Flex inline justifyContent='space-between' size={0} wrap={false}>
                                {title && (
                                    <Flex grow={1}>
                                        <ActionsShiftedContainer $hasActions={Boolean(actions)}>
                                            <Flex inline wrap={false} spacing='16'>
                                                <Flex>
                                                    <Text
                                                        font='headline'
                                                        fontSize={titleSize}
                                                        color='midnight80'
                                                        as='h2'
                                                        ellipsis={1}
                                                    >
                                                        {title}
                                                    </Text>
                                                </Flex>

                                                {postfix && (
                                                    <Flex>
                                                        <Text
                                                            font='headline'
                                                            as='h2'
                                                            fontSize={titleSize}
                                                            color='midnight60'
                                                            wrap={false}
                                                        >
                                                            {` ${postfix}`}
                                                        </Text>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </ActionsShiftedContainer>
                                    </Flex>
                                )}

                                {actions && <Flex>{actions}</Flex>}
                            </Flex>

                            {subTitle && (
                                <Text font='label' fontSize={subtitleSize} color='midnight70'>
                                    {subTitle}
                                </Text>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Root>
        </>
    );
});

export default Header;
