import { WorkflowWizardPopup } from 'app/(workspace)/[companyId]/workflows/resources/components';
import {
    sessionKeyWorkflowWizardClosedTime,
    storageKeyWorkflowWizardClosedCount,
} from 'app/(workspace)/[companyId]/workflows/resources/constants';
import { useCanShowWorkflowWizardPopup } from 'app/(workspace)/[companyId]/workflows/resources/hooks/useCanShowWorkflowWizardPopup';
import { memo, useCallback, useState } from 'react';

export const WorkflowWizard = memo(() => {
    const [closedInSession, setClosedInSession] = useState(false);

    const canShowWorkflowWizardPopup = useCanShowWorkflowWizardPopup();

    const handleClose = useCallback(() => {
        setClosedInSession(true);

        sessionStorage.setItem(sessionKeyWorkflowWizardClosedTime, String(Date.now()));

        const storageCount = localStorage.getItem(storageKeyWorkflowWizardClosedCount);
        const closedCount = storageCount ? parseInt(storageCount, 10) || 0 : 0;

        localStorage.setItem(storageKeyWorkflowWizardClosedCount, String(closedCount + 1));
    }, []);

    return canShowWorkflowWizardPopup && !closedInSession && <WorkflowWizardPopup onClose={handleClose} />;
});

WorkflowWizard.displayName = 'WorkflowWizard';
