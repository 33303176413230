import { Reference } from '@approvalmax/types';
import { domain } from 'modules/data';

export interface EditableXeroContactPhone {
    countryCode?: string;
    areaCode?: string;
    number?: string;
}

export interface EditableXeroContactAddress {
    address?: string;
    city?: string;
    region?: string;
    postalCode?: string;
    country?: string;
    attentionTo?: string;
}

export interface EditableXeroContact {
    name: string;
    accountNumber?: string;
    bankAccountDetails?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    skypeName?: string;
    website?: string;
    phone: EditableXeroContactPhone;
    fax: EditableXeroContactPhone;
    mobile: EditableXeroContactPhone;
    directDial: EditableXeroContactPhone;
    postalAddress: EditableXeroContactAddress;
    mailingAddress: EditableXeroContactAddress;

    defaultCurrency?: Reference;
    taxNumber?: string;
    companyNumber?: string;

    salesTax?: Reference;
    purchaseTax?: Reference;
    salesAccount?: domain.XeroAccount;
    purchasesAccount?: domain.XeroAccount;
    salesTrackingCategories: EditableXeroTrackingCategory[];
    purchasesTrackingCategories: EditableXeroTrackingCategory[];
}

export interface EditableXeroTrackingCategory extends Reference {
    name?: string;
}

export function createEditableXeroContact(contactName: string): EditableXeroContact {
    return {
        name: contactName,
        phone: {},
        fax: {},
        mobile: {},
        directDial: {},
        postalAddress: {},
        mailingAddress: {},
        salesTrackingCategories: [],
        purchasesTrackingCategories: [],
    };
}
