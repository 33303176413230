import { Dropdown } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useToggle } from 'react-use';

import DeadlineButton from './components/DeadlineButton/DeadlineButton';
import DeadlinePanel from './components/DeadlinePanel/DeadlinePanel';
import { StepDeadlineProps } from './StepDeadline.types';

const StepDeadline: FC<StepDeadlineProps> = memo((props) => {
    const { value, readonly, onChange, type = null } = props;

    const [isOpen, toggleIsOpen] = useToggle(false);

    const onClear = useCallback(() => {
        onChange('', null);
    }, [onChange]);

    return (
        <Dropdown
            width='fit-content'
            maxHeight={500}
            activator={<DeadlineButton onClear={onClear} readonly={readonly} value={value} type={type} />}
            open={isOpen}
            onOpen={toggleIsOpen}
            disabled={readonly}
        >
            <DeadlinePanel value={value} type={type} onChange={onChange} onClose={toggleIsOpen} readonly={readonly} />
        </Dropdown>
    );
});

export default StepDeadline;
