import { QueryOptions } from '@approvalmax/data';
import { requestsApiPaths, useGetRequest, UseGetRequestDuplicatesXeroPathParams } from 'shared/data';

import {
    UseGetRequestDuplicatesXeroParams,
    UseGetRequestDuplicatesXeroRespone,
} from './useGetRequestDuplicatesXero.types';

export const useGetRequestDuplicatesXero = (
    pathParams: UseGetRequestDuplicatesXeroPathParams,
    params: UseGetRequestDuplicatesXeroParams,
    queryOptions?: QueryOptions<UseGetRequestDuplicatesXeroRespone>
) => {
    return useGetRequest<UseGetRequestDuplicatesXeroRespone>(requestsApiPaths.duplicatesXeroPO, {
        pathParams,
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
