import { backend, domain } from 'modules/data';

export const isSalesInvoiceDetails = (
    details: domain.QBooksRequestDetails
): details is domain.QBooksSalesInvoiceDetails => {
    return details.integrationCode === domain.IntegrationCode.QBooksInvoice;
};

export const isSalesInvoiceLineItemAnswer = (
    lineItem: backend.IntegrationsQBooksItemBasedLineAnswer | backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer
): lineItem is backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer => {
    return 'Discount' in lineItem || 'DiscountAmount' in lineItem || 'ServiceDate' in lineItem;
};
