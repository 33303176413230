import { hooks } from '@approvalmax/utils';
import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
} from 'react';

import { Group } from './components';
import { useChecked, useGroup, useValidate } from './Radio.hooks';
import { Control, Icon, Input, Label, Root } from './Radio.styles';
import { ChildrenComponents, RadioProps } from './Radio.types';

/**
 * Radio buttons are used when only one choice may be selected in a series of options.
 * DON'T USE IT WITHOUT THE `Radio.Group` COMPONENT.
 */
const Radio = memo(
    forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
        const {
            innerRef,
            size = 'medium',
            children,
            checked = false,
            disabled,
            onChange,
            onChangeGroup,
            invalid,
            block,
            alignItems,
            ...inputProps
        } = useGroup({ props, ref });

        const { checkedValue, setCheckedValue } = useChecked(checked);
        const { invalidValue, setInvalidValue } = useValidate(invalid);
        const { inputRef, parentProps } = hooks.useCaptureFocus(innerRef);

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setCheckedValue(event.target.checked);
                setInvalidValue(false);

                onChange?.(event.target.checked, event);
                onChangeGroup?.(event.target.checked, event);
            },
            [onChange, onChangeGroup, setCheckedValue, setInvalidValue]
        );

        return (
            <Root
                $size={size}
                $checked={checkedValue}
                $disabled={disabled}
                $invalid={invalidValue}
                $block={block}
                $alignItems={alignItems}
                {...parentProps}
            >
                <Control>
                    <Input
                        type='radio'
                        disabled={disabled}
                        checked={checkedValue}
                        onChange={handleChange}
                        aria-invalid={invalidValue}
                        ref={inputRef}
                        {...inputProps}
                    />

                    <Icon />
                </Control>

                {children && <Label>{children}</Label>}
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<RadioProps & RefAttributes<HTMLInputElement>>> & ChildrenComponents;

Radio.Group = Group;

export default Radio;
