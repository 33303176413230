import { HelpCircleIcon } from '@approvalmax/ui';
import { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { hideHelpStripeState } from '../../HelpStripe.states';
import { StyledButton } from './ToggleButton.styles';

const ToggleButton = memo(() => {
    const [isHideHelpStripe, setIsHideHelpStripe] = useRecoilState(hideHelpStripeState);

    const toggleHelpStripe = useCallback(() => setIsHideHelpStripe((status) => !status), [setIsHideHelpStripe]);

    return (
        <StyledButton icon noPadding onClick={toggleHelpStripe} $hide={isHideHelpStripe}>
            <HelpCircleIcon size={24} />
        </StyledButton>
    );
});

export default ToggleButton;
