import { errorHelpers } from '@approvalmax/utils';
import { domain, State } from 'modules/data';

export const validateNetSuiteRequest = (state: State, request: domain.NetSuiteRequest): string[] => {
    switch (request.integrationCode) {
        case domain.IntegrationCode.NetSuiteBill:
            return [];

        case domain.IntegrationCode.NetSuitePO:
            return [];

        case domain.IntegrationCode.NetSuiteExpenseReport:
            return [];

        default:
            throw errorHelpers.notSupportedError();
    }
};
