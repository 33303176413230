import { defineMessages } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain } from 'modules/data';

import BoolConditionCell from '../components/MatrixPopupContent/components/BoolConditionCell/BoolConditionCell';
import ConditionHeaderColumn from '../components/MatrixPopupContent/components/ConditionHeaderColumn/ConditionHeaderColumn';
import NetSuiteVendorCreationConditionCell from '../components/MatrixPopupContent/components/NetSuiteVendorCreationConditionCell/NetSuiteVendorCreationConditionCell';
import { AccessType, MatrixType } from '../types/matrix';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    MatrixDefinition,
    renderAmountCell,
    renderAmountHeader,
    renderDefaultHeader,
    renderExactAsyncCell,
    renderHeaderWithAccessType,
} from './matrixDefinitions.shared';

const { netSuiteConstants } = constants;

const messages = defineMessages('app.WorkflowIdPage.config.matrixDefinitions.netsuite', {
    salesRepFieldName: 'Sales Rep',
});

const customFieldDefinition: MatrixDefinition['columns'][0] = {
    systemPurpose: domain.FieldSystemPurpose.NetSuiteCustom,
    renderHeader: ({ field, readonly, matrixType }) => (
        <ConditionHeaderColumn
            fieldId={field.id}
            fieldName={field.name}
            readonly={readonly}
            canRename={false}
            canRemove
            matrixType={matrixType}
        />
    ),
    renderCell: (options) => {
        const fieldType = options.field.netSuiteField?.type ?? '';

        if (netSuiteConstants.NUMERIC_CUSTOM_FIELDS.includes(fieldType)) {
            return renderAmountCell({
                ...options,
                showCurrency: false,
            });
        }

        if (netSuiteConstants.BOOLEAN_CUSTOM_FIELDS.includes(fieldType)) {
            return <BoolConditionCell {...options} />;
        }

        return renderExactAsyncCell(options);
    },
};

const approverBillAndPOFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: renderAmountHeader,
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.Requester,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteVendor,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteExpenseCategory,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteItem,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustomer,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteTaxCode,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    customFieldDefinition,
];

const approverSalesOrderFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: renderAmountHeader,
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustomer,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteEmployee,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: messages.salesRepFieldName,
            }),
        renderCell: (options) =>
            renderExactAsyncCell({
                ...options,
                field: {
                    ...options.field,
                    name: messages.salesRepFieldName,
                },
            }),
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteItem,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteTaxCode,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    customFieldDefinition,
];

const requesterExpenseReportFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteEmployee,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteExpenseCategory,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustomer,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteTaxCode,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    customFieldDefinition,
];

const approverExpenseReportFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: renderAmountHeader,
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.Requester,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    ...requesterExpenseReportFields,
];

const requesterBillAndPOFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteVendor,
        renderHeader: (options) =>
            renderHeaderWithAccessType(options, {
                accessTypeOptions: [AccessType.Mandatory],
                defaultAccessType: AccessType.Mandatory,
            }),
        renderCell: ({ rule, field, readonly, integrationCode, lineId, condition, onConditionChange }) => {
            return (
                <NetSuiteVendorCreationConditionCell
                    rule={rule}
                    field={field}
                    readonly={readonly}
                    integrationCode={integrationCode}
                    lineId={lineId}
                    onConditionChange={onConditionChange}
                    condition={
                        condition as domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition
                    }
                    matrixType={MatrixType.Requester}
                />
            );
        },
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteExpenseCategory,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteItem,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustomer,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteTaxCode,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustom,
        renderHeader: ({ field, readonly, matrixType }) => (
            <ConditionHeaderColumn
                fieldId={field.id}
                fieldName={field.name}
                readonly={readonly}
                canRename={false}
                canRemove
                matrixType={matrixType}
            />
        ),
        renderCell: (options) => renderExactAsyncCell(options),
    },
];

const approverVRAFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: renderAmountHeader,
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteVendor,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteExpenseCategory,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteItem,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustomer,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteTaxCode,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    customFieldDefinition,
];

const approverBillPaymentsFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: renderAmountHeader,
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.Requester,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuitePayee,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    customFieldDefinition,
];

const approverRAFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: renderAmountHeader,
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteCustomer,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteEmployee,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: messages.salesRepFieldName,
            }),
        renderCell: (options) =>
            renderExactAsyncCell({
                ...options,
                field: {
                    ...options.field,
                    name: messages.salesRepFieldName,
                },
            }),
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteItem,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.NetSuiteTaxCode,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    customFieldDefinition,
];

export function getNetSuiteMatrixDefinitions(options: {
    betaFeatures: domain.CompanyBetaFeature[];
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        [domain.IntegrationCode.NetSuiteBill]: {
            [MatrixType.Approval]: { columns: approverBillAndPOFields },
            [MatrixType.Requester]: { columns: requesterBillAndPOFields },
            [MatrixType.AutoApproval]: { columns: approverBillAndPOFields },
        },
        [domain.IntegrationCode.NetSuitePO]: {
            [MatrixType.Approval]: { columns: approverBillAndPOFields },
            [MatrixType.Requester]: { columns: requesterBillAndPOFields },
            [MatrixType.AutoApproval]: { columns: approverBillAndPOFields },
        },
        [domain.IntegrationCode.NetSuiteSalesOrder]: {
            [MatrixType.Approval]: { columns: approverSalesOrderFields },
            [MatrixType.AutoApproval]: { columns: approverSalesOrderFields },
        },
        [domain.IntegrationCode.NetSuiteInvoice]: {
            [MatrixType.Approval]: { columns: approverSalesOrderFields },
            [MatrixType.AutoApproval]: { columns: approverSalesOrderFields },
        },
        [domain.IntegrationCode.NetSuiteExpenseReport]: {
            [MatrixType.Approval]: { columns: approverExpenseReportFields },
            [MatrixType.Requester]: { columns: requesterExpenseReportFields },
            [MatrixType.AutoApproval]: { columns: approverExpenseReportFields },
        },
        [domain.IntegrationCode.NetSuiteVRA]: {
            [MatrixType.Approval]: { columns: approverVRAFields },
            [MatrixType.AutoApproval]: { columns: approverVRAFields },
        },
        [domain.IntegrationCode.NetSuiteBillPayment]: {
            [MatrixType.Approval]: { columns: approverBillPaymentsFields },
            [MatrixType.AutoApproval]: { columns: approverBillPaymentsFields },
        },
        [domain.IntegrationCode.NetSuiteRA]: {
            [MatrixType.Approval]: { columns: approverRAFields },
            [MatrixType.AutoApproval]: { columns: approverRAFields },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
