/**
 * Developer: Stepan Burguchev
 * Date: 5/18/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { immutable, ImmutableObject } from 'modules/immutable';

import { Action } from '../../actions/index';
import { DISCARD_INVITE_USERS_POPUP, INVITE_USERS, OPEN_INVITE_USERS_POPUP } from '../../actions/inviteUsers';

export interface InviteUsersData {
    users: string[];
    preselected: string[];
}

export type ActiveInviteUsers = ImmutableObject<InviteUsersData> | null;

export default function activeInviteUsersReducer(state: ActiveInviteUsers = null, action: Action): ActiveInviteUsers {
    switch (action.type) {
        case OPEN_INVITE_USERS_POPUP:
            return immutable<InviteUsersData>({
                users: action.users,
                preselected: action.preselectedUsers,
            });

        case INVITE_USERS:
        case DISCARD_INVITE_USERS_POPUP:
            return null;

        default:
            return state;
    }
}
