import Dropdown from '@approvalmax/ui/src/components/Dropdown/Dropdown';
import { FC, memo } from 'react';

import { Content } from './Tooltip.styles';
import { TooltipProps } from './Tooltip.types';

/**
 * A tooltip is a brief, informative message that appears when a user interacts with an element.
 *
 * Based on https://floating-ui.com library
 */
const Tooltip: FC<TooltipProps> = memo((props) => {
    const {
        size = 'medium',
        isClicking = false,
        display = 'inline-block',
        width = 'max-content',
        children,
        ...restProps
    } = props;

    return (
        <Dropdown {...restProps} hasArrow={true} width={width} isHovering isClicking={isClicking} display={display}>
            {children && <Content $size={size}>{children}</Content>}
        </Dropdown>
    );
});

export default Tooltip;
