import { compareHelpers, errorHelpers } from '@approvalmax/utils';
import { statics } from 'modules/common';
import { domain, du } from 'modules/data';
import { isSalesInvoiceLineItemAnswer } from 'pages/requestForm/utils/typeGuards';
import { emailToSupplierHelpers } from 'shared/helpers';

import * as backend from '../../backend';
import { getBeautifiedEntity } from '../../utils';
import { IntegrationCode } from '../Integration';
import {
    LineAmountType,
    QBooksDiscountType,
    QBooksExpenseDetails,
    QBooksRequestSpecifics,
    QBooksSalesInvoiceDetails,
    QBooksVendorDetails,
    RequestAttachment,
} from '../Request';
import {
    QBooksAccountLineItem,
    QBooksBaseLineItem,
    QBooksBillDetails,
    QBooksCustomField,
    QBooksJournalEntryDetails,
    QBooksLineItem,
    QBooksPurchaseOrderDetails,
    QBooksSharedDetails,
    QBooksTaxComponent,
} from '../Request.QBooks';
import { mapAttachment } from './requestSchema';
import { mapReference, mapReferenceWithValue } from './utils';

const mapCustomField = (value: backend.IntegrationsQBooksCustomField): QBooksCustomField => {
    return {
        field: mapReference(value)!,
        value: value.Value!,
    };
};

const mapTaxComponent = (value: backend.IntegrationsQBooksTaxComponent): QBooksTaxComponent => {
    return {
        taxAmount: value.TaxAmount,
        taxPercent: value.TaxPercent,
        taxableAmount: value.TaxableAmount,
        taxRateId: value.TaxRateId!,
        hidden: value.Hidden,
    };
};

const mapBaseLineItem = (value: backend.IntegrationsQBooksLineItemAnswer): QBooksBaseLineItem => {
    return {
        id: value.LineItemId || '',
        description: value.Description || '',
        amount: value.Amount,
        taxCode: value.TaxCode
            ? {
                  id: value.TaxCode.Id!,
                  text: value.TaxCode.Name!,
              }
            : undefined,
        customer: value.Customer ? mapReference(value.Customer)! : undefined,
        class: value.Class ? mapReference(value.Class)! : undefined,
        isBillable: value.IsBillable,
        isTaxable: value.IsTaxable,
        isMatched: value.IsMatched,
        remainingBalance: value.RemainingBalance,
    };
};

export const mapLineItem = (
    value: backend.IntegrationsQBooksItemBasedLineAnswer | backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer
): QBooksLineItem => {
    const common = {
        ...mapBaseLineItem(value),
        defaultRate: value.CatalogUnitPrice ?? null,
        item: value.ItemId
            ? {
                  id: value.ItemId,
                  text: value.Item || '',
              }
            : undefined,
        qty: value.Qty,
        unitPrice: value.UnitPrice,
        unitPriceGross: value.UnitPriceGross || value.UnitPrice,
    };

    return isSalesInvoiceLineItemAnswer(value)
        ? {
              ...common,
              discount: value.Discount ?? null,
              discountAmount: value.DiscountAmount ?? null,
              serviceDate: value.ServiceDate ?? null,
          }
        : {
              ...common,
              isInventory: value.IsInventory ?? false,
          };
};

export const mapAccountLineItem = (value: any): QBooksAccountLineItem => {
    return {
        ...mapBaseLineItem(value),
        account: value.AccountId
            ? {
                  id: value.AccountId,
                  text: value.Account,
              }
            : undefined,
    };
};

const mapSharedDetails = (value: any): QBooksSharedDetails => {
    return {
        number: '', // To be overridden later
        date: value.Date!,
        vendor: mapReference(value.Vendor)!,
        department: mapReference(value.Department),
        departmentTerminology: value.DepartmentTerminology === undefined ? null : value.DepartmentTerminology,
        lineItems: (value.LineItems || (value as any).ItemBasedLines || []).map(mapLineItem),
        accountLineItems: ((value as any).AccountBasedLines || []).map(mapAccountLineItem),
        memo: value.Memo!,
        mailingAddress: value.MailingAddress!,
        subTotal: value.SubTotal || 0,
        lineAmountType: value.LineAmountType as LineAmountType,
        totalTax: value.TotalTax || 0,
        taxComponents: (value.TaxComponents || []).map(mapTaxComponent),
        isStatusPushPending: value.IsStatusPushPending || value.isStatusPushPending || false,
        lockDate: value.LockDate || value.CompanyLockDate || null,
        lockDatePolicy: value.TemplateLockDatePolicy,
        url: value.Url || value.url || null,
        editUrl: value.EditUrl || value.editUrl || null,
    };
};

const mapPurchaseOrder = (
    value: backend.IntegrationsQBooksPurchaseOrderAnswer,
    emailToContact?: backend.EmailToContact
): QBooksPurchaseOrderDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Purchase Order details is undefined.');
    }

    const shared = mapSharedDetails(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksPo,
        integrationType: domain.IntegrationType.QBooks,
        number: value.PurchaseOrderNumber,
        vendorMessage: value.VendorMessage!,
        customFields: (value.CustomFields || []).map(mapCustomField),
        shipping: {
            method: value.ShippingMethod!,
            address: value.ShippingAddress!,
        },
        sendToSupplier: emailToContact?.EmailHasToBeSent || false,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
        status: value.Status || null,
        grnStatus: value.GrnStatus || null,
    };
};

const mapSalesInvoice = (
    value: backend.IntegrationsQBooksInvoiceAnswer,
    emailToContact?: backend.EmailToContact
): QBooksSalesInvoiceDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Sales Invoice details is undefined.');
    }

    return {
        applyTaxAfterDiscount: value.ApplyTaxAfterDiscount || false,
        balance: value.Balance,
        billingAddress: value.BillingAddress ?? '',
        customFields: (value.CustomFields || []).map(mapCustomField),
        customer: mapReference(value.Customer),
        customerMemo: value.CustomerMemo ?? '',
        date: value.Date,
        department: mapReference(value.Department),
        deposit: value.Deposit,
        discount: 'Discount' in value ? value.Discount : value.DiscountAmount,
        discountType: 'Discount' in value ? QBooksDiscountType.Percent : QBooksDiscountType.Amount,
        dueDate: value.DueDate ?? '',
        editUrl: value.EditUrl || null,
        integrationCode: domain.IntegrationCode.QBooksInvoice,
        integrationType: domain.IntegrationType.QBooks,
        isStatusPushPending: value.IsStatusPushPending || false,
        lineAmountType: value.LineAmountType,
        lineItems: (value.LineItems || []).map(mapLineItem),
        lockDate: value.LockDate || value.CompanyLockDate || null,
        lockDatePolicy: value.TemplateLockDatePolicy,
        memo: value.Memo ?? '',
        number: value.SalesInvoiceNumber,
        shipping: {
            method: value.ShippingMethod ?? '',
            address: value.ShippingAddress ?? '',
            date: value.ShippingDate ?? '',
            price: value.Shipping ?? null,
            fromAddress: value.ShippingFromAddress ?? '',
        },
        shippingTaxAmount: value.ShippingTaxAmount,
        shippingTaxCode: mapReference(value.ShippingTaxCode),
        status: value.Status,
        subTotal: value.SubTotal || 0,
        taxCode: mapReference(value.TaxCode),
        taxComponents: (value.TaxComponents || []).map(mapTaxComponent),
        term: mapReference(value.Term),
        totalTax: value.TotalTax || 0,
        trackingNumber: value.TrackingNumber ?? '',
        url: value.Url || null,
        useAutoTaxes: value.UseAutoTaxes ?? false,

        emailToContact: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };
};

const mapBill = (value: backend.IntegrationsQBooksBillInvoiceAnswer): QBooksBillDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Bill details is undefined.');
    }

    const shared = mapSharedDetails(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksBill,
        integrationType: domain.IntegrationType.QBooks,
        number: value.BillInvoiceNumber,
        dueDate: value.DueDate!,
        term: mapReference(value.Term),
        isPaid: value.IsPaid,
    };
};

const mapExpense = (value: any): QBooksExpenseDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Expense details is undefined.');
    }

    const shared = mapSharedDetails(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksExpense,
        integrationType: domain.IntegrationType.QBooks,
        payee: mapReference(value.Payee) || null,
        payeeType: mapReferenceWithValue<backend.IntegrationsQBooksPayeeTypes>(value.PayeeType) || null,
        paymentAccount: mapReference(value.PaymentAccount) || null,
        paymentType: mapReference(value.PaymentType) || null,
        paymentMethod: mapReference(value.PaymentMethod) || null,
        expenseNumber: value.ExpenseNumber,
        newCreatedVendor: null,
    };
};

const mapVendor = (value: any) => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Bill details is undefined.');
    }

    return {
        ...mapSharedDetails(value),
        ...getBeautifiedEntity(value),
        address: getBeautifiedEntity(value.address),
        paymentDetails: value.paymentDetails ? getBeautifiedEntity(value.paymentDetails) : null,
        currencyCode: statics.currency.findCurrencyByCode(value.currencyCode || ''),
    } as unknown as QBooksVendorDetails;
};

const mapJournalEntry = (value: backend.IntegrationsQBooksJournalEntryAnswer): QBooksJournalEntryDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Journal Entry details is undefined.');
    }

    return {
        integrationCode: domain.IntegrationCode.QBooksJournalEntry,
        integrationType: domain.IntegrationType.QBooks,
        date: value.Date || '',
        number: value.Number || '',
        memo: value.Memo || '',
        lineAmountType: (value.LineAmountType as LineAmountType) || domain.LineAmountType.TaxExclusive,
        debitSubTotal: value.DebitSubTotal || 0,
        creditSubTotal: value.CreditSubTotal || 0,
        debitTotalTax: value.DebitTotalTax || 0,
        creditTotalTax: value.CreditTotalTax || 0,
        lines: (value.Lines || []).map((line) => ({
            id: line.LineId,
            account: line.Account ? du.parseServerReference(line.Account) : null,
            amount: line.Amount,
            amountType: line.AmountSide,
            description: line.Description,
            payeeType: line.PayeeType
                ? {
                      id: line.PayeeType,
                      text: line.PayeeType,
                  }
                : null,
            payee: line.Payee ? du.parseServerReference(line.Payee) : null,
            taxCode: line.TaxCode ? du.parseServerReference(line.TaxCode) : null,
            taxRateId: line.TaxRate?.TaxRateId || null,
            taxApplicableOnType: line.TaxApplicableOnType || domain.TaxApplicableOnType.None,
            class: line.Class ? du.parseServerReference(line.Class) : null,
            department: line.Department ? du.parseServerReference(line.Department) : null,
        })),
        taxComponents: value.TaxComponents.map((taxComponent) => ({
            taxableAmount: taxComponent.TaxableAmount,
            taxApplicableOnType: taxComponent.TaxApplicableOnType,
            taxCodeId: taxComponent.TaxCodeId,
            taxCodeName: taxComponent.TaxCodeName,
            taxDebitAmount: taxComponent.TaxDebitAmount,
            taxCreditAmount: taxComponent.TaxCreditAmount,
            taxRateId: taxComponent.TaxRateId,
            taxPercent: taxComponent.TaxPercent,
            taxAmount: taxComponent.TaxAmount,
            hidden: taxComponent.Hidden,
        })),
        url: value.Url || null,
        editUrl: value.EditUrl || null,
        isStatusPushPending: value.IsStatusPushPending || false,
        lockDatePolicy: value.TemplateLockDatePolicy,
        lockDate: value.LockDate || value.CompanyLockDate || null,
    };
};

export const getQbooksBase = (request: backend.RequestAnswer): QBooksRequestSpecifics => {
    switch (request.IntegrationCode) {
        case IntegrationCode.QBooksPo:
            return {
                details: mapPurchaseOrder(request.QBooksPurchaseOrderDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksInvoice:
            return {
                details: mapSalesInvoice(request.QBooksSalesInvoiceDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksBill:
            return {
                details: mapBill(request.QBooksBillInvoiceDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksExpense:
            return {
                details: mapExpense(request.QBooksExpenseDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksVendor:
            return {
                details: mapVendor(request.QBooksVendorDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksJournalEntry:
            return {
                details: mapJournalEntry(request.QBooksJournalEntryDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        default:
            throw errorHelpers.notSupportedError();
    }
};
