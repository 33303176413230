import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseTwoFaDisablingVerifyEmailCodeRequest,
    UseTwoFaDisablingVerifyEmailCodeResponse,
} from './useTwoFaDisablingVerifyEmailCode.types';

export const useTwoFaDisablingVerifyEmailCode = () => {
    return useMutateLegacy<
        RequestDataParams<UseTwoFaDisablingVerifyEmailCodeRequest>,
        UseTwoFaDisablingVerifyEmailCodeResponse
    >(twoFaApiPaths.disablingVerifyEmailCode);
};
