import { hooks } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useCurrentUser, useMyTimeZone } from 'modules/utils';
import { useDelegations, type UseUpdateDelegationsData } from 'shared/data';
import { delegationHelpers } from 'shared/helpers';

import { getMyDelegatesPopupData } from '../../selectors/moduleSelectors';

export const useMyDelegatesPopupData = () => {
    const { data: delegatesData, isLoading } = useDelegations({
        staleTime: 60 * 1000,
    });
    const me = useCurrentUser();
    const myTimeZone = useMyTimeZone();
    const activeCompanyId = useSelector(selectors.navigation.getActiveCompanyId);

    const delegates = useSelector((state) => {
        const data = getMyDelegatesPopupData(state);

        return (data?.delegates || []).map((myCompanyDelegate) => {
            const company = selectors.company.getCompanyById(state, myCompanyDelegate.companyId);
            const team = selectors.company.getCompanyTeam(state, company);
            const companyDelegation = delegatesData?.items.find((delegation) => delegation.companyId === company.id);
            const delegate = companyDelegation?.delegates.find(
                (delegate) => delegate.fromUser.userId === me.databaseId
            );

            // Then data arrives, we need to recalculate dates according to the
            // TZ shift between local TZ and user profile TZ
            // Later on submit we will do a backward shift to the user profile TZ (not local)
            const newTz = statics.timeZone.findTimeZoneById(myTimeZone);
            const newUtcOffset = statics.timeZone.getUtcOffset(newTz);

            return {
                company,
                delegateId: delegate?.toUser?.email ?? null,
                delegate: delegate?.toUser,
                dateFrom: delegate?.dateFrom
                    ? statics.timeZone.addOffset(delegate.dateFrom, newUtcOffset).toISOString()
                    : null,
                dateTo: delegate?.dateTo
                    ? statics.timeZone.addOffset(delegate.dateTo, newUtcOffset).toISOString()
                    : null,
                originalDateFrom: delegate?.dateFrom,
                originDateTo: delegate?.dateTo,
                timeZone: myTimeZone,
                /**
                 * Non profile TZ where dateFrom was set
                 */
                dateFromSetTimeZone: myTimeZone,
                /**
                 * Non profile TZ where dateTo was set
                 */
                dateToSetTimeZone: myTimeZone,
                possibleDelegates: team.filter((teamUser) => teamUser.id !== me.id),
            };
        });
    });

    const filterOutUnchanged = (newDelegations: UseUpdateDelegationsData) => {
        return newDelegations.filter((delegation) => {
            const oldDelegation = delegatesData?.items.find(
                (oldDelegation) => oldDelegation.companyId === delegation.companyId
            );

            return delegation.delegates.some((delegate) => {
                const oldDelegate = oldDelegation?.delegates.find(
                    (oldDelegate) => oldDelegate.fromUser.userId === delegate.fromUser.userId
                );

                return (
                    delegate.toUser?.userId !== oldDelegate?.toUser.userId ||
                    delegationHelpers.compareDates(delegate?.dateFrom, oldDelegate?.dateFrom) ||
                    delegationHelpers.compareDates(delegate?.dateTo, oldDelegate?.dateTo)
                );
            });
        });
    };

    return {
        delegates,
        filterOutUnchanged,
        me,
        myTimeZone,
        companyId: activeCompanyId,
        isLoading,
    };
};

export const useDelegatesFilter = (delegates: ReturnType<typeof useMyDelegatesPopupData>['delegates']) => {
    const { filteredData, handleSearchQueryChange, searchQuery } = hooks.useFuzzySearch(delegates, {
        keys: ['company.displayName'],
    });

    return { delegates: filteredData, handleSearchQueryChange, searchQuery };
};
