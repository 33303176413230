import { FC, memo } from 'react';

import { usePopupContext } from '../../Popup.context';
import { Root } from './Body.styles';
import { BodyProps } from './Body.types';

const Body: FC<BodyProps> = memo((props) => {
    const { children, spacing, ...restProps } = props;

    const { size, closable } = usePopupContext();

    return (
        <Root $size={size} $closable={closable} spacing={spacing} {...restProps}>
            {children}
        </Root>
    );
});

export default Body;
