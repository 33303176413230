import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionDecisionPolicy.messages';
import { SectionDecisionPolicyProps } from './SectionDecisionPolicy.types';

const SectionDecisionPolicy: FC<SectionDecisionPolicyProps> = memo((props) => {
    const { links, settings, onChangeDecisionPolicy, qa, readonly } = props;

    const value = settings || domain.TemplateSettingsDecisionPolicyType.OnlyCurrent;

    return (
        <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.ApprovalDecisionPolicy]}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={onChangeDecisionPolicy}
                    name='decisionPolicy'
                    block
                    data-qa={qa('decision_policy')}
                >
                    <Radio value={domain.TemplateSettingsDecisionPolicyType.AllSteps}>{messages.allStepLabel}</Radio>

                    <Radio value={domain.TemplateSettingsDecisionPolicyType.OnlyCurrent}>
                        {messages.currentStepLabel}
                    </Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionDecisionPolicy;
