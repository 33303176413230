import { QBooksLogo, TransparentButton } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { Cin7Logo, DoneIcon, OracleNetsuiteLogo, TbReloadMiniIcon, XeroIcon } from 'modules/sprites';
import { font, oDisabled, oPopupBorderBottom, uTextEllipsis } from 'modules/styles';
import moment from 'moment';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { CompanyTitleText } from '../CompanyTitleText/CompanyTitleText';

const i18nPrefix = 'requestList/containers/SyncIntegrationButton/components/ConnectedCompanies/ConnectedCompanies';

interface ConnectedCompanyProps {
    company: selectors.types.ExpandedCompany;
    onSyncIntegration: (companyId: string) => void;
    syncInProgress: domain.ExtendedIntegration[];
}

const Root = styled.div``;
const CompanyItem = styled.div`
    display: flex;
    flex-flow: column;
    margin: 12px 0 10px 0;
`;

const CompanyTemplates = styled.div`
    display: flex;
    flex-flow: column;
`;

const CompanyTitle = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    ${uTextEllipsis}
    ${font(12, '#8c9ca7', 'semibold')}
`;

const CompanyTemplateName = styled.div`
    flex: 1;
    ${uTextEllipsis}
    ${font(12, '#8c9ca7', 'semibold')}
`;

const CompanyTitleIcon = styled.div`
    line-height: 0;
    margin: 0 10px 0 40px;
    flex: none;
`;
const CompanyTemplateItem = styled.div`
    display: flex;
    align-items: center;
    height: 35px;
    flex: none;
`;
const CompanyTemplateDoneIcon = styled(DoneIcon)<{ hidden: boolean }>`
    margin: 0 17px 0 50px;
    visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;
const CompanyTemplateLineContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 80px;
    flex: 1;
    ${oPopupBorderBottom()}
`;

const CompanyTitleSyncButton = styled.button`
    flex: none;
    padding: 0 2px;
    margin: 0 45px 0 15px;
    cursor: pointer;

    &:disabled {
        ${oDisabled}
    }
`;
const CompanyTemplateStatus = styled.div<{ inSync: boolean; error: boolean }>`
    ${font(12, '#000000')}
    width: 110px;

    ${(props) =>
        props.inSync &&
        css`
            color: #6c6a6a;
            font-style: italic;
        `}
    ${(props) =>
        props.error &&
        css`
            ${font(12, '#a3341a', 'bold')}
        `}
`;

const ConnectedCompany: FC<ConnectedCompanyProps> = (props) => {
    const { company, onSyncIntegration, syncInProgress } = props;

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    let IntegrationIcon;

    const [now] = useState(moment().toISOString());
    const integrationType = company.integration!.integrationType;

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            IntegrationIcon = XeroIcon;
            break;

        case domain.IntegrationType.QBooks:
            IntegrationIcon = QBooksLogo;
            break;

        case domain.IntegrationType.NetSuite:
            IntegrationIcon = OracleNetsuiteLogo;
            break;

        case domain.IntegrationType.Dear:
            IntegrationIcon = Cin7Logo;
            break;

        case domain.IntegrationType.None:
            throw errorHelpers.invalidOperationError();

        default:
            throw errorHelpers.assertNever(integrationType);
    }

    const syncForCompany = (syncInProgress || []).find((item) => item.companyId === company.id);

    const hasActiveSync = syncForCompany?.syncProgress.some(
        (templateSync) => templateSync.status === domain.IntegrationSyncStatus.InProgress
    );

    const companySyncDisabled = company.isReadonly || isButtonDisabled || hasActiveSync;

    useEffect(() => {
        setIsButtonDisabled(false);
    }, [hasActiveSync]);

    const getNow = useCallback(() => {
        return moment.max(moment(now), moment());
    }, [now]);

    const onSyncClick = useCallback(() => {
        setIsButtonDisabled(true);

        onSyncIntegration(company.id);
    }, [onSyncIntegration, company.id]);

    return (
        <CompanyItem>
            <CompanyTitle>
                <CompanyTitleIcon>
                    <IntegrationIcon width={30} height={30} />
                </CompanyTitleIcon>

                <CompanyTitleText>{company.displayName}</CompanyTitleText>

                <TransparentButton execute={onSyncClick} disabled={companySyncDisabled}>
                    {({ elementProps }) => (
                        <CompanyTitleSyncButton {...elementProps}>
                            <TbReloadMiniIcon width={16} height={20} />
                        </CompanyTitleSyncButton>
                    )}
                </TransparentButton>
            </CompanyTitle>

            <CompanyTemplates>
                {syncForCompany?.syncProgress.map((syncItem) => {
                    let statusText;

                    switch (syncItem.status) {
                        case domain.IntegrationSyncStatus.InProgress:
                            statusText = (
                                <FormattedMessage
                                    id={`${i18nPrefix}.inProgressStatusText`}
                                    defaultMessage='Sync in progress'
                                />
                            );
                            break;

                        case domain.IntegrationSyncStatus.Error:
                            statusText = (
                                <FormattedMessage id={`${i18nPrefix}.errorStatusText`} defaultMessage='Error' />
                            );
                            break;

                        case domain.IntegrationSyncStatus.Done:
                            if (syncItem.lastSyncDate) {
                                statusText = moment.min(moment(syncItem.lastSyncDate), moment()).from(getNow());
                            } else {
                                statusText = '-';
                            }

                            break;

                        default:
                            throw errorHelpers.assertNever(syncItem.status);
                    }

                    return (
                        <CompanyTemplateItem key={syncItem.templateId}>
                            <CompanyTemplateDoneIcon
                                hidden={syncItem.status !== domain.IntegrationSyncStatus.Done || !syncItem.lastSyncDate}
                                width={13}
                                height={10}
                            />

                            <CompanyTemplateLineContent>
                                <CompanyTemplateName>
                                    {selectors.template.getTemplateDisplayNameByCode(
                                        syncItem.templateIntegrationCode,
                                        true
                                    )}
                                </CompanyTemplateName>

                                <CompanyTemplateStatus
                                    inSync={syncItem.status === domain.IntegrationSyncStatus.InProgress}
                                    error={syncItem.status === domain.IntegrationSyncStatus.Error}
                                >
                                    {statusText}
                                </CompanyTemplateStatus>
                            </CompanyTemplateLineContent>
                        </CompanyTemplateItem>
                    );
                })}
            </CompanyTemplates>
        </CompanyItem>
    );
};

export default memo(ConnectedCompany);
