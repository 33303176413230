import { Search } from 'history';

export function getParamAsString<Return extends string = string>(
    search: Search,
    key: string,
    defaultValue: string
): Return;
export function getParamAsString<Return extends string = string>(search: Search, key: string): Return;

export function getParamAsString(search: Search, key: string, defaultValue?: string): string | undefined {
    const param = new URLSearchParams(search).get(key);

    return param || defaultValue;
}
