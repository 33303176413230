/**
 * Developer: Stepan Burguchev
 * Date: 3/10/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Guid } from '@approvalmax/types';
import { addArrayItem, ImmutableObject, removeArrayItem, set } from 'modules/immutable';

import { Action, UPDATE_TEMPLATE_GROUP_COLLAPSE_PREFERENCE } from '../actions';

export const COLLAPSED_TEMPLATE_GROUP_ITEMS = 'workflows/collapsedTemplateGroups';

interface LocalUserPreferences {
    [COLLAPSED_TEMPLATE_GROUP_ITEMS]: string[];
}

export default function (
    state: ImmutableObject<LocalUserPreferences>,
    action: Action
): ImmutableObject<LocalUserPreferences> {
    switch (action.type) {
        case UPDATE_TEMPLATE_GROUP_COLLAPSE_PREFERENCE: {
            const groupId = createTemplateGroupId(action.payload.groupId, action.payload.companyId);
            const collapsedTemplateGroups = action.payload.collapsed
                ? addArrayItem(state[COLLAPSED_TEMPLATE_GROUP_ITEMS] || [], groupId)
                : removeArrayItem(state[COLLAPSED_TEMPLATE_GROUP_ITEMS] || [], groupId);

            return set(state, COLLAPSED_TEMPLATE_GROUP_ITEMS, collapsedTemplateGroups);
        }

        default:
            return state;
    }
}

export function createTemplateGroupId(groupId: string, companyId: Guid) {
    return `${companyId}-${groupId}`;
}
