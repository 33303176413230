import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { defineMessages } from 'react-intl';

const i18nPrefix = 'reports.columnGroups';
const messages = defineMessages({
    lineItemFieldText: {
        id: `${i18nPrefix}.lineItemFieldText`,
        defaultMessage: 'Line items data',
    },
    keyDataText: {
        id: `${i18nPrefix}.keyDataText`,
        defaultMessage: 'Key data',
    },
    workflowText: {
        id: `${i18nPrefix}.workflowText`,
        defaultMessage: 'Workflow',
    },
    complianceText: {
        id: `${i18nPrefix}.complianceText`,
        defaultMessage: 'Compliance',
    },
    matchingText: {
        id: `${i18nPrefix}.matchingText`,
        defaultMessage: 'Matching',
    },
    poDetailsText: {
        id: `${i18nPrefix}.poDetailsText`,
        defaultMessage: 'PO details',
    },
    quoteDetailsText: {
        id: `${i18nPrefix}.quoteDetailsText`,
        defaultMessage: 'Quote details',
    },
    billDetailsText: {
        id: `${i18nPrefix}.billDetailsText`,
        defaultMessage: 'Bill details',
    },
    expenseDetailsText: {
        id: `${i18nPrefix}.expenseDetailsText`,
        defaultMessage: 'Expense details',
    },
    salesInvoiceDetailsText: {
        id: `${i18nPrefix}.salesInvoiceDetailsText`,
        defaultMessage: 'Sales invoice details',
    },
    CreditNotesPayableDetailsDetailsText: {
        id: `${i18nPrefix}.creditNotesPayableDetailsDetailsText`,
        defaultMessage: 'AP Credit Note details',
    },
    standaloneWorkflowFieldsText: {
        id: `${i18nPrefix}.standaloneWorkflowFieldsText`,
        defaultMessage: 'Stand-alone details',
    },
    grnStatusFieldText: {
        id: `${i18nPrefix}.grnStatusFieldText`,
        defaultMessage: 'Goods received note status',
    },
});

export enum ColumnGroup {
    LineItemField = 'LineItemField',
    KeyData = 'KeyData',
    Workflow = 'Workflow',
    Compliance = 'Compliance',
    Matching = 'Matching',
    PoDetails = 'PoDetails',
    QuoteDetails = 'QuoteDetails',
    BillDetails = 'BillDetails',
    ExpenseDetails = 'ExpenseDetails',
    SalesInvoiceDetails = 'SalesInvoiceDetails',
    CreditNotesPayableDetails = 'CreditNotesPayableDetails',
    StandaloneWorkflowFields = 'StandaloneWorkflowFields',
    GrnStatus = 'GrnStatus',
}

export interface ColumnGroupItem {
    id: ColumnGroup;
    text: string;
}

export const getColumnGroups = (company: selectors.types.ExpandedCompany): ColumnGroupItem[] => {
    const { isXeroMatchingV1ReadOnly, isXeroMatchingV2ReadOnly, isXeroMatchingV2, isQBooksMatching } = company.flags;

    const columnsGroup = [
        {
            id: ColumnGroup.KeyData,
            text: intl.formatMessage(messages.keyDataText),
        },
        {
            id: ColumnGroup.Workflow,
            text: intl.formatMessage(messages.workflowText),
        },
        {
            id: ColumnGroup.Compliance,
            text: intl.formatMessage(messages.complianceText),
        },
    ];

    if (isXeroMatchingV1ReadOnly || isXeroMatchingV2ReadOnly || isXeroMatchingV2 || isQBooksMatching) {
        columnsGroup.push({
            id: ColumnGroup.Matching,
            text: intl.formatMessage(messages.matchingText),
        });
    }

    columnsGroup.push(
        {
            id: ColumnGroup.PoDetails,
            text: intl.formatMessage(messages.poDetailsText),
        },
        {
            id: ColumnGroup.QuoteDetails,
            text: intl.formatMessage(messages.quoteDetailsText),
        },
        {
            id: ColumnGroup.BillDetails,
            text: intl.formatMessage(messages.billDetailsText),
        },
        {
            id: ColumnGroup.ExpenseDetails,
            text: intl.formatMessage(messages.expenseDetailsText),
        },
        {
            id: ColumnGroup.SalesInvoiceDetails,
            text: intl.formatMessage(messages.salesInvoiceDetailsText),
        },
        {
            id: ColumnGroup.CreditNotesPayableDetails,
            text: intl.formatMessage(messages.CreditNotesPayableDetailsDetailsText),
        },
        {
            id: ColumnGroup.StandaloneWorkflowFields,
            text: intl.formatMessage(messages.standaloneWorkflowFieldsText),
        },
        {
            id: ColumnGroup.LineItemField,
            text: intl.formatMessage(messages.lineItemFieldText),
        },
        {
            id: ColumnGroup.GrnStatus,
            text: intl.formatMessage(messages.grnStatusFieldText),
        }
    );

    return columnsGroup;
};
