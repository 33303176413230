import { Button } from '@approvalmax/ui';
import { actions } from 'modules/common';
import { useTwoFaEnablingWizardContext } from 'modules/components';
import { FC, memo, useCallback, useEffect } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

import {
    changeProfilePopupModified,
    changeTwoFaAlternativeEmailIsEnabled,
    changeTwoFaBackupCodesIsEnabled,
    changeTwoFaIsEnabled,
} from '../../../../actions';
import { useProfileContext } from '../../ProfileContext';
import BackupOptionsSection from '../BackupOptionsSection/BackupOptionsSection';
import BackupOptionsVerification from '../BackupOptionsVerification/BackupOptionsVerification';
import Section from '../Section/Section';
import Toggle from '../Toggle/Toggle';
import TwoFaDisablePopup from '../TwoFaDisablePopup/TwoFaDisablePopup';
import { messages } from './SecuritySection.messages';
import {
    Block2fa,
    Block2faCol,
    Block2faContainer,
    Block2faSubtitle,
    Block2faTitle,
    Block2faToggle,
    Description,
    Name,
    Note,
    Row,
    VerifyTwoFaContainer,
    VerifyTwoFaDescription,
} from './SecuritySection.styles';

const qa = bemFactory.qa('profile-popup-security-section');

const SecuritySection: FC = () => {
    const dispatch = useDispatch();
    const { profile, editProfile, modified } = useProfileContext();
    const [isTwoFaVerificationOpen, toggleIsTwoFaVerificationOpen] = useToggle(false);
    const [isTwoFaVerified, toggleIsTwoFaVerified] = useToggle(false);
    const is2faUnavailable = !profile.isPasswordSet;
    const [isTwoFaDisableOpen, toggleIsTwoFaDisableOpen] = useToggle(false);
    const { showWizard: showTwoFaEnablingWizard } = useTwoFaEnablingWizardContext();

    useEffect(() => {
        if (!profile.is2faEnabled) {
            toggleIsTwoFaVerified(false);
        }
    }, [profile.is2faEnabled, toggleIsTwoFaVerified]);

    const toggle2FAuth = useCallback(
        (proceed: boolean) => {
            if (!proceed) return;

            dispatch(changeProfilePopupModified(false));

            if (profile.is2faEnabled) {
                toggleIsTwoFaDisableOpen();
            } else {
                showTwoFaEnablingWizard((backupOption) => {
                    if (backupOption === 'backupCode') {
                        dispatch(changeTwoFaBackupCodesIsEnabled(true));
                    } else if (backupOption === 'email') {
                        dispatch(changeTwoFaAlternativeEmailIsEnabled(true));
                    }

                    dispatch(changeTwoFaIsEnabled(true, profile.email));
                });
            }
        },
        [dispatch, profile.email, profile.is2faEnabled, showTwoFaEnablingWizard, toggleIsTwoFaDisableOpen]
    );

    const onToggle2FAuth = useCallback(() => {
        if (modified) {
            dispatch(
                actions.showDiscardChangesPopup({
                    unsavedChangesConfirmationCallback: toggle2FAuth,
                })
            );
        } else {
            toggle2FAuth(true);
        }
    }, [dispatch, modified, toggle2FAuth]);

    const changeUseSession = useCallback(() => {
        editProfile('useSession', !profile.useSession);
    }, [editProfile, profile.useSession]);

    const onVerificationSuccess = useCallback(() => {
        toggleIsTwoFaVerificationOpen(false);
        toggleIsTwoFaVerified(true);
    }, [toggleIsTwoFaVerified, toggleIsTwoFaVerificationOpen]);

    return (
        <>
            <Section header={messages.securityHeader}>
                <Row>
                    <Name>{messages.useSessionTitle}</Name>

                    <Note />

                    <Toggle
                        dataQa={qa('use-session-toggle')}
                        dataQaId={profile.useSession}
                        onChange={changeUseSession}
                        on={profile.useSession}
                    />
                </Row>

                <Row noBorder>
                    <Block2faContainer>
                        <Block2fa>
                            <Block2faCol>
                                <Block2faTitle>
                                    <Name>{messages.twoFaTitle}</Name>

                                    <Note>{is2faUnavailable ? messages.twoFaStatusDisabled : ''}</Note>
                                </Block2faTitle>

                                {is2faUnavailable && (
                                    <Block2faSubtitle>
                                        <Description>{messages.twoFaDisabledDescription}</Description>
                                    </Block2faSubtitle>
                                )}
                            </Block2faCol>

                            <Block2faCol width='36px'>
                                <Block2faToggle>
                                    <Toggle
                                        dataQa={qa('2fa-toggle')}
                                        dataQaId={profile.is2faEnabled}
                                        onChange={onToggle2FAuth}
                                        on={profile.is2faEnabled}
                                        disabled={is2faUnavailable}
                                    />
                                </Block2faToggle>
                            </Block2faCol>
                        </Block2fa>

                        {profile.is2faEnabled && !isTwoFaVerified && (
                            <VerifyTwoFaContainer>
                                <VerifyTwoFaDescription>{messages.verifyDescription}</VerifyTwoFaDescription>

                                <Button execute={toggleIsTwoFaVerificationOpen}>{messages.edit}</Button>
                            </VerifyTwoFaContainer>
                        )}

                        {profile.is2faEnabled && isTwoFaVerified && <BackupOptionsSection />}
                    </Block2faContainer>
                </Row>

                {/* TODO: uncomment after release 4.82 merge to dev */}
                {/*
                <Row noBorder>
                    <PasskeysSettings />
                </Row>
                */}
            </Section>

            <TwoFaDisablePopup isOpen={isTwoFaDisableOpen} onClose={toggleIsTwoFaDisableOpen} />

            <BackupOptionsVerification
                isOpen={isTwoFaVerificationOpen}
                onClose={toggleIsTwoFaVerificationOpen}
                onSuccess={onVerificationSuccess}
            />
        </>
    );
};

export default memo(SecuritySection);
