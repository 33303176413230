import { ChevronRightIcon, CloseCircleFilledIcon, HorizontalDragIcon, LockIcon, SortableHandle } from '@approvalmax/ui';
import { Box, Button, Flex, Text, TextField } from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import { FC, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { ConfirmationDropdown } from 'shared/components';

import { getActiveTemplate, showCardValidationErrors } from '../../selectors/pageSelectors';
import { messages } from './DynamicStepContainer.messages';
import { Bubble, RemoveButton, Root, SortableHandleContent } from './DynamicStepContainer.styles';
import { DynamicStepContainerProps } from './DynamicStepContainer.types';

const qa = bemFactory.qa('wort-dynamic-step-container');

const DynamicStepContainer: FC<DynamicStepContainerProps> = memo((props) => {
    const {
        children,
        stepName,
        color = 'brandXero100',
        removable,
        onRemove,
        renameOnMount,
        onRename,
        onMounted,
        readonly,
    } = props;

    const showErrors = useSelector(showCardValidationErrors);
    const template = useSelector(getActiveTemplate);
    const [isRenaming, setIsRenaming] = useState(renameOnMount);
    const [value, setValue] = useState(stepName);

    useEffect(() => {
        if (!stepName) {
            setIsRenaming(true);
        }
    }, [stepName]);

    useEffect(() => {
        if (renameOnMount) {
            onMounted();
        }
    }, [renameOnMount, onMounted]);

    const handleRename = useCallback(() => {
        const newValue = value.trim();

        onRename(newValue || stepName);
        setValue(newValue || stepName);
        setIsRenaming(false);
    }, [onRename, stepName, value]);

    const onBubbliClick = useCallback(() => {
        if (!readonly) {
            setIsRenaming(true);
        }
    }, [readonly]);

    const handleRemove = useCallback(() => {
        amplitudeService.sendData('workflow: add or remove step', {
            workflow: template?.integrationCode?.toLocaleLowerCase() || 'standalone',
            'action type': 'remove',
        });

        onRemove?.();
    }, [onRemove, template?.integrationCode]);

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
                handleRename();
            }
        },
        [handleRename]
    );

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    return (
        <Root data-qa={qa()}>
            <div>
                <SortableHandle>
                    <SortableHandleContent data-qa={qa('drag-handle')} $hide={readonly}>
                        <Button noPadding icon>
                            <HorizontalDragIcon size={16} />
                        </Button>
                    </SortableHandleContent>
                </SortableHandle>

                <Flex inline spacing='12'>
                    {isRenaming ? (
                        <TextField
                            value={value}
                            onChange={setValue}
                            onBlur={handleRename}
                            onKeyDown={onKeyDown}
                            size='small'
                            width='fill-available'
                            initFocus
                            data-qa={qa('bubble-text-input')}
                            clearable={false}
                        />
                    ) : (
                        <Bubble
                            $color={color}
                            onClick={onBubbliClick}
                            $clickable={!readonly}
                            $closable={deleteConfirmationOpen}
                        >
                            <Flex inline spacing='4' justifyContent='space-between' alignItems='center' wrap={false}>
                                <Text font='label' fontSize='small' data-qa={qa('bubble-text')} ellipsis={1}>
                                    {stepName}
                                </Text>

                                {removable && !readonly && (
                                    <ConfirmationDropdown title={messages.deleteStep} onConfirm={handleRemove}>
                                        <RemoveButton
                                            onClick={domHelpers.stopPropagation}
                                            size='small'
                                            icon
                                            noPadding
                                            title={messages.removeStep}
                                            qa={qa('delete-step-button')}
                                        >
                                            <CloseCircleFilledIcon color='white100' size={12} />
                                        </RemoveButton>
                                    </ConfirmationDropdown>
                                )}

                                {!removable && !readonly && <LockIcon size={12} />}
                            </Flex>
                        </Bubble>
                    )}

                    {children}
                </Flex>
            </div>

            <Box spacing='18 4 0 8'>
                <ChevronRightIcon size={16} color='midnight60' />
            </Box>
        </Root>
    );
});

export default DynamicStepContainer;
