import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { createSelector } from 'reselect';

import { MatrixType } from '../types/matrix';
import { getDearMatrixDefinitions } from './matrixDefinitions.dear';
import { getNetSuiteMatrixDefinitions } from './matrixDefinitions.netsuite';
import { getQBooksMatrixDefinitions } from './matrixDefinitions.qbooks';
import { MatrixDefinition } from './matrixDefinitions.shared';
import { getStandaloneMatrixDefinitions } from './matrixDefinitions.standalone';
import { getXeroMatrixDefinitions } from './matrixDefinitions.xero';

interface GetMatrixDefinitionOptions {
    betaFeatures: domain.CompanyBetaFeature[];
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}

export const getMatrixDefinition: (options: GetMatrixDefinitionOptions) => MatrixDefinition = createSelector(
    (options: GetMatrixDefinitionOptions) => options.betaFeatures,
    (options: GetMatrixDefinitionOptions) => options.integrationCode,
    (options: GetMatrixDefinitionOptions) => options.matrixType,
    (betaFeatures, integrationCode, matrixType) => {
        const integrationType = selectors.integration.getIntegrationType(integrationCode);

        switch (integrationType) {
            case domain.IntegrationType.Xero:
                return getXeroMatrixDefinitions({ integrationCode, matrixType });

            case domain.IntegrationType.QBooks:
                return getQBooksMatrixDefinitions({ integrationCode, matrixType });

            case domain.IntegrationType.NetSuite:
                return getNetSuiteMatrixDefinitions({ betaFeatures, integrationCode, matrixType });

            case domain.IntegrationType.Dear:
                return getDearMatrixDefinitions({ betaFeatures, integrationCode, matrixType });

            case domain.IntegrationType.None:
                return getStandaloneMatrixDefinitions({ betaFeatures, integrationCode, matrixType });

            default:
                throw errorHelpers.assertNever(integrationType);
        }
    }
);
