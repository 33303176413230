import { Guid } from '@approvalmax/types';
import { backend } from 'modules/data';
import { stringify } from 'query-string';

import { ApiHandler } from '../types';

class RequestsUrls {
    constructor(private _apiHandler: ApiHandler) {}

    public get(getTransfer: backend.transfers.RequestGetTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/get?${stringify(getTransfer)}`;
    }

    public getEditingContext(transfer: backend.transfers.RequestGetTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/getEditingContext?${stringify(transfer)}`;
    }

    public selectAll(selectTransfer: backend.transfers.RequestSelectAllTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/selectAll?${stringify(selectTransfer)}`;
    }

    public selectForTemplateReset(selectTransfer: backend.transfers.RequestSelectForTemplateResetTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/selectForTemplateReset?${stringify(selectTransfer)}`;
    }

    public getAttachment(transfer: backend.transfers.RequestGetAttachmentTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/getAttachment?${stringify(transfer)}`;
    }

    public getAttachmentForAmaxPayBatch({
        attachmentId,
        companyId,
        batchPaymentRequestId,
        billRequestId,
    }: backend.transfers.RequestGetAttachmentForBatchPaymentTransfer) {
        return `${this._apiHandler.getApiUrlPrefix({ apiVersion: 'v2' })}companies/${companyId}/requests/${batchPaymentRequestId}/amaxPay/xero/bills/${billRequestId}/attachments/${attachmentId}`;
    }

    public getAttachmentForAirwallexBatch({
        attachmentId,
        companyId,
        batchPaymentRequestId,
        billRequestId,
    }: backend.transfers.RequestGetAttachmentForBatchPaymentTransfer) {
        return `${this._apiHandler.getApiUrlPrefix({ apiVersion: 'v2' })}companies/${companyId}/requests/${batchPaymentRequestId}/airwallex/xero/bills/${billRequestId}/attachments/${attachmentId}`;
    }

    public getAttachmentForXeroBatch({
        attachmentId,
        companyId,
        batchPaymentRequestId,
        billRequestId,
    }: backend.transfers.RequestGetAttachmentForBatchPaymentTransfer) {
        return `${this._apiHandler.getApiUrlPrefix({ apiVersion: 'v2' })}companies/${companyId}/requests/${batchPaymentRequestId}/xero/bills/${billRequestId}/attachments/${attachmentId}`;
    }

    public getCommentAttachment(transfer: backend.transfers.CommentGetAttachmentTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/getCommentAttachment?${stringify(transfer)}`;
    }

    public getComments(commentTransfer: backend.transfers.RequestGetCommentsTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/getComments?${stringify(commentTransfer)}`;
    }

    public getAuditReportDetails(transfer: backend.transfers.RequestAuditReportTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/getAuditReportDetails?${stringify(transfer)}`;
    }

    public getAuditReport(requestId: Guid, inline: boolean | undefined, companyId: Guid): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/getAuditReport?${stringify({
            requestId,
            inline,
            companyId,
        })}`;
    }

    public selectMatchingRequests(transfer: backend.transfers.SelectMatchingRequestsTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requests/selectMatchingRequests?${stringify(transfer)}`;
    }
}

export default class RequestsApi {
    public urls: RequestsUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new RequestsUrls(this._apiHandler);
    }

    public create(transfer: backend.transfers.RequestCreateTransfer): Promise<backend.RequestCreateAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/create',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public copy(transfer: backend.transfers.RequestCopyTransfer): Promise<backend.RequestCopyAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/copy',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public get(getTransfer: backend.transfers.RequestGetTransfer): Promise<backend.RequestSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: getTransfer,
            action: 'requests/get',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public originDeepLink(
        transfer: backend.transfers.RequestGetTransfer
    ): Promise<backend.RequestOriginDeepLinkAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/originDeepLink',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getRequestDetails(
        transfer: backend.transfers.RequestGetTransfer
    ): Promise<backend.RequestGetRequestDetailsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/getRequestDetails',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getEditingContext(
        transfer: backend.transfers.RequestGetTransfer
    ): Promise<backend.Answer<backend.RequestsRequestEditingContext>> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/getEditingContext',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public selectV2(selectTransfer: backend.transfers.RequestSelectV2Transfer): Promise<backend.RequestSelectV2Answer> {
        return this._apiHandler.doApiCall({
            data: selectTransfer,
            action: 'companies/requests',
            method: 'GET',
            allowsAnonymous: false,
            apiVersion: 'v2',
        });
    }

    public companySelectV2({
        companyId,
        ...selectTransfer
    }: backend.transfers.CompanyRequestSelectV2Transfer): Promise<backend.RequestSelectV2Answer> {
        return this._apiHandler.doApiCall({
            data: selectTransfer,
            action: `companies/${companyId}/requests`,
            method: 'GET',
            allowsAnonymous: false,
            apiVersion: 'v2',
        });
    }

    public selectAll(selectTransfer: backend.transfers.RequestSelectAllTransfer): Promise<backend.RequestSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: selectTransfer,
            action: 'requests/selectAll',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public selectByWorkflow(selectTransfer: {
        workflowId: string | null;
        companyId: string | null;
        filter: backend.transfers.SelectByWorkflowFilter;
        startFrom: number;
        rowNum: number;
    }): Promise<backend.RequestSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: {
                ...selectTransfer,
                workflowVersionId: selectTransfer.workflowId,
            },
            action: 'requests/selectByWorkflow',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public selectForTemplateReset(
        selectTransfer: backend.transfers.RequestSelectForTemplateResetTransfer
    ): Promise<backend.RequestSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: selectTransfer,
            action: 'requests/selectForTemplateReset',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public selectQuantityForTemplateReset(
        selectTransfer: backend.transfers.SelectQuantityForTemplateResetTransfer
    ): Promise<backend.RequestSelectQuantityForTemplateResetAnswer> {
        return this._apiHandler.doApiCall({
            data: selectTransfer,
            action: 'requests/selectQuantityForTemplateReset',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public edit(transfer: backend.transfers.RequestEditTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/edit',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public publish(transfer: backend.transfers.RequestChangeStatusTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/publish',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public respond(transfer: backend.transfers.RequestRespondTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/respond',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public completeReview(transfer: backend.transfers.RequestComleteReviewTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/completeReview',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public returnToReview(transfer: backend.transfers.RequestReturnToReviewTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/returnToReview',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public forceDecision(forceTransfer: backend.transfers.RequestForceDecisionTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: forceTransfer,
            action: 'requests/forceDecision',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public markAsBilled(transfer: backend.transfers.RequestsMarkAsBilledTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/markAsBilled',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public delete(deleteTransfer: backend.transfers.RequestDeleteTransfer): Promise<backend.RequestDeleteAnswer> {
        return this._apiHandler.doApiCall({
            data: deleteTransfer,
            action: 'requests/delete',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public close(closeTransfer: backend.transfers.RequestChangeStatusTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: closeTransfer,
            action: 'requests/close',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public cancel(cancelTransfer: backend.transfers.RequestChangeStatusTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: cancelTransfer,
            action: 'requests/cancel',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public attachFile(): Promise<backend.AttachmentListAnswer> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'requests/attachFile',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public attachFileToComment(): Promise<backend.AttachmentListAnswer> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'requests/attachFileToComment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public deleteAttachment(
        requestTransfer: backend.transfers.RequestDeleteAttachmentTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: requestTransfer,
            action: 'requests/deleteAttachment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public deleteCommentAttachment(
        transfer: backend.transfers.CommentDeleteAttachmentTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/deleteCommentAttachment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getAttachment(transfer: backend.transfers.RequestGetAttachmentTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/getAttachment',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getCommentAttachment(transfer: backend.transfers.CommentGetAttachmentTransfer): Promise<any> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/getCommentAttachment',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public addComment(
        commentTransfer: backend.transfers.RequestAddCommentTransfer
    ): Promise<backend.RequestAddCommentAnswer> {
        return this._apiHandler.doApiCall({
            data: commentTransfer,
            action: 'requests/addComment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public deleteComment(
        commentTransfer: backend.transfers.RequestDeleteCommentTransfer
    ): Promise<backend.RequestDeleteCommentAnswer> {
        return this._apiHandler.doApiCall({
            data: commentTransfer,
            action: 'requests/deleteComment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getComments(
        commentTransfer: backend.transfers.RequestGetCommentsTransfer
    ): Promise<backend.RequestGetCommentsAnswer> {
        return this._apiHandler.doApiCall({
            data: commentTransfer,
            action: 'requests/getComments',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public history(historyTransfer: backend.transfers.RequestHistoryTransfer): Promise<backend.RequestHistoryAnswer> {
        return this._apiHandler.doApiCall({
            data: historyTransfer,
            action: 'requests/history',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public resetTemplate(resetTransfer: backend.transfers.RequestResetTemplateTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: resetTransfer,
            action: 'requests/resetTemplate',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public resetAllForTemplate(
        resetTransfer: backend.transfers.RequestResetAllForTemplateTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: resetTransfer,
            action: 'requests/resetAllForTemplate',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public changeParticipants(
        changeTransfer: backend.transfers.RequestChangeParticipantsTransfer
    ): Promise<backend.RequestChangeParticipantsAsnwer> {
        return this._apiHandler.doApiCall({
            data: changeTransfer,
            action: 'requests/changeParticipants',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getAuditReportDetails(
        transfer: backend.transfers.RequestAuditReportTransfer
    ): Promise<backend.RequestAuditReportDetailsAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/getAuditReportDetails',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public getAuditReport(requestId: Guid, inline: boolean | undefined): Promise<any> {
        return this._apiHandler.doApiCall({
            data: {
                requestId,
                inline,
            },
            action: 'requests/getAuditReport',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public startOverRequests(transfer: backend.transfers.SyncRequestsTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/startOverRequests',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public selectMatchingRequests(
        transfer: backend.transfers.SelectMatchingRequestsTransfer
    ): Promise<backend.RequestSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/selectMatchingRequests',
            method: 'GET',
            allowsAnonymous: false,
        });
    }

    public matchRequests(
        transfer: backend.transfers.MatchedRequestsTransfer
    ): Promise<backend.MatchingAgreementMatchAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/matchRequests',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public unmatchRequests(
        transfer: backend.transfers.UnmatchedRequestsTransfer
    ): Promise<backend.MatchingAgreementMatchAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/unmatchRequests',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public syncRequests(transfer: backend.transfers.SyncRequestsTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/syncRequests',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getBillRequestsForBatchPayment(
        transfer: backend.transfers.BillRequestsForBatchPaymentTransfer
    ): Promise<backend.BillRequestsForBatchPaymentAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/xero/selectBillRequestsForBatchPayment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getBillRequestsForAirwallexBatchPayment(
        transfer: backend.transfers.BillRequestsForAirwallexBatchPaymentTransfer
    ): Promise<backend.BillRequestsForAirwallexBatchPaymentAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/airwallex/xero/selectBillRequestsForBatchPayment',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    // TODO:AmaxPay replace with new style api calls
    public getBillRequestsForAmaxPayBatchPayment(
        transfer: backend.transfers.BillRequestsForAmaxPayBatchPaymentTransfer,
        pathParams: { companyId: string; requestId: string }
    ): Promise<backend.BillRequestsForAmaxPayBatchPaymentAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: `requests/companies/${pathParams.companyId}/amaxPay/xero/batchPayments/${pathParams.requestId}/bills`,
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public editQBODocumentStatus(
        transfer: backend.transfers.EditQBODocumentStatusTransfer
    ): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'requests/editDocumentStatus',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public pay(payTransfer: backend.transfers.RequestPayTransfer): Promise<backend.RequestPayAnswer> {
        return this._apiHandler.doApiCall({
            data: payTransfer,
            action: 'requests/pay',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public getCurrentBalances(
        payTransfer: backend.transfers.GetRequestCurrentBalanceTransfer
    ): Promise<backend.RequestCurrentBalanceAsnwer> {
        return this._apiHandler.doApiCall({
            data: payTransfer,
            action: 'requests/getCurrentBalances',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public changeWatchers(
        changeWatchersTransfer: backend.transfers.ChangeWatchersTransfer
    ): Promise<backend.RequestCurrentBalanceAsnwer> {
        return this._apiHandler.doApiCall({
            data: changeWatchersTransfer,
            action: 'requests/changeWatchers',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
