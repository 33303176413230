import { useEffect, useState } from 'react';

const experimentOnValue = 'on';
const experimentOffValue = 'off';

export const useIsLocaleExperimentOn = (experimentName: string) => {
    const [isExperimentOn, setIsExperimentOn] = useState(false);

    useEffect(() => {
        const experimentValue = localStorage.getItem(experimentName);

        if (experimentValue) {
            setIsExperimentOn(experimentValue === experimentOnValue);
        } else {
            const newExperimentValue = Math.random() > 0.5 ? experimentOnValue : experimentOffValue;

            localStorage.setItem(experimentName, newExperimentValue);

            setIsExperimentOn(newExperimentValue === experimentOnValue);
        }
    }, [experimentName]);

    return isExperimentOn;
};
