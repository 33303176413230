import type { OnClickProp } from '@approvalmax/types';
import Button from '@approvalmax/ui/src/components/Button/Button';
import Progress from '@approvalmax/ui/src/components/Progress/Progress';
import { SelectOnChange } from '@approvalmax/ui/src/components/Select/Select.types';
import { Text } from '@approvalmax/ui/src/components/Text/Text';
import TextField from '@approvalmax/ui/src/components/TextField/TextField';
import { hooks } from '@approvalmax/utils';
import React, { ComponentProps, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMount, useUpdateEffect } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { HTMLTextFieldElement } from '../../../TextField/TextField.types';
import { useDropdownOpen } from '../DropdownMenu/DropdownMenu.hooks';
import { inputValuePristineState, selectInputFocusState, selectInputValueState } from './Activator.states';
import { Icon } from './Activator.styles';
import { ActivatorProps } from './Activator.types';

/**
 * Activator allows opening the DropdownMenu and selecting items.
 * It Also allows searching items if autocomplete is enabled.
 */
const Activator = memo(
    <Item extends Record<string, any>, Multiple extends boolean>(props: ActivatorProps<Item, Multiple>) => {
        const {
            multiple,
            itemNameKey,
            itemIdKey,
            initFocus = false,
            autocomplete,
            placeholder,
            onInputChange,
            onFocus,
            onBlur,
            progress,
            noInput,
            size,
            disabled,
            invalid,
            color,
            uppercase = false,
            readOnly,
            clearable: clearableProp = true,
            bordered,
            openIconOnHover,
            hoverColor,
            selectedItems,
            onChangeSelect,
            items,
            activatorRef,
            startIcon,
            hideEndIcon,
            open,
            onOpen,
            initOpen,
            buttonColor,
            noPadding = true,
            outline,
            selectOnFocus,
            tabIndex,
            ...restProps
        } = props;

        const [inputValue, setInputValue] = useRecoilState(selectInputValueState);
        const [dropdownOpen, setDropdownOpen] = useDropdownOpen({ open, onOpen, initOpen });
        const [inputFocus, setInputFocus] = useRecoilState(selectInputFocusState);
        const setInputValuePristine = useSetRecoilState(inputValuePristineState);
        const inputRef = useRef<HTMLInputElement>(null);
        const inputComposedRefs = hooks.useComposedRefs(activatorRef, inputRef);

        useMount(() => {
            if (initFocus) {
                setDropdownOpen(true);
            }
        });

        /**
         * If Select is not multiple, then we add selected item to the input value.
         */
        useEffect(() => {
            if (!multiple && selectedItems.length) {
                setInputValue(selectedItems[0][itemNameKey]);
            }
        }, [itemNameKey, multiple, selectedItems, setInputValue]);

        /**
         * If Dropdown is open, then we set focus to the input.
         */
        useUpdateEffect(() => {
            setInputFocus(Boolean(dropdownOpen));
        }, [dropdownOpen, setInputFocus]);

        /**
         * Select all input content on Dropdown open, when prop `selectOnFocus` is true
         */
        useEffect(() => {
            if (dropdownOpen) {
                // mark input as a pristine to avoid further dropdown list filtration
                setInputValuePristine(true);

                if (selectOnFocus && autocomplete) {
                    inputRef.current?.select();
                }
            }
        }, [autocomplete, dropdownOpen, selectOnFocus, setInputValuePristine]);

        /**
         * Clear input value if a dropdown is closed and nothing is selected
         * or if a dropdown is closed and Select is multiple.
         */
        useEffect(() => {
            if ((!dropdownOpen && !selectedItems.length) || (!dropdownOpen && multiple)) {
                setInputValue('');
            }
        }, [dropdownOpen, multiple, selectedItems.length, setInputValue]);

        /**
         * Handle clear input and remove selected item if Select is not multiple.
         */
        const handleClear = useCallback<Required<ComponentProps<typeof TextField>>['onClear']>(() => {
            if (!multiple) {
                onChangeSelect?.(undefined as Parameters<SelectOnChange<Item, Multiple>>[0], items);
            }
        }, [items, multiple, onChangeSelect]);

        /**
         * Handle input change and set inputValue.
         */
        const handleChange = useCallback(
            (value = '') => {
                setInputValuePristine(false);
                setInputValue(value);
                onInputChange && onInputChange(value);
            },
            [onInputChange, setInputValue, setInputValuePristine]
        );

        /**
         * Handle input focus and open dropdown if autocomplete is true.
         */
        const handleFocus = useCallback<Required<ComponentProps<typeof TextField>>['onFocus']>(
            (event) => {
                if (readOnly || !autocomplete) return;

                setDropdownOpen(true);

                onFocus?.(event);
            },
            [autocomplete, onFocus, readOnly, setDropdownOpen]
        );

        /**
         * Handle input blur and blur input only if dropdown is closed.
         */
        const handleBlur = useCallback<
            Required<ComponentProps<typeof TextField>>['onBlur'] | Required<ComponentProps<typeof Button>>['onBlur']
        >(
            (event: React.FocusEvent<HTMLTextFieldElement>) => {
                if (!dropdownOpen) {
                    setInputFocus(false);
                    onBlur?.(event);
                }
            },
            [dropdownOpen, onBlur, setInputFocus]
        );

        /**
         * Handle click on input and open dropdown if autocomplete is false.
         */
        const handleClick = useCallback<
            Required<ComponentProps<typeof TextField>>['onClick'] & Required<ComponentProps<typeof Button>>['onClick']
        >(
            (event) => {
                if (readOnly || disabled) return;

                if (autocomplete) {
                    if (
                        (event.target instanceof HTMLElement || event.target instanceof SVGElement) &&
                        event.target.closest('button')
                    ) {
                        setInputFocus(true);
                    }
                } else {
                    setDropdownOpen(!dropdownOpen);
                }
            },
            [autocomplete, disabled, dropdownOpen, readOnly, setDropdownOpen, setInputFocus]
        );

        /**
         * Handle hover on input and set local state
         */
        const [hover, setHover] = useState(false);
        const handleHover = useCallback(() => setHover(true), []);
        const handleLeave = useCallback(() => setHover(false), []);

        const handleClose = useCallback<NonNullable<OnClickProp['onClick']>>(
            (event) => {
                event.stopPropagation();
                setDropdownOpen(!dropdownOpen);
            },
            [dropdownOpen, setDropdownOpen]
        );

        /**
         * Input clearable if not in progress and autocomplete is true
         * or if a single Select has a value and is hovered.
         */
        const clearable = Boolean(
            clearableProp && !progress && (autocomplete || (!multiple && selectedItems.length && hover))
        );

        const endIconSize = size === 'xsmall' ? 12 : 16;

        /**
         * Input end icon if progress is true or if Select is not clearable.
         */
        const endIcon = useMemo(() => {
            if (progress) {
                return <Progress size='small' shape='circle' />;
            }

            if ((openIconOnHover && !hover) || hideEndIcon) {
                return undefined;
            }

            if (!clearable && !noInput && !readOnly) {
                return (
                    <Button icon noPadding disabled={disabled} onClick={handleClose}>
                        <Icon $open={dropdownOpen} size={endIconSize} />
                    </Button>
                );
            } else if (noInput && !readOnly) {
                return (
                    <Button
                        icon
                        noPadding
                        color={invalid ? 'red40' : buttonColor}
                        disabled={disabled}
                        onClick={handleClose}
                    >
                        <Icon $open={dropdownOpen} color={hover ? hoverColor : undefined} size={endIconSize} />
                    </Button>
                );
            }

            return;
        }, [
            progress,
            openIconOnHover,
            handleClose,
            hover,
            hideEndIcon,
            clearable,
            noInput,
            readOnly,
            disabled,
            dropdownOpen,
            endIconSize,
            invalid,
            buttonColor,
            hoverColor,
        ]);

        if (!noInput) {
            return (
                <TextField
                    readOnly={readOnly || !autocomplete}
                    value={inputValue}
                    onChange={handleChange}
                    focus={inputFocus}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClick={handleClick}
                    onClear={handleClear}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    clearable={clearable}
                    endIcon={endIcon}
                    placeholder={placeholder}
                    size={size}
                    disabled={disabled}
                    invalid={invalid}
                    bordered={bordered}
                    aria-autocomplete={autocomplete ? 'list' : undefined}
                    cursor={readOnly || disabled || autocomplete ? undefined : 'pointer'}
                    ref={inputComposedRefs}
                    tabIndex={tabIndex}
                    startIcon={startIcon}
                    {...restProps}
                />
            );
        } else {
            return (
                <Button
                    onClick={handleClick}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    endIcon={endIcon}
                    size={size}
                    disabled={disabled}
                    uppercase={uppercase}
                    fontWeight='regular'
                    onBlur={handleBlur}
                    noPadding={noPadding}
                    as='div'
                    tabIndex={tabIndex}
                    ref={activatorRef}
                    startIcon={startIcon}
                    outline={outline}
                    color={invalid ? 'red40' : buttonColor}
                >
                    <Text {...restProps} ellipsis={1} color={color} hoverColor={hoverColor}>
                        {inputValue || placeholder}
                    </Text>
                </Button>
            );
        }
    }
);

export default Activator;
