import { Flex } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { Content, Root } from './Item.styles';
import { ItemProps } from './Item.types';

/* Item component */
const Item: FC<ItemProps> = memo((props) => {
    const { spacing = '0', startIcon, children, divider, ...restProps } = props;

    const spacingArr = spacing.split(' ');
    const spacingSizes = spacingArr.length === 1 ? [spacingArr[0], spacingArr[0]] : spacingArr;

    return (
        <Root $spacing={spacingSizes} $divider={divider} {...restProps}>
            {startIcon ? (
                <Flex inline wrap={false} spacing='0 8'>
                    {startIcon}

                    <Content>{children}</Content>
                </Flex>
            ) : (
                children
            )}
        </Root>
    );
});

export default Item;
