import { CREATE_COMPANY_RESPONSE } from 'modules/company/actions';
import { COMPLETE_INTEGRATION_AUTH, COMPLETE_INTEGRATION_AUTH_FAILURE } from 'modules/integration/actions';
import { CANCEL_ACTIVE_POPUP } from 'modules/integration/actions/common';
import { LOAD_PAGE_DATA as WORKFLOW_LOAD_PAGE_DATA } from 'pages/workflows/actions';
import { all, call, race, take } from 'redux-saga/effects';

import { INIT_TUTORIAL } from './actions';
import { chameleonService } from './index';

function* showWorkflowTutorial() {
    const { comebackAfterIntegration } = yield race({
        moveToWorkflow: take(WORKFLOW_LOAD_PAGE_DATA),
        comebackAfterIntegration: take(COMPLETE_INTEGRATION_AUTH),
    });

    if (comebackAfterIntegration) {
        yield race({
            // hide happy connection popup
            moveToWorkflow: take(CANCEL_ACTIVE_POPUP),
            // when we can't complete integration and show workflow for standalone
            comebackAfterIntegration: call(function* () {
                yield take(COMPLETE_INTEGRATION_AUTH_FAILURE);
            }),
        });
    }

    window.chameleon.show_workflow_first_start_tutorial();
}

function* reInitTutorial() {
    yield take(CREATE_COMPANY_RESPONSE);

    const flags = window.ApprovalMax.flags;

    if (flags.useExternalTutorials) {
        chameleonService.init();
    }
}

export function* chameleonSagas() {
    if (!window.chameleon) {
        yield race({
            initialization: take(INIT_TUTORIAL),
            initializationAfterCompanyCreation: call(reInitTutorial),
        });
    }

    if (!window.chameleon) {
        return;
    }

    yield all([call(showWorkflowTutorial)]);
}
