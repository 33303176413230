import { AddIcon } from '@approvalmax/ui';
import { Button, Dropdown, Menu } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';

import { SingletonStepsTypes } from '../../SingletonSteps.types';
import { messages } from './ActionSelector.messages';
import { ActionSelectorProps } from './ActionSelector.types';

export const ActionSelector: FC<ActionSelectorProps> = memo((props) => {
    const { setActivationView, canAddReviewStep, canAddAutoApprovalStep } = props;

    const actionItems = useMemo(() => {
        const result = [];

        if (canAddAutoApprovalStep) {
            result.push({
                name: messages.addAutoApprovalStep,
                onClick: () => setActivationView(SingletonStepsTypes.AutoApprovalStep),
            });
        }

        if (canAddReviewStep) {
            result.push({
                name: messages.addReviewStep,
                onClick: () => setActivationView(SingletonStepsTypes.ReviewStep),
            });
        }

        return result;
    }, [canAddAutoApprovalStep, canAddReviewStep, setActivationView]);

    if (actionItems.length === 0) {
        return null;
    }

    return (
        <div>
            <Dropdown
                closeOnClickInnerButton
                activator={
                    <Button size='small' color='midnight40' data-starter-guide='wf_set_up_autoapproval'>
                        <AddIcon size={12} />
                    </Button>
                }
                width='auto'
            >
                <Menu items={actionItems} size='small' />
            </Dropdown>
        </div>
    );
});

ActionSelector.displayName = 'ActionSelector';
