import { DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useCallback } from 'react';

import { BaseItem, TableDraggableItem, TableProps } from './Table.types';

export const useTableReorder = <Item extends BaseItem>(
    params: Pick<TableProps<Item>, 'reorderable' | 'columns' | 'data' | 'onReorderColumns' | 'onReorderRows'>
) => {
    const { reorderable, columns, data, onReorderColumns, onReorderRows } = params;

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
    );

    const handleReordered = useCallback(
        (event: DragEndEvent) => {
            if (!reorderable) {
                return;
            }

            const { active, over } = event;

            const activeData = active.data.current as TableDraggableItem<Item>;

            switch (activeData.type) {
                case 'column':
                    if (!onReorderColumns) {
                        break;
                    }

                    if (over && active.id !== over?.id) {
                        const oldIndex = columns.findIndex((col) => col.id === active.id);
                        const newIndex = columns.findIndex((col) => col.id === over.id);

                        onReorderColumns(arrayMove(columns, oldIndex, newIndex));
                    }

                    break;
                case 'row':
                    if (!onReorderRows) {
                        break;
                    }

                    if (over && active.id !== over?.id) {
                        const oldIndex = data.findIndex((col) => col.id === active.id);
                        const newIndex = data.findIndex((col) => col.id === over.id);

                        onReorderRows(arrayMove(data, oldIndex, newIndex));
                    }

                    break;
            }
        },
        [columns, data, onReorderColumns, onReorderRows, reorderable]
    );

    return {
        sensors,
        handleReordered,
    };
};
