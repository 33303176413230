import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.CopyWorkflowPopup.WarningList', {
    warningTitle: 'Warnings',
    noWarnings: 'No warnings detected',
    applicableWorkflowSettingsCopyWarning:
        'Only applicable workflow settings available in both workflow types will be copied',
    QBooksDifferentValues:
        'If values are different in the target organisation (e.g. Vendor, Product/Service, Category), they will be ignored',
    QBooksDifferentClassesAndLocations:
        'If the Classes and Locations are different in the target organisation, the copy will not work',
    xeroDextRequesterWarning: 'Dext requester won’t be copied',
    xeroNoDefaultSubmitterWarning:
        'If the target workflow has no default submitter – same submitter as in source workflow will be set as “Pull from Xero” Requester',
    xeroReviewerStepWarning: 'Reviewer step won’t be copied',
    xeroCreateBillRequestersWarning: '"Create a Bill in ApprovalMax" Requesters won\'t be copied',
    xeroMatrixRulesWarning: "Requester rules in approval matrix won't be copied",
    differentValuesInTargetOrgWarning:
        'If values are different in the target organisation (e.g. Account codes, Tracking Category values, Contacts, Branding, Taxes, Items), they will be ignored.',
    xeroCreatePORequestersWarning: '“Create a PO in ApprovalMax” Requesters won’t be copied',
    xeroNoCreateBillRequestersWarning:
        'If the target workflow has "Create a Bill in ApprovalMax" Requesters – they will be removed as the source workflow does not have such Requesters',
    xeroNoCreatePORequestersWarning:
        'If the target workflow has "Create a PO in ApprovalMax" Requesters – they will be removed as the source workflow does not have such Requesters',
    trackingCategoryNameWarning:
        'If the Tracking Category Names (title) are different in the target organisation, the copy will not work.',
});
