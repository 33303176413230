import { ExtractComponentProp } from '@approvalmax/types';
import { Box, Button, Flex, Label, Select, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import { bulkCreationPopupCompanyIdState } from '../BulkCreationPopupContent.states';
import { messages } from './AdditionalOptions.messages';
import { AdditionalOptionsProps } from './AdditionalOptions.types';

export const AdditionalOptions: FC<AdditionalOptionsProps> = memo((props) => {
    const { editMode, setEditMode, disabled } = props;

    const [bulkCreationPopupCompanyId, setBulkCreationPopupCompanyId] = useRecoilState(bulkCreationPopupCompanyIdState);

    const [selectedCompanyId, setSelectedCompanyId] = useState(bulkCreationPopupCompanyId);
    const activeCompany = useSelector((state) => selectors.company.getCompanyById(state, bulkCreationPopupCompanyId));
    const companies = useSelector(selectors.company.getOcrAvailableCompanies);

    const onEdit = useCallback(() => {
        setEditMode(true);
        setSelectedCompanyId(bulkCreationPopupCompanyId);
    }, [bulkCreationPopupCompanyId, setEditMode]);

    const onCancel = useCallback(() => {
        setEditMode(false);
    }, [setEditMode]);

    const onSave = useCallback(() => {
        setBulkCreationPopupCompanyId(selectedCompanyId);
        setEditMode(false);
    }, [selectedCompanyId, setBulkCreationPopupCompanyId, setEditMode]);
    const onChangeCompany = useCallback<ExtractComponentProp<typeof Select, 'onChange'>>((companyId) => {
        setSelectedCompanyId(String(companyId));
    }, []);

    return (
        <Flex inline spacing='16' size={10} height='fit-content'>
            <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                {messages.additionalOptionsTitle}
            </Text>

            <Box color='midnight20' spacing='24' radius='xsmall' width='100%'>
                {editMode ? (
                    <Box color='white100' width='100%' spacing='12'>
                        <Flex inline direction='column'>
                            <div>
                                <Label required>{messages.companySelectTitle}</Label>

                                <Select
                                    clearable={false}
                                    itemNameKey='displayName'
                                    onChange={onChangeCompany}
                                    items={companies}
                                    value={selectedCompanyId}
                                />
                            </div>

                            <Flex inline justifyContent='end' spacing='8'>
                                <Button size='small' color='midnight40' onClick={onCancel}>
                                    {messages.cancelButtonTitle}
                                </Button>

                                <Button size='small' color='blue80' onClick={onSave}>
                                    {messages.saveButtonTitle}
                                </Button>
                            </Flex>
                        </Flex>
                    </Box>
                ) : (
                    <Flex inline spacing='8' width='100%' justifyContent='space-between'>
                        <Flex direction='column' spacing='8'>
                            <Text font='body' fontSize='medium' fontWeight='medium'>
                                {messages.integrationTitle}
                            </Text>

                            <Text font='body'>{`"${activeCompany?.displayName}"`}</Text>
                        </Flex>

                        <Button disabled={disabled} onClick={onEdit} color='blue80' outline>
                            {messages.editButtonTitle}
                        </Button>
                    </Flex>
                )}
            </Box>
        </Flex>
    );
});

AdditionalOptions.displayName = 'AdditionalOptions';
