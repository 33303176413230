import { objectHelpers, routerHelpers } from '@approvalmax/utils';
import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { Env } from '../configs';
import { getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import rootApiLegacy from '../rootApiLegacy';
import {
    ApiError,
    ApiMethods,
    ApiSource,
    ApiVersion,
    GetNextPageParam,
    InfiniteQueryOptions,
    ParamsLegacy,
} from '../types';

export const createUseInfiniteGetRequestLegacy = (defaultApiSource: ApiSource, defaultEnv: Env) => {
    return <
        ResponseData extends Record<string, any>,
        MappedResponseData extends Record<string, any> = ResponseData,
        PathParams extends Record<string, string> = Record<string, string>,
    >(
        path: string,
        data: Record<string, any>,
        options: {
            pathParams?: PathParams;
            mapData?: (data: ResponseData) => MappedResponseData;
            apiSource?: ApiSource;
            apiVersion?: ApiVersion;
            params?: ParamsLegacy;
            method?: ApiMethods;
            getNextPageParam: GetNextPageParam<ResponseData, MappedResponseData>;
            queryOptions?: InfiniteQueryOptions<ResponseData, MappedResponseData>;
        }
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            params,
            method = 'post',
            queryOptions,
            mapData,
            pathParams,
            getNextPageParam,
        } = options;

        const apiUrl = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, pathParams) ? routerHelpers.pathToUrl(path, pathParams) : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall(mapData);

        const queryClient = useQueryClient();

        const queryKey = [url, data, params, pathParams].filter(Boolean);

        const queryResult = useInfiniteQuery<MappedResponseData, ApiError>(
            queryKey,
            ({ pageParam }) =>
                handleApiCall(
                    rootApiLegacy({
                        baseURL: apiUrl,
                        method,
                        url,
                        headers: {
                            ...(Authorization && { Authorization }),
                        },
                        params: objectHelpers.has(pageParam, 'params') ? pageParam.params : params,
                        data: objectHelpers.has(pageParam, 'data') ? pageParam.data : data,
                    })
                ),
            {
                enabled: Boolean(url),
                ...queryOptions,
                getNextPageParam,
                meta: {
                    meta: queryOptions?.meta,
                    data,
                    path,
                    params,
                    pathParams,
                },
            }
        );

        const updateCachedData = useCallback(
            (getNewCachedData: (currentData: InfiniteData<MappedResponseData>) => InfiniteData<MappedResponseData>) => {
                queryClient.setQueryData(queryKey, (currentData: InfiniteData<MappedResponseData>) => {
                    return getNewCachedData(currentData);
                });
            },
            [queryClient, queryKey]
        );

        return {
            ...queryResult,
            queryKey,
            updateCachedData,
        };
    };
};
