import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { ChevronRightIcon } from '@approvalmax/ui/src/icons';
import { domain } from 'modules/data';
import { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { messages } from './GoToRequestBar.messages';
import { GoToRequestBarProps } from './GoToRequestBar.types';

const GoToRequestBar = memo<GoToRequestBarProps>((props) => {
    const { attachmentRequestName, activeRequest } = props;

    const [contentSplitView, setContentSplitView] = useRecoilState(contentSplitViewState);

    const openAttachmentRequestInSplitView = useCallback(() => {
        if (contentSplitView.mode === ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment) {
            setContentSplitView({
                mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                billId: contentSplitView.billId,
            });
        }
    }, [contentSplitView, setContentSplitView]);

    if (
        !attachmentRequestName ||
        !activeRequest ||
        !activeRequest.integrationCode ||
        ![
            domain.IntegrationCode.XeroAmaxPayBatchPayment,
            domain.IntegrationCode.XeroAirwallexBatchPayment,
            domain.IntegrationCode.XeroBillBatchPayment,
        ].includes(activeRequest.integrationCode)
    ) {
        return null;
    }

    return (
        <Flex inline direction='column' spacing='0'>
            <Box spacing='12 24' color='black100'>
                <Flex inline alignItems='center'>
                    <Button noPadding color='transparent' onClick={openAttachmentRequestInSplitView}>
                        <ChevronRightIcon size={28} color='white100' />
                    </Button>

                    <Text font='body'>{messages.goTo({ requestName: attachmentRequestName })}</Text>
                </Flex>
            </Box>

            <Box color='cobalt60' height={1} />
        </Flex>
    );
});

export default GoToRequestBar;
