import { statics } from 'modules/common';
import moment, { Moment } from 'moment';

/**
 * @returns True if dates are not equal
 */
export const compareDates = (
    newDate: Moment | string | undefined | null,
    originalDate: Moment | string | undefined | null
) => {
    return Boolean(
        originalDate ? (newDate && moment(newDate).toISOString()) !== moment(originalDate).toISOString() : newDate
    );
};

export const getTodayAtTimeZone = (timeZone: Parameters<typeof statics.timeZone.findTimeZoneById>[0]) => {
    const tz = statics.timeZone.findTimeZoneById(timeZone);

    const offset = statics.timeZone.getOffsetShift(
        statics.timeZone.getUtcOffset(tz),
        statics.timeZone.getLocalUtcOffset()
    );

    return statics.timeZone.addOffset(new Date(), offset).toDate();
};

export const findDateFromMaxDate = (dateToString: string | null) =>
    dateToString
        ? statics.timeZone.subtractOffset(dateToString, statics.timeZone.getLocalUtcOffset()).toDate()
        : undefined;

export const findDateToMinDate = (dateFromString: string | null, todayDate: Date) => {
    if (dateFromString) {
        return statics.timeZone.subtractOffset(dateFromString, statics.timeZone.getLocalUtcOffset()).toDate();
    }

    return moment.utc(todayDate).toDate();
};
