import { CloseCircleFilledIcon, DeadlineIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo, SyntheticEvent, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { useDeadlineDurationLabel, useDeadlineTypeLabel } from './DeadlineButton.hooks';
import { messages } from './DeadlineButton.messages';
import { CloseButton, Root } from './DeadlineButton.styles';
import { DeadlineButtonProps } from './DeadlineButton.types';

const qa = bemFactory.qa('wfc-duration-editor-button');

const DeadlineButton: FC<DeadlineButtonProps> = memo((props) => {
    const { value, type, readonly, onClear } = props;

    const empty = !value;
    const title = empty ? messages.workflowStepEmptyDurationH3 : messages.workflowStepDurationH3;

    const durationLabel = useDeadlineDurationLabel(value);
    const typeLabel = useDeadlineTypeLabel(type);

    const handleClear = useCallback(
        (event: SyntheticEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            onClear();
        },
        [onClear]
    );

    return (
        <Root title={title} data-qa={qa()}>
            <Flex inline spacing='4' wrap={false}>
                <Button color='transparent' noPadding uppercase={false} disabled={readonly}>
                    <Flex inline spacing='4' wrap={false} alignItems='center' data-qa={qa('text')}>
                        <Box width={20}>
                            <DeadlineIcon color={readonly ? 'midnight80' : 'blue80'} size={20} />
                        </Box>

                        <div>
                            <Text font='label' fontSize='xsmall' fontWeight='medium' color='blue100'>
                                {durationLabel}
                            </Text>

                            <Text font='label' fontSize='xxsmall' color='midnight80'>
                                {typeLabel}
                            </Text>
                        </div>
                    </Flex>
                </Button>

                {!readonly && !empty && (
                    <CloseButton onClick={handleClear} size='small' icon noPadding qa={qa('delete-button')}>
                        <CloseCircleFilledIcon size={12} color='midnight100' />
                    </CloseButton>
                )}
            </Flex>
        </Root>
    );
});

export default DeadlineButton;
