import { Box } from '@approvalmax/ui/src/components';
import { forwardRef, memo } from 'react';

import Item from '../Item/Item';
import { ListProps } from './List.types';

/* The List component creates lists with menu items */
const List = memo(
    forwardRef<HTMLUListElement, ListProps>((props, ref) => {
        const { items, spacing, ...restProps } = props;

        if (!items) return null;

        return (
            <Box {...restProps} as='ul' spacing={spacing} ref={ref} role='menu'>
                {items?.length &&
                    items.map((item, index) => <Item {...item} key={item.id || index} id={item.id || index} />)}
            </Box>
        );
    })
);

export default List;
