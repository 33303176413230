import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { dataApi, domain } from 'modules/data';
import { createAction, ExtractActions } from 'modules/react-redux';

export const CANCEL_ACTIVE_POPUP = 'PROFILE/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export type StartTrialPopupOrigin =
    | 'banner_in_drawer'
    | 'auto_approvals_wf_builder'
    | 'stand-alone_wf'
    | 'contact_approvals_wf'
    | 'batch_payments_wf'
    | 'quote_worflow_wf'
    | 'manual_journal_wf'
    | 'vendor_approvals_wf'
    | 'journal_entry_wf'
    | 'direct_link'
    | 'versionshistory_wfbuilder'
    | 'po_matching_requestcard'
    | 'budgetchecking_requestcard'
    | 'GRN_requestcard'
    | 'GRN_settings'
    | 'po_matching_settings'
    | 'billing: land on all feature trial paywall'
    | 'accrual_reports_popup'
    | domain.IntegrationCode;

export const OPEN_CONSENT_TO_START_TRIAL_POPUP = 'PROFILE/OPEN_CONSENT_TO_START_TRIAL_POPUP';
export const openConsentToStartTrialPopup = (company: selectors.types.ExpandedCompany, origin: StartTrialPopupOrigin) =>
    createAction(OPEN_CONSENT_TO_START_TRIAL_POPUP, {
        company,
        origin,
    });

export const OPEN_TRIAL_PERIOD_STARTED_POPUP = 'PROFILE/OPEN_TRIAL_PERIOD_STARTED_POPUP';
export const openTrialPeriodStartedPopup = () => createAction(OPEN_TRIAL_PERIOD_STARTED_POPUP, {});

export const OPEN_CONTACT_SALES_POPUP = 'PROFILE/OPEN_CONTACT_SALES_POPUP';
export const openContactSalesPopup = () => createAction(OPEN_CONTACT_SALES_POPUP, {});

export const OPEN_TWO_FA_SOFT_ENFORCEMENT_POPUP = 'PROFILE/OPEN_TWO_FA_SOFT_ENFORCEMENT_POPUP';
export const openTwoFaSoftEnforcementPopup = () => createAction(OPEN_TWO_FA_SOFT_ENFORCEMENT_POPUP, {});

export const OPEN_CONTACT_SUPPORT_POPUP = 'PROFILE/OPEN_CONTACT_SUPPORT_POPUP';
export const openContactSupportPopup = () => createAction(OPEN_CONTACT_SUPPORT_POPUP, {});

export const waitForAddingFeaturesToWorkflow = (company: selectors.types.ExpandedCompany) => {
    // this is temporary solution, will be changed to socket.io
    return new Promise<void>((resolve, reject) => {
        let inProgress = true;

        const timeoutId = setTimeout(() => {
            // reject if after 60 seconds there is still no template
            inProgress = false;
            reject();
        }, 60000);

        const wait = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

        let integrationCodes: domain.IntegrationCode[];

        switch (company.integration?.integrationType) {
            case domain.IntegrationType.Xero:
                integrationCodes = [domain.IntegrationCode.XeroContact, domain.IntegrationCode.XeroBillBatchPayment];
                break;

            case domain.IntegrationType.QBooks:
                integrationCodes = [domain.IntegrationCode.QBooksVendor];
                break;

            case domain.IntegrationType.NetSuite:
                throw errorHelpers.notSupportedError('This should not work for company with NetSuite integration');

            case domain.IntegrationType.Dear:
                throw errorHelpers.notSupportedError('This should not work for company with Cin7 Core integration');

            case domain.IntegrationType.None:
                throw errorHelpers.notSupportedError('This should not work for company without integration');

            default:
                throw errorHelpers.assertNever(company.integration!.integrationType || '');
        }

        const checkTemplatesForExistance = async () => {
            await wait(3000); // interval between API requests

            const response = await dataApi.templates.select({ companyId: company.id });
            const hasTemplateWithCurrentIntegractionCode = response.Templates.some((t) =>
                integrationCodes.includes(t.IntegrationCode as domain.IntegrationCode)
            );

            if (hasTemplateWithCurrentIntegractionCode) {
                clearTimeout(timeoutId);
                resolve();
            } else if (inProgress) {
                checkTemplatesForExistance();
            }
        };

        checkTemplatesForExistance();
    });
};

export type Action = ExtractActions<
    | typeof cancelActivePopup
    | typeof openConsentToStartTrialPopup
    | typeof openContactSalesPopup
    | typeof openContactSupportPopup
    | typeof openTrialPeriodStartedPopup
    | typeof openTwoFaSoftEnforcementPopup
>;
