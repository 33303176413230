import { FC, memo } from 'react';

import { Flex, Text } from '../../../../../components';
import { xeroNotTrialPremiumFeatures } from '../../AllFeatureTrialPaywallPopup.constants';
import { TrialIntegrationType } from '../../AllFeatureTrialPaywallPopup.types';
import { FeatureButton } from '..';
import {
    getAddOnsFeatures,
    getAdvancedFeatures,
    getPremiumFeatures,
    isShowAdvanced,
    isShowPremium,
} from '../Content/Content.helpers';
import { messages } from './FeaturesList.messages';
import { GridMinContent } from './FeaturesList.styles';
import { FeaturesListProps } from './FeaturesList.types';

export const FeaturesList: FC<FeaturesListProps> = memo((props) => {
    const { company, activeFeature, setActiveFeature } = props;

    const isXero = company?.integrationType === TrialIntegrationType.Xero;
    const isQbo = company?.integrationType === TrialIntegrationType.Quickbooks;

    const showAdvanced = isShowAdvanced(company);
    const showPremium = isShowPremium(company);
    const addons = getAddOnsFeatures({
        company,
        integrationType: company?.integrationType,
    });
    const showAddons = Boolean(addons.length);

    const wideTiles = !showAdvanced;

    const advancedFeatures = getAdvancedFeatures(company?.integrationType);
    const premiumFeatures = getPremiumFeatures(company?.integrationType);

    if (!isXero && !isQbo) {
        return null;
    }

    return (
        <Flex inline direction='row' wrap={false}>
            {showAdvanced && (
                <GridMinContent size={12} spacing='8' direction='column' inline>
                    <Text font='headline' fontSize='xxsmall' spacing='0 0 8'>
                        {messages.advanced}
                    </Text>

                    {advancedFeatures.map((feature) => (
                        <FeatureButton
                            key={feature.id}
                            title={feature.title}
                            active={activeFeature === feature.id}
                            onClick={() => setActiveFeature(feature.id)}
                        />
                    ))}
                </GridMinContent>
            )}

            {showPremium && (
                <GridMinContent size={wideTiles ? 24 : 12} spacing='8' direction='column' inline>
                    {(showAdvanced || showAddons) && (
                        <>
                            <Text font='headline' fontSize='xxsmall' spacing='0 0 8'>
                                {messages.premium}
                            </Text>

                            <FeatureButton title={messages.allAdvancedFeatures} disabled />
                        </>
                    )}

                    {premiumFeatures.map((feature, index) => (
                        <FeatureButton
                            key={feature.id}
                            title={feature.title}
                            active={activeFeature === feature.id}
                            onClick={() => setActiveFeature(feature.id)}
                        />
                    ))}

                    {isQbo && (
                        <Text font='body' fontSize='xsmall' color='midnight70'>
                            {messages.qboNote}
                        </Text>
                    )}

                    {isXero && (
                        <>
                            <Text font='body' fontSize='xsmall' spacing='16 0 0' color='midnight70'>
                                {messages.xeroNote}
                            </Text>

                            {xeroNotTrialPremiumFeatures.map((feature) => (
                                <FeatureButton
                                    key={feature.id}
                                    title={feature.title}
                                    active={activeFeature === feature.id}
                                    onClick={() => setActiveFeature(feature.id)}
                                />
                            ))}
                        </>
                    )}
                </GridMinContent>
            )}

            {showAddons && (
                <GridMinContent size={wideTiles ? 24 : 12} spacing='8' direction='column' inline>
                    <Text font='headline' fontSize='xxsmall' spacing='0 0 8'>
                        {messages.addOnsTitle}
                    </Text>

                    {addons.map((feature) => (
                        <FeatureButton
                            key={feature.id}
                            title={feature.title}
                            active={activeFeature === feature.id}
                            onClick={() => setActiveFeature(feature.id)}
                        />
                    ))}
                </GridMinContent>
            )}
        </Flex>
    );
});

FeaturesList.displayName = 'FeaturesList';
