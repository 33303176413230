import { Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { Root } from './UserConditionHeader.styles';
import { UserConditionHeaderProps } from './UserConditionHeader.types';

const UserConditionHeader: FC<UserConditionHeaderProps> = memo((props) => {
    const { title } = props;

    return (
        <Root width='236px' spacing='12 12 12 40'>
            <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                {title}
            </Text>
        </Root>
    );
});

export default UserConditionHeader;
