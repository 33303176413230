import { backend } from 'modules/data';
import { RequestEditMode } from 'pages/requestForm/selectors/requestSelectors';

export const getRuleTypeByEditMode = (editMode: RequestEditMode): backend.EditRoleForRequest => {
    switch (editMode) {
        case RequestEditMode.Submitter:
            return backend.EditRoleForRequest.Submitter;

        case RequestEditMode.Editor:
            return backend.EditRoleForRequest.Editor;

        case RequestEditMode.Reviewer:
            return backend.EditRoleForRequest.Reviewer;

        case RequestEditMode.Approver:
            return backend.EditRoleForRequest.Approver;
    }
};
