import { intl } from '@approvalmax/utils';
import difference from 'lodash/difference';
import { factories, selectors } from 'modules/common';
import { domain, schemas, State, stateTree } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

const i18nPrefix = 'company.actions';
const messages = defineMessages({
    usersInvitedNotification: {
        id: `${i18nPrefix}.usersInvitedNotification`,
        defaultMessage: '{count, plural, one {User invited} other {Users invited}}',
    },
    usersAddedNotification: {
        id: `${i18nPrefix}.usersAddedNotification`,
        defaultMessage: '{count, plural, one {User added} other {Users added}}',
    },
    theUserIsDelegateHimselfNotification: {
        id: `${i18nPrefix}.theUserIsDelegateHimselfNotification`,
        defaultMessage: '{name} cannot be set a delegate because he/she is a delegate themself.',
    },
});

export const LOAD_PAGE_DATA = 'USERS/LOAD_PAGE_DATA';
export const loadPageData = (companyId: string, entities: Partial<stateTree.Entities>) =>
    createAction(LOAD_PAGE_DATA, { companyId, entities });

export const CHANGE_USER_ROLE = 'USERS/CHANGE_USER_ROLE';
export const changeUserRole = (
    companyId: string,
    user: selectors.types.ExpandedCompanyUser,
    role: domain.CompanyUserRole
) =>
    createAction(CHANGE_USER_ROLE, {
        companyId,
        user,
        role,
    });

export const SHOW_ADD_USERS_POPUP = 'USERS/SHOW_ADD_USERS_POPUP';
export const showAddUsersPopup = () => createAction(SHOW_ADD_USERS_POPUP, {});

export const SHOW_INVITE_USERS_POPUP = 'USERS/SHOW_INVITE_USERS_POPUP';
export const showInviteUsersPopup = (users: domain.User[]) =>
    createAction(SHOW_INVITE_USERS_POPUP, {
        users,
    });

export const SHOW_SET_DELEGATE_POPUP = 'USERS/SHOW_SET_DELEGATE_POPUP';

interface ShowSetDelegatePopupAction {
    type: typeof SHOW_SET_DELEGATE_POPUP;
    userId: string;
}

export function showSetDelegatePopup(userId: string): ThunkAction<State> {
    return (dispatch) => {
        dispatch<ShowSetDelegatePopupAction>({
            type: SHOW_SET_DELEGATE_POPUP,
            userId,
        });
    };
}

export const SHOW_OFFBOARD_USER_POPUP = 'USERS/SHOW_OFFBOARD_USER_POPUP';
export const showOffboardUserPopup = (userEmail: string) =>
    createAction(SHOW_OFFBOARD_USER_POPUP, {
        userEmail,
    });

export const SHOW_DELETE_USERS_POPUP = 'USERS/SHOW_DELETE_USERS_POPUP';
export const showDeleteUsersPopup = (userIds: string[]) =>
    createAction(SHOW_DELETE_USERS_POPUP, {
        users: userIds,
    });

export const CANCEL_ACTIVE_POPUP = 'USERS/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const ADD_USERS = 'USERS/ADD_USERS';
export const ADD_USERS_RESPONSE = 'USERS/ADD_USERS_RESPONSE';
export const ADD_USERS_FAILURE = 'USERS/ADD_USERS_FAILURE';
export const addUsers = (company: domain.Company, userEmails: string[], doInvite: boolean, invitationText?: string) =>
    createAsyncAction({
        request: (state: State) => {
            const team = selectors.company.getCompanyById(state, company.id).allMembers;
            const newUsers = difference(
                userEmails,
                team.map((u) => u.userEmail)
            ).map((email) => factories.user.createUser(email));
            const existingUsers: domain.User[] = team.filter(
                (u) => userEmails.includes(u.userEmail) && u.status !== domain.CompanyUserStatus.Active
            );

            return createAction(ADD_USERS, {
                company,
                newUsers,
                existingUsers,
                doInvite,
                invitationText,
            });
        },

        response: async (request) => {
            let response = {
                Participants: [] as any,
            };

            if (request.newUsers.length > 0) {
                const result = await api.companies.addParticipants({
                    companyId: company.id,
                    userEmailsWithRoles: {},
                    doInvite,
                    invitationText,
                });

                response.Participants.push(...(result.Participants as any));
            }

            if (doInvite && request.existingUsers.length > 0) {
                const result = await api.companies.inviteParticipants({
                    companyId: company.id,
                    participantUserIdsWithRoles: Object.fromEntries(
                        request.existingUsers.map((user) => [
                            user.databaseId,
                            selectors.company.getCompanyUserRole(company, user.id),
                        ])
                    ),
                    invitationText,
                });

                response.Participants.push(...(result.Participants as any));
            }

            return createAction(ADD_USERS_RESPONSE, {
                request,
                userStatuses: schemas.company.extractUserStatusFromAnswerArray(response.Participants, company.author),
                raw: response,
            });
        },

        failure: (error, request) => createErrorAction(ADD_USERS_FAILURE, error, request),

        schema: {
            raw: { Participants: [schemas.userSchema] },
        },

        willDispatchResponse: () => {
            if (doInvite) {
                notificationService.showInfoToast(
                    intl.formatMessage(messages.usersInvitedNotification, {
                        count: userEmails.length,
                    })
                );
            } else {
                notificationService.showInfoToast(
                    intl.formatMessage(messages.usersAddedNotification, {
                        count: userEmails.length,
                    })
                );
            }
        },
    });

export const SET_DELEGATE = 'USERS/SET_DELEGATE';
export const setDelegate = (
    companyId: string,
    user: domain.User,
    delegateUserId?: string,
    delegateUserProfileId?: string,
    delegateDateFrom?: string | null,
    delegateDateTo?: string | null
) =>
    createAction(SET_DELEGATE, {
        companyId,
        user,
        delegateUserId,
        delegateUserProfileId,
        delegateDateFrom,
        delegateDateTo,
    });

export const INVITE_USERS = 'USERS/INVITE_USERS';
export const INVITE_USERS_RESPONSE = 'USERS/INVITE_USERS_RESPONSE';
export const INVITE_USERS_FAILURE = 'USERS/INVITE_USERS_FAILURE';
export const inviteUsers = (company: domain.Company, invitationMessage: string, users: domain.User[]) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(INVITE_USERS, {
                company,
                users: users.map((u) => u.id),
            }),

        response: async (request) => {
            const response = await api.companies.inviteParticipants({
                companyId: company.id,
                participantUserIdsWithRoles: Object.fromEntries(
                    users
                        .map((user) => (company.tempUsers || {})[user.id] || user)
                        .map((user) => [user.databaseId, selectors.company.getCompanyUserRole(company, user.id)])
                ),
                invitationText: invitationMessage,
            });

            return createAction(INVITE_USERS_RESPONSE, {
                request,
                raw: response,
                userStatuses: schemas.company.extractUserStatusFromAnswerArray(response.Participants, company.author),
            });
        },

        failure: (error, request) => createErrorAction(INVITE_USERS_FAILURE, error, {}),

        schema: {
            raw: { Participants: [schemas.userSchema] },
        },

        willDispatchResponse: () => {
            notificationService.showInfoToast(
                intl.formatMessage(messages.usersInvitedNotification, { count: users.length })
            );
        },
    });

export type Action =
    | ExtractActions<
          | typeof addUsers
          | typeof cancelActivePopup
          | typeof changeUserRole
          | typeof inviteUsers
          | typeof loadPageData
          | typeof setDelegate
          | typeof showAddUsersPopup
          | typeof showDeleteUsersPopup
          | typeof showInviteUsersPopup
          | typeof showOffboardUserPopup
      >
    | ShowSetDelegatePopupAction;
