import { PascalCaseToCamelCase } from '@approvalmax/types';
import { backend } from 'modules/data';

export enum VendorType {
    Individual = 'individual',
    Company = 'company',
}

interface AddressLine {
    label: string | null;
    defaultBilling: boolean;
    defaultShipping: boolean;
    address: Partial<{
        primaryAddress: string | null;
        secondaryAddress: string | null;
        additionalAddress: string | null;
        addressee: string | null;
        attention: string | null;
        city: string | null;
        country: string | null;
        zip: string | null;
        state: string | null;
        phone: string | null;
        addressRepresentation: string | null;
    }>;
}

interface Subsidiary {
    creditLimit: number | null;
    taxCodeId: string | null;
    subsidiaryId: string | null;
}

export interface UseCreateNetSuiteVendorData {
    companyId: string;
    vendor: Partial<{
        type: VendorType;
        companyName: string | null;
        webAddress: string | null;
        salutation: string | null;
        firstName: string | null;
        lastName: string | null;
        middleName: string | null;
        jobTitle: string | null;
        categoryId: string | null;
        comments: string | null;
        email: string | null;
        phone: string | null;
        altPhone: string | null;
        fax: string | null;
        subsidiaryId: string | null;
        addressLines: AddressLine[];
        legalName: string | null;
        accountNumber: string | null;
        defaultExpenseAccountId: string | null;
        defaultPayablesAccountId: string | null;
        currencyId: string | null;
        termsId: string | null;
        taxCodeId: string | null;
        creditLimit: number | null;
        incotermId: string | null;
        vatRegistrationNumber: string | null;
        is1099Eligible: boolean;
        isJobResource: boolean;
        currencies: string[];
        subsidiaries: Subsidiary[];
    }>;
}

export interface UseCreateNetSuiteVendorResponseBackend {
    Vendor: backend.ReferenceAnswer & {
        VatNumber: string | null;
    };
}

export type UseCreateNetSuiteVendorResponse = PascalCaseToCamelCase<UseCreateNetSuiteVendorResponseBackend>;
