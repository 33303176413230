import { groupBy } from 'lodash';
import { actions, selectors } from 'modules/common';
import { backend, State } from 'modules/data';
import { ProfileData } from 'modules/first-start/reducers/moduleReducer';
import { getAcceptedInvitationIds, getSelectedPracticeInvitation } from 'modules/first-start/selectors/moduleSelectors';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { routingService } from 'services/routing';

export const SHOW_FIRST_START_SETUP_POPUP = 'FIRST_START/SHOW_FIRST_START_SETUP_POPUP';
export const showFirstStartSetupPopup = (
    invitations: selectors.types.ExpandedCompanyInvitation[],
    firstName: string,
    lastName: string
) =>
    createAction(SHOW_FIRST_START_SETUP_POPUP, {
        invitations,
        firstName,
        lastName,
    });

export const UPDATE_PROFILE_DATA = 'FIRST_START/UPDATE_PROFILE_DATA';
export const updateProfileData = (profileData: ProfileData) =>
    createAction(UPDATE_PROFILE_DATA, {
        profileData,
    });

export const UPDATE_ACCEPTED_INVITATIONS = 'FIRST_START/UPDATE_ACCEPTED_INVITATIONS';
export const updateAcceptedInvitations = (acceptedInvitationIds: string[]) =>
    createAction(UPDATE_ACCEPTED_INVITATIONS, {
        acceptedInvitationIds,
    });

export const UPDATE_PRACTICE_ACCEPTED_INVITATIONS = 'FIRST_START/UPDATE_PRACTICE_ACCEPTED_INVITATIONS';
export const updatePracticeAcceptedInvitations = (accountId: string) =>
    createAction(UPDATE_PRACTICE_ACCEPTED_INVITATIONS, {
        accountId,
    });

export const COMPLETE_FIRST_START_SETUP = 'FIRST_START/COMPLETE_FIRST_START_SETUP';
export const COMPLETE_FIRST_START_SETUP_RESPONSE = 'FIRST_START/COMPLETE_FIRST_START_SETUP_RESPONSE';
export const COMPLETE_FIRST_START_SETUP_FAILURE = 'FIRST_START/COMPLETE_FIRST_START_SETUP_FAILURE';
export const completeFirstStartSetup = ({ isMobile }: { isMobile?: boolean }) =>
    createAsyncAction({
        request: (state: State) => {
            return createAction(COMPLETE_FIRST_START_SETUP, {
                acceptedInvitationIds: getAcceptedInvitationIds(state),
                invitations: selectors.companyInvitation.getPendingCompanyInvitations(state),
                practiceInvitations: selectors.practiceInvitations.getPracticeInvitations(state),
                selectedPracticeInvitation: getSelectedPracticeInvitation(state),
            });
        },

        response: async (request) => {
            // Accept invitations
            if (request.invitations.length > 0) {
                const responses = request.invitations
                    .filter((inv) => request.acceptedInvitationIds.includes(inv.id))
                    .map((inv) => ({
                        companyId: inv.companyId,
                        response: backend.ResponseToInvitation.Accepted,
                    }));

                const responsesByCompanyId = groupBy(responses, (r) => r.companyId);

                await Promise.all(
                    Object.entries(responsesByCompanyId).map(([companyId, responses]) =>
                        api.companies.respondToInvitation({
                            responses,
                            companyId,
                        })
                    )
                );
            }

            // Accept practice invitations
            if (request.practiceInvitations.length > 0 && request.selectedPracticeInvitation) {
                const responseData = request.practiceInvitations.map((invite) => {
                    const response =
                        invite.id === request.selectedPracticeInvitation
                            ? backend.transfers.PracticeInvitationResponse.Accepted
                            : backend.transfers.PracticeInvitationResponse.Rejected;

                    return {
                        accountId: invite.id,
                        response,
                    };
                });

                await api.account.respondToPracticeInvitation({ invitations: responseData });
            }

            const rawContext = await api.companies.getUserContext({});

            amplitudeService.sendData('signup: create user profile');

            return createAction(COMPLETE_FIRST_START_SETUP_RESPONSE, {
                request,
                rawContext,
                isMobile,
            });
        },

        failure: (error, request) => createErrorAction(COMPLETE_FIRST_START_SETUP_FAILURE, error, {}),

        didDispatchResponse: async (request, response, state, dispatch) => {
            await dispatch(actions.loadInitialAppData({ context: response.rawContext }));

            if (!isMobile) {
                routingService.pushToDefaultPath();
            }
        },
    });

export type Action = ExtractActions<
    | typeof completeFirstStartSetup
    | typeof showFirstStartSetupPopup
    | typeof updateAcceptedInvitations
    | typeof updatePracticeAcceptedInvitations
    | typeof updateProfileData
>;
