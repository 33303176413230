import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseTwoFaEnablingEmailSetRequest, UseTwoFaEnablingEmailSetResponse } from './useTwoFaEnablingEmailSet.types';

export const useTwoFaEnablingEmailSet = () => {
    return useMutateLegacy<RequestDataParams<UseTwoFaEnablingEmailSetRequest>, UseTwoFaEnablingEmailSetResponse>(
        twoFaApiPaths.enablingEmailSet
    );
};
