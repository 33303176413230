import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { Module } from 'modules/page';
import { ThunkAction } from 'modules/react-redux';

import {
    createAccount,
    showCreateOrJoinPopup,
    showFirstStartSetupPopup,
    showNewOrganisationPopup,
    showSignUpConsentPopup,
} from './actions';
import ModuleComponent from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import moduleReducer from './reducers/moduleReducer';
import profileReducer from './reducers/profileReducer';

export function loadFirstStartSetup(): ThunkAction {
    return (dispatch, getState) => {
        const state = getState();
        const invitations = selectors.companyInvitation.getPendingCompanyInvitations(state);
        const profile = selectors.profile.getProfileUser(state);

        const firstName = profile.firstName || '';
        const lastName = profile.lastName || '';

        dispatch(showFirstStartSetupPopup(invitations, firstName, lastName));
    };
}

export function loadSsoFirstStartSetup(options: {
    oauthProvider: domain.OAuthProvider;
    email: string;
    firstName: string;
    lastName: string;
    signUpCode: string;
    app?: domain.SsoLoginApplication;
    startingPoint?: domain.SsoStartingPoint;
    redirectUrl?: string;
}): ThunkAction {
    return (dispatch, getState) => {
        dispatch(showSignUpConsentPopup(options));
    };
}

export function loadNewOrganisationSetup(options: {
    oauthProvider?: domain.OAuthProvider;
    firstStart?: boolean;
    fromNoCompanies?: boolean;
}): ThunkAction {
    return (dispatch, getState) => {
        dispatch(showNewOrganisationPopup(options));
    };
}

export function loadSsoAfterSignUpSetup(): ThunkAction {
    return (dispatch, getState) => {
        dispatch(showCreateOrJoinPopup());
    };
}

export function loadCreateAccount(options: {
    isPartner: boolean;
    practiceName: string;
    companyCount: string;
    clientCount: string;
}): ThunkAction {
    return async (dispatch, getState) => {
        await dispatch(createAccount(options));
    };
}

export const moduleDef: Module = {
    id: 'firstStart',
    moduleReducer: moduleReducer,
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
        profile: profileReducer,
    },
};
