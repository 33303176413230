import { Flex, Link, Text } from '@approvalmax/ui/src/components';
import { FC } from 'react';

import { messages } from './SettingsSection.messages';
import { SettingsSectionProps } from './SettingsSection.types';

const SettingsSection: FC<SettingsSectionProps> = (props) => {
    const { title, learnMoreLink, children, spacing } = props;

    return (
        <Flex inline spacing={spacing || '12'} direction='column' width='100%'>
            <Flex container justifyContent='space-between'>
                <Text font='headline' color='midnight80' fontWeight='medium' fontSize='xsmall'>
                    {title}
                </Text>

                {learnMoreLink && (
                    <Link href={learnMoreLink} external title={messages.learnMore} font='body'>
                        {messages.learnMore}
                    </Link>
                )}
            </Flex>

            <Flex inline spacing='32' direction='column'>
                {children}
            </Flex>
        </Flex>
    );
};

export default SettingsSection;
