import { Radio } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { memo } from 'react';

import SettingsSection from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionNetSuiteAvailableLineItemType.messages';
import { SectionNetSuiteAvailableLineItemTypeProps } from './SectionNetSuiteAvailableLineItemType.types';

const SectionNetSuiteAvailableLineItemType = memo<SectionNetSuiteAvailableLineItemTypeProps>((props) => {
    const { templateSettings, onChange, readonly, qa, learnMoreLink } = props;

    const value = templateSettings.netSuiteAvailableLineItemType || domain.NetSuiteAvailableLineItemType.All;

    return (
        <SettingsSection title={messages.title} learnMoreLink={learnMoreLink}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={onChange}
                    name='netSuiteAvailableLineItem'
                    block
                    data-qa={qa('netsuite-available-line-item-type-settings-section')}
                >
                    <Radio value={domain.NetSuiteAvailableLineItemType.All}>{messages.all}</Radio>

                    <Radio value={domain.NetSuiteAvailableLineItemType.ExpenseLines}>{messages.expenseLines}</Radio>

                    <Radio value={domain.NetSuiteAvailableLineItemType.ItemLines}>{messages.itemLines}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionNetSuiteAvailableLineItemType;
