import { factories, selectors } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';
import { gaService } from 'services/ga';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';

import { SignupModuleData } from '../reducers/page/moduleReducer';
import { getModule } from '../selectors/pageSelectors';

export const SHOW_SIGNUP_MODULE = 'AUTH/SHOW_SIGNUP_MODULE';
export const showSignupModule = (options: {
    email?: string;
    token?: string;
    app?: domain.SsoLoginApplication;
    innerPageId?: string;
}) => createAction(SHOW_SIGNUP_MODULE, options);

export const SIGNUP = 'AUTH/SIGNUP';
export const SIGNUP_RESPONSE = 'AUTH/SIGNUP_RESPONSE';
export const SIGNUP_FAILURE = 'AUTH/SIGNUP_FAILURE';
export const signup = (email: string, acceptedProductNewsEmails: boolean, app?: domain.SsoLoginApplication) =>
    createAsyncAction({
        request: (state: State) => {
            const module = getModule<SignupModuleData>(state);
            const oneTimeToken = module.email === email ? module.token : undefined;
            const name = selectors.user.expandUser(factories.user.createUser(email)).displayName;

            return createAction(SIGNUP, {
                email,
                name,
                oneTimeToken,
                acceptedProductNewsEmails,
                app,
            });
        },

        response: async (request) => {
            const response = await api.auth.register({
                email: request.email,
                uiState: encodeURIComponent(
                    JSON.stringify({
                        app: request.app,
                    })
                ),
                sendConfirmationLink: true,
                timeZoneForEmail: window.ApprovalMax.userTimeZone,
                acceptedProductNewsEmails: request.acceptedProductNewsEmails,
                oneTimeToken: request.oneTimeToken,
            } as backend.transfers.AuthRegisterTransfer);

            return createAction(SIGNUP_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SIGNUP_FAILURE, error, {}),

        willDispatchResponse: async (request) => {
            await gaService.sendEvent('sign_up', {
                method: 'direct',
            });

            if (request.oneTimeToken) {
                storageService.setAuthenticated(true);
                routingService.reloadToDefaultPath();
            }
        },
    });

export type Action = ExtractActions<typeof showSignupModule | typeof signup>;
