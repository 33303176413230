import { changeTwoFaAlternativeEmailIsEnabled } from 'modules/profile/actions';
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notificationService } from 'services/notification';
import { useFinishAddingTFAAlternativeEmail, useStartAddingTFAAlternativeEmail } from 'shared/data';

import { messages } from './StepEmailConfirm.messages';
import { StepEmailConfirmProps } from './StepEmailConfirm.types';

export const useStepEmailConfirm = (props: StepEmailConfirmProps) => {
    const { email, setStateId, stateId, onClose } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const { mutate: sendCode, isError: isSendError, isLoading: isSendLoading } = useFinishAddingTFAAlternativeEmail();
    const { mutate: resendCode, isLoading: isResendLoading } = useStartAddingTFAAlternativeEmail();
    const [emailCode, setEmailCode] = useState('');
    const dispatch = useDispatch();

    const onSubmit = useCallback(async () => {
        if (stateId && emailCode) {
            sendCode(
                {
                    data: { stateId, alternativeEmailCode: emailCode.trim() },
                },
                {
                    onSuccess: () => {
                        onClose();
                        notificationService.showInfoToast(messages.onSuccess);
                        dispatch(changeTwoFaAlternativeEmailIsEnabled(true));
                    },
                    onError: (err) => {
                        inputRef.current!.focus();
                    },
                }
            );
        }
    }, [dispatch, emailCode, onClose, sendCode, stateId]);

    const onResend = useCallback(() => {
        if (stateId) {
            resendCode(
                {
                    data: { alternativeEmail: email },
                },
                {
                    onSuccess: (response) => {
                        setStateId(response.stateId);
                    },
                    onError: (err) => {
                        notificationService.showErrorToast(err.message);
                    },
                }
            );
        }
    }, [email, resendCode, setStateId, stateId]);

    return {
        email,
        emailCode,
        inputRef,
        onChange: setEmailCode,
        onSubmit,
        onResend,
        isLoading: isSendLoading || isResendLoading,
        isError: isSendError,
    };
};
