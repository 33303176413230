import { RequestDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useTwoFaEnablingStart.map';
import {
    UseTwoFaEnablingStartRequest,
    UseTwoFaEnablingStartResponse,
    UseTwoFaEnablingStartResponseBackend,
} from './useTwoFaEnablingStart.types';

export const useTwoFaEnablingStart = () => {
    return useMutateLegacy<
        RequestDataParams<UseTwoFaEnablingStartRequest>,
        UseTwoFaEnablingStartResponseBackend,
        UseTwoFaEnablingStartResponse
    >(twoFaApiPaths.enablingStart, { mapData });
};
