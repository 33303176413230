import Fuse, { type IFuseOptions } from 'fuse.js';
import { type SyntheticEvent, useCallback, useMemo, useState } from 'react';
import type { Paths } from 'type-fest';

import { useDebounce } from './useDebounce';

export type FuseSearchKeys<T extends object> = Paths<T>[];

type Options<T extends object, Keys extends string[] = Extract<Paths<T>, string>[]> = Omit<IFuseOptions<T>, 'keys'> & {
    keys?: Keys;
};

const defaultOptions = {
    findAllMatches: true,
    isCaseSensitive: false,
    ignoreFieldNorm: true,
    ignoreLocation: true,
    threshold: 0.1,
};

/**
 * Fuzzy search with internal state
 */
export const useFuzzySearch = <T extends object>(data: T[] = [], options: Options<T>) => {
    const [searchQuery, setSearchQuery] = useState('');
    const searchQueryDebounced = useDebounce(searchQuery, 500);

    const fuse = useMemo(() => new Fuse(data, { ...defaultOptions, ...options }), [data, options]);

    const filteredData = useMemo<T[]>(() => {
        if (searchQueryDebounced.trim()) {
            return fuse.search(searchQueryDebounced).map((i) => i.item);
        }

        return data;
    }, [searchQueryDebounced, data, fuse]);

    const handleSearchQueryChangeEvent = useCallback((event: SyntheticEvent, value: string) => {
        setSearchQuery(value);
    }, []);

    const handleSearchQueryChange = useCallback((value: string) => {
        setSearchQuery(value);
    }, []);

    return { filteredData, searchQuery, searchQueryDebounced, handleSearchQueryChangeEvent, handleSearchQueryChange };
};

/**
 * Fuzzy search with external state
 */
export function useSearchWithFuse<T extends object, Keys extends string[] = Extract<Paths<T>, string>[]>(
    data: T[] = [],
    searchText: string,
    options: Options<T, Keys>
): T[] {
    const fuse = useMemo(() => new Fuse(data, { ...defaultOptions, ...options }), [data, options]);

    return useMemo<T[]>(() => {
        if (searchText.trim()) {
            return fuse.search(searchText).map((i) => i.item);
        }

        return data;
    }, [data, fuse, searchText]);
}
