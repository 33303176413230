import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.tr<StyledMods<Mods<'bordered'>>>`
    ${mods.bordered.false`
        border-bottom: 1px solid ${theme.color.midnight50};
    `};

    ${mods.bordered.true`
        border: 1px solid ${theme.color.midnight50};
    `};
`;
