import { Reference } from '@approvalmax/types';
import { Flex } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';

import {
    addApprovalRuleToActiveMatrix,
    addFieldToActiveTemplate,
    createField,
    createFieldOption,
    setCondition,
} from '../../../../actions';
import { getMatrixDefinition } from '../../../../config/matrixDefinitions';
import { MatrixType } from '../../../../types/matrix';
import AddFieldHeaderColumn from '../AddFieldHeaderColumn/AddFieldHeaderColumn';
import { EditingPermissionHeader } from '../EditingPermissionHeader/EditingPermissionHeader';
import MatrixAddUserButton from '../MatrixAddUserButton/MatrixAddUserButton';
import { MatrixLine } from '../MatrixLine/MatrixLine';
import MatrixLineAutoApproval from '../MatrixLineAutoApproval/MatrixLineAutoApproval';
import RuleNameButton from '../RuleNameButton/RuleNameButton';
import UserConditionHeader from '../UserConditionHeader/UserConditionHeader';
import { useRequiredFieldIds, useUnusedFields, useUserColumnTitle } from './Matrix.hooks';
import { messages } from './Matrix.messages';
import { AddRuleNameContainer, Header, Root, Table } from './Matrix.styles';
import { MatrixProps } from './Matrix.types';

const Matrix: FC<MatrixProps> = memo((props) => {
    const { definition, company, team, template, integrationType, fields, readonly, submitters, cellFields, matrix } =
        props;

    const dispatch = useDispatch();
    const [isSticky, setIsSticky] = useState(false);
    const isStandalone = integrationType === domain.IntegrationType.None;
    const isAutoApprovalMatrix = matrix.type === MatrixType.AutoApproval;
    const isEditingMatrix = matrix.type === MatrixType.Editing;
    const isNetSuite = integrationType === domain.IntegrationType.NetSuite;
    const showAddField = !readonly && (isStandalone || (!isAutoApprovalMatrix && isNetSuite));

    const onCreateFieldOption = useCallback(
        (field: domain.Field, newOption: Reference) => {
            dispatch(createFieldOption(field, newOption));
        },
        [dispatch]
    );

    const createOnConditionChangeCreator = useCallback(
        (lineIndex: number) =>
            (ruleIndex: number) =>
            (lineId: string, rule: domain.MatrixRule, field: domain.Field, newCondition: domain.MatrixCondition) => {
                dispatch(setCondition(lineId, lineIndex, rule, ruleIndex, field, newCondition, matrix.type));
            },
        [dispatch, matrix.type]
    );

    const onCreateField = useCallback(
        (fieldName: string) => {
            dispatch(createField(template, fieldName));
        },
        [dispatch, template]
    );

    const onAddField = useCallback(
        (field: domain.Field) => {
            dispatch(addFieldToActiveTemplate(field));
        },
        [dispatch]
    );

    const onAddRule = useCallback(
        (ruleName: string) => {
            dispatch(addApprovalRuleToActiveMatrix(ruleName));
        },
        [dispatch]
    );

    const ruleNames = useMemo(() => matrix.data.map((rule) => rule.lineId), [matrix.data]);
    const userColumnTitle = useUserColumnTitle(matrix.type);
    const requiredFieldIds = useRequiredFieldIds({
        matrixType: matrix.type,
        template,
        requiredFieldIds: matrix.requiredFieldIds,
    });
    const unusedFields = useUnusedFields({ integrationType, fields, matrix, template });
    const canCreateField = isStandalone || isAutoApprovalMatrix;
    const isEditingOnApprovalAvailable = selectors.company.getIsEditingOnApprovalAvailable(
        company,
        template.integrationCode
    );
    const editingMatrixDefinition = useMemo(
        () =>
            getMatrixDefinition({
                betaFeatures: company.betaFeatures,
                integrationCode: template.integrationCode,
                matrixType: MatrixType.Editing,
            }),
        [company.betaFeatures, template.integrationCode]
    );
    const hasAccessToEditingMatrix =
        editingMatrixDefinition.columns.length > 0 &&
        isEditingOnApprovalAvailable &&
        matrix.type === MatrixType.Approval;

    const atTopStateChange = useCallback((atTop: boolean) => {
        setIsSticky(!atTop);
    }, []);

    const initialTopMostItemIndex = useMemo(() => {
        return matrix.data.findIndex((line) => line.lineId === matrix.highlightLineId);
    }, [matrix.data, matrix.highlightLineId]);

    return (
        <Root>
            <TableVirtuoso
                data={matrix.data}
                totalCount={matrix.data.length}
                atTopStateChange={atTopStateChange}
                components={{ Table }}
                initialTopMostItemIndex={initialTopMostItemIndex === -1 ? 0 : initialTopMostItemIndex}
                fixedHeaderContent={() => (
                    <tr>
                        <td>
                            <Header $sticky={isSticky}>
                                <Flex inline wrap={false}>
                                    <UserConditionHeader title={userColumnTitle} />

                                    {hasAccessToEditingMatrix && <EditingPermissionHeader />}

                                    {definition.columns.map((colDef) =>
                                        cellFields
                                            .filter((field) => field.systemPurpose === colDef.systemPurpose)
                                            .map((field) => (
                                                <Fragment key={field.id}>
                                                    {colDef.renderHeader({
                                                        field,
                                                        integrationCode: template.integrationCode,
                                                        matrixType: matrix.type,
                                                        readonly,
                                                        company,
                                                    })}
                                                </Fragment>
                                            ))
                                    )}

                                    {showAddField && (
                                        <AddFieldHeaderColumn
                                            unusedFields={unusedFields}
                                            onAddField={onAddField}
                                            onCreateField={onCreateField}
                                            canCreateField={canCreateField}
                                        />
                                    )}
                                </Flex>
                            </Header>
                        </td>
                    </tr>
                )}
                itemContent={(index, matrixLine) =>
                    isAutoApprovalMatrix ? (
                        <MatrixLineAutoApproval
                            key={matrixLine.lineId}
                            matrixType={matrix.type}
                            line={matrixLine}
                            company={company}
                            readonly={readonly}
                            cellFields={cellFields}
                            definition={definition}
                            integrationCode={template.integrationCode}
                            requiredFieldIds={requiredFieldIds}
                            templateSubmitters={submitters}
                            createOnConditionChange={createOnConditionChangeCreator(index)}
                            onCreateFieldOption={onCreateFieldOption}
                            lineIndex={index}
                            ruleNames={ruleNames}
                            highlight={initialTopMostItemIndex === index}
                        />
                    ) : (
                        <MatrixLine
                            key={matrixLine.lineId}
                            matrixType={matrix.type}
                            line={matrixLine}
                            company={company}
                            readonly={readonly}
                            cellFields={cellFields}
                            definition={definition}
                            template={template}
                            requiredFieldIds={requiredFieldIds}
                            templateSubmitters={submitters}
                            createOnConditionChange={createOnConditionChangeCreator(index)}
                            onCreateFieldOption={onCreateFieldOption}
                            team={team}
                            matrix={matrix}
                            hasAccessToEditingMatrix={hasAccessToEditingMatrix}
                            highlight={initialTopMostItemIndex === index}
                        />
                    )
                }
            />

            {isAutoApprovalMatrix && (
                <AddRuleNameContainer spacing='16 0 16 40' width='236px'>
                    <RuleNameButton
                        onSubmit={onAddRule}
                        ruleNames={ruleNames}
                        title={messages.ruleAutoApprovalButtonTitle}
                        readonly={readonly}
                    />
                </AddRuleNameContainer>
            )}

            {!isEditingMatrix && !isAutoApprovalMatrix && <MatrixAddUserButton team={team} readonly={readonly} />}
        </Root>
    );
});

Matrix.displayName = 'Matrix';

export default Matrix;
