/**
 * Developer: Stepan Burguchev
 * Date: 4/26/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './dateTimeEditor.scss';
import './reactDatesOverride.scss';

import { browserHelpers, eventHelpers, hooks, intl } from '@approvalmax/utils';
import moment, { Moment } from 'moment';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';

import { Dropdown } from '../../../drop';
import { DeleteIcon } from '../../../icons';
import { TransparentButton } from '../../../ui';
import { FieldContext } from '../../field/Field';
import { getDisabled } from '../../helpers';
import { BaseEditorProps } from '../EditorBase';

const i18nPrefix = 'form/editors/dateTime/DateTimeEditor';

export const ALL_DATETIME_THEMES = ['form', 'transparent'] as const;
export type DateTimeTheme = (typeof ALL_DATETIME_THEMES)[number];

export interface DateTimeEditorProps extends BaseEditorProps<string | null> {
    theme?: DateTimeTheme;
    targetElementId?: string;
    className?: string;
    portalClassName?: string;
    placeholder?: string;
    minDate?: string;
    maxDate?: string;
    excludedDates?: Moment[];
    hideClearButton?: boolean;
    withoutTimePart?: boolean;
    warning?: boolean;
    onFocus?: (event?: React.FocusEvent<HTMLInputElement, Element>) => void;
    onBlur?: (event?: React.FocusEvent<HTMLInputElement, Element>) => void;
}

function getMomentDate(value: Moment | string): Moment;
function getMomentDate(value: Moment | string | null | undefined): Moment | undefined | null;

function getMomentDate(value: Moment | string | null | undefined): Moment | undefined | null {
    if (!value) {
        return value as any;
    }

    let result;

    if (!(value instanceof moment)) {
        result = moment.utc(value);
    } else {
        result = value as Moment;
    }

    result = moment.utc({
        year: result.year(),
        month: result.month(),
        date: result.date(),
    });

    return result;
}

function formatInputText(value: string | null): string {
    if (!value) {
        return '';
    }

    return moment.utc(value).format('ll');
}

function parseInputText(text: string): string | false | null {
    if (!text.trim()) {
        return null;
    }

    const format = moment.localeData().longDateFormat('ll');
    const newValue = moment.utc(text, format);

    if (!newValue.isValid()) {
        return false;
    }

    return newValue.toISOString();
}

const adjustWithMinMaxRange = (
    newValue: string | null,
    { minDate, maxDate }: { minDate: Moment | undefined | null; maxDate: Moment | undefined | null }
) => {
    if (newValue) {
        // Adjust with min/max value
        let newValueMoment = getMomentDate(newValue);

        if (maxDate && newValueMoment.isAfter(maxDate)) {
            newValueMoment = maxDate;
        }

        if (minDate && newValueMoment.isBefore(minDate)) {
            newValueMoment = minDate;
        }

        newValue = newValueMoment.toISOString();
    }

    return newValue;
};

const defaultMinDate = '1753-01-01';
const defaultMaxDate = '9999-12-31';

const DateTimeEditor = forwardRef<HTMLInputElement, DateTimeEditorProps>((props, ref) => {
    const {
        className,
        portalClassName,
        targetElementId,
        theme = 'form',
        value,
        placeholder,
        invalid,
        warning,
        qa: qa$,
        focusOnMount,
        excludedDates,
        hideClearButton = false,
        withoutTimePart = false,
    } = props;
    const disabled = getDisabled(props.disabled, props.command);

    const bem = bemFactory.block('form-date-time-editor').themed(theme as any);
    const qa = bemFactory.qa('form-date-time-editor');

    const minDate = getMomentDate(props.minDate || defaultMinDate);
    const maxDate = getMomentDate(props.maxDate || defaultMaxDate);

    const [isOpen, setIsOpen] = useState(false);
    const [isPanelFocused, setIsPanelFocused] = useState(false);
    const [inputText, setInputText] = useState(formatInputText(value));
    const isPanelFocusedRef = hooks.useWrapInRef(isPanelFocused);
    const inputTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const panelTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const openPanel = () => {
        setIsOpen(true);
        setTimeout(() => {
            // If the panel was closed, we need to first wait for it to render
            setIsPanelFocused(true);
        }, 100);
    };

    const closePanel = () => {
        setIsOpen(false);
        setIsPanelFocused(false);
    };

    function commitInputText(newInputText: string) {
        let inputValue = parseInputText(newInputText);

        if (inputValue === false) {
            return false;
        }

        inputValue = adjustWithMinMaxRange(inputValue, { minDate, maxDate });

        if (inputValue !== value && props.onChange) {
            if (withoutTimePart) {
                inputValue = inputValue && moment(inputValue).utc().startOf('day').format('YYYY-MM-DD');
            }

            props.onChange(inputValue);
        }

        if (inputValue === value) {
            return false;
        }

        return true;
    }

    const onFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        if (props.onFocus) {
            props.onFocus(event);
        }

        setIsOpen(true);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        if (!commitInputText(inputText)) {
            setInputText(formatInputText(value));
        }

        inputTimerRef.current = setTimeout(() => {
            if (isPanelFocusedRef.current) {
                // We are on panel, it's ok
                return;
            }

            if (props.onBlur) {
                props.onBlur(event);
            }

            closePanel();
        }, 100);
    };

    const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                openPanel();
                break;

            case 'Escape':
                e.preventDefault();
                e.stopPropagation();
                closePanel();
                break;

            default:
                break;
        }
    };

    const { fieldId } = useContext(FieldContext);

    const [onMount, inputRef] = hooks.useForwardedRef(ref);

    hooks.useFocusOnMount(focusOnMount, inputRef);

    // Update the stateValue if props.value changes
    useEffect(() => {
        setInputText(formatInputText(value));
    }, [value]);

    if (browserHelpers.isMobile() || browserHelpers.isTablet()) {
        let minValue = minDate ? minDate.format('YYYY-MM-DD') : undefined;
        let maxValue = maxDate ? maxDate.format('YYYY-MM-DD') : undefined;

        return (
            <input
                id={fieldId}
                className={bem('mobile', { invalid, warning })}
                type='date'
                disabled={disabled}
                min={minValue}
                max={maxValue}
                value={value ? getMomentDate(value).format('YYYY-MM-DD') : ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const rawValue = e.target.value;

                    let newValue;

                    if (!rawValue) {
                        newValue = null;
                    } else {
                        newValue = moment.utc(rawValue, 'YYYY-MM-DD').toISOString();
                    }

                    if (withoutTimePart) {
                        newValue = newValue && moment(newValue).startOf('day').format('YYYY-MM-DD');
                    }

                    props.onChange(newValue);
                }}
            />
        );
    }

    return (
        <Dropdown
            className={bem.add(className)(null, {
                invalid,
                warning,
            })}
            portalClassName={portalClassName}
            targetElementId={targetElementId}
            qa={qa$}
            onRequestClose={() => closePanel()}
            isOpen={isOpen}
            button={
                <div className={bem('button', { disabled })}>
                    <input
                        id={fieldId}
                        ref={onMount}
                        className={bem('button-input')}
                        data-qa={qa('input')}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(e) => setInputText(e.target.value)}
                        value={inputText}
                        onKeyDown={onInputKeyDown}
                        onClick={() => setIsOpen(true)}
                        disabled={disabled}
                        type='text'
                    />

                    <TransparentButton
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.clearButtonTitle`,
                            defaultMessage: 'Clear field',
                        })}
                        focusable={false}
                        disabled={disabled}
                        execute={() => {
                            if (props.onChange) {
                                props.onChange(null);
                            }
                        }}
                        qa={qa('clear-button')}
                        className={bem('clear-button', { hidden: (!value && !inputText) || hideClearButton })}
                    >
                        <DeleteIcon width={8} height={8} />
                    </TransparentButton>
                </div>
            }
            panelMinWidth='none'
        >
            <div
                className={bem('panel')}
                data-qa={qa('panel')}
                data-testid='date-time-panel'
                onMouseDown={eventHelpers.preventDefaultCallback}
                onFocus={() => {
                    if (panelTimerRef.current) {
                        clearTimeout(panelTimerRef.current);
                    }

                    setIsPanelFocused(true);
                }}
                onBlur={() => {
                    panelTimerRef.current = setTimeout(() => setIsPanelFocused(false));
                }}
                onKeyDownCapture={(e) => {
                    if (e.key === 'Escape') {
                        e.preventDefault();
                        e.stopPropagation();
                        inputRef.current!.focus();
                        closePanel();
                    }
                }}
            >
                {/* Focus-trap, redirect => input */}

                <div tabIndex={0} onFocus={() => inputRef.current!.focus()} />

                <div className={bem('day-picker')}>
                    <DayPickerSingleDateController
                        date={getMomentDate(props.value) || null}
                        onDateChange={async (date) => {
                            let newValue = null;

                            if (date) {
                                newValue = getMomentDate(date).toISOString();
                            }

                            inputRef.current!.focus();
                            closePanel();

                            const newInputText = formatInputText(adjustWithMinMaxRange(newValue, { minDate, maxDate }));

                            setInputText(newInputText);
                            commitInputText(newInputText);
                        }}
                        focused={true}
                        isFocused={isPanelFocused}
                        onFocusChange={({ focused }) => {
                            setIsPanelFocused(Boolean(focused));
                        }}
                        isDayHighlighted={(day) => getMomentDate(day).isSame(getMomentDate(moment()))}
                        isOutsideRange={(day) => {
                            const d = getMomentDate(day);

                            const isBefore = minDate && d.isBefore(minDate);
                            const isAfter = maxDate && d.isAfter(maxDate);
                            const isExcluded =
                                excludedDates &&
                                excludedDates.some((date) => d.format('DD.MM.YYYY') === date.format('DD.MM.YYYY'));

                            return Boolean(isBefore || isAfter || isExcluded);
                        }}
                        initialVisibleMonth={null}
                        hideKeyboardShortcutsPanel
                        noBorder
                    />
                </div>

                {/* Focus-trap, redirect => input */}

                <div tabIndex={0} onFocus={() => inputRef.current!.focus()} />
            </div>
        </Dropdown>
    );
});

export default DateTimeEditor;
