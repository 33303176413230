import { domain, stateTree } from 'modules/data';
import { ImmutableObject, mergeDeep, mergeIn, setIn, updateIn, updateWithout } from 'modules/immutable';

import {
    Action,
    ADD_TEMP_USER,
    APPLY_TEMPLATE_SETTINGS_RESPONSE,
    CLEAR_TEMP_USERS,
    CREATE_FIELD_OPTION,
    DELETE_TEMPLATE_RESPONSE,
    FETCH_WORKFLOW_WITH_VERSION_RESPONSE,
    INVITE_USERS_RESPONSE,
    LOAD_PAGE_DATA,
    OPEN_START_OVER_POPUP_RESPONSE,
    RENAME_FIELD,
    RENAME_FIELD_FAILURE,
    SAVE_TEMPLATE_RESPONSE,
} from '../actions';
import { isNewUser } from '../selectors/userSelectors';

export default function (
    state: ImmutableObject<stateTree.Entities>,
    action: Action
): ImmutableObject<stateTree.Entities> {
    switch (action.type) {
        case LOAD_PAGE_DATA:
            return mergeDeep(state, action.payload.entities);

        case OPEN_START_OVER_POPUP_RESPONSE: {
            // TODO: needs review, probably should be removed (doesn't do anything now)
            let updatedState = mergeDeep(state, action.entities);

            return updatedState;
        }

        case SAVE_TEMPLATE_RESPONSE: {
            // TODO: needs review, entities get merged automatically now
            let updatedState = mergeDeep(state, action.entities);

            return updatedState;
        }

        case DELETE_TEMPLATE_RESPONSE:
            return updateWithout(state, 'templates', action.payload.request.template.id);

        case CREATE_FIELD_OPTION: {
            const options = state.fields[action.payload.field.id].exactValues;

            if (options.some((o) => o.id === action.payload.newOption.id)) {
                return state;
            }

            return setIn(
                state,
                ['fields', action.payload.field.id, 'exactValues'],
                options.concat(action.payload.newOption)
            );
        }

        case RENAME_FIELD:
            return setIn(state, ['fields', action.payload.field.id, 'name'], action.payload.name);

        case RENAME_FIELD_FAILURE:
            return setIn(state, ['fields', action.payload.request.field.id, 'name'], action.payload.request.field.name);

        case ADD_TEMP_USER: {
            let newState = state;

            if (!state.users[action.payload.newUser.id]) {
                newState = setIn(newState, ['users', action.payload.newUser.id], action.payload.newUser);
            }

            return setIn(
                newState,
                ['companies', action.payload.companyId, 'tempUsers', action.payload.newUser.id],
                action.payload.newUser
            );
        }

        case CLEAR_TEMP_USERS: {
            const withoutTempUsers = Object.keys(state.users).reduce(
                (acc: { [key: string]: domain.User }, userId: string) => {
                    // if user hasn't databaseId then it's temp user
                    if (state.users[userId].databaseId) {
                        acc[userId] = state.users[userId];
                    }

                    return acc;
                },
                {}
            );

            const stateWithoutTempUsers = setIn(state, ['users'], withoutTempUsers);
            const companiesWithoutTempUsers = Object.keys(state.companies).reduce(
                (acc: { [key: string]: domain.Company }, companyId: string) => {
                    acc[companyId] = {
                        ...state.companies[companyId],
                        tempUsers: null,
                    };

                    return acc;
                },
                {}
            );

            return setIn(stateWithoutTempUsers, ['companies'], companiesWithoutTempUsers);
        }

        case INVITE_USERS_RESPONSE: {
            const newUsers = action.payload.request.users
                .filter((u) => isNewUser(u, action.payload.company))
                .map((u) => u.id);
            const newState = mergeIn(
                mergeDeep(state, action.entities),
                ['companies', action.payload.request.company.id, 'userStatuses'],
                action.payload.userStatuses
            );

            return updateIn(newState, ['companies', action.payload.request.company.id, 'participants'], (p: string[]) =>
                p.concat(newUsers)
            );
        }

        /**
         * We need to update users entities, because of an old version of template may have offboarded users.
         * These users are most likely missing from redux entities.users
         * But we don't need to update templates entities, because of the current payload contains an old version
         * of template.
         * Old version has the same template.id, so it will be overwritten, but we don't need to store archive version
         * of template in redux entities.templates
         */
        case FETCH_WORKFLOW_WITH_VERSION_RESPONSE: {
            return mergeDeep(state, { users: action.payload.normilizedUsers });
        }

        case APPLY_TEMPLATE_SETTINGS_RESPONSE: {
            const { settings, companyId } = action.payload.request;

            const company = state.companies[companyId];

            const billMatchingSettings = settings.billMatchingSettings;
            const purchaseOrderMatchingSettings = settings.purchaseOrderMatchingSettings;
            const supplierEmailSettings = settings.supplierEmailSettings;
            const isGrnEnabled = settings.isGrnEnabled;

            let newState = state;

            if (isGrnEnabled != null && company?.integrationId) {
                const integration = state.integrations[company.integrationId];

                switch (integration.integrationType) {
                    case domain.IntegrationType.Xero:
                        newState = setIn(
                            state,
                            ['companies', companyId, 'xeroPurchaseOrderSettings', 'isGrnEnabled'],
                            isGrnEnabled
                        );
                        break;

                    case domain.IntegrationType.QBooks:
                        newState = setIn(
                            state,
                            ['companies', companyId, 'qboPurchaseOrderSettings', 'isGrnEnabled'],
                            isGrnEnabled
                        );
                        break;
                }
            }

            if (billMatchingSettings) {
                newState = setIn(state, ['companies', companyId, 'billMatchingSettings'], billMatchingSettings);
            }

            if (purchaseOrderMatchingSettings) {
                newState = setIn(
                    newState,
                    ['companies', companyId, 'purchaseOrderMatchingSettings'],
                    purchaseOrderMatchingSettings
                );
            }

            if (supplierEmailSettings) {
                newState = setIn(newState, ['companies', companyId, 'supplierEmailSettings'], supplierEmailSettings);
            }

            return newState;
        }

        default:
            return state;
    }
}
