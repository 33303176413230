import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopup.SectionNetSuiteFraudChangesAfter', {
    title: 'Fraud detection — changes made after approval',
    subtitle:
        'Which changes made in Xero after the approval in ApprovalMax should trigger the marking as "Changed after approval" and a notification to the Organisation\'s Administrators?',
    optionPayee: 'Payee',
    optionVendor: 'Vendor',
    optionTotalAmount: 'Total amount (more than 0.1 {companyCurrencyCode} change)',
    optionDate: 'Date',
    optionMemo: 'Memo',
    optionItem: 'Item',
    optionAccount: 'Account',
    optionExpenseCategory: 'Expense Category',
    optionLine: "Line's bill or credit",
});
