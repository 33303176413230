import { AmCaptureImage } from '@approvalmax/ui';
import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { closeActivePopup } from 'modules/request/actions';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';

import {
    bulkCreationPopupCompanyIdState,
    bulkCreationPopupOpenState,
} from '../BulkCreationPopupContent/BulkCreationPopupContent.states';
import { messages } from './BulkCreationBanner.messages';
import { StyledBox } from './BulkCreationBanner.styles';
import { BulkCreationBannerProps } from './BulkCreationBanner.types';

export const BulkCreationBanner: FC<BulkCreationBannerProps> = memo((props) => {
    const { companyId } = props;
    const dispatch = useDispatch();

    const setBulkCreationPopupOpen = useSetRecoilState(bulkCreationPopupOpenState);
    const setBulkCreationPopupCompanyId = useSetRecoilState(bulkCreationPopupCompanyIdState);

    const onCreate = useCallback(() => {
        dispatch(closeActivePopup());
        setBulkCreationPopupCompanyId(companyId);
        setBulkCreationPopupOpen(true);
    }, [companyId, dispatch, setBulkCreationPopupCompanyId, setBulkCreationPopupOpen]);

    return (
        <StyledBox width={280} height='fit-content' spacing='24' color='midnight20'>
            <Flex inline direction='column' alignItems='center' spacing='16'>
                <AmCaptureImage height={48} width={126} />

                <Text textAlign='center' font='headline' fontSize='xxsmall' fontWeight='medium'>
                    {messages.description}
                </Text>

                <Button onClick={onCreate} color='mint80'>
                    {messages.createButtonText}
                </Button>
            </Flex>
        </StyledBox>
    );
});

BulkCreationBanner.displayName = 'BulkCreationBanner';
