import { ErrorCode } from '@approvalmax/data';
import { errorHelpers } from '@approvalmax/utils';
import { backend, dataApi, domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useCurrentUser } from 'modules/utils';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { api } from 'services/api';

import { getActiveCompany } from '../../../selectors/moduleSelectors';

export enum XeroDataCreationStatus {
    CreatingWorkflows = 'CreatingWorkflows',
    CreatingRequests = 'CreatingRequests',
    Done = 'Done',
    Error = 'Error',
}

export async function createCompanyData({
    companyId,
    setStatus,
    currentUserEmail,
    companyCurrency,
}: {
    companyId: string;
    setStatus: (newStatus: XeroDataCreationStatus) => void;
    currentUserEmail: string;
    companyCurrency: string;
}) {
    try {
        setStatus(XeroDataCreationStatus.CreatingWorkflows);

        const managerEmail = 'regionalmanager@example.org';
        const cfoEmail = 'cfo@example.org';

        const fieldsResponse = await api.companies.selectFields({
            companyId: companyId,
            integrationCode: domain.IntegrationCode.XeroPo,
            withValues: true,
        });

        const trackingRegion = fieldsResponse.Fields.find(
            (f) => f.SystemPurpose === backend.FieldSystemPurpose.XeroTracking
        );

        if (!trackingRegion || trackingRegion.Name !== 'Region') {
            setStatus(XeroDataCreationStatus.Error);

            return;
        }

        const northTrackingOption = trackingRegion.ExactValues.find((to) => to.Value === 'North')!;
        const westCoastTrackingOption = trackingRegion.ExactValues.find((to) => to.Value === 'West Coast')!;
        const eastsideTrackingOption = trackingRegion.ExactValues.find(
            (to) => to.Value === 'Eastside' || to.Value === 'EastSide'
        )!;
        const southTrackingOption = trackingRegion.ExactValues.find((to) => to.Value === 'South')!;

        const amountField = fieldsResponse.Fields.find((f) => f.SystemPurpose === backend.FieldSystemPurpose.Amount)!;

        const templatesResponse = await dataApi.templates.select({
            companyId: companyId,
        });

        const billTemplate = templatesResponse.Templates.find(
            (t) => t.IntegrationCode === domain.IntegrationCode.XeroBill
        )!;
        const poTemplate = templatesResponse.Templates.find(
            (t) => t.IntegrationCode === domain.IntegrationCode.XeroPo
        )!;

        const buildStep = (name: string, participants: Array<backend.transfers.TemplateStepParticipantTransfer>) => {
            return {
                name: name,
                type: backend.StepType.DecisionStep,
                defaultDuration: '',
                deadlineRule: null,
                participants: participants,
                editors: [],
                ruleOrder: [],
                requiredFieldIds: [],
                readonlyFieldIds: [],
            };
        };

        const buildFirstStepParticipant = (email: string, options: Array<backend.FieldValueAnswer>) => {
            return {
                email: email,
                isBackup: false,
                rules: [
                    {
                        conditions: [
                            {
                                fieldId: trackingRegion.FieldId,
                                name: trackingRegion.Name,
                                conditionType: backend.FieldType.ExactValueRange,
                                exactConstraint: options.map((option) => {
                                    return { id: option.FieldValueId, value: option.Value };
                                }),
                            },
                        ],
                    },
                ],
            };
        };

        const buildFirstStep = () =>
            buildStep('Regional manager approval', [
                buildFirstStepParticipant(
                    currentUserEmail,
                    [northTrackingOption, westCoastTrackingOption].filter((obj) => obj !== undefined)
                ),
                buildFirstStepParticipant(
                    managerEmail,
                    [eastsideTrackingOption, southTrackingOption].filter((obj) => obj !== undefined)
                ),
            ]);

        const buildSecondStep = () =>
            buildStep('CFO approval', [
                {
                    email: cfoEmail,
                    isBackup: false,
                    rules: [
                        {
                            conditions: [
                                {
                                    fieldId: amountField.FieldId,
                                    name: amountField.Name,
                                    conditionType: backend.FieldType.NumericRange,
                                    amountType: backend.AmountConditionType.Gross,
                                    rangeConstraintGreaterEquals: 10000,
                                    exactConstraint: [],
                                },
                            ],
                        },
                    ],
                },
            ]);

        try {
            await api.templates.edit({
                templateId: billTemplate.TemplateId,
                companyId: companyId,
                templateName: billTemplate.TemplateName || '',
                externalSubmitter: billTemplate.ExternalSubmitter ? billTemplate.ExternalSubmitter.UserEmail : '',
                emailExternalSubmitter: billTemplate.EmailExternalSubmitter
                    ? billTemplate.EmailExternalSubmitter.UserEmail
                    : '',
                receiptBankExternalSubmitter: billTemplate?.ReceiptBankExternalSubmitter
                    ? billTemplate?.ReceiptBankExternalSubmitter.UserEmail
                    : '',
                integrationCode: billTemplate?.IntegrationCode,
                enabled: true,
                requiredFieldIds: billTemplate?.RequiredFieldIds,
                submitters: [
                    {
                        email: currentUserEmail,
                        rules: [],
                    },
                ],
                submitterRuleOrders: [],
                autoapprovalRules: [],
                steps: [buildFirstStep(), buildSecondStep()],
                reviewStep: null,
            });
        } catch (error) {
            if (errorHelpers.isApiError(error)) {
                if (error.code !== ErrorCode.XERO_ERROR) {
                    setStatus(XeroDataCreationStatus.Error);

                    return;
                }
            }
        }

        let editPoTemplateResponse: backend.TemplateEditAnswer;

        try {
            editPoTemplateResponse = await api.templates.edit({
                templateId: poTemplate.TemplateId,
                companyId: companyId,
                templateName: poTemplate.TemplateName || '',
                externalSubmitter: poTemplate.ExternalSubmitter ? poTemplate.ExternalSubmitter.UserEmail : '',
                emailExternalSubmitter: billTemplate.EmailExternalSubmitter
                    ? billTemplate.EmailExternalSubmitter.UserEmail
                    : '',
                receiptBankExternalSubmitter: '',
                integrationCode: poTemplate?.IntegrationCode,
                enabled: true,
                requiredFieldIds: poTemplate?.RequiredFieldIds,
                submitters: [
                    {
                        email: currentUserEmail,
                        rules: [],
                    },
                ],
                submitterRuleOrders: [],
                autoapprovalRules: [],
                steps: [buildFirstStep(), buildSecondStep()],
                reviewStep: null,
            });
        } catch (error) {
            if (errorHelpers.isApiError(error)) {
                if (error.code !== ErrorCode.XERO_ERROR) {
                    setStatus(XeroDataCreationStatus.Error);
                }
            }

            return;
        }

        setStatus(XeroDataCreationStatus.CreatingRequests);

        try {
            await api.templates.edit({
                templateId: poTemplate.TemplateId,
                companyId: companyId,
                templateName: poTemplate.TemplateName || '',
                externalSubmitter: poTemplate.ExternalSubmitter ? poTemplate.ExternalSubmitter.UserEmail : '',
                emailExternalSubmitter: billTemplate.EmailExternalSubmitter
                    ? billTemplate.EmailExternalSubmitter.UserEmail
                    : '',
                receiptBankExternalSubmitter: '',
                integrationCode: poTemplate?.IntegrationCode,
                enabled: true,
                requiredFieldIds: poTemplate?.RequiredFieldIds,
                submitters: [
                    {
                        email: currentUserEmail,
                        rules: [],
                    },
                ],
                submitterRuleOrders: [],
                autoapprovalRules: [],
                steps: [buildFirstStep(), buildSecondStep()],
                reviewStep: null,
            });
        } catch (error) {
            if (errorHelpers.isApiError(error)) {
                if (error.code !== ErrorCode.XERO_ERROR) {
                    setStatus(XeroDataCreationStatus.Error);

                    return;
                }
            }
        }

        // Create And Submit Purchase Order 1, 2 and Approve Second One

        const createPoResponse = await api.requests.create({
            templateId: poTemplate.TemplateId,
            companyId,
        });

        const items = (
            await api.integration.getItems({
                requestId: createPoResponse.RequestId,
                ruleType: 0,
                startFrom: 0,
                rowNum: 300,
                companyId,
            })
        ).Items;

        const accounts = (
            await api.integration.getAccounts({
                requestId: createPoResponse.RequestId,
                ruleType: 0,
                startFrom: 0,
                rowNum: 300,
                companyId,
            })
        ).Accounts!;

        const standardBrandingThemeId = fieldsResponse.Fields.find(
            (f) => f.SystemPurpose === backend.FieldSystemPurpose.XeroBranding
        )!.ExactValues.find((bv) => bv.Value === 'Standard')!.FieldValueId;

        const contact = fieldsResponse.Fields.find(
            (f) => f.SystemPurpose === backend.FieldSystemPurpose.XeroSupplier
        )!.ExactValues.find((bv) => bv.Value === 'ABC Furniture')!;

        const golfBalls = items.find((i) => i.Code === 'GB3-White')!;
        const purchasesAccount = accounts.find((a) => a.Code === '300')!;
        const today = moment().startOf('d');
        const todayUTC = moment()
            .utc()
            .startOf('d')
            .set('d', today.date())
            .set('m', today.month())
            .set('y', today.year())
            .toISOString();

        const getGolfBalls = () => {
            return {
                itemId: golfBalls.Id,
                description: golfBalls.PurchaseDetails.Description,
                taxAmount: '',
                taxCode: companyCurrency === 'AUD' ? 'BASEXCLUDED' : 'NONE',
                qty: '1.0000',
                unitPrice: '12.0000',
                amount: '12.00',
                accountId: purchasesAccount.Id,
                discount: '',
                trackingCategories: [{ id: trackingRegion.FieldId, valueId: northTrackingOption.FieldValueId }],
            };
        };

        const getPOPayload = (requestId: string) => {
            return {
                requestId: requestId,
                amount: 12.0,
                attachments: [],
                currency: companyCurrency,
                templateVersion: editPoTemplateResponse.Template.Version,
                companyId,
                purchaseOrder: {
                    reference: '',
                    lineAmountType: domain.LineAmountType.NoTax,
                    deliveryDate: '',
                    deliveryAddress: '',
                    deliveryInstructions: '',
                    attentionTo: '',
                    phone: '',
                    sendToContact: false,
                    brandingThemeId: standardBrandingThemeId,
                    contactId: contact.FieldValueId,
                    date: todayUTC,
                    lineItems: [getGolfBalls()],
                },
            };
        };

        const editPoResponse = await api.requests.edit(getPOPayload(createPoResponse.RequestId));

        const publishPOResponse = await api.requests.publish({
            requestId: createPoResponse.RequestId,
            companyId,
        });

        const createPo2Response = await api.requests.create({
            templateId: poTemplate.TemplateId,
            companyId,
        });

        const editPo2Response = await api.requests.edit(getPOPayload(createPo2Response.RequestId));

        const publishPO2Response = await api.requests.publish({
            requestId: createPo2Response.RequestId,
            companyId,
        });

        const getPO2Response = await api.requests.get({ requestId: createPo2Response.RequestId!, companyId });

        const approvePO2Response = await api.requests.respond({
            requestId: getPO2Response.Requests[0].RequestId,
            response: 2,
            requestVersion: getPO2Response.Requests[0].Version,
            stepId: getPO2Response.Requests[0].Steps[0].StepId,
            companyId,
        });

        const createBillResponse = await api.requests.create({
            templateId: billTemplate.TemplateId,
            companyId,
        });

        const editBillResponse = await api.requests.edit({
            requestId: createBillResponse.RequestId,
            amount: 12.0,
            attachments: [],
            currency: companyCurrency,
            templateVersion: editPoTemplateResponse.Template.Version,
            xeroInvoiceDetails: {
                reference: '',
                lineAmountType: domain.LineAmountType.NoTax,
                attentionTo: '',
                phone: '',
                brandingThemeId: standardBrandingThemeId,
                contactId: contact.FieldValueId,
                date: todayUTC,
                dueDate: todayUTC,
                lineItems: [getGolfBalls()],
            },
            companyId,
        });

        const publishBillResponse = await api.requests.publish({
            requestId: createBillResponse.RequestId,
            companyId,
        });

        setStatus(XeroDataCreationStatus.Done);
    } catch (e) {
        setStatus(XeroDataCreationStatus.Error);
    }
}

export function useXeroDemoDataCreator() {
    const [status, setStatus] = useState(XeroDataCreationStatus.CreatingWorkflows);

    const activeCompany = useSelector((state) => getActiveCompany(state));

    const currentUser = useCurrentUser();

    const startDataCreation = useCallback(() => {
        createCompanyData({
            companyId: activeCompany.id,
            setStatus,
            currentUserEmail: currentUser.userEmail,
            companyCurrency: activeCompany.defaultCurrency,
        });
    }, [activeCompany.id, currentUser.userEmail, activeCompany.defaultCurrency]);

    return useMemo(
        () => ({
            startDataCreation,
            status,
        }),
        [startDataCreation, status]
    );
}
