import { errorHelpers } from '@approvalmax/utils';
import { actions } from 'modules/common';
import { normalize, Schema } from 'normalizr';

import { Action, ActionKind, ActionMeta, ThunkAction } from '../types/Action';

function assignMeta<T extends string, P, M extends ActionMeta, E>(
    action: Action<T, P, M, E>,
    kind: ActionKind,
    operationId: string,
    isBlocking: boolean | undefined,
    showAsyncLoadingBar: boolean | undefined
) {
    let meta = action.meta as M;

    if (!action.meta) {
        meta = action.meta = {} as any;
    }

    meta.kind = kind;

    if (isBlocking != null) {
        meta.isBlocking = isBlocking;
    }

    if (operationId) {
        meta.operationId = operationId;
    }

    if (showAsyncLoadingBar != null) {
        meta.showAsyncLoadingBar = showAsyncLoadingBar;
    }
}

interface CreateAsyncActionOptions<
    TState,
    TDispatch extends <TSomeAction>(action: TSomeAction) => any,
    TEntities,
    TRequestActionType extends string,
    TRequestActionPayload,
    TRequestActionMeta extends ActionMeta,
    TResponseActionType extends string,
    TResponseActionPayload,
    TResponseActionMeta extends ActionMeta,
    TFailureActionType extends string,
    TFailureActionPayload,
    TFailureActionMeta extends ActionMeta,
> {
    /**
     * Return a request action which says that an async operation has started.
     */
    request: (state: TState) => Action<TRequestActionType, TRequestActionPayload, TRequestActionMeta, TEntities>;
    /**
     * Performs an async operation and returns a response action which indicated
     * that the async operation is done and provides the results.
     */
    response: (
        request: TRequestActionPayload,
        getState: () => TState,
        dispatch: TDispatch
    ) => Promise<Action<TResponseActionType, TResponseActionPayload, TResponseActionMeta, TEntities>>;
    /**
     * Returns a failure action which indicates that there has been an error when
     * performing the async request and provides the data necessary to handle it.
     */
    failure: (
        error: Error,
        request: TRequestActionPayload,
        state: TState
    ) => Action<TFailureActionType, TFailureActionPayload, TFailureActionMeta, undefined>;
    /**
     * Parse the response payload with the provided schema and put the results
     * into the `entities` property of the response. The entities are then automatically
     * merged into the state. (/modules/react-redux/reducers/mergeEntitiesReducer.ts)
     */
    schema?: Schema;
    /**
     * Makes the UI show the global loading bar somewhere on the screen.
     * Should not be used to control UI block.
     */
    showAsyncLoadingBar?: boolean;
    /**
     * Provide a unique id for this operation (`requestAction.type` by default).
     * Used so that the UI can detect a particular operation in progress and block
     * the right section of the UI.
     */
    operationId?: string | ((state: TState, request: TRequestActionPayload) => string);
    /**
     * An optional toast message (or a function that creates it) which is displayed
     * when the async operation succeeds.
     */
    successToast?:
        | string
        | ((state: TState, request: TRequestActionPayload, response: any) => string | undefined | null);
    /**
     * Says to the UI that this async operation should block it. It's up to the UI
     * to implement it i.e. disable editors, buttons etc.
     */
    isBlocking?: boolean;
    /**
     * A pre-flight check whether the async operation should be executed. Put your
     * validation with error toasts here.
     */
    shouldSendRequest?: (state: TState, dispatch: TDispatch) => boolean | Promise<boolean>;
    /**
     * An extra hook to perform operations after the async operation has finished.
     * Called BEFORE the final `response` action is dispatched. Use `didDispatchResponse`
     * when you need to dispatch more actions in the end.
     */
    willDispatchResponse?: (
        request: TRequestActionPayload,
        response: any,
        state: TState,
        dispatch: TDispatch
    ) => void | Promise<void>;
    /**
     * An extra hook to perform operations after the async operation has finished.
     * Called AFTER the final `response` action is dispatched. You can conditionally dispatch
     * more actions in this callback method.
     */
    didDispatchResponse?: (
        request: TRequestActionPayload,
        response: any,
        state: TState,
        dispatch: TDispatch
    ) => void | Promise<void>;
    /**
     * An extra hook to perform operations after the async operation has failed.
     * Called BEFORE the final `failure` action is dispatched. Use `didDispatchError`
     * when you need to dispatch more actions on error.
     */
    willDispatchError?: (
        request: TRequestActionPayload,
        error: Error,
        state: TState,
        dispatch: TDispatch
    ) => void | Promise<void>;
    /**
     * An extra hook to perform operations after the async operation has failed.
     * Called AFTER the final `failure` action is dispatched. You can conditionally dispatch
     * more actions in this callback method.
     */
    didDispatchError?: (
        request: TRequestActionPayload,
        error: Error,
        state: TState,
        dispatch: TDispatch
    ) => void | Promise<void>;
    /**
     * The promise returned by the thunk should be rejected if a failure has occured.
     * Example usage:
     * ```
     * // Inside a function component
     * try {
     *     setLoading(true);
     *     await dispatch(thisAsyncAction());
     * } catch {
     *     // You can now catch a failure
     *     setLoading(false);
     * }
     * ```
     */
    rejectOnFailure?: boolean;
}

type CreateAsyncActionResult<
    TState,
    TDispatch extends (action: any) => any,
    TEntities,
    TRequestActionType extends string,
    TRequestActionPayload,
    TRequestActionMeta extends ActionMeta,
    TResponseActionType extends string,
    TResponseActionPayload,
    TResponseActionMeta extends ActionMeta,
    TFailureActionType extends string,
    TFailureActionPayload,
    TFailureActionMeta extends ActionMeta,
> = ThunkAction<TState> & {
    $actions:
        | Action<TRequestActionType, TRequestActionPayload, TRequestActionMeta, TEntities>
        | Action<TResponseActionType, TResponseActionPayload, TResponseActionMeta, TEntities>
        | Action<TFailureActionType, TFailureActionPayload, TFailureActionMeta, undefined>;
    $requestAction: Action<TRequestActionType, TRequestActionPayload, TRequestActionMeta, TEntities>;
    $responseAction: Action<TResponseActionType, TResponseActionPayload, TResponseActionMeta, TEntities>;
    $failureAction: Action<TFailureActionType, TFailureActionPayload, TFailureActionMeta, undefined>;
};

export interface TypedCreateAsyncActionFn<TState, TDispatch extends (action: any) => any, TEntities> {
    <
        TRequestActionType extends string,
        TRequestActionPayload,
        TRequestActionMeta extends ActionMeta,
        TResponseActionType extends string,
        TResponseActionPayload,
        TResponseActionMeta extends ActionMeta,
        TFailureActionType extends string,
        TFailureActionPayload,
        TFailureActionMeta extends ActionMeta,
    >(
        options: CreateAsyncActionOptions<
            TState,
            TDispatch,
            TEntities,
            TRequestActionType,
            TRequestActionPayload,
            TRequestActionMeta,
            TResponseActionType,
            TResponseActionPayload,
            TResponseActionMeta,
            TFailureActionType,
            TFailureActionPayload,
            TFailureActionMeta
        >
    ): CreateAsyncActionResult<
        TState,
        TDispatch,
        TEntities,
        TRequestActionType,
        TRequestActionPayload,
        TRequestActionMeta,
        TResponseActionType,
        TResponseActionPayload,
        TResponseActionMeta,
        TFailureActionType,
        TFailureActionPayload,
        TFailureActionMeta
    >;
}

export const createAsyncAction = <
    TState,
    TDispatch extends (action: any) => any,
    TEntities,
    TRequestActionType extends string,
    TRequestActionPayload,
    TRequestActionMeta extends ActionMeta,
    TResponseActionType extends string,
    TResponseActionPayload,
    TResponseActionMeta extends ActionMeta,
    TFailureActionType extends string,
    TFailureActionPayload,
    TFailureActionMeta extends ActionMeta,
>(
    options: CreateAsyncActionOptions<
        TState,
        TDispatch,
        TEntities,
        TRequestActionType,
        TRequestActionPayload,
        TRequestActionMeta,
        TResponseActionType,
        TResponseActionPayload,
        TResponseActionMeta,
        TFailureActionType,
        TFailureActionPayload,
        TFailureActionMeta
    >
): CreateAsyncActionResult<
    TState,
    TDispatch,
    TEntities,
    TRequestActionType,
    TRequestActionPayload,
    TRequestActionMeta,
    TResponseActionType,
    TResponseActionPayload,
    TResponseActionMeta,
    TFailureActionType,
    TFailureActionPayload,
    TFailureActionMeta
> => {
    let result: any = async (dispatch: TDispatch, getState: () => TState) => {
        if (options.shouldSendRequest) {
            const shouldProceedResult = options.shouldSendRequest(getState(), dispatch);

            let shouldProceed: boolean;

            if (shouldProceedResult) {
                shouldProceed = await shouldProceedResult;
            } else {
                shouldProceed = shouldProceedResult;
            }

            if (shouldProceed === undefined) {
                console.warn('[createAsyncAction] shouldSendRequest returned undefined. Expected: true/false.');
            }

            if (!shouldProceed) {
                return;
            }
        }

        const requestAction = options.request(getState());

        let operationId =
            typeof options.operationId === 'function'
                ? options.operationId(getState(), requestAction.payload)
                : options.operationId || requestAction.type;

        assignMeta(
            requestAction,
            ActionKind.AsyncRequest,
            operationId,
            options.isBlocking,
            options.showAsyncLoadingBar
        );
        dispatch(requestAction);

        try {
            let responseAction = await options.response(requestAction.payload, getState, dispatch);

            assignMeta(
                responseAction,
                ActionKind.AsyncResponse,
                operationId,
                options.isBlocking,
                options.showAsyncLoadingBar
            );

            if (options.schema) {
                responseAction.entities = normalize(responseAction.payload, options.schema).entities as any;
            }

            if (options.successToast) {
                const message =
                    typeof options.successToast === 'function'
                        ? options.successToast(getState(), requestAction.payload, responseAction.payload)
                        : options.successToast;

                if (message) {
                    dispatch(actions.addInfoToast(message));
                }
            }

            if (options.willDispatchResponse) {
                await options.willDispatchResponse(requestAction.payload, responseAction.payload, getState(), dispatch);
            }

            await dispatch(responseAction);

            if (options.didDispatchResponse) {
                await options.didDispatchResponse(requestAction.payload, responseAction.payload, getState(), dispatch);
            }
        } catch (error) {
            errorHelpers.captureException(error);

            try {
                if (options.willDispatchError) {
                    await options.willDispatchError(requestAction.payload, error, getState(), dispatch);
                }

                const failureAction = options.failure(error, requestAction.payload, getState());

                assignMeta(
                    failureAction,
                    ActionKind.AsyncFailure,
                    operationId,
                    options.isBlocking,
                    options.showAsyncLoadingBar
                );

                dispatch(failureAction);

                if (options.didDispatchError) {
                    await options.didDispatchError(requestAction.payload, error, getState(), dispatch);
                }
            } catch (nestedError) {
                // Nested error while handling the original error
                errorHelpers.captureException(nestedError);
            }

            if (options.rejectOnFailure) {
                throw error;
            }
        }
    };

    return result;
};
